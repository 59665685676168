import * as t from 'io-ts';

import { DemoProjectTypeC } from 'common/constants/project-type';

export const DemoProjectC = t.intersection(
  [
    t.type(
      {
        id: t.number,
        name: t.string,
        type: DemoProjectTypeC,
        orderIndex: t.number,
      }),
    t.partial({
      tags: t.array(t.string),
      description: t.string,
      isImperial: t.boolean,
      hasLogo: t.boolean,
    }),
  ]);

export type DemoProject = t.TypeOf<typeof DemoProjectC>;

export const DemoProjectsC = t.array(DemoProjectC);
