import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { DemoProjectCardAuthWrapper } from 'common/components/demo-project-card-auth-wrapper';
import { ConstantFunctions } from 'common/constants/functions';
import { DemoProjectType } from 'common/constants/project-type';
import { AdminDemoProjectsApi } from '../../../api/demo-projects';
import { DemoProject } from '../../../pages/demo-projects/interfaces/demo-project';

interface Props {
  projectHeader: DemoProject;
  onDelete: (id: number, type: DemoProjectType) => void;
  onInviteToProject: (projectId: number) => void;
  onProjectNameEdit: (id: number) => void;
  onDuplicateProjectClick: (id: number) => void;
}

interface MenuItem  {
  text: string;
  icon: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export class DemoProjectsCard extends React.Component<Props> {
  private menuItems: MenuItem[] = [
    { text: 'Edit', icon: Icons.Edit2D, onClick: (e) => this.onDemoProjectEdit(e) },
    { text: 'Delete', icon: Icons.Delete, onClick: (e) => this.delete(e) },
  ];

  public render(): React.ReactNode {
    const { projectHeader } = this.props;
    const src = projectHeader.hasLogo ? `/api${AdminDemoProjectsApi.getScreenUrl(projectHeader.id)}` : '';

    return (
      <DemoProjectCardAuthWrapper
        image={src}
        projectName={projectHeader.name}
        menuItems={this.menuItems}
        onClick={ConstantFunctions.doNothing}
      />
    );
  }

  @autobind
  private delete(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    const { projectHeader } = this.props;
    this.props.onDelete(projectHeader.id, projectHeader.type);
  }

  @autobind
  private onDemoProjectEdit(e: React.MouseEvent<HTMLDivElement>): void {
    ConstantFunctions.stopEvent(e);
    this.props.onProjectNameEdit(this.props.projectHeader.id);
  }
}
