import styled from 'styled-components';

import { TinyText, Text } from '../../text';

const Form = styled.div`
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  label {
    margin-bottom: 0;
  }
  > ${TinyText} {
    position: absolute;
    bottom: -20px;
    left: 130px;
  }

  :not(:first-child) {
    > div {
      > ${Text} {
        display: none;
      }
    }
    > form {
      div:nth-child(1) {
        display: none;
      }
    }
  }

  :first-child {
    > div {
      > ${Text} {
        padding-bottom: 10px;
      }
    }
    > form {
      div:nth-child(1) {
        padding-bottom: 10px;
      }
    }
    > button {
      height: 100%;
      align-items: flex-end;
    }
  }
`;

const DeleteButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
`;

const DropdownContainer = styled.form`
  width: 210px;

  display: flex;
  flex-direction: column;
`;

const EmailInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RoleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Styled = {
  Form,
  DeleteButtonContainer,
  EmailInput,
  DropdownContainer,
  RoleContainer,
};
