import autobind from 'autobind-decorator';

import { DeferredExecutor } from 'common/utils/deferred-executer';
import { mathUtils } from 'common/utils/math-utils';
import { DrawingsCanvasConstants } from '../../constants';
import { DrawingLayoutApi } from '../../drawings-canvas';
import { DrawingContextObserver } from '../../drawings-contexts';
import {
  DrawingsBounds,
  DrawingsCanvasRenderParams,
  DrawingsSizeParameters,
  ShortPointDescription,
} from '../../interfaces';
import { DrawingsCanvasRect } from '../../interfaces/drawings-canvas-rect';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import { DrawingsUtils } from '../../utils/drawings-utils';
import { DrawingsZoomUtils } from '../../utils/drawings-zoom-utils';
import { DrawingInfo, DrawOptions, FocusElementsStatus, RenderResult } from './interfaces';
import { MenuView } from './menu-view';


export interface PdfDocumentRenderer {
  render: (
    pdfDocument: Core.Document,
    options: DrawOptions,
  ) => Promise<RenderResult>;
  cancelAndUnload: (pdfDocument: Core.Document) => void;
}

export interface AppyZoom {
  zoomCoefficient: number;
  mousePosition: ShortPointDescription;
  scrollTo?: () => void;
  depth?: number;
}

interface FocusBoundsSettings {
  bounds: DrawingsBounds;
  onlyScroll: boolean;
}

interface UpdateHandler<T> {
  updateContext: (value: T) => void;
  resetWithContext: (value: T) => void;
  getContext: () => T;
  getPrevContext: () => T;
}

export interface DrawingSize {
  width: number;
  height: number;
}

interface DrawingsViewportHelperConfig {
  zoomHandler: UpdateHandler<{ zoom: number }>;
  getCurrentRotation: () => number;
  getSelectedInstancesBounds:
  (pageSize: Core.Document.PageInfo) =>
    { zoomed: DrawingsBounds, bounds: DrawingsBounds } | null;
  canShowMainCanvas: () => boolean;
  getCurrentDrawingId: () => string;
  documentRenderer: PdfDocumentRenderer;
  restrictZoom?: boolean;
  scope?: paper.PaperScope;
}

const DEFAULT_ZOOM = 1;
const MAX_FOCUS_DEPTH = 3;

export class DrawingsViewportHelper {
  private _menuView: MenuView;

  private _secondLayoutObserver: DrawingContextObserver<boolean> = new DrawingContextObserver<boolean>(false);

  private _mainLayoutRef: HTMLDivElement;
  private _defaultCanvasRef: HTMLCanvasElement;
  private _scrollLayoutRef: HTMLDivElement;
  private _drawingLayoutApi: DrawingLayoutApi = null;

  private _boundToScroll: FocusBoundsSettings;
  private _focusElementsState: FocusElementsStatus = {
    bounds: undefined,
    zoomedBounds: undefined,
  };
  private _canvasRef: HTMLCanvasElement;
  private _rotation: number;
  private _config: DrawingsViewportHelperConfig;
  private _currentRenderId: number;

  private _applyResize: DeferredExecutor = new DeferredExecutor(1000);
  private _renderRectObserver: DrawingContextObserver<DrawingsBounds>
    = new DrawingContextObserver<DrawingsBounds>(null);
  private _drawingInfo: DrawingInfo;

  private _canceled: boolean;

  constructor(config: DrawingsViewportHelperConfig) {
    this._config = config;
    this._menuView = new MenuView({
      layoutView: this,
      getFocusElementsState: () => this._focusElementsState,
    });
  }

  public get secondLayoutObserver(): DrawingContextObserver<boolean> {
    return this._secondLayoutObserver;
  }

  public get drawingLayoutApi(): DrawingLayoutApi {
    return this._drawingLayoutApi;
  }

  public set drawingLayoutApi(api: DrawingLayoutApi) {
    this._drawingLayoutApi = api;
  }

  public get scrollLayoutRef(): HTMLDivElement {
    return this._scrollLayoutRef;
  }

  public set scrollLayoutRef(ref: HTMLDivElement) {
    this._scrollLayoutRef = ref;
  }

  public get defaultCanvasRef(): HTMLCanvasElement {
    return this._defaultCanvasRef;
  }

  public set defaultCanvasRef(ref: HTMLCanvasElement) {
    this._defaultCanvasRef = ref;
  }

  public get mainLayoutRef(): HTMLDivElement {
    return this._mainLayoutRef;
  }

  public set mainLayoutRef(api: HTMLDivElement) {
    this._mainLayoutRef = api;
  }

  public get drawingInfo(): DrawingInfo {
    return this._drawingInfo;
  }

  public set drawingInfo(drawingInfo: DrawingInfo) {
    this._drawingInfo = drawingInfo;
  }

  public get zoom(): number {
    return this._config.zoomHandler.getContext().zoom;
  }

  public get zoomHandler(): UpdateHandler<{ zoom: number }> {
    return this._config.zoomHandler;
  }

  public get rotation(): number {
    return this._rotation;
  }

  public get currentPageInfo(): Core.Document.PageInfo {
    if (this._drawingInfo) {
      const { pdfDocument, pageNumber } = this._drawingInfo;
      return pdfDocument.getPageInfo(DrawingsUtils.getPDFPageNumber(pageNumber));
    }
    return null;
  }

  public get renderRectObserver(): DrawingContextObserver<DrawingsBounds> {
    return this._renderRectObserver;
  }

  public set boundsToScroll(value: FocusBoundsSettings) {
    this._boundToScroll = value;
  }

  public get boundsToScroll(): FocusBoundsSettings {
    return this._boundToScroll;
  }

  public updateRenderParameters(zoom: number, rotation: number): void {
    if ((zoom === this._config.zoomHandler.getContext().zoom && rotation === this._rotation) || !this._drawingInfo) {
      return;
    }
    this._config.zoomHandler.updateContext({ zoom });
    this._rotation = rotation;
    this.updateBoundsIfNeeded();
  }

  public resetZoom(zoom: number): void {
    this._config.zoomHandler.resetWithContext({ zoom });
    this._drawingLayoutApi.setViewportZoom(zoom);
    this.updateBoundsIfNeeded();
  }

  public saveRotation(rotation: number): void {
    if (rotation === this._rotation) {
      return;
    }
    this._rotation = rotation;
    this.updateBoundsIfNeeded();
  }

  public saveOffsetPosition(point: paper.Point): void {
    const bounds: DrawingsBounds = {
      x: point.x,
      y: point.y,
      width: 0,
      height: 0,
    };
    this.updateSelectedBounds(bounds);
  }

  @autobind
  public saveMenuRef(ref: HTMLDivElement, isBooleanMenu?: boolean): void {
    this._menuView.saveMenuRef(ref, isBooleanMenu);
  }

  public updateSelectedBounds(bounds: DrawingsBounds, zoomedBounds?: DrawingsBounds): void {
    this._focusElementsState.bounds = bounds;
    if (!zoomedBounds && bounds) {
      const { width, height } = this.currentPageInfo;
      this._focusElementsState.zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
        bounds,
        this._config.zoomHandler.getContext().zoom,
        this._rotation,
        width,
        height,
      );
    } else {
      this._focusElementsState.zoomedBounds = zoomedBounds;
    }
    this._menuView.updateMenuPosition();
  }

  @autobind
  public setMainLayoutRef(ref: HTMLDivElement): void {
    this._mainLayoutRef = ref;
  }

  public removeDefaultCanvas(): void {
    if (this._defaultCanvasRef) {
      this._mainLayoutRef.removeChild(this._defaultCanvasRef);
      this._defaultCanvasRef = null;
    }
  }

  public updateDrawingZoom(canvas: HTMLCanvasElement): void {
    this.updateCanvasSize(
      ...DrawingsCanvasUtils.changeWidthHeightByRotation(
        this._defaultCanvasRef.width,
        this._defaultCanvasRef.height,
        this._rotation,
      ),
    );
    this._drawingLayoutApi.setViewportZoom(this._config.zoomHandler.getContext().zoom);
    const { width: canvasWidth, height: canvasHeight } = this._defaultCanvasRef.getBoundingClientRect();
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    const left = (mainWidth - canvasWidth) / 2;
    const top = (mainHeight - canvasHeight) / 2;
    this._drawingLayoutApi.updateCanvasRenderParameters({
      x1: 0,
      x2: canvas.width / this._config.zoomHandler.getContext().zoom,
      y1: 0,
      y2: canvas.height / this._config.zoomHandler.getContext().zoom,
      left,
      top,
    });
  }

  public renderMainPdfLayout(rotation: number): Promise<boolean> {
    this.removeDefaultCanvas();
    const drawingId = this._config.getCurrentDrawingId();
    return new Promise((resolve, reject) => {
      this.drawPdf({
        zoom: this._config.zoomHandler.getContext().zoom,
        pageRotation: DrawingsCanvasUtils.getPDFTronRotation(rotation),
        drawingId,
      }).then(({ canvas, options }) => {
        if (this._canceled) {
          return;
        }
        const currentRotation = this._config.getCurrentRotation();
        if (rotation !== currentRotation) {
          this.drawingLayoutApi.setViewportZoom(options.zoom);
          this.renderMainPdfLayout(currentRotation).then(resolve);
        } else {
          this.applyRotationForCurrentDrawing(canvas, drawingId, rotation).then(resolve);
        }
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  }

  public getMetersPerPx(format: string): number {
    const { pdfDocument, pageNumber } = this._drawingInfo;
    const { width, height } = pdfDocument.getPageInfo(DrawingsUtils.getPDFPageNumber(pageNumber));
    return DrawingsUtils.getMetersPerPx(width, height, format);
  }

  public applyRotationToLayout(rotation: number): void {
    const { width, height } = this._defaultCanvasRef.getBoundingClientRect();
    this._mainLayoutRef.style.minHeight = `${height}px`;
    this._mainLayoutRef.style.minWidth = `${width}px`;
    this._drawingLayoutApi.rotate(rotation);
    const trueWidthAndHeight = DrawingsCanvasUtils.changeWidthHeightByRotation(width, height, rotation);
    this._drawingLayoutApi.setCanvasSize(...trueWidthAndHeight);
    this.saveRotation(rotation);
  }


  public removeCanvas(): void {
    if (this._canvasRef) {
      this._mainLayoutRef.removeChild(this._canvasRef);
      this._canvasRef = null;
    }
  }

  public cancel(): void {
    this._canceled = true;
    if (this._drawingInfo) {
      this.cancelAndUnload(this._drawingInfo.pdfDocument);
    }
  }

  public cancelAndUnload(pdfDocument: Core.Document): void {
    if (Number.isInteger(this._currentRenderId)) {
      pdfDocument.unloadCanvasResources(this._currentRenderId);
      pdfDocument.cancelLoadCanvas(this._currentRenderId);
    }
  }

  public async scrollToDrawingInstances(settings: FocusBoundsSettings, depth: number = 0): Promise<boolean> {
    if (!this._defaultCanvasRef) {
      this._boundToScroll = settings;
      return false;
    }
    const { bounds, onlyScroll } = settings;
    const { width: canvasWidth, height: canvasHeight } = this._defaultCanvasRef.getBoundingClientRect();
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    const { width: viewWidth, height: viewHeight } = this._scrollLayoutRef.getBoundingClientRect();
    const { width, height } = this.currentPageInfo;

    const zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
      bounds,
      this._config.zoomHandler.getContext().zoom,
      this._rotation,
      width,
      height,
    );
    const canvasX = (mainWidth - canvasWidth) / 2;
    const canvasY = (mainHeight - canvasHeight) / 2;
    const boundWidthLessThenView = zoomedBounds.width < viewWidth;
    const boundHeightLessThenView = zoomedBounds.height < viewHeight;

    const widthDiff = zoomedBounds.width ? zoomedBounds.width - viewWidth : 0;
    const heightDiff = zoomedBounds.height ? zoomedBounds.height - viewHeight : 0;
    const [boundsWidth, boundsHeight] = DrawingsCanvasUtils.changeWidthHeightByRotation(
      bounds.width,
      bounds.height,
      this._rotation,
    );
    if (boundWidthLessThenView && boundHeightLessThenView) {
      const scrollX = canvasX + zoomedBounds.center.x - viewWidth / 2;
      const scrollY = canvasY + zoomedBounds.center.y - viewHeight / 2;
      if (onlyScroll || bounds.width === 0 && bounds.height === 0) {
        this._boundToScroll = null;
        this._scrollLayoutRef.scroll(scrollX, scrollY);
        return true;
      } else if (Math.abs(widthDiff) < Math.abs(heightDiff)) {
        await this.zoomOrScroll(
          widthDiff,
          viewWidth,
          boundsWidth,
          viewWidth,
          viewHeight,
          settings,
          scrollX,
          scrollY,
          depth,
        );
        return true;
      } else {
        await this.zoomOrScroll(
          heightDiff,
          viewHeight,
          boundsHeight,
          viewWidth,
          viewHeight,
          settings,
          scrollX,
          scrollY,
          depth,
        );
        return true;
      }
    } else {
      this._boundToScroll = settings;
      let degree = 1;
      const zoomInCoefficientDegree = DrawingsZoomUtils.getZoomInCoefficientDegree(
        this._config.zoomHandler.getContext().zoom) + 1;
      if (!boundHeightLessThenView && !boundWidthLessThenView) {
        if (widthDiff > heightDiff) {
          degree = zoomInCoefficientDegree - DrawingsZoomUtils.calculateMaxZoomDegree(viewWidth, boundsWidth);
        } else {
          degree = zoomInCoefficientDegree - DrawingsZoomUtils.calculateMaxZoomDegree(viewHeight, boundsHeight);
        }
      } else if (!boundHeightLessThenView) {
        degree = zoomInCoefficientDegree - DrawingsZoomUtils.calculateMaxZoomDegree(viewHeight, boundsHeight);
      } else if (!boundWidthLessThenView) {
        degree = zoomInCoefficientDegree - DrawingsZoomUtils.calculateMaxZoomDegree(viewWidth, boundsWidth);
      }
      const newZoom = Math.pow(DrawingsCanvasConstants.zoomOutCoefficient, degree);
      await this.applyZoom({
        zoomCoefficient: newZoom,
        mousePosition: [
          this.scrollLayoutRef.scrollLeft + viewWidth / 2,
          this.scrollLayoutRef.scrollTop + viewHeight / 2,
        ],
        depth,
      });
      return true;
    }
  }

  @autobind
  public focusToBounds(bounds: DrawingsBounds): void {
    const { width: viewWidth, height: viewHeight } = this._scrollLayoutRef.getBoundingClientRect();
    const { width, height } = this.currentPageInfo;

    const zoomedBounds =
      DrawingsGeometryUtils.zoomAndRotateBounds(
        bounds,
        this._config.zoomHandler.getContext().zoom,
        this._rotation,
        width,
        height,
      );
    const widthDiff = viewWidth / zoomedBounds.width;
    const heightDiff = viewHeight / zoomedBounds.height;
    this.applyZoom(
      {
        zoomCoefficient: Math.min(widthDiff, heightDiff),
        mousePosition: [
          this.scrollLayoutRef.scrollLeft + viewWidth / 2,
          this.scrollLayoutRef.scrollTop + viewHeight / 2,
        ],
        scrollTo: () => this.scrollToBounds(bounds),
      },
    );
  }

  public dropLayoutSettings(): void {
    const canvas = this.drawingLayoutApi.getDrawingCanvas();
    if (canvas) {
      canvas.style.width = '';
      canvas.style.height = '';
    }
    this.mainLayoutRef.scrollLeft = 0;
    this.mainLayoutRef.scrollTop = 0;
  }

  public removeDrawing(): void {
    this.drawingInfo = null;
    const canvas = this.drawingLayoutApi.getDrawingCanvas();
    canvas.style.width = '0px';
    canvas.style.height = '0px';
    this.mainLayoutRef.style.width = '100%';
    this.mainLayoutRef.style.height = '100%';
    this.removeDefaultCanvas();
  }

  @autobind
  public drawPdf(options: DrawOptions): Promise<RenderResult> {
    const { pdfDocument, pageNumber } = this._drawingInfo;
    return this._config.documentRenderer.render(
      pdfDocument,
      {
        ...options,
        pageNumber: DrawingsUtils.getPDFPageNumber(pageNumber),
      });
  }

  @autobind
  public scrollToCenter(): void {
    const { width, height } = this.currentPageInfo;
    const x = width / 2;
    const y = height / 2;
    this.scrollToBounds({
      x,
      y,
      width: 1,
      height: 1,
      center: { x, y },
    });
  }

  public scrollToPagePosition(left: number, top: number): void {
    const { width, height } = this._scrollLayoutRef.getBoundingClientRect();
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    const { width: pageWidth, height: pageHeight } = this.getCurrentPageParameters();
    const { zoom } = this._config.zoomHandler.getContext();
    this._scrollLayoutRef.scrollTop = (mainHeight - pageHeight * zoom - height) / 2 + top * zoom;
    this._scrollLayoutRef.scrollLeft = (mainWidth - pageWidth * zoom - width) / 2 + left * zoom;
  }

  @autobind
  public saveMainCanvas(canvas: HTMLCanvasElement, drawingId: string, skipUpdateDrawing?: boolean): void {
    if (!this._config.canShowMainCanvas()) {
      return;
    }
    this.removeDefaultCanvas();
    const currentDrawingId = this._config.getCurrentDrawingId();
    if (!this.mainLayoutRef || currentDrawingId !== drawingId || !this.currentPageInfo) {
      return;
    }

    this.defaultCanvasRef = canvas;
    this.mainLayoutRef.appendChild(this.defaultCanvasRef);

    const { width, height } = this.scrollLayoutRef.getBoundingClientRect();
    const { width: canvasWidth, height: canvasHeight } = canvas.getBoundingClientRect();
    this.mainLayoutRef.style.height = canvasHeight < height ? '100%' : `${canvasHeight}px`;
    this.mainLayoutRef.style.width = canvasWidth < width ? '100%' : `${canvasWidth}px`;
    const selectedBounds = this._config.getSelectedInstancesBounds(this.currentPageInfo);
    if (selectedBounds) {
      this.updateSelectedBounds(selectedBounds.bounds, selectedBounds.zoomed);
    }
    if (!skipUpdateDrawing) {
      this.updateDrawingZoom(canvas);
    } else {
      this.drawingLayoutApi.updateViewportParams();
    }
    this.scrollToCenter();
  }

  public setViewportRect(
    { x1, y1, x2, y2 }: DrawingsCanvasRect,
    zoom: number,
    needRotate?: boolean,
  ): DrawingsBounds {
    const { width, height } = this.getCurrentPageParameters();
    const canvasSize = { width: width * zoom, height: height * zoom };
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    let diffX = (mainWidth - canvasSize.width) / 2 - this._scrollLayoutRef.scrollLeft;
    let diffY = (mainHeight - canvasSize.height) / 2 - this._scrollLayoutRef.scrollTop;
    diffY = diffY < 0 ? 0 : diffY / zoom; // eslint-disable-line @typescript-eslint/no-unused-vars
    diffX = diffX < 0 ? 0 : diffX / zoom; // eslint-disable-line @typescript-eslint/no-unused-vars
    if (needRotate) {
      [x1, y1] = DrawingsCanvasUtils.getPointOnPage([x1, y1], this.currentPageInfo, this._rotation);
      [x2, y2] = DrawingsCanvasUtils.getPointOnPage([x2, y2], this.currentPageInfo, this._rotation);
      if (x1 > x2) {
        [x1, x2] = [x2, x1];
      }
      if (y1 > y2) {
        [y1, y2] = [y2, y1];
      }
    }
    return {
      x: x1,
      y: y1,
      width: x2 - x1,
      height: y2 - y1,
    };
  }

  @autobind
  public saveScrollLayout(ref: HTMLDivElement): void {
    this._scrollLayoutRef = ref;
  }


  @autobind
  public applyZoom(
    {
      zoomCoefficient,
      mousePosition: [mouseX, mouseY],
      scrollTo,
      depth: depth = 0,
    }: AppyZoom,
  ): Promise<void> {
    const scrollLayoutRect = this.scrollLayoutRef.getBoundingClientRect();
    this.removeCanvas();
    const mainLayoutRect = this.mainLayoutRef.getBoundingClientRect();
    this._secondLayoutObserver.updateContext(false);
    if (!this.shouldApplyZoom(scrollLayoutRect, mainLayoutRect, zoomCoefficient)) {
      return Promise.resolve();
    }
    const newZoom = this._config.zoomHandler.getContext().zoom * zoomCoefficient;
    const pageSize = this.getCurrentPageParameters();
    const canvasSize = this.getNewCanvasSize(pageSize, newZoom);
    this.updateLayoutSizes(canvasSize);
    this.drawingLayoutApi.setViewportZoom(newZoom);

    const newMainSize = this.getMainLayoutSizes(mainLayoutRect, canvasSize, scrollLayoutRect, zoomCoefficient);

    this.setMainLayoutSize(newMainSize.width, newMainSize.height);

    const {
      scrollLeft: newScrollLeft,
      scrollTop: newScrollTop,
    } = this.getNewScrollPositions(mouseX, mouseY, scrollLayoutRect, newZoom);
    this.scrollLayoutRef.scrollLeft = newScrollLeft;
    this.scrollLayoutRef.scrollTop = newScrollTop;
    const canvasRenderParams = DrawingsCanvasUtils.getCanvasRenderParams(
      newMainSize,
      scrollLayoutRect,
      pageSize,
      newScrollLeft,
      newScrollTop,
      newZoom,
      this._rotation,
    );
    const { x, y, width, height, left, top, drawingLayoutWidth, drawingLayoutHeight } = canvasRenderParams;
    const renderRect = {
      x1: x,
      y1: y,
      x2: (x + width),
      y2: (y + height),
    };
    this.drawingLayoutApi.setCanvasSize(
      ...DrawingsCanvasUtils.changeWidthHeightByRotation(drawingLayoutWidth, drawingLayoutHeight, this._rotation),
    );
    this.drawingLayoutApi.updateCanvasRenderParameters({ ...renderRect, left, top });
    this._focusElementsState.zoomedBounds = this._focusElementsState.bounds
      ? DrawingsGeometryUtils.zoomAndRotateBounds(
        this._focusElementsState.bounds,
        newZoom,
        this._rotation,
        pageSize.width,
        pageSize.height,
      )
      : null;
    return new Promise<void>((resolve, reject) => {
      this._config.zoomHandler.updateContext({ zoom: newZoom });
      if (this._boundToScroll) {
        this.scrollToDrawingInstances(this.boundsToScroll, depth + 1)
          .then(() => {
            this._secondLayoutObserver.updateContext(true);
            resolve();
          })
          .catch(reject);
      } else if (scrollTo) {
        scrollTo();
        this._secondLayoutObserver.updateContext(true);
        resolve();
      } else {
        this.drawPdf(
          {
            zoom: this._config.zoomHandler.getContext().zoom,
            renderRect,
            pageRotation: DrawingsCanvasUtils.getPDFTronRotation(this._rotation),
            drawingId: this._drawingInfo.drawingId,
          },
        )
          .then(({ canvas, options }) => {
            this.replaceViewportAfterScroll(left, top, canvas, options);
            this._secondLayoutObserver.updateContext(true);
            resolve();
          })
          .catch(reject);
      }
    }).catch(e => {
      console.error(e);
    });
  }

  @autobind
  public zoomEventHandler(event: WheelEvent): void {
    event.preventDefault();
    if (this._defaultCanvasRef) {
      if (event.deltaY < 0) {
        this.applyZoom({
          zoomCoefficient: DrawingsCanvasConstants.zoomInCoefficient,
          mousePosition: [event.clientX, event.clientY],
        });
      } else {
        this.applyZoom({
          zoomCoefficient: DrawingsCanvasConstants.zoomOutCoefficient,
          mousePosition: [event.clientX, event.clientY],
        });
      }
    }
  }

  @autobind
  public onChangeViewport(): void {
    if (!this._defaultCanvasRef) {
      return;
    }
    const canvasRenderParams = this.getCurrentRenderParams(this._rotation);
    const { x, y, width, height, left, top, drawingLayoutWidth, drawingLayoutHeight } = canvasRenderParams;
    const x2 = x + width;
    const y2 = y + height;
    const drawingCanvasRect = { x1: x, y1: y, x2, y2, left, top };
    if (x2 < x || y2 < y) {
      this.scrollToCenter();
      return;
    }
    this._menuView.updateMenuPosition();
    this._drawingLayoutApi.setCanvasSize(
      ...DrawingsCanvasUtils.changeWidthHeightByRotation(drawingLayoutWidth, drawingLayoutHeight, this._rotation),
    );
    this._drawingLayoutApi.updateCanvasRenderParameters(drawingCanvasRect);
    this.drawPdf(
      {
        zoom: this._config.zoomHandler.getContext().zoom,
        renderRect: { x1: x, y1: y, x2, y2 },
        pageRotation: DrawingsCanvasUtils.getPDFTronRotation(this._rotation),
        drawingId: this._drawingInfo.drawingId,
      },
    ).then(({ canvas, options }) => {
      this.replaceViewportAfterScroll(left, top, canvas, options);
    });
  }

  @autobind
  public onResize(width: number, height: number): void {
    if (!this._defaultCanvasRef) {
      return;
    }
    if (this._config.scope) {
      this._config.scope.view.autoUpdate = false;
    }
    this._applyResize.execute(() => {
      this.applyResize(width, height);
      if (this._config.scope) {
        this._config.scope.view.autoUpdate = true;
        this._config.scope.view.update();
      }
    });
  }

  @autobind
  public zoomIn(): void {
    const {
      width: scrollWidth,
      height: scrollHeight,
    } = this._scrollLayoutRef.getBoundingClientRect();
    this.applyZoom({
      zoomCoefficient: DrawingsCanvasConstants.zoomInCoefficient,
      mousePosition: [scrollWidth / 2, scrollHeight / 2],
    });
  }

  public getCurrentPageParameters(): DrawingsSizeParameters {
    let { width, height } = this.currentPageInfo;
    [width, height] = DrawingsCanvasUtils.changeWidthHeightByRotation(width, height, this._rotation);
    return { width, height };
  }

  @autobind
  public getPdfText(): Promise<string> {
    const doc = this._drawingInfo.pdfDocument;
    return doc.loadPageText(this._drawingInfo.pageNumber + 1)
      .catch((e) => {
        console.error(e);
        return '';
      });
  }

  @autobind
  public zoomOut(): void {
    const {
      width: scrollWidth,
      height: scrollHeight,
    } = this._scrollLayoutRef.getBoundingClientRect();
    this.applyZoom({
      zoomCoefficient: DrawingsCanvasConstants.zoomOutCoefficient,
      mousePosition: [scrollWidth / 2, scrollHeight / 2],
    });
  }

  @autobind
  public home(): void {
    const { width, height } = this._mainLayoutRef.getBoundingClientRect();
    this.applyZoom(
      {
        zoomCoefficient: 1 / this._config.zoomHandler.getContext().zoom,
        mousePosition: [width / 2, height / 2],
      },
    ).then(() => this.scrollToCenter());
  }

  @autobind
  public syncPageViewportRect(options: DrawOptions): void {
    const { width, height } = this.getCurrentPageParameters();
    const bounds: DrawingsBounds = options.renderRect
      ? this.setViewportRect(options.renderRect, options.zoom, true)
      : { x: 0, y: 0, width, height };
    this._renderRectObserver.updateContext(bounds);
  }

  private applyResize(width: number, height: number): void {
    if (!this._defaultCanvasRef || !this._mainLayoutRef) return;
    const pageSize = this.getCurrentPageParameters();
    const mainLayoutBounds = this._mainLayoutRef.getBoundingClientRect();
    const { width: canvasWidth, height: canvasHeight } = this._defaultCanvasRef.getBoundingClientRect();
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    const left = (mainWidth - canvasWidth) / 2;
    const top = (mainHeight - canvasHeight) / 2;
    this.removeCanvas();
    if (pageSize.width * this._config.zoomHandler.getContext().zoom < width) {
      this._mainLayoutRef.style.width = `100%`;
    } else if (width > mainLayoutBounds.width) {
      this._mainLayoutRef.style.width = `${width}px`;
    } else if (this._scrollLayoutRef.scrollLeft > left) {
      this._scrollLayoutRef.scrollLeft = this._scrollLayoutRef.scrollLeft - left;
      this._mainLayoutRef.style.width = `${width}px`;
    }

    if (pageSize.height * this._config.zoomHandler.getContext().zoom < height) {
      this._mainLayoutRef.style.height = `100%`;
    } else if (height > mainLayoutBounds.height) {
      this._mainLayoutRef.style.height = `${height}px`;
    } else if (this._scrollLayoutRef.scrollTop > top) {
      this._scrollLayoutRef.scrollTop = this._scrollLayoutRef.scrollTop - top;
      this._mainLayoutRef.style.height = `${height}px`;
    }
    this.onChangeViewport();
  }

  private setMainLayoutSize(width: number, height: number): void {
    this._mainLayoutRef.style.width = `${width}px`;
    this._mainLayoutRef.style.height = `${height}px`;
  }

  private updateBoundsIfNeeded(): void {
    if (this._focusElementsState.bounds) {
      this.updateSelectedBounds(this._focusElementsState.bounds);
    }
  }

  private replaceViewport(canvas: HTMLCanvasElement, left: number, top: number): void {
    this._canvasRef = canvas;
    this._mainLayoutRef.appendChild(this._canvasRef);
    this._canvasRef.style.position = 'absolute';
    this._canvasRef.style.left = `${left}px`;
    this._canvasRef.style.top = `${top}px`;
    this._drawingLayoutApi.updateViewportParams();
  }

  private updateCanvasSize(width: number, height: number): void {
    const canvas = this._drawingLayoutApi.getDrawingCanvas();
    canvas.width = width;
    canvas.height = height;
  }

  @autobind
  private scrollToBounds(bounds: DrawingsBounds): void {
    const { width, height } = this.currentPageInfo;
    const zoomedBounds = DrawingsGeometryUtils.zoomAndRotateBounds(
      bounds,
      this._config.zoomHandler.getContext().zoom,
      this._rotation,
      width,
      height,
    );
    const { width: canvasWidth, height: canvasHeight } = this._defaultCanvasRef.getBoundingClientRect();
    const { width: mainWidth, height: mainHeight } = this._mainLayoutRef.getBoundingClientRect();
    const { width: viewWidth, height: viewHeight } = this._scrollLayoutRef.getBoundingClientRect();
    const canvasX = (mainWidth - canvasWidth) / 2;
    const canvasY = (mainHeight - canvasHeight) / 2;
    const scrollX = canvasX + zoomedBounds.center.x - viewWidth / 2;
    const scrollY = canvasY + zoomedBounds.center.y - viewHeight / 2;
    this._scrollLayoutRef.scrollTo(scrollX, scrollY);
  }

  private zoomOrScroll(
    diff: number,
    view: number,
    bound: number,
    viewWidth: number,
    viewHeight: number,
    bounds: FocusBoundsSettings,
    scrollX: number,
    scrollY: number,
    depth: number = 0,
  ): Promise<void> {
    const zoomInCoefficientDegree = DrawingsZoomUtils.getZoomInCoefficientDegree(
      this._config.zoomHandler.getContext().zoom) - 1;
    const degree = DrawingsZoomUtils.calculateMaxZoomDegree(
      view - DrawingsCanvasConstants.zoomInFocusPerfectMarginsSum,
      bound,
    );
    if (Math.abs(diff) > DrawingsCanvasConstants.zoomInFocusPerfectMarginsSum && degree - zoomInCoefficientDegree > 0) {
      if (depth > MAX_FOCUS_DEPTH) {
        console.error('Too many zoom attempts', diff, degree - zoomInCoefficientDegree);
        this._boundToScroll = null;
        this._scrollLayoutRef.scroll(scrollX, scrollY);
        return Promise.resolve();
      }
      this._boundToScroll = bounds;
      return this.applyZoom({
        zoomCoefficient: Math.pow(DrawingsCanvasConstants.zoomInCoefficient, degree - zoomInCoefficientDegree),
        mousePosition: [
          this.scrollLayoutRef.scrollLeft + viewWidth / 2,
          this.scrollLayoutRef.scrollTop + viewHeight / 2,
        ],
        depth,
      });
    } else {
      this._boundToScroll = null;
      this._scrollLayoutRef.scroll(scrollX, scrollY);
      return Promise.resolve();
    }
  }

  private getCurrentRenderParams(rotation: number): DrawingsCanvasRenderParams {
    const {
      width: scrollWidth,
      height: scrollHeight,
    } = this._scrollLayoutRef.getBoundingClientRect();
    const { scrollLeft, scrollTop } = this._scrollLayoutRef;
    const canvasRenderParams = DrawingsCanvasUtils.getCanvasRenderParams(
      this._mainLayoutRef.getBoundingClientRect(),
      { width: scrollWidth, height: scrollHeight },
      this.getCurrentPageParameters(),
      scrollLeft,
      scrollTop,
      this._config.zoomHandler.getContext().zoom,
      rotation,
    );
    return canvasRenderParams;
  }

  private replaceViewportAfterScroll(
    left: number,
    top: number,
    canvas: HTMLCanvasElement,
    options: DrawOptions,
  ): void {
    if (this._config.zoomHandler.getContext().zoom !== options.zoom || this._canceled) {
      return;
    }
    this.removeCanvas();
    this.replaceViewport(canvas, left, top);
  }


  private updateLayoutSizes({ width, height }: DrawingsSizeParameters): void {
    this._defaultCanvasRef.style.width = `${width}px`;
    this._defaultCanvasRef.style.height = `${height}px`;
    this._mainLayoutRef.style.minWidth = `${width}px`;
    this._mainLayoutRef.style.minHeight = `${height}px`;
  }

  private shouldApplyZoom(
    scrollLayoutSize: DrawingsSizeParameters,
    mainLayoutSize: DrawingsSizeParameters,
    zoomCoefficient: number,
  ): boolean {
    const sameSizes = scrollLayoutSize.width === mainLayoutSize.width
      && scrollLayoutSize.height === mainLayoutSize.height;
    return !sameSizes || zoomCoefficient >= DEFAULT_ZOOM || !this._config.restrictZoom;
  }

  private getNewCanvasSize(
    pageSize: DrawingsSizeParameters,
    newZoom: number,
  ): DrawingsSizeParameters {
    return {
      width: pageSize.width * newZoom,
      height: pageSize.height * newZoom,
    };
  }

  private getMainLayoutSizes(
    mainLayoutSize: DrawingsSizeParameters,
    canvasSize: DrawingsSizeParameters,
    scrollLayoutSize: DOMRect,
    zoomCoefficient: number,
  ): DrawingsSizeParameters {
    if (canvasSize.width <= scrollLayoutSize.width && canvasSize.height <= scrollLayoutSize.height) {
      return scrollLayoutSize;
    } else {
      const width = mathUtils.clamp(
        mainLayoutSize.width * zoomCoefficient,
        scrollLayoutSize.width,
        Infinity,
      );
      const height = mathUtils.clamp(
        mainLayoutSize.height * zoomCoefficient,
        scrollLayoutSize.height,
        Infinity,
      );

      return { width, height };
    }
  }

  private getNewScrollPositions(
    mouseX: number,
    mouseY: number,
    { left, top, width, height }: DOMRect,
    newZoom: number,
  ): { scrollLeft: number, scrollTop: number } {
    const layoutMouseX = mathUtils.clamp(mouseX - left, 0, width);
    const layoutMouseY = mathUtils.clamp(mouseY - top, 0, height);
    const newLayoutMouseX =
      ((layoutMouseX + this._scrollLayoutRef.scrollLeft) / this._config.zoomHandler.getContext().zoom) * newZoom;
    const newLayoutMouseY =
      ((layoutMouseY + this._scrollLayoutRef.scrollTop) / this._config.zoomHandler.getContext().zoom) * newZoom;
    return {
      scrollLeft: newLayoutMouseX - layoutMouseX,
      scrollTop: newLayoutMouseY - layoutMouseY,
    };
  }

  @autobind
  private applyRotationForCurrentDrawing(
    canvas: HTMLCanvasElement,
    drawingId: string,
    rotation: number,
  ): Promise<boolean> {
    this.saveMainCanvas(canvas, drawingId, true);
    this.removeCanvas();
    const currentDrawingId = this._config.getCurrentDrawingId();
    if (currentDrawingId !== drawingId) {
      return Promise.resolve(false);
    }
    this.applyRotationToLayout(rotation);
    return this.executeScrollToInstanceIdOrScroll();
  }

  @autobind
  private executeScrollToInstanceIdOrScroll(): Promise<boolean> {
    if (this.boundsToScroll) {
      return this.scrollToDrawingInstances({ ...this._boundToScroll, onlyScroll: false });
    } else {
      this.onChangeViewport();
      return Promise.resolve(true);
    }
  }
}

