import { ElementTooltip, IconButton, Icons, Text } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { ContextMenuUtils } from 'common/components/item-context-menu';
import { Item } from 'common/components/item-context-menu/menu-item-container';
import { ConstantFunctions } from 'common/constants/functions';
import { ProjectsFolder } from '../../../projects/interfaces/projects-folder';
import { Styled } from './styled';

interface Props {
  folder: ProjectsFolder;
  onClick: (folder: ProjectsFolder) => void;
  editFolder: (folder: ProjectsFolder) => void;
  deleteFolder: (id: number) => void;
  moveFolder: (folder: ProjectsFolder) => void;
}


export const FolderListItem: React.FC<Props> = ({ folder, onClick, deleteFolder, editFolder, moveFolder }) => {
  const deleteHandleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    deleteFolder(folder.id);
  }, [folder, deleteFolder]);

  const renameFolder = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    editFolder(folder);
  }, [folder, editFolder]);

  const moveHandleFolder = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    ConstantFunctions.stopEvent(e);
    moveFolder(folder);
  }, [folder, moveFolder]);

  const menuItems: Item[] = useMemo(() => {
    return [
      { text: 'Edit', icon: Icons.Edit2D, onClick: (e) => renameFolder(e) },
      { text: 'Move to', icon: Icons.MoveToGroup_1, onClick: (e) => moveHandleFolder(e) },
      { text: 'Delete', icon: Icons.Delete, onClick: (e) => deleteHandleClick(e) },
    ];
  }, [deleteHandleClick, moveHandleFolder, renameFolder]);

  const onHandleClick = useCallback(() => {
    onClick(folder);
  }, [folder, onClick]);

  const onOpenContextMenuClick = ContextMenuUtils.useOpenContextMenu(() => menuItems);
  const onOpenContextMenuButtonClick = ContextMenuUtils.useOpenContextMenuButton(() => menuItems);

  const onButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    ConstantFunctions.stopEvent(e);
    onOpenContextMenuButtonClick(e);
  }, [onOpenContextMenuButtonClick]);

  return (
    <>
      <Styled.Folder onClick={onHandleClick} onContextMenu={onOpenContextMenuClick}>
        <Icons.Group_2 height={20} width={20}/>
        <ElementTooltip
          text={folder.name}
          position='top'
          speed='l'
          disabled={folder.name.length < 20}
          wordBreakAll={true}
          tooltipOverflow='hidden'
        >
          <Text fontSize={14} withEllipsis={true}>{folder.name}</Text>
        </ElementTooltip>
        <Styled.Button>
          <IconButton
            Icon={Icons.Menu}
            width={20}
            height={20}
            onClick={onButtonClick}
          />
        </Styled.Button>
      </Styled.Folder>
    </>
  );
};
