import React from 'react';

import { useWizzardSetting } from 'common/components/drawings/hooks';
import { WizzardToolsFullState } from 'common/components/drawings/interfaces';
import { SettingItem, SettingItemTutorial } from '../setting-item/setting-item';

interface Props {
  disabled?: boolean;
  settingKey: keyof WizzardToolsFullState;
  label: string;
  tutorial: SettingItemTutorial;
}

export const SettingsItemWizzard: React.FC<Props> = ({
  settingKey,
  label,
  tutorial,
  disabled,
}) => {
  const [ value, setValue] = useWizzardSetting(settingKey) as [boolean, (value: boolean) => void];

  return (
    <SettingItem
      label={label}
      checked={value}
      disabled={disabled}
      tutorial={tutorial}
      onChange={setValue}
    />
  );
};
