import styled from 'styled-components';


const Container = styled.div`
  display: flex;
  gap: 30px;
`;

export const Styled = {
  Container,
};
