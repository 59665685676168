import { Icons, SvgComponent } from '@kreo/kreo-ui-components';
import React from 'react';
import { TransformMenu } from '../transform-menu';
import { FlyingMenuActionKeys } from './action-keys';
import { AvailabilityKeys } from './availability-keys';

interface Item {
  tooltip: string;
  actionKey: FlyingMenuActionKeys;
  availability: AvailabilityKeys[];
  icon: SvgComponent;
  customComponent?: React.ComponentType<any>;
}


export const MENU_ITEMS: Item[] = [
  {
    tooltip: `Use AI-powered search to find areas similar in meaning to those you have selected.
      You can analyze up to four different polygons simultaneously.`,
    actionKey: FlyingMenuActionKeys.MagicSearch,
    availability: [AvailabilityKeys.CanMagicSearch, AvailabilityKeys.EditMeasurements],
    icon: Icons.MagicSearch2D,
  },
  {
    tooltip: 'Transform',
    actionKey: FlyingMenuActionKeys.TransformMenu,
    availability: [AvailabilityKeys.EditMeasurements],
    icon: Icons.FlipHorizontally,
    customComponent: TransformMenu,
  },
  {
    tooltip: 'Modify',
    actionKey: FlyingMenuActionKeys.Modify,
    availability: [AvailabilityKeys.EditMeasurements, AvailabilityKeys.OnlyOneSelected, AvailabilityKeys.Rendered],
    icon: Icons.Modify2D,
  },
  {
    tooltip: 'Move To',
    actionKey: FlyingMenuActionKeys.MoveTo,
    availability: [AvailabilityKeys.EditMeasurements],
    icon: Icons.MoveToGroup_1,
  },
  {
    tooltip: 'Knife',
    actionKey: FlyingMenuActionKeys.Knife,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.OnlyOneSelected,
      AvailabilityKeys.NotCount,
      AvailabilityKeys.NotIntersectedAtStart,
    ],
    icon: Icons.Knife,
  },
  {
    tooltip: 'Offset',
    actionKey: FlyingMenuActionKeys.Offset,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.OnlyOneSelected,
      AvailabilityKeys.NotCount,
      AvailabilityKeys.NotIntersectedAtStart,
    ],
    icon: Icons.Offset,
  },
  {
    tooltip: 'Merge',
    actionKey: FlyingMenuActionKeys.Merge,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsArea,
      AvailabilityKeys.CanBoolWithPolygons,
    ],
    icon: Icons.Union,
  },
  {
    tooltip: 'Subtract',
    actionKey: FlyingMenuActionKeys.Subtract,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsArea,
      AvailabilityKeys.CanBoolWithPolygons,
    ],
    icon: Icons.Subtract,
  },
  {
    tooltip: 'Enclose',
    actionKey: FlyingMenuActionKeys.Enclose,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsLine,
    ],
    icon: Icons.Enclose,
  },
  {
    tooltip: 'Extract polyline from polygon',
    actionKey: FlyingMenuActionKeys.OpenPolygon,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsArea,
    ],
    icon: Icons.ConvertToPolylines,
  },
  {
    tooltip: 'Split polyline into segments',
    actionKey: FlyingMenuActionKeys.SplitPolyline,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsLine,
    ],
    icon: Icons.PolylineToSegments,
  },
  {
    tooltip: 'Join',
    actionKey: FlyingMenuActionKeys.JoinCount,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsCount,
      AvailabilityKeys.CanJoin,
    ],
    icon: Icons.CountJoin,
  },
  {
    tooltip: 'Join',
    actionKey: FlyingMenuActionKeys.JoinLine,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.IsLine,
      AvailabilityKeys.CanJoin,
    ],
    icon: Icons.JoinPath,
  },
  {
    tooltip: 'Group',
    actionKey: FlyingMenuActionKeys.Group,
    availability: [
      AvailabilityKeys.EditMeasurements,
      AvailabilityKeys.MoreThanOneSelected,
    ],
    icon: Icons.NewGroup2d,
  },
  {
    tooltip: 'Trace Link',
    actionKey: FlyingMenuActionKeys.TraceLink,
    availability: [
      AvailabilityKeys.CanTraceLink,
    ],
    icon: Icons.Tracelinks2D,
  },
];
