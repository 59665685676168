import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React from 'react';

interface Props {
  openAddPaymentDialog: () => void;
}

const AddPaymentMethodButtonComponent: React.FC<Props> = ({
  openAddPaymentDialog,
}) => {
  return (
    <RectangleButton
      Icon={Icons.AddPayment}
      text={'Add payment method'}
      width={210}
      height={50}
      borderRadius={25}
      fontSize={14}
      mood={'secondary'}
      onClick={openAddPaymentDialog}
      dataControlName='add-payment-method'
    />
  );
};

export const AddPaymentMethodButton = React.memo(AddPaymentMethodButtonComponent);
