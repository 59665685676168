import autobind from 'autobind-decorator';
import { debounce } from 'lodash';
import { getDefaultPrecompiledFormulaApi, PrecompiledFormulaApi } from '2d/helpers';
import { isPropertyBreakDownProperty } from '2d/helpers/calculate-pia-assignment/utils';
import { AssignPiaPatch, AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup, DrawingsInstanceMeasure } from 'common/components/drawings';
import { DrawingsGeometryRenameInstance } from 'common/components/drawings/actions/payloads/annotation';
import { DrawingsEditFileTreeItem } from 'common/components/drawings/actions/payloads/common';
import { DrawingsGroupUtils, ParentToChildMap } from 'common/components/drawings/utils/drawings-group-utils';
import { arrayUtils } from 'common/utils/array-utils';
import { calcAssign } from 'unit-2d-database/components/side-panel/components/measure-panel/helpers';
import { TwoDElementViewState } from '../../store-slice';
import { extendDataByEmptyGroup, removeEmptyData } from '../extends-by-group';
import { splitMap } from '../split-map';
import { GroupTransaction } from './group-transaction';
import { TransactionHelpers } from './helpers';
import {
  AssignInfoGetter,
  AssignNameInfo,
  DrawingInfoGetter,
  HandleMoveElementPayload,
  HandleMoveGroupPayload,
  UpdateTable,
} from './interfaces';

export class Transaction extends GroupTransaction {
  private execUpdateTableDebounce: () => void;
  private setApplyState: (value: boolean) => void;
  private updateTable: UpdateTable = null;

  private drawingFileToElementMap: Record<string, Set<string>> = {};
  private drawingPageToElementMap: Record<string, Set<string>> = {};

  private elementTotalAssign: Record<string, AssignedPia> = {};

  private formulaCache: PrecompiledFormulaApi = null;

  private addToExec: Record<string, any> = {};
  private removeToExec: any[] = [];
  private updateToExec: Record<string, any> = {};

  public constructor(getDrawingInfo: DrawingInfoGetter, getAssignInfo: AssignInfoGetter) {
    super(getDrawingInfo, getAssignInfo);
    this.formulaCache = getDefaultPrecompiledFormulaApi();
    this.execUpdateTableDebounce = debounce(this.execUpdateTable, 50);
  }

  public saveUpdate(updateTable: UpdateTable, setApplyState: (value: boolean) => void, isPivot: () => boolean): void {
    this.updateTable = updateTable;
    this.setApplyState = setApplyState;
    this.isPivot = isPivot;
    this.setInitData();
  }

  public getEventHandlers(): TwoDElementViewState {
    return {
      handleCreateDrawingElement: this.handleCreateElement,
      handleRemoveDrawingElement: this.handleRemoveDrawingElement,
      handleRenameDrawingElement: this.handleRenameElement,
      handleRenameFile: this.handleRenameFile,
      handleRenamePage: this.handleRenamePage,
      handleRenameGroup: this.handleRenameGroup,
      handleSaveElementMeasurements: this.handleSaveElementMeasurement,
      handleChangeAssign: this.handleChangeAssign,
      handleToggleShowUnit: this.handleToggleShowUnit,
      handleToggleShowCode: this.handleChangeShowCode,
      handleMoveGroup: this.handleMoveGroupTransaction,
      changeColumnAfterAssign: this.changeColumnAfterAssign,
      handleCreateGroup: this.handleCreateGroup,
      handleDeleteGroup: this.handleDeleteGroup,
      handleMoveElement: this.handleMoveElement,
      updateColumnBeforeDelete: this.updateColumnBeforeDeleteDrawingElement,
      handlePiaDataLoaded: this.handlePiaDataLoaded,
      handleChangeIsImperial: this.handleChangeIsImperial,
      handleUpdateDatabase: this.handleUpdateDatabase,
    };
  }

  public changePivotMode(): void {
    this.onPivotModeChange();
    if (this.isPivot) {
      if (this.isPivot()) {
        const updateMap =extendDataByEmptyGroup(this.sourceData, this.fieldColDef);
        this.update = Object.values(updateMap);
      } else {
        const updateMap = removeEmptyData(this.sourceData, this.fieldColDef);
        this.update = Object.values(updateMap);
      }
    }

    this.applyTransactions();
  }

  @autobind
  protected handleUpdateDatabase(): void {
    this.updateBBColumn();
  }

  @autobind
  protected handleChangeIsImperial(): void {
    if (this.setApplyState) {
      this.setApplyState(true);
    }
    const drawingElementIds = Object.keys(this.drawingElementRows);
    splitMap(
      10,
      drawingElementIds,
      (drawingElementId) => {
        this.handleChangeElementAssign(drawingElementId);
      },
    ).then(() => {
      this.applyTransactions();
    });
  }

  @autobind
  protected handleCreateElement(drawingElementIds: string[]): void {
    if (this.setApplyState) {
      this.setApplyState(true);
    }
    splitMap(
      10,
      drawingElementIds,
      (drawingElementId) => {
        if (this.drawingElementRows[drawingElementId]) {
          return;
        }
        const transaction = this.getAddRowTransaction(drawingElementId);
        if (!transaction) {
          return;
        }
        const { getInstancesMeasures } = this.getDrawingInfo();
        getInstancesMeasures([drawingElementId]);
        this.add.push(...transaction);
      },
    ).then(() => {
      this.applyTransactions();
    });
  }

  @autobind
  protected handleMoveGroupTransaction(payload: Record<string, HandleMoveGroupPayload[]>): void {
    this.handleMoveGroup(payload);
    const { groups } = this.getDrawingInfo();
    const groupsChildrenMap = DrawingsGroupUtils.getParentToChildMap(groups);
    for (const data of Object.values(payload)) {
      for (const group of data) {
        const elementIds = this.getElementIds(group.id, groupsChildrenMap);
        for (const elementId of elementIds) {
          this.handleChangeElementAssign(elementId);
        }
      }
    }
    this.applyTransactions();
  }

  @autobind
  protected handleRemoveDrawingElement(drawingElementIds: string[]): void {
    for (const drawingElementId of drawingElementIds) {
      const transaction = this.getRemoveRowTransaction(drawingElementId);
      if (transaction === null) {
        continue;
      }
      this.remove.push(...transaction);
    }
    super.removeMetaInfo(new Set(drawingElementIds));

    this.applyTransactions();
  }

  @autobind
  protected handleRenameElement(payload: DrawingsGeometryRenameInstance): void {
    const transaction = this.getRenameElementTransaction(payload.instanceId, payload.name);
    if (transaction === null) {
      return;
    }
    this.update.push(...transaction);
    this.applyTransactions();
  }

  @autobind
  protected handleRenameFile(payload: DrawingsEditFileTreeItem): void {
    const transaction = this.getRenameFileTransaction(payload.id, payload.name);
    if (transaction === null) {
      return;
    }
    this.update.push(...transaction);
    this.applyTransactions();
  }

  @autobind
  protected handleRenamePage(pageId: string, name: string): void {
    const transaction = this.getRenamePageTransaction(pageId, name);
    if (transaction === null) {
      return;
    }
    this.update.push(...transaction);
    this.applyTransactions();
  }

  @autobind
  protected handleRenameGroup(groupId: string, name: string): void {
    const transaction = this.getRenameGroupTransaction(groupId, name);
    if (transaction === null) {
      return;
    }
    this.update.push(...transaction);
    this.applyTransactions();
  }


  @autobind
  protected handlePiaDataLoaded(piaData: Record<string, AssignedPia>): void {
    if (this.setApplyState) {
      this.setApplyState(true);
    }
    splitMap(10, Object.keys(piaData), (id) => {
      this.handleChangeElementAssign(id);
    }).then(() => {
      this.applyTransactions();
    });
  }

  @autobind
  protected handleSaveElementMeasurement(measures: DrawingsInstanceMeasure[]): void {
    if (this.setApplyState) {
      this.setApplyState(true);
    }
    splitMap(10, measures, (measure) => {
      const elementId = measure.id;
      if (Array.isArray(elementId)) {
        return;
      }
      let assign = this.elementTotalAssign[elementId];
      if (!assign) {
        this.getAssignNameInfo(elementId);
        assign = this.elementTotalAssign[elementId];
        if (!assign) {
          return;
        }
      }
      this.setPiaPatch(elementId, assign, measure);
    }).then(() => {
      this.applyTransactions();
    });
  }

  @autobind
  protected handleChangeAssign(assignPatch: AssignPiaPatch[]): void {
    const { groups } = this.getDrawingInfo();
    const groupsChildrenMap = DrawingsGroupUtils.getParentToChildMap(groups);
    for (const patch of assignPatch.reverse()) {
      for (const id of patch.ids) {
        const elementIds = this.getElementIds(id, groupsChildrenMap);
        for (const elementId of elementIds) {
          this.handleChangeElementAssign(elementId);
        }
        this.applyTransactions();
      }
    }
  }

  @autobind
  protected changeColumnAfterAssign(assignPatch: AssignPiaPatch[]): void {
    for (const patch of assignPatch.reverse()) {
      this.updateColumnsNewProperty(patch);
    }
  }

  @autobind
  protected handleToggleShowUnit(): void {
    for (const id of this.rowIds) {
      const rowData = this.sourceData[id];
      rowData.withUnit = !rowData.withUnit;
      this.update.push(rowData);
    }
    this.applyTransactions();
  }

  @autobind
  protected updateColumnBeforeDeleteDrawingElement(groupsIds: string[]): void {
    for (const id of groupsIds) {
      const { drawingElementAssign } = this.getCommonAssignInfo();
      const assign = drawingElementAssign[id];
      if (assign) {
        this.updateColumnsNewProperty({
          ids: [id],
          deletedAssemblies: assign.assemblies?.map(a => a.name),
          deletedItems: assign.items?.map(i => i.name),
        });
      }
    }
    this.applyTransactions();
  }

  @autobind
  protected handleCreateGroup(groupsByParent: Record<string, DrawingsGeometryGroup[]>): void {
    super.handleCreateGroup(groupsByParent);
    this.applyTransactions();
  }

  @autobind
  protected handleDeleteGroup(ids: Set<string>): void {
    super.handleDeleteGroup(ids);
    const { groups } = this.getDrawingInfo();
    const groupsChildrenMap = DrawingsGroupUtils.getParentToChildMap(groups);
    for (const id of ids) {
      const elementIds = this.getElementIds(id, groupsChildrenMap);
      for (const elementId of elementIds) {
        this.handleChangeElementAssign(elementId);
      }
      this.applyTransactions();
    }
  }

  @autobind
  protected handleMoveElement(data: HandleMoveElementPayload): void {
    super.handleMoveElement(data);
    for (const elementId of data.measurements) {
      this.handleChangeElementAssign(elementId);
    }
    this.applyTransactions();
  }

  @autobind
  protected handleChangeShowCode(isShow: boolean): void {
    super.handleChangeShowCode(isShow);
    this.applyTransactions();
  }

  @autobind
  private applyTransactions(): void {
    this.add.forEach(a => {
      this.sourceData[a.id] = a;
      this.rowIds.add(a.id);
      this.setDrawingElementRowIds(a);
      this.setDrawingFileToElement(a);
      this.setDrawingPageToElement(a);
      this.addToExec[a.id] = a;
    });
    this.remove.forEach(r => {
      delete this.sourceData[r.id];
      this.rowIds.delete(r.id);
      this.removeDrawingElementRowIds(r);
      this.removeDrawingFileToElement(r);
      this.removeDrawingPageToElement(r);
      this.removeToExec.push(r);
    });
    this.update.forEach(u => {
      this.updateToExec[u.id] = u;
    });
    this.add = [];
    this.remove = [];
    this.update = [];
    this.execUpdateTableDebounce();
  }

  @autobind
  private setInitData(): void {
    this.add = [];
    this.update = [];
    this.remove = [];
    this.initColumnState();
    if (!Object.keys(this.addToExec).length) {
      const columns = this.getUpdatedColumns();
      this.updateTable({
        add: Object.values(this.sourceData),
      }, columns, true);
    } else {
      this.execUpdateTable(true);
    }
  }

  @autobind
  private getElementIds(id: string, groupsChildrenMap: ParentToChildMap): string[] {
    const { groupsMap } = this.getDrawingInfo();
    if (groupsMap[id]) {
      const measurements = DrawingsGroupUtils.getAllInnerMeasurements(
        groupsMap[id],
        groupsChildrenMap,
        gr => !!gr);
      return measurements;
    } else {
      return [id];
    }
  }

  @autobind
  private handleChangeElementAssign(elementId: string): void {
    const commonAssignInfo = this.getCommonAssignInfo();
    const measure = commonAssignInfo.elementMeasurement[elementId];
    if (!measure) {
      return;
    }
    const assignNameInfo = this.getAssignNameInfo(elementId);
    const totalElementAssign = this.elementTotalAssign[elementId];

    const ids = TransactionHelpers.getRowsIds(elementId, assignNameInfo);
    const hasRows = ids && ids.length;
    if (hasRows) {
      const addPatches = this.createAddTransaction(ids);
      const pageName = this.getPageName(elementId);
      const dataList = TransactionHelpers.getAssignRowData(
        this.formulaCache,
        totalElementAssign,
        measure,
        commonAssignInfo,
        this.breakdownPropertyExtender,
        pageName,
      );

      const addPatchesMap = {};
      if (addPatches) {
        addPatches.forEach(p => addPatchesMap[p.id] = p);
      }

      for (const data of dataList) {
        const rowId = `${elementId}_${data.assignId}`;
        const addedPatch = addPatchesMap[rowId];
        if (addedPatch) {
          delete data.assignId;
          for (const key of Object.keys(data)) {
            addedPatch[key] = data[key];
          }
        } else {
          const rowData = this.sourceData[rowId];
          delete data.assignId;
          for (const key of Object.keys(rowData)) {
            const [, BBLevel] = isPropertyBreakDownProperty(key);
            if (BBLevel !== undefined) {
              delete rowData[key];
            }
          }
          for (const key of Object.keys(data)) {
            rowData[key] = data[key];
          }
          this.update.push(rowData);
        }
      }

      if (addPatches) {
        this.add = addPatches.concat(this.add);
      }
    }

    const existRows = this.drawingElementRows[elementId];
    if (existRows) {
      const rowSet = new Set(ids);
      for (const row of existRows) {
        if (!hasRows || !rowSet.has(row)) {
          this.remove.push(this.sourceData[row]);
        }
      }
    }
  }

  @autobind
  private setPiaPatch(elementId: string, assign: AssignedPia, measure: DrawingsInstanceMeasure): void {
    let transactions = [];

    const commonAssignInfo = this.getCommonAssignInfo();
    const pageName = this.getPageName(elementId);
    const dataList = TransactionHelpers.getAssignRowData(
      this.formulaCache,
      assign,
      measure,
      commonAssignInfo,
      this.breakdownPropertyExtender,
      pageName,
    );
    for (const data of dataList) {
      const assignId = data.assignId;
      const rowId = `${elementId}_${assignId}`;
      delete data.assignId;
      if (this.sourceData[rowId]) {
        for (const key of Object.keys(data)) {
          this.sourceData[rowId][key] = data[key];
        }
        this.update.push(this.sourceData[rowId]);
      } else {
        if (!transactions.length) {
          transactions = this.getAddRowTransaction(elementId);
        }
        if (transactions) {
          const transaction = transactions.find(t => t.id === rowId);
          for (const key of Object.keys(data)) {
            transaction[key] = data[key];
          }
          this.add.push(transaction);
        }
      }
    }
  }

  @autobind
  private execUpdateTable(isFirst?: boolean): void {
    if (this.updateTable) {
      const update = [];
      for (const updateToExec of Object.values(this.updateToExec)) {
        if (this.addToExec[updateToExec.id]) {
          this.addToExec[updateToExec.id] = updateToExec;
        } else if (!this.removeToExec.find(r => r.id === updateToExec.id)) {
          update.push(updateToExec);
        }
      }
      const columns = this.getUpdatedColumns();
      const add = Object.values(this.addToExec);
      const remove = Object.values(this.removeToExec);
      if (add.length || remove.length || update.length || columns?.length) {
        this.updateTable(
          {
            add,
            update,
            remove,
          },
          columns,
          isFirst,
        );
      } else {
        this.setApplyState(false);
      }

      this.addToExec = {};
      this.updateToExec = {};
      this.removeToExec = [];
    }
  }

  @autobind
  private setDrawingElementRowIds(element: any): void {
    if (this.drawingElementRows[element.measureId]) {
      this.drawingElementRows[element.measureId].add(element.id);
    } else {
      this.drawingElementRows[element.measureId] = new Set([element.id]);
    }
  }

  private getPageName(elementId: string): string {
    const { aiAnnotation, drawingsInfo } = this.getDrawingInfo();
    const measureValue = aiAnnotation.geometry[elementId];
    if (measureValue) {
      const drawingInfo = drawingsInfo[measureValue.drawingId];
      return drawingInfo.name;
    }
  }

  @autobind
  private setDrawingFileToElement(element: any): void {
    if (this.drawingFileToElementMap[element.fileId]) {
      this.drawingFileToElementMap[element.fileId].add(element.measureId);
    } else {
      this.drawingFileToElementMap[element.fileId] = new Set([element.measureId]);
    }
  }

  @autobind
  private setDrawingPageToElement(element: any): void {
    if (this.drawingPageToElementMap[element.pageId]) {
      this.drawingPageToElementMap[element.pageId].add(element.measureId);
    } else {
      this.drawingPageToElementMap[element.pageId] = new Set([element.measureId]);
    }
  }

  @autobind
  private removeDrawingElementRowIds(element: any): void {
    delete this.drawingElementRows[element.measureId];
  }

  @autobind
  private removeDrawingFileToElement(element: any): void {
    if (this.drawingFileToElementMap[element.fileId]) {
      this.drawingFileToElementMap[element.fileId].delete(element.id);
    }
  }

  @autobind
  private removeDrawingPageToElement(element: any): void {
    if (this.drawingPageToElementMap[element.pageId]) {
      this.drawingPageToElementMap[element.pageId].delete(element.id);
    }
  }

  @autobind
  private getAddRowTransaction(drawingElementId: string): any[] {
    const assign = this.getAssignNameInfo(drawingElementId);
    const ids = TransactionHelpers.getRowsIds(drawingElementId, assign);
    return this.createAddTransaction(ids);
  }

  @autobind
  private createAddTransaction(ids: string[]): any[] {
    if (!ids) {
      return null;
    }
    const patches = [];

    for (const id of ids) {
      if (!this.isRowExist(id)) {
        const rowData = this.createAddPatch(id);
        patches.push(rowData);
      }
    }

    if (!patches.length) {
      return null;
    }

    return patches;
  }

  @autobind
  private getRemoveRowTransaction(drawingElementId: string): any[] {
    const ids = this.getRowsIdsByDrawingElementId(drawingElementId);
    if (ids === null) {
      return null;
    }
    const patches = [];

    for (const id of ids) {
      if (this.isRowExist(id)) {
        const rowData = this.createRemovePatch(id);
        patches.push(rowData);
      }
    }

    if (!patches.length) {
      return null;
    }

    return patches;
  }

  @autobind
  private getRenameElementTransaction(elementId: string, newName: string): any[] | null {
    const ids = this.getRowsIdsByDrawingElementId(elementId);
    if (ids === null) {
      return null;
    }

    const patches = arrayUtils.filterMap(
      ids,
      this.isRowExist,
      (id) => this.createRenameElementUpdate(id, newName),
    );

    if (!patches.length) {
      return null;
    }

    return patches;
  }

  @autobind
  private getRenameFileTransaction(fileId: string, name: string): any[] | null {
    const rowIds = this.getRowIdsByFileId(fileId);

    if (rowIds === null) {
      return null;
    }

    const patches = arrayUtils.filterMap(
      rowIds,
      this.isRowExist,
      (id) => this.createRenameFileTransaction(id, name),
    );
    if (!patches.length) {
      return null;
    }

    return patches;
  }

  @autobind
  private getRenamePageTransaction(pageId: string, name: string): any[] | null {
    const rowIds = this.getRowIdsByPageId(pageId);

    if (rowIds === null) {
      return null;
    }

    const patches = arrayUtils.filterMap(
      rowIds,
      this.isRowExist,
      (id) => this.createRenamePageTransaction(id, name),
    );
    if (!patches.length) {
      return null;
    }

    return patches;
  }

  @autobind
  private getRenameGroupTransaction(groupId: string, name: string): any[] | null {
    const groupMetaInfo = this.groupMap[groupId];
    if (!groupMetaInfo) {
      return null;
    }
    const patch = [];
    for (const metaData of groupMetaInfo) {
      const source = this.sourceData[metaData.rowId];
      if (!source) {
        continue;
      }
      metaData.fields.forEach(field => {
        source[field] = name;
      });
      patch.push(source);
    }

    if (!patch.length) {
      return null;
    }

    return patch;
  }

  @autobind
  private getRowIdsByFileId(fileId: string): string[] | null {
    const drawingElementIds = this.getDrawingElementByFileId(fileId);
    if (drawingElementIds === null) {
      return null;
    }

    let rowIds = [];
    drawingElementIds.forEach(id => {
      const rowsWithDrawing = this.drawingElementRows[id];
      if (rowsWithDrawing && rowsWithDrawing.size) {
        rowIds = rowIds.concat(...rowsWithDrawing);
      }
    });
    if (!rowIds.length) {
      return null;
    }

    return rowIds;
  }

  @autobind
  private getRowIdsByPageId(pageId: string): string[] | null {
    const drawingElementIds = this.getDrawingElementByPageId(pageId);
    if (drawingElementIds === null) {
      return null;
    }

    let rowIds = [];
    drawingElementIds.forEach(id => {
      const rowsWithDrawing = this.drawingElementRows[id];
      if (rowsWithDrawing && rowsWithDrawing.size) {
        rowIds = rowIds.concat(...rowsWithDrawing);
      }
    });
    if (!rowIds.length) {
      return null;
    }

    return rowIds;
  }

  @autobind
  private createRenameFileTransaction(rowId: string, fileName: string): any {
    const source = this.sourceData[rowId];
    source.fileName = fileName;
    return source;
  }

  @autobind
  private createRenamePageTransaction(rowId: string, pageName: string): any {
    const source = this.sourceData[rowId];
    source.pageName = pageName;
    return source;
  }

  @autobind
  private getDrawingElementByFileId(fileId: string): Set<string> | null {
    const elementIds = this.drawingFileToElementMap[fileId];
    if (!elementIds || !elementIds.size) {
      return null;
    }
    return elementIds;
  }

  @autobind
  private getDrawingElementByPageId(pageId: string): Set<string> | null {
    const elementIds = this.drawingPageToElementMap[pageId];
    if (!elementIds || !elementIds.size) {
      return null;
    }
    return elementIds;
  }

  @autobind
  private createRenameElementUpdate(rowId: string, drawingElementName: string): void {
    const source = this.sourceData[rowId];
    source.measureName = drawingElementName;
    return source;
  }

  @autobind
  private createRemovePatch(id: string): any {
    const rowData = this.sourceData[id];
    return rowData;
  }

  @autobind
  private createAddPatch(id: string): any {
    const [drawingElementId] = TransactionHelpers.getRowIdParts(id);
    const drawingInfo = this.getDrawingInfo();
    const drawingElementInfo = TransactionHelpers.getDrawingElementInfo(drawingElementId, drawingInfo);
    const groupInfo = this.createGroupInfo(drawingElementId, id);

    const rowData = {
      id,
      ...drawingElementInfo,
      ...groupInfo,
    };

    return rowData;
  }

  @autobind
  private isRowExist(rowId: string): boolean {
    return this.rowIds.has(rowId);
  }

  @autobind
  private getCalcAssign(
    measureId: string,
  ): AssignedPia {
    const { drawingElementAssign } = this.getCommonAssignInfo();
    const { aiAnnotation } = this.getDrawingInfo();
    const measure = aiAnnotation.geometry[measureId];
    const measureAssign = drawingElementAssign[measureId] ? [drawingElementAssign[measureId]] : [];
    if (!measure) {
      return {
        items: [],
        assemblies: [],
      };
    }
    const groupsAssign = measure.groupId
      ? [...this.getParentAssign(measure.groupId), ...measureAssign]
      : [...measureAssign];

    return calcAssign(groupsAssign);
  }

  @autobind
  private getAssignNameInfo(drawingElementId: string): AssignNameInfo {
    const assign = this.getCalcAssign(drawingElementId);
    this.elementTotalAssign[drawingElementId] = JSON.parse(JSON.stringify(assign));
    const itemsNames = [];
    for (const item of assign.items) {
      itemsNames.push(item.name);
    }
    const assemblies = {};
    for (const assembly of assign.assemblies) {
      assemblies[assembly.name] = [];
      for (const item of assembly.items) {
        assemblies[assembly.name].push(item.name);
      }
    }

    return {
      items: itemsNames,
      assembly: assemblies,
    };
  }

  @autobind
  private getParentAssign(
    groupId: string,
  ): AssignedPia[] {
    const { drawingElementAssign } = this.getCommonAssignInfo();
    const { groups } = this.getDrawingInfo();

    const assign = drawingElementAssign[groupId];
    const result = assign ? [assign] : [];

    const group = groups.find((g) => g.id === groupId);

    return group?.parentId ? [...this.getParentAssign(group.parentId), ...result] : result;
  }
}
