import styled from 'styled-components';


const Container = styled.div`
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  padding: 30px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${props => props.theme.color.background};
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  z-index: 1;

  > button {
    border-radius: 20px;
    background-color: ${p => p.theme.color.background};
    border: 1px solid ${props => props.theme.color.backgroundRush};
    > p {
      font-size: 14px;
    }
  }
`;

const ExtraText = styled.div`
  max-width: 705px;
  height: 30px;
  margin-bottom: 20px;
`;

export const Styled = {
  Container,
  HeaderContainer,
  ExtraText,
};
