import React from 'react';
import { useSelector } from 'react-redux';

import { Mood } from 'common/enums/mood';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { RemovableListState } from './removable-list-state';


const DislikedAreasComponent: React.FC = () => {
  const dislikedAreasCount = useSelector<State, number>(s => s.drawings.magicSearch.negativeContours.length);
  const reset = useActionDispatch(MagicSearchActions.resetNegativeContours);

  return (
    <RemovableListState
      removedText={`${dislikedAreasCount} disliked areas`}
      tooltipText={'You can dislike the area measurement to exclude it from search'}
      reset={reset}
      mood={dislikedAreasCount > 0 ? Mood.Secondary : Mood.Disabled}
    />
  );
};

export const DislikedAreas = React.memo(DislikedAreasComponent);
