import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Feedback } from 'common/components/feedback';
import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { RequestStatus } from 'common/enums/request-status';
import { State } from 'common/interfaces/state';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi } from '../../hooks';
import { useDPI } from '../magic-search-menu/hooks/use-dpi';
import { SimilaritySliderWithCount } from '../similarity-slider';
import { WizzardMainContainer } from '../wizzard-tools/wizzard-main-container';
import { DislikedAreas } from './disliked-areas';
import { RemovedResults } from './removed-results';
import { Styled } from './styled';
import { useSimilarity } from './use-similarity';

interface Props {
  query?: string;
}

const MagicSearchResultComponent: React.FC<Props> = ({ query }) => {
  const { rendererApi } = useRendererApiContext();
  const { setDrawMode, drawMode } = useDrawModeApi();
  const drawModeOnClose = useSelector<State, DrawingsDrawMode>(s => s.drawings.magicSearch.drawModeOnClose);
  const create = useCallback(() => {
    rendererApi?.engine.createInstances({ query });
    setDrawMode(drawModeOnClose || DrawingsDrawMode.Disabled, { ignoreCancelMessage: true });
  }, [ rendererApi, setDrawMode, drawModeOnClose, query ]);

  const { count, setSimilarity, similarity } = useSimilarity();
  const [ dpi ] = useDPI();
  const { sendEvent } = useAnalytics();

  const sendFeedback = useCallback((rating: number, message: string) => {
    const mode = drawMode === DrawingsDrawMode.MagicSearch ? 'Magic Search' : 'Auto Measure 2.0';
    const analitycsMessage: any = {
      mode,
      rating,
      message,
      dpi,
    };
    if (query) {
      analitycsMessage.query = query;
    }
    sendEvent(MetricNames.common.feedback, analitycsMessage);
  }, [drawMode, query, sendEvent, dpi]);

  const snappingStatus = useSelector<State, RequestStatus>(s => s.drawings.magicSearch.snappingStatus);

  const mood = useMemo(() => {
    if (snappingStatus === RequestStatus.Loading || !count) {
      return Mood.Disabled;
    }
    return Mood.Secondary;
  }, [snappingStatus, count]);

  return (
    <WizzardMainContainer>
      <SimilaritySliderWithCount
        count={count}
        minSimilarity={0}
        maxSimilarity={100}
        similarity={similarity}
        onSimilarityChange={setSimilarity}
        similarityText='AI Confidence'
      />
      <Styled.Button>
        <RectangleButton
          height={36}
          mood={mood}
          onClick={create}
          Icon={snappingStatus === RequestStatus.Loading ? Icons.Loader : Icons.PlusBig}
          text='Create'
        />
      </Styled.Button>
      <RenderIf condition={drawMode === DrawingsDrawMode.MagicSearch}>
        <DislikedAreas />
      </RenderIf>
      <RemovedResults />
      <Feedback sendFeedback={sendFeedback}/>
    </WizzardMainContainer>
  );
};

export const MagicSearchResults = React.memo(MagicSearchResultComponent);
