import { PersistedStorageState, TwoDFullScreenMode } from 'persisted-storage/interfaces/state';

function selectTwoDFullScreenMode(storage: PersistedStorageState, projectId: string): TwoDFullScreenMode {
  return storage.projectTwoDFullScreenMode && projectId
    ? storage.projectTwoDFullScreenMode.find(m => m[0] === projectId)?.[1]
    : null;
}

export const PersistedStorageSelectors = {
  selectTwoDFullScreenMode,
};
