import { TinyText } from '@kreo/kreo-ui-components';
import React, { useMemo } from 'react';

import { useDownloadImage } from 'common/components/auth-image/use-download-image';
import { DrawingsApi } from 'common/components/drawings/api/drawings-api';
import { PdfPagePreview } from '../preview';
import { Styled } from './styled';

interface Props {
  created: boolean;
  pdfId: string;
  pageId: string;
  color: string;
  pageNumber: number;
}

export const PdfPagePreviewWithBubble: React.FC<Props> = ({ created, pdfId, color, pageNumber, pageId }) => {
  const src = useMemo(() => DrawingsApi.getScreenshotLink(pdfId, pageId), [pageId, pdfId]);
  const image = useDownloadImage(src);

  return (
    <Styled.Container>
      <PdfPagePreview
        created={created}
        pdfId={pdfId}
        pageId={pageId}
        zIndexSpinner={1}
      />
      <Styled.PageBubble color={color} isImage={!!image}>
        <TinyText color={'white'}>{pageNumber + 1}</TinyText>
      </Styled.PageBubble>
    </Styled.Container>
  );
};
