import { getMergeVerticalId, MERGE_HORIZONTAL_PREFIX } from '2d/units/excel-table-cell-formatter/common';
import { isColumnIdSpecialWord } from '../is-column-id-special-word';

export const isNeedUpdateKeyInRow = (
  key: string,
  rowKeys: Set<string>,
  isChangeStartFromFirstRow: boolean,
): boolean => {
  if (isColumnIdSpecialWord(key)) {
    return false;
  }
  if (key.startsWith(MERGE_HORIZONTAL_PREFIX) && isChangeStartFromFirstRow) {
    const verticalId = getMergeVerticalId(key.replace(MERGE_HORIZONTAL_PREFIX, ''));
    if (rowKeys.has(verticalId)) {
      return false;
    }
  }

  return true;
};
