import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, formValueSelector } from 'redux-form';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  DrawingsLeaveProjectConfirmationDialog,
  DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG,
} from 'common/components/drawings/dialogs';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ProjectType } from 'common/constants/project-type';
import { SubscriptionType } from 'common/constants/subscription';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ProjectAccessReason } from 'unit-projects/enums';
import { pushSearch } from '../../../actions/common';
import { CREATE_2D_PROJECT_DIALOG_NAME } from '../../../components/dialog';
import { CREATE_PROJECT } from '../../../constants/forms';
import { Company } from '../../../units/account/interfaces/company';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import { ProjectsHeader } from '../../2d-projects-header';
import { AccountActions } from '../../account/actions/creators';
import { PeopleActions } from '../../people/actions/actions';
import { UpdateInvitationToProjectParams } from '../../people/actions/payloads';
import { Person } from '../../people/interfaces/person';
import { ProjectsActions } from '../../projects/actions/creators/common';
import { ProjectsFolderActions } from '../../projects/actions/creators/projects-folder';
import {
  CompanyProjectHeadersFeedRequestPayload,
  DuplicateProjectPayload,
} from '../../projects/actions/payloads/common';
import { CompanyProjectHeader } from '../../projects/interfaces/company-project-header';
import { ProjectsFolder, ProjectsFolderFormData } from '../../projects/interfaces/projects-folder';
import { PROJECT_DUPLICATE_DIALOG, TwoDProjectDuplicateDialog } from '../2d-project-duplicate-dialog';
import {
  EditProjectNameDialog,
  PROJECT_NAME_EDIT_DIALOG_NAME,
} from '../2d-projects-name-edit-dialog';
import { ProjectsView } from './constants';
import { CreateFolderDialog, CREATE_FOLDER_DIALOG_NAME, FolderData, FolderList } from './folder-list';
import { InviteUsersDialog } from './invite-users-dialog';
import { MoveToFolderDialog, MOVE_TO_FOLDER_DIALOG_NAME } from './move-to-folder-dialog';
import { ProjectAccessFilterConstants } from './project-access-filter/constants';
import { ProjectFilter } from './project-access-filter/interfaces';
import { ProjectRemoveDialog, REMOVE_PROJECT_DIALOG_NAME } from './project-remove-dialog';
import { ProjectsRenderer } from './projects-renderer';
import { ProjectsTitle } from './projects-title';
import { Styled } from './styled';


const selector = formValueSelector(CREATE_PROJECT);

interface PageStateProps {
  projectHeaders: Record<number, CompanyProjectHeader>;
  searchQuery: string | null;
  allFetched: boolean;
  companyId: number;
  projectHeadersRequestStatus: RequestStatus;
  isDuplicateDialog: boolean;
  currentFolder: ProjectsFolder;
  projectStatus: RequestStatus;
  projectsAccessFilterName: string;
  isOpen: boolean;
  isSharedWithCompany: boolean;
  currentUserEmail: string;
  projectsViewType: ProjectsView;
  selectedCompany: Company;
  urlFolderId: number;
  folders: ProjectsFolder[];
  isFoldersLoaded: boolean;
}

interface PageDispatchProps {
  leaveProject: (projectId: number) => void;
  setProjectsAccessFilter: (filterName: string, projectType: ProjectType) => void;
  fetchCompanyProjectHeaders: (payload: CompanyProjectHeadersFeedRequestPayload) => void;
  showDialog: (name: string, data?: any) => void;
  closeDialog: (dialogName: string) => void;
  openSelfServePortal: () => void;
  removeProject: (projectId: number) => void;
  failureProjectIdSend: (projectId: number) => void;
  clearProjectHeaders: (companyId: number, type: ProjectType) => void;
  changeSearchQuery: (companyId: number, searchQuery: string | null, type: ProjectType) => void;
  changeProjectNameById: (name: string, id: number) => void;
  dropStore: () => void;
  updateInvitedPeoples: (payload: UpdateInvitationToProjectParams) => void;
  duplicateProject: (payload: DuplicateProjectPayload) => void;
  createFolder: (folder: ProjectsFolderFormData) => void;
  updateFolder: (folder: ProjectsFolder) => void;
  moveProjectToFolder: (projectId: number, folderId: number) => void;
  setCurrentFolder: (folder: ProjectsFolder) => void;
  saveProjectAsTemplate: (projectId: number) => void;
  setShareWithCompamy: (value: boolean) => void;
  changeProjectAccessReason: (projectHeader: CompanyProjectHeader, accessReason: ProjectAccessReason) => void;
  pushFolderToUrl: (folderId: number | null) => void;
  setShowInviteUsersPanel: (isOpen: boolean) => void;
}

interface OwnProps {
  projectType: ProjectType;
}

interface PageProps extends PageStateProps, PageDispatchProps, AbilityAwareProps, OwnProps { }

enum RemoveOperation {
  Leave,
  Delete,
}

interface PageState {
  projectToBeRemoved: CompanyProjectHeader | null;
  removeOperationType: RemoveOperation;
  projectToInviteUsers: CompanyProjectHeader | null;
  projectCreateStatus: ProjectCreateStatus;
  currentProjectId: number;
}

export const PROJECTS_PAGE_SIZE = 10;

class TwoDProjectsContentComponent extends React.Component<PageProps, PageState> {

  constructor(props: PageProps) {
    super(props);

    this.state = {
      projectToBeRemoved: null,
      projectToInviteUsers: null,
      projectCreateStatus: null,
      currentProjectId: null,
      removeOperationType: null,
    };
  }

  public componentWillUnmount(): void {
    this.props.dropStore();
  }

  public render(): React.ReactNode {
    const { projectHeaders, allFetched, searchQuery, projectType, ability, projectsViewType } = this.props;

    const loaded = this.props.projectHeadersRequestStatus === RequestStatus.Loaded;
    const is2dProjectType = projectType === ProjectType.Project2d;
    const canManageProjects = ability.can(Operation.Manage, Subject.Project);
    const canInvite = ability.can(Operation.Manage, Subject.ShareProjects);
    const canManageFolders = canManageProjects && is2dProjectType;
    const canCreateProject = ability.can(Operation.Create, Subject.Project);
    const projectTypeName = is2dProjectType ? 'project' : 'template';
    const filter = this.getCurrentAccessFilterValue();
    const isAdmin = filter.accessReasons.some(x => x === ProjectAccessReason.Admin);
    const canCreate2dProject = canCreateProject && ability.can(Operation.Read, Subject.QuantityTakeOff2d);

    return (
      <Styled.Container>
        <RenderIf condition={!this.props.isOpen}>
          <InviteUsersDialog
            isAdmin={isAdmin}
            projectToInviteUsers={this.state.projectToInviteUsers}
            onInvitePeople={this.onInvitePeople}
            onInviteToProjectDialogClose={this.onInviteToProjectDialogClose}
            onChangeSharedWithCompany={this.onChangeSharedWithCompany}
          />
        </RenderIf>
        {this.renderProjectRemoveDialog(projectTypeName)}
        {this.renderEditProjectNameDialog()}
        {this.renderDuplicateProjectDialog()}
        {this.renderFolderDialogs()}
        <ProjectsHeader
          onSearchChange={this.onSearchChanged}
          searchPlaceholder={projectTypeName}
          projectType={projectType}
          showSearch={true}
          canAddPeople={canInvite}
          isFolderEnable={canManageFolders}
        />
        <Styled.WrapperProjects isProjectType={is2dProjectType}>
          <RenderIf condition={canManageFolders}>
            <FolderList
              isAdmin={isAdmin}
              currentFolder={this.props.currentFolder}
              onFolderClick={this.openFolder}
              searchQuery={searchQuery.toLowerCase()}
            />
          </RenderIf>
          <ProjectsTitle
            showFilter={canCreateProject}
            accessFilterValue={filter.name}
            accessFilterAvailableValues={this.getCurrentFiltersList()}
            setProjectAccessFilter={this.changeProjectAccessFilter}
            openCreateProjectDialog={this.openCreate2dProjectDialog}
            title={is2dProjectType ? 'Projects' : 'Templates for projects'}
            createButtonDisabled={!canCreate2dProject}
          />
          <ProjectsRenderer
            projectHeadersRequestStatus={this.props.projectHeadersRequestStatus}
            isAdminMode={isAdmin}
            loaded={loaded}
            projectTypeName={projectTypeName}
            projectHeaders={projectHeaders}
            allFetched={allFetched}
            projectsViewType={projectsViewType}
            fetchProjects={this.fetchProjects}
            onRemoveProject={this.onRemoveProject}
            onLeaveProject={this.onLeave}
            onInviteToProjectClick={this.onInviteToProjectClick}
            onOpenEditProjectNameDialog={this.onOpenEditProjectNameDialog}
            onOpenDuplicateProjectDialog={this.onOpenDuplicateProjectDialog}
            openMoveToFolderDialog={this.openMoveToFolderDialog}
            saveProjectAsTemplate={this.saveProjectAsTemplate}
          />
          <Spinner
            show={this.props.projectStatus === RequestStatus.Loading}
            fullScreen={true}
            withBackground={true}
          />
        </Styled.WrapperProjects>
        <DrawingsLeaveProjectConfirmationDialog onSubmit={this.onProjectRemoveConfirmed} />
      </Styled.Container>
    );
  }

  public componentDidUpdate(prevProps: PageProps): void {
    const { projectToBeRemoved, projectCreateStatus } = this.state;
    const { currentFolder, urlFolderId, folders, isFoldersLoaded, ability } = this.props;
    const canCreateProject = ability.can(Operation.Create, Subject.Project);

    this.fetchProjectsFirstPageIfNeeded(prevProps);

    if (
      projectToBeRemoved
      && !projectToBeRemoved.projectFailureNotified
      && projectCreateStatus === ProjectCreateStatus.Failed
    ) {
      this.props.failureProjectIdSend(projectToBeRemoved.id);
    }

    if (urlFolderId && !canCreateProject) {
      this.props.pushFolderToUrl(null);
    }

    if (urlFolderId && currentFolder?.id !== urlFolderId) {
      const newCurrentFolder = folders.find(f => f.id === urlFolderId);
      if (newCurrentFolder) {
        this.props.setCurrentFolder(newCurrentFolder);
      } else {
        if (isFoldersLoaded) {
          this.openFolder(null);
        }
      }
    }

    if (currentFolder && !urlFolderId) {
      this.props.pushFolderToUrl(currentFolder.id);
    }
  }

  @autobind
  private changeProjectAccessFilter(filterName: string): void {
    const {
      projectType,
      projectsAccessFilterName,
      companyId,
      clearProjectHeaders,
      setProjectsAccessFilter,
    } = this.props;

    if (projectsAccessFilterName === filterName) {
      return;
    }

    const projectAccessReasons = ProjectAccessFilterConstants.filters.find(f => f.name === filterName).name;
    clearProjectHeaders(companyId, projectType);
    setProjectsAccessFilter(projectAccessReasons, projectType);
  }

  @autobind
  private onSearchChanged(companyId: number, value: string): void {
    const { changeSearchQuery, clearProjectHeaders, projectType } = this.props;
    clearProjectHeaders(companyId, projectType);
    changeSearchQuery(companyId, value, projectType);
  }

  @autobind
  private onInviteToProjectClick(projectId: number): void {
    this.setState({ projectToInviteUsers: this.props.projectHeaders[projectId] });
    this.props.setShowInviteUsersPanel(true);
  }

  @autobind
  private onInviteToProjectDialogClose(): void {
    this.setState({ projectToInviteUsers: null });
  }

  @autobind
  private onInvitePeople(users: Person[], isSharedWithCompany: boolean): void {
    const { updateInvitedPeoples, changeProjectAccessReason, currentUserEmail } = this.props;
    const projectHeader = this.state.projectToInviteUsers;
    updateInvitedPeoples({
      projectId: projectHeader.id,
      newEmails: users.map(user => user.email),
      oldEmails: projectHeader.users.map(user => user.email),
      isCompanyShared: isSharedWithCompany,
    });

    const isShareCurrentUser = users.some(user => user.email === currentUserEmail);
    if (isShareCurrentUser && projectHeader.accessReason === ProjectAccessReason.Admin) {
      changeProjectAccessReason(projectHeader, ProjectAccessReason.Shared);
    }
  }

  @autobind
  private onChangeSharedWithCompany(): void {
    this.props.setShareWithCompamy(!this.props.isSharedWithCompany);
  }

  @autobind
  private openFolder(folder: ProjectsFolder): void {
    this.props.changeSearchQuery(this.props.companyId, '', this.props.projectType);
    this.props.clearProjectHeaders(this.props.companyId, this.props.projectType);
    this.props.setCurrentFolder(folder);
    this.props.pushFolderToUrl(folder && folder.id);
  }

  @autobind
  private renderEditProjectNameDialog(): React.ReactNode {
    const projectHeaders = this.props.projectHeaders;
    if (projectHeaders && projectHeaders[this.state.currentProjectId]) {
      return (
        <EditProjectNameDialog
          onClose={this.onCloseEditProjectNameDialog}
        />
      );
    }
  }

  @autobind
  private onCloseEditProjectNameDialog(): void {
    this.setState({ currentProjectId: null });
  }

  @autobind
  private renderDuplicateProjectDialog(): React.ReactNode {
    if (!this.props.isDuplicateDialog) {
      return null;
    }
    return (
      <TwoDProjectDuplicateDialog
        projectId={this.state.currentProjectId}
        onDuplicateProject={this.onDuplicateProject}
        onClose={this.onCloseDuplicateProjectDialog}
      />
    );
  }

  @autobind
  private onOpenEditProjectNameDialog(projectId: number): void {
    this.setState(
      { currentProjectId: projectId },
      () => this.props.showDialog(PROJECT_NAME_EDIT_DIALOG_NAME, this.props.projectHeaders[projectId]),
    );
  }

  @autobind
  private onOpenDuplicateProjectDialog(projectId: number): void {
    this.setState(
      { currentProjectId: projectId },
      () => this.props.showDialog(PROJECT_DUPLICATE_DIALOG),
    );
  }

  @autobind
  private onCloseDuplicateProjectDialog(): void {
    this.props.closeDialog(PROJECT_DUPLICATE_DIALOG);
  }


  @autobind
  private onDuplicateProject(companyId: number): void {
    this.props.duplicateProject({ projectId: this.state.currentProjectId, companyId });
    this.onCloseDuplicateProjectDialog();
  }

  @autobind
  private onCloseRemoveDialog(): void {
    this.props.closeDialog(REMOVE_PROJECT_DIALOG_NAME);
  }

  @autobind
  private renderProjectRemoveDialog(type: string): React.ReactNode {
    return (
      <ProjectRemoveDialog
        type={type}
        projectToBeRemoved={this.state.projectToBeRemoved}
        onConfirmed={this.onProjectRemoveConfirmed}
        onCancel={this.onCloseRemoveDialog}
      />
    );
  }

  @autobind
  private onProjectRemoveConfirmed(): void {
    const { projectToBeRemoved, removeOperationType } = this.state;
    if (removeOperationType === RemoveOperation.Leave) {
      this.props.leaveProject(projectToBeRemoved.id);
    } else if (removeOperationType === RemoveOperation.Delete) {
      this.props.removeProject(projectToBeRemoved.id);
    }
    this.setState({ projectToBeRemoved: null });
  }

  @autobind
  private onLeave(projectId: number, projectCreateStatus: ProjectCreateStatus): void {
    const { projectHeaders, showDialog } = this.props;
    this.setState(
      {
        projectToBeRemoved: projectHeaders[projectId],
        projectCreateStatus,
        removeOperationType: RemoveOperation.Leave,
      },
      () => showDialog(DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG),
    );
  }

  @autobind
  private onRemoveProject(projectId: number, projectCreateStatus: ProjectCreateStatus): void {
    const { projectHeaders, showDialog } = this.props;
    this.setState(
      {
        projectToBeRemoved: projectHeaders[projectId],
        projectCreateStatus,
        removeOperationType: RemoveOperation.Delete,
      },
      () => showDialog(REMOVE_PROJECT_DIALOG_NAME),
    );
  }

  private fetchProjectsFirstPageIfNeeded(prevProps: PageProps): void {
    const { urlFolderId, projectType } = this.props;

    if (!this.canFetchProjectHeaders()) {
      return;
    }

    if (this.isNeedFetchProjectsHeaders(prevProps)) {
      const folderId = urlFolderId ? urlFolderId : this.getFolderId(projectType);
      this.fetchProjectHeadersRequest(folderId, 0);
    }
  }

  @autobind
  private isNeedFetchProjectsHeaders(prevProps: PageProps): boolean {
    if (this.isFirstRequest()) {
      return true;
    }
    if (this.isSelectedFolderChanged(prevProps)) {
      return true;
    }
    if (this.IsFilterChange(prevProps)) {
      return true;
    }
    if (this.isCompanyIdChange(prevProps)) {
      return true;
    }
    if (this.isSearchQuery(prevProps)) {
      return true;
    }
    if (this.isRemoveProject(prevProps)) {
      this.setState({ removeOperationType: null });
      return true;
    }

    return false;
  }

  @autobind
  private fetchProjects(): void {
    const { allFetched, projectHeaders, projectType } = this.props;
    if (!allFetched && this.canFetchProjectHeaders()) {
      const folderId = this.getFolderId(projectType);
      const projectsCount = Object.keys(projectHeaders).length;
      this.fetchProjectHeadersRequest(folderId, projectsCount);
    }
  }

  @autobind
  private fetchProjectHeadersRequest(folderId: number, projectsCount: number): void {
    const {
      companyId,
      searchQuery,
      fetchCompanyProjectHeaders,
      projectType,
      selectedCompany,
    } = this.props;

    fetchCompanyProjectHeaders(
      {
        skip: projectsCount,
        take: PROJECTS_PAGE_SIZE,
        companyId,
        type: projectType,
        search: searchQuery,
        folderId,
        accessReason: this.getCurrentAccessFilterValue().accessReasons,
        isGuest: selectedCompany.subscriptions[SubscriptionType.Takeoff2d].userRoleCode === RoleCode.Guest,
      },
    );
  }

  private getCurrentFiltersList(): string[] {
    const { ability } = this.props;
    return ability.can(Operation.Manage, Subject.ProjectAdministration)
      ? ProjectAccessFilterConstants.adminFilters
      : ProjectAccessFilterConstants.nonAdminFilters;
  }

  private getCurrentAccessFilterValue(): ProjectFilter {
    const { projectsAccessFilterName } = this.props;
    const filters = this.getCurrentFiltersList();
    const isFilterAvailable = projectsAccessFilterName && filters.includes(projectsAccessFilterName);
    const filterName = isFilterAvailable
      ? projectsAccessFilterName
      : ProjectAccessFilterConstants.defaultProjectsFilterName;
    return ProjectAccessFilterConstants.filters.find(x => x.name === filterName);
  }

  private canFetchProjectHeaders(): boolean {
    const { companyId, projectHeadersRequestStatus, projectType } = this.props;

    if (
      companyId !== null
      && projectHeadersRequestStatus !== RequestStatus.Failed
    ) {
      if (projectType === ProjectType.Project2dTemplate) {
        return this.props.ability.can(Operation.Read, Subject.Project2DTemplates);
      } else {
        return true;
      }
    }
    return false;
  }

  @autobind
  private isFirstRequest(): boolean {
    const { projectHeadersRequestStatus } = this.props;
    return projectHeadersRequestStatus === RequestStatus.NotRequested;
  }

  @autobind
  private isSelectedFolderChanged(prevProps: PageProps): boolean {
    const { currentFolder } = this.props;
    return currentFolder !== prevProps.currentFolder;
  }

  @autobind
  private IsFilterChange(prevProps: PageProps): boolean {
    const { projectsAccessFilterName } = this.props;
    return projectsAccessFilterName !== prevProps.projectsAccessFilterName;
  }

  @autobind
  private isCompanyIdChange(prevProps: PageProps): boolean {
    const { companyId } = this.props;
    return companyId !== prevProps.companyId;
  }

  @autobind
  private isSearchQuery(prevProps: PageProps): boolean {
    const { searchQuery } = this.props;
    return searchQuery !== prevProps.searchQuery;
  }

  @autobind
  private isRemoveProject(prevProps: PageProps): boolean {
    const { projectHeaders } = this.props;
    const { removeOperationType } = this.state;
    const projectsCount = Object.keys(projectHeaders).length;
    const prevProjectsCount = Object.keys(prevProps.projectHeaders).length;

    return removeOperationType !== null && projectsCount !== prevProjectsCount;
  }

  private getFolderId(projectType: ProjectType): number {
    return projectType === ProjectType.Project2d
      ? this.props.currentFolder && this.props.currentFolder.id
      : null;
  }


  @autobind
  private renderFolderDialogs(): React.ReactNode {
    return (
      <>
        <CreateFolderDialog
          onFormSubmit={this.createFolder}
          onCloseDialog={this.closeCreateFolderDialog}
        />
        <MoveToFolderDialog
          onSubmit={this.moveToFolder}
          onCloseDialog={this.closeMoveToFolderDialog}
          createFolder={this.openCreateFolderDialog}
        />
      </>
    );
  }

  @autobind
  private openMoveToFolderDialog(projectId: number): void {
    this.props.showDialog(
      MOVE_TO_FOLDER_DIALOG_NAME,
      {
        currentFolder: this.props.currentFolder,
        selectedFolder: null,
        projectId,
      },
    );
  }

  @autobind
  private closeMoveToFolderDialog(): void {
    this.props.closeDialog(MOVE_TO_FOLDER_DIALOG_NAME);
  }

  @autobind
  private moveToFolder(folder: ProjectsFolder, projectId: number, parentFolderId: number): void {
    if (folder) {
      this.props.updateFolder({ ...folder, parentFolderId });
    }
    if (projectId) {
      this.props.moveProjectToFolder(projectId, parentFolderId);
    }
  }

  @autobind
  private openCreateFolderDialog(data: FolderData): void {
    this.props.showDialog(CREATE_FOLDER_DIALOG_NAME, data);
  }

  @autobind
  private createFolder(id: number, data: ProjectsFolderFormData): void {
    const isEdit = !!id;
    if (isEdit) {
      this.props.updateFolder({ id, ...data });
    } else {
      this.props.createFolder(data);
    }
  }

  @autobind
  private closeCreateFolderDialog(): void {
    this.props.closeDialog(CREATE_FOLDER_DIALOG_NAME);
  }

  @autobind
  private saveProjectAsTemplate(projectId: number): void {
    this.props.saveProjectAsTemplate(projectId);
  }

  @autobind
  private openCreate2dProjectDialog(): void {
    const canUseTemplates = this.props.ability.can(Operation.Manage, Subject.Project2DTemplates);
    this.props.showDialog(CREATE_2D_PROJECT_DIALOG_NAME, {
      projectType: this.props.projectType,
      canUseTemplates,
    });
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps): PageStateProps => {
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;

  const headersStore = state.projects.projectHeadersByCompanyId[companyId];
  const projectHeadersStore = headersStore && headersStore[ownProps.projectType];

  const { data: projectHeaders, searchQuery, allFetched } = projectHeadersStore
    ? projectHeadersStore
    : { data: {}, searchQuery: '', allFetched: false };

  const projectHeadersRequestStatus = state.projects.requests.projectHeadersByCompanyId[companyId]
    || RequestStatus.NotRequested;

  const isOpen = CREATE_2D_PROJECT_DIALOG_NAME in state.dialog;
  const location = state.router.location;
  const folderId = Number(location.query.folder);
  const folder = state.projects.currentFolder;
  const isFoldersLoaded = state.projects.foldersLoadingStatus === RequestStatus.Loaded;

  return {
    companyId,
    projectHeaders,
    allFetched,
    searchQuery,
    projectHeadersRequestStatus,
    isDuplicateDialog: PROJECT_DUPLICATE_DIALOG in state.dialog,
    currentFolder: folder,
    projectStatus: state.projects.status,
    projectsAccessFilterName: (state.persistedStorage.projectAccessFilter || {})[ownProps.projectType],
    isOpen,
    isSharedWithCompany: !!selector(state, 'isShareWithCompany'),
    currentUserEmail: state.account.email,
    projectsViewType: state.persistedStorage.projectsViewType || ProjectsView.Grid,
    selectedCompany,
    urlFolderId: folderId,
    folders: state.projects.folders,
    isFoldersLoaded,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    fetchCompanyProjectHeaders: (payload) => dispatch(ProjectsActions.fetchCompanyProjectHeadersRequest(payload)),
    clearProjectHeaders: (companyId, type) => dispatch(ProjectsActions.clearCompanyProjectHeaders(companyId, type)),
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
    openSelfServePortal: () => dispatch(AccountActions.openSelfServePortal()),
    removeProject: projectId => dispatch(ProjectsActions.removeProject(projectId)),
    leaveProject: projectId => dispatch(ProjectsActions.leaveProject(projectId)),
    duplicateProject: ({ projectId, companyId }) => dispatch(ProjectsActions.duplicate2dProject(projectId, companyId)),
    changeSearchQuery: (companyId, searchQuery, type) =>
      dispatch(ProjectsActions.changeCompanyProjectsSearchQuery(companyId, searchQuery, type)),
    failureProjectIdSend: projectId => dispatch(ProjectsActions.failureProjectIdSend(projectId)),
    changeProjectNameById: (name: string, id: number) => dispatch(ProjectsActions.updateProjectNameById(name, id)),
    dropStore: () => dispatch(ProjectsActions.dropStore()),
    updateInvitedPeoples: (payload) => dispatch(PeopleActions.updateInvitedPeoples(payload)),
    createFolder: (folder) => dispatch(ProjectsFolderActions.createFolder(folder)),
    updateFolder: (folder) => dispatch(ProjectsFolderActions.updateFolder(folder)),
    moveProjectToFolder: (projectId, folderId) =>
      dispatch(ProjectsActions.moveProjectToFolder(projectId, folderId, true)),
    setCurrentFolder: (folder) => dispatch(ProjectsFolderActions.setCurrentFolder(folder)),
    saveProjectAsTemplate: (projectId) =>
      dispatch(ProjectsActions.createTemplateFromProject(projectId)),
    setProjectsAccessFilter: (filterName, projectType) =>
      dispatch(PersistedStorageActions.setProjectsAccessFilter(filterName, projectType)),
    setShareWithCompamy: (value) => dispatch(change(CREATE_PROJECT, 'isShareWithCompany', value)),
    changeProjectAccessReason: (projectHeader, accessReason) =>
      dispatch(ProjectsActions.changeProjectAccessReason(projectHeader, accessReason)),
    pushFolderToUrl: (id) => dispatch(pushSearch({ folder: !id ? undefined : id.toString() })),
    setShowInviteUsersPanel: (isOpen) => dispatch(PeopleActions.setShowInviteUsersPanel(isOpen)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const TwoDProjectsContentWithAbilityContext = withAbilityContext(TwoDProjectsContentComponent);

export const TwoDProjectsContent = reduxConnector(TwoDProjectsContentWithAbilityContext);
