import autobind from 'autobind-decorator';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { EngineObjectConfig } from '../../common';
import { SetDrawModeCallback } from '../../interfaces';
import { BaseEventsController } from './base-events-controller';

interface DrawingsContinueKeyListenerHelperConfig extends EngineObjectConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  changeDrawMode: SetDrawModeCallback;
}

export class DrawingsContinueKeyListenerHelper extends BaseEventsController<DrawingsContinueKeyListenerHelperConfig> {
  public activate(): void {
    super.activate();
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.addKeyDownEventListener(ModifyHotkey.FinishDraw, this.disableDrawMode);
  }

  public deactivate(): void {
    super.deactivate();
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.removeKeyDownEventListener(ModifyHotkey.FinishDraw, this.disableDrawMode);
  }

  @autobind
  private disableDrawMode(): void {
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }
}
