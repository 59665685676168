import produce from 'immer';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { Field } from '../../item-panel/interfaces';
import { AssemblyUpdater } from './assembly-form-updater/assembly-form-updater';

const getChangeValueHandler =
  (
    assemblyName: string,
    itemName: string,
    groupName: string,
    propertyId: string,
    updater: AssemblyUpdater,
    field: Field<any>,
  ) =>
    (breakdownId: string) => {
      if (field) {
        const newField: any = produce(field, (s) => {
          const name = getNameById(field.input.root, breakdownId);
          s.input.value = name;
          s.input.selectedId = breakdownId;
        });
        const [change] = updater
          .getItemGroupUpdater(assemblyName)
          .getPropertyGroupUpdater(itemName)
          .getFieldHandlers(propertyId, groupName);
        change(newField.input);
      }
    };

type AssemblyBreakdownFieldClickCallback = (
  assemblyName: string,
  itemName: string,
  propertyId: string,
  groupName: string,
) => void;

export function handleAssemblyBreakdownFieldClick(
  updater: AssemblyUpdater,
  openDialog: (data: any) => void,
): AssemblyBreakdownFieldClickCallback {
  return (assemblyName, itemName, propertyId, groupName) => {
    const form = updater.getForm();
    const field = form.assemblyGroupForm[assemblyName]
      && form.assemblyGroupForm[assemblyName].assemblyGroups.itemGroupForm[itemName].itemForm.groupForm[
        groupName
      ].fields.find((f) => f.id === propertyId) as Field<any>;
    const handleChangeValue = getChangeValueHandler(
      assemblyName,
      itemName,
      groupName,
      propertyId,
      updater,
      field,
    );
    openDialog({
      propertyName: field.name,
      root: field.input.root,
      selectedId: field.input.selectedId,
      onSelect: handleChangeValue,
    });
  };
}
