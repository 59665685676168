import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { isValidInputValue, getValidInputValueNumber } from '../validators-input';
import { useDisabledEffect, useHandleEnter } from './hooks';
import { Styled } from './styled';

interface Props {
  value: number;
  onBlur: (newValue: number | '') => void;
  onChange?: (newValue: number | '') => void;

  placeholder?: string;
  isAutoFocus?: boolean;
  inputId?: string;
  disabled?: boolean;
}

export const NumberInput: React.FC<Props> = (props) => {
  const {
    placeholder = 'Enter a value',
    value,
    onChange,
    onBlur,
    inputId,
    isAutoFocus,
    disabled,
  } = props;
  const [inputValue, setValue] = useState<string>('');

  useEffect(() => {
    if (value === undefined || isNaN(value)) {
      setValue('');
    } else {
      setValue(value?.toString());
    }
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;

    if (userInput === '') {
      setValue('');
      if (onChange) {
        onChange('');
      }
      return;
    }

    if (isValidInputValue(userInput)) {
      const newValue = Number(userInput);
      setValue(userInput);
      if (!isNaN(newValue)) {
        if (onChange) {
          onChange(newValue);
        }
      }
    }
  }, [onChange]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const validValue = getValidInputValueNumber(newValue);

    // Format the valid value to discard trailing zeros
    if (typeof validValue === 'number') {
      const formattedValue = parseFloat(validValue.toString());
      onBlur(formattedValue);
      setValue(formattedValue.toString());
    } else {
      onBlur(validValue);
      setValue('');
    }
  }, [onBlur]);

  const { handleEnterDown, inputRef } = useHandleEnter();
  useDisabledEffect(disabled, inputRef);

  return (
    <Styled.InputStyle
      placeholder={placeholder}
      type='text'
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      id={inputId}
      autoFocus={isAutoFocus}
      max={Number.MAX_VALUE}
      ref={inputRef as React.MutableRefObject<HTMLInputElement>}
      onKeyDown={handleEnterDown}
      disabled={disabled}
    />
  );
};
