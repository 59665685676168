import { AssignedPiaProperty } from '2d/interfaces';
import { getCodeById, getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { PropertiesTypeGuards } from 'unit-2d-database/helpers/properties-typeguards';
import { BreakDownPropertyValue, Property } from 'unit-2d-database/interfaces';
import { BreakdownPropertyExtender } from '../../items-helper/utils';

export const setBreakDownAssignData = (
  propertyValue: Record<string, string | number | boolean>,
  originProperties: Property[],
  property: AssignedPiaProperty,
  breakdownExtender: BreakdownPropertyExtender,
): void => {
  const originProperty = originProperties.find(
    (op) => op.name === property.name
      && PropertiesTypeGuards.isBreakdown(op)) as Property<BreakDownPropertyValue>;

  if (!originProperty) {
    return;
  }
  const root = originProperty.value.root;
  const name = getNameById(root, property.value?.value as string);
  const code = getCodeById(root, property.value?.value as string);
  if (!name || !code) {
    return;
  }
  propertyValue[property.name] = name;
  const breakdownFields = breakdownExtender(code, property.name, property.value.value as string, name);
  for (const key of Object.keys(breakdownFields)) {
    propertyValue[key] = breakdownFields[key];
  }
};
