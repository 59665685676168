import { Icons, SvgComponent } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { CompaniesMenu } from 'common/components/companies-menu/companies-menu';
import { RenderIf } from 'common/components/render-if';
import { SidePanelNavigation } from 'common/components/side-panel-navigation';
import { METRIC_IDS } from 'common/constants/id-maps';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { SmallScreenDialog } from 'common/small-screen-dialog';
import { KreoDialogActions } from 'common/UIKit';
import { MetaTagUtils, MetaTitlePostfix } from 'common/utils/meta-tag-utils';
import { CREATE_COMPANY_DIALOG_NAME, CreateCompanyDialog } from 'components/dialog/create-company-dialog';
import { DemoProjectDialog } from 'components/dialog/demo-project-dialog';
import { AppUrls } from 'routes/app-urls';
import { NavigationTabsType } from '../2d/constants/navigation-tabs-type';
import { AccountActions } from '../account/actions/creators';
import { AccountApi } from '../account/api';
import { Company } from '../account/interfaces/company';
import { OwnCompany } from '../account/interfaces/own-company';
import { AccountSelectors } from '../account/selectors';
import {
  CompanyInfoWrapper,
  CompanyInfoWrapperTabIndexes,
} from '../people/components/company-info-wrapper';
import { Person } from '../people/interfaces/person';
import { PersistedStorageActions } from '../persisted-storage/actions/creators';
import { ProjectsFolderActions } from '../projects/actions/creators/projects-folder';
import { MenuSelectProduct } from './menu-select-product';
import { Styled } from './styled';

interface MenuItem {
  text: string;
  Icon: SvgComponent;
  onClick: () => void;
  type: NavigationTabsType;
  createBtnText?: string;
  showNew?: () => boolean;
  isHide?: () => boolean;
  showUpgrade?: () => boolean;
}

interface PageStateProps {
  companyId: number;
  companies: Company[];
  selectedCompany: Company;
  isCollapseMenu: boolean;
  ownCompany: OwnCompany;
  users: Person[];
  isRoleGuest: boolean;
}

interface PageDispatchProps {
  showDialog: (name: string, data?) => void;
  selectCompany: (company: Company) => void;
  setShowCompanyInfoPanel: (isOpen: boolean) => void;
  toggleProjectMenu: () => void;
  goToDatabase: () => void;
  goToReportTemplates: () => void;
  goToProjects: () => void;
  goToDemoProjects: () => void;
  goToTemplates: () => void;
}

interface PageProps extends
  AbilityAwareProps,
  PageStateProps,
  PageDispatchProps {
  pageTitle: string;
  isCreateButtonHide: boolean;
  activeTabIndex?: number;
  createButtonDisable?: boolean;
  createButtonText?: string;
  onClickCreateButton?: () => void;
}

interface PageState {
  activeCompanyInfoType: CompanyInfoWrapperTabIndexes;
  isOpenMenuSelectProduct: boolean;
}

export enum ProjectMenuTab {
  Projects,
  Templates,
  Database,
  ReportTemplate,
}

class TwoDNavigationComponent extends React.Component<PageProps, PageState> {
  private projectsMenu: MenuItem[] = [
    {
      text: 'Projects',
      Icon: Icons.ProjectsNavigation2D,
      onClick: () => this.changeTab(ProjectMenuTab.Projects),
      type: NavigationTabsType.Project2d,
      createBtnText: this.props.createButtonText,
    },
    {
      text: 'Templates for projects',
      Icon: Icons.Templates,
      onClick: () => this.changeTab(ProjectMenuTab.Templates),
      type: NavigationTabsType.Project2dTemplate,
      createBtnText: this.props.createButtonText,
      isHide: () => this.props.isRoleGuest,
      showUpgrade: () => this.props.ability.cannot(Operation.Read, Subject.Project2DTemplates),
    },
    {
      text: 'Assemblies Database',
      Icon: Icons.DatabaseSmall,
      onClick: () => this.changeTab(ProjectMenuTab.Database),
      type: NavigationTabsType.Database,
      isHide: () => this.props.isRoleGuest,
      showUpgrade: () => this.props.ability.cannot(Operation.Read, Subject.PiaDatabase),
    },
    {
      text: 'Report Template',
      Icon: Icons.ReportTemplateNavigation2D,
      onClick: () => this.changeTab(ProjectMenuTab.ReportTemplate),
      type: NavigationTabsType.ReportTemplate,
      isHide: () => this.props.ability.cannot(Operation.Read, Subject.Takeoff2dReportTemplateShow),
      showUpgrade: () => this.props.ability.cannot(Operation.Manage, Subject.Takeoff2dReportTemplate),
    },
  ];

  constructor(props: PageProps) {
    super(props);

    this.state = {
      activeCompanyInfoType: CompanyInfoWrapperTabIndexes.Users,
      isOpenMenuSelectProduct: false,
    };
  }

  public componentDidMount(): void {
    MetaTagUtils.setTitle(this.props.pageTitle, MetaTitlePostfix.Takeoff);
  }

  public componentDidUpdate(): void {
    MetaTagUtils.setTitle(this.props.pageTitle, MetaTitlePostfix.Takeoff);
  }

  public render(): React.ReactNode {
    const {
      companies,
      selectedCompany,
      ownCompany,
      createButtonDisable,
      onClickCreateButton,
      createButtonText,
      children,
      isCreateButtonHide,
      activeTabIndex,
    } = this.props;

    const addCompanyClick = ownCompany ? null : this.openCreateCompanyDialog;

    const companiesList = companies.map(company => ({
      id: company.id,
      name: company.name,
      logo: AccountApi.getCompanyLogoLink(company),
    }));

    return (
      <Styled.Container>
        <CompaniesMenu
          companiesList={companiesList}
          activeCompanyId={selectedCompany.id}
          onCompanyClick={this.selectCompany}
          addCompanyClick={addCompanyClick}
        />
        <SidePanelNavigation
          buttonId={METRIC_IDS.addNewProject}
          menuItems={this.projectsMenu}
          Logo={Icons.KreoLogo2D}
          SmallLogo={Icons.KreoSmallLogo2D}
          onClickLogo={this.openMenuSelectProduct}
          activeItem={this.projectsMenu[activeTabIndex].text}
          onCreateButtonClick={onClickCreateButton}
          createButtonDisabled={createButtonDisable}
          isCollapsed={this.props.isCollapseMenu}
          collapseToggle={this.collapseToggle}
          createButtonText={createButtonText}
          isCreateButtonHide={isCreateButtonHide}
        />
        {children}
        <CompanyInfoWrapper defaultIndex={this.state.activeCompanyInfoType} />
        <CreateCompanyDialog />
        <DemoProjectDialog />
        <RenderIf condition={this.state.isOpenMenuSelectProduct}>
          <MenuSelectProduct
            closeMenu={this.closeMenuSelectProduct}
          />
        </RenderIf>
        <SmallScreenDialog />
      </Styled.Container>
    );
  }

  @autobind
  private openMenuSelectProduct(): void {
    this.setState({ isOpenMenuSelectProduct: true });
  }

  @autobind
  private closeMenuSelectProduct(): void {
    this.setState({ isOpenMenuSelectProduct: false });
  }

  @autobind
  private openCreateCompanyDialog(): void {
    this.props.showDialog(CREATE_COMPANY_DIALOG_NAME);
  }

  @autobind
  private openCompanyInfoPanel(): void {
    this.props.setShowCompanyInfoPanel(true);
  }

  @autobind
  private selectCompany(value: number): void {
    if (this.props.companyId === value) {
      this.setState({ activeCompanyInfoType: CompanyInfoWrapperTabIndexes.Users }, this.openCompanyInfoPanel);
    } else {
      this.props.selectCompany(this.props.companies.find(c => c.id === value));
    }
  }

  @autobind
  private collapseToggle(): void {
    this.props.toggleProjectMenu();
  }

  @autobind
  private changeTab(index: number): void {
    const selectedTab = this.projectsMenu[index];
    switch (selectedTab.type) {
      case NavigationTabsType.Database: {
        this.props.goToDatabase();
        break;
      }
      case NavigationTabsType.Project2d: {
        this.props.goToProjects();
        break;
      }
      case NavigationTabsType.DemoProjects: {
        this.props.goToDemoProjects();
        break;
      }
      case NavigationTabsType.Project2dTemplate: {
        this.props.goToTemplates();
        break;
      }
      case NavigationTabsType.ReportTemplate: {
        this.props.goToReportTemplates();
        break;
      }
      default:
    }
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const ownCompany = state.account.ownedCompany;
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;
  const isRoleGuest = AccountSelectors.currentSubscription(state).userRoleCode === RoleCode.Guest;

  return {
    companyId,
    companies: state.account.companies,
    selectedCompany: state.account.selectedCompany,
    isCollapseMenu: state.persistedStorage.isCollapseMenu,
    ownCompany,
    users: state.people.companiesUsers,
    isRoleGuest,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    selectCompany: company => {
      dispatch(AccountActions.selectCompany(company));
      dispatch(ProjectsFolderActions.setCurrentFolder(null));
    },
    goToDatabase: () => dispatch(push(AppUrls.qto2d.database.path)),
    goToReportTemplates: () => dispatch(push(AppUrls.qto2d.reportTemplates.path)),
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
    goToDemoProjects: () => dispatch(push(AppUrls.qto2d.demoProjects.path)),
    goToTemplates: () => dispatch(push(AppUrls.qto2d.templates.path)),
    setShowCompanyInfoPanel: (isOpen) => dispatch(AccountActions.setShowCompanyInfoPanel(isOpen)),
    toggleProjectMenu: () => dispatch(PersistedStorageActions.toggleProjectMenu()),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const TwoDNavigation = withAbilityContext(reduxConnector(TwoDNavigationComponent));
