import produce from 'immer';

export interface BaseAfterEffects<TForm> {
  afterChange?: (newForm: TForm, id: string, ...args: any) => void;
  afterDelete?: (newForm: TForm, id: string, ...args: any) => void;
  afterUnitChange?: (newForm: TForm, id: string, ...args: any) => void;
  afterVisibilityChange?: (newForm: TForm, id: string, ...args: any) => void;
}

export interface Params<TForm> {
  getForm: () => TForm;
  afterEffects: BaseAfterEffects<TForm>;
}

export abstract class Updater<TForm, TElement> {
  public getForm: () => TForm;
  protected afterChange?: (newForm: TForm, id: string) => void;
  protected afterDelete?: (newForm: TForm, id: string) => void;
  protected afterUnitChange?: (newForm: TForm, id: string) => void;
  protected afterVisibilityChange?: (newForm: TForm, id: string) => void;

  public constructor({
    getForm,
    afterEffects: {
      afterChange,
      afterDelete,
      afterUnitChange,
      afterVisibilityChange,
    },
  }: Params<TForm>,
  ) {
    this.getForm = getForm;
    this.afterChange = afterChange;
    this.afterDelete = afterDelete;
    this.afterUnitChange = afterUnitChange;
    this.afterVisibilityChange = afterVisibilityChange;
  }

  protected bindHandler<TArgs, T extends TArgs[]>(
    id: string,
    handler: (element: TForm, id: string, ...args: T) => void,
    afterChange: () => (newForm: TForm, id: string, ...args: T) => void,
  ): (...args: T) => void {
    return (...args) => {
      const form = this.getForm();
      const newForm = produce(form, (f) => {
        handler(f as TForm, id, ...args);
      });
      if (afterChange) {
        afterChange()(newForm, id, ...args);
      }
    };
  }

  protected updateParams(
    {
      getForm,
      afterEffects: {
        afterChange,
        afterDelete,
        afterUnitChange,
        afterVisibilityChange,
      },
    }: Params<TForm>,
  ): void {
    this.getForm = getForm;
    this.afterChange = afterChange;
    this.afterDelete = afterDelete;
    this.afterUnitChange = afterUnitChange;
    this.afterVisibilityChange = afterVisibilityChange;
  }

  protected abstract getElement(form: TForm, id: string): TElement;
}
