import {
  HoverMenu,
  TinyText,
  Waves,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { DragSourceCollector, DropTargetCollector, DragSource, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { DrawingsActions, PdfPagePreviewWithBubble } from 'common/components/drawings';
import { MoveTab } from 'common/components/drawings/actions/payloads/common';
import {
  DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG,
  DrawingsDeletePage,
} from 'common/components/drawings/dialogs/delete-page-confirmation-dialog';
import {
  DrawingsRendererApiContextProps,
  withRendererApiContext,
} from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { DrawModeContextProps, withDrawingModeApi } from 'common/components/drawings/hooks';
import {
  DrawingsFile,
  DrawingsFolderViewInfo,
} from 'common/components/drawings/interfaces/drawings-file-info';
import { LabelWithRename } from 'common/components/label-with-rename';
import { DndItem } from 'common/enums/dnd-item';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { getMovingElement } from 'common/utils/dnd-utils';
import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { TwoDHeaderPageTabHoverClose } from '../two-d-header-page-tab-hover-close';
import { TwoDHeaderPageTabHoverMenu } from '../two-d-header-page-tab-hover-menu';
import { ContextMenu } from './context-menu';
import { Styled } from './styled';

interface DNDProps {
  connectDropTarget: (element: JSX.Element) => JSX.Element;
  connectDragSource: (element: JSX.Element) => JSX.Element;
  connectDragPreview: (element: JSX.Element) => JSX.Element;
  isDragging: boolean;
}

interface StateProps {
  entities: Record<string, DrawingsFile | DrawingsFolderViewInfo>;
  selectedPages: string[];
  projectId: number;
  minifiedTabs: boolean;
  commentsCount: number;
}

interface DispatchProps {
  onRemoveTabs: (removedTabs: string[]) => void;
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void;
  openDialog: (name: string, data: DrawingsDeletePage) => void;
  moveTab: (payload: MoveTab) => void;
  toggleMinifyTabs: () => void;
}

interface OwnProps extends StateProps, DispatchProps, DrawingsRendererApiContextProps, DrawModeContextProps {
  index: number;
  id: string;
  pdfId: string;
  name: string;
  color?: string;
  pageNumber: number;
  isActive: boolean;
  screenshotCreated: boolean;
  onClickTab: (id: string) => void;
  canEditMeasurement: boolean;
  changePosition: (dragIndex: number, hoverIndex: number) => void;
  canChangeColor: boolean;
}

interface Props extends OwnProps, DNDProps { }

interface ComponentState {
  isContextMenuOpened: boolean;
  contextMenuPositionX: number;
  contextMenuPositionY: number;
}

class TwoDHeaderPageTabComponent extends React.PureComponent<Props, ComponentState> {
  private containerRef: HTMLDivElement;
  private divRef: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      isContextMenuOpened: false,
      contextMenuPositionX: 0,
      contextMenuPositionY: 0,
    };
  }

  public render(): JSX.Element {
    const {
      id,
      entities,
      isActive,
      color,
      pdfId,
      pageNumber,
      minifiedTabs,
      name,
      canEditMeasurement,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      screenshotCreated,
      canChangeColor,
    } = this.props;

    const pdfName = entities[pdfId] && entities[pdfId].properties.name;
    const isFirstTab = this.props.selectedPages[0] === this.props.id;
    const isLastTab = this.props.selectedPages[this.props.selectedPages.length - 1] === this.props.id;
    const TooltipComponent = (): React.ReactNode => (
      <TwoDHeaderPageTabHoverMenu
        pdfId={pdfId}
        screenshotCreated={screenshotCreated}
        pageId={id}
        name={name}
        color={color}
        pdfName={pdfName}
        pageNumber={pageNumber + 1}
      />
    );
    return connectDragPreview(
      connectDragSource(
        connectDropTarget(
          <div>
            <Styled.Wrapper ref={this.divRef} isDragging={isDragging}>
              <HoverMenu
                position={'bottom'}
                speed={'xl'}
                MenuComponent={TooltipComponent} // eslint-disable-line react/jsx-no-bind
                disabled={true}
              >
                <Styled.Container
                  onClick={this.onClickTab}
                  onContextMenu={this.onContextMenu}
                  isActive={isActive}
                  color={color}
                  pageNumber={pageNumber}
                  minified={minifiedTabs}
                  ref={this.saveRefContainer}
                >
                  <Styled.PreviewImage>
                    <PdfPagePreviewWithBubble
                      created={screenshotCreated}
                      color={color}
                      pdfId={pdfId}
                      pageId={id}
                      pageNumber={pageNumber}
                    />
                    <Styled.CloseButton>
                      <TwoDHeaderPageTabHoverClose onClick={this.onRemoveTab} />
                    </Styled.CloseButton>
                    <CommentCountMark count={this.props.commentsCount} showTooltip={true} />
                  </Styled.PreviewImage>
                  <Styled.TextContainer minified={minifiedTabs}>
                      <LabelWithRename
                        value={name}
                        changeValue={this.onChange}
                        fontSize={14}
                        disableRenaming={!canEditMeasurement}
                        backgroundColor={'background'}
                        isHoverBackground={true}
                        isHoverEditIcon={true}
                      />
                    <TinyText color={ColorsName.turquoiseFont} withEllipsis={true}>
                      {pdfName}
                    </TinyText>
                  </Styled.TextContainer>

                  {!isActive && <Waves color="turquoise" />}
                </Styled.Container>
              </HoverMenu>
              {this.state.isContextMenuOpened && (
                <ContextMenu
                  isActive={isActive}
                  fileName={pdfName}
                  drawingName={name}
                  drawingId={this.props.id}
                  positionX={this.state.contextMenuPositionX}
                  positionY={this.state.contextMenuPositionY}
                  onClose={this.onContextMenuClose}
                  color={color}
                  canEditMeasurements={this.props.canEditMeasurement}
                  selectedPages={this.props.selectedPages}
                  isFirstTab={isFirstTab}
                  isLastTab={isLastTab}
                  onRemoveTab={this.onRemoveTab}
                  onRemoveOtherTabs={this.onRemoveOtherTabs}
                  onColorChange={this.onColorChange}
                  openDeletePageConfirmDialog={this.openDeletePageConfirmDialog}
                  onMoveTabLeft={this.onMoveTabLeft}
                  onMoveTabRight={this.onMoveTabRight}
                  canChangeColor={canChangeColor}
                />
              )}
            </Styled.Wrapper>
          </div>,
        ),
      ),
    );
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.state.isContextMenuOpened && !isEqual(prevProps.selectedPages, this.props.selectedPages)) {
      const { left, width } = this.containerRef.getBoundingClientRect();
      this.setState({ contextMenuPositionX: left + width / 2 });
    }

    if (this.props.isActive && !prevProps.isActive) {
      this.containerRef.scrollIntoView();
    }
  }

  public componentDidMount(): void {
    if (this.props.isActive) {
      this.containerRef.scrollIntoView({ inline: 'start' });
    }
  }

  @autobind
  private saveRefContainer(ref: HTMLDivElement): void {
    this.containerRef = ref;
  }

  @autobind
  private openDeletePageConfirmDialog(): void {
    const data: DrawingsDeletePage = { name: this.props.name, pageId: this.props.id, pdfId: this.props.pdfId };
    this.props.openDialog(DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG, data);
  }

  @autobind
  private onClickTab(): void {
    if (this.props.isActive) {
      this.props.onClickTab(this.props.id);
    } else {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, { afterSave: () => this.props.onClickTab(this.props.id) });
    }
  }

  @autobind
  private onRemoveTab(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    if (this.props.isActive) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, {
        afterSave: () => {
          this.props.onRemoveTabs([this.props.id]);
        },
      });
    } else {
      this.props.onRemoveTabs([this.props.id]);
    }

  }

  @autobind
  private onRemoveOtherTabs(): void {
    if (!this.props.isActive) {
      this.props.setDrawMode(DrawingsDrawMode.Disabled, {
        afterSave: () => {
          this.props.onRemoveTabs(this.props.selectedPages.filter(page => page !== this.props.id));
        },
      });
    } else {
      this.props.onRemoveTabs(this.props.selectedPages.filter(page => page !== this.props.id));
    }
    this.onContextMenuClose();
  }

  @autobind
  private onColorChange(color: string): void {
    this.props.onParameterUpdate(this.props.id, 'color', color);
  }

  @autobind
  private onContextMenu(e: MouseEvent): void {
    e.preventDefault();
    this.setState({
      isContextMenuOpened: true,
      contextMenuPositionX: e.pageX,
      contextMenuPositionY: e.pageY,
    });
  }

  @autobind
  private onContextMenuClose(): void {
    this.setState({ isContextMenuOpened: false });
  }

  @autobind
  private onChange(value: string): void {
    const { onParameterUpdate, id, name } = this.props;
    if (name !== value) {
      onParameterUpdate(id, 'name', value);
    }
  }

  @autobind
  private onMoveTabLeft(): void {
    this.props.moveTab({ id: this.props.id, offset: -1 });
    this.containerRef.scrollIntoView({ inline: 'end' });
  }

  @autobind
  private onMoveTabRight(): void {
    this.props.moveTab({ id: this.props.id, offset: 1 });
    this.containerRef.scrollIntoView({ inline: 'start' });
  }
}

const specDrop = {
  hover: (props: Props, monitor, component) => {
    if (!component) {
      return null;
    }

    const node = component.divRef.current;
    const hoverIndex = props.index;

    const element = getMovingElement(hoverIndex, node, monitor);
    if (element) {
      const { dragElement, dragIndex } = element;
      props.changePosition(dragIndex, hoverIndex);
      dragElement.index = hoverIndex;
    }
  },
  drop: (props: Props) => {
    const hoverIndex = props.index;
    const offset = hoverIndex - props.selectedPages.indexOf(props.id);
    props.moveTab({ id: props.id, offset });
  },
};

const collectDrop: DropTargetCollector<any, any> = (connecter) => {
  return {
    connectDropTarget: connecter.dropTarget(),
  };
};

const specDrag = {
  beginDrag: (props: Props) => ({
    index: props.index,
  }),
};

const collectDrag: DragSourceCollector<any, any> = (connecter, monitor) => {

  return {
    connectDragSource: connecter.dragSource(),
    connectDragPreview: connecter.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  return {
    projectId: state.projects.currentProject.id,
    entities: state.drawings.files.entities,
    selectedPages: state.drawings.selectedPages,
    minifiedTabs: state.persistedStorage.isPagesTabsMinified,
    commentsCount: state.twoDComments.drawingCommentsCount[ownProps.id],
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    openDialog: (name, data) => dispatch(KreoDialogActions.openDialog(name, data)),
    onRemoveTabs: (payload) => dispatch(DrawingsActions.removeTabs(payload)),
    onParameterUpdate: (drawingId, parameter, value) =>
      dispatch(DrawingsActions.pageMetaParameterUpdate(drawingId, parameter, value)),
    moveTab: (payload) => dispatch(DrawingsActions.moveTab(payload)),
    toggleMinifyTabs: () => dispatch(PersistedStorageActions.toggleTwoDPagesMinified()),
  };
};

const connecter = connect(mapStateToProps, mapDispatchToProps);

const dragConnector = DragSource<OwnProps>(DndItem.DrawingPageTab, specDrag, collectDrag);
const dropConnector = DropTarget<OwnProps>(DndItem.DrawingPageTab, specDrop, collectDrop);

const TwoDHeaderPageTabDnDConnector = dropConnector(dragConnector(
  TwoDHeaderPageTabComponent,
));

export const TwoDHeaderPageTab = withRendererApiContext(
  connecter(withDrawingModeApi(TwoDHeaderPageTabDnDConnector)) as React.ComponentType<any>,
);
