import { SagaIterator, Task } from 'redux-saga';
import { all, call, delay, fork, put, take, takeEvery } from 'redux-saga/effects';

import { TwoDActions } from '2d/actions/creators';
import { TwoDElementViewActions } from '2d/components/2d-element-view/store-slice';
import { AssignPiaPatch, AssignedPia } from '2d/index';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { ActionWith } from 'common/interfaces/action-with';
import { arrayUtils } from 'common/utils/array-utils';
import { AsyncArrayUtils } from 'common/utils/async-array-util';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { UuidUtil } from 'common/utils/uuid-utils';
import { getCurrentProject } from '../../../../units/projects/selectors';
import { DrawingsAnnotationActions } from '../actions/creators/annotation';
import { DrawingsActions } from '../actions/creators/common';
import { DrawingsAnnotationLegendActions } from '../actions/creators/drawings-annotation-legend';
import { DrawingsUpdateActions } from '../actions/creators/update';
import {
  DrawingsEditFileTreeItem,
} from '../actions/payloads/common';
import {
  CreateDrawingGroup,
  MoveDrawingGroupTree,
} from '../actions/payloads/drawings-annotation-legend';
import {
  CreateDrawingGroupData,
  DrawingGroupChanges,
  DrawingsBeUpdatesState,
  DrawingsChange,
  DrawingsChanges,
  DrawingsElementChanges,
  DrawingsPageUpdateBatch,
  DrawingsPageUpdates,
  DrawingsUpdateFiles,
  UpdateDrawingGroupData,
} from '../actions/payloads/update';
import { DrawingsUpdateActionTypes } from '../actions/types/update';
import { DrawingsApi } from '../api/drawings-api';
import { DrawingsInstancesUpdateType } from '../enums';
import { DrawingChangeOperation } from '../enums/drawing-change-operation';
import { DrawingChangeSource } from '../enums/drawing-change-source';
import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import { DrawingsCreateFile, DrawingsPageResponse } from '../interfaces/drawings-api-payload';
import { DrawingsGeometryType } from '../interfaces/drawings-geometry';
import { DrawingsGeometryGroup } from '../interfaces/drawings-geometry-group';
import {
  DrawingsGeometryInstance,
  DrawingsGeometryInstanceUpdatePayload,
} from '../interfaces/drawings-geometry-instance';
import { DrawingsShortInfo } from '../interfaces/drawings-short-drawing-info';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsGroupUtils } from '../utils/drawings-group-utils';

const SAVE_CHANGES_DELAY = 1000;

interface UpdatedGeometryState {
  id: string;
  updateType: DrawingsInstancesUpdateType;
}

interface UpdatesState {
  drawingsState?: DrawingsState;

  createdFolders: Record<string, DrawingsEditFileTreeItem>;
  createdFiles: Record<string, DrawingsCreateFile>;

  updateFileSystem: Record<string, DrawingsEditFileTreeItem>;
  updatePages: Record<string, DrawingsPageUpdateBatch>;

  deletedFileSystemItems: string[];
  deletedPages: string[];

  createdGeometries: Set<string>;
  removedGeometries: Set<string>;
  removedGeometriesPages: Record<string, string>;
  updatedGeometries: UpdatedGeometryState[];
  pagesWithUpdatedGeometry: Set<string>;

  selectedPages?: string[];
  currentPage?: string;

  createdGroups: Record<string, CreateDrawingGroup>;
  removedGroups: Set<string>;
  updatedGroups: GroupsAndMeasurementsChanges;
  movedGroups: GroupsAndMeasurementsChanges;
  pinnedGroups?: Set<string>;

  projectId: number;

  piaToSave: Record<string, AssignedPia>;
  pagesPia: Record<string, Record<string, AssignedPia>>;
}

interface GroupsAndMeasurementsChanges {
  groups: Set<string>;
  measurements: Set<string>;
}


function processPage(page: DrawingsPageUpdateBatch): Partial<DrawingsPageResponse> {
  return {
    id: page.id,
    originalCalibrationLineLength: page.originalCalibrationLineLength,
    drawingCalibrationLineLength: page.drawingCalibrationLineLength,
    paperSize: page.paperSize,
    name: page.name,
    color: page.color,
    rotationAngle: page.rotationAngle,
    isOptimized: page.isOptimized,
  };
}

function convertUpdatesForBackend(stateUpdates: UpdatesState): DrawingsBeUpdatesState {
  return {
    createdFolders: Object.values(stateUpdates.createdFolders),
    createdFiles: Object.values(stateUpdates.createdFiles),

    updateFileSystemForms: Object.values(stateUpdates.updateFileSystem),
    updatedPages: Object.values(stateUpdates.updatePages).map(processPage),

    deletedFileSystemItems: stateUpdates.deletedFileSystemItems,
    deletedPages: stateUpdates.deletedPages,

    selectedPages: stateUpdates.selectedPages,
    currentPage: stateUpdates.currentPage,
  };
}

function isFileSystemChange(
  _changes: DrawingsChanges[],
  source: DrawingChangeSource,
): _changes is DrawingsUpdateFiles[] {
  return source === DrawingChangeSource.FileSystem;
}

function isPageChange(
  _changes: DrawingsChanges[],
  source: DrawingChangeSource,
): _changes is DrawingsPageUpdates[] {
  return source === DrawingChangeSource.Page;
}

function isElementChangesSource(
  _changes: DrawingsChanges[],
  source: DrawingChangeSource,
): _changes is DrawingsElementChanges[] {
  return source === DrawingChangeSource.Elements;
}

function isGroupsChange(
  _changes: DrawingsChanges[],
  source: DrawingChangeSource,
): _changes is DrawingGroupChanges[] {
  return source === DrawingChangeSource.Groups;
}

function isGroupsCreateChange(
  _changesData: UpdateDrawingGroupData | CreateDrawingGroupData,
  operation: DrawingChangeOperation,
): _changesData is CreateDrawingGroupData {
  return operation === DrawingChangeOperation.Create;
}

interface WithPointPositions {
  pointPositions: Record<string, ShortPointDescription>;
}


type GeometryWithPointPosition = WithPointPositions & DrawingsGeometryType;

function prepareInstanceGeometryInfo(
  geometry: DrawingsGeometryType,
  type: DrawingsInstanceType,
  commonPoints: Record<string, ShortPointDescription>,
): GeometryWithPointPosition {
  const pointsIterator = DrawingAnnotationUtils.geometryPointsIterator(geometry, type);
  const pointPositions = arrayUtils.toDictionary(pointsIterator, x => x, x => commonPoints[x]);
  return { ...geometry, pointPositions };
}


function processInstance(
  { aiAnnotation: { geometry, points }, hiddenInstances }: DrawingsState,
  id: string,
  updateType: DrawingsInstancesUpdateType,
): Partial<DrawingsGeometryInstance> {
  const isHidden = hiddenInstances.includes(id);
  switch (updateType) {
    case DrawingsInstancesUpdateType.Description: {
      const instance = { ...geometry[id], isHidden };
      delete instance.geometry;
      return instance;
    }
    case DrawingsInstancesUpdateType.Full:
      const { geometry: instanceGeometry, type } = geometry[id];
      return {
        ...geometry[id],
        geometry: prepareInstanceGeometryInfo(instanceGeometry, type, points),
        isHidden,
      };
    default:
      throw new Error(`Unexpected instance update type ${updateType}`);
  }
}

const SAVE_GEOMETRIES_REQUEST_BATCH_SIZE = 30;

function* saveGeometries(updates: UpdatesState, drawings: DrawingsState): SagaIterator {
  function* savePageGeometries(
    data: DrawingsShortInfo,
    requestData: DrawingsGeometryInstanceUpdatePayload,
  ): SagaIterator {
    const changesInfo = yield call(
      DrawingsApi.updateMarkups,
      updates.projectId,
      data.pdfId,
      data.drawingId,
      requestData,
    );
    yield put(DrawingsAnnotationActions.updateUserInInstances(
      changesInfo,
      Object.keys(requestData.created),
      changesInfo.editedMeasurementIds,
    ));
  }

  let callEffects = [];

  const piaToSave = new Array<AssignPiaPatch>();

  for (const pageId of updates.pagesWithUpdatedGeometry) {
    const data = drawings.drawingsInfo[pageId];
    const piaToSaveForPage = updates.pagesPia[pageId];
    if (piaToSaveForPage) {
      for (const [instanceId, pia] of Object.entries(piaToSaveForPage)) {
        piaToSave.push({
          ids: [instanceId],
          addedAssemblies: pia.assemblies,
          addedItems: pia.items,
        });
      }
    }
    const drawingToUpdate: DrawingsGeometryInstanceUpdatePayload[] =
      yield call(getDrawingUpdatesForBackend, updates, drawings, data, pageId);
    for (const requestData of drawingToUpdate) {
      callEffects.push(call(savePageGeometries, data, requestData));
      if (callEffects.length >= SAVE_GEOMETRIES_REQUEST_BATCH_SIZE) {
        yield all(callEffects);
        callEffects = [];
      }
    }
  }

  if (callEffects.length) {
    yield all(callEffects);
  }

  if (piaToSave.length) {
    const iterationId = UuidUtil.generateUuid();
    const instances = piaToSave.reduce((accumulator, pia) => {
      arrayUtils.extendArray(accumulator, pia.ids);
      return accumulator;
    }, []);

    yield put(TwoDActions.setExportExcelLoad(iterationId));
    yield put(TwoDActions.sendAssignPatch(piaToSave, iterationId, null, updates.projectId));
    yield put(DrawingsAnnotationActions.removeNotSavedInstance(instances));
  }
}

function* saveChanges(updates: UpdatesState, drawings: DrawingsState): SagaIterator {
  try {
    const {
      drawingGeometryGroups,
      aiAnnotation: { geometry },
    } = drawings;
    const groupsDictionary = arrayUtils.toDictionaryByKey(drawingGeometryGroups, x => x.id);
    if (Object.keys(updates.createdGroups).length) {
      yield call(createGroups, updates, groupsDictionary);
    }

    if (updates.pagesWithUpdatedGeometry.size) {
      yield call(saveGeometries, updates, drawings);
    }

    if (updates.updatedGroups.groups.size || updates.updatedGroups.measurements.size) {
      yield call(updateGroups, updates, groupsDictionary, geometry);
    }

    if (
      updates.movedGroups.groups.size || updates.movedGroups.measurements.size
    ) {
      yield call(moveGroups, updates, groupsDictionary, geometry);
    }

    if (updates.removedGroups.size) {
      yield call(DrawingsApi.deleteDrawingsGroups, updates.projectId, Array.from(updates.removedGroups));
      yield put(TwoDElementViewActions.handleDeleteGroup(updates.removedGroups));
    }

    const drawingUpdates = convertUpdatesForBackend(updates);
    if (Object.values(drawingUpdates).some(x => x && x.length)) {
      const { createdFiles } = yield call(DrawingsApi.update, updates.projectId, drawingUpdates);
      if (createdFiles?.length) {
        createdFiles.forEach((f) => {
          f.parentId = updates.createdFiles[f.id].parentId;
        });
        yield put(DrawingsActions.setFilesData(createdFiles));
      }
    }
    if (updates.pinnedGroups) {
      yield call(DrawingsApi.updatePinnedGroups, updates.projectId, [...updates.pinnedGroups]);
    }
    yield put(DrawingsUpdateActions.runNextCommitOperation(updates.projectId));
  } catch (error) {
    console.error('drawing elements: save elements changes failed', error, updates);
    yield put(DrawingsUpdateActions.clearUpdates(updates.projectId));
    const currentProject = yield selectWrapper(getCurrentProject);
    if (currentProject && currentProject.id === updates.projectId) {
      yield put(DrawingsActions.dropState());
      yield put(DrawingsActions.loadDrawingsData());
    }
  }
}

function filterGroupData(
  groupsIds: Record<string, CreateDrawingGroup>,
  drawingGeometryGroups: Record<string, DrawingsGeometryGroup>,
  pia: Record<string, AssignedPia>,
): { groupsByParent: Record<string, DrawingsGeometryGroup[]>, pia: AssignPiaPatch[] } {
  let groups = [];
  const processedGroups: Record<string, boolean> = {};
  const groupsMap = {};
  const piaToSave = new Array<AssignPiaPatch>();
  for (const groupId of Object.keys(groupsIds)) {
    const group = { ...drawingGeometryGroups[groupId] };
    const parentId = groupsIds[groupId].parentGroupId;
    if (parentId) {
      groupsMap[parentId] = groupsMap[parentId] || {};
      groupsMap[parentId].innerGroups = groupsMap[parentId].innerGroups?.slice() || [];
      if (!groupsMap[parentId].innerGroups.some(x => x.id === groupId)) {
        groupsMap[parentId].innerGroups.push(group);
      }
      if (!processedGroups[parentId]) {
        groups.push(group);
      }
    } else {
      groups.push(group);
    }

    if (groupsMap[groupId]) {
      const innerGroupsIds = new Set(groupsMap[groupId].innerGroups?.map(x => x.id));
      groups = groups.filter(x => !innerGroupsIds.has(x.id));
      group.innerGroups = group.innerGroups?.slice() || [];
      arrayUtils.extendArray(group.innerGroups, groupsMap[groupId].innerGroups);
      group.innerGroups = arrayUtils.uniqWith<DrawingsGeometryGroup>(group.innerGroups, x => x.id);
    }
    groupsMap[groupId] = group;
    if (pia[groupId]) {
      const { assemblies, items } = pia[groupId];
      piaToSave.push({ ids: [groupId], addedAssemblies: assemblies, addedItems: items });
    }
    processedGroups[groupId] = true;
  }
  return { groupsByParent: arrayUtils.groupBy(groups, x => x.parentId), pia: piaToSave };
}

function* createGroups(
  updates: UpdatesState,
  drawingGeometryGroups: Record<string, DrawingsGeometryGroup>,
): SagaIterator {
  const { groupsByParent, pia } = filterGroupData(updates.createdGroups, drawingGeometryGroups, updates.piaToSave);
  for (const [ parentId, groups ] of Object.entries(groupsByParent)) {
    const data = {
      parentGroupId: parentId === 'undefined' ? null : parentId,
      groups,
    };
    const creationInfo = yield call(DrawingsApi.addDrawingsGroups, updates.projectId, data);
    yield put(DrawingsAnnotationLegendActions.updateUserInGroups(creationInfo, data.groups));
  }
  yield put(TwoDElementViewActions.handleCreateGroup(groupsByParent));
  if (pia?.length) {
    const iterationId = UuidUtil.generateUuid();
    yield put(TwoDActions.setExportExcelLoad(iterationId));
    yield put(TwoDActions.sendAssignPatch(pia, iterationId, null, updates.projectId));
  }
}

function* updateGroups(
  updates: UpdatesState,
  drawingGeometryGroups: Record<string, DrawingsGeometryGroup>,
  geometry: Record<string, DrawingsGeometryInstance>,
): SagaIterator {
  const { groups, measurements } = updates.updatedGroups;
  const updatedGroups = arrayUtils.filterMap(
    groups,
    id => id in drawingGeometryGroups,
    id => {
      const group = drawingGeometryGroups[id];
      return { id: group.id, name: group.name, color: group.color };
    },
  );
  const updatedMeasurements = Array.from(measurements)
    .map(x => ({ id: x, name: geometry[x].name, color: geometry[x].geometry.color }));

  yield call(
    DrawingsApi.updateDrawingsGroups,
    updates.projectId, {
      groups: updatedGroups,
      measurements: updatedMeasurements,
    },
  );
  yield put(TwoDElementViewActions.handleRenameGroup(updatedGroups));
}

function* moveGroups(
  updates: UpdatesState,
  drawingGeometryGroups: Record<string, DrawingsGeometryGroup>,
  geometry: Record<string, DrawingsGeometryInstance>,
): SagaIterator {
  const groupsByParent = arrayUtils.groupBy<{ orderIndex: number, id: string, parentId: string }, string>(
    arrayUtils.filterMapIterator(
      updates.movedGroups.groups.values(),
      x => x in drawingGeometryGroups,
      x => ({ orderIndex: drawingGeometryGroups[x].orderIndex, id: x, parentId: drawingGeometryGroups[x].parentId }),
    ),
    x => x.parentId,
  );
  const measurementsByParent = arrayUtils.groupBy(
    updates.movedGroups.measurements,
    x => geometry[x].groupId,
  );

  for (const [parentId, groups] of Object.entries(groupsByParent)) {
    const groupedByIndex = DrawingsGroupUtils.groupBySequentialIndex(groups);
    for (const groupsToMove of groupedByIndex) {
      const data: MoveDrawingGroupTree = {
        parentGroupId: parentId === 'undefined' ? null : parentId,
        orderIndex: groupsToMove[0].orderIndex,
        groups: groupsToMove.map(g => g.id),
        measurements: measurementsByParent[parentId] || [],
      };
      yield call(DrawingsApi.moveDrawingsGroups, updates.projectId, data);
    }
    yield put(TwoDElementViewActions.handleMoveGroup(groupsByParent));
  }

  const groupParentIds = new Set(Object.keys(groupsByParent));
  for (const [parentId, measurements] of Object.entries(measurementsByParent)) {
    if (!groupParentIds.has(parentId)) {
      const data: MoveDrawingGroupTree = {
        parentGroupId: parentId === 'undefined' ? null : parentId,
        groups: [],
        measurements,
      };
      yield call(DrawingsApi.moveDrawingsGroups, updates.projectId, data);
      yield put(TwoDElementViewActions.handleMoveElement(data));
    }
  }
}

function* saveChangesWithDelay(updates: UpdatesState): SagaIterator {
  try {
    const drawings: DrawingsState = yield selectWrapper(x => x.drawings);
    yield delay(SAVE_CHANGES_DELAY);
    yield call(saveChanges, updates, updates.drawingsState || drawings);
  } catch (error) {
    console.error('drawing elements: save elements changes failed', error, updates);
    yield put(DrawingsUpdateActions.clearUpdates(updates.projectId));
    const currentProject = yield selectWrapper(getCurrentProject);
    if (currentProject && currentProject.id === updates.projectId) {
      yield put(DrawingsActions.dropState());
      yield put(DrawingsActions.loadDrawingsData());
    }
  }
}

function isFileCreation(item: any): item is DrawingsCreateFile {
  return !!item.temporaryFilePath;
}

async function getDrawingUpdatesForBackend(
  updates: UpdatesState,
  drawings: DrawingsState,
  drawingsInfo: DrawingsShortInfo,
  pageId: string,
): Promise<DrawingsGeometryInstanceUpdatePayload[]> {
  const {
    instances,
  } = drawings.aiAnnotation.fileData[drawingsInfo.pdfId][drawingsInfo.drawingId];
  const drawingInstancesSet = new Set(instances);

  const groupedUpdates: Record<string, DrawingsGeometryInstanceUpdatePayload> = {};

  const createdInstancesGeometries = AsyncArrayUtils.iteratorWithDelayEveryNStep(
    updates.createdGeometries.values(),
    1,
    1000,
  );
  for await (const instanceId of createdInstancesGeometries) {
    if (!drawingInstancesSet.has(instanceId)) {
      continue;
    }

    const instance = processInstance(drawings, instanceId, DrawingsInstancesUpdateType.Full);

    const parentGroupId = instance.groupId;
    groupedUpdates[parentGroupId] = groupedUpdates[parentGroupId] || getDefaultDrawingUpdatePayload(parentGroupId);
    groupedUpdates[parentGroupId].created[instance.id] = instance as DrawingsGeometryInstance;
  }

  const updated = arrayUtils.toDictionary(
    arrayUtils.filterIterator(updates.updatedGeometries, x => drawingInstancesSet.has(x.id)),
    x => x.id,
    ({ id, updateType }) => processInstance(drawings, id, updateType),
  );
  const deleted = [
    ...arrayUtils.filterIterator(updates.removedGeometries, x => updates.removedGeometriesPages[x] === pageId),
  ];
  let updatePayload = Object.values(groupedUpdates);
  if (!updatePayload.length) {
    updatePayload = [getDefaultDrawingUpdatePayload()];
  }

  updatePayload[0].updated = updated;
  updatePayload[0].deleted = deleted;
  return updatePayload;
}

function getDefaultDrawingUpdatePayload(targetGroupId?: string): DrawingsGeometryInstanceUpdatePayload {
  return { targetGroupId, created: {}, updated: {}, deleted: [] };
}

function updateChangesBatchByFiles(changes: DrawingsUpdateFiles[], updates: UpdatesState, state: DrawingsState): void {
  for (const update of changes) {
    if (update.operation === DrawingChangeOperation.Create) {
      if (isFileCreation(update.currentEntity)) {
        updates.createdFiles[update.entityId] = update.currentEntity;
      } else {
        updates.createdFolders[update.entityId] = update.currentEntity as DrawingsEditFileTreeItem;
      }
    } else if (update.operation === DrawingChangeOperation.Update) {
      const entity = update.currentEntity as DrawingsEditFileTreeItem;
      if (update.entityId in updates.createdFiles) {
        updates.createdFiles[update.entityId].name = entity.name;
        updates.createdFiles[update.entityId].parentId = entity.parentFolderId;
      } else {
        updates.updateFileSystem[update.entityId] = entity;
      }
    } else {
      for (const file of update.files) {
        if (update.operation === DrawingChangeOperation.Delete) {
          if (file.id in updates.createdFiles) {
            delete updates.createdFiles[file.id];
          } else {
            delete updates.updateFileSystem[file.id];
            updates.deletedFileSystemItems.push(update.entityId);
          }
          for (const page of file.pages) {
            if (page.drawingId in updates.updatePages) {
              delete updates.updatePages[page.drawingId];
            }

            if (updates.pagesWithUpdatedGeometry.has(page.drawingId)) {
              updates.pagesWithUpdatedGeometry.delete(page.drawingId);
              for (const instanceId of state.aiAnnotation.fileData[file.id][page.drawingId].instances) {
                updates.createdGeometries.delete(instanceId);
                updates.updatedGeometries = updates.updatedGeometries.filter(x => x.id !== instanceId);
                updates.removedGeometries.delete(instanceId);
              }
            }
          }
          updates.currentPage = update.currentPage;
        }
      }

      for (const folder of update.folders) {
        if (update.operation === DrawingChangeOperation.Delete) {
          if (folder.id in updates.createdFolders) {
            delete updates.createdFolders[folder.id];
          } else {
            delete updates.updateFileSystem[folder.id];
            updates.deletedFileSystemItems.push(update.entityId);
          }
        }
      }
    }
  }
}

function updateChangesBatchByPages(changes: DrawingsPageUpdates[], updates: UpdatesState, state: DrawingsState): void {
  for (const change of changes) {
    if (change.operation === DrawingChangeOperation.Update) {
      updates.updatePages[change.id] = change;
    }
    if (change.operation === DrawingChangeOperation.Delete) {
      if (change.id in updates.updatePages) {
        delete updates.updatePages[change.id];
      }
      if (updates.pagesWithUpdatedGeometry.has(change.id)) {
        updates.pagesWithUpdatedGeometry.delete(change.id);
        const shortInfo = state.drawingsInfo[change.id];
        for (const instanceId of state.aiAnnotation.fileData[shortInfo.pdfId][shortInfo.drawingId].instances) {
          updates.createdGeometries.delete(instanceId);
          updates.updatedGeometries = updates.updatedGeometries.filter(g => g.id !== instanceId);
          updates.removedGeometries.delete(instanceId);
        }
      }
      updates.deletedPages = [change.id];
      updates.selectedPages = change.selectedPages;
      updates.currentPage = change.currentPage;
    }
  }
}

function updateChangesBatchByGeometryUpdate(changes: DrawingsElementChanges[], updates: UpdatesState): void {
  for (const change of changes) {
    updates.pagesWithUpdatedGeometry.add(change.pageId);
    updates.pagesPia[change.pageId] = {
      ...(updates.pagesPia[change.pageId] || {}),
      ...change.pia,
    };
    for (const elementId of change.elementIds) {
      switch (change.operation) {
        case DrawingChangeOperation.Create:
          if (updates.removedGeometries.has(elementId)) {
            updates.removedGeometries.delete(elementId);
            delete updates.removedGeometriesPages[elementId];
            updates.updatedGeometries.push({ id: elementId, updateType: DrawingsInstancesUpdateType.Full });
          } else {
            updates.createdGeometries.add(elementId);
          }
          break;
        case DrawingChangeOperation.Delete:
          if (updates.createdGeometries.has(elementId)) {
            updates.createdGeometries.delete(elementId);
          } else {
            updates.updatedGeometries = updates.updatedGeometries.filter(g => g.id !== elementId);
            updates.removedGeometries.add(elementId);
            updates.removedGeometriesPages[elementId] = change.pageId;
          }
          updates.updatedGroups.measurements.delete(elementId);
          updates.movedGroups.measurements.delete(elementId);
          break;
        case DrawingChangeOperation.Update:
          if (!updates.createdGeometries.has(elementId)) {
            const updateInfo = updates.updatedGeometries.find(x => x.id === elementId);
            if (updateInfo) {
              updateInfo.updateType = updateInfo.updateType < change.updateType
                ? change.updateType
                : updateInfo.updateType;
            } else {
              updates.updatedGeometries.push({ id: elementId, updateType: change.updateType });
            }
          }
          break;
        default:
      }
    }
  }
}

function updateChangesBatchByGroupsUpdate(changes: DrawingGroupChanges[], updates: UpdatesState): void {
  for (const change of changes) {
    if (isGroupsCreateChange(change.data, change.operation)) {
      for (const group of change.data.groups) {
        if (updates.removedGroups.has(group.id)) {
          updates.removedGroups.delete(group.id);
        } else {
          updates.createdGroups[group.id] = group;
          if (change.pia && change.pia[group.id]) {
            updates.piaToSave[group.id] = change.pia[group.id];
          }
        }
      }
    } else {
      if (change.operation === DrawingChangeOperation.Pin) {
        updates.pinnedGroups = updates.pinnedGroups || new Set<string>();
      }
      for (const elementId of change.data.groups) {
        switch (change.operation) {
          case DrawingChangeOperation.Create:
            break;
          case DrawingChangeOperation.Delete:
            if (updates.createdGroups[elementId]) {
              delete updates.createdGroups[elementId];
            } else {
              if (updates.updatedGroups.groups.has(elementId)) {
                updates.updatedGroups.groups.delete(elementId);
              }
              updates.removedGroups.add(elementId);
            }
            if (updates.pinnedGroups?.has(elementId)) {
              updates.pinnedGroups.delete(elementId);
            }
            if (updates.piaToSave[elementId]) {
              delete updates.piaToSave[elementId];
            }
            break;
          case DrawingChangeOperation.Update:
            if (!updates.createdGroups[elementId]) {
              updates.updatedGroups.groups.add(elementId);
            }
            break;
          case DrawingChangeOperation.Move:
            if (!updates.createdGroups[elementId]) {
              updates.movedGroups.groups.add(elementId);
            }
            break;
          case DrawingChangeOperation.Pin:
            updates.pinnedGroups.add(elementId);
            break;
          default:
        }
      }
    }
    for (const elementId of change.data.measurements) {
      switch (change.operation) {
        case DrawingChangeOperation.Update:
          updates.updatedGroups.measurements.add(elementId);
          break;
        case DrawingChangeOperation.Move:
          updates.movedGroups.measurements.add(elementId);
          break;
        default:
      }
    }
  }
}

function createClearState(): UpdatesState {
  return {
    createdFolders: {},
    createdFiles: {},

    updateFileSystem: {},
    updatePages: {},

    deletedFileSystemItems: [],
    deletedPages: [],

    createdGeometries: new Set<string>(),
    removedGeometries: new Set<string>(),
    removedGeometriesPages: {},
    updatedGeometries: new Array<UpdatedGeometryState>(),
    pagesWithUpdatedGeometry: new Set<string>(),

    createdGroups: {},
    removedGroups: new Set<string>(),
    updatedGroups: {
      groups: new Set<string>(),
      measurements: new Set<string>(),
    },
    movedGroups: {
      groups: new Set<string>(),
      measurements: new Set<string>(),
    },
    pinnedGroups: null,

    projectId: null,

    piaToSave: {},
    pagesPia: {},
  };
}

interface ProjectSyncState {
  task: Task;
  updates: UpdatesState;
}

export function* drawingsUpdateSaga(): SagaIterator {
  try {
    DatadogLogger.log('DRAWINGS UPDATE SAGA INITIALIZED');
    const syncProjects: Record<number, ProjectSyncState> = {};
    function* runNext({ payload }: ActionWith<number>): SagaIterator {
      try {
        const currentSync = syncProjects[payload];
        if (currentSync?.updates) {
          currentSync.task = yield fork<any>(saveChangesWithDelay, currentSync.updates, runNext);
          currentSync.updates = null;
        } else {
          delete syncProjects[payload];
        }
      } catch (error) {
        console.error('drawing updates: update elements run next failed', error);
        throw new Error(error);
      }
    }
    yield takeEvery(DrawingsUpdateActionTypes.RUN_NEXT_COMMIT_OPERATION, runNext);
    yield takeEvery(
      DrawingsUpdateActionTypes.FORCE_SAVE,
      function* (): SagaIterator {
        try {
          for (const syncData of Object.values(syncProjects)) {
            if (!syncData.updates || syncData.updates?.drawingsState) {
              continue;
            }
            const drawings: DrawingsState =
              yield selectWrapper(x => x.drawings);
            syncData.updates.drawingsState = drawings;
          }
        } catch (error) {
          console.error('drawing updates: update elements run next failed', error);
        }
      },
    );
    yield takeEvery(
      DrawingsUpdateActionTypes.CLEAR_UPDATES,
      function* drop({ payload: projectId }: ActionWith<number>): SagaIterator {
        try {
          if (syncProjects[projectId]) {
            delete syncProjects[projectId];
          }
        } catch (error) {
          console.error('drawing updates: update elements clear update', error);
          throw new Error(error);
        }
      },
    );
    while (true) {
      const action = (yield take(DrawingsUpdateActionTypes.COMMIT_UPDATES)) as ActionWith<DrawingsChange>;
      const projectId = yield selectWrapper(x => x.projects.currentProject?.id);
      if (!projectId) {
        continue;
      }
      try {
        DatadogLogger.log(`DRAWINGS UPDATE PREPARE DATA, projectId: ${projectId}`);
        syncProjects[projectId] = syncProjects[projectId] || { updates: null, task: null };
        const currentProjectSync = syncProjects[projectId];
        currentProjectSync.updates = currentProjectSync.updates || createClearState();
        const { task, updates } = currentProjectSync;
        updates.projectId = projectId;
        if (isFileSystemChange(action.payload.changes, action.payload.source)) {
          updateChangesBatchByFiles(action.payload.changes, updates, yield selectWrapper(x => x.drawings));
        } else if (isPageChange(action.payload.changes, action.payload.source)) {
          updateChangesBatchByPages(action.payload.changes, updates, yield selectWrapper(x => x.drawings));
        } else if (isElementChangesSource(action.payload.changes, action.payload.source)) {
          updateChangesBatchByGeometryUpdate(action.payload.changes, updates);
        } else if (isGroupsChange(action.payload.changes, action.payload.source)) {
          updateChangesBatchByGroupsUpdate(action.payload.changes, updates);
        }
        DatadogLogger.log(`${DrawingsUpdateActionTypes.COMMIT_UPDATES} ${!task || !task.isRunning()}`, updates);
        if (!task || !task.isRunning()) {
          yield put(DrawingsUpdateActions.runNextCommitOperation(updates.projectId));
        }
      } catch (error) {
        DatadogLogger.error(`drawings update: save elements update for project ${projectId} failed`);
        console.error(`drawings update: save elements update for project ${projectId} failed`, error);
        yield put(DrawingsUpdateActions.clearUpdates(projectId));
        const currentProjectId = yield selectWrapper(x => x.projects.currentProject?.id);
        if (projectId === currentProjectId) {
          yield put(DrawingsActions.dropState());
          yield put(DrawingsActions.loadDrawingsData());
        }
      }
    }

  } catch (error) {
    DatadogLogger.error('update drawings failed', error);
    console.error('drawing elements: update elements failed', error);
  }
}
