import React from 'react';
import { AddPaymentMethodButton } from './add-payment-method-button';

import { PaymentMethodInfo } from './interfaces';
import { Manager } from './manager/manager';

interface Props {
  paymentMethods: PaymentMethodInfo[];
  selectedPaymentId: string;
  openPaymentManagementDialog: () => void;
  onSelectPayment: (paymentId: string) => void;
}

const PaymentManagerNewComponent: React.FC<Props> = ({
  paymentMethods,
  selectedPaymentId,
  openPaymentManagementDialog,
  onSelectPayment,
}) => {
  if (!paymentMethods?.length) {
    return (<AddPaymentMethodButton openAddPaymentDialog={openPaymentManagementDialog}/>);
  } else {
    return (
      <Manager
        paymentMethods={paymentMethods}
        selectedPaymentId={selectedPaymentId}
        openManagePaymentsDialog={openPaymentManagementDialog}
        onSelectPayment={onSelectPayment}
      />
    );
  }
};

export const PaymentManagerNew = React.memo(PaymentManagerNewComponent);
