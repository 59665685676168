import React from 'react';

import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';


export const ADD_SEATS_INSTRUCTION_DIALOG = 'ADD_SEATS_INSTRUCTION_DIALOG';

export const AddSeatsConfirmationDialog: React.FC = () => {
  return (
    <ConfirmationDialog
      name={ADD_SEATS_INSTRUCTION_DIALOG}
      title={`New seats aren't available in your subscription`}
      text={'Press "Choose another plan" and choose Pro subscription to be able to add new seats'}
      cancelButtonText='Okay'
      // zIndex={10000}
    />
  );
};
