import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';

export const useSubscriptionId = (): string => {
  const subscriptionId = useSelector<State, string>((state) => {
    const account = state.account;
    return account && account.ownCompanyBillingInfo
      && account.ownCompanyBillingInfo.subscriptionId || null;
  });
  return subscriptionId;
};
