
import { MagicSearchUtils } from 'common/components/drawings/helpers/geometry/magic-search';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { BucketAiMasks } from './bucket-ai-masks';
import { OneClickBase, OneClickBaseConfig } from './one-click-base';

export class BucketAIBase<T extends OneClickBaseConfig = OneClickBaseConfig> extends OneClickBase<T> {
  protected override async showMask(contours: ShortPointDescription[][]): Promise<void> {
    this.removeMasks();
    if (this._config.onShowMask) {
      this._config.onShowMask(contours);
    }
    const paths = await MagicSearchUtils.contoursUnion(contours);
    this._masks = new BucketAiMasks({
      layer: this._resultGroup,
      geometry: paths,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      withBorder: true,
      type: this._config.type,
    });
  }
}
