import styled from 'styled-components';

const Count = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  border-radius: 5px;
  background-color: ${p => p.theme.color.pale};
  margin-right: 5px;

  > p {
    line-height: 1;
    padding-top: 2px;
    color: ${p => p.theme.color.turquoise};
  }

`;

export const Styled = {
  Count,
};
