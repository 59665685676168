import styled, { keyframes } from 'styled-components';

import { SpinnerStyled } from 'common/components/spinner';

const Container = styled.div`
  height: 90px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin-left: 15px;
`;

const DropdownAppearAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const MenuContainer = styled.div`
  transform-origin: left top;
  animation: ${DropdownAppearAnimation} ${p => p.theme.duration.s} ${p => p.theme.function.oq};
`;

const PreviewImage = styled.div`
  position: relative;
  height: 60px;
  width: 88px;
  margin-right: 15px;
  border-radius: 10px;

  > svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
  }

  img {
    display: block;
    width: inherit;
    height: inherit;
    border-radius: 10px;
    position: relative;
    object-fit: cover;
  }

  ${SpinnerStyled.SpinnerContainer} {
    z-index: 0;
    border-radius: 10px;
  }

  ${SpinnerStyled.Spinner} {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: ${p => p.theme.color.background};
  }
`;

const TextContainer = styled.div`
  max-width: 130px;
  display: flex;
  flex-direction: column;
  .pdf-tabs-item__input {
    min-width: 80px;
    padding: 5px 3px;
    font-size: inherit;
    line-height: inherit;
    border-radius: 4px;
    outline: 0;
    color: ${p => p.theme.color.mainFont};
    background-color: ${p => p.theme.color.background};
    border: 1px solid ${p => p.theme.color.turquoise};
  }
  div {
    cursor: text;
  }
`;

const PageBubble = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: ${p => p.color || p.theme.color.red};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > p {
    color: #ffffff !important;
  }
`;


export const Styled = {
  Container,
  MenuContainer,
  PreviewImage,
  TextContainer,
  PageBubble,
};
