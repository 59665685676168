import {
  CopyPasteHotkey,
  DrawingHotkey,
  DrawingToolHotkey,
  ZoomHotkey,
  MoveDrawingHotkey,
} from 'common/components/drawings/utils/hotkey-utils';
import { Hotkey } from 'common/hotkeys';
import { arrayUtils } from 'common/utils/array-utils';

interface ShortcutData {
  name: string;
  hotKeys?: Hotkey[];
  mouseKeys?: Hotkey[];
}

interface HotKeys {
  shortcutToShow?: string;
  splitSymbol?: string;
  splitterToShow?: string;
}

interface Shortcut {
  name: string;
  hotKeys?: HotKeys[];
  mouseKeys?: HotKeys[];
}
interface ShortcutGroupInfo {
  name: string;
  title: string;
  description: string;
  shortcutList: Shortcut[];
}


function getShortcutList(shortcuts: ShortcutData[]): Shortcut[] {
  return shortcuts.map(x => {
    if (!x.hotKeys || !x.hotKeys.length) {
      return x as any;
    }
    const uniqueHotKeys = arrayUtils.uniqWith(
      x.hotKeys.filter(hotkey => !!hotkey),
      hotKey => hotKey.splitterToShow,
    );
    return { ...x, hotKeys: uniqueHotKeys };
  });
}

const toolsShortcutList: ShortcutData[] = [
  {
    name: 'Select',
    hotKeys: DrawingToolHotkey.SelectTool,
  },
  {
    name: 'Polyline',
    hotKeys: DrawingToolHotkey.PolylineTool,
  },
  {
    name: 'Polygon',
    hotKeys: DrawingToolHotkey.PolygonTool,
  },
  {
    name: 'Rectangle 2 points',
    hotKeys: DrawingToolHotkey.Rectangle2PointTool,
  },
  {
    name: 'Rectangle 3 points',
    hotKeys: DrawingToolHotkey.Rectangle3PointTool,
  },
  {
    name: 'Auto Count',
    hotKeys: DrawingToolHotkey.AutoCountTool,
  },
  {
    name: 'Count',
    hotKeys: DrawingToolHotkey.CountTool,
  },

  {
    name: 'Sticker',
    hotKeys: DrawingToolHotkey.StickersTool,
  },
  {
    name: 'Dimension Line',
    hotKeys: DrawingToolHotkey.RulerTool,
  },
  {
    name: 'Comment',
    hotKeys: DrawingToolHotkey.CommentTool,
  },
];

const generalShortcutList: ShortcutData[] = [
  {
    name: 'Copy Measurements',
    hotKeys: CopyPasteHotkey.Copy,
  },
  {
    name: 'Paste With a Point',
    hotKeys: CopyPasteHotkey.PasteWithPoint,
  },
  {
    name: 'Paste Measurements',
    hotKeys: CopyPasteHotkey.Paste,
  },
  {
    name: 'Cut Measurements',
    hotKeys: CopyPasteHotkey.Cut,
  },
  {
    name: 'Delete Measurements',
    hotKeys: DrawingHotkey.RemoveInstance,
  },
  {
    name: 'Select All Measurements',
    hotKeys: DrawingHotkey.SelectAll,
  },
  {
    name: 'Duplicate',
    hotKeys: DrawingHotkey.Duplicate,
  },
  {
    name: 'Modify selected measurements',
    mouseKeys: [new Hotkey('Double click')],
  },
  {
    name: 'Deselect, quit edit, switch to cursor',
    hotKeys: DrawingHotkey.Unselect,
  },
  {
    name: 'Select multiple measurements',
    hotKeys: [new Hotkey('Ctrl + Click')],
  },
  {
    name: 'Multi-select',
    mouseKeys: [new Hotkey('Mouse drag')],
  },
  {
    name: 'Show All',
    hotKeys: DrawingHotkey.ShowAll,
  },
  {
    name: 'Hide Measurements',
    hotKeys: DrawingHotkey.ShowOrHide,
  },
  {
    name: 'Focus',
    hotKeys: DrawingHotkey.Focus,
  },
  {
    name: 'Isolate',
    hotKeys: DrawingHotkey.Isolate,
  },
  {
    name: 'Measurements',
    hotKeys: DrawingHotkey.ToggleElementsPanel,
  },
  {
    name: 'Merge',
    hotKeys: DrawingHotkey.Unite,
  },
  {
    name: 'Subtract',
    hotKeys: DrawingHotkey.Subtract,
  },
  {
    name: 'Create Group',
    hotKeys: DrawingHotkey.GroupDrawings,
  },
  {
    name: 'AI Suggest',
    hotKeys: DrawingHotkey.ToggleAiSuggest,
  },
];

const navigationShortcutList: ShortcutData[] = [
  {
    name: 'Move drawing',
    mouseKeys: MoveDrawingHotkey.MoveDrawing,
  },
  {
    name: 'Zoom in / Zoom out',
    mouseKeys: [new Hotkey('Mouse wheel')],
  },
  {
    name: 'Zoom in',
    hotKeys: ZoomHotkey.ZoomIn,
  },
  {
    name: 'Zoom out',
    hotKeys: ZoomHotkey.ZoomOut,
  },
  {
    name: 'Home',
    hotKeys: ZoomHotkey.Home,
  },
];

const spreadsheetShortcutList: ShortcutData[] = [
  {
    name: 'Start or stop editing',
    hotKeys: [new Hotkey('Enter')],
  },
  {
    name: 'Select all cells into a range',
    hotKeys: [new Hotkey('Ctrl + A')],
  },
  {
    name: 'Copy to clipboard',
    hotKeys: CopyPasteHotkey.Copy,
  },
  {
    name: 'Paste from clipboard',
    hotKeys: CopyPasteHotkey.PasteWithPoint,
  },
  {
    name: 'Navigate focused cell',
    hotKeys: [new Hotkey('⭠, ⭡, ⭢, ⭣', { shortcutToShow: '⭠ + ⭡ + ⭢ + ⭣', splitSymbol: '+', splitterToShow: ',' })],
  },
  {
    name: 'Cancel editing',
    hotKeys: [new Hotkey('Esc')],
  },
  {
    name: 'Move focus to the right',
    hotKeys: [new Hotkey('Tab')],
  },
  {
    name: 'Move focus to the left',
    hotKeys: [new Hotkey('Shift + Tab')],
  },
];


export const ShortcutGroup: ShortcutGroupInfo[] = [
  {
    name: 'Tools',
    title: 'Tools',
    description: 'Enable the tools in Kreo with the help of the following hotkeys',
    shortcutList: getShortcutList(toolsShortcutList),
  },
  {
    name: 'General',
    title: 'General',
    description: 'Use the following shortcuts for general purposes in Kreo',
    shortcutList: getShortcutList(generalShortcutList),
  },
  {
    name: 'Navigation',
    title: 'Navigation',
    description: 'Use the following shortcuts for navigation in Kreo',
    shortcutList: getShortcutList(navigationShortcutList),
  },
  {
    name: 'Spreadsheet',
    title: 'Spreadsheet',
    description: 'Make your work in the spreadsheet more comfortable using the following shortcuts',
    shortcutList: getShortcutList(spreadsheetShortcutList),
  },
];
