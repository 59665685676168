import { Icons, RoundButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';

import { RenderIf } from 'common/components/render-if';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DNDSourceProps, withDragSourceWrapper } from 'common/utils/with-drag/with-drag-source-wrapper';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { DrawingsGeometryGroup } from '../..';
import { ToggleButton } from '../../../toggle-button';
import {
  DrawingsGeometryGroupWithNesting,
} from '../../interfaces/drawings-geometry-instance';
import { DrawingAnnotationFiltrationUtils } from '../../utils/drawing-annotation-filtration-utils';
import {
  DrawingsDragAndDropData, DrawingsDragAndDropType,
} from '../../utils/drawings-annotation-drag-utils';
import { WithGroupMeasureContextProps, withGroupMeasureContext } from '../contexts/group-measure-context';
import { DrawingsAnnotationLegendUserInfo } from '../drawings-annotation-legend-user-info';
import { EntityLabel } from '../entity-label';
import { GroupsMenuType } from '../groups-menu';
import { LegendItemAPI } from '../interfaces';
import { ColorIndicator } from './color-indicator';
import { Container } from './container';
import { GroupCount } from './count-indicator';
import { GroupTitleWrapper } from './group-title-wrapper';
import { GroupValues } from './group-values';
import { Styled } from './styled';

interface OwnProps extends AnalyticsProps, WithGroupMeasureContextProps {
  isOpen: boolean;
  isSelected: boolean;
  isHidden: boolean;
  group: DrawingsGeometryGroupWithNesting;
  groupName: string;
  nestingCount: number;
  canEditMeasurement: boolean;
  hasAssignedPia: boolean;
  hasInheritedPia: boolean;
  canChangeVisibility: boolean;
  isPinned: boolean;
  isHighlighted: boolean;
  onPinClick: (id: string) => void;
  onClick: (event: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroup) => void;
  onToggleOpen: (id: string) => void;
  onHiddenChange: (instancesIds: string[], isHidden: boolean) => void;
  onNameChange: (id: string, name: string) => void;
  onContextMenu: (e: React.MouseEvent<HTMLDivElement>, group: DrawingsGeometryGroup) => boolean;
  onDoubleClick: (instancesIds: string[]) => void;
  openGroupMenu: (
    event: React.MouseEvent<HTMLDivElement>,
    group: DrawingsGeometryGroupWithNesting,
    menuType: GroupsMenuType,
  ) => void;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  moveToGroup: (groupId: string, groupIds: string[], measurementIds: string[]) => void;
  getItemsToMove(): DrawingsDragAndDropData;
  panelMinWidth?: number;
  isOpenFilterPages?: boolean;
}

interface Props extends OwnProps, DNDSourceProps { }

interface ComponentState {
  hideCreateNew: boolean;
  hideVisibleToggle: boolean;
  hideAvatar: boolean;
  hideColorIndicator: boolean;
  hideCount: boolean;
  hideEntityLabel: boolean;
  hideTitle: boolean;
  hideOpenToggleIcon: boolean;
  hidePin: boolean;
  hideMeasurementsInfo: boolean;
  hideMeasurements: boolean;
}

const MIN_ITEM_WIDTH = 31;
const MIN_TITLE_WIDTH = 60;
const MAX_TITLE_WIDTH = 100;
const MAX_SEPARATOR_WIDTH = 50;

class DrawingsAnnotationLegendGroupComponent extends React.PureComponent<Props, ComponentState> {
  private containerSeparatorRef: HTMLDivElement;
  private containerAnnotationRef: HTMLDivElement;
  private containerTitleRef: HTMLDivElement;
  private containerPinRef: HTMLDivElement;
  private containerAddFolderRef: HTMLDivElement;

  private updateSizeExecutor: DeferredExecutor = new DeferredExecutor(100);
  private prevWidth: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      hideAvatar: false,
      hideVisibleToggle: false,
      hideColorIndicator: false,
      hideCount: false,
      hideEntityLabel: false,
      hideTitle: false,
      hideOpenToggleIcon: false,
      hidePin: false,
      hideMeasurements: false,
      hideMeasurementsInfo: false,
      hideCreateNew: false,
    };
  }

  public render(): React.ReactNode {
    const {
      group,
      isSelected,
      isOpen,
      isHidden,
      nestingCount,
      canEditMeasurement,
      hasAssignedPia,
      hasInheritedPia,
      isPinned,
      measureValues,
      isHighlighted,
      moveToGroup,
      isOpenFilterPages,
      groupName,
    } = this.props;
    const hideInfo = measureValues &&
      (measureValues.length === 2 && !this.state.hideMeasurementsInfo || measureValues.length === 1);

    return (
      <Container
        forwardedRef={this.setContainerAnnotationRef}
        onClick={this.onClick}
        isSelected={isSelected}
        hidePin={this.state.hidePin}
        nestingCount={nestingCount}
        onContextMenu={this.onContextMenu}
        hideCount={this.state.hideCount}
        group={group}
        moveToGroup={moveToGroup}
        isOpenFilterPages={isOpenFilterPages}
      >
        <GroupTitleWrapper
          isHighlighted={!isSelected && isHighlighted}
          isOpen={isOpen}
          hideOpenToggleIcon={this.state.hideOpenToggleIcon}
          hideTitle={this.state.hideTitle}
          onOpenChange={this.onOpenChange}
          getContainerTitleRef={this.setContainerTitleRef}
          disableEdit={!canEditMeasurement}
          onNameChange={this.onNameChange}
          name={groupName}
          setRenameMode={this.setRenameMode}
        />
        <Styled.SeparatorResizeContainer ref={this.setContainerSeparatorRef} />
        <Styled.ItemsContainer
          hideAvatar={this.state.hideAvatar}
          hideVisibleToggle={this.state.hideVisibleToggle}
          hideEntityLabel={this.state.hideEntityLabel}
          hideCreateNew={this.state.hideCreateNew}
        >
          <Styled.GroupValues hideInfo={hideInfo}>
            <GroupValues
              measureValues={measureValues}
              shouldHideIconsInfo={this.state.hideMeasurements}
              shouldHideInfo={this.state.hideMeasurementsInfo}
            />
          </Styled.GroupValues>
          <Styled.PinContainer ref={this.setContainerPinRef}>
            <ToggleButton
              tooltipId={group.name}
              active={isPinned}
              icon={Icons.PinFolder}
              activeIcon={Icons.PinFolder}
              tooltip='Pin'
              activeTooltip='Unpin'
              onToggle={this.onPinClick}
            />
          </Styled.PinContainer>
          <GroupCount
            count={group.drawingsCount}
            measureValues={measureValues}
            isOpenFilterPages={isOpenFilterPages}
          />
          <ColorIndicator
            color={group.color}
            hide={this.state.hideColorIndicator}
            openColorPicker={this.openColorPicker}
            canEditMeasurement={canEditMeasurement}
          />
          <Styled.CreateNew ref={this.setContainerAddFolderRef}>
            <RoundButton
              Icon={Icons.PlusSmall}
              diameter={20}
              onClick={this.openNewMenu}
              mood={canEditMeasurement ? 'secondary' : 'disabled'}
            />
          </Styled.CreateNew>
          <RenderIf condition={this.props.canChangeVisibility}>
            <ToggleButton
              activeIcon={Icons.Hide2D}
              icon={Icons.Show2D}
              tooltip='Hide'
              activeTooltip='Show'
              tooltipId={group.name}
              active={isHidden}
              onDoubleClick={this.onDoubleClick}
              onToggle={this.onVisibleToggle}
            />
          </RenderIf>
          <DrawingsAnnotationLegendUserInfo
            creatorId={group.creatorId}
            createdAt={group.createdAt}
          />
          <EntityLabel
            selected={isSelected}
            inherited={hasInheritedPia}
            assigned={hasAssignedPia}
          />
        </Styled.ItemsContainer>
      </Container>
    );
  }

  public componentDidMount(): void {
    this.prevWidth = this.containerAnnotationRef.offsetWidth;
    this.updateVisibilityState();
    this.props.sendUpdateApi(
      {
        updateRenderParams: () => this.updateSizeExecutor.execute(() => this.updateItemContainerWidth()),
      },
      this.props.group.id,
    );
  }

  public componentDidUpdate(): void {
    this.updateSizeExecutor.execute(() => this.updateItemContainerWidth());
  }

  public componentWillUnmount(): void {
    this.updateSizeExecutor.reset();
    this.props.sendUpdateApi(null, this.props.group.id);
  }

  @autobind
  private updateItemContainerWidth(): void {
    if (this.containerAnnotationRef) {
      this.updateVisibilityState();
      const itemWidth = this.containerAnnotationRef.offsetWidth;
      if (itemWidth !== this.prevWidth) {
        this.prevWidth = itemWidth;
      }
    }
  }

  @autobind
  private updateVisibilityState(): void {
    const {
      hideAvatar,
      hideEntityLabel,
      hideVisibleToggle,
      hideOpenToggleIcon,
      hideTitle,
      hideColorIndicator,
      hideCount,
      hideMeasurements,
      hideMeasurementsInfo,
      hideCreateNew,
    } = this.state;
    const prevWidth = this.prevWidth;
    const { panelMinWidth } = this.props;

    const itemWidth = this.containerAnnotationRef.offsetWidth;
    const separatorWidth = this.containerSeparatorRef.offsetWidth;
    const titleWidth = this.containerTitleRef.offsetWidth;
    const isUpdatePanel = this.containerPinRef.offsetWidth === 0 || this.containerAddFolderRef.offsetWidth === 0;
    const { showItem, hideItem } = this.shouldChangeVisibilityState();
    if (panelMinWidth !== 0 && isUpdatePanel) {
      if (hideMeasurementsInfo) {
        if (showItem && prevWidth < itemWidth && !hideAvatar) {
          this.setState({ hideMeasurementsInfo: false });
          return;
        }
      } else {
        if (hideItem) {
          this.setState({ hideMeasurementsInfo: true });
          return;
        }
      }

      if (hideMeasurementsInfo && hideItem && !hideAvatar) {
        this.setState({ hideAvatar: true });
        return;
      }
      if (hideMeasurementsInfo && showItem && hideAvatar && !hideEntityLabel) {
        this.setState({ hideAvatar: false });
        return;
      }

      if (hideAvatar && hideItem && !hideEntityLabel) {
        this.setState({ hideEntityLabel: true });
        return;
      }
      if (hideAvatar && showItem && hideEntityLabel && !hideColorIndicator) {
        this.setState({ hideEntityLabel: false });
        return;
      }

      if (hideEntityLabel && hideItem && !hideColorIndicator) {
        this.setState({ hideColorIndicator: true });
        return;
      }
      if (hideEntityLabel && showItem && hideColorIndicator && !hideMeasurements) {
        this.setState({ hideColorIndicator: false });
        return;
      }

      if (hideColorIndicator && hideItem && !hideMeasurements) {
        this.setState({ hideMeasurements: true });
        return;
      }
      if (hideColorIndicator && showItem && hideMeasurements && !hideCount) {
        this.setState({ hideMeasurements: false });
        return;
      }

      if (hideMeasurements && hideItem && !hideCount) {
        this.setState({ hideCount: true });
        return;
      }
      if (hideMeasurements && showItem && hideCount && !hideVisibleToggle) {
        this.setState({ hideCount: false });
        return;
      }

      if (hideCount && hideItem && !hideCreateNew) {
        this.setState({ hideCreateNew: true });
        return;
      }

      if (hideCount && showItem && hideCreateNew) {
        this.setState({ hideCreateNew: false });
        return;
      }

      if (hideCreateNew && hideItem && !hideVisibleToggle) {
        this.setState({ hideVisibleToggle: true });
        return;
      }
      if (hideCreateNew && showItem && hideVisibleToggle && !hideTitle) {
        this.setState({ hideVisibleToggle: false });
        return;
      }

      if (hideVisibleToggle && !hideTitle && titleWidth < 12 && titleWidth !== 0) {
        this.setState({ hideTitle: true });
        return;
      }
      if (hideVisibleToggle && separatorWidth > MAX_SEPARATOR_WIDTH && !hideOpenToggleIcon && hideTitle) {
        this.setState({ hideTitle: false });
        return;
      }

      if (hideTitle && separatorWidth === 0 && itemWidth < MIN_ITEM_WIDTH) {
        this.setState({ hideOpenToggleIcon: true });
      }
      if (hideTitle && separatorWidth > MIN_ITEM_WIDTH) {
        this.setState({ hideOpenToggleIcon: false });
      }
    }
  }

  private shouldChangeVisibilityState(): { showItem: boolean, hideItem: boolean } {
    const separatorWidth = this.containerSeparatorRef.offsetWidth;
    const titleWidth = this.containerTitleRef.offsetWidth;
    const hideItem = separatorWidth === 0 && titleWidth < MIN_TITLE_WIDTH;
    const showItem = separatorWidth > MAX_SEPARATOR_WIDTH || titleWidth > MAX_TITLE_WIDTH;
    return { showItem, hideItem };
  }

  @autobind
  private setContainerPinRef(ref: HTMLDivElement): void {
    this.containerPinRef = ref;
  }

  @autobind
  private setContainerAddFolderRef(ref: HTMLDivElement): void {
    this.containerAddFolderRef = ref;
  }

  @autobind
  private setContainerSeparatorRef(ref: HTMLDivElement): void {
    this.containerSeparatorRef = ref;
  }

  @autobind
  private setContainerAnnotationRef(ref: HTMLDivElement): void {
    this.containerAnnotationRef = ref;
  }

  @autobind
  private setContainerTitleRef(ref: HTMLDivElement): void {
    this.containerTitleRef = ref;
  }

  @autobind
  private openNewMenu(event: React.MouseEvent<HTMLDivElement>): void {
    this.props.openGroupMenu(event, this.props.group, GroupsMenuType.CreateNew);
  }

  @autobind
  private openColorPicker(event: React.MouseEvent<HTMLDivElement>): void {
    this.props.openGroupMenu(event, this.props.group, GroupsMenuType.Color);
  }

  @autobind
  private onClick(event: React.MouseEvent<HTMLDivElement>): void {
    const { onClick, group } = this.props;
    onClick(event, group);
  }

  @autobind
  private setRenameMode(renameMode: boolean): void {
    this.setState({ hidePin: renameMode });
  }

  @autobind
  private onVisibleToggle(): void {
    const { onHiddenChange, isHidden, group } = this.props;
    const instancesIds = DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds([group]);
    onHiddenChange(instancesIds, !isHidden);
  }

  @autobind
  private onDoubleClick(): void {
    const instancesIds = DrawingAnnotationFiltrationUtils.getDrawingsInstanceIds([this.props.group]);
    this.props.onDoubleClick(instancesIds);
  }

  @autobind
  private onPinClick(): void {
    this.props.sendToggleEvent(
      MetricNames.measureManager.pinFolder,
      this.props.isPinned,
      { folderName: this.props.group.name },
    );
    this.props.onPinClick(this.props.group.id);

  }

  @autobind
  private onContextMenu(event: React.MouseEvent<HTMLDivElement>): void {
    const { onContextMenu, group } = this.props;
    onContextMenu(event, group);
  }

  @autobind
  private onOpenChange(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const { onToggleOpen, group } = this.props;
    onToggleOpen(group.id);
  }


  @autobind
  private onNameChange(value: string): void {
    const { onNameChange, group } = this.props;
    if (group.name !== value) {
      onNameChange(group.id, value);
    }
  }
}

const specDrag = {
  beginDrag: (props: Props) => {
    const itemsToMove = props.isSelected
      ? props.getItemsToMove()
      : {
        groups: [props.group],
        measurements: [],
      };
    return itemsToMove;
  },
  canDrag: (props: Props) => props.canEditMeasurement,
};

const WrappedComponent = withAnalyticsContext(withGroupMeasureContext((DrawingsAnnotationLegendGroupComponent)));

export const DrawingsAnnotationLegendGroup = withDragSourceWrapper(
  WrappedComponent,
  specDrag,
  DrawingsDragAndDropType.DrawingGroup,
);
