import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useDisabledEffect, useHandleEnter } from './hooks';
import { Styled } from './styled';

interface Props {
  value: string;
  onBlur: (newValue: string) => void;

  placeholder?: string;
  onChange?: (newValue: string) => void;
  onFocus?: (newValue: string) => void;
  onKeyEnterDown?: () => void;
  disabled?: boolean;
  isAutoFocus?: boolean;
  inputId?: string;
  isArea?: boolean;
  areaRows?: number;
  maxLength?: number;
  maxValue?: number;
}

export const TextInput: React.FC<Props> = (props) => {
  const {
    placeholder = 'Enter a value',
    value,
    onChange,
    onBlur,
    onFocus,
    onKeyEnterDown,
    disabled,
    isAutoFocus,
    inputId,
    isArea,
    areaRows = 4,
    maxLength,
    maxValue,
  } = props;
  const [inputValue, setValue] = useState<string>(value);
  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
    if (maxValue) {
      if (e.target.value.length <= maxValue) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }
  }, [onChange, maxValue]);

  const changeTextArea = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }, [handleChange]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const trimmedValue = e.target.value.trim();
    if (!trimmedValue) {
      onBlur('');
      setValue('');
    } else {
      onBlur(trimmedValue);
    }
  }, [onBlur]);

  const handleFocus = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const trimmedValue = e.target.value.trim();
    if (onFocus) {
      onFocus(trimmedValue);
    }

    if (inputValue === ' ') {
      setValue('');
    }

    e.target.setSelectionRange(0, 0);
  }, [inputValue, onFocus]);

  const { handleEnterDown, inputRef } = useHandleEnter(isArea, onKeyEnterDown);
  useDisabledEffect(disabled, inputRef);

  if (isArea) {
    return (
      <Styled.TextAreaStyle
        placeholder={placeholder}
        value={inputValue}
        onChange={changeTextArea}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        autoFocus={isAutoFocus}
        onKeyDown={handleEnterDown}
        id={inputId}
        ref={inputRef as React.MutableRefObject<HTMLTextAreaElement>}
        rows={areaRows}
        maxLength={maxLength}
      />);
  }

  return (
    <Styled.InputStyle
      placeholder={placeholder}
      type='text'
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      disabled={disabled}
      autoFocus={isAutoFocus}
      onKeyDown={handleEnterDown}
      id={inputId}
      ref={inputRef as React.MutableRefObject<HTMLInputElement>}
    />
  );
};
