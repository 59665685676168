import { ShortPointDescription } from '../drawing-ai-annotation';
import { MagicSearchSnappedGeometry } from './magic-search-responses';

export interface PdfGeometry {
  id?: number;
  parentId?: number;
  isClosed?: boolean;
  text?: string;
  points: ShortPointDescription[];
  similarity: number;
  holes?: ShortPointDescription[][];
}

export enum PdfGeometryStatus {
  Empty = 'Empty',
  Succcess = 'Success',
  ReachedLimit = 'ReachedLimit',
  IGNORE = 'IGNORE',
}

export interface PdfGeometryResponse {
  geometries: PdfGeometry[];
  status: PdfGeometryStatus;
  response: PdfAiClickResponse;
  hasPoints?: boolean;
}

interface PdfCount {
  points: ShortPointDescription[];
  similarity: number;
}

export interface PdfCountResponse {
  status: PdfGeometryStatus;
  geometries: PdfCount[];
}

export interface PdfFinderGeometryResponse {
  similarity: number;
  geometries: PdfGeometry[];
}

export interface PdfFinderResponse {
  geometries: PdfGeometry[];
  status: PdfGeometryStatus;
}

export type PdfGeometryResults = PdfGeometry[];

export interface PdfStatistic {
  geometriesCount: number;
}

interface PdfAiContour {
  conf: number;
  points: ShortPointDescription[];
}

interface PdfAiClickWindowInfo {
  dpi: number;
  size: number;
}

interface PdfAiClickOutput {
  boxes: any[];
  contours: PdfAiContour[];
  maskContours: PdfAiContour[];
  points: any[];
  type: string;
  windows: [PdfAiClickWindowInfo, PdfAiClickWindowInfo];
  windowPolygon: ShortPointDescription[][];
}

export interface PdfAiClickResponse {
  output: PdfAiClickOutput;
  geometries: MagicSearchSnappedGeometry[];
}
