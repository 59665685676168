import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import {
  DrawingsAllowedPathType,
  DrawingsRenderParams,
  DrawingsShortInfo,
  MagicSearchState,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { Vector2Utils } from 'common/components/drawings/utils/math-utils/vector2-utils';
import { mathUtils } from 'common/utils/math-utils';
import { NewDrawingSettings } from '../../interfaces';
import { BucketAIBase } from '../wizzard/ai';
import { OneClickBaseConfig } from '../wizzard/ai/one-click-base';

interface AISearchBucketAIConfig extends OneClickBaseConfig {
  getDrawingInfo: () => DrawingsShortInfo;
  magicSearchDataObserver: ContextObserver<MagicSearchState>;
}

(window as any).MIN_RAND_POINTS = 1;
(window as any).MAX_RAND_POINTS = 5;

export class AISearchBucketAI extends BucketAIBase<AISearchBucketAIConfig> {
  private _resultGeometry: DrawingsAllowedPathType;

  constructor(config: AISearchBucketAIConfig) {
    super(config);
    this._config.newDrawingStylesObserver.subscribe(this.onUpdateDrawingStyles);
  }

  public override destroy(): void {
    super.destroy();
    if (this._resultGeometry) {
      this._resultGeometry.remove();
    }
  }

  public getGeometry(): ShortPointDescription[][] {
    if (!this._resultGeometry.children || this._resultGeometry.children.length === 1) {
      return [this._resultGeometry.segments.map(({ point }) => [point.x, point.y])];
    } else {
      return this._resultGeometry.children.map<ShortPointDescription[]>(
        (path: paper.Path) => path.segments.map<ShortPointDescription>(({ point }) => [point.x, point.y]),
      );
    }
  }

  public async findCorrectPolygon(contour: ShortPointDescription[][]): Promise<void> {
    this._points.forEach((point) => point.destroy());
    this._points = [];
    if (this._resultGeometry) {
      this._resultGeometry.remove();
      this._resultGeometry = null;
    }
    const points = this.generatePoints(contour);
    this._points = points.map((point, index) => this.createPoint(point, true, index));
    const drawingInfo = this._config.getDrawingInfo();
    const dpi = this._config.magicSearchDataObserver.getContext().dpi;
    const result = await this.getBucketResults({ isAuto: false, drawingInfo, dpi });
    if (result.geometries) {
      const path = new paper.Path(result.geometries[0].points.map((x) => new paper.Point(x)));
      path.addTo(this._resultGroup);
      path.closePath();
      this._resultGeometry = path;
      this.refreshGroupStyles();
    }
  }

  protected refreshGroupStyles(): void {
    const { strokeStyle, strokeWidth, color } = this._config.newDrawingStylesObserver.getContext();
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    const { stroke, fill } = this._config.colorCache.getPaperColor(color);
    this._resultGeometry.strokeColor = stroke;
    this._resultGeometry.fillColor = fill;
    this._resultGeometry.strokeWidth = strokeWidth / zoom;
    this._resultGeometry.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle, strokeWidth }, zoom);
  }

  @autobind
  protected updateRenderParams(params: DrawingsRenderParams): void {
    super.updateRenderParams(params);
    if (this._resultGeometry) {
      this.updateView(params, this._config.newDrawingStylesObserver.getContext());
    }
  }

  @autobind
  private onUpdateDrawingStyles(newStyle: NewDrawingSettings): void {
    if (this._resultGeometry) {
      this.updateView(this._config.renderParamsContextObserver.getContext(), newStyle);
    }
  }

  private updateView({ zoom }: DrawingsRenderParams, newStyle: NewDrawingSettings): void {
    const color = this._config.colorCache.getPaperColor(newStyle.color);
    this._resultGeometry.strokeWidth = newStyle.strokeWidth / zoom;
    this._resultGeometry.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
    this._resultGroup.strokeColor = color.stroke;
    this._resultGroup.fillColor = color.fill;
  }

  private generatePoints(contour: ShortPointDescription[][]): paper.Point[] {
    const box = contour[0].reduce(
      (acc, [x, y]) => {
        return {
          minX: Math.min(acc.minX, x),
          minY: Math.min(acc.minY, y),
          maxX: Math.max(acc.maxX, x),
          maxY: Math.max(acc.maxY, y),
        };
      },
      { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
    );

    const pointsCount = Math.floor(
      mathUtils.randomInRange((window as any).MIN_RAND_POINTS, (window as any).MAX_RAND_POINTS),
    );

    const randomPoints: paper.Point[] = [];
    while (randomPoints.length < pointsCount) {
      const x = Math.random() * (box.maxX - box.minX) + box.minX;
      const y = Math.random() * (box.maxY - box.minY) + box.minY;

      if (Vector2Utils.isPointInPolygon([x, y], contour[0])) {
        if (contour.slice(1).every((polygon) => !Vector2Utils.isPointInPolygon([x, y], polygon))) {
          randomPoints.push(new paper.Point(x, y));
        }
      }
    }

    return randomPoints;
  }
}
