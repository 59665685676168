import { RectangleButton, Text } from '@kreo/kreo-ui-components';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';

import { RenderIf } from 'common/components/render-if';
import { DateFormatConstants } from 'common/constants/date-formats';
import { CompanySubscriptionModel } from '../../../../../units/account/interfaces/company-subscription-model';
import { SubscriptionActivityStatus } from '../../../../../units/subscription/enums/subscription-activity-status';
import { EstimateSubscriptionModel } from '../../../../../units/subscription/interfaces/estimation-subscription-model';
import { useTotalInvoice } from '../../../hooks';
import { PAYMENTS_DETAILS_DIALOG } from '../../payment-details-dialog';
import { Styled } from './styled';

const DATA_NEXT_BILLING = 'date-next-billing';

interface Props {
  subscription: CompanySubscriptionModel;
  billingEstimation: EstimateSubscriptionModel;
  openDialog: (name: string) => void;
}

export const PaymentDetailsBlock: React.FC<Props> = ({ subscription, billingEstimation, openDialog }: Props) => {
  const isTrialActive = subscription?.trialEnd && subscription?.billingStatus === SubscriptionActivityStatus.Trial;

  const total = useTotalInvoice();

  const nextBillingDate = useMemo(() => {
    return billingEstimation?.nextBillingAt && !billingEstimation?.immediateInvoice
      ? moment(billingEstimation.nextBillingAt).format(DateFormatConstants.SUBSCRIPTION_DATE_FORMAT)
      : null;
  }, [billingEstimation]);

  const handleOnDetailsClick = useCallback(() => {
    openDialog(PAYMENTS_DETAILS_DIALOG);
  }, [openDialog]);

  const promoCodes: string[] = useMemo(() => {
    if (billingEstimation?.nextInvoice) {
      return billingEstimation.nextInvoice.discounts.map(d => d.description);
    }
    if (billingEstimation?.immediateInvoice) {
      return billingEstimation.immediateInvoice.discounts.map(d => d.description);
    }
  }, [billingEstimation]);

  if (!nextBillingDate && !total && !isTrialActive) {
    return null;
  }

  return (
    <Styled.PaymentDetails>
      <RenderIf condition={!!promoCodes?.length}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Applied promo codes: '}</pre>
          </Text>
          <Styled.PromoCodesList>
            {promoCodes?.join(', ')}
          </Styled.PromoCodesList>
        </Text>
      </RenderIf>
      <RenderIf condition={isTrialActive}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Trial end at '}</pre>
          </Text>
          <pre>
            {moment(subscription?.trialEnd).format(DateFormatConstants.SUBSCRIPTION_DATE_FORMAT)}
          </pre>
        </Text>
      </RenderIf>
      <RenderIf condition={!!nextBillingDate}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Next billing at '}</pre>
          </Text>
          <pre data-control-name={DATA_NEXT_BILLING}>{nextBillingDate}</pre>
        </Text>
      </RenderIf>
      <RenderIf condition={!!total}>
        <Text fontSize={14}>
          <Text fontSize={14} color="additionalFont">
            <pre>{'Total: '}</pre>
          </Text>
          £{total}
        </Text>
        <RenderIf condition={!!billingEstimation}>
          <Styled.DetailsButton>
            <RectangleButton
              size="s"
              height={18}
              width={72}
              onClick={handleOnDetailsClick}
              text={'More details'}
            />
          </Styled.DetailsButton>
        </RenderIf>
      </RenderIf>
    </Styled.PaymentDetails>
  );
};
