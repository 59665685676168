import { CursorHintType } from 'common/components/drawings/layout-components/drawings-cursor-hint';
import { BucketAIHoverPreview } from './bucket-ai-hover-preview';
import { BucketAIBase } from './bucket-ai-with-processing';
import { GeometryType } from './geometry-types';
import { OneClickStates, OneClickTool } from './one-click-tool';


const MESSAGES: Record<OneClickStates, CursorHintType> = {
  [OneClickStates.Progress]: CursorHintType.BucketFillInProgress,
  [OneClickStates.OutOfArea]: CursorHintType.BucketAIOutOffArea,
  [OneClickStates.NotFound]: CursorHintType.BucketFillNotFound,
  [OneClickStates.NoPoints]: CursorHintType.BucketAINoPoints,
  [OneClickStates.OnlyNegativePoints]: CursorHintType.BucketAIOnlyNegativePoints,
  [OneClickStates.Expand]: CursorHintType.BucketAIExpandArea,
  [OneClickStates.AddNegative]: CursorHintType.BucketAINegativePoint,
};

export class BucketAI extends OneClickTool {
  public override onHover(point: paper.Point): void {
    super.onHover(point);
    if (this._config.oneClickAreaHoverContextObserver.getContext() && !this._ai.hasPoints()) {
      this._hoverPreview.findMask(point);
    }
  }

  protected override init(): void {
    this._hoverPreview = new BucketAIHoverPreview({ ...this._config, type: GeometryType.Polygon });
    this._ai = new BucketAIBase({
      ...this._config,
      onShowMask: () => this.removePreview(),
      withPolygonizer: true,
      type: GeometryType.Polygon,
      onPointRemoveClick: this.onRemovePoint,
    });
  }


  protected override async getPointStatus(point: paper.Point): Promise<{ isValid: boolean, isPositive: boolean }> {
    let isPositive = false;
    let isValid = true;
    if (this._snapshots.length > 0) {
      if (this._ai.masks?.masksCount) {
        isPositive = !this._ai.masks.isInsideMasks(point);
        if (isPositive && this._lastWindow?.length && this._ai.windowsHelper) {
          isValid = (await this._ai.windowsHelper.isPointInWindow(point, this._lastWindow) ?? true);
        }
      } else {
        isPositive = this._preview?.isPointOutsideLastPolygon(point) ?? true;
        if (this._lastWindow?.length && isPositive && this._ai.windowsHelper && this._preview) {
          isValid = await this._ai.windowsHelper.isPointInWindow(
            point,
            this._lastWindow,
          );
        }
      }
    } else {
      isPositive = true;
    }
    return { isValid, isPositive };
  }

  protected updateMessage(state: OneClickStates): void {
    this._config.setCursorMessage(MESSAGES[state]);
  }

  protected override checkExtensionState(): OneClickStates {
    if (this._preview) {
      const point = this._currentPoint;
      if (this._preview.isPointOutsideLastPolygon(point)) {
        return OneClickStates.Expand;
      } else {
        return OneClickStates.AddNegative;
      }
    }
  }
}
