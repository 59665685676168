import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { CommentZoneStyle, DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { FinishedDrawingElement } from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempDragRectangleEntity,
} from './drawings-geometry-temp-rectangle-drag-entity';


const ONE_POINT_THRESHOLD = 10;


export class DrawingsGeometryTempCommentRect extends DrawingsGeometryTempDragRectangleEntity {
  protected _path: paper.Path.Rectangle;
  protected _startPoint: paper.Point;
  protected _lastPoint: paper.Point;
  protected _rect: paper.Rectangle;


  public override convert(): FinishedDrawingElement[] {
    const threshold = ONE_POINT_THRESHOLD / this._config.renderParametersContextObserver.getContext().zoom;
    if (this._rect.size.width < threshold && this._rect.size.height < threshold) {
      return [
        {
          type: DrawingsInstanceType.Comment,
          geometry: null,
          points: {
            startPoint: [this._startPoint.x, this._startPoint.y] as ShortPointDescription,
          },
        },
      ];
    }
    const [x1, x2] = [this._startPoint.x, this._lastPoint.x].sort((a, b) => a - b);
    const [y1, y2] = [this._startPoint.y, this._lastPoint.y].sort((a, b) => a - b);
    return [
      {
        type: DrawingsInstanceType.Comment,
        geometry: null,
        points: {
          startPoint: [x1, y1] as ShortPointDescription,
          endPoint: [x2, y2] as ShortPointDescription,
        },
      },
    ];
  }


  public override updateTempPointPosition(point: paper.Point): paper.Point {
    if (!this._path.segments.length) {
      return;
    }
    this.renderPath(point);
  }

  protected override render(point: paper.Point): void {
    this._rect = new paper.Rectangle(point, new paper.Size(0, 0));
    this._startPoint = point;
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    this._path = new paper.Path.Rectangle(this._rect, CommentZoneStyle.borderRadius);
    this.renderPath(point);
    this._path.strokeColor = DrawingsCanvasColors.utility;
    this._path.shadowColor = DrawingsCanvasColors.utility;
    this._path.strokeWidth = this._config.strokeWidth / zoom;

    this._path.shadowBlur = CommentZoneStyle.shadowOffset / zoom;
    this._path.dashArray = DrawingsCanvasUtils.scaleDashArray(
      CommentZoneStyle.dashArray, CommentZoneStyle.strokeWidth, zoom);
    this._path.fillColor = null;
    this._path.addTo(this._config.layer);
  }

  @autobind
  protected override updateStrokeParams(): void {
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    const radius = CommentZoneStyle.borderRadius.divide(zoom);
    const rectangle = new paper.Path.Rectangle(this._rect, radius);
    this._path.removeSegments();
    this._path.addSegments(rectangle.segments);
    this.applyZoomToStroke(zoom);
  }

  private renderPath(point: paper.Point): void {
    const [x1, x2] = [this._startPoint.x, point.x].sort((a, b) => a - b);
    const [y1, y2] = [this._startPoint.y, point.y].sort((a, b) => a - b);
    this._rect.point = new paper.Point(x1, y1);
    this._rect.size = new paper.Size(x2 - x1, y2 - y1);
    this._lastPoint = point;
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    const radius = CommentZoneStyle.borderRadius.divide(zoom);
    const rectangle = new paper.Path.Rectangle(this._rect, radius);
    this._path.removeSegments();
    this._path.addSegments(rectangle.segments);
    this.applyZoomToStroke(zoom);
    this._path.closePath();
  }

  private applyZoomToStroke(zoom: number): void {
    this._path.dashArray = DrawingsCanvasUtils.scaleDashArray(
      CommentZoneStyle.dashArray, CommentZoneStyle.strokeWidth, zoom);
    this._path.strokeWidth = CommentZoneStyle.strokeWidth / zoom;
    this._path.shadowBlur = CommentZoneStyle.shadowOffset / zoom;
  }
}
