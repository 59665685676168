import { PiaCalculatedPropertyValue } from '2d/index';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { FormulaPartTypes } from 'common/components/formula-editor/input/enums/formula-input-part-type';
import { FormulaInputParsers } from 'common/components/formula-editor/input/helpers/formula-parsers';
import { ExcelExpressionEvaluator } from 'common/excel-expression-evaluator';
import { arrayUtils } from 'common/utils/array-utils';
import { UnitTypes } from 'common/utils/unit-util';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { FormatTypeGuards } from 'unit-2d-database/helpers/format-typeguards';
import { PropertiesTypeGuards } from 'unit-2d-database/helpers/properties-typeguards';
import * as Pia from 'unit-2d-database/interfaces';
import {
  MeasuredValueGetter,
  PrecompiledFormula,
  PrecompiledFormulaApi,
  PropertyCalculator,
  Status,
  Value,
} from './interfaces';
import { convertValue, getFormatedValue, isPropertyBreakDownProperty } from './utils';

interface ItemConfig {
  item: Pia.Item;
  parent: PropertyCalculator;
  readonly originProperties: Record<string, Pia.Property>;
  getMeasuredValue: MeasuredValueGetter;
  precompiledFormulas: PrecompiledFormulaApi;
}

interface FormulaParseResult {
  updatedFormula: string;
  params: Record<string, string>;
  onlyConstants: boolean;
  nameToParams: Record<string, string>;
  measuredFunctions: string[];
  constants: Record<string, string | number>;
  isValid: boolean;
}

export class Item implements PropertyCalculator {
  private _item: Pia.Item;
  private _parent: PropertyCalculator;
  private _properties: Record<string, Pia.Property>;

  private _cachedProperties: Map<string, Value>;

  private readonly _originProperties:  Record<string, Pia.Property>;
  private _getMeasuredValue: MeasuredValueGetter;

  private _precompiledFormula: PrecompiledFormulaApi;

  constructor(
    {
      item,
      parent,
      originProperties,
      getMeasuredValue,
      precompiledFormulas: precompiledFormulas,
    }: ItemConfig,
  ) {
    this._precompiledFormula = precompiledFormulas;
    this._item = item;
    this._properties = arrayUtils.toDictionaryByKey(item.properties, x => x.name);
    this._parent = parent;
    this._originProperties = originProperties;
    this._cachedProperties = new Map();
    this._getMeasuredValue = getMeasuredValue;
  }

  public getPropertyNames(): string[] {
    return Object.keys(this._properties);
  }

  public getName(): string {
    return this._item.name;
  }

  public hasProperty(propertyName: string): boolean {
    return propertyName in this._properties;
  }

  public getPropertyValue(propertyName: string, formulaPath: Pia.Property[], withPercentageConversion: boolean): Value {
    if (!this._cachedProperties.has(propertyName)) {
      const property = this.getProperty(propertyName);
      if (property) {
        this.calculatePropertyValue(property, formulaPath, propertyName);
      } else {
        const value = this._parent.getPropertyValue(propertyName, formulaPath, withPercentageConversion);
        this._cachedProperties.set(propertyName, value);
      }
    }
    const calculatedProperty = this._cachedProperties.get(propertyName);
    const { format, value } = calculatedProperty
      ? calculatedProperty
      : {
        format: undefined,
        value: undefined,
      };
    const shouldConvert =
      format && withPercentageConversion && FormatTypeGuards.isNumeric(format) && format.unit === UnitTypes.Percentage;
    return shouldConvert
      ? { ...calculatedProperty, value: (value as number) / 100 }
      : calculatedProperty;
  }

  public calculate(): Record<string, PiaCalculatedPropertyValue> {
    const itemProperties: Record<string, PiaCalculatedPropertyValue> = {};
    for (const property of this._item.properties) {
      if (!property.value) {
        continue;
      }
      const value = this.getPropertyValue(property.name, [], false);
      if (!value) {
        continue;
      }
      itemProperties[property.name] = {
        value: value.value,
        format: property.value.format,
      };
    }
    return itemProperties;
  }

  private setPropertyValue(
    propertyName: string,
    value: string | number,
    status: Status,
    format: Pia.BaseFormat,
  ): void {
    this._cachedProperties.set(propertyName, {
      status,
      format,
      value,
    });
  }

  private getProperty(propertyName: string): Pia.Property {
    const [name] = isPropertyBreakDownProperty(propertyName);
    return this._properties[name];
  }

  private calculatePropertyValue(
    property: Pia.Property,
    formulaPath: Pia.Property[],
    requestedPropertyName: string,
  ): void {
    const originProperty = this._originProperties[property.name];
    if (property.value === null || property.value === undefined) {
      return;
    }
    if (!PropertiesTypeGuards.isFormula(property)) {
      if (PropertiesTypeGuards.isBreakdown(property)
        && originProperty
        && PropertiesTypeGuards.isBreakdown(originProperty)) {
        const [, level] = isPropertyBreakDownProperty(requestedPropertyName);
        const value = getNameById(originProperty.value.root, property.value.value, level);
        this.setPropertyValue(
          requestedPropertyName,
          value,
          Status.Ok,
          property.value.format,
        );
      } else {
        this.setPropertyValue(
          property.name,
          property.value.value,
          Status.Ok,
          property.value.format,
        );
      }
    } else {
      if (formulaPath.includes(property)) {
        this.setFormulaPathInvalid(formulaPath, 'ERR: INFINITY LOOP');
        return;
      }
      const newFormulaPath = formulaPath.concat(property);
      if (this._precompiledFormula.getFormula(property.value.value as string)) {
        this.calculatePrecompiledFormula(property, newFormulaPath);
      } else {
        this.calculateFormula(property, newFormulaPath);
      }
    }
  }

  private calculatePrecompiledFormula(property: Pia.Property, formulaPath: Pia.Property[]): void {
    const precompiledFormula = this._precompiledFormula.getFormula(property.value.value as string);
    if (precompiledFormula.onlyConstants && precompiledFormula.value !== undefined) {
      const formatedValue = getFormatedValue(precompiledFormula.value, property);
      this.setPropertyValue(property.name, formatedValue, Status.Ok, property.value.format);
      return;
    }
    const params = { ...precompiledFormula.constants };
    for (const [key, value] of Object.entries(precompiledFormula.params)) {
      const propertyValueObject = this.getPropertyValue(key, formulaPath, true);
      if (!propertyValueObject) {
        return;
      }
      const { value: propertyValue, status } = propertyValueObject;
      if (propertyValue === undefined || status === Status.Error || propertyValue === null) {
        this.setFormulaPathInvalid(formulaPath);
        return;
      }
      params[value] = propertyValue;
    }

    for (const measuredParam of precompiledFormula.measuredParams) {
      const measuredValue = this._getMeasuredValue(measuredParam);
      if (measuredValue === null || measuredValue === undefined) {
        this.setFormulaPathInvalid(formulaPath);
        return;
      }
      params[measuredParam] = measuredValue;
    }
    try {
      const { expression, onlyConstants } = precompiledFormula;
      const value = expression.evaluate(params as any);
      if (typeof value !== 'string' && Number.isNaN(value)) {
        this.setFormulaPathInvalid(formulaPath);
      } else if (!onlyConstants && typeof value === 'number') {
        const convertedValue = convertValue(property.value.format, value);
        this.setPropertyValue(property.name, convertedValue, Status.Ok, property.value.format);
      } else {
        const formatedValue = getFormatedValue(value, property);
        this.setPropertyValue(property.name, formatedValue, Status.Ok, property.value.format);
      }
    } catch (e) {
      this.setFormulaPathInvalid(formulaPath);
      console.warn(`cannot calculate precompiled formula`, precompiledFormula);
    }
  }

  private calculateFormula(property: Pia.Property, formulaPath: Pia.Property[]): void {
    const {
      params,
      onlyConstants,
      updatedFormula,
      measuredFunctions,
      nameToParams,
      constants,
      isValid,
    } = this.parseFormula(property.value.value as string, formulaPath);
    try {
      if (!updatedFormula) {
        this.setPropertyValue(property.name, '', Status.Ok, property.value.format);
        return;
      }
      const expression = ExcelExpressionEvaluator.parse(ExcelFormulaHelper.replaceBinaryOpts(updatedFormula));
      const precompiledFormulaInfo: PrecompiledFormula = {
        expression,
        onlyConstants,
        params: nameToParams,
        measuredParams: measuredFunctions,
        constants,
      };
      this._precompiledFormula.setFormula(property.value.value as string, precompiledFormulaInfo);
      if (!isValid) {
        this.setFormulaPathInvalid(formulaPath);
        return;
      }
      const value = expression.evaluate(params as any);
      if (typeof value !== 'string' && Number.isNaN(value)) {
        this.setFormulaPathInvalid(formulaPath);
      } else if (!onlyConstants && typeof value === 'number') {
        const convertedValue = convertValue(property.value.format, value);
        this.setPropertyValue(property.name, convertedValue, Status.Ok, property.value.format);
      } else {
        if (onlyConstants) {
          precompiledFormulaInfo.value = value;
        }
        const formatedValue = getFormatedValue(value, property);
        this.setPropertyValue(property.name, formatedValue, Status.Ok, property.value.format);
      }
    } catch (error) {
      this.setFormulaPathInvalid(formulaPath);
      console.warn(`cannot calculate formula ${updatedFormula}`);
    }
  }

  private parseFormula(
    formula: string, newFormulaPath: Pia.Property[],
  ): FormulaParseResult {
    let onlyConstants = true;
    let updatedFormula = '';
    const params = {};
    const constants = {};
    let constCount = 1;
    let propsCount = 1;
    const measuredFunctions = [];
    const nameToParamKey: Record<string, string> = {};
    let isValid = true;
    for (const { type, text } of FormulaInputParsers.formulaIterator(formula)) {
      switch (type) {
        case FormulaPartTypes.Property:
          const propertyName = FormulaInputParsers.getPropertyName(text);
          if (propertyName in nameToParamKey) {
            updatedFormula += nameToParamKey[propertyName];
            break;
          }
          const propertyValueObject = this.getPropertyValue(propertyName, newFormulaPath, true);
          if (!propertyValueObject) {
            isValid = false;
          }
          const { value: propertyValue, status } = propertyValueObject
            ? propertyValueObject
            : { value: undefined, status: Status.Error };
          if (propertyValue === undefined || status === Status.Error || propertyValue === null) {
            isValid = false;
          }
          const propsKey = `${FormulaPartTypes.Property}${propsCount}`;
          params[propsKey] = propertyValue;
          nameToParamKey[propertyName] = propsKey;
          updatedFormula += propsKey;
          onlyConstants = false;
          propsCount++;
          break;
        case FormulaPartTypes.FunctionSplitted:
          updatedFormula += text.toLocaleLowerCase();
          break;
        case FormulaPartTypes.FunctionOpen:
          updatedFormula += text.toLocaleLowerCase();
          break;
        case FormulaPartTypes.MeasureFunction:
          const measureFunctionName = text.slice(0, text.length - 2);
          const measuredValue = this._getMeasuredValue(measureFunctionName);
          if (measuredValue === null || measuredValue === undefined) {
            isValid = false;
          }
          params[measureFunctionName] = measuredValue;
          measuredFunctions.push(measureFunctionName);
          updatedFormula += measureFunctionName;
          onlyConstants = false;
          break;
        case FormulaPartTypes.Constant:
          const constKey = `${FormulaPartTypes.Constant}${constCount}`;
          params[constKey] = this.parseConst(text);
          constants[constKey] = params[constKey];
          updatedFormula += constKey;
          constCount++;
          break;
        case FormulaPartTypes.Spaces:
          break;
        default:
          updatedFormula += text;
      }
    }
    return {
      constants,
      updatedFormula,
      params,
      onlyConstants,
      nameToParams: nameToParamKey,
      measuredFunctions,
      isValid,
    };
  }

  private setFormulaPathInvalid(formulaPath: Pia.Property[], errorText: string = 'INVALID'): void {
    for (const { name } of formulaPath) {
      this.setPropertyValue(name, errorText, Status.Error, null);
    }
  }

  private parseConst(text: string): string | number {
    return text.startsWith('"') ? text.replace(/"/gi, '') : Number(text);
  }
}
