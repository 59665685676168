import { objectUtils } from 'common/utils/object-utils';
import { DrawingsShortInfo, ShortPointDescription, TextSearchSettings } from '../interfaces';
import { CancelableAction, CancelableResult, DocsLoader } from '../interfaces/util';

interface LoadingState {
  isCanceled: boolean;
}

interface SearchResultItem {
  ambient: string;
  output: string;
  contour: ShortPointDescription[];
}

type Result = Record<string, SearchResultItem[]> | null;

async function searchInDoc(
  doc: Core.PDFNet.PDFDoc,
  pattern: string,
  pageInfo: Record<number, string[]>,
  modes: number,
  loadingState: LoadingState,
): Promise<Record<string, SearchResultItem[]>> {
  const txtSearch = await Core.PDFNet.TextSearch.create();
  const result: Record<string, SearchResultItem[]> = {};

  await doc.lock();
  await txtSearch.setMode(modes);
  await txtSearch.setPattern(pattern);
  await txtSearch.begin(doc, pattern, modes);
  let searchResult = await txtSearch.run();
  const pageResults: Record<number, SearchResultItem[]> = {};
  while (searchResult.code === Core.PDFNet.TextSearch.ResultCode.e_found) {
    if (loadingState.isCanceled) {
      return result;
    }
    const currentPageNumber = searchResult.page_num - 1;
    if (!pageInfo[currentPageNumber]) {
      searchResult = await txtSearch.run();
      continue;
    }
    const hlts = searchResult.highlights;
    await hlts.begin(doc);
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    const curPage = await doc.getPage(searchResult.page_num);
    const geometry = await curPage.getDefaultMatrix(true);
    let hasHighlight = false;
    while (await hlts.hasNext()) {
      const quadArr = await hlts.getCurrentQuads();
      if (quadArr.length) {
        hasHighlight = true;
      }
      for (const { p1x, p2x, p3x, p4x, p1y, p2y, p3y, p4y } of quadArr) {
        const p1 = await geometry.mult(p1x, p1y);
        const p2 = await geometry.mult(p2x, p2y);
        const p3 = await geometry.mult(p3x, p3y);
        const p4 = await geometry.mult(p4x, p4y);
        minX = Math.min(minX, p1.x, p2.x, p3.x, p4.x);
        minY = Math.min(minY, p1.y, p2.y, p3.y, p4.y);
        maxX = Math.max(maxX, p1.x, p2.x, p3.x, p4.x);
        maxY = Math.max(maxY, p1.y, p2.y, p3.y, p4.y);

      }
      hlts.next();
    }
    if (hasHighlight) {
      pageResults[currentPageNumber] = pageResults[currentPageNumber] || [];
      pageResults[currentPageNumber].push({
        ambient: searchResult.ambient_str,
        output: searchResult.out_str,
        contour: [[minX, minY], [maxX, minY], [maxX, maxY], [minX, maxY]],
      });
    }
    searchResult = await txtSearch.run();
  }
  doc.unlock();
  for (const [pageNumber, searchResults] of Object.entries(pageResults)) {
    for (const drawingId of pageInfo[pageNumber]) {
      result[drawingId] = searchResults;
    }
  }
  return result;
}

function getModes({ caseSensitive, wholeWord, regExp }: TextSearchSettings): number {
  let modes = Core.PDFNet.TextSearch.Mode.e_ambient_string + Core.PDFNet.TextSearch.Mode.e_highlight;
  if (caseSensitive) {
    modes += Core.PDFNet.TextSearch.Mode.e_case_sensitive;
  }
  if (wholeWord) {
    modes += Core.PDFNet.TextSearch.Mode.e_whole_word;
  }
  if (regExp) {
    modes += Core.PDFNet.TextSearch.Mode.e_reg_expression;
  }
  return modes;
}

async function search(
  loader: DocsLoader,
  drawings: Record<string, DrawingsShortInfo>,
  settings: TextSearchSettings,
  loadingState: LoadingState,
): Promise<Result> {
  const filePages: Record<string, Record<number, string[]>> = {};
  const separatePages: Array<[string, string]> = [];
  for (const [drawingId, { pdfId, pageNumber, filter }] of Object.entries(drawings)) {
    if (filter && filter.isEnabled && filter.state?.uselessElementStylesCount) {
      separatePages.push([pdfId, drawingId]);
    } else {
      filePages[pdfId] = filePages[pdfId] || {};
      filePages[pdfId][pageNumber] = filePages[pdfId][pageNumber] || [];
      filePages[pdfId][pageNumber].push(drawingId);
    }
  }

  const result: Result = {};
  const pagesData = Object.entries(filePages);

  while (pagesData.length || separatePages.length) {
    const currentData = pagesData.splice(0, 10);
    const promises = currentData.map(async ([pdfId, pagesInfo]) => {
      const doc = await loader.loadFileAsDoc({ fileId: pdfId, drawingId: pdfId, isFiltered: false });
      if (loadingState.isCanceled) {
        return {};
      }
      return searchInDoc(doc, settings.query, pagesInfo, getModes(settings), loadingState);
    });

    const currentSeparatedPages = separatePages.splice(0, 10);
    const separatedPagesPromises = currentSeparatedPages.map(async ([pdfId, drawingId]) => {
      const doc = await loader.loadFileAsDoc({
        fileId: pdfId,
        drawingId,
        pageNumber: drawings[drawingId].pageNumber,
        isFiltered: true,
      });
      if (loadingState.isCanceled) {
        return {};
      }
      return searchInDoc(doc, settings.query, { 0: [drawingId] }, getModes(settings), loadingState);
    });

    if (loadingState.isCanceled) {
      return {};
    }

    const results = await Promise.all(promises);
    for (const res of results) {
      objectUtils.extend(result, res);
    }

    const separatedPagesResults = await Promise.all(separatedPagesPromises);
    for (const res of separatedPagesResults) {
      objectUtils.extend(result, res);
    }

    if (loadingState.isCanceled) {
      return {};
    }
  }

  if (loadingState.isCanceled) {
    return {};
  }
  return result;
}

export function searchInDocs(
  loader: DocsLoader,
  drawings: Record<string, DrawingsShortInfo>,
  settings: TextSearchSettings,
): CancelableAction<Result> {
  const loadingState: LoadingState = { isCanceled: false };
  const promise = search(loader, drawings, settings, loadingState)
    .then<CancelableResult<Result>>((info) => {
      return {
        data: info,
        isFinished: !loadingState.isCanceled,
      };
    })
    .catch<CancelableResult<Result>>((err) => {
      if (!err.isCanceled) {
        throw err;
      }
      return { isFinished: false, data: null };
    });
  return {
    promise,
    cancel: () => {
      loader.cancel();
      loadingState.isCanceled = true;
    },
  };
}


