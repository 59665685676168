import {
  Input,
  ModalWrapper,
  RectangleButton,
} from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { Canceler } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, Field, FormSubmitHandler, formValueSelector, InjectedFormProps, isValid, reduxForm } from 'redux-form';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { NewFileUploadWrap } from 'common/components/file-upload-wrap';
import { RenderIf } from 'common/components/render-if';
import { ShareWithCompany } from 'common/components/share-with-company';
import { ApiConstants } from 'common/constants/api';
import { ControlNames } from 'common/constants/control-names';
import { METRIC_IDS } from 'common/constants/id-maps';
import { ProjectType } from 'common/constants/project-type';
import { UploadingFile } from 'common/interfaces/common-state';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { FileExtensionUtils } from 'common/utils/file-extension-utils';
import { InviteUsersToProjectPanel } from '../..';
import { Common } from '../../../../actions';
import { CREATE_PROJECT } from '../../../../constants/forms';
import { TwoDActions } from '../../../../units/2d/actions/creators';
import { Company } from '../../../../units/account/interfaces/company';
import { Person } from '../../../../units/people/interfaces/person';
import { ProjectsActions } from '../../../../units/projects/actions/creators/common';
import { ShortProjectHeader } from '../../../../units/projects/interfaces/short-project-header';
import Form from '../../../form';
import { ConfirmationDialog } from '../../confirmation-dialog';
import { projectValidateRequired } from '../create-project-common-dialog';
import { InvitePeopleButton } from './invite-people-button';
import { InvitedPeopleList } from './invited-people-list';
import { Styled } from './styled';
import { TemplatesDropdown } from './templates-dropdown';

export const CREATE_2D_PROJECT_DIALOG_NAME = 'create2dProject';
const CLOSE_CREATE_2D_PROJECT_DIALOG_NAME = 'closeCreate2dProject';

const selector = formValueSelector(CREATE_PROJECT);
const isValidFormSelector = isValid(CREATE_PROJECT);

interface StateProps {
  files: UploadingFile[];
  projectName: string;
  isDownloadFileInProgress: boolean;
  cancelDownLoadFile: Canceler;
  isOpen: boolean;
  disableSubmit: boolean;
  hasSomeFiles: boolean;
  templates: ShortProjectHeader[];
  projectType: ProjectType;
  canUseTemplates: boolean;
  selectedTemplateId: number;
  isSharedWithCompany: boolean;
  company: Company;
}

interface DispatchProps {
  setProjectName: (value: string) => void;
  setInvitedUsers: (value: string[]) => void;
  closeCreateProjectDialog: () => void;
  openDialog: (dialogName: string) => void;
  clearFiles: () => void;
  setUploadFiles: (projectId: number, files: string[]) => void;
  removeDownloadFileName: () => void;
  setType: (type: ProjectType) => void;
  setTemplate: (id: number) => void;
  fetchAllTemplates: () => void;
  setShareWithCompany: (value: boolean) => void;
}

interface OwnState {
  invitedPeople: string[];
  fileExtensions: string[];
}

interface OwnProps {
  sendEvent: (params: { name: string, files: string, inviteUsers: string, templateName?: string }) => void;
  onSubmit: () => void;
}


interface Props extends StateProps, DispatchProps, InjectedFormProps<{}, OwnProps>, OwnProps, AbilityAwareProps {
  projectType: ProjectType;
}

class Create2dProjectDialogComponent extends React.PureComponent<Props, OwnState> {


  public constructor(props: Props) {
    super(props);
    const isAdmin = props.ability.can(Operation.Admin, Subject.Application);
    const canConvertFiles = props.ability.can(Operation.Manage, Subject.TakeOff2dFileConverter);

    this.state = {
      invitedPeople: [],
      fileExtensions: FileExtensionUtils.get2DFileExtensions(isAdmin, canConvertFiles),
    };
  }

  public componentDidUpdate(prevProps: Props): void {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (this.props.canUseTemplates) {
        this.props.fetchAllTemplates();
      }
      const isAdmin = this.props.ability.can(Operation.Admin, Subject.Application);
      const canConvertFiles = this.props.ability.can(Operation.Manage, Subject.TakeOff2dFileConverter);
      this.setState({
        fileExtensions: FileExtensionUtils.get2DFileExtensions(isAdmin, canConvertFiles),
      });
    }
  }

  public render(): React.ReactNode {
    if (!this.props.isOpen) {
      return null;
    }
    const {
      isDownloadFileInProgress,
      disableSubmit,
      projectType,
      ability,
    } = this.props;
    const canShareProject = ability.can(Operation.Manage, Subject.ShareProjects);
    const create2dProjectMode = projectType === ProjectType.Project2d;
    const projectTypeName = create2dProjectMode ? 'project' : 'project template';
    const inviteButtonText = this.props.isSharedWithCompany ? 'Invite guests' : 'Invite people';

    return (
      <DialogWrapper name={CREATE_2D_PROJECT_DIALOG_NAME}>
        <ModalWrapper onExit={this.onClose}>
          <Styled.Container>
            <Form
              handleSubmit={this.handleSubmit}
              className='create-project-dialog'
              controlName={ControlNames.createProjectForm}
            >
              <Styled.FormContent>
                <Styled.FormContentGroup>
                  <Styled.FieldGroup>
                    <Field
                      width={'340px'}
                      name='name'
                      component={Input}
                      type='text'
                      text={create2dProjectMode ? 'Project name' : 'Project template'}
                      validate={projectValidateRequired}
                    />
                  </Styled.FieldGroup>
                  <Styled.Separator />
                  <Styled.InvitePeopleGroup
                    isInvitedPeople={!!this.state.invitedPeople.length}
                  >
                    <RenderIf condition={canShareProject}>
                      <ShareWithCompany
                        onChange={this.onSetShareWithCompany}
                        checked={this.props.isSharedWithCompany}
                        company={this.props.company}
                      />
                      <InvitedPeopleList invitedPeople={this.state.invitedPeople} />
                    </RenderIf>
                    <UpgradeWrapper isNeedUpdate={!canShareProject}>
                      <InvitePeopleButton
                        text={inviteButtonText}
                        canShareProject={canShareProject}
                      />
                    </UpgradeWrapper>
                  </Styled.InvitePeopleGroup>
                  <RenderIf condition={create2dProjectMode && this.props.canUseTemplates}>
                    <Styled.Separator />
                    <Styled.FieldGroup>
                      <TemplatesDropdown
                        templates={this.props.templates}
                        onChange={this.props.setTemplate}
                      />
                    </Styled.FieldGroup>
                  </RenderIf>
                  <Styled.CreateSeparator />
                  <Styled.FieldGroup id={METRIC_IDS.createProject}>
                    <RectangleButton
                      width={340}
                      height={60}
                      mood={disableSubmit ? 'disabled' : 'positive'}
                      text={this.getCreateProjectText(projectTypeName)}
                    />
                  </Styled.FieldGroup>
                </Styled.FormContentGroup>
                <Styled.Files>
                  <NewFileUploadWrap
                    isDownloadFileInProgress={isDownloadFileInProgress}
                    onDeleteFile={this.onDeleteFile}
                    formName={CREATE_2D_PROJECT_DIALOG_NAME}
                    extensions={this.state.fileExtensions}
                    extensionsToShow={this.state.fileExtensions}
                    onUploadStarted={this.onUploadStarted}
                  />
                </Styled.Files>
              </Styled.FormContent>
            </Form >
          </Styled.Container >
          <ConfirmationDialog
            name={CLOSE_CREATE_2D_PROJECT_DIALOG_NAME}
            title={`Are you sure you want to exit the "Create ${projectTypeName}" Mode?`}
            text='This action cannot be undone'
            confirmButtonText='Exit'
            onConfirm={this.onCloseDialog}
            cancelButtonText='Cancel'
          />
        </ModalWrapper >
        <InviteUsersToProjectPanel
          onFormSubmit={this.onInvitePeople}
          invitedUsers={this.state.invitedPeople}
          projectType={projectType}
          isSharedWithCompany={this.props.isSharedWithCompany}
          onChangeSharedWithCompany={this.onSetShareWithCompany}
        />
      </DialogWrapper >
    );
  }


  private getCreateProjectText(projectTypeName: string): string {
    const { disableSubmit, hasSomeFiles, selectedTemplateId } = this.props;
    const isNotEmptyProject = hasSomeFiles || selectedTemplateId || disableSubmit;
    return isNotEmptyProject ? `Create ${projectTypeName}` : `Create empty ${projectTypeName}`;
  }

  @autobind
  private onClose(): void {
    this.props.openDialog(CLOSE_CREATE_2D_PROJECT_DIALOG_NAME);
  }

  @autobind
  private onCloseDialog(): void {
    this.props.clearFiles();
    this.props.setProjectName('');
    this.props.setTemplate(null);
    this.props.closeCreateProjectDialog();
    this.props.setShareWithCompany(false);
    this.setState({ invitedPeople: [] });
    if (this.props.isDownloadFileInProgress) {
      this.props.cancelDownLoadFile(ApiConstants.RequestCancelMessage);
      this.props.removeDownloadFileName();
    }
  }

  private getExtensionsRegExp(extensions: string[]): RegExp {
    const regexExtensions = extensions
      .map(x => `\\.${x}`)
      .join('|');

    return new RegExp(`(${regexExtensions})$`, 'i');
  }

  @autobind
  private onDeleteFile(file: UploadingFile): void {
    const { projectName, setProjectName, files } = this.props;
    const fileExtensionRegExp = this.getExtensionsRegExp(this.state.fileExtensions);
    if (file && file.file.name.replace(fileExtensionRegExp, '') === projectName) {
      if (files.length > 1) {
        setProjectName(files[1].file.name.replace(fileExtensionRegExp, ''));
      } else {
        setProjectName('');
      }
    }
  }

  @autobind
  private handleSubmit(handler: FormSubmitHandler<{}, {}>): void {
    if (!this.props.disableSubmit) {
      this.props.setType(this.props.projectType);
      this.props.handleSubmit(handler);
      this.props.sendEvent({
        name: this.props.projectName,
        files: this.props.files.map(f => f.file.name).join(','),
        inviteUsers: this.state.invitedPeople.join(','),
        templateName: this.props.selectedTemplateId
          && this.props.templates.find(t => t.id === this.props.selectedTemplateId)?.name,
      });
      this.onCloseDialog();
    }
  }

  @autobind
  private onSetShareWithCompany(): void {
    this.props.setShareWithCompany(!this.props.isSharedWithCompany);

    if (this.props.isSharedWithCompany) {
      this.setState({ invitedPeople: [] });
    }
  }

  @autobind
  private onInvitePeople(users: Person[]): void {
    const emails = users.map(x => x.email);
    this.props.setInvitedUsers(emails);
    this.setState({
      invitedPeople: emails,
    });
  }

  @autobind
  private onUploadStarted(files: File[]): void {
    const { projectName, setProjectName } = this.props;
    if (!projectName && files && files.length) {
      const fileExtensionRegExp = this.getExtensionsRegExp(this.state.fileExtensions);
      setProjectName(files[0].name.replace(fileExtensionRegExp, ''));
    }
  }
}

function mapStateToProps(state: State): StateProps {
  const isDownloadFileInProgress = !!state.common.downloadFileInfo;
  const isOpen = CREATE_2D_PROJECT_DIALOG_NAME in state.dialog;
  return {
    isDownloadFileInProgress,
    isOpen,
    files: state.common.files,
    projectName: selector(state, 'name'),
    cancelDownLoadFile: isDownloadFileInProgress ? state.common.downloadFileInfo.canceler : null,
    hasSomeFiles: !!state.common.files.length,
    disableSubmit: !selector(state, 'name') || !isValidFormSelector(state)
      || state.common.files.some(f => f.progress < 1),
    templates: state.projects.templates,
    projectType: isOpen ? state.dialog[CREATE_2D_PROJECT_DIALOG_NAME].projectType : null,
    canUseTemplates: isOpen ? state.dialog[CREATE_2D_PROJECT_DIALOG_NAME].canUseTemplates : false,
    selectedTemplateId: selector(state, 'templateId'),
    isSharedWithCompany: !!selector(state, 'isShareWithCompany'),
    company: state.account.selectedCompany,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setProjectName: (value) => dispatch(change(CREATE_PROJECT, 'name', value)),
    setInvitedUsers: (value) => dispatch(change(CREATE_PROJECT, 'invitations', value)),
    setShareWithCompany: (value) => dispatch(change(CREATE_PROJECT, 'isShareWithCompany', value)),
    closeCreateProjectDialog: () => dispatch(KreoDialogActions.closeDialog(CREATE_2D_PROJECT_DIALOG_NAME)),
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
    clearFiles: () => dispatch(Common.commonClearFiles()),
    setUploadFiles: (projectId, files) => dispatch(TwoDActions.setUploadFiles(projectId, files)),
    removeDownloadFileName: () => dispatch(Common.removeDownloadFileName()),
    setType: (type) => dispatch(change(CREATE_PROJECT, 'type', type)),
    setTemplate: (id) => dispatch(change(CREATE_PROJECT, 'templateId', id)),
    fetchAllTemplates: () => dispatch(ProjectsActions.fetchAllTemplates()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
const Create2dProjectDialogConnected = connector(withAbilityContext(Create2dProjectDialogComponent));

export const Create2dProjectDialog = reduxForm<{}, OwnProps>({
  form: CREATE_PROJECT,
  destroyOnUnmount: true,
  initialValues: {
    isShared: true,
  },
})(Create2dProjectDialogConnected);
