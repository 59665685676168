import styled from 'styled-components';

import { BlockQuestionnaireStyled } from 'common/components/questionnaire/block-questionnaire';
import { CloseButtonStyled } from 'common/components/questionnaire/close-button';
import { ContinueButtonStyled } from 'common/components/questionnaire/continue-button';


const Container = styled.div`
  position: relative;
  max-width: 580px;
  z-index: 1;

  ${BlockQuestionnaireStyled.CurrentReply} {
    min-width: fit-content;
  }

  ${BlockQuestionnaireStyled.ClosedQuestion} {
    align-items: flex-start;
  }

  ${CloseButtonStyled.Container} {
    margin-top: 20px;
  }

  ${ContinueButtonStyled.Container} {
    margin-top: 20px;

    > button {
      width: 180px;

      > svg {
        display: none;
      }
    }
  }
`;

const FeedbackForm = styled.div`
  width: 100%;
  max-height: calc(100vh - 270px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  padding: 20px;
  border-radius: 20px;
  background: ${props => props.theme.color.backgroundRush};
  box-sizing: border-box;

  textarea {
    ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
      border-width: 2px;
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover,
    ::-webkit-scrollbar-thumb:active {
      background: ${p => p.theme.color.gray} !important;
    }
    ::-webkit-scrollbar-track {
      width: 6px;
      border-radius: 5px;
      background: ${p => p.theme.color.background};
    }
  }
`;

export const Styled = {
  Container,
  FeedbackForm,
};
