import { Action } from 'redux';

import { UpdateColumnData } from 'common/components/excel-table';
import { ActionWith } from 'common/interfaces/action-with';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { Arrangement } from '../../constants';
import { TwoDViewTableType } from '../../constants/table-type';
import {
  AddNewSheetStatuses,
  CellToUpdatePayload,
  ReportPage,
  UpdateCellForm,
  AssignedPia,
  AssignPiaPatch,
  PiaCalculated,
  UpdateFocusedCell,
} from '../../interfaces';
import { TablePreset } from '../../interfaces/table-presets';
import {
  FormulaBarPayload,
  FormWithProjectAndReportIdsPayload,
  MoveToPagePayload,
  ProjectAndReportIdsPayload,
  ReportPageAddRowsOrColumnsPayload,
  SetDrawingInstanceInCellRangePayload,
  SetUploadFilesPayload,
  ToggleViewToolPanelsPayload,
  UpdateCellsPayload,
  UpdatePageInfoPayload,
  UpdateReportQueueMessageApiCallBackType,
  UpdateReportQueueMessagePayload,
  UpdateViewConfigPayload,
} from '../payloads';
import { TwoDActionTypes } from '../types';

function setFocusCell(payload: UpdateFocusedCell): ActionWith<UpdateFocusedCell> {
  return {
    type: TwoDActionTypes.FOCUS_CELL,
    payload,
  };
}

function setWrite(value: boolean): ActionWith<boolean> {
  return {
    type: TwoDActionTypes.SET_WRITE,
    payload: value,
  };
}

function setRead(value: boolean): ActionWith<boolean> {
  return {
    type: TwoDActionTypes.SET_READ,
    payload: value,
  };
}

function updateDrawingsToCell(value: Record<string, string[]>): ActionWith<Record<string, string[]>> {
  return {
    type: TwoDActionTypes.UPDATE_DRAWINGS_TO_CELL,
    payload: value,
  };
}

function updateDynamicGroupsToCell(value: Record<string, string[]>): ActionWith<Record<string, string[]>> {
  return {
    type: TwoDActionTypes.UPDATE_DYNAMIC_GROUPS_TO_CELL,
    payload: value,
  };
}

function updateCellToCell(payload: Record<string, string[]>): ActionWith<Record<string, string[]>> {
  return {
    type: TwoDActionTypes.UPDATE_CELL_TO_CELL,
    payload,
  };
}

function clearCellToUpdate(): Action {
  return {
    type: TwoDActionTypes.CLEAR_CELL_TO_UPDATE,
  };
}

function setCellToUpdate(value: CellToUpdatePayload[]): ActionWith<CellToUpdatePayload[]> {
  return {
    type: TwoDActionTypes.SET_CELL_TO_UPDATE,
    payload: value,
  };
}

function getPages(projectId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.GET_PAGES,
    payload: projectId,
  };
}

function setPages(pages: ReportPage[]): ActionWith<ReportPage[]> {
  return {
    type: TwoDActionTypes.SET_PAGES,
    payload: pages,
  };
}

function setReportPageEtag(pageId: string, etag: number): ActionWith<[string, number]> {
  return {
    type: TwoDActionTypes.SET_REPORT_PAGE_ETAG,
    payload: [pageId, etag],
  };
}

function setSessionId(pageId: string, sessionId: string): ActionWith<[string, string]> {
  return {
    type: TwoDActionTypes.SET_REPORT_SESSION_ID,
    payload: [pageId, sessionId],
  };
}

function calculatePia(): Action {
  return {
    type: TwoDActionTypes.CALCULATE_PIA,
  };
}

function createPage(): Action {
  return {
    type: TwoDActionTypes.CREATE_PAGE,
  };
}

function downloadExcelFile(projectId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.DOWNLOAD_EXCEL_FILE,
    payload: projectId,
  };
}

function deletePage(pageId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.DELETE_PAGE,
    payload: pageId,
  };
}

function restorePage(pageId: string, sessionId: string): ActionWith<[string, string]> {
  return {
    type: TwoDActionTypes.RESTORE_PAGE,
    payload: [pageId, sessionId],
  };
}

function moveToPage(pageId: string, offset: number): ActionWith<MoveToPagePayload> {
  return {
    type: TwoDActionTypes.MOVE_TO_PAGE,
    payload: {
      pageId,
      offset,
    },
  };
}

function setNewPage(page: ReportPage): ActionWith<ReportPage> {
  return {
    type: TwoDActionTypes.SET_NEW_PAGE,
    payload: page,
  };
}

function saveCellsUpdates(payload:
  UpdateCellsPayload<UpdateCellForm[]>,
): ActionWith<UpdateCellsPayload<UpdateCellForm[]>> {
  return {
    type: TwoDActionTypes.SAVE_REPORT_CELLS_UPDATES,
    payload,
  };
}

function putMessageToUpdateReportQueue<T>(
  apiCall: UpdateReportQueueMessageApiCallBackType<T>,
  form: FormWithProjectAndReportIdsPayload<T>,
): ActionWith<UpdateReportQueueMessagePayload<T>> {
  return {
    type: TwoDActionTypes.PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE,
    payload: { apiCall, form },
  };
}

function updateReportQueueMessageDone(sheetId: string): ActionWith<string> {
  return { type: TwoDActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_DONE, payload: sheetId };
}

function updateReportQueueMessageFailed(projectId: string, pageId: string): ActionWith<ProjectAndReportIdsPayload> {
  return {
    type: TwoDActionTypes.UPDATE_REPORT_QUEUE_MESSAGE_FAILED,
    payload: { projectId, sheetId: pageId },
  };
}

function updatePageInfo(projectId: string, data: ReportPage): ActionWith<UpdatePageInfoPayload> {
  return {
    type: TwoDActionTypes.UPDATE_PAGE_INFO,
    payload: {
      projectId,
      data,
    },
  };
}

function duplicatePage(pageId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.DUPLICATE_PAGE,
    payload: pageId,
  };
}

function addRows(projectId: string, pageId: string, count: number): ActionWith<ReportPageAddRowsOrColumnsPayload> {
  return {
    type: TwoDActionTypes.ADD_ROWS,
    payload: { projectId, pageId, count },
  };
}

function addColumns(projectId: string, pageId: string, count: number): ActionWith<ReportPageAddRowsOrColumnsPayload> {
  return {
    type: TwoDActionTypes.ADD_COLUMNS,
    payload: { projectId, pageId, count },
  };
}

function setSelectedSheetId(sheetId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_SELECTED_SHEET_ID,
    payload: sheetId,
  };
}

function setUploadFiles(projectId: number, files: string[]): ActionWith<SetUploadFilesPayload> {
  return {
    type: TwoDActionTypes.SET_UPLOAD_EXCEL,
    payload: {
      files,
      projectId,
    },
  };
}

function setShowSheetsIds(ids: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.SET_SHOW_SHEETS_IDS,
    payload: ids,
  };
}
function showTraceLink(instanceId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SHOW_TRACE_LINK,
    payload: instanceId,
  };
}

function hideTraceLink(): Action {
  return {
    type: TwoDActionTypes.HIDE_TRACE_LINK,
  };
}

function setCellWithBoarder(cellId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_CELL_WITH_BORDER,
    payload: cellId,
  };
}

function setFormulaBar(formulaBar: FormulaBarPayload): ActionWith<FormulaBarPayload> {
  return {
    type: TwoDActionTypes.SET_FORMULA_BAR,
    payload: formulaBar,
  };
}

function setDrawingInstanceInCellRange(sheetId: string, ranges: Record<string, boolean>):
  ActionWith<SetDrawingInstanceInCellRangePayload> {
  return {
    type: TwoDActionTypes.SET_DRAWING_INSTANCE_IN_CELL_RANGE,
    payload: { sheetId, ranges },
  };
}

function setTableViewType(tableViewType: Arrangement): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_TWOD_TABLE_VIEW_TYPE,
    payload: tableViewType,
  };
}

function dropState(): Action {
  return {
    type: TwoDActionTypes.DROP_STATE,
  };
}

function setEditFullCellId(cellId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_EDIT_FULL_CELL_ID,
    payload: cellId,
  };
}

function updateColumnData(payload: FormWithProjectAndReportIdsPayload<UpdateColumnData[]>):
  ActionWith<FormWithProjectAndReportIdsPayload<UpdateColumnData[]>> {
  return {
    type: TwoDActionTypes.UPDATE_COLUMN_DATA,
    payload,
  };
}

function setFilteredNodeId(payload: Record<string, boolean>): ActionWith<Record<string, boolean>> {
  return {
    type: TwoDActionTypes.SET_FILTERED_NODE_IDS,
    payload,
  };
}

function setQuickSearchValue(payload: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_QUICK_SEARCH_VALUE,
    payload,
  };
}

function setAddNewSheetStatus(payload: AddNewSheetStatuses): ActionWith<AddNewSheetStatuses> {
  return {
    type: TwoDActionTypes.SET_ADD_NEW_SHEET_STATUS,
    payload,
  };
}

function addNewSheet(reportSheet: ReportPage): ActionWith<ReportPage> {
  return {
    type: TwoDActionTypes.ADD_NEW_SHEET,
    payload: reportSheet,
  };
}

function getTablePresets(): Action {
  return {
    type: TwoDActionTypes.GET_TABLE_PRESETS,
  };
}

function setTablePresets(presets: TablePreset[]): ActionWith<TablePreset[]> {
  return {
    type: TwoDActionTypes.SET_TABLE_PRESETS,
    payload: presets,
  };
}

function createTablePreset(preset: TablePreset): ActionWith<TablePreset> {
  return {
    type: TwoDActionTypes.CREATE_TABLE_PRESET,
    payload: preset,
  };
}

function updateTablePreset(preset: TablePreset): ActionWith<TablePreset> {
  return {
    type: TwoDActionTypes.UPDATE_TABLE_PRESET,
    payload: preset,
  };
}

function deleteTablePreset(id: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.DELETE_TABLE_PRESET,
    payload: id,
  };
}

function setSelectedAssemblyIds(ids: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.SET_SELECTED_ASSEMBLIES_IDS,
    payload: ids,
  };
}

function setSelectedItemsIds(ids: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.SET_SELECTED_ITEMS_IDS,
    payload: ids,
  };
}

function setOpenMeasureSelectTable(): Action {
  return {
    type: TwoDActionTypes.SET_OPEN_MEASURE_SELECT_TABLE,
  };
}

function setCloseMeasureSelectTable(): Action {
  return {
    type: TwoDActionTypes.SET_CLOSE_MEASURE_SELECT_TABLE,
  };
}


function setIsSkipCalcPia(payload: boolean): ActionWith<boolean> {
  return {
    type: TwoDActionTypes.SET_IS_SKIP_CALC_PIA,
    payload,
  };
}

function setInstancesAssignPia(assignment: Record<string, AssignedPia>): ActionWith<Record<string, AssignedPia>> {
  return {
    type: TwoDActionTypes.SET_INSTANCES_ASSIGN_PIA,
    payload: assignment,
  };
}

function setCalculatedPia(payload: Record<string, PiaCalculated>): ActionWith<Record<string, PiaCalculated>> {
  return {
    type: TwoDActionTypes.SET_CALCULATED_PIA,
    payload,
  };
}

function setAssignPia(assign: Record<string, AssignedPia>): ActionWith<Record<string, AssignedPia>> {
  return {
    type: TwoDActionTypes.SET_ASSIGN_PIA,
    payload: assign,
  };
}

function setIsAssignPiaLoading(value: boolean): ActionWith<boolean> {
  return {
    type: TwoDActionTypes.SET_IS_ASSIGN_PIA_LOADING,
    payload: value,
  };
}

function createView(sourceType: TwoDViewTableType): ActionWith<TwoDViewTableType> {
  return {
    type: TwoDActionTypes.CREATE_VIEW_REQUEST,
    payload: sourceType,
  };
}

function updateViewConfig(
  projectId: string,
  tableId: string,
  configuration: TwoDViewTableConfig,
): ActionWith<UpdateViewConfigPayload> {
  return {
    type: TwoDActionTypes.UPDATE_VIEW_CONFIGURATION,
    payload: {
      configuration,
      tableId,
      projectId,
    },
  };
}

function removeAssignPia(instancesIds: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.REMOVE_ASSIGN_PIA,
    payload: instancesIds,
  };
}

function sendAssignPatch(
  assign: AssignPiaPatch[],
  iterationId: string,
  callback?: () => void,
  projectId?: string | number,
):
  ActionWith<[AssignPiaPatch[], string, () => void, string | number]> {
  return {
    type: TwoDActionTypes.SEND_ASSIGN_PATCH,
    payload: [assign, iterationId, callback, projectId],
  };
}

function fetchAssignPia(): Action {
  return {
    type: TwoDActionTypes.FETCH_ASSIGN_PIA,
  };
}

function setAssignPiaReady(): Action {
  return {
    type: TwoDActionTypes.SET_ASSIGN_PIA_READY,
  };
}

function setAssignPiaLoad(): Action {
  return {
    type: TwoDActionTypes.SET_ASSIGN_PIA_LOAD,
  };
}

function setExportExcelReady(iterationId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_EXPORT_REPORT_READY,
    payload: iterationId,
  };
}

function setExportExcelLoad(iterationId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_EXPORT_REPORT_LOAD,
    payload: iterationId,
  };
}

function loadViewConfig(tableId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.LOAD_VIEW_CONFIGURATION,
    payload: tableId,
  };
}

function setViewConfig(tableId: string, config: TwoDViewTableConfig): ActionWith<UpdateViewConfigPayload> {
  return {
    type: TwoDActionTypes.SET_MEASURE_VIEW_CONFIGURATION,
    payload: {
      tableId,
      configuration: config,
    },
  };
}

function setSelectedView(tableId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.SET_SELECTED_VIEW,
    payload: tableId,
  };
}

function setIsGetPageData(isGetPageData: boolean): ActionWith<boolean> {
  return {
    type: TwoDActionTypes.SET_IS_GET_PAGE_DATA,
    payload: isGetPageData,
  };
}

function toggleViewToolPanels(tableId: string, toolPanelName: string):
ActionWith<ToggleViewToolPanelsPayload> {
  return {
    type: TwoDActionTypes.TOGGLE_VIEW_TOOL_PANELS,
    payload: { tableId, toolPanelName },
  };
}

function setViewMeasureId(payload: Record<string, boolean>): ActionWith<Record<string, boolean>> {
  return {
    type: TwoDActionTypes.SET_VIEW_MEASURE_ID,
    payload,
  };
}

function setSelectedMeasureIdView(selectedIds: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.SET_SELECTED_MEASURE_ID_VIEW,
    payload: selectedIds,
  };
}

function afterCopyMeasure(ids: string[]): ActionWith<string[]> {
  return {
    type: TwoDActionTypes.AFTER_COPY_MEASURES,
    payload: ids,
  };
}

function toggleShowCode(viewId: string): ActionWith<string> {
  return {
    type: TwoDActionTypes.TOGGLE_SHOW_CODE,
    payload: viewId,
  };
}


export const TwoDActions = {
  setFocusCell,
  setWrite,
  setRead,
  updateDrawingsToCell,
  updateDynamicGroupsToCell,
  updateCellToCell,
  clearCellToUpdate,
  setCellToUpdate,
  getPages,
  setPages,
  createPage,
  updatePageInfo,
  addRows,
  addColumns,
  setNewPage,
  saveCellsUpdates,
  putMessageToUpdateReportQueue,
  updateReportQueueMessageDone,
  updateReportQueueMessageFailed,
  downloadExcelFile,
  deletePage,
  restorePage,
  setSelectedSheetId,
  setUploadFiles,
  moveToPage,
  setShowSheetsIds,
  showTraceLink,
  hideTraceLink,
  duplicatePage,
  setCellWithBoarder,
  setFormulaBar,
  setDrawingInstanceInCellRange,
  setTableViewType,
  dropState,
  setEditFullCellId,
  updateColumnData,
  setFilteredNodeId,
  setQuickSearchValue,
  setAddNewSheetStatus,
  addNewSheet,
  setSelectedView,
  setIsGetPageData,

  getTablePresets,
  setTablePresets,
  createTablePreset,
  updateTablePreset,
  deleteTablePreset,

  setSelectedAssemblyIds,
  setSelectedItemsIds,
  setAssignPia,
  setIsAssignPiaLoading,
  removeAssignPia,

  sendAssignPatch,
  fetchAssignPia,

  setInstancesAssignPia,
  setCalculatedPia,
  setAssignPiaReady,
  setAssignPiaLoad,
  calculatePia,
  setIsSkipCalcPia,

  createView,
  updateViewConfig,
  loadViewConfig,
  setViewConfig,
  toggleViewToolPanels,

  setExportExcelLoad,
  setExportExcelReady,

  setViewMeasureId,

  setSelectedMeasureIdView,

  setOpenMeasureSelectTable,
  setCloseMeasureSelectTable,

  setReportPageEtag,
  setSessionId,

  afterCopyMeasure,
  toggleShowCode,
};
