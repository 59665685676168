export enum CursorHintType {
  Continue = 1,
  WizzardPolylineInProgress,
  WizzardPolylineNotFound,
  BucketFillInProgress,
  BucketFillNotFound,
  BucketAIOutOffArea,
  BucketAINoPoints,
  BucketAINegativePoint,
  BucketAIExpandArea,
  BucketAIOnlyNegativePoints,
  OneClickLineInProgress,
  OneClickLineNotFound,
  OneClickLineOutOffArea,
  OneClickLineNoPoints,
  OneClickLineNegativePoint,
  OneClickLineExpandLine,
  OneClickLineOnlyNegativePoints,
  NoGeometriesInArea,
  ReachedLimitInArea,
  SelectionAreaCrashed,
  NoGeometryFoundByClick,
}
