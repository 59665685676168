import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  img {
    border-radius: inherit;
  }
`;

const PageBubble = styled.div<{ isImage: boolean }>`
  position: absolute;
  right: 4px;
  bottom: 4px;
  min-width: 8px;
  height: 15px;
  padding: 0 4px;
  background-color: ${p => p.color || p.theme.color.red};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p => !p.isImage ? 'z-index: 1' : ''};
`;

export const Styled = {
  Container,
  PageBubble,
};
