import React from 'react';

import { usePersistedStorageValueWithChange } from 'persisted-storage/hooks';
import { PersistedStorageStateBoolKeys } from 'persisted-storage/interfaces/state';
import { SettingItem, SettingItemTutorial } from '../setting-item';

interface Props {
  disabled?: boolean;
  settingKey: PersistedStorageStateBoolKeys;
  label: string;
  tutorial: SettingItemTutorial;
}


const PersistStorageSettingItemComponent: React.FC<Props> = ({
  settingKey,
  label,
  tutorial,
  disabled,
}) => {
  const [ value, setValue] = usePersistedStorageValueWithChange(settingKey);

  return (
    <SettingItem
      label={label}
      checked={value}
      disabled={disabled}
      tutorial={tutorial}
      onChange={setValue}
    />
  );
};

export const PersistStorageSettingItem = React.memo(PersistStorageSettingItemComponent);
