import styled from 'styled-components';

const EditIcon = styled.div`
  position: absolute;
  display: block;
  padding-left: 5px;
  right: 0;
  opacity: 0;
  transition: ${(p) => p.theme.duration.s} linear;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  svg {
    fill: ${(p) => p.theme.color.gray};
    height: 10px;
  }
`;

const Container = styled.div<{ disableRenaming: boolean, isHoverBackground: boolean, backgroundColor: string }>`
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  pointer-events: ${(p) => (p.disableRenaming ? 'none' : 'auto')};
  div {
    display: flex;
    align-items: center;
  }
  p {
    z-index: 1;
    border-radius: 4px;
    padding: ${(p) => (p.isHoverBackground ? '0 2px' : 0)};
    transition: ${(p) => p.theme.duration.s} linear;
    position: relative;
  }
  &:hover {
    p {
      background: ${(p) => p.isHoverBackground && p.theme.color[p.backgroundColor]};
    }
    ${EditIcon} {
      right: -15px;
      opacity: 1;
    }
  }
`;

const Input = styled.input<{ fontSize: number, backgroundColor: string, isHoverBackground: boolean }>`
  height: ${(p) => p.height}px;
  width: 100%;
  padding: ${(p) => (p.isHoverBackground ? '0 2px' : 0)};
  font-size: ${(p) => p.fontSize || 16}px;
  color: ${(p) => p.theme.color.mainFont};
  background-color: ${(p) => p.theme.color[p.backgroundColor]};
  border-radius: 4px;
  outline: none;
  border: none;
  position: relative;
  display: flex;
`;

export const Styled = {
  Container,
  Input,
  EditIcon,
};
