import { useSelector } from 'react-redux';
import { DrawingsMeasureUtils } from 'common/components/drawings';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { useDrawModeApi } from 'common/components/drawings/hooks';
import { WizzardToolsState } from 'common/components/drawings/interfaces';
import { State } from 'common/interfaces/state';


export function useResultType(): DrawingsInstanceType {
  const { drawMode } = useDrawModeApi();
  const wizzardWandIsPolygon = useSelector<State, WizzardToolsState>(s => {
    return s.drawings.wizzardTools;
  });
  return DrawingsMeasureUtils.getInstanceTypeFromDrawMode({
    drawMode,
    wizzard: wizzardWandIsPolygon,
  }) as DrawingsInstanceType;
}
