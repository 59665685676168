import React from 'react';

import {
  ShapeSwitcher,
} from 'common/components/drawings/drawings-controls/drawings-instances-styles';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { useNewInstancesSetting } from 'common/components/drawings/hooks';
import { Property } from 'common/components/property';
import { useResultType } from '../geometry-params/use-result-type';
import { StrokeStyles } from './stroke-styles';
import { Styled } from './styled';

const StylesMenuComponent: React.FC = () => {
  const instanceType = useResultType();

  const [ shape, changeShape ] = useNewInstancesSetting('shape');

  if (instanceType === DrawingsInstanceType.Count) {
    return (
      <Styled.ShapeSwitcherWrapper>
        <Property title='Shape'>
          <ShapeSwitcher shape={shape} setShape={changeShape}/>
        </Property>
      </Styled.ShapeSwitcherWrapper>
    );
  } else {
    return (<StrokeStyles/>);
  }
};

export const StylesMenu = React.memo(StylesMenuComponent);
