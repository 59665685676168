import { forOwn, keys as lodashKeys } from 'lodash';

const isObject = (val: any): boolean => val && typeof val === 'object';

const deepFreeze = <T>(obj: T): T => {
  if (isObject(obj) && !Object.isFrozen(obj)) {
    Object.keys(obj).forEach((name) => deepFreeze(obj[name]));
    Object.freeze(obj);
  }

  return obj;
};

function keysToLowerCamelCase<TSource = object, TResult = object>(source: TSource): TResult {
  return _transformKeys(source, (key) => key.charAt(0).toLowerCase() + key.slice(1));
}

function buildAscComparerBy<T>(property: keyof T): (t1: T, t2: T) => number {
  return (t1: T, t2: T): number => {
    if (t1[property] > t2[property]) {
      return 1;
    } else if (t1[property] < t2[property]) {
      return -1;
    } else {
      return 0;
    }
  };
}

function toQueryString<T = {}>(source: T): string {
  const results = [];
  forOwn(source, (value, key) => {
    if (value === null || value === undefined) {
      return;
    }

    if (Array.isArray(value)) {
      forOwn(value, arrayItem => {
        results.push(`${key}=${encodeURIComponent(arrayItem)}`);
      });
    } else {
      results.push(`${key}=${encodeURIComponent(value as any)}`);
    }
  });

  return results.join('&');
}

function _transformKeys<TSource = object, TResult = object>(
  source: TSource,
  transformFunc: (key: string) => string,
): TResult {
  const keys = lodashKeys(source);

  let n = keys.length;
  const result = {};
  while (n--) {
    const currentKey = keys[n];
    const transformedKey = transformFunc(currentKey);
    if (source[currentKey] instanceof Array) {
      result[transformedKey] = source[currentKey].map(x =>
        typeof x === 'object'
          ? _transformKeys(x, transformFunc)
          : x);
    } else if (typeof source[currentKey] === 'object') {
      result[transformedKey] = _transformKeys(source[currentKey], transformFunc);
    } else {
      result[transformedKey] = source[currentKey];
    }
  }

  return result as TResult;
}

function areEqualByFields<T>(source: T, target: T, fields: Array<keyof T>): boolean {
  for (const field of fields) {
    if (source[field] !== target[field]) {
      return false;
    }
  }
  return true;
}

function extend(source: Object, target: Object): void {
  for (const key in target) {
    if (target.hasOwnProperty(key)) {
      source[key] = target[key];
    }
  }
}

function deepCopyObjectWithNoUndefineds(obj: any): any {
  if (typeof obj !== 'object') {
    return obj;
  }

  const result = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== undefined) {
        result[key] = deepCopyObjectWithNoUndefineds(value);
      }
    }
  }

  return result;
}


export const objectUtils = {
  deepFreeze,
  keysToLowerCamelCase,
  buildAscComparerBy,
  toQueryString,
  areEqualByFields,
  extend,
  deepCopyObjectWithNoUndefineds,
};
