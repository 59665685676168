import * as THREE from 'three';

export interface Config<T> {
  scene: THREE.Scene;
  geometry: T;
}

export abstract class VisibleObject<K, T extends Config<K>> {
  protected _config: T;
  protected _mesh?: THREE.Object3D;

  constructor(config: T) {
    this._config = config;
    this.render(this._config.geometry);
  }

  public get mesh(): THREE.Object3D | undefined {
    return this._mesh;
  }

  public destroy(): void {
    this.removeGeometry();
  }

  public getGeometry(): K {
    return this._config.geometry;
  }

  public setNewGeometry(geometry: K): void {
    this._config.geometry = geometry;
    this.destroy();
    this.render(geometry);
  }

  protected removeGeometry(): void {
    if (this._mesh) {
      this._config.scene.remove(this._mesh);
      this._mesh.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.dispose();
        }
      });
    }
  }

  protected abstract render(_geometry: K): void;

}
