import { Text, Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import images from 'react-payment-inputs/images';

import { Styled } from './styled';

interface Props {
  brand?: string;
  last4: string;
  isActive: boolean;
}

const Component: React.FC<Props> = ({
  brand,
  last4,
  isActive,
}) => {
  return (
    <Styled.Payment>
      <Styled.CardType
        xmlns="http://www.w3.org/2000/svg"
        viewBox='0 0 24 16'
      >
        {images[brand?.toLowerCase()]}
      </Styled.CardType>
      <Text color='mainFont'>
        ending {last4}
      </Text>
      <Styled.Status>
        {isActive ? <Icons.Apply /> : null}
      </Styled.Status>
    </Styled.Payment>
  );
};

export const CardPayment = React.memo(Component);
