import { ContextObserver } from 'common/components/drawings/drawings-contexts/context-observers';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  DrawingsGeometryInstance,
  DrawingsPolylineGeometry,
  FinderSaveType,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { PdfGeometry } from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { Vector2Utils } from 'common/components/drawings/utils/math-utils/vector2-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../common';
import { NewDrawingSettings } from '../../interfaces/new-drawing-style';
import { WizzardPreview } from './wizzard-preview';

export type AllowedFinderGeometry = PdfGeometry[];


interface Config extends VisibleEntityConfig<AllowedFinderGeometry> {
  fill?: boolean;
  newDrawingsStylesObserver: ContextObserver<NewDrawingSettings>;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
  colorCache: DrawingsColorCacheHelper;
  layer: paper.Layer;
  isCount: boolean;
  similarity: number;
  geometriesToHide: Record<number, boolean>;
}

interface ConvertedGeometry {
  instances: DrawingsGeometryInstance[];
  newPoints: Record<string, ShortPointDescription>;
}

export class FinderPreview extends VisibleEntity<AllowedFinderGeometry, Config> {
  private _preview: WizzardPreview;

  public updateFilters(similarity: number, geometriesToHide: Record<number, boolean>): void {
    this._preview.updateFilters(similarity, geometriesToHide);
    this._config.similarity = similarity;
    this._config.geometriesToHide = geometriesToHide;
  }

  public destroy(): void {
    this._preview.destroy();
  }

  public getInstances(
    drawingId: string,
    expectedType: FinderSaveType = FinderSaveType.Count,
  ): ConvertedGeometry {
    switch (expectedType) {
      case FinderSaveType.Count:
        return this.getCount(drawingId);
      case FinderSaveType.CenterLine:
        return this.getCenterLine(drawingId);
      case FinderSaveType.Area:
        return this.getArea(drawingId);
      case FinderSaveType.MaxLengthSegments:
        return this.getMaxSegments(drawingId);
      case FinderSaveType.MinLengthSegments:
        return this.getMinSegments(drawingId);
      default:
    }

    return {
      instances: [],
      newPoints: {},
    };
  }

  protected render(geometries: AllowedFinderGeometry): void {
    if (this._preview) {
      this._preview.destroy();
    }
    this._preview = new WizzardPreview({
      similarity: this._config.similarity,
      geometry: geometries,
      colorCache: this._config.colorCache,
      layer: this._config.layer,
      newDrawingsStylesObserver: this._config.newDrawingsStylesObserver,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      fill: true,
      geometriesToHide: {},
      viewSettingsObserver: this._config.viewSettingsObserver,
    });
  }

  private getCount(
    drawingId: string,
  ): { instances: DrawingsGeometryInstance[], newPoints: Record<string, ShortPointDescription> } {
    const { shape, name, color } = this._config.newDrawingsStylesObserver.getContext();
    const points = [];
    const pointsDescription = {};
    for (const rect of this.geometriesToExportIterator()) {
      const point = rect.points.reduce((aggregated, currentvalue, _i, array) => {
        aggregated[0] += currentvalue[0] / array.length;
        aggregated[1] += currentvalue[1] / array.length;
        return aggregated;
      }, [0, 0]);
      const id = UuidUtil.generateUuid();
      pointsDescription[id] = point;
      points.push(id);
    }
    const instanceId = UuidUtil.generateUuid();
    const instance = {
      drawingId,
      id: instanceId,
      name,
      isAuto: false,
      type: DrawingsInstanceType.Count,
      geometry: {
        color,
        points,
        shape,
      },
    };
    return {
      instances: [instance],
      newPoints: pointsDescription,
    };
  }

  private getCenterLine(
    drawingId: string,
  ): { instances: DrawingsGeometryInstance[], newPoints: Record<string, ShortPointDescription> } {
    const geometries = this._preview.geometry;
    const isQuad = this.isQuad(geometries[0]);
    const {
      polylineThickness,
      strokeStyle,
      strokeWidth,
      offset,
      polylineHeight,
      color,
      name,
    } = this._config.newDrawingsStylesObserver.getContext();

    const instances: Array<DrawingsGeometryInstance<DrawingsPolylineGeometry>> = [];
    const newPoints = {};

    const addPoint = (point: ShortPointDescription): string => {
      const id = UuidUtil.generateUuid();
      newPoints[id] = point;
      return id;
    };

    for (const geometry of this.geometriesToExportIterator()) {
      const [p1, p2, p3, p4] = geometry.points;
      let points: string[];
      if (isQuad) {
        points = this.getMaxAngleSegments(geometry)
          .map((s) => addPoint(Vector2Utils.divide(Vector2Utils.add(s[0], s[1]), 2)));
      } else {
        if (Vector2Utils.squaredDist(p1, p2) > Vector2Utils.squaredDist(p2, p3)) {
          const center1 = Vector2Utils.divide(Vector2Utils.add(p2, p3), 2);
          const center2 = Vector2Utils.divide(Vector2Utils.add(p1, p4), 2);
          points = [center1, center2].map(addPoint);
        } else {
          const center1 = Vector2Utils.divide(Vector2Utils.add(p1, p2), 2);
          const center2 = Vector2Utils.divide(Vector2Utils.add(p3, p4), 2);
          points = [center1, center2].map(addPoint);
        }
      }
      instances.push({
        drawingId,
        id: UuidUtil.generateUuid(),
        name,
        isAuto: false,
        type: DrawingsInstanceType.Polyline,
        geometry: {
          color,
          points,
          strokeStyle,
          strokeWidth,
          thickness: polylineThickness,
          offset,
          height: polylineHeight,
        },
      });
    }

    return {
      instances,
      newPoints,
    };
  }

  private getArea(
    drawingId: string,
  ): ConvertedGeometry {

    const {
      strokeStyle,
      strokeWidth,
      offset,
      polygonHeight,
      color,
      name,
    } = this._config.newDrawingsStylesObserver.getContext();
    const instances = [];
    const newPoints = {};

    const addPoint = (point: ShortPointDescription): string => {
      const id = UuidUtil.generateUuid();
      newPoints[id] = point;
      return id;
    };

    for (const geometry of this.geometriesToExportIterator()) {
      instances.push({
        drawingId,
        id: UuidUtil.generateUuid(),
        name,
        isAuto: false,
        type: DrawingsInstanceType.Rectangle,
        geometry: {
          color,
          points: geometry.points.map(addPoint),
          strokeStyle,
          strokeWidth,
          offset,
          height: polygonHeight,
        },
      });
    }
    return { instances, newPoints };
  }

  private getMaxSegments(
    drawingId: string,
  ): ConvertedGeometry {
    const geometries = this._preview.geometry;
    const isQuad = this.isQuad(geometries[0]);
    const {
      polylineThickness,
      strokeStyle,
      strokeWidth,
      offset,
      polylineHeight,
      color,
      name,
    } = this._config.newDrawingsStylesObserver.getContext();

    const instances: Array<DrawingsGeometryInstance<DrawingsPolylineGeometry>> = [];
    const newPoints = {};

    const addPoint = (point: ShortPointDescription): string => {
      const id = UuidUtil.generateUuid();
      newPoints[id] = point;
      return id;
    };

    for (const geometry of this.geometriesToExportIterator()) {
      const [p1, p2, p3, p4] = geometry.points;
      let lines: string[][] = [];
      if (isQuad) {
        lines = this.getMinAngleSegments(geometry).map((s) => s.map(addPoint));
      } else {
        if (Vector2Utils.squaredDist(p1, p2) > Vector2Utils.squaredDist(p2, p3)) {
          lines = [[addPoint(p1), addPoint(p2)], [addPoint(p3), addPoint(p4)]];
        } else {
          lines = [[addPoint(p2), addPoint(p3)], [addPoint(p1), addPoint(p4)]];
        }
      }

      lines.forEach((points) => instances.push({
        drawingId,
        id: UuidUtil.generateUuid(),
        name,
        isAuto: false,
        type: DrawingsInstanceType.Polyline,
        geometry: {
          color,
          points,
          strokeStyle,
          strokeWidth,
          thickness: polylineThickness,
          offset,
          height: polylineHeight,
        },
      }));
    }

    return {
      instances,
      newPoints,
    };
  }

  private getMinSegments(
    drawingId: string,
  ): ConvertedGeometry {
    const {
      polylineThickness,
      strokeStyle,
      strokeWidth,
      offset,
      polylineHeight,
      color,
      name,
    } = this._config.newDrawingsStylesObserver.getContext();
    const isQuad = this.isQuad(this._config.geometry[0]);
    const instances: Array<DrawingsGeometryInstance<DrawingsPolylineGeometry>> = [];
    const newPoints = {};

    const addPoint = (point: ShortPointDescription): string => {
      const id = UuidUtil.generateUuid();
      newPoints[id] = point;
      return id;
    };

    for (const geometry of this.geometriesToExportIterator()) {
      const [p1, p2, p3, p4] = geometry.points;
      let lines: string[][] = [];
      if (isQuad) {
        lines = this.getMaxAngleSegments(geometry).map((s) => s.map(addPoint));
      } else {
        if (Vector2Utils.squaredDist(p1, p2) > Vector2Utils.squaredDist(p2, p3)) {
          lines = [[addPoint(p2), addPoint(p3)], [addPoint(p1), addPoint(p4)]];
        } else {
          lines = [[addPoint(p1), addPoint(p2)], [addPoint(p3), addPoint(p4)]];
        }
      }

      lines.forEach((points) => instances.push({
        drawingId,
        id: UuidUtil.generateUuid(),
        name,
        isAuto: false,
        type: DrawingsInstanceType.Polyline,
        geometry: {
          color,
          points,
          strokeStyle,
          strokeWidth,
          thickness: polylineThickness,
          offset,
          height: polylineHeight,
        },
      }));
    }

    return {
      instances,
      newPoints,
    };
  }


  private *geometriesToExportIterator(): IterableIterator<PdfGeometry> {
    const geometries = this._config.geometry;
    for (const geometry of geometries) {
      if (geometry.similarity < this._config.similarity || this._config.geometriesToHide[geometry.id]) {
        continue;
      }
      yield geometry;
    }
  }

  private isQuad(geometry: PdfGeometry): boolean {
    const [p1, p2, p3] = geometry.points;
    return Math.abs(Vector2Utils.squaredDist(p1, p2) - Vector2Utils.squaredDist(p2, p3)) < 10e-6;
  }

  private getMinAngleSegments(geometry: PdfGeometry): [
    [ShortPointDescription, ShortPointDescription], [ShortPointDescription, ShortPointDescription]] {
    const [p1, p2, p3, p4] = geometry.points;
    const angle1 = Math.atan(Vector2Utils.slope(p1, p2));
    const angle2 = Math.atan(Vector2Utils.slope(p3, p2));
    return angle1 <= angle2 ? [[p1, p2], [p3, p4]] : [[p2, p3], [p4, p1]];
  }

  private getMaxAngleSegments(geometry: PdfGeometry): [
    [ShortPointDescription, ShortPointDescription], [ShortPointDescription, ShortPointDescription]] {
    const [p1, p2, p3, p4] = geometry.points;
    const angle1 = Math.abs(Math.atan(Vector2Utils.slope(p1, p2)) % Math.PI);
    const angle2 = Math.abs(Math.atan(Vector2Utils.slope(p3, p2)) % Math.PI);
    return angle1 >= angle2 ? [[p1, p2], [p3, p4]] : [[p2, p3], [p4, p1]];
  }
}
