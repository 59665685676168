import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useActionDispatch } from 'common/hooks';
import { InvitationCompanyUsers } from 'common/interfaces/people';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { pushSearch } from '../../../../actions/common';
import { PeopleActions } from '../../actions/actions';
import { RoleData } from './interfaces';

export function useInvitePeopleToCompany(
  companyId: number,
): (peopleInvites: Array<{ email: string, role: RoleData }>) => void {
  const dispatch = useDispatch();
  const invitePeople = useActionDispatch(PeopleActions.invitePeopleToCompanyNew);
  const { sendEvent } = useAnalytics();

  const closeDialog = useCallback(() => {
    dispatch(pushSearch({ addCompanyUser: undefined, addCompanyGuest: undefined }));
  }, [dispatch]);

  return useCallback((peopleInvites: Array<{ email: string, role: RoleData }>) => {
    if (!peopleInvites.length) {
      closeDialog();
      return;
    }
    const invites: Record<number, InvitationCompanyUsers> = {};
    for (const person of peopleInvites) {
      invites[person.role.id] =
        invites[person.role.id] || {
          companyId,
          emails: [],
          host: window.location.host,
          roleId: person.role.id,
        };
      invites[person.role.id].emails.push(person.email);
    }

    invitePeople(Object.values(invites));
    closeDialog();

    for (const invite of peopleInvites) {
      sendEvent(
        MetricNames.invitePeople.invitePeopleToCompany,
        {
          inviteEmails: [invite.email],
          role: invite.role.name,
        },
      );
    }
  }, [invitePeople, closeDialog, companyId, sendEvent]);
}
