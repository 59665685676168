import styled from 'styled-components';

const Icon = styled.div`
  width: 20px;
  height:  20px;
  margin-right: 10px;
  svg {
    fill: ${p => p.theme.color.gray};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
`;

const OpenDialogButton = styled.div`
  margin-left: auto;
`;

const PaymentDropdownContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

const PaymentDropdownDescription = styled.div`
`;

const PaymentDropdown = styled.div`
  min-width: 265px;
  width: 265px;
  margin-left: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const Styled = {
  Container,
  PaymentDropdown,
  PaymentDropdownDescription,
  PaymentDropdownContainer,
  OpenDialogButton,
  Header,
  Icon,
};
