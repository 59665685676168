import { Avatar } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { useCurrentDrawingInfo, useDrawingMode } from 'common/components/drawings/hooks';
import { ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { Spinner } from 'common/components/spinner';
import { PersonUtils } from 'common/utils/person-utils';
import { CommentaryTargetTypeGuards } from 'unit-2d-comments/index';
import { CommentaryDrawingWithPointTarget, CommentaryDrawingWithRectTarget } from 'unit-2d-comments/interfaces';
import { Person } from '../../../../../../units/people/interfaces/person';
import { Styled } from './styled';

export interface DrawingsCommentApi {
  setPosition: (
    x: number,
    y: number,
  ) => void;
  getTargetPosition: () => ShortPointDescription;
  hide: () => void;
}

interface Props {
  id: number | string;
  user: Person;
  target: CommentaryDrawingWithPointTarget | CommentaryDrawingWithRectTarget;
  temporary: boolean;
  isSelected: boolean;
  isSending?: boolean;
  sendCommentRef: (api: DrawingsCommentApi, id: number | string) => void;
  onClick?: (commentId: number) => void;
}

const CommentComponent: React.FC<Props> = ({
  id,
  user,
  target,
  temporary,
  isSelected,
  isSending,
  sendCommentRef,
  onClick,
}) => {
  const drawMode = useDrawingMode();
  const rotation = useCurrentDrawingInfo()?.rotationAngle;
  const { rendererApi } = useRendererApiContext();


  const { userName, avatarLink } = useMemo(() => {
    return PersonUtils.getPersonVisualData(user);
  }, [user]);

  const containerRef = useRef<HTMLDivElement>(null);

  const getTargetPosition = useCallback<() => ShortPointDescription>(() => {
    if (CommentaryTargetTypeGuards.isDrawingWithPoint(target)) {
      return [target.x, target.y];
    } else {
      return DrawingsCanvasUtils.getBottomRightPointByRotation({
        left: target.x1,
        top: target.y1,
        right: target.x2,
        bottom: target.y2,
      }, rotation);
    }
  }, [target, rotation]);

  const changePositionAndOrientation = useCallback((x: number, y: number) => {
    if (containerRef.current) {
      containerRef.current.style.display = 'flex';
      containerRef.current.style.left = `${x}px`;
      containerRef.current.style.top = `${y}px`;
    }
  }, []);

  const hide = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.style.display = 'none';
    }
  }, []);

  const onClickCallback = useCallback(() => {
    if (onClick) {
      onClick(id as number);
    }
  }, [id, onClick]);

  useEffect(() => {
    sendCommentRef(
      containerRef.current
        ? {
          setPosition: changePositionAndOrientation,
          getTargetPosition,
          hide,
        }
        : null,
      id,
    );
  }, [containerRef, id, changePositionAndOrientation, getTargetPosition, hide, sendCommentRef]);

  const [hovered, setHovered] = React.useState(false);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const isDrawMode = drawMode !== DrawingsDrawMode.Disabled;

  useEffect(() => {
    if (rendererApi && CommentaryTargetTypeGuards.isDrawingWithRect(target)) {
      if ((hovered || isSelected || temporary) && !isDrawMode) {
        rendererApi.engine.showComment(target as CommentaryDrawingWithRectTarget, id as string | number, !temporary);
      } else {
        rendererApi.engine.removeCommentIfItsShown(id);
      }
    }
  }, [hovered, isSelected, id, target, rendererApi, temporary, isDrawMode]);

  useEffect(() => {
    return () => {
      rendererApi?.engine.removeCommentIfItsShown(id);
    };
  }, [rendererApi, id]);

  return (
    <Styled.Container
      temporary={temporary}
      ref={containerRef}
      onClick={onClickCallback}
      isSelected={isSelected}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {
        isSending ? (
          <Spinner
            show={true}
            withBackground={false}
          />
        ) : (
          <Avatar
            name={userName}
            size={24}
            avatar={avatarLink}
          />
        )
      }
    </Styled.Container>
  );
};

export const Comment = React.memo(CommentComponent);
