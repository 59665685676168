import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { SubscriptionActions } from '../../actions/creators';
import { CompanyBillingInfoModel } from '../../interfaces/company-billing-info-model';

export const useOwnCompanyBillingEffect = (): void => {
  const subscriptionType = useSelector<State, SubscriptionType>(s => s.account.selectedSubscriptionType);
  const ownBillingInfo = useSelector<State, CompanyBillingInfoModel>(s => s.account.ownCompanyBillingInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ownBillingInfo) {
      dispatch(SubscriptionActions.fetchOwnCompanyBillingInfo(subscriptionType));
    }
  }, []);
};
