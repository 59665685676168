import produce from 'immer';
import { getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { ItemUpdater } from '../../assembly-panel/item-form-updater';
import { Field } from '../../item-panel/interfaces';

type GetChangeValueHandler = (
  updater: ItemUpdater,
  field: Field<any>,
  itemName: string,
  groupName: string,
  propertyName: string,
) => (breakdownId: string) => void;

const getChangeValueHandler: GetChangeValueHandler =
  (updater, field, itemName, groupName, propertyName) => (breakdownId: string) => {
    const newField: any = produce(field, (s) => {
      const name = getNameById(field.input.root, breakdownId);
      s.input.value = name;
      s.input.selectedId = breakdownId;
    });
    const [change] = updater.getPropertyGroupUpdater(itemName).getFieldHandlers(propertyName, groupName);
    change(newField.input);
  };

export function handleItemBreakdownFieldClick(
  updater: ItemUpdater,
  openDialog: (data: any) => void,
): (itemName: string, propertyId: string, groupName: string) => void {
  return (itemName, propertyId, groupName) => {
    const form = updater.getForm();
    const field = form.itemGroupForm[itemName].itemForm.groupForm[groupName].fields.find(
      (f) => f.id === propertyId,
    ) as Field<any>;

    const handleValueChange = getChangeValueHandler(updater, field, itemName, groupName, propertyId);
    openDialog({
      propertyName: field.name,
      root: field.input.root,
      selectedId: field.input.selectedId,
      onSelect: handleValueChange,
    });
  };
}
