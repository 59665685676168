import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Item, Property } from 'unit-2d-database/interfaces';
import { SelectEvents, selectItemsAction } from 'unit-2d-info-panel/content/selection-view/assign-form/constants';
import { Action } from 'unit-2d-info-panel/content/selection-view/assign-form/helpers';
import { AssemblyForm } from '../../assembly-panel/item-form-updater';
import { mapItemGroupForm } from '../../assembly-panel/map-items-to-form';
import { FormulaDialogData } from '../../formula-dialog';
import { getItemUpdater, handleItemFormulaFieldClick } from '../helpers';
import { handleItemBreakdownFieldClick } from '../helpers/handle-item-breakdown-field-click';
import { ItemUpdaterCallbacks } from '../helpers/item-form-updater';
import { MeasurePanelItemAfterEffects } from '../interfaces';
import { useGetDatabaseEntitiesCallback } from './use-get-database-entity-callback';
import { useSelectItemsCallback } from './use-select-items-callback';

interface StateRef {
  form?: AssemblyForm;
  setForm?: (f: AssemblyForm) => void;
}

type ItmePanel = (
  items: Item[],
  units: string[],
  afterEffects: MeasurePanelItemAfterEffects,
  propertiesDatabase: Property[],
  itemsDatabase: Item[],
  action: Action<SelectEvents>,
  openDialog: (data: FormulaDialogData) => void,
  openBreakdownDialog: (data: any) => void,
  setPiaLoad: () => void,
) => AssemblyForm;

export const useItemPanel: ItmePanel = (
  items,
  units,
  afterEffects,
  propertiesDatabase,
  itemsDatabase,
  action,
  openDialog,
  openBreakdownDialog,
  setPiaLoad,
) => {
  const refForm = useRef<StateRef>({});
  const getFormCallback = useCallback(() => refForm.current.form, []);
  const setFormCallback = useCallback((newForm: AssemblyForm) => {
    const form = produce(newForm, f => {
      f.addedItems = {};
      f.removedItems = {};
      for (const { itemForm } of Object.values(f.itemGroupForm)) {
        itemForm.addedProperty = {};
        itemForm.removedProperty = {};
      }
    });
    refForm.current.setForm(form);
  }, []);

  const refCallbacks = useRef<ItemUpdaterCallbacks>({
    getForm: getFormCallback,
    setForm: setFormCallback,
  });

  const updater = useMemo(() => {
    return getItemUpdater(refCallbacks.current, afterEffects, setPiaLoad);
  }, [afterEffects, setPiaLoad]);

  const form = useMemo(() => ({
    addedItems: {},
    removedItems: {},
    itemGroupForm: mapItemGroupForm(
      items,
      updater,
      units,
      (itemName, propertyName, propertyGroupName) => handleItemFormulaFieldClick(
        form,
        updater,
        openDialog,
        propertiesDatabase,
        units,
      )(itemName, propertyName, propertyGroupName),
      (itemName, propertyName, propertyGroupName) => handleItemBreakdownFieldClick(
        updater,
        openBreakdownDialog,
      )(itemName, propertyName, propertyGroupName),
      propertiesDatabase,
      itemsDatabase,
      item => item.name,
      propeprty => propeprty.name,
    ),
  }), [items, updater, units, propertiesDatabase, itemsDatabase, openDialog, openBreakdownDialog]);
  const [stateForm, setForm] = useState(form);
  useEffect(() => {
    setForm(form);
    refForm.current.form = form;
  }, [form]);

  refForm.current.form = stateForm;
  refForm.current.setForm = setForm;
  const getItemsDatabase = useGetDatabaseEntitiesCallback(itemsDatabase);
  const getPropertiesDatabase = useGetDatabaseEntitiesCallback(propertiesDatabase);

  const handleSelectItems = useSelectItemsCallback(
    updater,
    units,
    getItemsDatabase,
    getPropertiesDatabase,
    stateForm,
    openDialog,
    openBreakdownDialog,
  );
  useEffect(() => {
    action.addEventLister(selectItemsAction, handleSelectItems);
  }, []);

  return stateForm;
};
