import { Text, Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { Styled } from './styled';

interface Props {
  type: string;
  description: string;
  isActive: boolean;
}

const Component: React.FC<Props> = ({ type, description, isActive }) => {
  return (
    <Styled.Payment>
      <Text color='mainFont'>
        {type}: {description}
      </Text>
      <Styled.Status>
        {isActive ? <Icons.Apply /> : null}
      </Styled.Status>
    </Styled.Payment>
  );
};

export const NoCardIntegratedPayment = React.memo(Component);
