import { IconButton, Icons, MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { MenuItem } from 'common/components/menu-item';
import { Mood } from 'common/enums/mood';
import { useActionDispatch, useOpenMenuWithPosition } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { Assembly, Group, Item, Property } from 'unit-2d-database/interfaces';
import { TwoDDatabaseActions } from 'unit-2d-database/store-slice';

interface Props {
  position: { x: number, y: number };
  onClose: () => void;
}

const MenuContent: React.FC<Props> = ({
  position,
  onClose,
}) => {
  const deleteItems = useActionDispatch(TwoDDatabaseActions.deleteItemRequest);
  const deleteFolders = useActionDispatch(TwoDDatabaseActions.deleteItemGroupRequest);
  const deleteProperties = useActionDispatch(TwoDDatabaseActions.deletePropertiesRequest);
  const deleteAssemblies = useActionDispatch(TwoDDatabaseActions.deleteAssemblyRequest);
  const deleteAssemblyGroups = useActionDispatch(TwoDDatabaseActions.deleteAssemblyGroupRequest);

  const assemblies = useSelector<State, Assembly[]>(x => x.twoDDatabase.assemblies);
  const properties = useSelector<State, Property[]>(x => x.twoDDatabase.properties);
  const assemblyGroups = useSelector<State, Group[]>(x => x.twoDDatabase.assemblyGroups);
  const itemGroups = useSelector<State, Group[]>(x => x.twoDDatabase.itemsGroups);
  const items = useSelector<State, Item[]>(x => x.twoDDatabase.items);

  const deleteAssembliesCallback = useCallback(() => {
    const rootGroupIds = arrayUtils.filterMap(assemblyGroups, x => !x.parentFolderId, x => x.id);
    const rootAssemblies = arrayUtils.filterMap(assemblies, x => !x.folderId, x => x.id);
    deleteAssemblies(rootAssemblies);
    deleteAssemblyGroups(rootGroupIds);
    onClose();
  }, [assemblies, assemblyGroups, deleteAssemblies, deleteAssemblyGroups, onClose]);


  const deleteItemsCallback = useCallback(() => {
    const rootGroupIds = arrayUtils.filterMap(itemGroups, x => !x.parentFolderId, x => x.id);
    const rootItems = arrayUtils.filterMap(items, x => !x.folderId, x => x.id);
    deleteItems(rootItems);
    deleteFolders(rootGroupIds);
    onClose();
  }, [deleteFolders, deleteItems, itemGroups, items, onClose]);

  const deletePropertiesCallback = useCallback(() => {
    deleteProperties(properties.map(x => x.id));
    onClose();
  }, [deleteProperties, properties, onClose]);

  const deleteAll = useCallback(() => {
    deleteAssembliesCallback();
    deleteItemsCallback();
    deletePropertiesCallback();
    onClose();
  }, [deleteAssembliesCallback, deleteItemsCallback, deletePropertiesCallback, onClose]);

  return (
    <MovableContextMenu
      x={position.x}
      y={position.y}
      onClose={onClose}
    >
      <MenuItem
        text='Clear all'
        textColor='mainFont'
        fontSize={14}
        onClick={deleteAll}
        withBorder={true}
        size='m'
        Icon={Icons.Delete}
        disabled={false}
      />
      <MenuItem
        text='Delete Assemblies'
        textColor='mainFont'
        fontSize={14}
        onClick={deleteAssembliesCallback}
        withBorder={true}
        size='m'
        Icon={Icons.Delete}
        disabled={false}
      />
      <MenuItem
        text='Delete Items'
        textColor='mainFont'
        fontSize={14}
        onClick={deleteItemsCallback}
        withBorder={true}
        size='m'
        Icon={Icons.Delete}
        disabled={false}
      />
      <MenuItem
        text='Delete Properties'
        textColor='mainFont'
        fontSize={14}
        onClick={deletePropertiesCallback}
        withBorder={true}
        size='m'
        Icon={Icons.Delete}
        disabled={false}
      />
    </MovableContextMenu>
  );
};

const ClearDatabaseButtonComponent: React.FC = () => {
  const { isOpen, onClose, onOpen, position } = useOpenMenuWithPosition(true);
  const canAdmin = useAbility(Operation.Admin, Subject.Application);
  if (!canAdmin) {
    return null;
  }
  return (
    <>
      <IconButton
        Icon={Icons.Delete}
        mood={Mood.Negative}
        width={20}
        height={20}
        onClick={onOpen}
      />
      {
        isOpen ? (
          <MenuContent
            position={position}
            onClose={onClose}
          />
        ) : null
      }
    </>
  );
};

export const ClearDatabaseButton = ClearDatabaseButtonComponent;
