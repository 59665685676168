import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';

import { LinkComponent } from 'common/components/link-component';
import { EnhancedVirtualList } from 'common/UIKit';
import { RoleData, UserData } from '../interfaces';
import { Styled } from '../styled';
import { UsersTabListItem } from './users-tab-list-item/users-tab-list-item';


interface Props {
  users: UserData[];
  userRoles: RoleData[];
  emptySeatsCount: number;
  disableEditUserList: string[];
  canInvite: boolean;
  maxUsersCount: number;
  resendInvitation?: (email: string) => void;
  addSeatsButtonClick?: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  addUserButtonClick?: () => void;
}

const UsersTabComponent: React.FC<Props> = ({
  users,
  userRoles,
  disableEditUserList,
  emptySeatsCount,
  canInvite,
  changeUserRole,
  deleteUser,
  addUserButtonClick,
  resendInvitation,
  addSeatsButtonClick,
}) => {
  const [userMenu, setUserMenu] = useState(null);
  const menuButtonClick = useCallback((target, email) => {
    const { top } = target.getBoundingClientRect();
    setUserMenu({ email, top });
  }, []);
  const closeMenu = React.useCallback(() => userMenu && setUserMenu(null), [!!userMenu]);
  const deleteUserHandler = React.useCallback((email) => {
    deleteUser(email);
    closeMenu();
  }, [deleteUser, closeMenu]);
  const changeUserRoleHandler = React.useCallback((email, role) => {
    changeUserRole(email, role);
    closeMenu();
  }, [closeMenu, changeUserRole]);

  const items = React.useMemo(() => users.concat(Array(emptySeatsCount).fill(null)), [emptySeatsCount, users]);

  const renderItem = React.useCallback((item: UserData | null) => {
    return (
      <UsersTabListItem
        resendInvitation={resendInvitation}
        menuButtonClick={menuButtonClick}
        userMenuInfo={userMenu}
        user={item}
        userRoles={userRoles}
        canInvite={canInvite}
        closeMenu={closeMenu}
        deleteUser={deleteUserHandler}
        changeUserRole={changeUserRoleHandler}
        disableEditUserList={disableEditUserList}
        addUserButtonClick={addUserButtonClick}
      />
    );
  }, [
    resendInvitation,
    menuButtonClick,
    userMenu,
    userRoles,
    canInvite,
    closeMenu,
    deleteUserHandler,
    changeUserRoleHandler,
    disableEditUserList,
    addUserButtonClick,
  ]);

  return (
    <Styled.UsersTab>
      {addSeatsButtonClick &&
        <Styled.AddPeopleButton>
          <LinkComponent
            size='m'
            onClick={addSeatsButtonClick}
            icon={Icons.PlusBig}
            text={'Buy more seats'}
          />
        </Styled.AddPeopleButton>
      }
      <Styled.UsersContainer usersQuantity={users.length + emptySeatsCount}>
        <EnhancedVirtualList
          objects={items}
          itemHeight={Styled.USER_HEIGHT + 10}
          renderedItemsCount={100}
          renderItem={renderItem}
        />
      </Styled.UsersContainer>
    </Styled.UsersTab>
  );
};

export const UsersTab = React.memo(UsersTabComponent);
