import { MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { RoleData } from '../../interfaces';


interface Props {
  role: RoleData;
  isActive: boolean;
  onSelectRole: (role: RoleData) => void;
}

const UserRolesMenuItemComponent: React.FC<Props> = ({ role, isActive, onSelectRole }) => {
  const onSelectRoleHandler = React.useCallback(() => onSelectRole(role), [onSelectRole, role]);

  return (
    <MenuItem
      key={role.id}
      text={role.name}
      onClick={onSelectRoleHandler}
      textColor={isActive ? 'turquoise' : 'gray'}
    />
  );
};

export const UserRolesMenuItem = React.memo(UserRolesMenuItemComponent);
