import { MERGE_HORIZONTAL_PREFIX, MERGE_VERTICAL_PREFIX } from '2d/units/excel-table-cell-formatter/common';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';

interface UpdateValue {
  columnId: string;
  value: string;
  prevValue: string;
}

const prefixRegexp = new RegExp(`${MERGE_HORIZONTAL_PREFIX}|${MERGE_VERTICAL_PREFIX}`);

export const changeMergeColumnValue = (
  mergeKeys: Record<string, number>,
  offset: number,
  startIndex: number,
): UpdateValue[] => {
  const result = [];
  for (const columnId of Object.keys(mergeKeys)) {
    if (columnId.startsWith(MERGE_VERTICAL_PREFIX)) {
      continue;
    }
    const key = columnId.replace(prefixRegexp, '');
    const keyIndex = ExcelColumnHelper.excelColNameToIndex(key);
    if (keyIndex <= startIndex && keyIndex+mergeKeys[columnId] > startIndex) {
      result.push({
        columnId,
        value: (mergeKeys[columnId] + offset),
        prevValue: mergeKeys[columnId],
      });
    }
  }
  return result;
};
