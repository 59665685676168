import {
  HotKeys,
  IconButton,
  RectangleButton,
  SvgComponent,
} from '@kreo/kreo-ui-components';
import { Delete, PlusBig, SelectAll, Share } from '@kreo/kreo-ui-components/icons';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { SubscriptionType } from 'common/constants/subscription';
import { CompanyEmployeeRole } from 'common/enums/company-employee-role';
import { Mood } from 'common/enums/mood';
import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { Company } from '../../../units/account/interfaces/company';
import { AccountSelectors } from '../../../units/account/selectors';
import { ENTER_ESCAPE } from '../custom-dialog/custom-dialog';
import { InputSelect } from '../inputs';
import { LinkComponent } from '../link-component';
import { RenderIf } from '../render-if';
import { ShareWithCompany } from '../share-with-company';
import { Text } from '../text';
import { Styled } from './styled';


const EmailDropdown: React.FC<any> = ({ elements, onEmailChange, formData, index }) => {
  const activeElementIndex = useMemo(() => elements.findIndex(x => x === formData), [elements, formData]);

  const handleClick = useCallback(
    emailIndex => onEmailChange(index, elements[emailIndex]),
    [onEmailChange, elements, index],
  );

  return (
    <RenderIf condition={!(activeElementIndex < 0)}>
      <Styled.DropdownContainer>
        <InputSelect
          elements={elements}
          activeElementIndex={activeElementIndex}
          onClick={handleClick}
          isShowArrow={true}
          createNewElement={false}
        />
      </Styled.DropdownContainer>
    </RenderIf>
  );
};

const DeleteButton: React.FC<{ index: number, onDelete: (index: number) => void }> = ({
  index,
  onDelete,
}) => {
  const handleDelete = useCallback(() => onDelete(index), [onDelete, index]);
  return (
    <IconButton
      Icon={Delete}
      height={20}
      iconHeight={20}
      onClick={handleDelete}
    />
  );
};

interface Props {
  onFormSubmit: (emails: string[]) => void;
  onClose: () => void;
  onChangeShareWithCompany: () => void;
  openAddPeopleDialog: () => void;
  title: string;
  buttonTitle: string;
  userEmails: string[];
  addedEmails: string[];
  isShareWithCompany: boolean;
  company: Company;
  currentUserEmail: string;
  ButtonIcon?: SvgComponent;
  userLimit?: number;
}

export const AddProjectUserForm = React.memo<Props>(({
  onFormSubmit,
  title,
  buttonTitle,
  ButtonIcon,
  userEmails,
  addedEmails,
  onClose,
  isShareWithCompany,
  onChangeShareWithCompany,
  openAddPeopleDialog,
  company,
  currentUserEmail,
}) => {
  const [formsData, setFormsData] = React.useState(null);
  const [showExtraButton, setShowExtraButton] = React.useState(null);

  const isAddGuest = useSelector((state: State) => state.people.isAddGuest);
  const employees = useSelector((state: State) => state.people.employees);
  const employeesMaxCount = useSelector((state: State) => {
    const subscription = AccountSelectors.currentSubscription(state);
    return subscription && subscription.employeesMaxCount;
  });
  const emptySeatCount = useMemo(() => {
    return employeesMaxCount - employees.filter(x => x.isPayable).length;
  }, [employees, employeesMaxCount]);

  const userLimit = useMemo(() => !isAddGuest ? emptySeatCount : null, [emptySeatCount, isAddGuest]);

  const canShareProjects = useAbility(Operation.Manage, Subject.ShareProjects);
  const isEmployees = useAbility(Operation.Create, Subject.Employees);
  const isGuests = useAbility(Operation.Create, Subject.Guests);
  const isSubcontractors = useAbility(Operation.Create, Subject.Subcontractors);
  const canInvite = useMemo(() => {
    return isEmployees || isGuests || isSubcontractors;
  }, [isEmployees, isGuests, isSubcontractors]);
  const canAddPeople = useMemo(() => {
    return canShareProjects && userLimit && canInvite;
  }, [canInvite, canShareProjects, userLimit]);

  const isAdmin = useMemo(
    () => company.subscriptions[SubscriptionType.Takeoff2d].userRoleCode === RoleCode.Administrator
      && company.subscriptions[SubscriptionType.Takeoff2d].userRole !== CompanyEmployeeRole.Owner,
    [company],
  );

  const newEmailDropdown = useMemo(
    () => userEmails.find(x => !(formsData && formsData.some(f => f === x))),
    [userEmails, formsData],
  );
  const defaultFormData = useMemo(
    () => addedEmails && addedEmails.length ? addedEmails : [newEmailDropdown],
    [addedEmails, newEmailDropdown],
  );
  const notUsedEmail = useMemo(
    () => userEmails.filter(x => !(formsData && formsData.some(f => f === x))),
    [userEmails, formsData],
  );
  const isCanAddMore = useMemo(() => !!notUsedEmail.length, [notUsedEmail]);
  const emailsElements = useMemo(() => {
    if (formsData) {
      return userEmails.filter(x => !formsData.some(f => f === x));
    }
    return [];
  }, [userEmails, formsData]);

  useEffect(() => {
    setFormsData(defaultFormData);
  }, []);

  useEffect(() => {
    if (!isShareWithCompany) {
      setFormsData(addedEmails);
    }
  }, [addedEmails, isShareWithCompany]);

  useEffect(() => {
    if (!formsData?.filter(el => el !== currentUserEmail).length) {
      setShowExtraButton(true);
    } else {
      setShowExtraButton(false);
    }
  }, [currentUserEmail, formsData]);

  useEffect(() => {
    if (isShareWithCompany && !addedEmails?.length) {
      setFormsData([]);
    } else if (isShareWithCompany) {
      const foundEmails = userEmails.filter(email => addedEmails.includes(email));
      setFormsData(foundEmails);
    }
  }, [addedEmails, isShareWithCompany, userEmails]);

  const moreClick = useCallback(() => {
    setFormsData([...formsData, newEmailDropdown]);
  }, [formsData, newEmailDropdown]);

  const selectAllClick = useCallback(() => {
    setFormsData([...formsData, ...notUsedEmail]);
  }, [formsData, notUsedEmail]);

  const submitHandle = useCallback(() => {
    const guests =  addedEmails.filter(email => !userEmails.includes(email));

    return onFormSubmit([...formsData, ...guests]);
  }, [formsData, onFormSubmit, addedEmails, userEmails]);

  const onEmailChange = useCallback(
    (index, value) => {
      const newFormsData = [...formsData];
      newFormsData[index] = value;
      setFormsData(newFormsData);
    }, [formsData]);

  const deleteForm = useCallback(
    index =>
      setFormsData(formsData.filter((_, i) => i !== index)),
    [setFormsData, formsData]);

  return (
    <HotKeys keyMap={ENTER_ESCAPE} handlers={{ escape: onClose, enter: submitHandle }} autofocus={true}>
      <Styled.Container>
        <Text>
          <Styled.IconShare>
            <Share />
            {title}
          </Styled.IconShare>
          <LinkComponent
            text={'Select All'}
            icon={SelectAll}
            onClick={selectAllClick}
            mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
          />
        </Text>
        <Styled.BtnWrapper>
          <Styled.LinkWrapper>
            <LinkComponent
              text={isShareWithCompany ? 'Add guest' : 'Add more'}
              icon={PlusBig}
              onClick={moreClick}
              mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
            />
            <LinkComponent
              text={'Add people to company'}
              icon={PlusBig}
              onClick={openAddPeopleDialog}
              mood={canAddPeople ? Mood.Secondary : Mood.Disabled}
            />
          </Styled.LinkWrapper>
          <RectangleButton
            Icon={ButtonIcon}
            mood={Mood.Secondary}
            text={buttonTitle}
            width={165}
            height={60}
            onClick={submitHandle}
          />
        </Styled.BtnWrapper>
        <ShareWithCompany
          checked={isShareWithCompany}
          onChange={onChangeShareWithCompany}
          company={company}
        />
        <Styled.FormWrapper>
          <RenderIf condition={showExtraButton}>
            <LinkComponent
              text={isShareWithCompany ? 'Add guest' : 'Add more'}
              icon={PlusBig}
              onClick={moreClick}
              mood={isCanAddMore ? Mood.Secondary : Mood.Disabled}
            />
          </RenderIf>
          <RenderIf condition={!!formsData}>
            {formsData && formsData.map((formData, index) => {
              const currentElement = userEmails.find(u => u === formData);
              const allEmail = currentElement ? [...emailsElements, currentElement] : emailsElements;

              if (formData === currentUserEmail && !isAdmin || !currentElement) {
                return null;
              }

              return (
                <Styled.Form key={index}>
                  <Text fontSize={14}>Email</Text>
                  <EmailDropdown
                    onEmailChange={onEmailChange}
                    elements={allEmail.sort()}
                    formData={formData}
                    index={index}
                  />
                  <DeleteButton index={index} onDelete={deleteForm} />
                </Styled.Form>
              );
            })}
          </RenderIf>
        </Styled.FormWrapper>
      </Styled.Container>
    </HotKeys>
  );
});

