import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { EstimateSubscriptionModel } from '../interfaces/estimation-subscription-model';

export const useTotalInvoice = (): number => {
  const billingEstimation = useSelector<State, EstimateSubscriptionModel>(state => state.account.billingEstimation);

  const total = useMemo(() => {
    return billingEstimation?.nextInvoice && billingEstimation.nextInvoice?.total
      ? billingEstimation?.nextInvoice.total / 100
      : billingEstimation?.immediateInvoice && billingEstimation.immediateInvoice?.total
        ? billingEstimation?.immediateInvoice.total / 100
        : null;
  }, [billingEstimation]);

  return total;
};
