import autobind from 'autobind-decorator';
import * as THREE from 'three';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsGeometryStrokedType, ShortPointDescription } from 'common/components/drawings/interfaces';
import { COLORS } from '../constants';
import { createMultiMaterialObject } from '../utils';
import { VisibleObject, Config as VisibleObjectConfig } from './visible-object';

interface MaterialLookSettings {
  transparent: boolean;
  opacity: number;
  roughness: number;
}

export interface ConvertGeometryResult {
  indices: Uint16Array;
  vertices: Float32Array;
}

export interface GeometryConfig<T extends DrawingsGeometryStrokedType> extends VisibleObjectConfig<T> {
  getPointInfo: (pointId: string) => ShortPointDescription | undefined;
  scaleObserver: ContextObserver<number>;
  id: string;
  materialLookSettings: MaterialLookSettings;
  materialCahce: { getMaterialByColor: ({ color, side }: { color: string, side: THREE.Side }) => THREE.Material };
}

export class Geometry<
  T extends DrawingsGeometryStrokedType = DrawingsGeometryStrokedType,
  C extends GeometryConfig<T> = GeometryConfig<T>,
> extends VisibleObject<T, C> {
  protected _mesh?: THREE.Mesh;
  protected _shape: THREE.Shape;
  protected _linePoints: ShortPointDescription[][];
  protected _segments: THREE.Line[];
  protected _height;
  protected _selected: boolean;
  protected _offset: number;

  constructor(config: C) {
    super(config);
    config.scaleObserver.subscribe(this.onScaleChange);
  }

  public set opacity(opacity: number) {
    this._mesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.opacity = opacity;
      }
    });
  }

  public set materialLookSettings(settings: C['materialLookSettings']) {
    this._mesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material.transparent = settings.transparent;
        child.material.opacity = settings.opacity;
        child.material.roughness = settings.roughness;
      }
    });
  }

  public setSelected(select: boolean): void {
    if (select === this._selected) {
      return;
    }
    const color = select ? COLORS.edgesSelected : COLORS.edges;
    this._segments.forEach((segment) => {
      (segment.material as THREE.LineBasicMaterial).color = color;
    });
    this._selected = select;
  }

  public override destroy(): void {
    super.destroy();
    this._config.scaleObserver.unsubscribe(this.onScaleChange);
    this._mesh?.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();
      }
    });
  }

  protected calculateHeight(scale: number): number {
    const { geometry } = this._config;
    return geometry.height ? geometry.height * scale : 0;
  }

  protected getMaterial(): THREE.Material {
    const { color } = this._config.geometry;
    return this._config.materialCahce.getMaterialByColor({ color, side: THREE.BackSide });
  }

  protected override async render(geometry: T): Promise<void> {
    this._linePoints = [];
    this._segments = [];
    const scale = this._config.scaleObserver.getContext();
    this._height = this.calculateHeight(scale);
    const { vertices, indices } = await Promise.resolve(this.getConvertedGeometry(geometry));

    const bufferGeometry = new THREE.BufferGeometry();
    bufferGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    bufferGeometry.setIndex(new THREE.BufferAttribute(indices, 1));
    bufferGeometry.computeVertexNormals();

    // const standardMaterial = new THREE.MeshStandardMaterial(this.getMaterialSettings(geometry));
    const mesh = createMultiMaterialObject(bufferGeometry, [this.getMaterial()], { id: this._config.id });
    this._config.scene.add(mesh);
    this._mesh = mesh as THREE.Mesh;

    this.applyOffset();
    this.renderLines();
  }

  protected renderLines(): void {
    const color = this._selected === true ? COLORS.edgesSelected : COLORS.edges;
    this._mesh.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        const edges = new THREE.EdgesGeometry(child.geometry);
        const lines = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color }));
        lines.position.z = lines.position.z + this._offset;
        this._segments.push(lines);
        this._config.scene.add(lines);
      }
    });
  }

  protected getConvertedGeometry(_geometry: T): ConvertGeometryResult | Promise<ConvertGeometryResult> {
    return { indices: new Uint16Array(), vertices: new Float32Array() };
  }

  protected applyOffset(): void {
    if (this._config.geometry.offset) {
      this._offset = this._config.geometry.offset * this._config.scaleObserver.getContext();
      this._mesh.position.z = this._offset;
    } else {
      this._offset = 0;
    }
  }

  @autobind
  protected onScaleChange(scale: number): void {
    const newHeight = this.calculateHeight(scale);
    if (this._height === newHeight) {
      return;
    }

    this._height = newHeight;
    this.removeGeometry();
    this.render(this._config.geometry);
  }

  protected removeLines(): void {
    this._segments.forEach((segment) => {
      segment.geometry.dispose();
      this._config.scene.remove(segment);
    });
    this._segments = [];
  }

  @autobind
  protected convertPoint(pointId: string): ShortPointDescription {
    const point = this._config.getPointInfo(pointId);
    if (!point) {
      throw new Error(`Point with id ${pointId} not found`);
    }
    return [point[0], -point[1]];
  }

  protected override removeGeometry(): void {
    super.removeGeometry();
    this.removeLines();
  }
}
