import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { KreoDialogActions } from 'common/UIKit';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';
import { SubscriptionActions } from '../../../actions/creators';
import { RENEW_CONFIRM_DIALOG } from '../renew-confirm-dialog';

type UseAfterApplyCallback = (
  subscription: CompanySubscriptionModel,
) => [(id: string, hasChange?: boolean) => void];

export const useAfterApplyCallback: UseAfterApplyCallback = (subscription) => {
  const dispatch = useDispatch();
  const afterRenew = useCallback((subscriptionId, hasChange) => {
    dispatch(KreoDialogActions.openDialog(RENEW_CONFIRM_DIALOG, { subscriptionId, hasChange }));
  }, [dispatch]);

  const afterPause = useCallback((subscriptionId) => {
    dispatch(SubscriptionActions.resumeSubscription(subscriptionId));
  }, [dispatch]);

  const handleAfterApply = useCallback((id, hasChange) => {
    const isPausedSubscription = AccountSelectors.isPausedSubscription(subscription);
    if (isPausedSubscription) {
      afterPause(id);
    } else {
      afterRenew(id, hasChange);
    }
  }, []);

  return [handleAfterApply];
};
