import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { change, formValueSelector } from 'redux-form';
import { State } from 'common/interfaces/state';
import { ProjectsActions } from 'unit-projects/actions/creators/common';

import { Project } from 'unit-projects/interfaces/project';
import { InviteUsersToProjectPanel } from '../../../../components/dialog';
import { CREATE_PROJECT } from '../../../../constants/forms';
import { PeopleActions } from '../../../../units/people/actions/actions';
import { UpdateInvitationToProjectParams } from '../../../../units/people/actions/payloads';
import { Person } from '../../../../units/people/interfaces/person';


const selector = formValueSelector(CREATE_PROJECT);

interface StateProps {
  isSharedWithCompany: boolean;
  currentProject: Project;
}

interface DispatchProps {
  updateInvitedPeoples: (payload: UpdateInvitationToProjectParams) => void;
  updateProjectInvitedUsers: (users: Person[]) => void;
  setShareWithCompany: (value: boolean) => void;
}

interface Props extends DispatchProps, StateProps {
  projectId: number;
  projectType: string;
  projectUsers: Array<{ email: string }> | Person[];
}

const InviteUsersProjectDialogComponent: React.FC<Props> = ({
  projectId,
  projectType,
  projectUsers,
  isSharedWithCompany,
  currentProject,
  updateInvitedPeoples,
  updateProjectInvitedUsers,
  setShareWithCompany,
}) => {
  const invitedUsers = useMemo(() => projectUsers.map(x => x.email), [projectUsers]);

  const onInvitePeople = useCallback((users: Person[], isCompanyShared: boolean) => {
    updateInvitedPeoples({
      projectId,
      newEmails: users.map(user => user.email),
      oldEmails: projectUsers.map(user => user.email),
      isCompanyShared,
    });
    updateProjectInvitedUsers(users);
  }, [updateInvitedPeoples, projectId, projectUsers, updateProjectInvitedUsers]);

  const onSetShareWithCompany = useCallback(() => {
    setShareWithCompany(!isSharedWithCompany);
  }, [setShareWithCompany, isSharedWithCompany]);
  return (
    <InviteUsersToProjectPanel
      onFormSubmit={onInvitePeople}
      invitedUsers={invitedUsers}
      projectType={projectType}
      isSharedWithCompany={currentProject.isCompanyShared}
      onChangeSharedWithCompany={onSetShareWithCompany}
    />
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    isSharedWithCompany: !!selector(state, 'isShareWithCompany'),
    currentProject: state.projects.currentProject,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    updateInvitedPeoples: (payload) => dispatch(PeopleActions.updateInvitedPeoples(payload)),
    updateProjectInvitedUsers: (payload) => dispatch(ProjectsActions.updateProjectInvitedUsers(payload)),
    setShareWithCompany: (value) => dispatch(change(CREATE_PROJECT, 'isShareWithCompany', value)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const InviteUsersProjectDialog = connector(InviteUsersProjectDialogComponent);
