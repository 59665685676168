import React, { useCallback } from 'react';

import { UpgradeWrapper } from '2d/components/upgrade-plan';
import { InteractiveMenuContainer } from 'common/components/interactive-menu';
import { ElementTooltip } from 'common/components/tooltip';
import { ConstantFunctions } from 'common/constants/functions';
import { useOpenMenuWithRect } from 'common/hooks';
import { DrawingsSVGIcons } from '../../constants/drawings-svg-icons';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsSvgMenu } from '../drawings-svg-lib-control/drawings-svg-menu';
import { InstrumentInfo } from '../interfaces';
import { SelectModeSettings } from './interfaces';
import { Styled } from './styled';


interface ViewProps {
  instrument: InstrumentInfo;
  isActive: boolean;
  onClick: (e: React.MouseEvent) => void;
  needUpdatePlan: boolean;
}

const InstrumentView: React.FC<ViewProps> = ({
  instrument,
  isActive,
  onClick,
  needUpdatePlan,
}) => {

  const Icon = instrument.icon;

  return (
    <ElementTooltip
      position={'bottom'}
      tooltipWidth={210}
      speed={'l'}
      text={instrument.name}
      size='small'
      hotKey={instrument.hotKey}
      description={instrument.description}
      disabled={needUpdatePlan || !instrument.description}
    >
      <UpgradeWrapper isNeedUpdate={needUpdatePlan}>
        <Styled.Instrument
          active={isActive}
          onClick={needUpdatePlan ? undefined : onClick}
          disabled={needUpdatePlan || instrument.disabled}
          id={instrument.uniqueId}
          data-control-name={instrument.dataControlName}
        >
        <Icon />
        </Styled.Instrument>
        {instrument.descriptionContent ? (
          <instrument.descriptionContent.component {...instrument.descriptionContent.props} />
        ) : null}
      </UpgradeWrapper>
    </ElementTooltip>
  );
};


export interface ItemProps {
  instrument: InstrumentInfo;
  isActive: boolean;
  needUpdatePlan: boolean;
  onClick: (selectSettings: SelectModeSettings) => void;
}


export const Instrument: React.FC<ItemProps> = ({
  instrument,
  onClick,
  isActive,
  needUpdatePlan,
}) => {

  const { onOpen, isOpen, position, onClose } = useOpenMenuWithRect(true);

  const onClickHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      ConstantFunctions.stopPropagation(e);
      if (!needUpdatePlan) {
        if (instrument.id === DrawingsDrawMode.Image) {
          onOpen(e);
        } else {
          onClick({ mode: instrument.id });
        }
      }
    },
    [onClick, instrument, needUpdatePlan, onOpen],
  );

  const onSelectImage = useCallback((imageKey: DrawingsSVGIcons) => {
    onClick({ mode: instrument.id, image: imageKey });
    onClose();
  }, [instrument, onClick, onClose]);

  return (
    <>
      <InstrumentView
        instrument={instrument}
        isActive={isActive}
        onClick={onClickHandler}
        needUpdatePlan={needUpdatePlan}
      />
      {
        isOpen ? (
          <InteractiveMenuContainer
            parentRect={position}
            withBlanket={true}
            onCloseClick={onClose}
            bindVertical='top'
            orientation='vertical'
            marginTop={10}
          >
            <DrawingsSvgMenu
              onSelect={onSelectImage}
            />
          </InteractiveMenuContainer>
        ) : null
      }
    </>
  );
};
