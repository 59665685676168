import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { LinkComponent } from 'common/components/link-component';
import { ColorsName } from 'common/enums/kreo-colors';
import { Mood } from 'common/enums/mood';
import { RenderIf } from '../../render-if';
import { Styled } from './styled';


export interface FormsData {
  id: string;
  email: string;
  role: string;
}

interface Props {
  canAddUser: boolean;
  hiddenSkipButton: boolean;
  inviteUsersButtonText: string;
  moreClick: () => void;
  handleSkip: () => void;
  onHandleInviteUsers: () => void;
}

export const ManageButtons = React.memo<Props>(({
  canAddUser,
  hiddenSkipButton,
  inviteUsersButtonText,
  moreClick,
  handleSkip,
  onHandleInviteUsers,
}) => {
  return (
    <>
      {canAddUser && (
        <Styled.AddEmailButtonContainer canAddUser={canAddUser}>
          <LinkComponent
            text={'Add email address'}
            icon={Icons.PlusBig}
            onClick={moreClick}
          />
        </Styled.AddEmailButtonContainer>
      )}
      <Styled.ContinueButtonContainer>
        <RenderIf condition={!hiddenSkipButton}>
          <RectangleButton
            mood={Mood.Default}
            text={'Skip'}
            fontSize={14}
            height={40}
            textColor={ColorsName.mainFont}
            onClick={handleSkip}
          />
        </RenderIf>
        <RectangleButton
          mood={Mood.Secondary}
          text={inviteUsersButtonText}
          fontSize={14}
          height={40}
          onClick={onHandleInviteUsers}
        />
      </Styled.ContinueButtonContainer>
    </>
  );
},
);
