import { DropdownInputNew, Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { PaymentMethodInfo, PaymentMethodType } from '../interfaces';
import { CardIntegratedPayment } from './card-integrated-payment';
import { CardPayment } from './card-payment';
import { NoCardIntegratedPayment } from './no-card-integrated-payment';
import { Styled } from './styled';


interface Props {
  selectedPaymentId: string;
  paymentMethods: PaymentMethodInfo[];
  onSelectPaymentMethod: (paymentMethodId: string) => void;
}

function paymentMethodRenderer(info: PaymentMethodInfo, isActive: boolean): React.ReactNode {
  if (info.type === PaymentMethodType.Card) {
    return <CardPayment {...info.card} isActive={isActive} />;
  } else if (info.card) {
    return <CardIntegratedPayment type={info.type} {...info.card} isActive={isActive} />;
  } else {
    return <NoCardIntegratedPayment type={info.type} isActive={isActive} description={info.description} />;
  }
}

function selectedOptionRenderer(info: PaymentMethodInfo): React.ReactNode {
  return info ? paymentMethodRenderer(info, false) : <Text>Select Card</Text>;
}

const PaymentMethodsDropdownComponent: React.FC<Props> = ({
  selectedPaymentId,
  paymentMethods,
  onSelectPaymentMethod,
}) => {
  const convertedPaymentMethods = React.useMemo(
    () => paymentMethods.map(x => ({ ...x, value: x.id })),
    [paymentMethods],
  );
  const onSelectSelectPaymentMethod = React.useCallback(
    (paymentOption) => onSelectPaymentMethod(paymentOption.value),
    [onSelectPaymentMethod],
  );
  return (
    <Styled.Container>
      <DropdownInputNew<PaymentMethodInfo & { value: string }>
        options={convertedPaymentMethods}
        selectedOption={selectedPaymentId}
        onClick={onSelectSelectPaymentMethod}
        borderRadius={10}
        selectedOptionRenderer={selectedOptionRenderer}
        optionRenderer={paymentMethodRenderer}
        backgroundColor='background'
        maxItemsAmount={3}
        closeAfterClick={true}
      />
    </Styled.Container>
  );
};

export const PaymentMethodsDropdown = React.memo(PaymentMethodsDropdownComponent);
