import styled from 'styled-components';


const USER_HEIGHT = 60;
const USER_MARGIN = 10;
export const AVATAR_HEIGHT = 120;
const HEADER_GAP = 40;

const Container = styled.div`
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 640px;
  height: 100%;
  border-radius: 40px;
  &, * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  position: relative;
  padding: 0 40px;
  display: grid;
  grid-template-columns: ${AVATAR_HEIGHT}px calc(100% - ${AVATAR_HEIGHT + HEADER_GAP}px);
  height: ${AVATAR_HEIGHT}px;
  grid-gap: ${HEADER_GAP}px;
  align-items:center;
  width: 100%;
  margin-bottom: 20px;
`;

const CompanyDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FlowNavigation = styled.div`
  height: 50px;
  padding: 0 40px;
  border-bottom: 1px solid ${p => p.theme.color.background};
  margin-bottom: 20px;
`;

const Company = styled.div`
  padding: 0 40px;
  width: 100%;
`;

const UsersTab = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  width: 100%;
  height: 100%;
  align-items: flex-start;
`;

const UsersContainer = styled.div<{ usersQuantity: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const User = styled.div`
  height: ${USER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  width: 100%;
  background: ${p => p.theme.color.background};
  border-radius: 30px;
  :not(:last-child){
    margin-bottom: ${USER_MARGIN}px;
  }

  div:first-child {
    min-width: 40px;
  }
`;

const UserTextInfo = styled.div`
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
  cursor: default;
  p:first-child {
    margin-bottom: auto;
    margin-top: auto;
  }
`;

const UserRole = styled.div`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 15px;
  padding: 0 20px;
  background: ${p => p.theme.color.backgroundRush};
`;

const UserRightSide = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
  margin-right: 20px;
`;

const UserMenu = styled.div`
  position: relative;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserMenuListContainer = styled.div<{ top: number }>`
  position: fixed;
  right: 85px;
  top: ${p => p.top + 20}px;
  z-index: 1;
`;

const DeleteCompanyButton = styled.div`
  margin-top: 50px;
`;

const RolesMenuItems = styled.div`
  > div {
    height: 40px;
    :first-child {
      padding-top: 10px;
    }
    :last-child {
      padding-bottom: 10px;
    }
  }
`;

const DeleteUserMenuItem = styled.div`
  > div {
    border-top: 1px solid ${p => p.theme.color.background};
  }
`;

const AddPeopleButton = styled.div`
  > div {
    margin-bottom: 20px;
  }
`;

const MoreUserAvatar = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:  ${p => p.theme.color.backgroundRush};
  border-radius: 50%;
  svg {
    height: 20px;
    fill: ${p => p.theme.color.gray};
  }
`;

const AddUserButton = styled.div`
  margin-left: auto;
  margin-right: 50px;
`;


export const Styled = {
  Container,
  Header,
  CompanyDescription,
  Company,
  FlowNavigation,
  UsersTab,
  UsersContainer,
  User,
  UserTextInfo,
  UserRole,
  UserRightSide,
  UserMenu,
  UserMenuListContainer,
  DeleteCompanyButton,
  RolesMenuItems,
  DeleteUserMenuItem,
  AddPeopleButton,
  MoreUserAvatar,
  AddUserButton,
  USER_HEIGHT,
};
