import { ElementTooltip, IconButton, Icons, MenuItem, Text, MovableContextMenu } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { withUndoRedoApiProps } from 'common/undo-redo/with-undo-redo-api-props';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { TwoDFullScreenMode } from '../../../../units/persisted-storage/interfaces/state';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';
import { TwoDActions } from '../../actions/creators';
import { Arrangement } from '../../constants';
import { ReportPage } from '../../interfaces';
import { TwoDReportUndoRedoHelper } from '../../units/2d-report-undo-redo-helper';
import { getArrangementCount } from '../../units/get-arragement-count';
import { TwoDElementViewActions } from '../2d-element-view/store-slice';
import { IMPORT_EXCEL_DIALOG, TwoDExcelImporter } from '../2d-excel-importer';
import { UpgradeWrapper } from '../upgrade-plan';
import { ExportExcelButton } from './export-excel-button';
import { ShowCodeSwitcher } from './show-code-switcher';
import { Styled } from './styled';
import { TwoDSwitcher } from './switcher/switcher';


interface StateProps {
  currentDrawingId: string;
  tableViewType: Arrangement;
  reportPages: ReportPage[];
  showTableUnits: boolean;
  isFullScreenDrawings: boolean;
  showTableTotal: boolean;
  selectedSheetId: string;
  isGetPageData: boolean;
  viewConfig: TwoDViewTableConfig;
  reports: ReportPage[];
  canShowFullScreen: boolean;
}

interface DispatchProps {
  openImportDialog: () => void;
  setShowSheetIds: (ids: string[]) => void;
  setTableViewType: (tableViewType: Arrangement) => void;
  showUnitsToggle: () => void;
  showTableTotalToggle: () => void;
}

interface OwnProps {
  projectId: string;
  onFullscreenTableToggle: () => void;
  isFullScreenTable: boolean;
  reportEditPermission: boolean;
  reportViewPermission: boolean;
}

interface Props extends OwnProps, StateProps, DispatchProps, UndoRedoContextApiProps, AbilityAwareProps { }

interface ComponentState {
  isOpen: boolean;
  x: number;
  y: number;
}

class TwoDReportPanelMenuComponent extends React.PureComponent<Props, ComponentState> {
  private arrangementTooltips: Record<string, string> = {
    [Arrangement.SingleView]: '1 View',
    [Arrangement.TwoViewsSideBySide]: '2 Views Side-by-Side',
    [Arrangement.TwoViewsStacked]: '2 Views Stacked',
    [Arrangement.ThreeViewsTopSplit]: '3 Views Top Split',
    [Arrangement.ThreeViewsBottomSplit]: '3 Views Bottom Split',
    [Arrangement.ThreeViewsLeftSplit]: '3 Views Left Split',
    [Arrangement.ThreeViewsRightSplit]: '3 Views Right Split',
    [Arrangement.FourViews]: '4 Views',
    [Arrangement.FourViewsTopSplit]: '4 Views Top Split',
    [Arrangement.FourViewsBottomSplit]: '4 Views Bottom Split',
    [Arrangement.FourViewsLeftSplit]: '4 Views Left Split',
    [Arrangement.FourViewsRightSplit]: '4 Views Right Split',
    [Arrangement.FourViewsStacked]: '4 Views Stacked',
    [Arrangement.FourViewsSideBySide]: '4 Views Side-by-Side',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      x: 0,
      y: 0,
    };
  }

  public render(): JSX.Element {
    const {
      projectId,
      ability,
      reportEditPermission,
      reportViewPermission,
      isFullScreenTable,
      tableViewType,
      showTableUnits,
      isFullScreenDrawings,
      showTableTotal,
      canShowFullScreen,
      isGetPageData,
    } = this.props;
    const isImportDisable = !ability.can(Operation.Manage, Subject.Takeoff2dExcelImport);
    const canImport = ability.can(Operation.Read, Subject.ImportSchedules2D)
      && reportEditPermission;
    const isReportEditable = this.isShowToggleWithChangeReport();
    const showFullscreen = this.props.reports.length && canShowFullScreen;
    return (
      <>
        <IconButton
          Icon={Icons.Menu}
          onClick={this.onOpenMenu}
          width={36}
          height={36}
        />
        {this.state.isOpen && (
          <MovableContextMenu
            x={this.state.x}
            y={this.state.y}
            onClose={this.filtersClose}
          >
            {
              canImport && (
                <Styled.ImportItemWrapper>
                  <UpgradeWrapper isNeedUpdate={isImportDisable}>
                    <ElementTooltip
                      text='Import .xlsx files'
                      position='right'
                      speed='l'
                    >
                      <MenuItem
                        Icon={Icons.Sheet}
                        text='Import .xlsx files'
                        onClick={this.openImportDialog}
                        textColor={ColorsName.mainFont}
                        fontSize={14}
                        size={'m'}
                        withBorder={true}
                        disabled={!reportEditPermission || isImportDisable}
                      />
                    </ElementTooltip>
                  </UpgradeWrapper>
                </Styled.ImportItemWrapper>

              )
            }
            {
              reportViewPermission &&
              <ExportExcelButton onClick={this.closeMenu} projectId={projectId} />
            }
            {reportViewPermission &&
              <>
                <Styled.Tabs>
                  <Text
                    color={ColorsName.turquoiseInversed}
                    fontSize={12}
                  >
                    Views
                  </Text>
                  <Styled.Icons isDisabledButton={isFullScreenDrawings}>
                    {Object.values(Arrangement).map(key => {
                      const isEnable = this.isArrangementEnable(Arrangement[key]);
                      return isEnable && (
                        <ElementTooltip
                          key={key}
                          text={this.arrangementTooltips[key]}
                          position={'bottom'}
                          disabled={isFullScreenDrawings}
                        >
                          <IconButton
                            key={key}
                            Icon={Icons[key]}
                            width={35}
                            height={35}
                            onClick={this.setArrangementUndoRedo(Arrangement[key])}
                            defaultColor={tableViewType === Arrangement[key] && 'turquoise'}
                            mood={isFullScreenDrawings ? 'disabled' : 'default'}
                          />
                        </ElementTooltip>
                      );
                    })}
                  </Styled.Icons>
                </Styled.Tabs>
                {isReportEditable && <TwoDSwitcher
                  onToggle={this.onShowUnitTableToggle}
                  isChecked={showTableUnits}
                  name={'Show units'}
                />}
                {isReportEditable && <TwoDSwitcher
                  onToggle={this.onShowTableTotalToggle}
                  isChecked={showTableTotal}
                  name={'Show total'}
                />}
                <RenderIf condition={!!showFullscreen}>
                  <TwoDSwitcher
                    onToggle={this.onFullscreenTableToggle}
                    isChecked={isFullScreenTable}
                    name={'Fullscreen'}
                  />
                </RenderIf>
                <ShowCodeSwitcher
                  handleClick={this.closeMenu}
                />
              </>
            }
          </MovableContextMenu>
        )}
        <Spinner show={!isGetPageData} fullScreen={false} withBackground={true} />
        <TwoDExcelImporter />
      </>
    );
  }

  private isShowToggleWithChangeReport(): boolean {
    if (this.props.viewConfig) {
      return this.props.viewConfig.allowColumnsConfiguration;
    }
    return true;
  }

  @autobind
  private onOpenMenu(e: React.MouseEvent): void {
    const { left, top } = e.currentTarget.getBoundingClientRect();
    const menuOffset = 25;
    this.setState({
      isOpen: true,
      x: left + menuOffset,
      y: top + menuOffset,
    });
  }

  @autobind
  private closeMenu(): void {
    this.setState({ isOpen: false });
  }

  @autobind
  private openImportDialog(): void {
    this.props.openImportDialog();
    this.closeMenu();
  }

  @autobind
  private onFullscreenTableToggle(): void {
    this.props.onFullscreenTableToggle();
    this.closeMenu();
  }

  @autobind
  private onShowUnitTableToggle(): void {
    this.props.showUnitsToggle();
    this.closeMenu();
  }

  @autobind
  private onShowTableTotalToggle(): void {
    this.props.showTableTotalToggle();
    this.closeMenu();
  }

  @autobind
  private filtersClose(): void {
    this.setState({ isOpen: false });
  }

  @autobind
  private isArrangementEnable(type: Arrangement): boolean {
    const reportCount = this.props.reportPages.length;
    switch (type) {
      case Arrangement.SingleView:
        return reportCount >= 1;
      case Arrangement.TwoViewsStacked:
      case Arrangement.TwoViewsSideBySide:
        return reportCount >= 2;
      case Arrangement.ThreeViewsBottomSplit:
      case Arrangement.ThreeViewsLeftSplit:
      case Arrangement.ThreeViewsRightSplit:
      case Arrangement.ThreeViewsTopSplit:
        return reportCount >= 3;
      case Arrangement.FourViews:
      case Arrangement.FourViewsBottomSplit:
      case Arrangement.FourViewsLeftSplit:
      case Arrangement.FourViewsRightSplit:
      case Arrangement.FourViewsSideBySide:
      case Arrangement.FourViewsStacked:
      case Arrangement.FourViewsTopSplit:
        return reportCount >= 4;
      default:
        return false;
    }
  }

  @autobind
  private setArrangement(type: Arrangement): void {
    const reportCount = getArrangementCount(type);
    const { selectedSheetId, reportPages } = this.props;
    const sheetToShowIds = reportCount === 1
      ? [selectedSheetId]
      : [
        selectedSheetId,
        ...reportPages
          .filter(p => p.id !== selectedSheetId)
          .slice(0, reportCount - 1)
          .map(p => p.id)];
    this.props.setShowSheetIds(sheetToShowIds);
    this.props.setTableViewType(type);
  }

  @autobind
  private setArrangementUndoRedo(type: Arrangement): () => void {
    return () => {
      const prevType = this.props.tableViewType;
      const { undo, redo } = TwoDReportUndoRedoHelper.getUpdateArrangementUndoRedo(type, prevType, this.setArrangement);
      this.props.addUndoRedo(undo, redo);

      if (!this.props.isFullScreenDrawings) {
        redo();
      }
    };
  }
}


function mapStateToProps(state: State): StateProps {
  const { currentDrawingInfo } = state.drawings;
  const projectId = state.projects.currentProject.id.toString();
  return {
    selectedSheetId: state.twoD.selectedSheetId,
    currentDrawingId: currentDrawingInfo && currentDrawingInfo.drawingId,
    tableViewType: state.twoD.tableViewType,
    reportPages: state.twoD.reportPages,
    viewConfig: state.twoD.viewsConfigs[state.twoD.selectedSheetId],
    showTableUnits: state.persistedStorage.showTableUnits,
    isFullScreenDrawings: PersistedStorageSelectors
      .selectTwoDFullScreenMode(state.persistedStorage, projectId) === TwoDFullScreenMode.Drawings,
    showTableTotal: state.persistedStorage.showTableTotal,
    isGetPageData: state.twoD.isGetPageData,
    reports: state.twoD.reportPages,
    canShowFullScreen: state.multiView.connectionStatus === null,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openImportDialog: () => dispatch(KreoDialogActions.openDialog(IMPORT_EXCEL_DIALOG)),
    setShowSheetIds: (ids: string[]) => dispatch(TwoDActions.setShowSheetsIds(ids)),
    setTableViewType: (tableViewType: Arrangement) => dispatch(TwoDActions.setTableViewType(tableViewType)),
    showUnitsToggle: () => {
      dispatch(PersistedStorageActions.toggleShowUnits());
      dispatch(TwoDElementViewActions.handleToggleShowUnit());
    },
    showTableTotalToggle: () => dispatch(PersistedStorageActions.toggleShowTableTotal()),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const TwoDReportPanelMenu = connector(
  withAbilityContext(
    withUndoRedoApiProps(TwoDReportPanelMenuComponent),
  ),
);
