const prefix = '@two-d';

export const TwoDActionTypes = {
  FOCUS_CELL: `${prefix}/FOCUS_CELL`,
  SET_WRITE: `${prefix}/SET_WRITE`,
  SET_READ: `${prefix}/SET_READ`,
  UPDATE_DRAWINGS_TO_CELL: `${prefix}/UPDATE_DRAWINGS_TO_CELL`,
  UPDATE_DYNAMIC_GROUPS_TO_CELL: `${prefix}/UPDATE_DYNAMIC_GROUPS_TO_CELL`,
  SET_CELL_TO_UPDATE: `${prefix}/SET_CELL_TO_UPDATE`,
  CLEAR_CELL_TO_UPDATE: `${prefix}/CLEAR_CELL_TO_UPDATE`,
  GET_PAGES: `${prefix}/GET_PAGES`,
  SET_PAGES: `${prefix}/SET_PAGES`,
  CREATE_PAGE: `${prefix}/CREATE_PAGE`,
  CREATE_VIEW: `${prefix}/CREATE_VIEW`,
  DELETE_PAGE: `${prefix}/DELETE_PAGE`,
  RESTORE_PAGE: `${prefix}/RESTORE_PAGE`,
  ADD_ROWS: `${prefix}/ADD_ROWS`,
  ADD_COLUMNS: `${prefix}/ADD_COLUMNS`,
  SET_NEW_PAGE: `${prefix}/SET_NEW_PAGE`,
  SAVE_REPORT_CELLS_UPDATES: `${prefix}/SAVE_REPORT_CELLS_UPDATES`,
  PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE: `${prefix}/PUT_MESSAGE_TO_UPDATE_REPORT_QUEUE`,
  UPDATE_REPORT_QUEUE_MESSAGE_DONE: `${prefix}/UPDATE_REPORT_QUEUE_MESSAGE_DONE`,
  UPDATE_REPORT_QUEUE_MESSAGE_FAILED: `${prefix}/UPDATE_REPORT_QUEUE_MESSAGE_FAILED`,
  DOWNLOAD_EXCEL_FILE: `${prefix}/DOWNLOAD_EXCEL_FILE`,
  SET_SELECTED_SHEET_ID: `${prefix}/SET_SELECTED_SHEET_ID`,
  SET_UPLOAD_EXCEL: `${prefix}/SET_UPLOAD_EXCEL`,
  UPDATE_PAGE_INFO: `${prefix}/UPDATE_PAGE_INFO`,
  MOVE_TO_PAGE: `${prefix}/MOVE_TO_PAGE`,
  SET_TABLE_POSITION: `${prefix}/SET_TABLE_POSITION`,
  SET_SHOW_SHEETS_IDS: `${prefix}/SET_SHOW_SHEETS_IDS`,
  SHOW_TRACE_LINK: `${prefix}/SHOW_TRACE_LINK`,
  HIDE_TRACE_LINK: `${prefix}/HIDE_TRACE_LINK`,
  DUPLICATE_PAGE: `${prefix}/DUPLICATE_PAGE`,
  SET_CELL_WITH_BORDER: `${prefix}/SET_CELL_WITH_BORDER`,
  SET_FORMULA_BAR: `${prefix}/SET_FORMULA_BAR`,
  SET_DRAWING_INSTANCE_IN_CELL_RANGE: `${prefix}/SET_DRAWING_INSTANCE_IN_CELL_RANGE`,
  SET_TWOD_TABLE_VIEW_TYPE: `${prefix}/SET_TWOD_TABLE_VIEW_TYPE`,
  DROP_STATE: `${prefix}/DROP_STATE`,
  SET_EDIT_FULL_CELL_ID: `${prefix}/SET_EDIT_FULL_CELL_ID`,
  UPDATE_COLUMN_DATA: `${prefix}/UPDATE_COLUMN_DATA`,
  SET_QUICK_SEARCH_VALUE: `${prefix}/SET_QUICK_SEARCH_VALUE`,
  SET_FILTERED_NODE_IDS: `${prefix}/SET_FILTERED_NODE_IDS`,
  SET_ADD_NEW_SHEET_STATUS: `${prefix}/SET_ADD_NEW_SHEET_STATUS`,
  UPDATE_CELL_TO_CELL: `${prefix}/UPDATE_CELL_TO_CELL`,
  ADD_NEW_SHEET: `${prefix}/ADD_NEW_SHEET`,
  SET_SELECTED_VIEW: `${prefix}/SET_SELECTED_VIEW`,
  SET_IS_GET_PAGE_DATA: `${prefix}/SET_IS_GET_PAGE_DATA`,

  GET_TABLE_PRESETS: `${prefix}/GET_TABLE_PRESETS`,
  SET_TABLE_PRESETS: `${prefix}/SET_TABLE_PRESETS`,
  CREATE_TABLE_PRESET: `${prefix}/CREATE_TABLE_PRESET`,
  UPDATE_TABLE_PRESET: `${prefix}/UPDATE_TABLE_PRESET`,
  DELETE_TABLE_PRESET: `${prefix}/DELETE_TABLE_PRESET`,

  SET_SELECTED_ASSEMBLIES_IDS: `${prefix}/SET_SELECTED_ASSEMBLIES_IDS`,
  SET_SELECTED_ITEMS_IDS: `${prefix}/SET_SELECTED_ITEMS_IDS`,
  SET_CALCULATED_PIA: `${prefix}/SET_CALCULATED_PIA`,
  SET_INSTANCES_ASSIGN_PIA: `${prefix}/SET_INSTANCES_ASSIGN_PIA`,
  SET_IS_SKIP_CALC_PIA: `${prefix}/SET_IS_SKIP_CALC_PIA`,
  CALCULATE_PIA: `${prefix}/CALCULATE_PIA`,

  SET_OPEN_MEASURE_SELECT_TABLE: `${prefix}/SET_OPEN_MEASURE_SELECT_TABLE`,
  SET_CLOSE_MEASURE_SELECT_TABLE: `${prefix}/SET_CLOSE_MEASURE_SELECT_TABLE`,

  REMOVE_ASSIGN_PIA: `${prefix}/REMOVE_ASSIGN_PIA`,
  SET_ASSIGN_PIA: `${prefix}/SET_ASSIGN_PIA`,
  SET_IS_ASSIGN_PIA_LOADING: `${prefix}/SET_IS_ASSIGN_PIA_LOADING`,

  CREATE_MEASURE_VIEW: `${prefix}CREATE_MEASURE_VIEW`,
  CREATE_ITEMS_VIEW: `${prefix}/CREATE_ITEMS_VIEW`,
  CREATE_VIEW_REQUEST: `${prefix}/CREATE_MEASURE_VIEW_REQUEST`,
  UPDATE_VIEW_CONFIGURATION: `${prefix}/UPDATE_MEASURE_VIEW_CONFIGURATION`,
  LOAD_VIEW_CONFIGURATION: `${prefix}/LOAD_MEASURE_VIEW_CONFIGURATION`,
  SET_MEASURE_VIEW_CONFIGURATION: `${prefix}/SET_MEASURE_VIEW_CONFIGURATION`,
  TOGGLE_VIEW_TOOL_PANELS: `${prefix}/TOGGLE_VIEW_TOOL_PANELS`,
  SET_EMPTY_ITEM_VIEW_CONFIG: `${prefix}/SET_EMPTY_ITEM_VIEW_CONFIG`,

  SEND_ASSIGN_PATCH: `${prefix}/SEND_ASSIGN_PATCH`,
  FETCH_ASSIGN_PIA: `${prefix}/FETCH_ASSIGN_PIA`,

  SET_ASSIGN_PIA_READY: `${prefix}/SET_ASSIGN_PIA_READY`,
  SET_ASSIGN_PIA_LOAD: `${prefix}/SET_ASSIGN_PIA_LOAD`,

  SET_EXPORT_REPORT_READY: `${prefix}/SET_EXPORT_REPORT_READY`,
  SET_EXPORT_REPORT_LOAD: `${prefix}/SET_EXPORT_REPORT_LOAD`,

  SET_VIEW_MEASURE_ID: `${prefix}/SET_VIEW_MEASURE_ID`,

  SET_SELECTED_MEASURE_ID_VIEW: `${prefix}/SET_SELECTED_MEASURE_ID_VIEW`,

  SET_REPORT_PAGE_ETAG: `${prefix}/SET_REPORT_PAGE_ETAG`,
  SET_REPORT_SESSION_ID: `${prefix}/SET_REPORT_SESSION_ID`,

  AFTER_COPY_MEASURES: `${prefix}/AFTER_COPY_MEASURES`,
  TOGGLE_SHOW_CODE: `${prefix}/TOGGLE_SHOW_CODE`,
};
