import styled, { keyframes } from 'styled-components';


const DropdownAppearAnimation = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const Dropdown = styled.div<{ width: number, emergencePlace: string }>`
  width: ${p => p.width || 180}px;
  box-shadow: ${p => p.theme.shadow.m};
  border-radius: 15px;
  background-color: ${p => p.theme.color.backgroundRush};
  transform-origin: ${p => (p.emergencePlace ? p.emergencePlace : 'left top')};
  animation: ${DropdownAppearAnimation} ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  z-index: 10;
`;

export const Styled = {
  Dropdown,
};
