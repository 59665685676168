import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DrawingsInstanceMeasure, DrawingsLayoutApi } from 'common/components/drawings';
import {
  DrawingsAnnotationLegendActions,
} from 'common/components/drawings/actions/creators/drawings-annotation-legend';
import {
  AnnotationFilters,
  FilterChangePayload,
  ScheduleFilterValue,
} from 'common/components/drawings/interfaces/drawing-filters';
import { CellFocusData, ExcelTableApi, UpdateCellData, UpdateCellDataTransaction } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { ClipboardHelper, ExcelFormulaHelper, RangeHelper, ReferenceHelper } from 'common/components/excel-table/utils';
import { FormulaToolbarApi } from 'common/components/formula-toolbar';
import { QuickSearchInput } from 'common/components/quick-search';
import { QuoteExceptionDialog } from 'common/components/quote-exception-dialog';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { withUndoRedoApiProps } from 'common/undo-redo/with-undo-redo-api-props';
import { arrayUtils } from 'common/utils/array-utils';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { StringUtils } from 'common/utils/string-utils';
import { ValueHelper } from 'common/utils/value-helper';
import { PersistedStorageSelectors } from 'persisted-storage/selectors/projectTwoDFullScreenSelector';
import { CommentaryThread } from 'unit-2d-comments/interfaces';
import { PersistedStorageActions } from '../../../persisted-storage/actions/creators';
import { TableInnerClipboard, TwoDFullScreenMode } from '../../../persisted-storage/interfaces/state';
import { TwoDActions } from '../../actions/creators';
import {
  FormulaBarPayload,
  FormWithProjectAndReportIdsPayload,
  SheetUpdateCells,
  UpdateCellsPayload,
} from '../../actions/payloads';
import { Arrangement } from '../../constants';
import { TwoDViewTableType } from '../../constants/table-type';
import {
  AddNewSheetStatuses,
  FocusedCell,
  ReportPage,
  ReportPageType,
  ReportTableApi,
  TableSettings,
  TwoDSheetApi,
  UpdateCellForm,
} from '../../interfaces';
import { Qto2dReportUpdater } from '../../units';
import { TwoDFullCellId, TwoDRegex } from '../../units/2d-regex';
import { TwoDReportUndoRedoHelper } from '../../units/2d-report-undo-redo-helper';
import { CellDataStore, SheetDataStore } from '../../units/cell-data-controllers/report-cell-data-store';
import { getConfigId } from '../../units/excel-table-cell-formatter/common';
import { TwoDReport } from '../2d-report';
import {
  DELETE_EXCEL_TAB_CONFIRM_DIALOG,
  DeleteExcelTab,
  TwoDReportDeleteTabDialog,
} from '../2d-report-delete-tab-dialog';
import { TwoDReportFormatPanel } from '../2d-report-format-panel';
import { TwoDReportPanelMenu } from '../2d-report-panel-menu';
import { FocusChangeData } from '../2d-report/2d-sheet';
import { Styled } from '../styled';
import { TwoDReportToolBar } from '../tool-bar';
import { OpenInNewWindowButton } from './open-in-new-window-button';
import { ReportDataProgress } from './report-data-progress';
import { TableTabs } from './table-tabs/table-tabs';

const TABLE_UPDATE_DELAY = 1000;

interface StateProps {
  focusedCell: FocusedCell;
  drawingsMeasure: Record<string, DrawingsInstanceMeasure>;
  selectedSheetId: string;
  sheetToShow: string[];
  tableTypeView: Arrangement;
  isFormulaBarFocused: boolean;
  formulaBarValue: string;
  editFullCellId: string;
  isQuickSearchVisible: boolean;
  quickSearchValue: string;
  addNewSheetStatus: AddNewSheetStatuses;
  isCellRangeActive: boolean;
  twoDTableClipboard: TableInnerClipboard;
  isShowTwoDReport: boolean;
  isGroupLoaded: boolean;
  isFullScreenTableReport: boolean;
  comments: CommentaryThread[];
  drawingsInCellRange: Record<string, boolean>;
  reportSessionIdMap: Record<string, string>;
}

interface DispatchProps {
  createSpreadSheet: () => void;
  createMeasureView: () => void;
  createItemsView: () => void;
  saveCellsUpdates: (payload: UpdateCellsPayload<UpdateCellForm[]>) => void;
  updatePageInfo: (projectId: string, page: ReportPage) => void;
  deletePage: (pageId: string) => void;
  restorePage: (pageId: string, sessionId: string) => void;
  duplicatePage: (pageId: string) => void;
  moveToPage: (pageId: string, offset: number) => void;
  setSelectedSheetId: (id: string) => void;
  openDialog: (name: string, data?: any) => void;
  setShowSheetIds: (ids: string[]) => void;
  setFormulaBar: (formulaBar: FormulaBarPayload) => void;
  setEditCellId: (cellId: string) => void;
  setTableViewType: (type: Arrangement) => void;
  setFocusedCell: (data: CellFocusData, sheetId: string) => void;
  toggleQuickSearch: () => void;
  setQuickSearchValue: (value: string) => void;
  changeFilterData: (changeFilterData: FilterChangePayload) => void;
  addRows: (sheetId: string, count: number) => void;
  addColumns: (sheetId: string, count: number) => void;
  setFocusView: (tableId: string) => void;
  resetSelectedViewMeasureId: () => void;
  setDrawingInstanceInCellRange: (sheetId: string, ranges: Record<string, boolean>) => void;
  set2dFullScreenMode: (payload: { type: TwoDFullScreenMode, projectId: string }) => void;
}

interface OwnProps {
  projectId: string;
  reportPages: ReportPage[];
  drawingsLayoutApi: DrawingsLayoutApi;
  onApiReady: (ref: ReportTableApi) => void;
  onClickTabInFullScreenDrawingsMode: () => void;
  onFullscreenTableToggle: () => void;
  selectSheetData: (id: string) => SheetDataStore;
  selectCellValue: (sheetId: string, columnId: string, rowId: string) => string | number;
  selectCellData: (sheetId: string, columnId: string, rowId: string) => string;
  deleteCellData: (sheetId: string) => void;
  updateCellsValue: (payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean) => void;
  addNewRows: (sheetId: string, count: number) => void;
  setColumnWidth: (sheetId: string, payload: Array<{ columnId: string, width: number }>) => void;
  addNewColumns: (sheetId: string, count: number) => void;
  onSheetReady: (sheetId: string) => void;
  isReportDataLoaded: boolean;
  getAllSheetsDataStore: () => CellDataStore;
  updateCellDataOnFill: (sheetId: string, columnId: string, rowId: string, data: string | number) => void;
  isTableVisible: boolean;
  isFullScreenTable: boolean;
  isValidCell: (cellId: string) => boolean;
  openInNewWindow: (url: string) => void;
  disableChangeFullScreen?: boolean;
  positionTooltip?: string;
}

interface Props extends StateProps, DispatchProps, UndoRedoContextApiProps, OwnProps, AbilityAwareProps { }

interface OwnState {
  cellRanges: Ag.CellRange[];
  excelPageId: string;
  excelPageName: string;
  gridApiRefs: Record<string, TwoDSheetApi>;
  tableViewType: Arrangement;
  valueToInsertFormulaBar: string;
  isAddUndoRedo: boolean;
}

const FILLING_DELAY = 500;

class TwoDReportPanelComponent extends React.Component<Props, OwnState> {
  private reportUpdater: Qto2dReportUpdater;
  private tableBodyRef: React.RefObject<HTMLDivElement> = React.createRef();
  private referenceReader: ReferenceHelper = new ReferenceHelper();
  private formulaBarApi: FormulaToolbarApi = null;
  private updatedCellsOnFill: UpdateCellData[] = [];
  private fillingDeferredExecutor: DeferredExecutor = new DeferredExecutor(FILLING_DELAY);
  private canEditReport = this.props.ability.can(Operation.Update, Subject.Takeoff2DReport);
  private canViewReport = this.props.ability.can(Operation.Read, Subject.Takeoff2DReport);

  public constructor(props: Props) {
    super(props);
    this.state = {
      gridApiRefs: {},
      excelPageId: null,
      excelPageName: null,
      cellRanges: [],
      tableViewType: Arrangement.SingleView,
      valueToInsertFormulaBar: '',
      isAddUndoRedo: false,
    };
  }

  public componentDidMount(): void {
    this.createTableUpdater();
    if (this.props.onApiReady) {
      this.saveApi();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    this.updateTableTypeViewIfNeeded(prevProps);
    this.onInputQuickSearch();

    if (this.state.isAddUndoRedo && this.props.reportPages !== prevProps.reportPages) {
      const { undo, redo } = TwoDReportUndoRedoHelper.createPageUndoRedo(
        prevProps.reportPages,
        () => this.props.reportSessionIdMap,
        this.props.restorePage,
        this.deleteTable,
        () => this.props.reportPages,
      );
      this.props.addUndoRedo(undo, redo);
      this.setState({ isAddUndoRedo: false });
    }

    if (this.props.isReportDataLoaded && !this.props.reportPages.length) {
      const payload = {
        type: TwoDFullScreenMode.Drawings,
        projectId: this.props.projectId,
      };
      this.props.set2dFullScreenMode(payload);
    }
  }

  public render(): JSX.Element {
    const isReportDataLoaded = this.props.isReportDataLoaded && this.props.isGroupLoaded;

    return (
      <Styled.Wrapper
        isFullScreenDrawings={this.props.isTableVisible}
        isFullScreenTable={this.props.isFullScreenTable}
      >
        {isReportDataLoaded ? this.getContent() : this.getSpinner()}
      </Styled.Wrapper>
    );
  }

  public componentWillUnmount(): void {
    this.executeAllChanges();
  }

  private getSpinner(): React.ReactNode {
    const { isTableVisible } = this.props;
    return (
      <RenderIf condition={!isTableVisible}>
        <ReportDataProgress isInitialized={false} />
      </RenderIf>
    );
  }

  private executeAllChanges(): void {
    if (this.reportUpdater) {
      this.reportUpdater.executeImmediatelyAll();
    }
  }

  private getSelectedSheetId(): string {
    const firstReportPageId = this.props.reportPages.length ? this.props.reportPages[0].id : '';
    return this.props.selectedSheetId ? this.props.selectedSheetId : firstReportPageId;
  }

  @autobind
  private onInputQuickSearch(): void {
    const ref = this.getSelectedGridRef();
    if (ref) {
      ref.api.setQuickFilter(this.props.quickSearchValue);
    }
  }

  @autobind
  private setTableFocus(ref: ExcelTableApi): void {
    const { rowId, columnId } = this.props.focusedCell.cell;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const isRowExist = !!ref.api.getRowNode(rowId);
    const isColumnExist = !!ref.api.getColumnDef(columnId);
    if (isRowExist && isColumnExist) {
      ref.api.setFocusedCell(rowIndex, columnId);
      ref.api.ensureIndexVisible(rowIndex);
      ref.api.ensureColumnVisible(columnId);
    } else {
      ref.api.setFocusedCell(0, 'A');
    }
    ref.api.clearRangeSelection();
  }

  @autobind
  private updateTableTypeViewIfNeeded(prevProps: Props): void {
    const { reportPages, isShowTwoDReport, setFocusView } = this.props;
    if (reportPages.length) {
      const selectedSheetId = this.getSelectedSheetId();
      const currentReport = reportPages.find((report) => report.id === selectedSheetId);
      const isUpdateToShow = currentReport?.type === ReportPageType.MeasureView;
      if (isUpdateToShow && isShowTwoDReport) {
        setFocusView(currentReport.id);
      }
      if (reportPages.length < prevProps.reportPages.length) {
        this.changeReportPage(reportPages[0].id);
      }
    }
  }

  @autobind
  private getTableBodySize(): { height: number, width: number } {
    if (!this.tableBodyRef.current) {
      return { height: 0, width: 0 };
    }
    const { height, width } = this.tableBodyRef.current.getBoundingClientRect();
    return { height, width };
  }

  @autobind
  private getSelectedGridRef(): TwoDSheetApi {
    return this.state.gridApiRefs[this.props.selectedSheetId];
  }

  private getContent(): React.ReactNode {
    const {
      isReportDataLoaded,
      isFullScreenTable,
      reportPages,
      drawingsLayoutApi,
      isTableVisible,
      isShowTwoDReport,
      isQuickSearchVisible,
      isCellRangeActive,
      projectId,
      quickSearchValue,
      selectSheetData,
      toggleQuickSearch,
      setQuickSearchValue,
      changeFilterData,
      selectCellValue,
      selectCellData,
      addNewRows,
      setColumnWidth,
      addNewColumns,
      onSheetReady,
      getAllSheetsDataStore,
      isValidCell,
    } = this.props;

    return (
      <>
        <Styled.Container
          isFullScreenTable={isFullScreenTable}
          isQuickSearchVisible={isQuickSearchVisible}
        >
          <RenderIf condition={!isTableVisible}>
            <ReportDataProgress isInitialized={true} />
          </RenderIf>
          <Styled.TableHeader>
            {this.getTabs()}
            <RenderIf condition={!isTableVisible && isShowTwoDReport}>
              <TwoDReportToolBar
                isReportDataLoaded={isReportDataLoaded}
                drawingsLayoutApi={drawingsLayoutApi}
                reportPages={reportPages}
                isEditAnotherSheet={this.isEditAnotherSheet}
                getSelectedGridRef={this.getSelectedGridRef}
                referenceReader={this.referenceReader}
                saveFormulaBarApi={this.saveFormulaBarApi}
                updateRowData={this.updateRowData}
                selectSheetData={selectSheetData}
                onChangeTableWithUndoRedo={this.onChangeTableWithUndoRedo}
                getValueToUpdateFromFormulaBar={this.getValueToUpdateFromFormulaBar}
                updateCellsWithUndoRedo={this.updateCellsWithUndoRedo}
              />
              <TwoDReportFormatPanel
                cellRanges={this.state.cellRanges}
                updateCells={this.updateCurrentPageCells}
                insertFunction={this.insertFunction}
                gridApiRef={this.getSelectedGridRef()}
                toggleQuickSearch={toggleQuickSearch}
                setQuickSearchValue={setQuickSearchValue}
                changeFilterData={changeFilterData}
                isQuickSearchVisible={isQuickSearchVisible}
                isCellRangeActive={isCellRangeActive}
                reportEditPermission={this.canEditReport}
                reportViewPermission={this.canViewReport}
                isFullScreenTable={isFullScreenTable}
              />
            </RenderIf>
          </Styled.TableHeader>
          <Styled.TableBody
            ref={this.tableBodyRef}
            reportViewPermission={this.canViewReport}
            isFullScreenTable={isFullScreenTable}
            isShowTwoDReport={isShowTwoDReport}
            isQuickSearchVisible={isQuickSearchVisible}
          >
            {this.canViewReport && (
              <TwoDReport
                drawingsLayoutApi={drawingsLayoutApi}
                saveApi={this.saveApiRef}
                getTableBodySize={this.getTableBodySize}
                referenceReader={this.referenceReader}
                onCellRangeChanged={this.onCellRangeChanged}
                projectId={projectId}
                setFormulaBarValue={this.setFormulaBarValue}
                updateCells={this.updateCellsWithUndoRedo}
                onFocusChange={this.onFocusedCellChange}
                handlePaste={this.handlePaste}
                formulaBarApi={this.formulaBarApi}
                reportEditPermission={this.canEditReport}
                selectCellValue={selectCellValue}
                selectCellData={selectCellData}
                selectSheetData={selectSheetData}
                addNewRows={addNewRows}
                setColumnWidth={setColumnWidth}
                addNewColumns={addNewColumns}
                onSheetReady={onSheetReady}
                getSheetDataStore={getAllSheetsDataStore}
                updateCellDataOnFill={this.updateCellDataOnFill}
                isValidCell={isValidCell}
              />
            )}
          </Styled.TableBody>
          <RenderIf condition={isShowTwoDReport}>
            <QuickSearchInput
              placeholder={'Search on the sheet'}
              value={quickSearchValue}
              isQuickSearchVisible={isQuickSearchVisible}
              setQuickSearchValue={setQuickSearchValue}
              toggleQuickSearch={toggleQuickSearch}
            />
          </RenderIf>
        </Styled.Container>
        <QuoteExceptionDialog />
      </>
    );
  }

  @autobind
  private getTabs(): React.ReactNode {
    const selectedSheetId = this.getSelectedSheetId();

    return (
      <Styled.Tabs
        isFullScreenTable={this.props.isFullScreenTable}
      >
        <Styled.TableHeaderMenu>
          <TwoDReportPanelMenu
            projectId={this.props.projectId}
            isFullScreenTable={this.props.isFullScreenTable}
            onFullscreenTableToggle={this.props.onFullscreenTableToggle}
            reportEditPermission={this.canEditReport}
            reportViewPermission={this.canViewReport}
          />
        </Styled.TableHeaderMenu>
        <TableTabs
          activeTabId={selectedSheetId}
          tabList={this.canViewReport ? this.props.reportPages : []}
          onClickTab={this.onClickTableTab}
          onChangeTabInfo={this.onChangeTabInfoWithUndoRedo}
          onMoveTo={this.onMoveToTab}
          onCreateSpreadSheet={this.onCreateSpreadSheet}
          onCreateItemView={this.onCreateItemView}
          onCreateMeasureView={this.onCreateMeasureView}
          onDeleteTab={this.openDeleteExcelConfirm}
          onDuplicateTab={this.onDuplicateTable}
          addNewSheetStatus={this.props.addNewSheetStatus}
          isFullScreenDrawings={this.props.isTableVisible}
          canEditReport={this.canEditReport}
          isFullScreenTableReport={this.props.isFullScreenTableReport}
          comments={this.props.comments}
        />
        <OpenInNewWindowButton
          openInNewWindow={this.props.openInNewWindow}
          positionTooltip={this.props.positionTooltip}
        />
        <TwoDReportDeleteTabDialog onSubmit={this.onDeleteTable} />
      </Styled.Tabs>
    );
  }

  private createTableUpdater(): void {
    const callBacks = {
      onCellsUpdate: (sheetForm: FormWithProjectAndReportIdsPayload<UpdateCellForm[]>) => {
        this.props.saveCellsUpdates({
          projectId: sheetForm.projectId,
          sheets: [
            {
              sheetId: sheetForm.sheetId,
              form: sheetForm.form,
            },
          ],
        });
      },
    };
    this.executeAllChanges();

    this.reportUpdater = new Qto2dReportUpdater(TABLE_UPDATE_DELAY, callBacks);
  }

  @autobind
  private updateCellsWithUndoRedo(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void {
    const { undo, redo } = TwoDReportUndoRedoHelper.getUpdateCellsUndoRedo(
      payload,
      this.updateCellAndSetFormulaBarValue,
    );
    this.props.addUndoRedo(undo, redo);
    this.updateCells(payload, skipRefresh);
  }

  @autobind
  private updateCellAndSetFormulaBarValue(payload: Array<SheetUpdateCells<UpdateCellData[]>>): void {
    this.updateCells(payload);
    const cells = payload[0].form;
    const cell = this.props.focusedCell.cell;
    if (cells.length === 1 && cell.cellId === `${cells[0].columnId}${cells[0].rowId}`) {
      this.props.setFormulaBar({ value: cells[0].value });
    }
  }

  @autobind
  private updateCells(payload: Array<SheetUpdateCells<UpdateCellData[]>>, skipRefresh?: boolean): void {
    const { projectId, updateCellsValue } = this.props;

    updateCellsValue(payload, skipRefresh);

    payload.forEach((sheetForm) => {
      this.reportUpdater.onCellUpdate({
        projectId,
        sheetId: sheetForm.sheetId,
        form: sheetForm.form.map((x) => ({
          rowId: x.rowId,
          columnId: x.columnId,
          value: x.value === undefined ? '' : x.value,
        })),
      });
    });
  }

  @autobind
  private openDeleteExcelConfirm(excelPageId: string, excelPageName: string): void {
    const data: DeleteExcelTab = { pageName: excelPageName, pageId: excelPageId };
    this.props.openDialog(DELETE_EXCEL_TAB_CONFIRM_DIALOG, data);
  }

  @autobind
  private saveFormulaBarApi(api: FormulaToolbarApi): void {
    this.formulaBarApi = api;
  }

  @autobind
  private insertFunction(value: string): void {
    this.formulaBarApi.insertFunctionValue(value);
  }

  @autobind
  private setFormulaBarValue(value: string): void {
    if (!this.isEditAnotherSheet()) {
      this.props.setFormulaBar({ value });
    }
  }

  @autobind
  private onCellRangeChanged(ranges: Ag.CellRange[]): void {
    this.setState({ cellRanges: ranges });
  }

  @autobind
  private async handlePaste(cvs?: string): Promise<void> {
    const {
      focusedCell: {
        cell: { columnId, rowId },
      },
      selectedSheetId,
      selectSheetData,
    } = this.props;
    const text = await this.getCvs(cvs);
    const rows = this.getRowsToPaste(text);
    const insertRowCount = rows.length;
    const insertColumnsCount = rows.reduce((result, row) => (row.length > result ? row.length : result), 1);
    const form = [];
    const sheetData = selectSheetData(selectedSheetId);
    const currentColumnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
    const maxColumnCount = sheetData.columns.length;
    if (this.isNeedExtendsColumnsTable(currentColumnIndex, maxColumnCount, insertColumnsCount)) {
      this.extendsColumnsTable(selectedSheetId, currentColumnIndex, maxColumnCount, insertColumnsCount);
    }
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const maxRowCound = sheetData.rows.length;
    if (this.isNeedExtendsRowsTable(rowIndex, maxRowCound, insertRowCount)) {
      this.extendsRowsTable(selectedSheetId, rowIndex, maxRowCound, insertRowCount);
    }
    rows.forEach((row, index) => {
      const newRowId = Number(rowId) + index;
      row.forEach((columnValue, columnIndex) => {
        const newColumnId = this.getNewColumnId(columnId, columnIndex);
        const rowData = this.getSelectedGridRef().api.getRowNode(newRowId.toString()).data;
        const value = ClipboardHelper.formatFromClipboard(columnValue, `${newColumnId}${newRowId}`, selectedSheetId);
        form.push({
          columnId: newColumnId,
          rowId: newRowId,
          value: value.value,
          prevValue: rowData[newColumnId],
        });
        if (value.settings) {
          const configColumnId = getConfigId(columnId);
          form.push({
            columnId: configColumnId,
            rowId: newRowId,
            value: value.settings,
            prevValue: rowData[configColumnId],
          });
        }
      });
    });
    this.updateCurrentPageCells(form);
    this.getSelectedGridRef().api.refreshCells();
  }

  private isNeedExtendsColumnsTable(columnIndex: number, maxColumnIndex: number, inserColumnCount: number): boolean {
    return columnIndex + inserColumnCount > maxColumnIndex;
  }

  private extendsColumnsTable(
    selectedSheetId: string,
    columnIndex: number,
    maxColumnIndex: number,
    inserColumnCount: number,
  ): void {
    const addColumnCount = columnIndex + inserColumnCount - maxColumnIndex + 1;
    this.props.addColumns(selectedSheetId, addColumnCount);
    this.props.addNewColumns(selectedSheetId, addColumnCount);
  }

  private isNeedExtendsRowsTable(rowIndex: number, maxRowIndex: number, inserRowCount: number): boolean {
    return rowIndex + inserRowCount > maxRowIndex;
  }

  private extendsRowsTable(
    selectedSheetId: string,
    rowIndex: number,
    maxRowIndex: number,
    inserRowCount: number,
  ): void {
    const addRowCount = rowIndex + inserRowCount - maxRowIndex + 1;
    this.props.addRows(selectedSheetId, addRowCount);
    this.props.addNewRows(selectedSheetId, addRowCount);
  }

  private async getCvs(cvs?: string): Promise<string> {
    if (cvs) {
      return cvs;
    } else {
      return (await navigator.clipboard.readText()).replace(/\r/g, '');
    }
  }

  private getRowsToPaste(clipboardValue: string): string[][] {
    if (ClipboardHelper.isExpectedClipboardValue(clipboardValue, this.props.twoDTableClipboard.expectedClipboard)) {
      return this.props.twoDTableClipboard.rows;
    } else {
      return RangeHelper.getCellsValueFromCSV(clipboardValue);
    }
  }

  @autobind
  private getNewColumnId(oldId: string, index: number): string {
    const oldIndex = ExcelColumnHelper.excelColNameToIndex(oldId);
    return ExcelColumnHelper.indexToExcelColName(oldIndex + index);
  }

  @autobind
  private getApi(): ReportTableApi {
    return {
      updateRowData: this.insertValueToCell,
      refreshCell: this.refreshCell,
      setFocusedCell: this.setFocusedCell,
      insertDrawingInstanceToCell: this.insertDrawingInstance,
      insertDrawingInstanceToColumn: this.insertDrawingInstanceToColumn,
      insertDrawingInstanceToTable: this.insertDrawingInstanceToTable,
      updateRowDataTransaction: this.updateRowDataTransaction,
      updateColumns: this.updateColumns,
      setRowData: this.setRowData,
      getDynamicCellsList: this.getDynamicCellsList,
      changeTableTab: this.onClickTableTab,
      getApi: this.getApi,
    };
  }

  @autobind
  private saveApi(): void {
    this.props.onApiReady({
      updateRowData: this.insertValueToCell,
      refreshCell: this.refreshCell,
      setFocusedCell: this.setFocusedCell,
      insertDrawingInstanceToCell: this.insertDrawingInstance,
      insertDrawingInstanceToColumn: this.insertDrawingInstanceToColumn,
      insertDrawingInstanceToTable: this.insertDrawingInstanceToTable,
      updateRowDataTransaction: this.updateRowDataTransaction,
      updateColumns: this.updateColumns,
      setRowData: this.setRowData,
      getDynamicCellsList: this.getDynamicCellsList,
      changeTableTab: this.onClickTableTab,
      getApi: this.getApi,
    });
  }

  @autobind
  private insertDrawingInstance(value: string, config: TableSettings): void {
    if (!this.referenceReader.updateTarget(value)) {
      const cell = this.props.focusedCell.cell;
      this.insertValueToCell(cell.rowId, cell.columnId, [value], config);
    }
  }

  @autobind
  private insertDrawingInstanceToColumn(instanceValues: string[]): void {
    const {
      focusedCell: { cell },
      selectedSheetId,
      selectSheetData,
    } = this.props;
    const maxRowId = Number(cell.rowId) + instanceValues.length - 1;
    const data = instanceValues.map((value, index) => {
      const rowId = Number(cell.rowId) + index;
      return this.getDataToUpdate(rowId.toString(), cell.columnId, value);
    });
    const currentRowCount = selectSheetData(selectedSheetId).rows.length;
    if (maxRowId > currentRowCount) {
      this.props.addRows(selectedSheetId, maxRowId - currentRowCount + 1);
    }

    this.updateCurrentPageCells(data);
  }

  private getCellToUpdate(
    cell: CellFocusData,
    rowOffset: number,
    columnOffset: number,
  ): { rowId: number, columnId: string } {
    const rowId = Number(cell.rowId) + rowOffset;
    const columnId = ExcelColumnHelper.shiftColumnName(cell.columnId, columnOffset);
    return { rowId, columnId };
  }

  @autobind
  private insertDrawingInstanceToTable(instanceValues: string[][], isShift: boolean, showColumnHeaders: boolean): void {
    const {
      focusedCell: { cell },
      selectSheetData,
      selectedSheetId,
    } = this.props;
    const data = [];
    let maxRowId = 1;
    let maxColumnId = 1;

    instanceValues.forEach((element, elementIndex) => {
      const isColumnHeader = !elementIndex && showColumnHeaders;
      element.forEach((option, optionIndex) => {
        if (option !== '') {
          const { rowOffset, columnOffset } = isShift
            ? { rowOffset: optionIndex, columnOffset: elementIndex }
            : { rowOffset: elementIndex, columnOffset: optionIndex };
          const { rowId, columnId } = this.getCellToUpdate(cell, rowOffset, columnOffset);
          if (rowId > maxRowId) {
            maxRowId = rowId;
          }
          const columnIndex = ExcelColumnHelper.excelColNameToIndex(columnId);
          if (columnIndex > maxColumnId) {
            maxColumnId = columnIndex;
          }
          data.push(this.getDataToUpdate(rowId.toString(), columnId, option, isColumnHeader));
        }
      });
    });

    const currentPageData = selectSheetData(selectedSheetId);
    const currentRowCount = currentPageData.rows.length;
    const currentColumnCount = currentPageData.columns.length;

    if (maxRowId > currentRowCount) {
      this.props.addRows(selectedSheetId, maxRowId - currentRowCount + 1);
    }
    if (maxColumnId > currentColumnCount) {
      this.props.addColumns(selectedSheetId, maxColumnId - currentColumnCount + 1);
    }

    this.updateCurrentPageCells(data);
  }

  @autobind
  private getDynamicCellsList(
    instanceValues: string[][],
    cell: TwoDFullCellId,
    showColumnHeaders: boolean,
  ): Record<string, string> {
    const result = {};

    instanceValues.forEach((element, elementIndex) => {
      const isHeader = !elementIndex && showColumnHeaders;
      element.forEach((option, optionIndex) => {
        if (option !== '') {
          const { rowId, columnId } = this.getCellToUpdate(cell, elementIndex + 1, optionIndex);
          result[ExcelFormulaHelper.getCellLink(cell.sheetId, columnId, rowId)] = isHeader ? option : `=${option}`;
        }
      });
    });
    return result;
  }

  @autobind
  private updateCurrentPageCells(form: UpdateCellData[]): void {
    const { selectedSheetId } = this.props;

    const payload = { sheetId: selectedSheetId, form };

    this.updateCellsWithUndoRedo([payload]);
  }

  @autobind
  private onFocusedCellChange(data: FocusChangeData): void {
    if (
      data.newData.sheetId === data.prevData.sheetId &&
      data.newData.cellData.cellId === data.prevData.cellData.cellId
    ) {
      return;
    }
    const { undo, redo } = TwoDReportUndoRedoHelper.getFocusCellUndoRedo(data, this.updateExcelFocusedCell);
    this.props.addUndoRedo(undo, redo);
  }

  @autobind
  private setFocusedCell(sheetId: string, cellId: string): void {
    if (this.props.selectedSheetId !== sheetId) {
      this.onChangeTableWithUndoRedo(sheetId);
    }
    this.updateExcelFocusedCell(sheetId, cellId);
  }

  @autobind
  private updateExcelFocusedCell(sheetId: string, cellId: string): void {
    const { rowId, columnId } = TwoDRegex.fullCellId.exec(cellId).groups;
    const data: CellFocusData = { cellId, rowId, columnId };
    this.props.setFocusedCell(data, sheetId);
    const api = this.getSelectedGridRef()?.api;
    if (api) {
      const rowToBeSelected = api.getRowNode(rowId);
      const rowIndex = rowToBeSelected.rowIndex;
      api.ensureIndexVisible(rowIndex);
      api.ensureColumnVisible(columnId);
      api.setFocusedCell(rowIndex, columnId);
      const cellRange: Ag.CellRangeParams = {
        rowStartIndex: rowIndex,
        rowEndIndex: rowIndex,
        columnEnd: columnId,
        columnStart: columnId,
      };
      api.clearRangeSelection();
      api.addCellRange(cellRange);
    }
  }

  @autobind
  private refreshCell(force: boolean): void {
    for (const ref of Object.values(this.state.gridApiRefs)) {
      if (ref.api) {
        ref.api.refreshCells({ force });
      }
    }
  }

  @autobind
  private getDataToUpdate(rowId: string, columnId: string, value: string, keepOriginalValue?: boolean): UpdateCellData {
    const { selectedSheetId, selectSheetData } = this.props;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const oldDataRow = selectSheetData(selectedSheetId)[rowIndex];
    const oldData = oldDataRow ? oldDataRow[columnId] : '';
    const oldValue = oldData === undefined || oldData === null ? '' : oldData.toString();
    const insertValue = keepOriginalValue ? value : ExcelFormulaHelper.getValueToInsert(oldValue, value);
    const row = selectSheetData(selectedSheetId)[rowIndex];
    const prevValue = row ? row[columnId] : '';
    const newValue = this.getValueToUpdateFromFormulaBar(insertValue, selectedSheetId);
    return {
      columnId,
      rowId,
      value: newValue,
      prevValue,
    };
  }

  @autobind
  private insertValueToCell(
    rowId: string,
    columnId: string,
    valuesToInsert: Iterable<string>,
    config?: TableSettings,
  ): void {
    const { reportPages, selectedSheetId, drawingsInCellRange } = this.props;
    if (reportPages.find((r) => r.id === selectedSheetId).type === ReportPageType.MeasureView) {
      return;
    }
    let valueToInsert = this.props.formulaBarValue;
    const cellInSelectedRange = this.cellInSelectedRange(rowId, columnId);

    const newDrawingsInCellRange = {};

    for (const value of valuesToInsert) {
      valueToInsert = ExcelFormulaHelper.getValueToInsert(valueToInsert, value);
      if (cellInSelectedRange && TwoDRegex.drawingInstance.test(value)) {
        const drawingId = value.match(TwoDRegex.drawingInstance).groups.drawingInstanceId;
        newDrawingsInCellRange[drawingId] = true;
      }
    }

    this.updateRowData(rowId, columnId, valueToInsert, config);
    if (Object.keys(newDrawingsInCellRange)) {
      this.props.setDrawingInstanceInCellRange(
        this.props.selectedSheetId,
        { ...drawingsInCellRange, ...newDrawingsInCellRange },
      );
    }

  }

  private cellInSelectedRange(rowId: string, columnId: string): boolean {
    const { focusedCell } = this.props;
    if (focusedCell && focusedCell.cell.rowId === rowId && focusedCell.cell.columnId === columnId) {
      return true;
    }
    return this.state.cellRanges.some((range) => {
      const fromIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const toIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
      return rowIndex <= toIndex
        && rowIndex >= fromIndex
        && range.columns.some((column) => column.getColId() === columnId);
    });
  }

  @autobind
  private isEditAnotherSheet(): boolean {
    return !!this.props.editFullCellId;
  }

  @autobind
  private updateRowData(rowId: string, columnId: string, value: string, config?: TableSettings): void {
    const { selectedSheetId, selectSheetData, setFormulaBar, focusedCell } = this.props;
    const rowIndex = ExcelTableRowIdentification.getRowIndexFromId(rowId);
    const row = selectSheetData(selectedSheetId).rows[rowIndex];
    const prevData = row[columnId];
    const newData = this.getValueToUpdateFromFormulaBar(value, selectedSheetId);

    if (prevData !== newData) {
      const form: UpdateCellData[] = [
        {
          columnId,
          rowId,
          value: newData,
          prevValue: prevData,
        },
      ];
      if (config) {
        const configColumnId = getConfigId(columnId);
        form.push({
          columnId: configColumnId,
          rowId,
          value: config,
          prevValue: row[configColumnId],
        });
      }
      this.updateCurrentPageCells(form);

      const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);

      if (focusedCell.cell && focusedCell.cell.cellId === cellId) {
        setFormulaBar({ value });
      }
    }
  }

  @autobind
  private getValueToUpdateFromFormulaBar(value: string, sheetId: string): string | number {
    const formattedValue = ExcelFormulaHelper.isFormula(value)
      ? ExcelFormulaHelper.extendCellIdBySheetId(value, sheetId)
      : value;
    const newValue = ValueHelper.isNumberValue(formattedValue) ? Number(value) : formattedValue;
    return newValue;
  }

  @autobind
  private onClickTableTab(id: string): void {
    const currentReport = this.props.reportPages.find((report) => report.id === id);
    if (!currentReport) {
      return;
    }
    const {
      isTableVisible: isFullScreenDrawings,
      selectedSheetId,
      focusedCell: { cell },
      onClickTabInFullScreenDrawingsMode,
      disableChangeFullScreen,
    } = this.props;
    if (!disableChangeFullScreen && isFullScreenDrawings) {
      onClickTabInFullScreenDrawingsMode();
    }
    this.onChangeTableWithUndoRedo(id);

    if (currentReport.type && currentReport.type === ReportPageType.MeasureView) {
      this.props.setFocusView(id);
    } else {
      this.props.setFocusedCell(cell, id);
    }

    const editCellId = ExcelFormulaHelper.getCellWithSheetId(selectedSheetId, cell.cellId);
    const ref = this.getSelectedGridRef();
    const editCell = ref && ref.api.getEditingCells();
    if (editCell && editCell[0]) {
      this.formulaBarApi.setFocus();
      this.props.setEditCellId(editCellId);
    }

    if (this.props.isFormulaBarFocused && !this.isEditAnotherSheet()) {
      this.props.setEditCellId(editCellId);
    }

    this.props.resetSelectedViewMeasureId();
  }

  @autobind
  private onChangeTableWithUndoRedo(id: string): void {
    const prevId = this.props.selectedSheetId;
    const { undo, redo } = TwoDReportUndoRedoHelper.getSelectedSheetIdUndoRedo(id, prevId, this.changeReportPage);
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private changeReportPage(id: string): void {
    const { selectedSheetId, sheetToShow } = this.props;
    const selectedSheetIndex = sheetToShow.indexOf(selectedSheetId);
    const newSheetToShow = [...sheetToShow];
    const indexOfId = sheetToShow.indexOf(id);
    if (indexOfId !== -1) {
      newSheetToShow.splice(indexOfId, 1, selectedSheetId);
    }
    newSheetToShow.splice(selectedSheetIndex, 1, id);
    this.props.setShowSheetIds([...newSheetToShow]);
    this.props.setSelectedSheetId(id);
  }

  @autobind
  private onDuplicateTable(pageId: string): void {
    this.reportUpdater.executeImmediatelyAll();
    this.props.duplicatePage(pageId);
    this.setState({ isAddUndoRedo: true });
  }

  @autobind
  private onMoveToTab(pageId: string, offset: number): void {
    if (this.canEditReport) {
      const { undo, redo } = TwoDReportUndoRedoHelper.movePageUndoRedo(pageId, offset, this.props.moveToPage);
      this.props.addUndoRedo(undo, redo);
      this.props.moveToPage(pageId, offset);
    }
  }

  @autobind
  private onChangeTabInfo(pageInfo: ReportPage): void {
    const { projectId } = this.props;
    const payload: ReportPage = {
      id: pageInfo.id,
      name: pageInfo.name,
      color: pageInfo.color,
      type: pageInfo.type,
    };
    this.props.updatePageInfo(projectId, payload);
  }

  @autobind
  private onChangeTabInfoWithUndoRedo(pageInfo: ReportPage): void {
    const prevPageInfo = this.props.reportPages.find((page) => page.id === pageInfo.id);

    if (prevPageInfo.name !== pageInfo.name) {
      pageInfo.name = StringUtils.iterateName(
        arrayUtils.filterMap(this.props.reportPages, p => p.id !== pageInfo.id, p => p.name),
        pageInfo.name,
      );
    }

    if (prevPageInfo) {
      const { undo, redo } = TwoDReportUndoRedoHelper.getChangePageInfoUndoRedo(
        prevPageInfo,
        pageInfo,
        this.onChangeTabInfo,
      );
      this.props.addUndoRedo(undo, redo);
    }
    this.onChangeTabInfo(pageInfo);
  }

  @autobind
  private onCreateSpreadSheet(): void {
    this.props.createSpreadSheet();
    this.setState({ isAddUndoRedo: true });
  }

  @autobind
  private onCreateMeasureView(): void {
    this.props.createMeasureView();
    this.setState({ isAddUndoRedo: true });
  }

  @autobind
  private onCreateItemView(): void {
    this.props.createItemsView();
    this.setState({ isAddUndoRedo: true });
  }

  @autobind
  private onDeleteTable(data: DeleteExcelTab): void {
    const { undo, redo } = TwoDReportUndoRedoHelper.deletePageUndoRedo(
      data.pageId,
      () => this.props.reportSessionIdMap,
      this.deleteTable,
      this.props.restorePage,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private deleteTable(pageId: string): void {
    this.props.deletePage(pageId);
    this.props.deleteCellData(pageId);
    this.props.setTableViewType(Arrangement.SingleView);
  }

  @autobind
  private saveApiRef(
    ref: ExcelTableApi,
    sheetId: string,
    updateColumns?: (payload: Array<Record<string, string>>) => void,
  ): void {
    this.setState(
      (s) => {
        const newGridApiRefs = { ...s.gridApiRefs };
        if (ref) {
          newGridApiRefs[sheetId] = { ...ref, updateColumns };
        } else {
          delete newGridApiRefs[sheetId];
        }
        return { ...s, gridApiRefs: newGridApiRefs };
      },
      () => {
        if (ref && sheetId === this.props.selectedSheetId) {
          this.setTableFocus(ref);
        }
      },
    );
  }

  @autobind
  private updateRowDataTransaction(sheetId: string, payload: UpdateCellDataTransaction): void {
    const ref = this.state.gridApiRefs[sheetId];
    if (ref && payload) {
      ref.updateRowData(payload);
    }
  }

  @autobind
  private updateColumns(sheetId: string, payload: Array<Record<string, string>>): void {
    const ref = this.state.gridApiRefs[sheetId];
    ref.updateColumns(payload);
  }

  @autobind
  private setRowData(sheetId: string, rows: Array<Record<string, string>>): void {
    const ref = this.state.gridApiRefs[sheetId];
    if (ref) {
      ref.api.setRowData(rows);
    }
  }

  @autobind
  private updateCellDataOnFill(sheetId: string, updateCellPayload: UpdateCellForm[]): void {
    updateCellPayload.forEach((updatedCell) => {
      this.updatedCellsOnFill.push(updatedCell as UpdateCellData);
      const { columnId, rowId, value } = updatedCell;
      this.props.updateCellDataOnFill(sheetId, columnId, rowId, value);
    });
    this.fillingDeferredExecutor.execute(() => {
      const payload: Array<SheetUpdateCells<UpdateCellData[]>> = [{ sheetId, form: this.updatedCellsOnFill }];
      this.updateCellsWithUndoRedo(payload);
      this.updatedCellsOnFill = [];
    });
  }
}

function mapStateToProps(state: State): StateProps {
  const innerClipboard = state.persistedStorage.innerClipboard;
  const projectId = state.projects.currentProject.id.toString();
  return {
    focusedCell: state.twoD.focusedCell,
    drawingsMeasure: state.drawings.elementMeasurement,
    selectedSheetId: state.twoD.selectedSheetId,
    sheetToShow: state.twoD.showSheetIds,
    tableTypeView: state.twoD.tableViewType,
    formulaBarValue: state.twoD.formulaBar.value,
    isFormulaBarFocused: state.twoD.formulaBar.isFocused,
    editFullCellId: state.twoD.editFullCellId,
    isQuickSearchVisible: state.persistedStorage.isQuickSearchVisible,
    quickSearchValue: state.twoD.quickSearchValue,
    addNewSheetStatus: state.twoD.addNewSheetStatus,
    isCellRangeActive: state.drawings.filterData[AnnotationFilters.Report] === ScheduleFilterValue.CellRange,
    twoDTableClipboard: innerClipboard ? innerClipboard.twoDTable : { expectedClipboard: '', rows: [] },
    isShowTwoDReport: state.twoD.isShowTwoDReport,
    isGroupLoaded: state.drawings.groupsLoaded,
    isFullScreenTableReport: PersistedStorageSelectors
      .selectTwoDFullScreenMode(state.persistedStorage, projectId) === TwoDFullScreenMode.Report,
    comments: state.twoDComments.comments,
    drawingsInCellRange: state.twoD.drawingInstanceInCellRange,
    reportSessionIdMap: state.twoD.reportPageSessionIdMap,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps {
  return {
    setFocusedCell: (data, sheetId) => dispatch(TwoDActions.setFocusCell({ data, sheetId })),
    deletePage: (pageId) => dispatch(TwoDActions.deletePage(pageId)),
    restorePage: (pageId, sessionId) => dispatch(TwoDActions.restorePage(pageId, sessionId)),
    duplicatePage: (pageId) => dispatch(TwoDActions.duplicatePage(pageId)),
    moveToPage: (pageId, offset) => dispatch(TwoDActions.moveToPage(pageId, offset)),
    createSpreadSheet: () => dispatch(TwoDActions.createPage()),
    saveCellsUpdates: (payload) => dispatch(TwoDActions.saveCellsUpdates(payload)),
    setSelectedSheetId: (id) => dispatch(TwoDActions.setSelectedSheetId(id)),
    openDialog: (name, data) => dispatch(KreoDialogActions.openDialog(name, data)),
    setShowSheetIds: (ids) => dispatch(TwoDActions.setShowSheetsIds(ids)),
    updatePageInfo: (projectId, page) => dispatch(TwoDActions.updatePageInfo(projectId, page)),
    setFormulaBar: (formulaBar) => dispatch(TwoDActions.setFormulaBar(formulaBar)),
    setEditCellId: (cellId) => dispatch(TwoDActions.setEditFullCellId(cellId)),
    setTableViewType: (tableViewType) => dispatch(TwoDActions.setTableViewType(tableViewType)),
    addRows: (sheetId, count) => dispatch(TwoDActions.addRows(ownProps.projectId, sheetId, count)),
    addColumns: (sheetId, count) => dispatch(TwoDActions.addColumns(ownProps.projectId, sheetId, count)),
    toggleQuickSearch: () => dispatch(PersistedStorageActions.toggleQuickSearch()),
    setQuickSearchValue: (value) => dispatch(TwoDActions.setQuickSearchValue(value)),
    changeFilterData: (changeFilterData) =>
      dispatch(DrawingsAnnotationLegendActions.changeFilterData(changeFilterData)),
    createMeasureView: () => dispatch(TwoDActions.createView(TwoDViewTableType.Measurements)),
    createItemsView: () => dispatch(TwoDActions.createView(TwoDViewTableType.Items)),
    setFocusView: (tableId) => dispatch(TwoDActions.setSelectedView(tableId)),
    resetSelectedViewMeasureId: () => dispatch(TwoDActions.setSelectedMeasureIdView([])),
    setDrawingInstanceInCellRange:
      (sheetId, ranges) => dispatch(TwoDActions.setDrawingInstanceInCellRange(sheetId, ranges)),
    set2dFullScreenMode: (payload) => dispatch(PersistedStorageActions.set2dFullScreenMode(payload)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export const TwoDReportPanel = connector(withAbilityContext(withUndoRedoApiProps(TwoDReportPanelComponent)));
