import { Icons, MenuItem } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { SmallMenuList } from 'common/components/small-menu-list';
import { RoleData, UserData, UserMenuInfo } from '../../interfaces';
import { Styled } from '../../styled';
import { UserRolesMenuItem } from './user-roles-menu-item';

interface Props {
  user: UserData;
  userMenu: UserMenuInfo;
  userRoles: RoleData[];
  closeMenu: () => void;
  changeUserRole: (email: string, role: RoleData) => void;
  deleteUser: (email: string) => void;
}

export const UserMenu: React.FC<Props> = ({ userMenu, changeUserRole, deleteUser, user, closeMenu, userRoles }) => {
  const changeUserRoleHandler = React.useCallback((role: RoleData) => {
    changeUserRole(user.email, role);
  }, [changeUserRole, user.email]);
  const deleteUserHandler = React.useCallback(() => deleteUser(user.email), [deleteUser, user.email]);

  return (
    <Styled.UserMenuListContainer top={userMenu?.top}>
      <SmallMenuList onExit={closeMenu} emergencePlace={'right top'}>
        {changeUserRole &&
          <Styled.RolesMenuItems>
            {userRoles.map(role =>
              (<UserRolesMenuItem
                key={role.id}
                role={role}
                onSelectRole={changeUserRoleHandler}
                isActive={role.name === user.accountRole}
              />),
            )}
          </Styled.RolesMenuItems>}
        {deleteUser &&
          <Styled.DeleteUserMenuItem>
            <MenuItem text={'Delete'} Icon={Icons.Delete} onClick={deleteUserHandler} />
          </Styled.DeleteUserMenuItem>
        }
      </SmallMenuList>
    </Styled.UserMenuListContainer>
  );
};
