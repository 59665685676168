import autobind from 'autobind-decorator';
import { DrawingsGeometryInstance, ShortPointDescription } from 'common/components/drawings/interfaces';
import { DrawingAnnotationUtils } from 'common/components/drawings/utils/drawing-annotation-utils';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGeometryUtils } from 'common/components/drawings/utils/drawings-geometry-utils';
import { EngineObject, EngineObjectConfig } from '../../common';
import { BoundingRect } from '../../drawings-geometry-entities';
import {
  InstanceGeometryManager,
  PointPositionsEditor,
  RotationProcessor,
  StrictAngleController,
} from '../../interfaces';
import { DragEventWrapper } from '../drag-instances';
import { DrawingsRenderedPointsCache } from '../drawings-rendered-points-cache';
import { StrictAngleRotationProcessor } from './strict-angle-rotation-processor';

interface Config extends EngineObjectConfig {
  dragProcessingHelper: DragEventWrapper;
  pointsPositionEditor: PointPositionsEditor;
  pointsManager: DrawingsRenderedPointsCache;
  instanceManager: InstanceGeometryManager;
  strictController: StrictAngleController;
  getSelectedIds: () => string[];
  getInstance: (id: string) => DrawingsGeometryInstance;
  getSelectionRect: () => BoundingRect;
  getCurrentPageInfo: () => Core.Document.PageInfo;
  getPointCoordinates: (pointId: string) => ShortPointDescription;
}

interface RotateResult {
  isValid: boolean;
  updatedPoints: Record<string, ShortPointDescription>;
  instancePoints: Record<string, string[]>;
}


interface RotatePayload {
  ids: string[];
  diffAngle: number;
  center: ShortPointDescription;
  getPoint: (pointId: string) => ShortPointDescription;
}

export class InstancesRotationHelper extends EngineObject<Config> {
  private _rotationProcessor: RotationProcessor;

  constructor(config: Config) {
    super(config);

    this._rotationProcessor = new StrictAngleRotationProcessor({
      dragProcessingHelper: this._config.dragProcessingHelper,
      changeRotation: (...args) => this.onChangeRotation(...args),
      getCenterPoint: () => this._config.getSelectionRect().position,
      strictController: this._config.strictController,
    });
  }

  @autobind
  public onChangeRotation(diffAngle: number, finish: boolean): boolean {
    const rect = this._config.getSelectionRect();
    const centerPoint = [rect.position.x, rect.position.y] as ShortPointDescription;
    const {
      updatedPoints,
      instancePoints,
      isValid,
    } = this.rotate({
      ids: this._config.getSelectedIds(),
      diffAngle,
      center: centerPoint,
      getPoint: (pointId: string) => this._config.pointsManager.getConvertedPoint(pointId),
    });
    this._config.pointsManager.addPoints(updatedPoints);
    for (const [instanceIds, points] of Object.entries(instancePoints)) {
      this._config.instanceManager.updatePointsPositionInInstance(points, instanceIds);
    }
    if (finish) {
      if (!isValid) {
        return false;
      }
      this._config.pointsPositionEditor.finishEditPoints(Object.keys(updatedPoints), null, true);
    }
    rect.rotate(diffAngle, { showAngle: true });
    return isValid;
  }

  public rotateByIds(ids: string[], diffAngle: number): boolean {
    const center = this.getRectCenter(ids);
    const { isValid, instancePoints, updatedPoints } = this.rotate({
      ids,
      diffAngle,
      center,
      getPoint: (pointId: string) => this._config.getPointCoordinates(pointId),
    });
    if (isValid) {
      this._config.pointsManager.addPoints(updatedPoints);
      for (const [instanceIds, points] of Object.entries(instancePoints)) {
        if (this._config.instanceManager.isInstanceRendered(instanceIds)) {
          this._config.instanceManager.updatePointsPositionInInstance(points, instanceIds);
        }
      }
      this._config.pointsPositionEditor.finishEditPoints(Object.keys(updatedPoints));
    }
    return isValid;
  }

  public get rotationProcessor(): RotationProcessor {
    return this._rotationProcessor;
  }

  private rotate({ ids, diffAngle, center, getPoint }: RotatePayload): RotateResult {
    const updatedPoints: Record<string, ShortPointDescription> = {};
    const { width, height } = this._config.getCurrentPageInfo();
    const instancePoints: Record<string, string[]> = { };
    let isValid = true;
    for (const instanceId of ids) {
      const instance = this._config.getInstance(instanceId);
      if (!instance) {
        continue;
      }
      const { type, geometry } = instance;
      if (DrawingsGeometryUtils.isPolyGeometry(type, geometry) || DrawingsGeometryUtils.isCount(type, geometry)) {
        const geometryPoints = [];
        for (const pointId of DrawingAnnotationUtils.geometryPointsIterator(geometry, type)) {
          geometryPoints.push(pointId);
          const point = getPoint(pointId);
          const rotatedPoints = DrawingsCanvasUtils.rotatePoint(point, -diffAngle, center);
          const [x, y] = rotatedPoints;
          if (width < x || x < 0 || height < y || y < 0) {
            isValid = false;
          }
          updatedPoints[pointId] = rotatedPoints;
        }
        instancePoints[instanceId] = geometryPoints;
      }
    }
    return {
      isValid,
      updatedPoints,
      instancePoints,
    };
  }

  private getRectCenter(ids: string[]): ShortPointDescription {
    const [xs, ys] = ids.reduce(([x, y], id) => {
      const instance = this._config.getInstance(id);
      if (!instance) {
        return [x, y];
      }
      for (const pointId of DrawingAnnotationUtils.geometryPointsIterator(instance.geometry, instance.type)) {
        const point = this._config.getPointCoordinates(pointId);
        x.push(point[0]);
        y.push(point[1]);
      }
      return [x, y];
    }, [[], []] as [number[], number[]]);
    const centerX = xs.reduce((sum, x) => sum + x, 0) / xs.length;
    const centerY = ys.reduce((sum, y) => sum + y, 0) / ys.length;
    return [centerX, centerY];
  }
}
