import styled, { css } from 'styled-components';

import { ToggleButtonStyled } from '../../../toggle-button';
import { CountStyled } from '../count';
import { DrawingsAnnotationLegendUserInfoStyled } from '../drawings-annotation-legend-user-info';
import { EntityLabelStyled } from '../entity-label';

const MARGIN_LEFT_ITEM = 10;

const Text = styled.div`
  width: 100%;
  height: 100%;
  color: ${p => p.theme.color.mainFont};
  display: flex;
  height: 10px;
  align-items: center;
  line-height: 10px;
`;

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  min-width: 0;
`;

const SeparatorResizeContainer = styled.div`
  height: 100%;
  flex: 1 100 auto;
  min-width: 0;
  max-width: 100%;
`;

const hideMeasureInfoStyle = css`
  padding-left: 10px;
  padding-right: 0px;
`;

const InfoItem = styled.div<{ hideIconsInfo: boolean, hideInfo: boolean }>`
  position: relative;
  display: ${p => p.hideIconsInfo ? 'none' : 'flex'};
  padding-right: 10px;
  padding-left: 15px;
  color: ${p => p.theme.color.gray};
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
  white-space: nowrap;
  z-index: 2;

  ${p => p.hideInfo ? hideMeasureInfoStyle : ''}

  svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: currentColor;
    width: 10px;
  }
`;

const CreateSameButton = styled.div`
  display: flex;
  z-index: 2;
  margin-right: 5px;
  > div {
    padding-right: 0;
  }
`;

interface ItemsContainerProps {
  hideInfo: boolean;
  hideVisibleToggle: boolean;
  hideEntityLabel: boolean;
  hideAvatar: boolean;
  hideCreateLikeThis: boolean;
  hideCount: boolean;
}

const ItemsContainer = styled.div<ItemsContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;

  ${InfoItem} {
    height: 10px;
    padding-left: ${p => p.hideInfo ? 10 : 15}px;
    padding-right: 10px;
    align-items: center;
  }

  ${Text} {
    display: ${p => p.hideInfo ? 'none' : 'flex'};
  }

  ${ToggleButtonStyled.VisibleToggle} {
    display: ${p => p.hideVisibleToggle ? 'none' : 'flex'};
    padding-left: 0;
  }

  ${EntityLabelStyled.Container} {
    display: ${p => p.hideEntityLabel ? 'none' : 'inline-flex'};
  }

  ${DrawingsAnnotationLegendUserInfoStyled.AvatarContainer} {
    display: ${p => p.hideAvatar ? 'none' : 'inline-flex'};
  }

  ${CreateSameButton} {
    display: ${p => p.hideCreateLikeThis ? 'none' : 'flex'};
  }

  ${CountStyled.Count} {
    ${p => p.hideCount ? 'display: none;' : ''}
  }
`;

const Icon = styled.div`
  width: 100%;
  height: 100%;
`;

const Inner = styled.div<{ hideTitle: boolean }>`
  display: ${p => p.hideTitle ? 'none' : 'flex'};
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  flex: 1 2 auto;
`;

const IconContainer = styled.div<{ hideIconContainer: boolean, markColor: string }>`
  width: 20px;
  height: 20px;
  min-width: 17px;
  padding-left: 2px;
  padding-right: 7px;
  display: ${p => p.hideIconContainer ? 'none' : 'flex'};

  ${Icon} {
    svg {
      width: 20px;
      height: 20px;
      color: ${p => p.markColor};
    }
  }

  ${InfoItem} {
    padding-left: 0;
    display: flex;
  }

  :nth-child(2) {
    display: none;
  }

  ${Text} {
    display: none;
  }
`;

const CopyButton = styled.div`
  display: none;
`;

const Container = styled.div<{ isSelected: boolean, nestingCount: number }>`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  flex-shrink: 0;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: ${p => p.nestingCount * 20 + MARGIN_LEFT_ITEM}px;
  color: ${p => p.theme.color.mainFont};
  background-color: ${p => p.isSelected ? p.theme.color.pale : p.theme.color.backgroundRush};
  box-shadow: 0 0 0 1px ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  min-width: initial;
  max-width: -webkit-fill-available;
  z-index: 0;

  &:hover {
    background-color: ${p => p.isSelected ? p.theme.color.pale : p.theme.color.background};
    box-shadow: 0 0 0 1px ${p => p.isSelected ? p.theme.color.turquoise : p.theme.color.background};
    z-index: 1;
  }

  &:active {
    transform: translateY(2px);
  }
`;

export const Styled = {
  Container,
  Wrapper,
  SeparatorResizeContainer,
  IconContainer,
  Inner,
  ItemsContainer,
  InfoItem,
  Icon,
  Text,
  CopyButton,
  CreateSameButton,
};
