import { CommentaryTargetType } from '..';
import {
  CommentaryDrawingTarget,
  CommentaryDrawingWithPointTarget,
  CommentaryDrawingWithRectTarget,
  CommentaryItemsPageTarget,
  CommentarySpreadsheetReportPageTarget,
  CommentaryTakeOffPageTarget,
  CommentaryTarget,
  CommetaryReportPageTarget,
} from '../interfaces';

function isDrawingWithPoint(target: CommentaryTarget): target is CommentaryDrawingWithPointTarget {
  return target.type === CommentaryTargetType.DrawingWithPoint;
}

function isDrawingWithRect(target: CommentaryTarget): target is CommentaryDrawingWithRectTarget {
  return target.type === CommentaryTargetType.DrawingWithRect;
}

function isDrawingShowable(
  target: CommentaryTarget,
): target is CommentaryDrawingWithPointTarget | CommentaryDrawingWithRectTarget {
  return isDrawingWithPoint(target) || isDrawingWithRect(target);
}


function isDrawingSimple(target: CommentaryTarget): target is CommentaryDrawingTarget {
  return target.type === CommentaryTargetType.Drawing;
}

function isDrawing(target: CommentaryTarget): target is CommentaryDrawingTarget {
  return isDrawingWithPoint(target) || isDrawingSimple(target) || isDrawingWithRect(target);
}

function isSpreadsheetPage(target: CommentaryTarget): target is CommentarySpreadsheetReportPageTarget {
  return target.type === CommentaryTargetType.SpreadsheetReportPage;
}

function isTakeoffPage(target: CommentaryTarget): target is CommentaryTakeOffPageTarget {
  return target.type === CommentaryTargetType.TakeoffReportPage;
}

function isItemsPage(target: CommentaryTarget): target is CommentaryItemsPageTarget {
  return target.type === CommentaryTargetType.ItemsReportPage;
}

function isReportPage(target: CommentaryTarget): target is CommetaryReportPageTarget {
  return isSpreadsheetPage(target) || isTakeoffPage(target) || isItemsPage(target);
}

export const CommentaryTargetTypeGuards = {
  isDrawingWithPoint,
  isDrawingSimple,
  isDrawingWithRect,
  isDrawingShowable,
  isDrawing,
  isSpreadsheetPage,
  isTakeoffPage,
  isItemsPage,
  isReportPage,
};
