import React, { useCallback } from 'react';
import { useWizzardSetting } from 'common/components/drawings/hooks';
import { getLineQualityByName, LineQuality, LineQualityName } from 'common/components/drawings/interfaces';
import { InputSelectNoIndex } from 'common/components/inputs';
import { PropertyWithInfo } from '../../property-with-info';
import { WizzardMainContainer } from '../wizzard-main-container';

// eslint-disable-next-line max-len
const TOOLTIP_TEXT = `Adjust this setting to change the size of the polylines. The default is automatic, but you can select 'Long Lines' for longer polylines or 'Short Lines' for shorter ones. This ensures the best quality for your polylines`;

const OPTIONS = [
  LineQualityName[LineQuality.Light],
  LineQualityName[LineQuality.MediumLight],
  LineQualityName[LineQuality.MediumHigh],
].map(p => p.toString());


const OneClickLineSettingsComponent: React.FC = () => {
  const [ quality, setQuality ] = useWizzardSetting('lineQuality');
  const onQualityChange = useCallback((value: string) => {
    setQuality(getLineQualityByName(value));
  }, [setQuality]);
  return (
    <WizzardMainContainer>
      <PropertyWithInfo name="Mode" tooltipText={TOOLTIP_TEXT}>
        <InputSelectNoIndex
          elements={OPTIONS}
          onClick={onQualityChange}
          createNewElement={false}
          activeElement={LineQualityName[quality]}
          isShowArrow={true}
        />
      </PropertyWithInfo>
    </WizzardMainContainer>
  );
};

export const OneClickLineSettings = React.memo(OneClickLineSettingsComponent);
