import * as Sentry from '@sentry/browser';
import { OpenReplaySessionTracker } from 'utils/openreplay/openreplay-session-tracker';
import { UserInfoResponse } from '../../units/account/interfaces/user-info-response';
import { ignoreErrors } from './ignore-errors';


const initSentry = (dsn: string, release: string, environment: string): void => {
  Sentry.init({
    dsn,
    release,
    environment,
    attachStacktrace: true,
    integrations: [Sentry.extraErrorDataIntegration({ depth: 6 })],
    ignoreErrors,
    beforeSend: (event) => {
      event.extra = event.extra || {};
      event.extra.openreplay = OpenReplaySessionTracker.getInstance()?.getSessionURL();
      return event;
    },
  });
};

const captureException = (error: any, errorInfo: Record<string, any>): void => {
  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    const instance = OpenReplaySessionTracker.getInstance();
    if (instance) {
      scope.setExtra('errormoment', instance.getSessionURL({ withCurrentTime: true }));
    }
    Sentry.captureException(error);
  });
};

const getError = (args: any[]): any => {
  let error;
  for (const arg of args) {
    if (arg instanceof Error) {
      error = arg;
      break;
    }
  }

  return error
    ? error
    : args[0];
};

const getOverrideConsoleErrorFunc = (oldConsoleLog: (...args: any[]) => void) => {
  return (...args) => {
    const error = getError(args);
    oldConsoleLog(...args);
    const extraInfo = { ...args };
    captureException(error, extraInfo);
  };
};

const overrideConsoleError = (): void => {
  const oldConsoleError = console.error;
  console.error = getOverrideConsoleErrorFunc(oldConsoleError);
};

export const ExceptionHandlingUtils = {
  initSentryAndOverrideConsoleError: (dsn: string, release: string, environment: string): void => {
    initSentry(dsn, release, environment);
    overrideConsoleError();
  },
  captureException: (error: Error, errorInfo: React.ErrorInfo): void => {
    captureException(error, errorInfo);
  },
  addUserContext: (user: UserInfoResponse | null): void => {
    Sentry.setUser(user);
  },
};
