import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  ContourType,
  DrawingsPolygonGeometry,
  DrawingsRenderParams,
  DrawingsShortInfo,
  FixContour,
  MagicSearchState,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from 'common/components/drawings/interfaces/drawings-canvas-context-props';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { Vector2Utils } from 'common/components/drawings/utils/math-utils/vector2-utils';
import { RequestStatus } from 'common/enums/request-status';
import { UuidUtil } from 'common/utils/uuid-utils';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { DrawingsGeometryUtilityPolygon } from '../../drawings-geometry-entities';
import { FinishedDrawingElement } from '../../drawings-geometry-entities/temporary';
import { WizzardGeometryPreview } from '../../drawings-geometry-entities/temporary/preview';
import { CreateElementCallback, NewDrawingSettings } from '../../interfaces';
import { GeometryType } from '../wizzard/ai/geometry-types';
import { AISearchBucketAI } from './ai-search-bucker-ai';


export interface AiSearchHelperConfig extends EngineObjectConfig {
  magicSearchDataObserver: ContextObserver<MagicSearchState>;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  colorCache: DrawingsColorCacheHelper;
  layer: paper.Layer | paper.Group;
  setPreviewFixApi: (previewApi: FixContour) => void;
  applyFix: (sourceContours: number[], polygons: ShortPointDescription[][]) => void;
  updateFixStatus: (status: RequestStatus) => void;
  onTogglePreviewStatus: (indices: number[]) => void;
  removeNegativePolygon: (index: number) => void;
  onCreateElement: CreateElementCallback;
  getDrawingInfo: () => DrawingsShortInfo;
  textRenderParams: ContextObserver<MeasuresViewSettings>;
}

export interface Style extends DrawingsPaperColorInfo{
  strokeWidth: number;
  strokeStyle: number[];
}

export class AiSearchHelperBase<T extends AiSearchHelperConfig = AiSearchHelperConfig> extends DestroyableObject<T> {
  protected _previews: Map<number, WizzardGeometryPreview>;
  protected _group: paper.Group;
  protected _fixGroup: paper.Group;

  private _bucketFill: AISearchBucketAI;
  private _selectionArea: DrawingsGeometryUtilityPolygon;

  constructor(config: T) {
    super(config);
    this._group = new paper.Group();
    this._fixGroup = new paper.Group();
    this._group.addTo(this._config.layer);
    this._fixGroup.addTo(this._config.layer);
    this._previews = new Map();
    this._config.magicSearchDataObserver.subscribe(this.updatePreviews);
    this._config.renderParametersContextObserver.subscribe(this.updateStyles);
    this._config.newDrawingStylesObserver.subscribe(this.updateStyles);
  }

  public destroy(): void {
    this._config.setPreviewFixApi(null);
    this._group.remove();
    this._config.magicSearchDataObserver.unsubscribe(this.updatePreviews);
    this._config.renderParametersContextObserver.unsubscribe(this.updateStyles);
    this._config.newDrawingStylesObserver.unsubscribe(this.updateStyles);
    this._selectionArea?.destroy();
    this._fixGroup.remove();
    if (this._bucketFill) {
      this._bucketFill.destroy();
    }
  }

  public convert(): FinishedDrawingElement[] {
    const {
      previews,
      previewsToDelete,
      fixedContours,
      hiddenPreviews,
    } = this._config.magicSearchDataObserver.getContext();
    const extraProperties = this.getExtracProperties();
    const result = [];
    const addToResult = (preview: ShortPointDescription[], holes: ShortPointDescription[][]): void => {
      const points: Record<string, ShortPointDescription> = {};
      const parseContours = (contour: ShortPointDescription[]): string[] => {
        const contourPoints = [];
        if (Vector2Utils.equal(contour[0], contour[contour.length - 1])) {
          contour.pop();
        }
        for (const point of contour) {
          const id = UuidUtil.generateUuid();
          points[id] = point;
          contourPoints.push(id);
        }
        return contourPoints;
      };

      const geometry: DrawingsPolygonGeometry = {
        ...extraProperties as DrawingsPolygonGeometry,
        points: parseContours(preview),
      };
      if (holes.length > 0) {
        geometry.children = holes.map((h) => parseContours(h));
      }
      result.push({
        type: DrawingsInstanceType.Polygon,
        name: this._config.newDrawingStylesObserver.getContext().name,
        geometry,
        points,
      });
    };

    previews.forEach((preview, index) => {
      if (previewsToDelete.includes(index) || hiddenPreviews.includes(index)) {
        return;
      }
      addToResult(preview.points, preview.holes);
    });
    fixedContours.forEach(({ points: contour, holes }) => {
      addToResult(contour, holes);
    });

    return result;
  }

  public apply(analytics: Record<string, string | number>): void {
    const geometriesToCreate = this.convert();
    this._config.onCreateElement(geometriesToCreate, { isDrawn: true, analyticsParams: analytics });
  }

  protected getExtracProperties(): Partial<DrawingsPolygonGeometry> {
    const {
      polygonHeight,
      offset,
      color,
      strokeStyle,
      strokeWidth,
    } = this._config.newDrawingStylesObserver.getContext();
    const result: Partial<DrawingsPolygonGeometry> = {
      color,
      strokeStyle,
      strokeWidth,
    };
    if (polygonHeight !== undefined && polygonHeight !== null) {
      result.height = polygonHeight;
    }
    if (offset !== undefined && offset !== null) {
      result.offset = offset;
    }
    return result;
  }

  protected getStyles(): Style {
    const newDrawingsContext = this._config.newDrawingStylesObserver.getContext();
    const zoom = this._config.renderParametersContextObserver.getContext().zoom;
    const strokeWidth = newDrawingsContext.strokeWidth / zoom;
    return {
      strokeWidth,
      strokeStyle: DrawingsCanvasUtils.scaleStroke(newDrawingsContext, zoom),
      ...this._config.colorCache.getPaperColor(newDrawingsContext.color),
    };
  }

  @autobind
  protected updatePreviews(magicSearch: MagicSearchState): void {
    this.clearPreviews();
    this.onSelectionAreaUpdated(magicSearch.searchArea);


    const style = this.getStyles();
    if (magicSearch.fixContour) {
      const { state, contour } = magicSearch.fixContour;
      if (
        state === RequestStatus.NotRequested
        || state === RequestStatus.Loading
        || state === RequestStatus.Failed
      ) {
        this.renderPreview(
          -1,
          [contour.contour, ...contour.holes],
          style,
          null,
        );
      }
      return;
    } else if (this._bucketFill) {
      this._bucketFill.destroy();
      this._bucketFill = null;
    }

    const previewsToDeleteSet = new Set(magicSearch.previewsToDelete);
    const hiddenPreviews = new Set(magicSearch.hiddenPreviews);
    magicSearch.previews.forEach((contour, index) => {
      if (hiddenPreviews.has(index)) {
        return;
      }
      this.renderPreview(
        index,
        [contour.points, ...contour.holes],
        previewsToDeleteSet.has(index) ? { ...style, ...DrawingsCanvasColors.warningColorInfo } : style,
        ContourType.Source,
      );
    });
    magicSearch.fixedContours.forEach(({ points: contour, holes }, index) => {
      this.renderPreview(magicSearch.previews.length + index, [contour, ...holes], style, ContourType.Fixed);
    });

    magicSearch.negativeContours.forEach((contour, index) => {
      this.renderPreview(
        magicSearch.previews.length + magicSearch.fixedContours.length + index,
        [contour],
        { ...style, ...DrawingsCanvasColors.warningColorInfo },
        ContourType.Negative,
      );
    });
  }

  @autobind
  protected updateStyles(): void {
    const styles = this.getStyles();
    const magicSearch = this._config.magicSearchDataObserver.getContext();
    const previewsToDeleteSet = new Set(magicSearch.previewsToDelete);
    this._previews.forEach((preview) => {
      const previewIndex = Number(preview.id);
      preview.strokeWidth = styles.strokeWidth;
      preview.dashArray = styles.strokeStyle;
      preview.color = previewsToDeleteSet.has(previewIndex) || preview.negative
        ? DrawingsCanvasColors.warningColorInfo
        : styles;
    });
  }

  @autobind
  private onPreviewClick(id: number, contourType: ContourType): void {
    const {
      status,
      previewsToDelete,
      previews,
      resultToSources,
      fixedContours,
    } = this._config.magicSearchDataObserver.getContext();
    if (contourType === ContourType.Negative) {
      const contourIndex = id - previews.length - fixedContours.length;
      this._config.removeNegativePolygon(contourIndex);
      return;
    }
    if (id === undefined) {
      return;
    }
    if (status === WizzardStatus.Preview) {
      if (previewsToDelete.includes(id) && contourType === ContourType.Source) {
        this._config.onTogglePreviewStatus(resultToSources[id]);
      } else {
        const contourIndex = contourType === ContourType.Fixed ? id - previews.length : id;
        const fixedContour = fixedContours[contourIndex];
        const preview = contourType === ContourType.Fixed ? fixedContour : previews[contourIndex];
        this._config.setPreviewFixApi({
          state: RequestStatus.NotRequested,
          contourIndex,
          contourType,
          api: {
            setAsDeleted: this.setAsDeleted,
            apply: this.applyFix,
            tryFix: this.tryFix,
          },
          contour: {
            contour: preview.points,
            holes: preview.holes,
          },
          sources: contourType === ContourType.Fixed
            ? fixedContour.sourceContours
            : resultToSources[contourIndex],
        });
      }
    }
  }

  private onSelectionAreaUpdated(area: ShortPointDescription[]): void {
    if (!area) {
      this._selectionArea?.destroy();
      this._selectionArea = null;
    } else {
      if (this._selectionArea) {
        this._selectionArea.destroy();
      }
      this._selectionArea = new DrawingsGeometryUtilityPolygon({
        layer: this._group,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        geometry: area,
        color: DrawingsCanvasColors.autocompleteColorInfo,
      });
    }
  }

  private clearPreviews(): void {
    this._group.removeChildren();
    this._previews.clear();
  }

  @autobind
  private applyFix(): void {
    const sources = this._config.magicSearchDataObserver.getContext().fixContour.sources;
    this._config.applyFix(sources, this._bucketFill.getGeometry());
  }

  @autobind
  private setAsDeleted(): void {
    this._config.setPreviewFixApi(null);
    const { fixContour } = this._config.magicSearchDataObserver.getContext();
    this._config.onTogglePreviewStatus(fixContour.sources);
  }

  @autobind
  private async tryFix(): Promise<void> {
    this._config.updateFixStatus(RequestStatus.Loading);
    if (!this._bucketFill) {
      const bucketFill = new AISearchBucketAI({
        magicSearchDataObserver: this._config.magicSearchDataObserver,
        getDrawingInfo: this._config.getDrawingInfo,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        colorCache: this._config.colorCache,
        layer: this._fixGroup,
        type: GeometryType.Polygon,
        textRenderParams: this._config.textRenderParams,
      });
      this._bucketFill = bucketFill;
    }
    const { fixContour: { contour } } = this._config.magicSearchDataObserver.getContext();
    await this._bucketFill.findCorrectPolygon([contour.contour, ...contour.holes]);
    this._config.updateFixStatus(RequestStatus.Loaded);
  }

  private renderPreview(
    index: number,
    contour: ShortPointDescription[][],
    style: Style,
    contourType: ContourType): void {
    const preview = new WizzardGeometryPreview({
      id: index.toString(),
      geometry: contour,
      color: style,
      layer: this._group,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      onClick: index < 0 ? undefined : (_e, id) => this.onPreviewClick(id, contourType),
      thickness: null,
      showThickness: false,
      fill: true,
      negative: contourType === ContourType.Negative,
    });
    preview.strokeWidth = style.strokeWidth;
    this._previews.set(index, preview);
  }
}
