import * as React from 'react';
import { useSelector } from 'react-redux';

import { Mood } from 'common/enums/mood';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { RemovableListState } from './removable-list-state';


const RemovedResultsComponent: React.FC = () => {
  const removedPreviewsCount = useSelector<State, number>(s => s.drawings.magicSearch.previewsToDelete.length);
  const reset = useActionDispatch(MagicSearchActions.resetDeletePreviews);

  return (
    <RemovableListState
      removedText={`${removedPreviewsCount} removed areas`}
      tooltipText={'You can delete the area measurement if the AI is incorrect by clicking on it (it will turn red)'}
      reset={reset}
      mood={removedPreviewsCount > 0 ? Mood.Secondary : Mood.Disabled}
    />
  );
};

export const RemovedResults = React.memo(RemovedResultsComponent);
