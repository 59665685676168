import { diff } from 'deep-object-diff';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AssignedPia } from '2d/interfaces';
import { DrawingsGeometryGroup } from 'common/components/drawings';
import { State } from 'common/interfaces/state';
import { objectUtils } from 'common/utils/object-utils';
import { Assembly, Item, Property, PropertyTypeEnum } from 'unit-2d-database/interfaces';
import { getAssignByGroup } from '../measure-panel/hooks/use-assign';


function convertPropertiesListToDict(properties: Property[]): Record<string, Property> {
  return properties.reduce((acc, property) => {
    if (
      property.value
        && (property.value.type === PropertyTypeEnum.SingleSelect
          || property.value.type === PropertyTypeEnum.SingleSelectLink)) {
      acc[property.name] = objectUtils.deepCopyObjectWithNoUndefineds(property);
    } else {
      acc[property.name] = property;
    }
    return acc;
  }, {});
}

function convertItemListToDict(items: Item[]): Record<string, Record<string, Property>> {
  return items.reduce((acc, item) => {
    acc[item.name] = convertPropertiesListToDict(item.properties);
    return acc;
  }, {});
}

function convertAssemlbiesListToDict(assemblies: Assembly[]): Record<string, Record<string, Record<string, Property>>> {
  return assemblies.reduce((acc, assembly) => {
    acc[assembly.name] = convertItemListToDict(assembly.items);
    return acc;
  }, {});
}

function convertAssingToDict(assign: AssignedPia): {
  items: Record<string, Record<string, Property>>,
  assemblies: Record<string, Record<string, Record<string, Property>>>,
} {
  return {
    items: convertItemListToDict(assign.items),
    assemblies: convertAssemlbiesListToDict(assign.assemblies),
  };
}

export const useDiffAssign = (parentId: string, currentId: string, inheritId: string): any => {
  const assign = useSelector<State, Record<string, AssignedPia>>(s => s.twoD.assignPia);
  const groups = useSelector<State, DrawingsGeometryGroup[]>(s => s.drawings.drawingGeometryGroups);

  return useMemo(() => {
    const [parentAssemblies, parentItems] = getAssignByGroup(parentId, groups, assign);
    const parentAssign = {
      items: convertItemListToDict(parentItems),
      assemblies: convertAssemlbiesListToDict(parentAssemblies),
    };
    const currentAssign = assign[currentId] ? convertAssingToDict(assign[currentId]) : { items: {}, assemblies: {} };

    const diffAssign: any = diff(parentAssign, currentAssign);
    const hoverEntities = [];
    if (!diffAssign) {
      return [];
    }
    if (diffAssign.items && currentAssign.items) {
      for (const [key, value] of Object.entries(diffAssign.items)) {
        const item = currentAssign.items[key];
        if (!item) {
          continue;
        }
        const parentItem = parentAssign.items[key];
        if (!parentItem) {
          continue;
        }
        const itemName = key;
        if (!value) {
          continue;
        }
        for (const pk of Object.keys(value)) {
          const property = item[pk];
          const propertyName = property?.name;

          if (parentItem[pk]) {
            hoverEntities.push({ itemName, propertyName, assemblyName: '', inheritId });
          }
        }
      }
    }

    if (diffAssign.assemblies && currentAssign.assemblies) {
      for (const [ak, av] of Object.entries(diffAssign.assemblies)) {
        if (!av) {
          continue;
        }
        const assembly = currentAssign.assemblies[ak];
        if (!assembly) {
          continue;
        }
        const parentAssembly = parentAssign?.assemblies[ak];
        if (!parentAssembly) {
          continue;
        }
        const assemblyName = ak;
        for (const [key, value] of Object.entries(av)) {
          const item = assembly[key];
          const itemName = key;
          const parentItem = parentAssembly[key];
          if (!value || !parentItem) {
            continue;
          }
          for (const pk of Object.keys((value))) {
            const property = item[pk];
            const propertyName = property?.name;
            if (parentItem[pk]) {
              hoverEntities.push({ assemblyName, itemName, propertyName, inheritId });
            }
          }
        }
      }
    }
    return hoverEntities;
  }, [parentId, currentId, assign, inheritId, groups]);
};
