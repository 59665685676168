import { AnyAction } from 'redux';

import { RequestStatus } from 'common/enums/request-status';
import { Account } from '../common/interfaces/account';
import { AccountActionTypes } from '../units/account/actions/types';
import { accountReducerMethods } from '../units/account/reducer-methods';
import { Currency } from '../units/subscription/constants';
import { subscriptionReducerMethods } from '../units/subscription/reducers/reducer';

export const SUBSCRIPTION_EDIT_FORM_STATE = {
  teamSize: 1,
  selectedPlanGroup: null,
  paidPlanGroup: null,
  billingPeriod: null,
  paymentCardId: null,
  selectedAddonGroups: null,
  paidAddonGroups: [],
  coupons: [],
  billingCountry: null,
  vatNumber: null,
};

export const ACCOUNT_INITIAL_STATE: Account = {
  selectedSubscriptionType: null,
  status: { hasError: false, message: '' },
  registrationRequestStatus: RequestStatus.Loaded,
  restrictAvatarFile: null,
  resetPasswordStatus: RequestStatus.Loaded,
  companies: null,
  companyLogoTemporaryKey: '',
  selectedCompany: null,
  subscribeMotivationDialogOpenTime: null,
  subscriptionRoles: null,
  updateRolesPermissionStatus: RequestStatus.NotRequested,
  updateRolesStatus: RequestStatus.NotRequested,
  statuses: {
    confirmationStatus: RequestStatus.Loaded,
    sendConfirmAgainStatus: RequestStatus.Loaded,
    fetchCompanies: RequestStatus.Loaded,
    createCompany: RequestStatus.Loaded,
  },
  settings: {
    isImperial: false,
  },
  showQuestionnaire: false,
  companyInfoSidePanelIsOpen: false,

  confirmResponseCode: null,
  signInResponseCode: null,
  signInSavedEmail: null,
  confirmResend: false,
  isInvitation: false,

  initialAppDataStatus: RequestStatus.NotRequested,
  subscriptionPlans: null,
  isTwoBillingPeriod: false,
  hasMonthBillingPeriod: false,
  hasYearBillingPeriod: false,
  ownCompanyBillingInfo: null,
  requests: {
    subscriptionPlans: RequestStatus.NotRequested,
    ownCompanyBillingInfo: RequestStatus.NotRequested,
  },
  motivationPopupSettings: null,
  billingCountries: [],
  payment: null,
  paymentStatus: RequestStatus.NotRequested,
  billingEstimation: null,
  billingEstimationError: null,
  subscriptionValidation: {
    vatNumberValidation: '',
    renewValidation: '',
  },
  currentCountry: null,
  currencyRates: {
    [Currency.GBP.shortcat]: 1,
  },
  showLoadingCreateSubscription: false,
  billingCollection: null,
  billingHistoryStatus: RequestStatus.Loaded,
  isHandleSubscriptionUpdate: false,
  subscriptionEditorState: SUBSCRIPTION_EDIT_FORM_STATE,
};

export default function account(
  state: Account = ACCOUNT_INITIAL_STATE,
  action: AnyAction = { type: '', payload: null },
): Account {
  if (action.type in accountReducerMethods) {
    return accountReducerMethods[action.type](state, action.payload);
  } else if (action.type in subscriptionReducerMethods) {
    return subscriptionReducerMethods[action.type](state, action.payload);
  } else if (action.type === AccountActionTypes.LOG_OUT) {
    return ACCOUNT_INITIAL_STATE;
  }
  return state;
}
