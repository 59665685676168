import {
  getMergeHorizontalId,
  isMergeVerticalKey,
  MERGE_VERTICAL_PREFIX,
} from '2d/units/excel-table-cell-formatter/common';
import { isColumnIdSpecialWord } from '../is-column-id-special-word';
import { getColumnIndex, getShiftedColumnId } from '../utils';

export const getShiftColumnAfterDelete = (
  key: string,
  startIndex: number,
  offset: number,
  columnKeys: Set<string>,
  rowKeys: string[],
): void => {
  const currentColumnIndex = getColumnIndex(key);
  if (currentColumnIndex < startIndex || isColumnIdSpecialWord(key)) {
    return;
  }
  if (isMergeVerticalKey(key) && currentColumnIndex === startIndex) {
    const horizontalKey = getMergeHorizontalId(key.replace(MERGE_VERTICAL_PREFIX, ''));
    if (rowKeys.includes(horizontalKey)) {
      return;
    }
  }
  columnKeys.add(key);
  if (currentColumnIndex > startIndex + offset - 1) {
    columnKeys.add(getShiftedColumnId(key, -offset));
  }
};
