import * as Ag from 'ag-grid-community';

const STYLE_PROPERTY_PREFIX = 'f_';
const CONFIG_PREFIX = 'c_';
export const MERGE_HORIZONTAL_PREFIX = 'mh_';
export const MERGE_VERTICAL_PREFIX = 'mv_';

export const isStylePropertyKey = (key: string): boolean => key.startsWith(STYLE_PROPERTY_PREFIX);
export const getStylePropertyId = (colId: string): string => `${STYLE_PROPERTY_PREFIX}${colId}`;

export const isConfigKey = (key: string): boolean => key.startsWith(CONFIG_PREFIX);
export const getConfigId = (colId: string): string => `${CONFIG_PREFIX}${colId}`;

export const isMergeHorizontalKey = (key: string): boolean => key.startsWith(MERGE_HORIZONTAL_PREFIX);
export const getMergeHorizontalId = (colId: string): string => `${MERGE_HORIZONTAL_PREFIX}${colId}`;

export const isMergeVerticalKey = (key: string): boolean => key.startsWith(MERGE_VERTICAL_PREFIX);
export const getMergeVerticalId = (colId: string): string => `${MERGE_VERTICAL_PREFIX}${colId}`;

export const getStartAndEndRowIndexes = (range: Ag.CellRange): [number, number] =>
  range.startRow.rowIndex < range.endRow.rowIndex
    ? [range.startRow.rowIndex, range.endRow.rowIndex]
    : [range.endRow.rowIndex, range.startRow.rowIndex];

export const isSpecKey = (key: string): boolean => {
  return key === 'index' || key === 'id';
};
