import { DefaultColumnColdIdConstants } from '../transaction/helpers/constants';


export const defaultVisibleMeasureColumnSet: Set<string> = new Set([
  DefaultColumnColdIdConstants.NAME,
  DefaultColumnColdIdConstants.FILE_NAME,
  DefaultColumnColdIdConstants.PAGE_NAME,
  DefaultColumnColdIdConstants.PARENT_GROUP,
  DefaultColumnColdIdConstants.AREA,
  DefaultColumnColdIdConstants.PERIMETER,
  DefaultColumnColdIdConstants.HEIGHT,
  DefaultColumnColdIdConstants.THICKNESS,
  DefaultColumnColdIdConstants.VOLUME,
  DefaultColumnColdIdConstants.VERTICAL_AREA,
  DefaultColumnColdIdConstants.LENGTH,
  DefaultColumnColdIdConstants.COUNT,
  DefaultColumnColdIdConstants.POINTS_COUNT,
  DefaultColumnColdIdConstants.SEGMENTS_COUNT,
  DefaultColumnColdIdConstants.CREATED_BY,
  DefaultColumnColdIdConstants.CREATED_DATE,
  DefaultColumnColdIdConstants.LAST_EDITED_BY,
  DefaultColumnColdIdConstants.LAST_EDITED_DATE,
]);
