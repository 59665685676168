import { Text, ModalWrapper, LinkComponent, RectangleButton } from '@kreo/kreo-ui-components';
import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { ControlNames } from 'common/constants/control-names';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { usePaymentPortalWithReload } from '../../payment-method-manager/payment-manager/use-payment-portal';
import { InfoBlock } from './info-block';
import { Styled } from './styled';

export const PAYMENT_METHOD_NOT_ADDED_DIALOG = 'PAYMENT_METHOD_NOT_ADDED_DIALOG';

interface PaymentMethod {
  img: string;
  description: string;
}

const PAYMENT_METHODS: PaymentMethod[] = [
  {
    img: 'free',
    description: 'Free trial, no card charge until trial ends',
  },
  {
    img: 'bell',
    description: 'You get an email reminder 2 days before your trial ends',
  },
  {
    img: 'garantee',
    description: '7-day money-back guarantee if you forgot to cancel the trial',
  },
];

interface Props {
  onClickNotUseTrialButton?: () => void;
}


export const PaymentMethodNotAddedDialog: React.FC<Props> = memo(
  ({ onClickNotUseTrialButton }: Props) => {
    const dispatch = useDispatch();
    const closeDialog = useCallback(
      () => dispatch(KreoDialogActions.closeDialog(PAYMENT_METHOD_NOT_ADDED_DIALOG)),
      [dispatch],
    );
    const openPaymentPortal = usePaymentPortalWithReload();

    const addPaymentAndClose = useCallback(() => {
      openPaymentPortal();
      closeDialog();
    }, [openPaymentPortal, closeDialog]);

    const handleCloseDialog = useCallback(() => {
      if (onClickNotUseTrialButton) {
        onClickNotUseTrialButton();
      }
      closeDialog();
    }, [closeDialog, onClickNotUseTrialButton]);

    return (
      <DialogWrapper name={PAYMENT_METHOD_NOT_ADDED_DIALOG}>
        <ModalWrapper onExit={closeDialog}>
          <Styled.Wrapper>
            <Styled.Container>
              <Styled.Header>
                <Text fontSize={18}>Oops! Payment method is not added</Text>
              </Styled.Header>
              <Styled.InfoContainer>
                {PAYMENT_METHODS.map((el, index) => {
                  return (
                    <InfoBlock
                      key={index}
                      img={el.img}
                      description={el.description}
                    />
                  );
                })}
              </Styled.InfoContainer>
              <Styled.AddButtonContainer controlName={ControlNames.addCardButton}>
                <RectangleButton
                  size='m'
                  width={290}
                  mood={'positive'}
                  onClick={addPaymentAndClose}
                  text={'Sure, let me add it'}
                />
              </Styled.AddButtonContainer>
              <Styled.ExitButtonContainer controlName={ControlNames.notAddCardButton}>
                <LinkComponent
                  size='m'
                  onClick={handleCloseDialog}
                  text={'I don’t want to use my card for a trial'}
                />
              </Styled.ExitButtonContainer>
            </Styled.Container>
          </Styled.Wrapper>
        </ModalWrapper>
      </DialogWrapper>
    );
  });
