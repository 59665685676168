import styled from 'styled-components';

import { Text, TinyText } from 'common/components/text';
import { InviteFieldStyled, InvitePeopleFormStyled } from '../invite-people-form';

const Container = styled.div`
  position: relative;
  padding: 40px 40px 35px;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 640px;
  max-height: calc(100vh - 80px);
  border-radius: 40px;
  &, * {
    box-sizing: border-box;
  }
  button {
    margin-left: auto;
  }
  > ${Text} {
    margin-bottom: 40px;
  }

  div:first-child {
    ${InviteFieldStyled.DeleteButtonContainer} {
      height: 50px;
    }
  }

  ${InvitePeopleFormStyled.Container} {
    border-radius: 0;
    padding: 0 5px 5px;
    overflow-y: auto;

    ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${p => p.theme.color.gray};
    }

    ::-webkit-scrollbar-track {
      background: ${p => p.theme.color.background};
    }

    ::-webkit-scrollbar {
      width: 6px;
    }
  }
`;

const Form = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  label {
    margin-bottom: 0;
  }
  > ${TinyText} {
    position: absolute;
    bottom: -20px;
    left: 130px;
  }
  > button {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    transition: ${p => p.theme.duration.s} ${p => p.theme.function.oq};
  }
  :hover {
    > button {
      opacity: 1;
      transform: translate(-10px, -50%);
    }
  }
`;

const DropdownContainer = styled.form`
  width: 210px;
`;

export const Styled = {
  Container,
  DropdownContainer,
  Form,
};
