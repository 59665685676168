import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { pushSearch } from '../../../../actions/common';

const InvitePeopleStep = 'invite-teammates';

export const useShowInvitePage = (): [boolean, () => void] => {
  const dispatch = useDispatch();
  const step = useSelector<State, string>(s => s.router.location.query.step);
  const show = useMemo(() => step === InvitePeopleStep, [step]);
  useEffect(() => {
    dispatch(pushSearch({ step: InvitePeopleStep }));
  }, []);
  const handleClick = useCallback(() => {
    dispatch(pushSearch({ step: undefined }));
  }, [dispatch]);

  return [show, handleClick];
};
