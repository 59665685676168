import { useEffect, useMemo, useRef } from 'react';
import { Operation } from 'common/ability/operation';
import { useAbilityContext } from 'common/ability/use-ability-context';
import { useGlobalKeyboardEvents } from 'common/components/global-keyboard-events-controller';
import { Hotkey } from 'common/hotkeys';
import { useRendererApiContext } from '../../drawings-contexts';
import { DrawingsDrawMode } from '../../enums';
import { useMeasurementsAbilities } from '../../hooks';
import { OptionsSubjectsMap } from '../helpers';
import { InstrumentsTypeGuards } from '../helpers/instruments-type-guards';
import { InstrumentGroupInfo, InstrumentInfo } from '../interfaces';
import { useToggleDrawMode } from './use-toggle-draw-mode';

function *iterateOptions(options: Array<InstrumentInfo | InstrumentGroupInfo>): IterableIterator<InstrumentInfo> {
  for (const option of options) {
    if (InstrumentsTypeGuards.isGroup(option)) {
      yield *iterateOptions(option.subInstruments);
    } else {
      yield option;
    }
  }
}

const EMPTY = {};

export function useInstrumentsListeners(options: Array<InstrumentInfo | InstrumentGroupInfo>): void {
  const toggleDrawMode = useToggleDrawMode();
  const { canEditMeasurements } = useMeasurementsAbilities();
  const { addKeyDownEventListener, removeKeyDownEventListener } = useGlobalKeyboardEvents();
  const { rendererApi } = useRendererApiContext();
  const { ability } = useAbilityContext();
  const hotkeyListeners = useMemo<Record<number, Map<Hotkey, () => void>>>(() => {
    if (!canEditMeasurements) {
      return EMPTY;
    }
    const listeners: Record<number, Map<Hotkey, () => void>> = {};
    for (const option of iterateOptions(options)) {
      if (!option.hotKeys?.length || OptionsSubjectsMap[option.id]?.some((s) => ability.cannot(Operation.Update, s))) {
        continue;
      }
      listeners[option.id] = listeners[option.id] || new Map<Hotkey, () => void>();
      for (const hotKey of option.hotKeys) {
        listeners[option.id].set(hotKey, () => toggleDrawMode(option.id));
      }
    }
    listeners[DrawingsDrawMode.Disabled].set(new Hotkey('Escape'), () => {
      if (!rendererApi?.hasNotFinishedGeometry()) {
        toggleDrawMode(DrawingsDrawMode.Disabled);
      }
    });
    return listeners;
  }, [options, toggleDrawMode, canEditMeasurements, rendererApi, ability]);

  const currentListens = useRef<Record<number, Map<Hotkey, () => void>>>(null);

  useEffect(() => {
    if (currentListens.current) {
      for (const hotkeysMap of Object.values(currentListens.current)) {
        for (const [hotkey, callback] of hotkeysMap.entries()) {
          removeKeyDownEventListener(hotkey, callback);
        }
      }
    }
    currentListens.current = hotkeyListeners;
    for (const hotkeysMap of Object.values(hotkeyListeners)) {
      for (const [hotkey, callback] of hotkeysMap.entries()) {
        addKeyDownEventListener(hotkey, callback);
      }
    }
  }, [hotkeyListeners]);
}
