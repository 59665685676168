import styled from 'styled-components';

import { LinkComponentStyled } from 'common/components/link-component';


const AddEmailButtonContainer = styled.div<{ canAddUser: boolean }>`
  width: 100%;

  ${LinkComponentStyled.DivContainer} {
    margin-top: ${p => p.canAddUser ? 7 : 0}px;
  }
`;

const ContinueButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
  margin-top: 30px;

  > button {
    width: fit-content;
    padding: 0 28px;
    margin-left: 0;
  }
`;

export const Styled = {
  AddEmailButtonContainer,
  ContinueButtonContainer,
};
