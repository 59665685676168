import { Text, H3 } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { SpinnerPage } from 'routes/spinner-page';
import { SUBSCRIPTION_EDIT_FORM_STATE } from '../../../reducers/account';
import { AccountActions } from '../../../units/account/actions/creators';
import { SUBSCRIPTION__DIALOG } from '../components/subscription-dialog';
import { SubscriptionStatus } from '../enums/subscription-status';
import { useSubscriptionStatus } from '../hooks';
import { FeedbackForm } from './feedback-form';
import { useOwnCompanyBillingEffect, useSubmitFormCallback } from './hooks';
import { EXTRA_TEXT, HEADER_TEXT } from './interfaces';
import { Styled } from './styled';

export const CancellationSurveyPage: React.FC = memo(() => {
  const dispatch = useDispatch();
  const subscriptionStatus = useSubscriptionStatus();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSpinnerPage, setShowSpinnerPage] = useState(false);

  const resetSubscriptionEditForm = useCallback(
    () => dispatch(AccountActions.setSubscriptionEditFormState(SUBSCRIPTION_EDIT_FORM_STATE)),
    [dispatch],
  );
  const onChangeUrl = useCallback((url) => dispatch(push(url)), []);
  const sendForm = useSubmitFormCallback(setShowSpinnerPage);

  const closePage = useCallback(() => {
    setIsLoading(true);
    resetSubscriptionEditForm();
    onChangeUrl(AppUrls.root.url());
  }, [onChangeUrl, resetSubscriptionEditForm]);

  useOwnCompanyBillingEffect();

  useEffect(() => {
    dispatch(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG));
  }, []);

  useEffect(() => {
    if (subscriptionStatus === SubscriptionStatus.Canceled) {
      onChangeUrl(AppUrls.root.url());
    }
  }, [subscriptionStatus]);

  const isShowSpinnerPage = useMemo(() => {
    return false;
    return showSpinnerPage || subscriptionStatus !== SubscriptionStatus.Valid;
  }, [showSpinnerPage, subscriptionStatus]);

  const showForm = useMemo(() => {
    return subscriptionStatus === SubscriptionStatus.Valid || subscriptionStatus === SubscriptionStatus.Paused;
  }, [subscriptionStatus]);

  return (
    <Styled.Container>
      <RenderIf condition={isShowSpinnerPage}>
        <SpinnerPage />
      </RenderIf>
      <RenderIf condition={showForm}>
        <Spinner show={isLoading} withBackground={true} />
        <Styled.HeaderContainer>
          <H3>{HEADER_TEXT}</H3>
        </Styled.HeaderContainer>
        <Styled.ExtraText>
          <Text color={'gray'}>
            {EXTRA_TEXT}
          </Text>
        </Styled.ExtraText>
        <FeedbackForm
          sendForm={sendForm}
          closePage={closePage}
        />
      </RenderIf>
    </Styled.Container>
  );
});
