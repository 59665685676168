import { ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { CommentaryDrawingWithRectTarget } from 'unit-2d-comments/interfaces';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { CommentRect } from './comment-rect';

interface Config extends EngineObjectConfig {
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  layer: paper.Layer;
}

export class CommentViewHelper extends DestroyableObject<Config> {
  private _comment: CommentRect;

  private _commentId: string | number;

  public destroy(): void {
    if (this._comment) {
      this._comment.destroy();
    }
  }

  public renderCommentView(comment: CommentaryDrawingWithRectTarget, commentId: string | number, fill: boolean): void {
    if (this._comment) {
      this._comment.destroy();
      this._comment = null;
    }

    this._commentId = commentId;

    if (!comment) {
      return;
    }

    this._comment = new CommentRect({
      geometry: comment,
      renderParamsContextObserver: this._config.renderParamsContextObserver,
      layer: this._config.layer,
      fill,
    });
  }

  public removeCommentIfItsShown(commentId: string | number): void {
    if (this._commentId === commentId && this._comment) {
      this._comment.destroy();
      this._comment = null;
      this._commentId = null;
    }
  }
}

