import GUI from 'lil-gui';
import { CursorHintType } from 'common/components/drawings/layout-components/drawings-cursor-hint';
import { TestingGui } from 'common/testing-gui/testing-gui';
import { BucketAIHoverPreview } from './bucket-ai-hover-preview';
import { BucketAIBase } from './bucket-ai-with-processing';
import { AIToolsConstants } from './constants';
import { GeometryType } from './geometry-types';
import { OneClickStates, OneClickTool } from './one-click-tool';

const SHOW_TESTING_GUI = false;


const MESSAGES: Record<OneClickStates, CursorHintType> = {
  [OneClickStates.Progress]: CursorHintType.OneClickLineInProgress,
  [OneClickStates.OutOfArea]: CursorHintType.OneClickLineOutOffArea,
  [OneClickStates.NotFound]: CursorHintType.OneClickLineNotFound,
  [OneClickStates.NoPoints]: CursorHintType.OneClickLineNoPoints,
  [OneClickStates.OnlyNegativePoints]: CursorHintType.OneClickLineOnlyNegativePoints,
  [OneClickStates.Expand]: CursorHintType.OneClickLineExpandLine,
  [OneClickStates.AddNegative]: CursorHintType.OneClickLineNegativePoint,
};

export class OneClickLineTool extends OneClickTool {
  private _gui: GUI = null;

  public override destroy(): void {
    super.destroy();
    TestingGui.destroy();
    this._gui = null;
  }

  protected override init(): void {
    this._hoverPreview = new BucketAIHoverPreview({ ...this._config, type: GeometryType.Polyline });
    this._ai = new BucketAIBase({
      ...this._config,
      onShowMask: () => this.removePreview(),
      withPolygonizer: false,
      type: GeometryType.Polyline,
      onPointRemoveClick: this.onRemovePoint,
    });
    if (SHOW_TESTING_GUI) {
      this._gui = TestingGui.getTestingGui();
      this._gui.add(AIToolsConstants, 'POINT_COMPARISON_THRESHOLD');
      this._gui.add(AIToolsConstants, 'USE_POINT_THRESHOLD');
      this._gui.add(AIToolsConstants, 'IS_AUTO');
      this._gui.add(AIToolsConstants, 'POLYGONIZE_AI');
      this._gui.add(AIToolsConstants, 'POLYGONIZE_BE');
    }
  }

  protected override async getPointStatus(
    point: paper.Point,
    ctrl: boolean,
  ): Promise<{ isValid: boolean, isPositive: boolean }> {
    let isValid = true;
    const isPositive = AIToolsConstants.USE_POINT_THRESHOLD
      ? !this._preview?.isPointOnLine(point)
      : !ctrl;

    if (isPositive && this._lastWindow?.length && this._ai.windowsHelper) {
      isValid = await this._ai.windowsHelper.isPointInWindow(point, this._lastWindow);
    }
    return {
      isValid,
      isPositive,
    };
  }

  protected override getQuality(): { dpi: number, isAuto: boolean } {
    const { lineQuality } = this._config.wizzardSettingsObserver.getContext();
    return { dpi: lineQuality, isAuto: AIToolsConstants.IS_AUTO };
  }

  protected override updateMessage(state: OneClickStates): void {
    this._config.setCursorMessage(MESSAGES[state]);
  }

  protected override checkExtensionState(): OneClickStates {
    if (AIToolsConstants.USE_POINT_THRESHOLD && this._preview) {
      const point = this._currentPoint;
      if (this._preview.isPointOnLine(point)) {
        return OneClickStates.AddNegative;
      } else {
        return OneClickStates.Expand;
      }
    }
    return null;
  }
}
