import styled from 'styled-components';

import { SpinnerStyled } from 'common/components/spinner';


const Container = styled.div<{ zIndexSpinner: number }>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all ${p => p.theme.duration.s} linear;
  border-radius: inherit;
  box-shadow: 0 0 0 2px ${p => p.theme.color.background};

  img {
    display: block;
    width: inherit;
    height: inherit;
    position: relative;
    object-fit: cover;
  }

  ${SpinnerStyled.SpinnerContainer} {
    z-index: ${p => p.zIndexSpinner};
  }

  ${SpinnerStyled.Spinner} {
    border-radius: 0;
    width: 100%;
    height: 100%;
    background: ${p => p.theme.color.background};
  }
`;

export const Styled = {
  Container,
};
