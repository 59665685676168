import * as Ag from 'ag-grid-community';
import moment from 'moment';

import {
  DrawingsGeometryGroup,
  DrawingsInstanceMeasure,
  DrawingsLayoutApi,
} from 'common/components/drawings';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { DrawingsFiles } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsGeometryState } from 'common/components/drawings/interfaces/drawings-state';
import { DateFormatConstants } from 'common/constants/date-formats';
import { mathUtils } from 'common/utils/math-utils';
import { TWODUnitConversionMap, UnitTypes, UnitUtil } from 'common/utils/unit-util';
import { TwoDViewTableConfig } from 'unit-2d-database/interfaces';
import { FooterValue, NumberColumnDef, sortComparator, StringColumnDef, wrapZeroValue } from '../../constants';
import { Props } from '../../interfaces';
import { extendsCodeColumns } from '../extend-code-column';
import { extendDataByEmptyGroup, getGroupExtender } from '../extends-by-group';
import { hasUpdatedGeometry } from '../has-updated-geometry';
import { orderAutoGroupColumn } from '../items-helper/utils';
import { splitMap } from '../split-map';
import { DefaultColumnColdIdConstants } from '../transaction/helpers/constants';

export interface ViewData<TData> {
  columns: Ag.ColDef[];
  map: Record<string, TData>;
}

export interface IDataHelper<TRowData> {
  getViewData(sourceData: Props<TRowData>, isPivot: boolean, config: TwoDViewTableConfig): Promise<ViewData<TRowData>>;
  getAutoColumnGroup(): Ag.ColDef;
  isNeedUpdate(props: Props<TRowData>, isPivot: boolean): boolean;
}

interface User {
  id: string;
  email?: string;
  lastName?: string;
  firstName?: string;
}

interface MeasureSource {
  files: DrawingsFiles;
  drawingsInfo: Record<string, DrawingsShortInfo>;
  groups: DrawingsGeometryGroup[];
  drawingApi: DrawingsLayoutApi;
  aiAnnotation: DrawingsGeometryState;
  elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  users: User[];
  isImperial: boolean;
  withUnit: boolean;
  withTableTotal: boolean;
  groupsMap: Record<string, DrawingsGeometryGroup>;
  showCode: boolean;
}

interface MeasureRowData {
  id: string;
  name: string;
  color: string;
  type: string;
  fileName: string;
  pageName: string;
  parentGroup: string;
  area: number | string;
  perimeter: number | string;
  height: number | string;
  thickness: number | string;
  verticalArea: number | string;
  volume: number | string;
  length: number | string;
  count: number | string;
  pointsCount: number | string;
  segmentsCount: number | string;
  createdBy: string;
  createdDate: string;
  lastEditedBy: string;
  lastEditedDate: string;
  measureId: string;

  unit_area: string;
  unit_perimeter: string;
  unit_length: string;
  unit_height: string;
  unit_thickness: string;
  unit_verticalArea: string;
  unit_volume: string;
  unit_count: string;
  unit_pointsCount: string;
  unit_segmentsCount: string;
  withUnit: boolean;
}

export class MeasureHelper implements IDataHelper<MeasureRowData> {
  private prevIsPivot: boolean;
  private prevSource: MeasureSource;
  private prevResult: ViewData<MeasureRowData>;
  private autoGroupColumn: Ag.ColDef = {
    field: 'name',
    width: 180,
    minWidth: 125,
    cellRendererParams: {
      footerValueGetter: () => {
        return FooterValue;
      },
      suppressCount: true,
      innerRenderer: 'defaultCellRenderer',
    },
    checkboxSelection: true,
    headerCheckboxSelection: true,
    comparator: sortComparator,
  };

  public getAutoColumnGroup(): Ag.ColDef {
    return this.autoGroupColumn;
  }

  public isNeedUpdate(props: Props<MeasureRowData>, isPivot: boolean): boolean {
    const sourceData = this.getSourceData(props);
    if (
      this.prevSource &&
      !!this.prevIsPivot === isPivot &&
      sourceData.aiAnnotation.additionalColors === this.prevSource.aiAnnotation.additionalColors &&
      sourceData.aiAnnotation.fileData === this.prevSource.aiAnnotation.fileData &&
      sourceData.aiAnnotation.geometryCreators === this.prevSource.aiAnnotation.geometryCreators &&
      sourceData.aiAnnotation.pointsInfo === this.prevSource.aiAnnotation.pointsInfo &&
      sourceData.files === this.prevSource.files &&
      sourceData.elementMeasurement === this.prevSource.elementMeasurement &&
      sourceData.isImperial === this.prevSource.isImperial &&
      sourceData.drawingsInfo === this.prevSource.drawingsInfo &&
      sourceData.groups === this.prevSource.groups &&
      sourceData.withUnit === this.prevSource.withUnit &&
      sourceData.withTableTotal === this.prevSource.withTableTotal &&
      sourceData.showCode === this.prevSource.showCode
      && !hasUpdatedGeometry(sourceData.aiAnnotation.geometry, this.prevSource?.aiAnnotation.geometry)
    ) {
      return false;
    }

    return true;
  }

  public async getViewData(props: Props<MeasureRowData>, isPivot: boolean): Promise<ViewData<MeasureRowData>> {
    if (!this.isNeedUpdate(props, isPivot)) {
      return this.prevResult;
    }

    const sourceData = this.getSourceData(props);
    this.prevSource = sourceData;
    this.prevIsPivot = isPivot;
    const map: Record<string, Partial<MeasureRowData>> = {};
    const [extender, groupHierarchyColumns] = getGroupExtender(sourceData.groupsMap);
    for (const groupKey of Object.keys(sourceData.groupsMap)) {
      extender({}, groupKey);
    }

    const keys = Object.keys(sourceData.aiAnnotation.geometry);
    await splitMap(100, keys, (id) => {
      const value = sourceData.aiAnnotation.geometry[id];
      const [fileName, pageName] = this.getFileInfo(value.drawingId, sourceData);
      const parentGroup = this.getGroupName(value.groupId, sourceData);
      const createdBy = this.getUserName(props.users.find(u => u.id === value.creator));
      const createdDate = this.getDate(value.createdAt);
      const lastEditedBy = this.getUserName(props.users.find(u => u.id === value.editor));
      const lastEditedDate = this.getDate(value.editedAt);

      const groupHierarchyMap: Record<string, string> = {};
      extender(groupHierarchyMap, value.groupId);

      map[id] = {
        id,
        measureId: id,
        name: value.name,
        type: value.type,
        color: value.geometry.color,
        fileName,
        pageName,
        parentGroup,

        createdBy,
        createdDate,
        lastEditedBy,
        lastEditedDate,
        withUnit: sourceData.withUnit,

        ...groupHierarchyMap,
      };
    });

    const measures = this.getMeasures(keys, sourceData);
    await splitMap(100, keys, (id, index) => {
      const [
        area,
        perimeter,
        length,
        count,
        pointsCount,
        segmentsCount,
        height,
        thickness,
        verticalArea,
        volume,
      ] = measures[index];
      const [convertedArea, convertedAreaUnit] = this.roundValueGetter(area, sourceData, UnitTypes.M2);
      const [convertedPerimeter, convertedPerimeterUnit] = this.roundValueGetter(perimeter, sourceData, UnitTypes.M);
      const [convertedLength, convertedLengthUnit] = this.roundValueGetter(length, sourceData, UnitTypes.M);
      const [convertedHeight, convertedHeightUnit] = this.roundValueGetter(height, sourceData, UnitTypes.M);
      const [convertedThickness, convertedThicknessUnit] = this.roundValueGetter(thickness, sourceData, UnitTypes.M);
      const [convertedVerticalArea, convertedVerticalAreaUnit] =
        this.roundValueGetter(verticalArea, sourceData, UnitTypes.M2);
      const [convertedVolume, convertedVolumeUnit] = this.roundValueGetter(volume, sourceData, UnitTypes.M3);

      map[id] = {
        ...map[id],

        area: wrapZeroValue(convertedArea),
        unit_area: UnitUtil.getSupUnit(convertedAreaUnit),
        perimeter: wrapZeroValue(convertedPerimeter),
        unit_perimeter: convertedPerimeterUnit,
        length: wrapZeroValue(convertedLength),
        unit_length: convertedLengthUnit,
        height: wrapZeroValue(convertedHeight),
        unit_height: convertedHeightUnit,
        thickness: wrapZeroValue(convertedThickness),
        unit_thickness: convertedThicknessUnit,
        verticalArea: wrapZeroValue(convertedVerticalArea),
        unit_verticalArea: UnitUtil.getSupUnit(convertedVerticalAreaUnit),
        volume: wrapZeroValue(convertedVolume),
        unit_volume: UnitUtil.getSupUnit(convertedVolumeUnit),
        count: wrapZeroValue(count),
        unit_count: UnitTypes.Nr,
        pointsCount: wrapZeroValue(pointsCount),
        unit_pointsCount: UnitTypes.Nr,
        segmentsCount: wrapZeroValue(segmentsCount),
        unit_segmentsCount: UnitTypes.Nr,
      };
    });
    const defaultColumns = this.getColumns();
    const columns = {
      ...defaultColumns,
      ...groupHierarchyColumns,
    };

    if (sourceData.showCode) {
      extendsCodeColumns(columns, []);
    }

    if (isPivot) {
      extendDataByEmptyGroup(map, columns);
    }

    const columnList = Object.values(columns);
    columnList.unshift(orderAutoGroupColumn);
    this.prevResult = { columns: columnList, map: map as Record<string, MeasureRowData>  };

    return { columns: columnList, map: map as Record<string, MeasureRowData> };
  }

  private getFileInfo(drawingId: string, { files, drawingsInfo }: MeasureSource): [string, string] {
    const drawingInfo = drawingsInfo[drawingId];
    if (!drawingInfo) {
      return ['', ''];
    }

    const fileInfo = files.entities[drawingInfo.pdfId];
    if (!fileInfo) {
      return ['', drawingInfo.name];
    }

    return [fileInfo.properties.name, drawingInfo.name];
  }

  private getGroupName(groupId: string, { groups }: MeasureSource): string {
    const group = groups.find(g => g.id === groupId);
    if (!group) {
      return undefined;
    }

    return group.name;
  }

  private getMeasures(
    ids: string[],
    { drawingApi, drawingsInfo, aiAnnotation, elementMeasurement }: MeasureSource,
  ): number[][] {
    if (drawingApi) {
      const measures = drawingApi.getInstancesMeasures(
        ids,
        drawingsInfo,
        aiAnnotation,
        elementMeasurement,
      );
      return measures.map(m => {
        const {
          area,
          perimeter,
          length,
          count,
          pointsCount,
          segmentsCount,
          height,
          thickness,
          verticalArea,
          volume,
        } = m.measures as any;
        return [
          area,
          perimeter,
          length,
          count === undefined ? 1 : count,
          pointsCount,
          segmentsCount,
          height,
          thickness,
          verticalArea,
          volume,
        ];
      });
    }
    return [];
  }

  private getSourceData(props: Props<MeasureRowData>): MeasureSource {
    const groupsMap: Record<string, DrawingsGeometryGroup> = props.groups.reduce(
      (acc, group) => {
        acc[group.id] = group;
        return acc;
      },
      {},
    );

    return {
      aiAnnotation: props.aiAnnotation,
      elementMeasurement: props.elementMeasurement,
      files: props.files,
      drawingsInfo: props.drawingsInfo,
      groups: props.groups,
      drawingApi: props.drawingApi,
      users: props.users,
      isImperial: props.isImperial,
      withUnit: props.withUnit,
      groupsMap,
      withTableTotal: props.withTableTotal,
      showCode: props.showCode,
    };
  }

  private convertedValueGetter(value: number, isImperial: boolean, siUnit: UnitTypes): [number, string] {
    if (isImperial) {
      const imperialUnit = TWODUnitConversionMap[siUnit];
      const converted = UnitUtil.convertUnit(value, siUnit, imperialUnit);
      return [converted.value, converted.unit];
    }
    return [value, siUnit];
  }

  private roundValueGetter(value: number, { isImperial }: MeasureSource, siUnit: UnitTypes): [number, string] {
    if (value !== undefined && value !== null) {
      const [convertedValue, convertedUnit] = this.convertedValueGetter(value, isImperial, siUnit);
      return [mathUtils.round(convertedValue, 2), convertedUnit];
    }
    return [undefined, undefined];
  }

  private getUserName(user: User): string {
    if (!user) {
      return '';
    }
    if (user.firstName || user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.email;
  }

  private getDate(date: string): string {
    return date ? moment(date).format(DateFormatConstants.FULL_DATE_TIME_FORMAT) : '';
  }

  private getColumns(): Record<string, Ag.ColDef> {
    return {
      name: {
        field: DefaultColumnColdIdConstants.NAME,
        colId: DefaultColumnColdIdConstants.NAME,
        headerName: 'Measurement name',
        headerCheckboxSelection: params => !params.columnApi.getRowGroupColumns().length,
        checkboxSelection: params => !params.columnApi.getRowGroupColumns().length,
        valueGetter: (params) => {
          const value = params.data?.name;
          if (params.columnApi.getRowGroupColumns().length || value) {
            return value;
          } else {
            return FooterValue;
          }
        },
        ...StringColumnDef,
      },
      fileName: {
        field: DefaultColumnColdIdConstants.FILE_NAME,
        colId: DefaultColumnColdIdConstants.FILE_NAME,
        headerName: `File name`,
        ...StringColumnDef,
      },
      pageName: {
        field: DefaultColumnColdIdConstants.PAGE_NAME,
        colId: DefaultColumnColdIdConstants.PAGE_NAME,
        headerName: `Page name`,
        ...StringColumnDef,
      },
      parentGroup: {
        field: DefaultColumnColdIdConstants.PARENT_GROUP,
        colId: DefaultColumnColdIdConstants.PARENT_GROUP,
        headerName: 'Parent group',
        ...StringColumnDef,
      },
      area: {
        field: DefaultColumnColdIdConstants.AREA,
        colId: DefaultColumnColdIdConstants.AREA,
        headerName: 'Area',
        ...NumberColumnDef,
      },
      perimeter: {
        field: DefaultColumnColdIdConstants.PERIMETER,
        colId: DefaultColumnColdIdConstants.PERIMETER,
        headerName: 'Perimeter',
        ...NumberColumnDef,
      },
      height: {
        field: DefaultColumnColdIdConstants.HEIGHT,
        colId: DefaultColumnColdIdConstants.HEIGHT,
        headerName: 'Height',
        ...NumberColumnDef,
      },
      thickness: {
        field: DefaultColumnColdIdConstants.THICKNESS,
        colId: DefaultColumnColdIdConstants.THICKNESS,
        headerName: 'Thickness',
        ...NumberColumnDef,
      },
      volume: {
        field: DefaultColumnColdIdConstants.VOLUME,
        colId: DefaultColumnColdIdConstants.VOLUME,
        headerName: 'Volume',
        ...NumberColumnDef,
      },
      verticalArea: {
        field: DefaultColumnColdIdConstants.VERTICAL_AREA,
        colId: DefaultColumnColdIdConstants.VERTICAL_AREA,
        headerName: 'Vertical area',
        ...NumberColumnDef,
      },
      length: {
        field: DefaultColumnColdIdConstants.LENGTH,
        colId: DefaultColumnColdIdConstants.LENGTH,
        headerName: 'Length',
        ...NumberColumnDef,
      },
      count: {
        field: DefaultColumnColdIdConstants.COUNT,
        colId: DefaultColumnColdIdConstants.COUNT,
        headerName: 'Count',
        ...NumberColumnDef,
      },
      pointsCount: {
        field: DefaultColumnColdIdConstants.POINTS_COUNT,
        colId: DefaultColumnColdIdConstants.POINTS_COUNT,
        headerName: 'Points Count',
        ...NumberColumnDef,
      },
      segmentsCount: {
        field: DefaultColumnColdIdConstants.SEGMENTS_COUNT,
        colId: DefaultColumnColdIdConstants.SEGMENTS_COUNT,
        headerName: 'Segments Count',
        ...NumberColumnDef,
      },
      createdBy: {
        field: DefaultColumnColdIdConstants.CREATED_BY,
        colId: DefaultColumnColdIdConstants.CREATED_BY,
        headerName: 'Created by',
      },
      createdDate: {
        field: DefaultColumnColdIdConstants.CREATED_DATE,
        colId: DefaultColumnColdIdConstants.CREATED_DATE,
        headerName: 'Created date',
      },
      lastEditedBy: {
        field: DefaultColumnColdIdConstants.LAST_EDITED_BY,
        colId: DefaultColumnColdIdConstants.LAST_EDITED_BY,
        headerName: 'Last edited by',
      },
      lastEditedDate: {
        field: DefaultColumnColdIdConstants.LAST_EDITED_DATE,
        colId: DefaultColumnColdIdConstants.LAST_EDITED_DATE,
        headerName: 'Last edited date',
      },
    };
  }
}
