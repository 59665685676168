import React, { useCallback, useState } from 'react';

import { TextInput } from 'common/components/inputs';
import { H6 } from 'common/components/text';
import { ControlButtons } from '../control-buttons/control-buttons';
import { Styled } from './styled';

const MAX_VALUE_LENGTH = 2048;

interface Props {
  sendForm: (form: Record<string, string>) => void;
  closePage: () => void;
}

export const FeedbackForm: React.FC<Props>  = ({
  sendForm,
  closePage,
}: Props) => {
  const [feedback, setFeedback] = useState<string>('');

  const handleChange = useCallback((value) => {
    if (value.length <= MAX_VALUE_LENGTH) {
      setFeedback(value.trim());
    } else {
      setFeedback(value.slice(0, MAX_VALUE_LENGTH).trim());
    }
  }, []);

  const handleBlur = useCallback((value: string) => {
    setFeedback(value.trim());
  }, []);

  const sendFeedbackForm = useCallback(() => {
    sendForm({
      'Why did you decide to cancel your subscription to our service?': feedback,
    });
  }, [feedback, sendForm]);

  return (
    <Styled.Container>
      <Styled.FeedbackForm>
        <H6>
          Why did you decide to cancel your subscription to our app?
        </H6>
        <TextInput
          value={feedback.trim()}
          onChange={handleChange}
          onBlur={handleBlur}
          isArea={true}
          isAutoFocus={true}
          placeholder='Please enter the reason for canceling your subscription'
          areaRows={8}
          maxValue={MAX_VALUE_LENGTH}
        />
      </Styled.FeedbackForm>
      <ControlButtons
        continueButtonTitle={'Cancel subscription'}
        showContinueButton={feedback.trim().length > 2}
        onClickContinue={sendFeedbackForm}
        closeButtonClick={closePage}
      />
    </Styled.Container>
  );
};
