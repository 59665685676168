
import { RequestStatus } from 'common/enums/request-status';
import { PreviewContour } from '../actions/payloads/magic-search';
import { DrawingsDrawMode } from '../enums';
import { WizzardStatus } from '../enums/dropper-state';
import { MagicSearchContour, MagicSearchSnappedGeometry } from './api-responses/magic-search-responses';
import { ShortPointDescription } from './drawing-ai-annotation';


export interface FixContourAPI {
  setAsDeleted: () => void;
  apply: () => void;
  tryFix: () => void;
}

export enum ContourType {
  Source,
  Fixed,
  Negative,
}

export interface FixContour {
  state: RequestStatus;
  contourIndex: number;
  contourType: ContourType;
  api: FixContourAPI;
  sources: number[];
  contour: { contour: ShortPointDescription[], holes: ShortPointDescription[][] };
}


export interface FixedContour {
  points: ShortPointDescription[];
  sourceContours: number[];
  previewIndex: number;
  holes: ShortPointDescription[][];
}

export interface MagicSearchState {
  contoursToSearch: ShortPointDescription[][];
  negativeContours: ShortPointDescription[][];
  contours: MagicSearchContour[];
  previews: PreviewContour[];
  resultToSources: Record<number, number[]>;
  dpi: number;
  status: WizzardStatus;
  searchArea: ShortPointDescription[];
  searchAreaSpecifyMode: boolean;
  similarity: number;
  contoursToDelete: number[];
  lastSnapped: MagicSearchSnappedGeometry[];
  previewsToDelete: number[];
  drawModeOnClose?: DrawingsDrawMode;
  query: string;
  snappingStatus: RequestStatus;
  replacedContours: number[];
  hiddenPreviews: number[];
  fixedContours: FixedContour[];
  fixContour: FixContour;
}
