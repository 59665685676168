import { useCallback } from 'react';

import { Assembly, Item, Property } from 'unit-2d-database/interfaces';
import { FormulaDialogData } from '../../formula-dialog';
import { AssemblyUpdater, handleAssemblyFormulaFieldClick, mapAssemblyGroupForm } from '../helpers';
import { handleAssemblyBreakdownFieldClick } from '../helpers/handle-assembly-breakdown-field-click';
import { MeasureForm } from '../interfaces';

type SelectAssembliesCallback = (
  units: string[],
  getAssembliesDatabase: () => Assembly[],
  getItemsDatabase: () => Item[],
  getPropertiesDatabase: () => Property[],
  getForm: () => MeasureForm,
  updater: AssemblyUpdater,
  openFormulaDialog: (data: FormulaDialogData) => void,
  openBreakdownDialog: (data: any) => void,
  ) => (ids: string) => void;

export const useSelectAssembliesCallback: SelectAssembliesCallback = (
  units,
  getAssembliesDatabase,
  getItemsDatabase,
  getPropertiesDatabase,
  getForm,
  updater,
  openFormulaDialog,
  openBreakdownDialog,
) => {
  return useCallback((ids) => {
    const idsSet = new Set(ids);
    const assembliesDatabase = getAssembliesDatabase();
    const itemsDatabase = getItemsDatabase();
    const propertiesDatabase = getPropertiesDatabase();
    const assemblies = assembliesDatabase.filter(a => idsSet.has(a.id));
    const handleFormulaClick = handleAssemblyFormulaFieldClick(
      getForm(),
      updater,
      openFormulaDialog,
      propertiesDatabase,
      units,
    );
    const handleBBClick = handleAssemblyBreakdownFieldClick(
      updater,
      openBreakdownDialog,
    );
    const groupsForm = mapAssemblyGroupForm(
      assemblies,
      updater,
      units,
      handleFormulaClick,
      handleBBClick,
      assembliesDatabase,
      propertiesDatabase,
      itemsDatabase,
    );
    updater.updateAssemblyForm(groupsForm);
  }, []);
};
