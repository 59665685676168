import { useCallback } from 'react';
import { NewDrawingSettings } from '../../drawings-geometry/interfaces';
import { DrawingsInstanceType } from '../../enums';
import {
  DrawingsBasePolyGeometryNew,
  DrawingsCountGeometry,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
  DrawingsSelectAggregationGroup,
} from '../../interfaces';
import { DrawingsGeometryStyle } from '../../interfaces/drawings-geometry-style';
import { useUpdateNewInstanceSettings } from '../redux-selectors';


type ParamsToCopyConfig<T> = {
  [K in keyof NewDrawingSettings]?: keyof T;
};

type BasicParasm<T extends DrawingsBasePolyGeometryNew = DrawingsBasePolyGeometryNew> = {
  [K in keyof T]?: T[K];
};

const STROKED_GEOMETRY_PARAMS_TO_COPY: ParamsToCopyConfig<DrawingsBasePolyGeometryNew> = {
  strokeWidth: 'strokeWidth',
  offset: 'offset',
  strokeStyle: 'strokeStyle',
};

const POLYLINE_BASIC_PARAMS: BasicParasm<DrawingsPolylineGeometry> = {
  offset: undefined,
  height: undefined,
  thickness: undefined,
};

const POLYGON_BASIC_PARAMS: BasicParasm = {
  height: undefined,
  offset: undefined,
};

const POLYLINE_GEOMETRY_PARAMS_TO_COPY: ParamsToCopyConfig<DrawingsPolylineGeometry> = {
  ...STROKED_GEOMETRY_PARAMS_TO_COPY,
  polylineThickness: 'thickness',
  polylineHeight: 'height',
};

const POLYGON_GEOMETRY_PARAMS_TO_COPY: ParamsToCopyConfig<DrawingsPolygonGeometry>  = {
  ...STROKED_GEOMETRY_PARAMS_TO_COPY,
  polygonHeight: 'height',
};

const COUNT_GEOMETRY_PARAMS_TO_COPY: ParamsToCopyConfig<DrawingsCountGeometry> = {
  shape: 'shape',
};


const BASIC_PARAMS_BY_TYPE = {
  [DrawingsSelectAggregationGroup.Area]: POLYGON_BASIC_PARAMS,
  [DrawingsSelectAggregationGroup.Line]: POLYLINE_BASIC_PARAMS,
  [DrawingsSelectAggregationGroup.Count]: {},
};

const COPY_PARAMS_BY_RESULT_TYPE = {
  [DrawingsInstanceType.Polygon]: POLYGON_GEOMETRY_PARAMS_TO_COPY,
  [DrawingsInstanceType.Polyline]: POLYLINE_GEOMETRY_PARAMS_TO_COPY,
  [DrawingsInstanceType.Count]: COUNT_GEOMETRY_PARAMS_TO_COPY,
  [DrawingsInstanceType.Rectangle]: STROKED_GEOMETRY_PARAMS_TO_COPY,
};

export interface CopyBaseInstance {
  groupId: string;
  name: string;
  id: string;
}

export interface GeometryParams extends DrawingsGeometryStyle {
  thickness: number;
  height: number;
  offset: number;
}

export function useCopyInstanceSettingsToNewGeometry(
  instance: CopyBaseInstance,
  geometryParams: Partial<GeometryParams>,
  aggregationGroup: DrawingsSelectAggregationGroup,
  resultType: DrawingsInstanceType,
): () => void {
  const updateNewInstanceSettings = useUpdateNewInstanceSettings();

  return useCallback(() => {
    if (!instance) {
      return;
    }
    const newSettings: Partial<NewDrawingSettings> = {};
    newSettings.color = geometryParams.color;
    newSettings.name = instance.name;
    const getters = COPY_PARAMS_BY_RESULT_TYPE[resultType];
    const geometry = { ...BASIC_PARAMS_BY_TYPE[aggregationGroup], ...geometryParams };
    for (const [key, value] of Object.entries<string>(getters)) {
      if (value in geometry) {
        newSettings[key] = geometry[value];
      }
    }

    updateNewInstanceSettings(newSettings);
  }, [instance, resultType, updateNewInstanceSettings, geometryParams, aggregationGroup]);
}
