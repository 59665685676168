import { Avatar, FlowNavigation } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { H4, Text } from 'common/components/text';
import { DEFAULT_TAB_NAMES } from './company-info-tab';
import { CompanyTab } from './company-tab';
import { RoleData, UserData } from './interfaces';
import { AVATAR_HEIGHT, Styled } from './styled';
import { UsersTab } from './user-tab/users-tab';


interface Props {
  companyName: string;
  companyId: number;
  companyNameChange?: (name: string) => void;
  companyUsers: UserData[];
  maxUsersCount: number;
  emptySeatsCount: number;
  companyGuests: UserData[];
  deleteCompany?: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  resendInvitation?: (email: string) => void;
  addUserButtonClick?: () => void;
  addGuestButtonClick?: () => void;
  avatar: string;
  avatarComponent: () => React.ReactNode;
  userRoles: RoleData[];
  guestRoles: RoleData[];
  disableEditUserList: string[];
  addSeatsButtonClick?: () => void;
  openTabIndex?: number;
  canInvite?: boolean;
  visibleTabs?: string[];
}


export const CompanyInfo = React.memo<Props>((props) => {
  const {
    companyName,
    companyId,
    companyNameChange,
    resendInvitation,
    companyUsers,
    maxUsersCount,
    companyGuests,
    deleteCompany,
    changeUserRole,
    deleteUser,
    addUserButtonClick,
    addGuestButtonClick,
    avatar,
    avatarComponent,
    userRoles,
    guestRoles,
    disableEditUserList,
    addSeatsButtonClick,
    openTabIndex = 1,
    emptySeatsCount,
    canInvite,
    visibleTabs,
  } = props;

  const tabsNames = visibleTabs || DEFAULT_TAB_NAMES;
  const [currentTab, setCurrentTab] = React.useState(tabsNames[openTabIndex]);
  const customAvatarComponent = avatarComponent && avatarComponent();
  const tabsItems = React.useMemo(() => tabsNames.map(name => {
    return {
      name,
      current: name === currentTab,
      onClick: () => setCurrentTab(name),
    };
  }), [currentTab, tabsNames]);
  const payableUsersCount = Number.isInteger(emptySeatsCount)
    ? maxUsersCount - emptySeatsCount
    : maxUsersCount - companyUsers.length;
  const usersCountString = `${payableUsersCount} of ${maxUsersCount} seats used`;
  const getActiveTab = (tabName: string): React.ReactNode => {
    switch (tabName) {
      case tabsNames[0]:
        return (
          <CompanyTab
            companyName={companyName}
            companyId={companyId}
            companyNameChange={companyNameChange}
            deleteCompany={deleteCompany}
          />
        );
      case tabsNames[1]:
        return (
          <UsersTab
            emptySeatsCount={emptySeatsCount}
            users={companyUsers}
            maxUsersCount={maxUsersCount}
            changeUserRole={changeUserRole}
            deleteUser={deleteUser}
            addUserButtonClick={addUserButtonClick}
            userRoles={userRoles}
            canInvite={canInvite}
            disableEditUserList={disableEditUserList}
            addSeatsButtonClick={addSeatsButtonClick}
            resendInvitation={resendInvitation}
          />
        );
      default:
      case tabsNames[2]:
        return (
          <UsersTab
            maxUsersCount={maxUsersCount}
            emptySeatsCount={canInvite ? 1 : emptySeatsCount}
            users={companyGuests}
            changeUserRole={changeUserRole}
            deleteUser={deleteUser}
            addUserButtonClick={addGuestButtonClick}
            userRoles={guestRoles}
            disableEditUserList={disableEditUserList}
            canInvite={canInvite}
            resendInvitation={resendInvitation}
          />
        );
    }
  };
  return (
    <Styled.Container>
      <Styled.Header>
        {customAvatarComponent ||
          <Avatar
            size={AVATAR_HEIGHT}
            name={companyName}
            avatar={avatar}
          />
        }
        <Styled.CompanyDescription>
          <H4 withEllipsis={true}>{companyName}</H4>
          <Text color={'turquoiseInversed'}>{usersCountString}</Text>
        </Styled.CompanyDescription>
      </Styled.Header>
      <Styled.FlowNavigation>
        <FlowNavigation
          items={tabsItems}
          TextComponent={Text}
          borderOverhang={5}
          height={5}
          marginRight={35}
        />
      </Styled.FlowNavigation>
      {getActiveTab(currentTab)}
    </Styled.Container>
  );
},
);


