import { HotKeys, Icons, Label } from '@kreo/kreo-ui-components';
import { ENTER_ESCAPE } from '@kreo/kreo-ui-components/constants/key-maps';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Styled } from './styled';

interface Props {
  value: string;
  fontSize?: number;
  changeValue: (value: string) => void;
  backgroundColor?: string;
  disableRenaming: boolean;
  stopPropagation?: boolean;
  isHoverBackground: boolean;
  isHoverEditIcon: boolean;
  getRenameMode?: (renameMode: boolean) => void;
}


export const LabelWithRename: React.FC<Props> = ({
  value,
  fontSize = 16,
  changeValue,
  backgroundColor = 'backgroundRush',
  disableRenaming,
  stopPropagation,
  isHoverBackground,
  isHoverEditIcon,
  getRenameMode,
}) => {
  const [renameMode, setRenameMode] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const containerRef = React.createRef<HTMLDivElement>();
  const inputRef = React.createRef<HTMLInputElement>();
  const closeHandler = useCallback(() => {
    containerRef.current.focus();
    setRenameMode(false);
    setInputValue(value);
  }, [setRenameMode, value, setInputValue, containerRef]);
  const applyRenameHandler = useCallback(() => {
    inputValue !== '' ? changeValue(inputValue) : setInputValue(value);
    setRenameMode(false);
  }, [inputValue, value, changeValue]);

  const onClick = useCallback((e) => {
    e.stopPropagation();
    if (!renameMode) {
      setRenameMode(true);
      setInputValue(value);
    }
  }, [ renameMode, value ]);

  const onDoubleClick = useCallback((e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (!renameMode) {
      setRenameMode(true);
      setInputValue(value);
    }
  }, [renameMode, value, stopPropagation]);

  const onChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, [setInputValue]);

  useEffect(() => {
    if (getRenameMode) {
      getRenameMode(renameMode);
    }
  }, [getRenameMode, renameMode]);

  const lineHeight = Math.ceil(fontSize * 1.5);

  const EditIcon = useMemo(() => {
    return isHoverEditIcon && (
      <Styled.EditIcon onClick={onClick}>
        <Icons.RenameSmall />
      </Styled.EditIcon>
    );
  }, [isHoverEditIcon, onClick]);

  const handlers = useMemo(() => {
    return {
      escape: closeHandler,
      enter: () => inputRef.current.blur(),
    };
  }, [closeHandler, inputRef]);

  return (
    <Styled.Container
      ref={containerRef}
      // onClick={onClick}
      onDoubleClick={onDoubleClick}
      disableRenaming={disableRenaming}
      isHoverBackground={isHoverBackground}
      backgroundColor={backgroundColor}
    >
      {renameMode ? (
        <HotKeys
          keyMap={ENTER_ESCAPE}
          handlers={handlers}
        >
          <Styled.Input
            ref={inputRef}
            fontSize={fontSize}
            value={inputValue}
            backgroundColor={backgroundColor}
            onChange={onChange}
            onDoubleClick={onDoubleClick}
            onBlur={applyRenameHandler}
            autoFocus={true}
            isHoverBackground={isHoverBackground}
            size={inputValue.length || 1}
            height={lineHeight}
          />
        </HotKeys>
      ) : (
        <Label value={value} fontSize={fontSize} beforeElement={EditIcon} />
      )}
    </Styled.Container>
  );
};
