export const valueFormatter = (column: any) => (param) => {
  const aggDepth = column.aggDepth;
  switch (aggDepth) {
    case 'All':
      return param.value;
    case 'LeafOnly': {
      if (!param.node.group || param.node.childrenAfterGroup.every(c => !c.group)) {
        return param.value;
      }
      return '';
    }
    case 'Dynamic': {
      const columnState = param.columnApi.getColumnState();
      const aggDepthTarget = column.aggDepthTarget;
      const groupIndexMap: Record<number, string> = {};
      columnState.forEach(c => {
        if (c.rowGroupIndex !== null) {
          groupIndexMap[c.rowGroupIndex] = c.colId;
        }
      });
      const targetColumn = columnState.find(c => c.colId === aggDepthTarget);
      if (targetColumn) {
        const targetRowGroupIndex = targetColumn.rowGroupIndex;
        if (!param.node.group) {
          return param.value;
        }
        const fieldColumnState = columnState.find(c => c.colId === `[${param.node.field}]`);
        if (!fieldColumnState) {
          return param.value;
        }
        const currentGroupIndex = fieldColumnState.rowGroupIndex;
        if (currentGroupIndex >= targetRowGroupIndex) {
          return param.value;
        } else {
          const leafNode = param.node.allLeafChildren[0];
          const value = leafNode?.data[aggDepthTarget.replace(/[\[\]]/g, '')];
          if (value === undefined || value === '') {
            let index = targetColumn.rowGroupIndex - 1;
            while (index >= 0) {
              const parenValue = leafNode?.data[groupIndexMap[index].replace(/[\[\]]/g, '')];
              if (parenValue !== undefined) {
                if (groupIndexMap[index] === `[${param.node.field}]`) {
                  return param.value;
                } else {
                  return '';
                }
              }
              index--;
            }
          } else {
            return '';
          }
        }
      }

      return '';
    }
    default:
      return param.value;
  }
};
