const prefix = '@drawings-annotation';

export const DrawingsAnnotationActionTypes = {
  SAVE_AI_ANNOTATION: `${prefix}/SAVE_AI_ANNOTATION`,
  SAVE_ALL_GEOMETRIES: `${prefix}/SAVE_ALL_GEOMETRIES`,
  UPDATE_POINT: `${prefix}/UPDATE_POINT`,
  GET_ANNOTATION_DATA: `${prefix}/GET_ANNOTATION_DATA`,
  UPDATE_GEOMETRY: `${prefix}/UPDATE_GEOMETRY`,
  ADD_INSTANCE: `${prefix}/ADD_INSTANCE`,
  REMOVE_NOT_SAVED_INSTANCES: `${prefix}/REMOVE_NOT_SAVED_INSTANCES`,
  RENAME_INSTANCES: `${prefix}/RENAME_INSTANCES`,
  REMOVE_INSTANCES: `${prefix}/REMOVE_INSTANCES`,
  LOAD_FULL_GEOMETRY: `${prefix}/LOAD_FULL_GEOMETRY`,
  REMOVE_PAGE_DATA: `${prefix}/REMOVE_PAGE_DATA`,
  CHANGE_INSTANCES_GEOMETRY_PARAM: `${prefix}/CHANGE_INSTANCES_STYLE_PARAM`,
  UPDATE_USER_IN_INSTANCES: `${prefix}/UPDATE_USER_IN_INSTANCES`,
  UPDATE_GROUP_IN_INSTANCES: `${prefix}/UPDATE_GROUP_IN_INSTANCES`,
};

