import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { CommentZoneStyle, DrawingsCanvasColors } from 'common/components/drawings/constants';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { VisibleEntity, VisibleEntityConfig } from '../../common';

interface CommentGeometry {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface Config extends VisibleEntityConfig<CommentGeometry> {
  layer: paper.Layer;
  fill: boolean;
}

export class CommentRect extends VisibleEntity<CommentGeometry, Config> {
  private _path: paper.Path.Rectangle;
  constructor(config: Config) {
    super(config);
    this._config.renderParamsContextObserver.subscribe(this.updateZoom, true);
  }

  public updateGeometry(geometry: CommentGeometry): void {
    if (this._path) {
      this._path.remove();
    }

    this.render(geometry);
  }


  public destroy(): void {
    if (this._path) {
      this._path.remove();
    }
    this._config.renderParamsContextObserver.unsubscribe(this.updateZoom);
  }

  protected render({ x1, x2, y1, y2 }: CommentGeometry): void {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    this._path = new paper.Path.Rectangle({
      topLeft: new paper.Point(x1, y1),
      bottomRight: new paper.Point(x2, y2),
      shadowColor: DrawingsCanvasColors.utilityHex,
      shadowBlur: CommentZoneStyle.shadowOffset,
      strokeColor: DrawingsCanvasColors.utility,
      strokeWidth: CommentZoneStyle.strokeWidth / zoom,
      dashArray: DrawingsCanvasUtils.scaleDashArray(CommentZoneStyle.dashArray, CommentZoneStyle.strokeWidth, zoom),
      radius: CommentZoneStyle.borderRadius.divide(zoom),
      fillColor: this._config.fill ? DrawingsCanvasColors.utilityFill : null,
    });
    this._path.addTo(this._config.layer);
  }

  @autobind
  private updateZoom(): void {
    if (!this._path) {
      return;
    }
    this._path.remove();
    this.render(this._config.geometry);
  }
}
