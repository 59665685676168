import { IconButton, Icons, Text, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import React from 'react';

import { HelpUserButton } from 'common/components/help-user-button';
import { RenderIf } from 'common/components/render-if';
import { MenuProperties } from '../menu-properties';
import { ClearDatabaseButton } from './clear-database-button';
import { CreateGroupButton } from './create-group-button';
import { Styled } from './styled';

export interface IDatabaseHeader {
  helpCentreLink: string;
  baseName: string;
  itemName: string;
  icon: React.ReactNode;
  videoLink?: string;
  onClickAddNew?: () => void;
  onClickAddGroup?: () => void;
}

interface Props {
  databasesHeader: IDatabaseHeader;
  menuTop: number;
  dumpDatabase: () => void;
}

interface ComponentState {
  isOpenMenu: boolean;
}

export class DatabasesHeader extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  public render(): JSX.Element {
    const {
      helpCentreLink,
      baseName,
      itemName,
      icon,
      onClickAddNew,
      onClickAddGroup,
    } = this.props.databasesHeader;

    return (
      <Styled.Header>
        <Styled.Icons>
          {icon}
          <Text fontSize={18}>{`${baseName} base`}</Text>
          <CreateGroupButton onClick={onClickAddGroup}/>
          <RenderIf condition={!!this.props.dumpDatabase}>
            <IconButton Icon={Icons.Export} width={20} height={20} onClick={this.props.dumpDatabase} />
          </RenderIf>
          <ClearDatabaseButton/>
        </Styled.Icons>
        <Styled.ButtonsContainer>
          <Styled.ButtonTextWrapper>
            <a href={helpCentreLink} target="_blank">
              <HelpUserButton Icon={Icons.HelpCenter2D} tooltipText="Help Centre" />
              <Text>{`How to create ${itemName}`}</Text>
            </a>
          </Styled.ButtonTextWrapper>
          <Styled.ButtonTextWrapper>
            <RectangleButton
              height={20}
              width={80}
              borderRadius={10}
              text={'+ New'}
              mood={'secondary'}
              onClick={onClickAddNew || this.openContextMenu}
            />
            {this.state.isOpenMenu && (
              <Styled.MenuContainer top={this.props.menuTop}>
                <MenuProperties closeContextMenu={this.closeContextMenu} />
              </Styled.MenuContainer>
            )}
          </Styled.ButtonTextWrapper>
        </Styled.ButtonsContainer>
      </Styled.Header>
    );
  }

  @autobind
  private openContextMenu(): void {
    this.setState({ isOpenMenu: true });
  }

  @autobind
  private closeContextMenu(): void {
    this.setState({ isOpenMenu: false });
  }
}
