import numeral from 'numeral';

export const numberUtils = {
  getNumeralFormatter(value: React.ReactText): Numeral {
    if (Math.abs(value as number) > 1e-6) {
      return numeral(value);
    } else {
      return numeral(0);
    }
  },
  epsilonEqual(num1: number, num2: number, epsilon: number): boolean {
    return Math.abs(num1 - num2) <= epsilon;
  },
  round: (value: number, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  },
};
