import * as React from 'react';

import { CompanyTab } from '../company-tab';
import { RoleData, UserData } from '../interfaces';
import { UsersTab } from '../user-tab';

interface Props {
  tabName: string;
  companyName: string;
  companyId: number;
  companyNameChange?: (name: string) => void;
  companyUsers: UserData[];
  maxUsersCount: number;
  emptySeatsCount: number;
  companyGuests: UserData[];
  canInvite: boolean;
  deleteCompany?: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  resendInvitation?: (email: string) => void;
  addUserButtonClick?: () => void;
  addGuestButtonClick?: () => void;
  userRoles: RoleData[];
  guestRoles: RoleData[];
  disableEditUserList: string[];
  addSeatsButtonClick?: () => void;
}

export const DEFAULT_TAB_NAMES = ['Company', 'Users', 'Guests'];

export const CompanyInfoTabs: React.FC<Props> = (props) => {
  const {
    companyName,
    companyId,
    companyNameChange,
    resendInvitation,
    companyUsers,
    maxUsersCount,
    companyGuests,
    canInvite,
    deleteCompany,
    changeUserRole,
    deleteUser,
    addUserButtonClick,
    addGuestButtonClick,
    userRoles,
    guestRoles,
    disableEditUserList,
    addSeatsButtonClick,
    emptySeatsCount,
    tabName,
  } = props;
  switch (tabName) {
    case DEFAULT_TAB_NAMES[0]:
      return (
        <CompanyTab
          companyName={companyName}
          companyId={companyId}
          companyNameChange={companyNameChange}
          deleteCompany={deleteCompany}
        />
      );
    case DEFAULT_TAB_NAMES[1]:
      return (
        <UsersTab
          emptySeatsCount={emptySeatsCount}
          users={companyUsers}
          maxUsersCount={maxUsersCount}
          changeUserRole={changeUserRole}
          deleteUser={deleteUser}
          addUserButtonClick={addUserButtonClick}
          userRoles={userRoles}
          disableEditUserList={disableEditUserList}
          canInvite={canInvite}
          addSeatsButtonClick={addSeatsButtonClick}
          resendInvitation={resendInvitation}
        />
      );
    default:
    case DEFAULT_TAB_NAMES[2]:
      return (
        <UsersTab
          maxUsersCount={1}
          emptySeatsCount={emptySeatsCount}
          users={companyGuests}
          changeUserRole={changeUserRole}
          deleteUser={deleteUser}
          addUserButtonClick={addGuestButtonClick}
          userRoles={guestRoles}
          disableEditUserList={disableEditUserList}
          canInvite={canInvite}
          resendInvitation={resendInvitation}
        />
      );
  }
};
