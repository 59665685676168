import { DropDownStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

import { BackdropStyled } from 'unit-2d-database/components/side-panel/components';


const InviteUsersContainer = styled.div`
  ${BackdropStyled.Backdrop} {
    z-index: 1002;
  }

  ${BackdropStyled.Container} {
    z-index: 1002;
    width: 640px;
  }
`;

const AddProjectUserPopupWrapper = styled.div`
  height: 100%;

  > div {
    height: 100%;
  }

  ${DropDownStyled.DropDownMenuContainer} {
    max-height: 135px;
  }
  ${DropDownStyled.ItemsContainer} {
    max-height: 100%;
    height: 100%;
  }
`;

export const Styled = {
  InviteUsersContainer,
  AddProjectUserPopupWrapper,
};
