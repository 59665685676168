import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React from 'react';
import { Text } from 'common/components/text';

import { ElementTooltip } from 'common/components/tooltip';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';

interface Props {
  removedText: string;
  tooltipText: string;
  mood: Mood;
  reset: () => void;
}

export const RemovableListState: React.FC<Props> = ({
  removedText,
  tooltipText,
  reset,
  mood,
}) => {
  return (
    <Styled.RemovedInstancesContainer>
      <Styled.ContentWrapper>
        <ElementTooltip
          position='top'
          speed='l'
          text={'reset'}
        >
          <IconButton
            Icon={Icons.UndoClassic}
            onClick={reset}
            width={20}
            height={20}
            mood={mood}
          />
        </ElementTooltip>
        <Text fontSize={14} lineHeight={16}>
          {removedText}
        </Text>
      </Styled.ContentWrapper>
      <ElementTooltip
        position='left'
        speed='l'
        text={tooltipText}
      >
        <Icons.Questions2D />
      </ElementTooltip>
    </Styled.RemovedInstancesContainer>
  );
};
