import { Icons, SvgComponent } from '@kreo/kreo-ui-components';

import { State } from 'common/interfaces/state';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import {
  CommentaryDrawingTarget,
  CommentaryItemsPageTarget,
  CommentarySpreadsheetReportPageTarget,
  CommentaryTakeOffPageTarget,
  CommentaryTarget,
} from 'unit-2d-comments/interfaces';


function drawingNameSelector(state: State, target: CommentaryDrawingTarget): string {
  return state.drawings.drawingsInfo[target.pageId]
    ? state.drawings.drawingsInfo[target.pageId].name
    : 'Undefined Page';
}

function spreadsheetPageSelector(state: State, target: CommentarySpreadsheetReportPageTarget): string {
  const report = state.twoD.reportPages.find(r => r.id === target.reportPageId);
  return report
    ? target.address
      ? `${report.name}!${target.address}`
      : `${report.name}`
    : 'Undefined Spreadsheet Page';
}

function takeoffPageSelector(state: State, target: CommentaryTakeOffPageTarget): string {
  const report = state.twoD.reportPages.find(r => r.id === target.reportPageId);
  return report
    ? `${report.name}`
    : 'Undefined Takeoff Page';
}

function itemsPageSelector(state: State, target: CommentaryItemsPageTarget): string {
  const report = state.twoD.reportPages.find(r => r.id === target.reportPageId);
  return report
    ? `${report.name}`
    : 'Undefined Items Page';
}

interface TargetInfo {
  icon: SvgComponent;
  tooltip: string;
  nameSelector: (state: State, target: CommentaryTarget) => string;
}

export const TargetUtils: Record<CommentaryTargetType, TargetInfo> = {
  [CommentaryTargetType.Project]: {
    icon: Icons.Page,
    tooltip: 'Comment added to the whole project',
    nameSelector: (state) => state.projects.currentProject?.name,
  },
  [CommentaryTargetType.Drawing]: {
    icon: Icons.DrawingSmall,
    tooltip: 'Comment added to the drawing area',
    nameSelector: drawingNameSelector,
  },
  [CommentaryTargetType.DrawingWithPoint]: {
    icon: Icons.DrawingSmall,
    tooltip: 'Comment added to the drawing area',
    nameSelector: drawingNameSelector,
  },
  [CommentaryTargetType.DrawingWithRect]: {
    icon: Icons.DrawingSmall,
    tooltip: 'Comment added to the drawing area',
    nameSelector: drawingNameSelector,
  },
  [CommentaryTargetType.SpreadsheetReportPage]: {
    icon: Icons.Table2D,
    tooltip: 'Comment added to the report',
    nameSelector: spreadsheetPageSelector,
  },
  [CommentaryTargetType.ItemsReportPage]: {
    icon: Icons.ItemsList,
    tooltip: 'Comment added to the report',
    nameSelector: itemsPageSelector,
  },
  [CommentaryTargetType.TakeoffReportPage]: {
    icon: Icons.MeasurementsList,
    tooltip: 'Comment added to the report',
    nameSelector: takeoffPageSelector,
  },
  [CommentaryTargetType.ReportPage]: {
    icon: Icons.Table2D,
    tooltip: 'Comment added to the report',
    nameSelector: spreadsheetPageSelector,
  },
};
