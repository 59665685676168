import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { DrawingsSelectAggregationGroup, WizzardToolsState } from '../interfaces';
import { DrawingInstancesAggregationUtils } from '../utils';
import { DrawingsMeasureUtils } from '../utils/drawings-measure-utils';
import { useDrawModeApi } from './use-draw-mode-api';

export function useNewInstanceAggregationType(): DrawingsSelectAggregationGroup {
  const { drawMode } = useDrawModeApi();
  const wizzard = useSelector<State, WizzardToolsState>(s => {
    return s.drawings.wizzardTools;
  });

  return useMemo(() => {
    const instanceType = DrawingsMeasureUtils.getInstanceTypeFromDrawMode({
      drawMode,
      wizzard,
    });
    return DrawingInstancesAggregationUtils.INSTANCE_TYPE_TO_AGGREGATION[instanceType];
  }, [wizzard, drawMode]);
}
