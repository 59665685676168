import styled from 'styled-components';

import { Text } from '../text';


const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 100%;
  border-radius: 40px;
  &, * {
    box-sizing: border-box;
  }
  > button {
    margin-left: auto;
  }
  > ${Text} {
    margin-bottom: 40px;
  }
`;

const FieldNames = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Styled = {
  Container,
  FieldNames,
};
