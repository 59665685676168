import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsRenderParams } from 'common/components/drawings/interfaces';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsGeometryEntityPoint, DrawingsGeometryEntityPointConfig } from '../../../drawings-geometry-entities';

export interface DrawingsGeometryBucketAiPointConfig
  extends DrawingsGeometryEntityPointConfig<{ isPositive: boolean }> {
  metadata: { isPositive: boolean };
  measuresViewParams: ContextObserver<MeasuresViewSettings>;
}


export class DrawingsGeometryBucketAiPoint
  extends DrawingsGeometryEntityPoint<{ isPositive: boolean }, DrawingsGeometryBucketAiPointConfig> {
  private _symbol: paper.Group;

  protected override render(): void {
    super.render();
    this._config.measuresViewParams.subscribe(this.changeTextRenderParams);
  }

  @autobind
  protected override changeVisualData(params: DrawingsRenderParams): void {
    super.changeVisualData(params);
    if (this._symbol) {
      this._symbol.remove();
    }
    const halfWidth = (this._config.radius - 1) / 2 / params.zoom;
    const pointToAdd = new paper.Point(halfWidth, 0);

    const horizontal = new paper.Path([
      this._position.subtract(pointToAdd),
      this._position.add(pointToAdd),
    ]);

    if (this._config.metadata.isPositive) {
      const pointToAddVert = new paper.Point(0, halfWidth);
      const vertical = new paper.Path([
        this._position.subtract(pointToAddVert),
        this._position.add(pointToAddVert),
      ]);
      this._symbol = new paper.Group([horizontal, vertical]);
    } else {
      this._symbol = new paper.Group([horizontal]);
    }
    this._symbol.strokeWidth = 2 / params.zoom;
    this._symbol.strokeColor = DrawingsCanvasColors.white;
    this._symbol.addTo(this._group);
    this._symbol.strokeCap = 'round';
    const rotation = this._config.measuresViewParams.getContext().rotation;
    if (rotation === 90 || rotation === 270) {
      this._symbol.rotate(90);
    }
  }

  @autobind
  protected changeTextRenderParams({ rotation }: MeasuresViewSettings): void {
    if (rotation === 90 || rotation === 270) {
      if (this._symbol) {
        this._symbol.rotate(90);
      }
    } else {
      if (this._symbol) {
        this._symbol.rotate(-90);
      }
    }
  }
}
