export enum CommentaryTargetType {
  Project = 'Project',
  Drawing = 'Page',
  DrawingWithPoint = 'PageDrawing',
  DrawingWithRect = 'PageDrawingRect',
  ReportPage = 'ReportPage',
  SpreadsheetReportPage = 'SpreadsheetReportPage',
  TakeoffReportPage = 'TakeoffReportPage',
  ItemsReportPage = 'ItemsReportPage',
}
