export const DrawingDialogs = {
  DELETE_MEASUREMENTS_DIALOG: 'DELETE_MEASUREMENTS_DIALOG',
  PDF_MANAGER_DIALOG: 'PDF_MANAGER_DIALOG',
  PDF_UPLOAD_DIALOG: 'PDF_UPLOAD_DIALOG',
  AUTO_MEASURE_INSTRUCTION_DIALOG: 'AUTO_MEASURE_INSTRUCTION_DIALOG',
  BUY_AUTO_MEASURE_DIALOG: 'BUY_AUTO_MEASURE_DIALOG',
  USER_ANNOTATION_INSTRUCTION_DIALOG: 'USER_ANNOTATION_INSTRUCTION_DIALOG',
  BUY_USER_ANNOTATION_DIALOG: 'BUY_USER_ANNOTATION_DIALOG',

  SELECT_SCALE_PAGES: 'SELECT_SCALE_PAGES',
  SELECT_COMPARE_PAGE: 'SELECT_COMPARE_PAGE',

  EXPORT_NO_PAGES_DIALOG: 'EXPORT_NO_PAGES_DIALOG',

  UNSAVED_CHANGES_DIALOG: 'UNSAVED_CHANGES_DIALOG',

  DUPLICATE_DRAWING_DIALOG: 'DUPLICATE_DRAWING_DIALOG',

  MOVE_TO_DIALOG: 'MOVE_TO_DIALOG',
  RENAME_TOOL_DIALOG: 'renameToolDialogName',

  NOT_ENOUGH_GEOMETRIES_DIALOG: 'NOT_ENOUGH_GEOMETRIES_DIALOG',
  TOO_MANY_GEOMETRIES_DIALOG: 'TO_MANY_GEOMETRIES_DIALOG',

  NOT_SCALED_DIALOG: 'NOT_SCALED_DIALOG',
  AUTO_MEASURE_TYPE_DIALOG: 'AUTO_MEASURE_TYPE_DIALOG',
};
