import * as Ag from 'ag-grid-community';
import { SEPARATOR_PROPERTY_KEY, TREE_PROPERTY_POSTFIX } from '../../items-helper/utils';

export const filterValueGetter = (param: Ag.ValueGetterParams): any => {
  const data = param.data;
  const value = data ? data[param.colDef.field] : '';
  if (value && value.toString().startsWith(TREE_PROPERTY_POSTFIX)) {
    return value
      ? value.split(SEPARATOR_PROPERTY_KEY)[2]
      : '';
  } else {
    return value;
  }
};
