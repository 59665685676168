import { Icons } from '@kreo/kreo-ui-components';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { CompaniesSelectWithTheme } from 'common/components/companies-select-with-theme';
import { PageHeader } from 'common/components/page-header';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { SpinnerPage } from 'routes/spinner-page';
import { AppUrls } from '../../../../routes/app-urls';
import { CompanySubscriptionModel } from '../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../account/selectors';
import { SubscriptionActions } from '../../actions/creators';
import {
  SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS,
} from '../../components/subscription-dialog/select-subscription-plan-dialog';
import { SubscriptionEditor } from '../../components/subscription-dialog/subscription-editor';
import {
  useEditSubscriptionText,
  useAfterApplyCallback,
  useHideStatus,
  useRenewCanceledArhivedSubscriptionEffect,
  useIsArchivedMemo,
  useHandleChangePlanCallback,
} from './hooks';
import { RenewArchivedDialog } from './renew-arhived-dialog';
import { RenewConfirmDialog } from './renew-confirm-dialog';
import { Styled } from './styled';

interface PageProps extends RouteComponentProps<{}> {}

export const RenewSubscriptionPage: React.FC<PageProps> = memo(() => {
  const dispatch = useDispatch();
  const subscription = useSelector<State, CompanySubscriptionModel>(AccountSelectors.currentSubscription);
  const isArhived = useIsArchivedMemo(subscription);
  const isPausedSubscription = useMemo(() => AccountSelectors.isPausedSubscription(subscription), [subscription]);
  const [showLoading, setShowLoading] = useState(false);
  const [isPlanChanged, handleChangePlan] = useHandleChangePlanCallback();
  const [submitButtonText, headerText] = useEditSubscriptionText(subscription, isArhived, isPlanChanged);
  const [hideCancel, hidePause, isSkipSubscription] = useHideStatus(subscription, isArhived, isPlanChanged);
  const [handleAfterApply] = useAfterApplyCallback(subscription);
  useRenewCanceledArhivedSubscriptionEffect(subscription, isArhived);
  const shouldChangePlan = isArhived && !isPausedSubscription && !isPlanChanged;
  const changePlan = useCallback(() => {
    dispatch(KreoDialogActions.openDialog(SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS.DIALOG_NAME));
    dispatch(SubscriptionActions.endUpdateSubscription());
  }, [dispatch]);
  const handleClickApply = shouldChangePlan
    ? changePlan
    : handleAfterApply;
  const showSpinner = useCallback(() => {
    setShowLoading(true);
  }, []);

  return (
    <Styled.Container>
      <Styled.Header>
        <PageHeader
          redirectUrl={AppUrls.products.path}
          logoSrc="/static/icons/kreo-logo-description.svg"
          Icon={Icons.KreoLogo2D}
          hideButton={true}
          size={50}
        >
          <CompaniesSelectWithTheme />
        </PageHeader>
      </Styled.Header>
      <Styled.Main>
        <SubscriptionEditor
          submitButtonText={submitButtonText}
          skipUpdate={true}
          afterApply={handleClickApply}
          hideCancelSubscribtion={hideCancel}
          hidePauseSubscribtion={hidePause}
          isSkipUpdateSubscription={isSkipSubscription}
          reactivate={true}
          headerText={headerText}
          onChangeSubscription={handleChangePlan}
          isArhived={isArhived}
        />
      </Styled.Main>
      <RenewArchivedDialog />
      <RenewConfirmDialog afterApply={showSpinner}/>
      <RenderIf condition={showLoading}>
        <SpinnerPage />
      </RenderIf>
    </Styled.Container>
  );
});

