import React, { useContext } from 'react';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { GlobalKeyboardEventsControllerContext } from 'common/components/global-keyboard-events-controller/context';
import { UndoRedoContextApiProps } from 'common/undo-redo';
import { UndoRedoContext } from 'common/undo-redo/undo-redo-context';
import { usePersistedStorageValue } from 'persisted-storage/hooks';
import { useAddCommentFromDrawing } from '../drawings-canvas-menus/hooks';
import {
  ContextObserver,
  DrawingsCommonPointsContext,
  DrawingsElementAndGroupOperationsContext,
  DrawingsElementAndGroupOperationsContextProps,
  DrawingsGeometryOperationsApiContext,
  DrawingsGeometryOperationsContext,
  DrawingsGeometryOperationsFullContext,
  DrawingsRendererApiContext,
  DrawingsRendererApiContextSetter,
  DrawingsRendererApiFullContextProps,
} from '../drawings-contexts';
import { DrawingsSelectionContextProps } from '../drawings-contexts/drawings-selection-context';
import { DrawingsSelectionContext } from '../drawings-contexts/drawings-selection-context/drawings-selection-context';
import { DrawingsCopyPasteBuffer } from '../drawings-geometry';
import { NewDrawingSettings, StartCreateCommentPayload } from '../drawings-geometry/interfaces';
import { LayoutFocusedContext } from '../drawings-layout-container';
import { useNewInstancesSettings, useUpdateNewInstanceSettings } from '../hooks';
import { useOneClickAreaHoverObserver } from '../hooks/ability/use-one-click-area-observer';
import { useMagicSearchContextObserver } from '../hooks/magic-search/use-magic-search-context-observer';
import {
  DrawingSettingsApi,
  useDrawingSettingsWithUpdate,
} from '../hooks/settings/use-drawing-settings';
import { SnappingSettings, useSnappingFullApi, useSnappingSettings } from '../hooks/settings/use-snappings-settings';
import { MagicSearchState } from '../interfaces';
import { DrawingsCommonPointsContextProps } from '../interfaces/drawings-common-points-context-props';
import { AiSuggestSettingApi, useAiSuggestSetting } from '../layout-components/top-control-group/ai-suggest-button';
import { useCopyPasteBuffer } from './hooks';

export interface DrawingsCanvasContextWrapperProps
  extends GlobalKeyboardEventsControllerContextProps,
  DrawingsSelectionContextProps,
  DrawingsElementAndGroupOperationsContextProps,
  DrawingsRendererApiFullContextProps,
  DrawingsGeometryOperationsFullContext,
  UndoRedoContextApiProps,
  DrawingsCommonPointsContextProps,
  SnappingSettings,
  DrawingSettingsApi,
  AiSuggestSettingApi {
    layoutFocused: boolean;
    offsetIsStroke: boolean;
    newInstancesSettings: NewDrawingSettings;
    canEdit3dMeasurements: boolean;
    oneClickAreaHoverObserver: ContextObserver<boolean>;
    copyPasteBuffer: DrawingsCopyPasteBuffer;
    magicSearchContextObserver: ContextObserver<MagicSearchState>;
    toggleSnapping: () => void;
    startAddComment: (payload: StartCreateCommentPayload) => void;
    updateNewDrawingSettings: (newSettings: Partial<NewDrawingSettings>) => void;
}


export function withDrawingsCanvasContextWrapper<P>(
  Component: React.ComponentType<P & DrawingsCanvasContextWrapperProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof DrawingsCanvasContextWrapperProps>>> {
  return (props: P) => {
    // todo: remove any after https://kreosoftware.atlassian.net/browse/KREOP-11436
    const keyboardListenerProps = useContext(GlobalKeyboardEventsControllerContext);
    const geometryOperationsProps = useContext(DrawingsGeometryOperationsContext);
    const geometryOperationsApi = useContext(DrawingsGeometryOperationsApiContext);
    const rendererSetter = useContext(DrawingsRendererApiContextSetter);
    const rendererApi = useContext(DrawingsRendererApiContext);
    const elementOperationsContext = useContext(DrawingsElementAndGroupOperationsContext);
    const selectionContext = useContext(DrawingsSelectionContext);
    const undoRedoContext = useContext(UndoRedoContext);
    const commonPointContext = useContext(DrawingsCommonPointsContext);
    const newInstancesContext = useNewInstancesSettings();
    const layoutFocused = useContext(LayoutFocusedContext);
    const snappingSettings = useSnappingSettings();
    const drawingSettings = useDrawingSettingsWithUpdate();
    const aiSuggestSetting = useAiSuggestSetting();
    const offsetIsStroke = usePersistedStorageValue('drawingOffsetIsStroke');
    const canEdit3dMeasurements = useAbility(Operation.Update, Subject.Takeoff2dMeasurement3d);
    const copyPasteBuffer = useCopyPasteBuffer();
    const magicSearchContext = useMagicSearchContextObserver();
    const updateNewDrawingSettings = useUpdateNewInstanceSettings();
    const canUseOneClickHoverObserver = useOneClickAreaHoverObserver();
    const { toggleSnapping } = useSnappingFullApi();
    const startAddComment = useAddCommentFromDrawing();
    return (
      <Component
        layoutFocused={layoutFocused}
        {...props}
        {...keyboardListenerProps}
        {...geometryOperationsProps}
        {...geometryOperationsApi}
        {...elementOperationsContext}
        {...selectionContext}
        {...drawingSettings}
        {...undoRedoContext}
        {...commonPointContext}
        setRendererApi={rendererSetter}
        {...rendererApi}
        {...snappingSettings}
        {...aiSuggestSetting}
        offsetIsStroke={offsetIsStroke}
        newInstancesSettings={newInstancesContext}
        canEdit3dMeasurements={canEdit3dMeasurements}
        copyPasteBuffer={copyPasteBuffer}
        magicSearchContextObserver={magicSearchContext}
        updateNewDrawingSettings={updateNewDrawingSettings}
        oneClickAreaHoverObserver={canUseOneClickHoverObserver}
        toggleSnapping={toggleSnapping}
        startAddComment={startAddComment}
      />);
  };
}
