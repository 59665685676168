import { Constants } from '@kreo/kreo-ui-components';
import styled from 'styled-components';
import { SpinnerStyled } from 'common/components/spinner';


const Container = styled.div<{ isSelected: boolean, temporary: boolean }>`
  position: absolute;
  background-color: ${p => p.temporary
    ? Constants.Colors.GENERAL_COLORS.green
    : p.isSelected
      ? p.theme.color.turquoise
      : p.theme.color.backgroundRush};
  box-shadow: 0 0 0 2px ${p => p.theme.color.turquoise};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 20px 5px;
  transform: translateY(-100%);
  cursor: pointer;
  transition: box-shadow 0.35s ease;

  > div {
    cursor: pointer;
  }

  &:hover {
    box-shadow: 0 0 0 4px ${p => p.theme.color.turquoise};
  }

  ${SpinnerStyled.Spinner} {
    background-color: transparent;
  }
`;

export const Styled = {
  Container,
};
