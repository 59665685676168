import autobind from 'autobind-decorator';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { Styled } from './styled';


interface Props {
  emergencePlace?: string;
  width?: number;
  onExit?: () => void;
}


class Menu extends React.Component<Props> {
  public render(): React.ReactNode {
    const { children, emergencePlace, width } = this.props;
    return (
      <Styled.Dropdown emergencePlace={emergencePlace} width={width}>
        {children}
      </Styled.Dropdown>
    );
  }

  @autobind
  public handleClickOutside(): void {
    if (this.props.onExit) {
      this.props.onExit();
    }
  }
}

export const SmallMenuList = onClickOutside(Menu);
