import { IconButton, Icons } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { RoleData, UserData, UserMenuInfo } from '../../interfaces';
import { Styled } from '../../styled';
import { UserMenu } from './user-menu';


interface Props {
  userMenuInfo: UserMenuInfo;
  user: UserData;
  disableEditUserList: string[];
  userRoles: RoleData[];
  closeMenu: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  menuButtonClick?: (target: HTMLElement, email: string) => void;
}

export const UserMenuControl: React.FC<Props> = ({
  userMenuInfo,
  user,
  disableEditUserList,
  changeUserRole,
  deleteUser,
  menuButtonClick,
  closeMenu,
  userRoles,
}) => {
  const menuButtonClickHandler = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => menuButtonClick(e.target as HTMLElement, user.email),
    [menuButtonClick, user.email],
  );

  return (
    <Styled.UserMenu>
      {(changeUserRole || deleteUser) && !disableEditUserList.includes(user.email) &&
        <IconButton onClick={menuButtonClickHandler} Icon={Icons.Menu} />
      }
      {userMenuInfo && userMenuInfo.email === user.email &&
        <UserMenu
          user={user}
          userMenu={userMenuInfo}
          closeMenu={closeMenu}
          deleteUser={deleteUser}
          userRoles={userRoles}
          changeUserRole={changeUserRole}
        />
      }
    </Styled.UserMenu>
  );
};
