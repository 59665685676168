import { Avatar, RectangleButton } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { Text, TinyText } from 'common/components/text';
import { RoleData, UserData, UserMenuInfo } from '../../interfaces';
import { Styled } from '../../styled';
import { UserMenuControl } from '../user-menu-control';

interface Props {
  userMenuInfo: UserMenuInfo;
  user: UserData;
  disableEditUserList: string[];
  userRoles: RoleData[];
  canInvite: boolean;
  resendInvitation: (email: string) => void;
  closeMenu: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  menuButtonClick?: (target: HTMLElement, email: string) => void;
}

const UserComponent: React.FC<Props> = ({
  userMenuInfo,
  user,
  disableEditUserList,
  canInvite,
  changeUserRole,
  deleteUser,
  menuButtonClick,
  closeMenu,
  userRoles,
  resendInvitation,
}) => {
  const resendInvitationHandler = useCallback(() => {
    if (!user.isAccepted) {
      resendInvitation(user.email);
    }
  }, [resendInvitation, user.email, user.isAccepted]);

  const title = useMemo(() => {
    return user.name.length > 30
      ? user.name
      : undefined;
  }, [user.name]);

  return (
    <Styled.User>
      <Avatar name={user.name} size={40} avatar={user.avatar} />
      <Styled.UserTextInfo title={title}>
        <Text withEllipsis={true}>{user.name}</Text>
        <TinyText color={'turquoiseInversed'}>{user.email}</TinyText>
      </Styled.UserTextInfo>
      <Styled.UserRightSide>
        {
          user.isAccepted || !canInvite ? (
            <Styled.UserRole onClick={resendInvitationHandler}>
              <TinyText>{user.accountRole}</TinyText>
            </Styled.UserRole>
          ) : (
            <RectangleButton
              height={30}
              width={110}
              fontSize={12}
              mood='secondary'
              text='Resend'
              onClick={resendInvitationHandler}
            />
          )
        }
        <UserMenuControl
          menuButtonClick={menuButtonClick}
          userMenuInfo={userMenuInfo}
          user={user}
          userRoles={userRoles}
          closeMenu={closeMenu}
          deleteUser={deleteUser}
          changeUserRole={changeUserRole}
          disableEditUserList={disableEditUserList}
        />
      </Styled.UserRightSide>
    </Styled.User>
  );
};

export const User = React.memo(UserComponent);
