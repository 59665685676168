import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ProjectHotkey } from 'common/components/drawings/utils/hotkey-utils';
import {
  GlobalKeyboardEventsControllerContextProps,
  withGlobalKeyboardEventsController,
} from 'common/components/global-keyboard-events-controller';
import { ConstantFunctions } from 'common/constants/functions';
import { ProjectType } from 'common/constants/project-type';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AppUrls } from 'routes/app-urls';
import { Create2dProjectDialog, CREATE_2D_PROJECT_DIALOG_NAME } from '../../components/dialog';
import { ConfirmationDialog } from '../../components/dialog/confirmation-dialog';
import { withAnalyticsContext, AnalyticsProps } from '../../utils/posthog/analytics-wraper';
import { MetricNames } from '../../utils/posthog/metric-names';
import { TwoDNavigation } from '../2d-navigation';
import { ProjectMenuTab } from '../2d-navigation/2d-navigation';
import { TwoDProjectsContent } from '../2d-projects-page/2d-projects-content';
import { AccountActions } from '../account/actions/creators';
import { Company } from '../account/interfaces/company';
import { OwnCompany } from '../account/interfaces/own-company';
import { CreateOrganizationFormData } from '../empty-organizations/components/create-organization-dialog';
import { PersistedStorageActions } from '../persisted-storage/actions/creators';
import { ProjectsActions } from '../projects/actions/creators/common';
import { ProjectsFolderActions } from '../projects/actions/creators/projects-folder';
import { SUBSCRIPTION__DIALOG } from '../subscription/components/subscription-dialog';


interface PageStateProps {
  companyId: number;
  companies: Company[];
  selectedCompany: Company;
  isCollapseMenu: boolean;
  ownCompany: OwnCompany;
}

interface PageDispatchProps {
  showDialog: (name: string, data?) => void;
  closeDialog: (dialogName: string) => void;
  createProject: () => void;
  selectCompany: (company: Company) => void;
  toggleProjectMenu: () => void;
  createCompany: (company: CreateOrganizationFormData) => void;
  goToProjects: () => void;
}

interface PageProps extends
  GlobalKeyboardEventsControllerContextProps,
  PageStateProps,
  PageDispatchProps,
  AbilityAwareProps,
  AnalyticsProps { }


const TEMPLATES_ADDON_DIALOG = 'TEMPLATES_ADDON_DIALOG';

class TwoDTemplatesPageComponent extends React.Component<PageProps> {
  public componentDidMount(): void {
    if (this.props.ability.cannot(Operation.Manage, Subject.Project2DTemplates)) {
      this.openAddonDialog();
    }
    this.props.addKeyDownEventListener(ProjectHotkey.OpenCreateProjectDialog, this.showDialog);
  }

  public componentDidUpdate(): void {
    if (this.props.ability.cannot(Operation.Read, Subject.Project2DTemplates)) {
      this.props.goToProjects();
      return;
    }
  }

  public componentWillUnmount(): void {
    this.props.removeKeyDownEventListener(ProjectHotkey.OpenCreateProjectDialog, this.showDialog);
  }

  public render(): React.ReactNode {
    const { ability } = this.props;

    const canCreateTemplate = ability.can(Operation.Create, Subject.Project2DTemplates);

    const templatesAddonText = ability.can(Operation.Manage, Subject.Billing)
      ? 'To be able to use the feature, add the Templates add-on to your account first.'
      : 'Contact your company owner to add the Templates add-on, please.';

    return (
      <TwoDNavigation
        pageTitle='Templates'
        isCreateButtonHide={false}
        createButtonDisable={!canCreateTemplate}
        createButtonText={'Create Template'}
        onClickCreateButton={this.openCreate2dProjectDialog}
        activeTabIndex={ProjectMenuTab.Templates}
      >
        <Create2dProjectDialog onSubmit={this.onSubmit2d} sendEvent={this.sendEvent}/>
        <TwoDProjectsContent projectType={ProjectType.Project2dTemplate} />
        <ConfirmationDialog
          name={TEMPLATES_ADDON_DIALOG}
          title={'The Templates feature is not available.'}
          text={templatesAddonText}
          cancelButtonText='Okay'
          zIndex={1003}
        />
      </TwoDNavigation>
    );
  }

  @autobind
  private sendEvent(params: { name: string, files: string }): void {
    this.props.sendEvent(MetricNames.projects.createProjectTemplate, params);
  }

  @autobind
  private onSubmit2d(): void {
    this.props.createProject();
    this.props.closeDialog(CREATE_2D_PROJECT_DIALOG_NAME);
  }

  @autobind
  private openCreate2dProjectDialog(): void {
    const canUseTemplates = this.props.ability.can(Operation.Manage, Subject.Project2DTemplates);
    this.props.showDialog(CREATE_2D_PROJECT_DIALOG_NAME, {
      projectType: ProjectType.Project2dTemplate,
      canUseTemplates,
    });
  }

  @autobind
  private showDialog(e: KeyboardEvent): void {
    ConstantFunctions.stopEvent(e);
    this.openCreate2dProjectDialog();
  }

  @autobind
  private openAddonDialog(): void {
    const { showDialog, ability } = this.props;
    if (ability.can(Operation.Manage, Subject.Billing)) {
      showDialog(SUBSCRIPTION__DIALOG);
    }
    showDialog(TEMPLATES_ADDON_DIALOG);
  }
}

const mapStateToProps = (state: State): PageStateProps => {
  const ownCompany = state.account.ownedCompany;
  const selectedCompany = state.account.selectedCompany;
  const companyId = selectedCompany ? selectedCompany.id : null;

  return {
    companyId,
    companies: state.account.companies,
    selectedCompany: state.account.selectedCompany,
    isCollapseMenu: state.persistedStorage.isCollapseMenu,
    ownCompany,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): PageDispatchProps => {
  return {
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    closeDialog: dialogName => dispatch(KreoDialogActions.closeDialog(dialogName)),
    createProject: () => dispatch(ProjectsActions.createProject()),
    selectCompany: company => {
      dispatch(AccountActions.selectCompany(company));
      dispatch(ProjectsFolderActions.setCurrentFolder(null));
    },
    toggleProjectMenu: () => dispatch(PersistedStorageActions.toggleProjectMenu()),
    createCompany: company => dispatch(AccountActions.createCompany(company)),
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
  };
};

const reduxConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const TwoDTemplatesPage = reduxConnector(
  withAbilityContext(withGlobalKeyboardEventsController(
    withAnalyticsContext(TwoDTemplatesPageComponent),
  )),
);
