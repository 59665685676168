import produce from 'immer';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'uuid';

import { Mood } from 'common/enums/mood';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { EMAIL } from '../../../constants/regex-templates';
import { TempInvitePeople } from '../../../units/subscription/store/interface';
import { RoleData } from '../company-info/interfaces';
import { RenderIf } from '../render-if';
import { InviteField } from './invite-field';
import { ManageButtons } from './manage-buttons';
import { Styled } from './styled';


const DUPLICATES_EMAILS_DIALOG = 'duplicatesEmailsDialog';
const DUPLICATES_YOUR_EMAIL_DIALOG = 'duplicatesYourEmailDialog';

export interface FormsData {
  id: string;
  email: string;
  role: string;
}

interface Props {
  onSubmit: (formsData: Array<{ email: string, role: RoleData }> | FormsData[]) => void;
  inviteUsersButtonText: string;
  roles?: string[];
  userLimit?: number;
  hiddenSkipButton?: boolean;
  userRoles?: RoleData[];
}

export const InvitePeopleForm = React.memo<Props>(({
  userLimit,
  roles,
  userRoles,
  hiddenSkipButton,
  inviteUsersButtonText,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const userEmail = useSelector<State, string>(state => state.account.email);
  const invitedPeople = useSelector<State, TempInvitePeople[]>(state => state.subscription.tempInvitePeople);
  const [formsData, setFormsData] = useState<FormsData[]>([]);
  const [duplicatesEmail, setDuplicatesEmail] = useState<string>('');
  const [dialogKey, setDialogKey] = useState<string>(DUPLICATES_EMAILS_DIALOG);
  const [scrollActive, setScrollActive] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const canAddUser = useMemo(() => !userLimit || userLimit > formsData.length, [formsData.length, userLimit]);
  const optionsDialog = useMemo(() => ({
    [DUPLICATES_EMAILS_DIALOG]: {
      name: DUPLICATES_EMAILS_DIALOG,
      title: `You have entered duplicated email`,
      text: `You've entered email "${duplicatesEmail}"  twice in the list of teammates.
        Press "Cancel" if you want to enter other teammates email.
        Press "Continue" to invite all teammates without duplication.`,
    },
    [DUPLICATES_YOUR_EMAIL_DIALOG]: {
      name: DUPLICATES_YOUR_EMAIL_DIALOG,
      title: `You have entered your email`,
      text: `You've entered your email "${userEmail}" in the list of teammates.
        Press "Cancel" if you want to enter your teammates email. Press "Continue" to invite all other teammates.`,
    },
  }), [duplicatesEmail, userEmail]);

  const scrollEventTracking = useCallback(() => {
    if (containerRef.current) {
      const hasScroll = containerRef.current.scrollHeight > containerRef.current.clientHeight;
      setScrollActive(hasScroll);
    }
  }, []);

  useEffect(() => {
    scrollEventTracking();
    window.addEventListener('resize', scrollEventTracking);

    return () => {
      window.removeEventListener('resize', scrollEventTracking);
    };
  }, [formsData, scrollEventTracking]);

  const cleanForm = useCallback(() => {
    return { id: uuid.v4(), email: undefined, role: roles[roles.length - 1] };
  }, [roles]);

  useEffect(() => {
    if (invitedPeople && invitedPeople.length) {
      setFormsData(invitedPeople);
    } else {
      const numberOfForms = userLimit !== undefined ? 1 : 3;
      setFormsData(Array.from({ length: numberOfForms }, cleanForm));
    }
  }, [cleanForm, invitedPeople, userLimit]);

  const moreClick = useCallback(() => {
    setFormsData([...formsData, cleanForm()]);
  }, [cleanForm, formsData]);

  const onEmailChange = useCallback((index, value) => {
    const newFormsData = produce(formsData, (s) => {s[index].email = value;});
    setFormsData(newFormsData);
  }, [formsData]);

  const onRoleChange = useCallback((index: number, roleIndex: number) => {
    const newRole = roles[roleIndex];
    const newFormsData = produce(formsData, (s) => {s[index].role = newRole;});
    setFormsData(newFormsData);
  }, [formsData, roles]);

  const deleteForm = useCallback((index) => {
    if (formsData.length === 1 && formsData[0].email === '') {
      return;
    }
    if (index === 0 && formsData.length === 1) {
      onEmailChange(index, '');
    } else {
      setFormsData(formsData.filter((_form, i) => i !== index));
    }
  }, [formsData, onEmailChange]);

  const validateEmail = useCallback((email: string): boolean => {
    if (email) {
      return EMAIL.test(email);
    } else {
      return true;
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const isInvalidEmail = formsData.some(form => !validateEmail(form.email));
    if (isInvalidEmail) {
      return;
    }

    if (onSubmit) {
      const filterFormsData = (shouldFilterDuplicates: boolean): any => {
        return arrayUtils.filterMap(
          formsData,
          (form) => {
            const isValidEmail = !!form.email;
            const isNotUserEmail = form.email !== userEmail;
            return shouldFilterDuplicates ? isValidEmail && isNotUserEmail : isValidEmail;
          },
          (form) => {
            if (userRoles) {
              const role = userRoles.find(roleObj => roleObj.name === form.role);
              return { ...form, role };
            }
            return form;
          },
        );
      };

      const shouldFilterDuplicates = dialogKey === DUPLICATES_YOUR_EMAIL_DIALOG;
      const newFormsData = filterFormsData(shouldFilterDuplicates);
      onSubmit(newFormsData);
    }
  }, [dialogKey, formsData, onSubmit, userEmail, userRoles, validateEmail]);

  const onHandleInviteUsers = useCallback(() => {
    const emails = arrayUtils.filterMap(
      formsData,
      (form) => form.email !== undefined && form.email !== '',
      (form) => form.email,
    );
    const duplicatesEmails = emails.filter((email, index) => emails.indexOf(email) !== index);
    const isDuplicatesYourEmail = emails.includes(userEmail);

    if (duplicatesEmails.length) {
      setDuplicatesEmail(duplicatesEmails[0]);
      setDialogKey(DUPLICATES_EMAILS_DIALOG);
      dispatch(KreoDialogActions.openDialog(DUPLICATES_EMAILS_DIALOG));
    } else if (isDuplicatesYourEmail) {
      setDialogKey(DUPLICATES_YOUR_EMAIL_DIALOG);
      dispatch(KreoDialogActions.openDialog(DUPLICATES_YOUR_EMAIL_DIALOG));
    } else {
      handleSubmit();
    }
  }, [dispatch, formsData, handleSubmit, userEmail]);

  const handleSkip = useCallback(() => {
    if (onSubmit) {
      if (invitedPeople && invitedPeople.length) {
        onSubmit(invitedPeople);
      } else {
        onSubmit(null);
      }
    }
  }, [invitedPeople, onSubmit]);

  return (
    <>
      <Styled.Container ref={containerRef}>
        {formsData.map((formData, index) =>
          (<InviteField
            key={formData.id}
            roles={roles}
            activeElementIndex={roles.indexOf(formData.role)}
            value={formData.email}
            index={index}
            onRoleChange={onRoleChange}
            deleteField={deleteForm}
            onEmailChange={onEmailChange}
          />),
        )}
        <RenderIf condition={!scrollActive}>
          <ManageButtons
            canAddUser={canAddUser}
            hiddenSkipButton={hiddenSkipButton}
            inviteUsersButtonText={inviteUsersButtonText}
            moreClick={moreClick}
            handleSkip={handleSkip}
            onHandleInviteUsers={onHandleInviteUsers}
          />
        </RenderIf>
      </Styled.Container>
      <RenderIf condition={scrollActive}>
        <ManageButtons
          canAddUser={canAddUser}
          hiddenSkipButton={hiddenSkipButton}
          inviteUsersButtonText={inviteUsersButtonText}
          moreClick={moreClick}
          handleSkip={handleSkip}
          onHandleInviteUsers={onHandleInviteUsers}
        />
        </RenderIf>
      <ConfirmationDialog
        name={optionsDialog[dialogKey].name}
        title={optionsDialog[dialogKey].title}
        text={optionsDialog[dialogKey].text}
        cancelButtonText={'Cancel'}
        confirmButtonText={'Continue'}
        submitButtonMood={Mood.Positive}
        onConfirm={handleSubmit}
      />
    </>
  );
},
);
