import produce from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Assembly, Item, Property } from 'unit-2d-database/interfaces';
import { SelectEvents, selectAssembliesAction } from 'unit-2d-info-panel/content/selection-view/assign-form/constants';
import { Action } from 'unit-2d-info-panel/content/selection-view/assign-form/helpers';
import { FormulaDialogData } from '../../formula-dialog';
import { Callbacks, getAssemblyUpdater, handleAssemblyFormulaFieldClick, mapAssemblyGroupForm } from '../helpers';
import { handleAssemblyBreakdownFieldClick } from '../helpers/handle-assembly-breakdown-field-click';
import { MeasureForm, MeasurePanelAssemblyAfterEffects } from '../interfaces';
import { useGetDatabaseEntitiesCallback } from './use-get-database-entity-callback';
import { useSelectAssembliesCallback } from './use-select-assemblies-callback';

interface StateRef {
  form?: MeasureForm;
  setForm?: (f: MeasureForm) => void;
}

export const useAssemblyPanel = (
  assemblies: Assembly[],
  units: string[],
  afterEffects: MeasurePanelAssemblyAfterEffects,
  assembliesDatabase: Assembly[],
  itemsDatabase: Item[],
  propertiesDatabase: Property[],
  openFormulaDialog: (data: FormulaDialogData) => void,
  openBreakdownDialog: (data: any) => void,
  action: Action<SelectEvents>,
  setPiaLoad: () => void,
): MeasureForm => {
  const refFrom = useRef<StateRef>({});
  const refAssembliesDatabase = useRef<Assembly[]>([]);
  refAssembliesDatabase.current = assembliesDatabase;
  const getFormCallback = useCallback(() => refFrom.current.form, []);
  const setFormCallback = useCallback((newForm: MeasureForm) => {
    const form = produce(newForm, f => {
      for (const { assemblyGroups } of Object.values(f.assemblyGroupForm)) {
        assemblyGroups.addedItems = {};
        assemblyGroups.removedItems = {};
        for (const { itemForm } of Object.values(assemblyGroups.itemGroupForm)) {
          itemForm.addedProperty = {};
          itemForm.removedProperty = {};
        }
      }
    });
    refFrom.current.setForm(form);
  }, []);
  const refCallbacks = useRef<Callbacks>({
    getForm: getFormCallback,
    setForm: setFormCallback,
  });
  const updater = useMemo(() => {
    return getAssemblyUpdater(refCallbacks.current, afterEffects, setPiaLoad);
  }, [afterEffects, setPiaLoad]);

  const form = useMemo(() => ({
    assemblyGroupForm: mapAssemblyGroupForm(
      assemblies,
      updater,
      units,
      (assemblyId, itemId, propertyId, groupName) => handleAssemblyFormulaFieldClick(
        refFrom.current.form,
        updater,
        openFormulaDialog,
        propertiesDatabase,
        units,
      )(assemblyId, itemId, propertyId, groupName),
      (assemblyId, itemId, propertyId, groupName) => handleAssemblyBreakdownFieldClick(
        updater,
        openBreakdownDialog,
      )(assemblyId, itemId, propertyId, groupName),
      assembliesDatabase,
      propertiesDatabase,
      itemsDatabase,
    ),
  }), [
    assemblies,
    updater,
    units,
    assembliesDatabase,
    propertiesDatabase,
    itemsDatabase,
    openFormulaDialog,
    openBreakdownDialog,
  ]);

  const [stateForm, setForm] = useState(form);
  useEffect(() => {
    setForm(form);
    refFrom.current.form = form;
  }, [form]);
  refFrom.current.form = stateForm;
  refFrom.current.setForm = setForm;

  const getAssembliesDatabase = useGetDatabaseEntitiesCallback(assembliesDatabase);
  const getItemsDatabase = useGetDatabaseEntitiesCallback(itemsDatabase);
  const getPropertiesDatabase = useGetDatabaseEntitiesCallback(propertiesDatabase);

  const handleSelectAssemblies = useSelectAssembliesCallback(
    units,
    getAssembliesDatabase,
    getItemsDatabase,
    getPropertiesDatabase,
    () => refFrom.current.form,
    updater,
    openFormulaDialog,
    openBreakdownDialog,
  );
  useEffect(() => {
    action.addEventLister(selectAssembliesAction, handleSelectAssemblies);
  }, []);

  return stateForm;
};
