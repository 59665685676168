import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { ContextObserver } from '../../../../drawings-contexts';
import { DrawingsCursorTypeHelper } from '../../../drawings-helpers';
import { EditPermissions, RotationProcessor } from '../../../interfaces';
import { DrawingsMouseEventHandler } from '../../base';
import { RotationControlWithText } from '../rotation-control-with-text';
import { DrawingsGeometryBoundingPoint } from './bounding-point';
import { BoundingRect, BoundingRectConfig } from './bounding-rect';


interface PointParams {
  textParamsObserver: ContextObserver<MeasuresViewSettings>;
  onStartDrag: DrawingsMouseEventHandler;
  cursorHelper: DrawingsCursorTypeHelper;
  layer: paper.Layer;
}

interface DrawingsGeometrySelectionBoundingRectConfig extends BoundingRectConfig{
  layer?: paper.Layer | paper.Group;
  pointParams: PointParams;
  rotationProcessor: RotationProcessor;
  editPermissionsObserver: ContextObserver<EditPermissions>;
}

export class DrawingsGeometrySelectionBoundingRect extends BoundingRect<DrawingsGeometrySelectionBoundingRectConfig> {
  private _point: DrawingsGeometryBoundingPoint;
  private _rotationControl: RotationControlWithText;
  private _angle: number = 0;

  public set pointVisibility(value: boolean) {
    if (this._point) {
      this._point.visibility = value;
    }
  }

  public override get position(): paper.Point {
    return super.position;
  }

  public override set position(value: paper.Point) {
    const diff = value.subtract(this.position);
    super.position = value;
    this._point.position = this._point.position.add(diff);
    this._rotationControl.move(diff);
  }

  public override destroy(): void {
    super.destroy();
    this._config.pointParams.textParamsObserver.unsubscribe(this.updateRenderRotation);
    if (this._point) {
      this._point.destroy();
    }
    if (this._rotationControl) {
      this._rotationControl.destroy();
    }
  }

  public override rotate(angle: number, { showAngle }: { showAngle: boolean }): void {
    super.rotate(angle);
    this._angle += angle;
    if (showAngle) {
      this._rotationControl.showAngle(this._angle);
    }
    this.updateAdditionalPoints(this.position);
  }

  protected override render(geometry: paper.Rectangle): void {
    super.render(geometry);
    this._config.pointParams.textParamsObserver.subscribe(this.updateRenderRotation);
  }

  private updateAdditionalPoints(value: paper.Point): void {
    const { rotation } = this._config.pointParams.textParamsObserver.getContext();
    const leftTop = DrawingsCanvasUtils.getTopLeftPointByRotation(this._bounds, rotation);
    this._point.position = new paper.Point(leftTop).rotate(this._angle, value);
    const side = DrawingsCanvasUtils.getRightSideLine(this._config.geometry, rotation)
      .map(x => x.rotate(this._angle, value)) as [paper.Point, paper.Point];
    this._rotationControl.updateGeometry(side);
  }

  @autobind
  private updateRenderRotation({ rotation }: MeasuresViewSettings): void {
    const leftTop = DrawingsCanvasUtils.getTopLeftPointByRotation(this._bounds, rotation);
    if (!this._config.editPermissionsObserver.getContext().canEditMeasure) {
      return;
    }

    if (!this._point) {
      this._point = new DrawingsGeometryBoundingPoint({
        observableRenderContext: this._config.renderParamsContextObserver,
        position: new paper.Point(leftTop),
        onStartDrag: this._config.pointParams.onStartDrag,
        cursorHelper: this._config.pointParams.cursorHelper,
        layer: this._config.pointParams.layer,
      });
    } else {
      this._point.position = new paper.Point(leftTop);
    }

    if (!this._rotationControl) {
      this._rotationControl = new RotationControlWithText({
        geometry: DrawingsCanvasUtils.getRightSideLine(this._config.geometry, rotation),
        renderParamsContextObserver: this._config.renderParamsContextObserver,
        layer: this._config.pointParams.layer,
        rotationProcessor: this._config.rotationProcessor,
      });
    }
  }
}

