import React, { useCallback, useMemo } from 'react';
import { useRendererApiContext } from 'common/components/drawings/drawings-contexts';
import { DrawingsContentMenuItemWrapper } from '../../drawings-context-menu/drawings-context-menu-item-wrapper';
import { DrawingsSubmenuContainer } from '../submenu-containers';
import { TransformMenuActions, TransformMenuItems } from './menu-items';

interface Props {
  selectedInstancesIds: string[];
  onClose: () => void;
}

export const TransformMenuContent: React.FC<Props> = ({
  onClose,
  selectedInstancesIds,
}) => {

  const doActionAndClose = useCallback((action: (...args) => void, ...args) => {
    action(...args);
    onClose();
  }, [onClose]);


  const { rendererApi } = useRendererApiContext();

  const eventHandlers = useMemo(() => {
    return {
      [TransformMenuActions.FlipHorizontally]: {
        onClick: () => rendererApi.engine.flipElements(selectedInstancesIds, 'horizontal'),
      },
      [TransformMenuActions.FlipVertically]: {
        onClick: () => rendererApi.engine.flipElements(selectedInstancesIds, 'vertical'),
      },
      [TransformMenuActions.RotateLeft]: {
        onClick: () => rendererApi.engine.rotateSelectedElements(selectedInstancesIds, -90),
      },
      [TransformMenuActions.RotateRight]: {
        onClick: () => rendererApi.engine.rotateSelectedElements(selectedInstancesIds, 90),
      },
    };
  }, [selectedInstancesIds, rendererApi]);


  return (
    <DrawingsSubmenuContainer>
      {
        TransformMenuItems.map((item) => {
          const currentEventHandlers = eventHandlers[item.action];
          return (
            <DrawingsContentMenuItemWrapper
              key={item.action}
              badge={item.badge}
              hotkey={item.hotKey}
              icon={item.icon}
              {...currentEventHandlers}
              doActionAndClose={doActionAndClose}
            >
              {item.action}
            </DrawingsContentMenuItemWrapper>
          );
        })
      }
    </DrawingsSubmenuContainer>
  );
};
