export enum BoolFeatureFlag {
  BuyKreoProvidedDb = 'ui-buy-kreo-provided-db',
  AccessBidPricing = 'ui-access-bid-pricing',
  AccessActivityGroupingStep = 'ui-access-scenario-activity-grouping',
  AllowToSendUserAnalytics = 'allow-to-send-user-analytics',
  AccessQtoReport = 'ui-access-qto-report',
  AccessQtoCustomFilters = 'ui-access-qto-custom-filters',
  AccessModelManagement = 'ui-access-model-management',
  AccessSmartLook = 'ui-access-smart-look',
  ImportSchedules2D = 'ui-access-import-schedules-2-d',
  DemoProjects2d = '2d-demo-projects',
  Access2DMeasurementsAutocomplete = 'ui-access-2-d-measurement-autocomplete',
  UseOldPaymentUI = 'use-old-payment-ui',
  Ui2dPauseSubscription = 'ui-2d-pause-subscription',
  RemindLaterTakeSurvey = 'remind-later-take-survey',
  AccessCaddie = 'ui-access-caddie',
  AccesTwoDReportTemplate= 'ui-access-twod-report-template',
  AccessWizard = 'ui-access-wizzard',
  AccessWizzardFinder = 'ui-access-wizzard-finder',
  SignalRLogging = 'signalr-logging',
  FeatureFlagOnlyAnnualPlans = 'feature-flag-only-annual-plans',
  FindSimilarWithAi = 'find-similar-with-AI',
  AutoMeasure2 = 'auto-measure-2-0',
  OneClickAreaHover = 'one-click-area-hover',
  OneClickLine = 'ui-access-one-click-line',
  OpenReplay = 'ui-access-openreplay',
}
