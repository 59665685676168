import { isMergeVerticalKey } from '2d/units/excel-table-cell-formatter/common';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { MoveCellTempData, MoveDataPayload } from '../interfaces';
import { shiftRows } from '../shift-rows';

export const deleteRows = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const sheetData = sheetsData[sheetId];
  const rows = sheetData.rows;

  const { updatedCellsMap, moveCellRefMap } = shiftRows(sheetData, startIndex, offset);

  for (let i = 0; i <= startIndex; i++) {
    const row = rows[i];
    Object.keys(row).forEach((columnId) => {
      if (isMergeVerticalKey(columnId)) {
        const mergeSize: number = row[columnId] as number;
        if (startIndex <= i + mergeSize) {
          const rowId = ExcelTableRowIdentification.getRowIdFromIndex(i);
          const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);

          updatedCellsMap[cellId] = {
            rowId,
            columnId,
            value: mergeSize - offset,
            prevValue: mergeSize,
          };
        }
      }
    });
  }

  return { updatedCellsMap, moveCellRefMap };
};
