import autobind from 'autobind-decorator';
import _ from 'lodash';
import * as React from 'react';

import { Spinner } from 'common/components/spinner';
import { TreeTableRowType } from 'common/components/tree-table/interfaces';
import { DrawingsFile, DrawingsFiles, DrawingsFolderViewInfo } from '../../../interfaces/drawings-file-info';
import { DrawingsPdfBrowserFileTree } from '../drawings-pdf-browser-file-tree';
import { DrawingsPdfBrowserPages } from '../drawings-pdf-browser-pages';
import { DrawingsPdfBrowserDialogProps } from '../interfaces';
import { Styled } from './styled';

interface Props extends DrawingsPdfBrowserDialogProps {

}

interface ComponentState {
  isFullScreen: boolean;
  selectFileId: string;
}

export class DrawingsPdf extends React.Component<Props, ComponentState> {
  private scrollRef = {
    scrollTo: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isFullScreen: false,
      selectFileId: null,
    };
  }

  public render(): React.ReactNode {
    const {
      isLoading,
      canEdit,
      selectedPages,
      files,
      highlightState,
      multiSelect,
      selectAllText,
      deselectAllText,
      renderFilesFooterContent,
      renderFilesHeaderAdditionalContent,
      onChangeDrawingSelection,
      onExpandEntity,
      addFolder,
      getContextMenuItems,
      onDeleteFileClick,
      onDragRow,
      onChangeName,
      startAddFile,
      onPageParameterUpdate,
      onDeletePage,
    } = this.props;
    const sortedFiles = this.getSortDrawingsFiles();

    return (
      <Styled.Container fullScreen={this.state.isFullScreen}>
        <Spinner show={isLoading} withBackground={true} />
        <DrawingsPdfBrowserFileTree
          getSelectedPagesCount={this.getSelectedPagesCount}
          canEdit={canEdit}
          renderHeaderAdditionalContent={renderFilesHeaderAdditionalContent}
          renderFooterContent={renderFilesFooterContent}
          files={files}
          highlightState={highlightState}
          onExpandEntity={onExpandEntity}
          onSelectFile={this.onSlectFile}
          addFolder={addFolder}
          getContextMenuItems={getContextMenuItems}
          onDeleteClick={onDeleteFileClick}
          onDragRow={onDragRow}
          onChangeName={onChangeName}
          startAddFile={startAddFile}
        />
        <DrawingsPdfBrowserPages
          onDeletePage={onDeletePage}
          selectAllText={selectAllText}
          deselectAllText={deselectAllText}
          multiSelect={multiSelect}
          onParameterUpdate={onPageParameterUpdate}
          selectedPages={selectedPages}
          sortedFiles={sortedFiles}
          canEditMeasurement={canEdit}
          onToggleFullScreen={this.toggleFullScreen}
          isFullScreen={this.state.isFullScreen}
          onChangeDrawingSelection={onChangeDrawingSelection}
          scrollRef={this.scrollRef}
          copyDrawingGeometriesToPage={this.props.copyDrawingGeometriesToPage}
        />
      </Styled.Container>
    );
  }

  @autobind
  private getSortDrawingsFiles(): DrawingsFile[]  {
    const { files, selectedFiles } = this.props;
    const sortedFiles: DrawingsFile[] = [];
    const copyFiles: DrawingsFiles = _.cloneDeep(files);
    const sortedRootIds = this.sortRootIds(copyFiles);
    function sortChildren(ids: string[]): void {
      ids.forEach(id => {
        const entity = { ...copyFiles.entities[id] };
        if (entity.type === TreeTableRowType.Group) {
          const sortedChildrenIds = entity.children.sort((a, b) => {
            return copyFiles.entities[a].properties.name.localeCompare(copyFiles.entities[b].properties.name);
          });
          sortChildren(sortedChildrenIds);
        } else {
          const selectedFile =  selectedFiles.find(f => f.id === entity.id);
          if (selectedFile) {
            if (selectedFile.pages.length !== (entity as DrawingsFile).pages.length) {
              (entity as DrawingsFile).pages = selectedFile.pages;
            }
            sortedFiles.push(entity as DrawingsFile);
          }
        }
      });
    }

    sortChildren(sortedRootIds);

    return sortedFiles;
  }

  @autobind
  private sortRootIds(files: DrawingsFiles): string[]  {
    const folders: DrawingsFolderViewInfo[] = [];
    const elements: DrawingsFile[] = [];
    const sortedRootIds = [];

    for (const id of Object.keys(files.entities)) {
      if (!files.entities[id].parentId) {
        const entity = files.entities[id];
        if (entity.type === TreeTableRowType.Group) {
          folders.push(entity as DrawingsFolderViewInfo);
        } else if (entity.type === TreeTableRowType.Element) {
          elements.push(entity as DrawingsFile);
        }
      }
    }

    folders.sort((a, b) => a.properties.name.localeCompare(b.properties.name));
    elements.sort((a, b) => a.properties.name.localeCompare(b.properties.name));
    folders.forEach(f => sortedRootIds.push(f.id));
    elements.forEach(e => sortedRootIds.push(e.id));

    return sortedRootIds;
  }

  @autobind
  private onSlectFile(id: string): void {
    const selectId = this.getSelectId(id);
    if (!selectId) {
      return;
    }

    if (!this.canScroll(selectId)) {
      return;
    }

    this.scrollRef.scrollTo(selectId);
  }

  private canScroll(id: string): boolean {
    return !!(this.props.files.entities[id] as DrawingsFile).pages.length;
  }

  private getSelectId(id: string): string {
    const selectElement = this.props.files.entities[id];
    if (selectElement.type === TreeTableRowType.Group) {
      const selectId = this.getFirstElementId(selectElement);
      if (selectId) {
        return selectId;
      }
      return;
    }

    return id;
  }

  private getFirstElementId(selectElement: DrawingsFile | DrawingsFolderViewInfo): string {
    const firstElement = selectElement.children[0];
    if (firstElement && this.props.files.entities[firstElement].type === TreeTableRowType.Group) {
      return this.getFirstElementId(this.props.files.entities[firstElement]);
    }

    return firstElement;
  }

  @autobind
  private toggleFullScreen(): void {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  }

  @autobind
  private getSelectedPagesCount(entity: DrawingsFile): number {
    return this.props.selectedPages.reduce(
      (acc, selectedPage) => {
        const isPageSelected = entity.pages.some(p => p.drawingId === selectedPage);
        return isPageSelected ? acc + 1 : acc;
      },
      0);
  }
}
