import * as React from 'react';

import { Hotkey } from 'common/hotkeys';


type AddListener = (key: Hotkey | Hotkey[], callback: (e: KeyboardEvent) => void) => void;

/**
 * @callback must be a same object with callback in add listener method
 */
type RemoveListener = (key: Hotkey | Hotkey[], callback: (e: KeyboardEvent) => void) => void;

export interface GlobalKeyboardEventsControllerContextProps {
  addKeyUpEventListener: AddListener;
  addKeyDownEventListener: AddListener;
  removeKeyUpEventListener: RemoveListener;
  removeKeyDownEventListener: RemoveListener;
  focus: () => void;
}

export const GlobalKeyboardEventsControllerContext =
  React.createContext<GlobalKeyboardEventsControllerContextProps>(null);


export const GlobalKeyboardEventsControllerContextProvider:
  React.FC<GlobalKeyboardEventsControllerContextProps> = props => {
    const value = { ...props };
    delete value.children;
    return (
      <GlobalKeyboardEventsControllerContext.Provider value={value}>
        {props.children}
      </GlobalKeyboardEventsControllerContext.Provider>
    );
  };
