import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { MetricNames, useAnalytics } from 'utils/posthog';
import { SubscriptionActions } from '../../actions/creators';
import { useSubscriptionId } from '../../hooks';

export const useSubmitFormCallback = (
  setShowSpinnerPage: (value: boolean) => void,
): (form: Record<string, string>) => void => {
  const { sendEvent } = useAnalytics();
  const subscriptionId = useSubscriptionId();
  const dispatch = useDispatch();
  const cancelSubscription = useCallback(
    () => dispatch(SubscriptionActions.cancelSubscription(subscriptionId)),
    [dispatch, subscriptionId],
  );
  return useCallback((form) => {
    setShowSpinnerPage(true);
    cancelSubscription();
    sendEvent(MetricNames.trial.cancelledSubscription, form);
    dispatch(SubscriptionActions.dropOwnCompanyBillingInfoState());
  }, [
    cancelSubscription,
    sendEvent,
    setShowSpinnerPage,
    dispatch,
  ]);
};
