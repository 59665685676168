import { ElementTooltip, Icons, Text } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { SubscriptionType } from 'common/constants/subscription';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';

import { AppUrls } from 'routes/app-urls';
import { TrackerProvider } from '../../../../analytics/components/utils/tracker-provider';
import { SubscriptionActions } from '../../../actions/creators';
import { SubscriptionActivityStatus } from '../../../enums/subscription-activity-status';
import { SubscriptionStatus } from '../../../enums/subscription-status';
import { useCurrentSubscriptionType, useSubscriptionId, useSubscriptionStatus } from '../../../hooks';
import { SUBSCRIPTION__DIALOG } from '../../subscription-dialog';
import { Styled } from '../styled';
import {
  CancelSubscriptionActiveDialog,
  CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG,
} from './cancel-subscription-active-dialog';
import {
  CancelSubscriptionTrialDialog,
  CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG,
} from './cancel-subscription-trial-dialog';
import {
  CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG,
  ConsequencesCancelSubscriptionDialog,
} from './consequences-cancel-subscription-dialog';
import { EXTENDED_TRIAL_DIALOG, ExtendedTrialDialog } from './extended-trial-dialog';

interface StateProps {
  startDate: string;
  nextBilling: string;
  billingStatus: SubscriptionActivityStatus;
}

interface DispatchProps {
  openConfirmDialog: (mode: string) => void;
  closeConfirmDialog: (mode: string) => void;
  onChangeUrl: (url: string) => void;
}

interface Props extends DispatchProps, StateProps {
  handleExtendTrial: () => void;
}

const DESCRIPTION_UNSUBSCRIBE = `Complete a quick unsubscribe survey in the bot below &
  your subscription will be automatically cancelled right after it.`;

const CancelSubscriptionButtonComponent: React.FC<Props> = ({
  openConfirmDialog,
  closeConfirmDialog,
  handleExtendTrial,
  onChangeUrl,
  startDate,
  nextBilling,
  billingStatus,
}) => {
  const subscriptionStatus = useSubscriptionStatus();
  const closeDialog = useCallback(() => {
    if (billingStatus === SubscriptionActivityStatus.Trial) {
      closeConfirmDialog(CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG);
    } else {
      closeConfirmDialog(CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG);
    }
  }, [closeConfirmDialog, billingStatus]);

  const currentSubscriptionType = useCurrentSubscriptionType();
  const currentSubscriptionId = useSubscriptionId();
  const cancel = useActionDispatch(SubscriptionActions.cancelSubscription);

  const tryCancelSubscription = useCallback(() => {
    if (currentSubscriptionType === SubscriptionType.Takeoff2d) {
      if (billingStatus === SubscriptionActivityStatus.Trial) {
        openConfirmDialog(CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG);
      } else {
        openConfirmDialog(CONFIRM_DELETE_SUBSCRIPTION_ACTIVE_DIALOG);
      }
    } else {
      cancel(currentSubscriptionId);
    }
  }, [
    openConfirmDialog,
    billingStatus,
    cancel,
    currentSubscriptionId,
    currentSubscriptionType,
  ]);

  const handleCloseDialog = useCallback(() => {
    closeDialog();
    openConfirmDialog(CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG);
  }, [closeDialog, openConfirmDialog]);

  const handleCloseConsequencesDialog = useCallback(() => {
    closeConfirmDialog(CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG);
  }, [closeConfirmDialog]);

  const handleConfirmationConsequencesDialog = useCallback(() => {
    onChangeUrl(AppUrls.qto2d.cancellationSurvey.path);
    closeConfirmDialog(CONSEQUENCES_CANCEL_SUBSCRIPTION_DIALOG);
  }, [onChangeUrl, closeConfirmDialog]);

  const handleWriteUs = useCallback(() => {
    TrackerProvider.intercomTracker()('show');
    closeDialog();
  }, [closeDialog]);

  const handleBookDemo = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const handleExtendTrialCallback = useCallback(() => {
    handleExtendTrial();
    closeDialog();
    openConfirmDialog(EXTENDED_TRIAL_DIALOG);
  }, [closeDialog, handleExtendTrial, openConfirmDialog]);

  const closeExtendTrialDialog = useCallback(() => {
    closeConfirmDialog(CONFIRM_DELETE_SUBSCRIPTION_TRIAL_DIALOG);
    closeConfirmDialog(EXTENDED_TRIAL_DIALOG);
    closeConfirmDialog(SUBSCRIPTION__DIALOG);
  }, [closeConfirmDialog]);

  const canExtendTrial = useMemo(() => {
    const endTrialDate = new Date(startDate);
    endTrialDate.setDate(endTrialDate.getDate() + 7);
    const nextBillingDate = new Date(nextBilling);

    return endTrialDate.toDateString() === nextBillingDate.toDateString();
  }, [
    startDate,
    nextBilling,
  ]);

  useEffect(() => {
    if (subscriptionStatus === SubscriptionStatus.Canceled) {
      onChangeUrl(AppUrls.root.url());
      closeDialog();
      closeConfirmDialog(SUBSCRIPTION__DIALOG);
    }
  }, [subscriptionStatus]);

  return (
    <>
      <ElementTooltip
        text={DESCRIPTION_UNSUBSCRIBE}
        position='right'
        wordBreakAll={true}
        tooltipWidth={240}
      >
        <Text>
          <Styled.LinkButton
            color='red'
            onClick={tryCancelSubscription}
            data-control-name={'cancel-subscription'}
          >
            <Icons.Close />
            Cancel Subscription
          </Styled.LinkButton>
        </Text>
      </ElementTooltip>
      <CancelSubscriptionTrialDialog
        onCancelSubscriptionClick={handleCloseDialog}
        handleBookDemo={handleBookDemo}
        handleExtendTrial={handleExtendTrialCallback}
        canExtendTrial={canExtendTrial}
        handleWriteUs={handleWriteUs}
      />
      <CancelSubscriptionActiveDialog
        onCancelSubscriptionClick={handleCloseDialog}
        handleBookDemo={handleBookDemo}
        handleWriteUs={handleWriteUs}
        onCancel={closeDialog}
        nextBilling={nextBilling}
      />
      <ConsequencesCancelSubscriptionDialog
        onCancelSubscriptionClick={handleConfirmationConsequencesDialog}
        onCancel={handleCloseConsequencesDialog}
      />
      <ExtendedTrialDialog
        onExtendTrialClick={closeExtendTrialDialog}
      />
    </>
  );
};

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    openConfirmDialog: (mode) => dispatch(KreoDialogActions.openDialog(mode)),
    closeConfirmDialog: (mode) => dispatch(KreoDialogActions.closeDialog(mode)),
    onChangeUrl: (url) => dispatch(push(url)),
  };
}

function mapStateToProps(state: State): StateProps {
  const type = state.account.selectedSubscriptionType;
  return {
    startDate: state.account.selectedCompany.subscriptions[type].createdAt,
    nextBilling: state.account.selectedCompany.subscriptions[type].nextBillingAt,
    billingStatus: state.account.selectedCompany.subscriptions[type].billingStatus,
  };
}

export const CancelSubscriptionButton = React.memo(
  connect(mapStateToProps, mapDispatchToProps)(CancelSubscriptionButtonComponent));
