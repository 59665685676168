import * as THREE from 'three';
import { MaterialSettings } from '../utils/add-material-settings';

export class MaterialCache {
  private _collections: Map<THREE.Side, Map<string, THREE.MeshStandardMaterial>> = new Map();

  private _materialLookSettings: MaterialSettings;

  constructor(materialLookSettings: MaterialSettings) {
    this._materialLookSettings = materialLookSettings;
  }

  public set materialLookSettings(value: MaterialSettings) {
    this._materialLookSettings = value;
    this._collections.forEach((collection) => {
      collection.forEach((material) => {
        material.transparent = value.transparent;
        material.opacity = value.opacity;
        material.roughness = value.roughness;
      });
    });
  }

  public getMaterialByColor({ color, side }: { color: string, side: THREE.Side }): THREE.Material {
    let collection = this._collections.get(side);
    if (!collection) {
      collection = new Map();
      this._collections.set(side, collection);
    }

    if (!collection.has(color)) {
      collection.set(color, new THREE.MeshStandardMaterial({
        color: new THREE.Color(color),
        side,
        transparent: this._materialLookSettings.transparent,
        opacity: this._materialLookSettings.opacity,
        roughness: this._materialLookSettings.roughness,
      }));
    }

    return collection.get(color);
  }
}
