
import React, { FocusEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';

import { State } from 'common/interfaces/state';
import { ThreeDZoomControl } from '../drawings-floating-controls';
import { HoverInstanceChangeEvent } from '../drawings-geometry/interfaces';
import { DrawingsViewportHelper } from '../helpers/viewport';
import { useCurrentDrawingInfo } from '../hooks';
import { DrawingsGeometryInstance, ShortPointDescription } from '../interfaces';
import { CommentsView } from './comments-view';
import { ThreeEngine } from './engine';
import { useCanvas } from './hooks/use-canvas';
import { useScaleObserver } from './hooks/use-scale-observer';
import { useCreateScreenshot } from './hooks/use-screenshot';
import { useSettingsObserver } from './hooks/use-settings-observer';
import { useShowthicknessObserver } from './hooks/use-show-thickness-observer';
import { Styled } from './styled';

interface OwnProps {
  instancesIds: string[];
  viewportHelper: DrawingsViewportHelper;
  scale: number;
  metersPerPixel: number;
  onSelect: (id: string, e: MouseEvent) => void;
  onHover: (e: HoverInstanceChangeEvent) => void;
  onFocus: FocusEventHandler;
  onToggleFullScreen: () => void;
  canToggleFullScreen: boolean;
}

interface Props extends OwnProps{}


const Drawings3DViewComponent: React.FC<Props> = ({
  scale,
  metersPerPixel,
  instancesIds,
  onHover,
  onSelect,
  viewportHelper,
  onToggleFullScreen,
  onFocus,
  canToggleFullScreen,
}) => {
  const viewerRef = useRef<ThreeEngine | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const scaleObserver = useScaleObserver(scale, metersPerPixel);

  const settingsObserver = useSettingsObserver();
  const showThicknessContextObserver = useShowthicknessObserver();

  const geometries = useSelector<State, Record<string, DrawingsGeometryInstance>>(
    (state: State) => state.drawings.aiAnnotation.geometry,
  );
  const points = useSelector<State, Record<string, ShortPointDescription>>(
    (state: State) => state.drawings.aiAnnotation.points,
  );
  const currentDrawing = useCurrentDrawingInfo();
  const filteredIds = useSelector<State, string[]>(
    (state: State) => state.drawings.filteredElementIds,
  );
  const hiddenInstancesIds = useSelector<State, string[]>(
    (state: State) => state.drawings.hiddenInstances,
  );
  const selectedInstancesIds = useSelector<State, string[]>(
    (state: State) => state.drawings.selectedInstances,
  );

  useEffect(() => {
    if (viewerRef.current) {
      viewerRef.current.setVisualData({
        instancesIds: instancesIds || [],
        points,
        filteredIds,
        hiddenIds: hiddenInstancesIds,
        selectedGeometries: selectedInstancesIds,
        geometries,
      });
    }
  }, [
    instancesIds,
    geometries,
    points,
    filteredIds,
    hiddenInstancesIds,
    selectedInstancesIds,
  ]);


  const canvas = useCanvas(viewportHelper, currentDrawing);

  useEffect(() => {
    if (!canvas) {
      if (viewerRef.current) {
        viewerRef.current.removeBackground();
      }
      return;
    }

    if (viewerRef.current) {
      viewerRef.current.setBackground(canvas);
      viewerRef.current.home();
    }
  }, [canvas, currentDrawing.drawingId]);

  const onResize = useCallback(() => {
    if (viewerRef.current) {
      viewerRef.current.onResize();
    }
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    if (canvas) {
      viewerRef.current.setBackground(canvas);
      viewerRef.current.home();
    }
  }, [isInitialized]);

  const init3dView = useCallback((ref: HTMLDivElement | null) => {
    if (!ref && viewerRef.current) {
      viewerRef.current.destroy();
      viewerRef.current = null;
      setIsInitialized(false);
      return;
    }

    if (viewerRef.current) {
      return;
    }

    const engine =  new ThreeEngine({
      root: ref,
      scaleObserver,
      onSelect,
      onHover,
      settingsObserver,
      showThicknessContextObserver,
    });

    engine.init({
      instancesIds,
      points,
      filteredIds,
      hiddenIds: hiddenInstancesIds,
      selectedGeometries: selectedInstancesIds,
      geometries,
    }).then(() => {
      viewerRef.current = engine;
      setIsInitialized(true);
    });
  }, [
    scaleObserver,
    onSelect,
    onHover,
  ]);

  const home = useCallback(() => {
    if (canvas) {
      viewerRef.current?.home();
    }
  }, [canvas]);

  const createScreenshot = useCreateScreenshot(viewerRef.current?.createScreenshot);
  return (
    <Styled.Container ref={init3dView} onFocus={onFocus}>
      <ReactResizeDetector
        handleWidth={true}
        handleHeight={true}
        onResize={onResize}
      />
      <ThreeDZoomControl
        home={home}
        onToggleFullScreen={onToggleFullScreen}
        createScreenshot={createScreenshot}
        canToggleFullScreen={canToggleFullScreen}
      />
      { viewerRef.current ? (
          <CommentsView
            engine={viewerRef.current}
          />) : null
      }
    </Styled.Container>
  );
};

export const Drawings3DView = React.memo(Drawings3DViewComponent);
