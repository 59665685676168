import { DropDownStyled } from '@kreo/kreo-ui-components';
import styled from 'styled-components';

const Payment = styled.div`
  display: flex;
  width: 100% ;
  align-items: center;
  color: ${p => p.theme.color.mainFont};
`;

const CardType = styled.svg`
  margin-right: 10px;
  width: 30px!important;
  height: 18px!important;
`;

const Status = styled.div`
  margin-left: auto;
`;

const Container = styled.div`
  ${DropDownStyled.DropDownContainer} {
    padding: 10px 25px 10px 15px;
    border: 1px solid ${p => p.theme.color.pale};
    ${DropDownStyled.DropDownItem} {
      padding: 10px 25px 10px 15px;
    }
  }
`;

export const Styled = {
  Payment,
  Container,
  CardType,
  Status,
};
