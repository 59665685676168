import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import { isPropertyBreakDownProperty } from '2d/helpers/calculate-pia-assignment/utils';
import {
  AssignAssemblyPatch,
  AssignItemPatch,
  AssignPiaPatch,
  AssignedPia,
  AssignedPiaItem,
  AssignedPiaProperty,
} from '2d/interfaces';
import { getCodeById, getNameById } from 'unit-2d-database/components/breakdown-property/utils';
import { Constants } from 'unit-2d-database/components/side-panel/components/property-panel/forms/tree-form/constants';
import { PropertiesTypeGuards } from 'unit-2d-database/helpers/properties-typeguards';
import { BreakDownPropertyValue, Item, Property, PropertyTypeEnum } from 'unit-2d-database/interfaces';
import { CODE_COLUMN_ID, getCodeColumn } from '../extend-code-column';
import { getColumn } from '../extends-by-group';
import {
  BreakdownPropertyExtender,
  ORDER_COLUMN_ID,
  getBreakdownPropertyExtender,
  orderAutoGroupColumn,
} from '../items-helper/utils';
import { CommonTransaction } from './common-transaction';
import { TransactionHelpers } from './helpers';
import { defaultColumSet } from './helpers/get-col-def';
import { getPropertyColumn } from './helpers/get-property-column';
import { AssignInfoGetter, Column, DrawingInfoGetter, PropertyColumn } from './interfaces';


export class ColumnTransaction extends CommonTransaction {
  protected breakdownPropertyExtender: BreakdownPropertyExtender = null;
  protected fieldColDef: Record<string, Column> = {};

  private addColDef: Ag.ColDef[] = [];
  private removeColDef: string[] = [];
  private propertyColumns: Record<string, PropertyColumn> = {};
  private breakDownPropertyColumns: Record<string, Ag.ColDef> = {};
  private fieldsCount: Record<string, number> = {};

  public constructor(getDrawingInfo: DrawingInfoGetter, getAssignInfo: AssignInfoGetter) {
    super(getDrawingInfo, getAssignInfo);
    this.updateBBColumn();
  }

  protected initColumnState(): void {
    if (!this.hasAssignColumn()) {
      this.setAssignColumn();
    }
    if (!this.hasDefaultColumn()) {
      this.setDefaultColumn();
    }
  }

  @autobind
  protected handleChangeShowCode(isShow: boolean): void {
    if (isShow) {
      const { originProperties } = this.getCommonAssignInfo();
      const breakdownProperties = originProperties.filter(
        (p) => p.value.type === PropertyTypeEnum.Breakdown,
      ) as Array<Property<BreakDownPropertyValue>>;
      const codeColumn = getCodeColumn(breakdownProperties);
      this.addColDef.push(codeColumn);
      this.breakDownPropertyColumns[CODE_COLUMN_ID] = codeColumn;
    } else {
      this.removeColDef.push(CODE_COLUMN_ID);
      delete this.breakDownPropertyColumns[CODE_COLUMN_ID];
    }
  }

  protected getUpdatedColumns(): Ag.ColDef[] {
    if (!this.isColumnUpdate()) {
      return undefined;
    }
    const result: Column[] = [];
    const fields = Object.entries(this.fieldsCount);
    for (const [key, value] of fields) {
      if (value && this.fieldColDef[key]) {
        result.push(this.fieldColDef[key]);
      }
    }

    const sortedColumn = TransactionHelpers.sortColumn(result);

    if (sortedColumn.length) {
      if (this.breakDownPropertyColumns[ORDER_COLUMN_ID]) {
        sortedColumn.unshift(this.breakDownPropertyColumns[ORDER_COLUMN_ID]);
      }
      if (this.breakDownPropertyColumns[CODE_COLUMN_ID]) {
        sortedColumn.unshift(this.breakDownPropertyColumns[CODE_COLUMN_ID]);
      }
    }
    sortedColumn.unshift(orderAutoGroupColumn);

    this.addColDef = [];
    this.removeColDef = [];

    return sortedColumn;
  }

  protected handleNewField(field: string): Ag.ColDef {
    this.addFieldCount(field);
    const colDef = TransactionHelpers.getColDef(
      field,
      this.propertyColumns,
      this.breakDownPropertyColumns,
    );
    if (colDef !== null) {
      this.fieldColDef[field] = colDef;
      this.addColDef.push(colDef);
    }
    return colDef;
  }

  protected deleteField(field: string): void {
    this.deleteFieldCount(field);
  }

  protected updateColumnsNewProperty(assignPatch: AssignPiaPatch): void {
    const deletedProperty = this.getDeletedPropertyFromPatch(assignPatch);
    for (const property of deletedProperty) {
      this.deleteField(property);
    }

    const addProperties = this.getAddedPropertyFromPatch(assignPatch);
    for (const property of addProperties) {
      this.addPropertyField(property);
    }
  }

  protected hasGroupColumn(groupMaxNesting: number): boolean {
    const maxNestingGroupColumn = getColumn(groupMaxNesting - 1);
    return !!this.fieldColDef[maxNestingGroupColumn.field];
  }

  protected onPivotModeChange(): void {
    this.updateBBColumn();
  }

  protected updateBBColumn(): void {
    const { originProperties } = this.getCommonAssignInfo();
    const isPivot = this.isPivot && this.isPivot();
    const breakdownProperties = originProperties
      ? originProperties.filter(
        (p) => p.value.type === PropertyTypeEnum.Breakdown,
      ) as Array<Property<BreakDownPropertyValue>>
      : [];
    const [breakdownPropertyExtender, groupHierarchyBreakdownProperty] =
      getBreakdownPropertyExtender(
        breakdownProperties,
        isPivot,
      );
    this.breakdownPropertyExtender = breakdownPropertyExtender;
    this.breakDownPropertyColumns = groupHierarchyBreakdownProperty();
  }

  private getDeletedPropertyFromPatch(assignPatch: AssignPiaPatch): string[] {
    const properties = [];

    const { drawingElementAssign } = this.getCommonAssignInfo();

    for (const id of assignPatch.ids) {
      const drawingAssign = drawingElementAssign[id];
      if (!drawingAssign) {
        continue;
      }

      this.setDeletedAssemblyProperties(properties, drawingAssign, assignPatch.deletedAssemblies);
      this.setUpdateAssemblyProperties(properties, drawingAssign, assignPatch.updatedAssemblies);
      this.setDeleteItemProperties(properties, drawingAssign.items, assignPatch.deletedItems);
      this.setUpdatedItemProperties(properties, drawingAssign.items, assignPatch.updatedItems);
    }
    return properties;
  }

  private isColumnUpdate(): boolean {
    return (!!this.addColDef.length || !!this.removeColDef.length) && !!Object.entries(this.fieldsCount).length;
  }

  private addPropertyField(property: AssignedPiaProperty): void {
    const { originProperties } = this.getCommonAssignInfo();
    const originProperty = property.value ? property : originProperties.find(p => p.name === property.name);

    if (originProperty) {
      this.propertyColumns[originProperty.name] = getPropertyColumn(
        this.propertyColumns[originProperty.name], originProperty,
      );
    }
    this.handleNewField(originProperty.name);
    if (originProperty.value) {
      if (PropertiesTypeGuards.isBreakdown(originProperty)) {
        this.setAdditionalBBField(originProperty);
      }
    }
  }

  private addFieldCount(field: string): void {
    if (this.fieldsCount[field]) {
      this.fieldsCount[field] += 1;
    } else {
      this.fieldsCount[field] = 1;
    }
  }

  private deleteFieldCount(field: string): void {
    if (this.fieldsCount[field]) {
      this.fieldsCount[field] -= 1;
    }
    if (this.fieldsCount[field] === 0) {
      this.removeColDef.push(field);
    }
  }

  private setDefaultColumn(): void {
    defaultColumSet.forEach((c) => {
      this.handleNewField(c);
    });
  }

  private hasDefaultColumn(): boolean {
    let allDefaultPropertiesExist = true;
    defaultColumSet.forEach(c => {
      allDefaultPropertiesExist = allDefaultPropertiesExist && !!this.fieldColDef[c];
    });
    return allDefaultPropertiesExist;
  }

  private hasAssignColumn(): boolean {
    return !!Object.keys(this.fieldColDef).length;
  }

  private setAssignColumn(): void {
    const { drawingElementAssign } = this.getCommonAssignInfo();

    if (!drawingElementAssign) {
      return;
    }

    for (const assign of Object.values(drawingElementAssign)) {
      if (assign.assemblies) {
        for (const assembly of assign.assemblies) {
          if (!assembly.items) {
            continue;
          }
          for (const item of assembly.items) {
            if (!item.properties) {
              continue;
            }
            for (const property of item.properties) {
              this.addPropertyField(property);
            }
          }
        }
      }

      if (assign.items) {
        for (const item of assign.items) {
          if (!item.properties) {
            continue;
          }
          for (const property of item.properties) {
            this.addPropertyField(property);
          }
        }
      }
    }
  }

  private setAdditionalBBField(property: AssignedPiaProperty): void {
    const breakdownFields = this.getAdditionalBBFields(property);
    if (breakdownFields) {
      breakdownFields.forEach(bbField => this.handleNewField(bbField));
    }
  }

  private getAdditionalBBFields(property: AssignedPiaProperty): string[] {
    const { originProperties } = this.getCommonAssignInfo();
    const originProperty = originProperties.find(
      (op) => op.name === property.name
        && PropertiesTypeGuards.isBreakdown(op)) as Property<BreakDownPropertyValue>;
    if (!originProperty) {
      return;
    }
    const root = originProperty.value.root;
    const name = getNameById(root, property.value?.value as string);
    const code = getCodeById(root, property.value?.value as string);
    if (!name || !code) {
      return;
    }
    const breakdownFields = Object.keys(this.breakdownPropertyExtender(
      code,
      property.name,
      property.value.value as string,
      name,
    ));
    return breakdownFields;
  }

  private getAddedPropertyFromPatch(patch: AssignPiaPatch): Property[] {
    const properties: Property[] = [];

    if (patch.addedAssemblies) {
      for (const addAssembly of patch.addedAssemblies) {
        this.setAddPropertyFromAddItem(properties, addAssembly.items);
      }
    }

    if (patch.updatedAssemblies) {
      for (const updateAssembly of patch.updatedAssemblies) {
        if (updateAssembly.addedItems) {
          this.setAddPropertyFromAddItem(properties, updateAssembly.addedItems);
        }

        if (updateAssembly.updatedItems) {
          this.setAddPropertyFromUpdateItem(properties, updateAssembly.updatedItems);
        }
      }
    }

    if (patch.addedItems) {
      this.setAddPropertyFromAddItem(properties, patch.addedItems);
    }

    if (patch.updatedItems) {
      this.setAddPropertyFromUpdateItem(properties, patch.updatedItems);
    }

    return properties;
  }

  private setAddPropertyFromUpdateItem(properties: Property[], items: AssignItemPatch[]): void {
    if (!items) {
      return;
    }
    for (const updateItem of items) {
      if (updateItem.addedProperties) {
        this.setAddProperty(properties, updateItem.addedProperties);
      }
      if (updateItem.updatedProperties) {
        for (const updatedProperty of updateItem.updatedProperties) {
          if (updatedProperty.value) {
            if (PropertiesTypeGuards.isBreakdown(updatedProperty)) {
              this.setAdditionalBBField(updatedProperty);
            }
          }
        }
      }
    }
  }

  private setAddPropertyFromAddItem(properties: Property[], items: Item[]): void {
    if (!items) {
      return;
    }
    for (const addItem of items) {
      this.setAddProperty(properties, addItem.properties);
    }
  }

  private setAddProperty(properties: Property[], addProperties: Property[]): void {
    if (!addProperties) {
      return;
    }
    for (const addProperty of addProperties) {
      properties.push(addProperty);
    }
  }

  private setDeletedAssemblyProperties(
    properties: string[],
    drawingAssign: AssignedPia,
    deletedAssemblies: string[],
  ): void {
    if (!drawingAssign.assemblies) {
      return;
    }
    if (!deletedAssemblies) {
      return;
    }
    for (const deletedAssemblyName of deletedAssemblies) {
      const deletedAssembly = drawingAssign.assemblies.find(a => a.name === deletedAssemblyName);
      if (!deletedAssembly) {
        continue;
      }
      if (!deletedAssembly.items) {
        continue;
      }
      for (const item of deletedAssembly.items) {
        if (!item.properties) {
          continue;
        }
        for (const property of item.properties) {
          properties.push(property.name);
        }
      }
    }
  }

  private setUpdateAssemblyProperties(
    properties: string[],
    drawingAssign: AssignedPia,
    updatedAssemblies: AssignAssemblyPatch[],
  ): void {
    if (!updatedAssemblies) {
      return;
    }

    if (!drawingAssign.assemblies) {
      return;
    }

    for (const updatedAssemblyPatch of updatedAssemblies) {
      const updateAssembly = drawingAssign.assemblies.find(a => a.name === updatedAssemblyPatch.name);
      if (!updateAssembly) {
        continue;
      }
      this.setUpdatedItemProperties(properties, updateAssembly.items, updatedAssemblyPatch.updatedItems);
      this.setDeleteItemProperties(properties, updateAssembly.items, updatedAssemblyPatch.deletedItems);
    }
  }

  private setUpdatedItemProperties(
    properties: string[],
    assignItems: AssignedPiaItem[],
    updatedItems: AssignItemPatch[],
  ): void {
    if (!assignItems) {
      return;
    }
    if (!updatedItems) {
      return;
    }

    for (const updateItemPatch of updatedItems) {
      const updateItem = assignItems.find(i => i.name === updateItemPatch.name);
      if (!updateItem) {
        continue;
      }

      if (updateItemPatch.deletedProperties) {
        for (const property of updateItemPatch.deletedProperties) {
          properties.push(property);
        }
      }

      if (updateItemPatch.updatedProperties) {
        for (const property of updateItemPatch.updatedProperties) {
          const { originProperties } = this.getCommonAssignInfo();
          const originProperty = originProperties.find(op => op.name === property.name);
          if (originProperty) {
            if (PropertiesTypeGuards.isBreakdown(originProperty)) {
              if (property.value) {
                const root = originProperty.value.root;
                const prevPropertyValue = updateItem.properties.find(p => p.name === property.name);
                const code = getCodeById(root, prevPropertyValue.value?.value as string);
                const parts = code?.split(Constants.codeSeparator);
                this.deleteBBAdditionalColumns(originProperty, parts?.length);
              }
              if (!property.value) {
                properties.push(originProperty.name);
              }
            } else {
              if (!property.value) {
                properties.push(property.name);
              }
            }
          } else {
            if (!property.value) {
              properties.push(property.name);
            }
          }
        }
      }
    }
  }

  private setDeleteItemProperties(
    properties: string[],
    assignItems: AssignedPiaItem[],
    deletedItems: string[],
  ): void {
    if (!assignItems) {
      return;
    }
    if (!deletedItems) {
      return;
    }

    for (const deleteItemName of deletedItems) {
      const deleteItem = assignItems.find(i => i.name === deleteItemName);
      if (!deleteItem) {
        continue;
      }
      if (!deleteItem.properties) {
        continue;
      }
      for (const property of deleteItem.properties) {
        properties.push(property.name);
        if (property.value) {
          if (PropertiesTypeGuards.isBreakdown(property)) {
            this.deleteBBAdditionalColumns(property);
          }
        }
      }
    }
  }

  private deleteBBAdditionalColumns(property: AssignedPiaProperty, size?: number): void {
    for (const key of Object.keys(this.fieldsCount)) {
      const [, level] = isPropertyBreakDownProperty(key);
      if (level !== undefined && key.includes(property.name) && (size === undefined || level < size)) {
        this.deleteField(key);
      }
    }
  }
}
