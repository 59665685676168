import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { AsyncIterationExecuter } from 'common/utils/async-iteration-executor';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import {
  ContextObserver,
  ContextObserverWithPrevious,
} from '../../drawings-contexts';
import { DrawingsInstanceType } from '../../enums';
import { DrawingsDrawMode } from '../../enums/drawings-draw-mode';
import { DrawingsGeometryEntityState } from '../../enums/drawings-geometry-entity-state';
import { DrawingsColorCacheHelper } from '../../helpers/drawings-color-cache-helper';
import {
  DrawingsPointInfo,
  ShortPointDescription,
} from '../../interfaces/drawing-ai-annotation';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingGeometryUpdateEventHandler } from '../../interfaces/drawing-update-events-handlers';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import {
  DrawingsAllowedPathType,
  DrawingsGeometryParams,
  DrawingsGeometryType,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
} from '../../interfaces/drawings-geometry';
import { DrawingsGeometryInstanceShort } from '../../interfaces/drawings-geometry-instance';
import { DrawingsGeometryStyle } from '../../interfaces/drawings-geometry-style';
import { DrawingsInstanceMeasure } from '../../interfaces/drawings-instance-measure';
import { DrawingsMeasureType } from '../../interfaces/drawings-measures';
import { DrawingsGeometryUtils } from '../../utils/drawings-geometry-utils';
import {
  DrawingsAddRemovePointResults,
  DrawingsDragEntityEventHandler,
  DrawingsGeometryEntityPolygon,
  DrawingsMouseEventHandler,
} from '../drawings-geometry-entities';
import {
  DrawingsGeometryEntityCount,
} from '../drawings-geometry-entities/drawings-geometry-entity-count';
import {
  DrawingsGeometryEntityPolyline,
} from '../drawings-geometry-entities/drawings-geometry-entity-polyline';
import {
  DrawingsGeometrySelectionArea,
} from '../drawings-geometry-entities/utility';
import {
  BatchedUpdateCallback,
  DrawingsEngineFlipType,
  EditPermissions,
  GetCurrentDrawingCallback,
  GetDrawingInstanceCallback,
  GetInstanceMeasureCallback,
  GetPointCoordinatesCallback,
  HoverChangedEventHandler,
  InstanceGeometryManager,
  InstancesManager,
  InstancesSelectionManager,
  SendMeasuresUpdateCallback,
  SetCursorHintCallback,
  SetDrawModeCallback,
  ShapeFactory,
  StrictAngleController,
} from '../interfaces';
import { DrawingsDragProcessingHelper } from './drag-instances/drawings-drag-processing-helper';
import { DrawingsEditSegmentHelper } from './drawings-edit-segment-helper';
import { DrawingsGeometryContinue } from './drawings-geometry-continue';
import { DrawingsGeometryConversionProcessor } from './drawings-geometry-conversion-processor';
import { DrawingsGeometryDragEventHelper } from './drawings-geometry-drag-event-helper';
import { AlignmentType, DrawingsGeometryModify, ModifiableInstanceType } from './drawings-geometry-modify';
import { DrawingsInstancesChangesProcessor } from './drawings-instances-changes-processor';
import { DrawingsRenderedPointsCache } from './drawings-rendered-points-cache';
import { DrawingsViewHelper } from './drawings-view-helper';
import {
  CountFactory,
  GeometryInstancesFactory,
  PolygonFactory,
  PolylineFactory,
  RectangleFactory,
} from './geometry';
import { InstancesRotationHelper } from './rotation';
import { DrawingsGeometrySnappingHelper } from './snapping';
import { DrawingsCursorTypeHelper } from './visual-helpers';
import { BoundingRectHelper } from './visual-helpers/bounding-rect-helper';


export interface DrawingsGeometryEntityHelperConfig {
  cursorHelper: DrawingsCursorTypeHelper;
  renderParamsContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  editPermissionsObserver: ContextObserver<EditPermissions>;
  onEntityClick: DrawingsMouseEventHandler;
  dragHelper: DrawingsDragProcessingHelper;
  viewHelper: DrawingsViewHelper;
  snappingHelper: DrawingsGeometrySnappingHelper;
  cachedPoints: DrawingsRenderedPointsCache;
  changeDrawMode: SetDrawModeCallback;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  getPoint: (id: string) => paper.Point;
  startDragUtilityPoint: (id: string, e: PaperMouseEvent) => void;
  getPointInfo: (pointId: string) => DrawingsPointInfo;
  getPointCoordinates: GetPointCoordinatesCallback;
  getCurrentDrawing: GetCurrentDrawingCallback;
  onDoubleMainGeometryClick: DrawingsMouseEventHandler;
  onDragEntity: DrawingsDragEntityEventHandler;
  onSelectSegmentMeasure: DrawingsMouseEventHandler;
  sendMeasuresUpdate: SendMeasuresUpdateCallback;
  getDrawingInstance: GetDrawingInstanceCallback;
  getPaperColor: (color: string) => DrawingsPaperColorInfo;
  getColorOfInstance: (instanceId: string) => string;
  getInstanceMeasures: GetInstanceMeasureCallback;
  onUpdateInstances: DrawingGeometryUpdateEventHandler;
  colorCacheHelper: DrawingsColorCacheHelper;
  changePointsPositionAndMeasures: (
    updatedPoints: Record<string, ShortPointDescription>,
    instancesMeasures: DrawingsInstanceMeasure[],
  ) => void;
  cancelModify: () => void;
  geometryConversionProcessor: DrawingsGeometryConversionProcessor;
  dragEventsHelper: DrawingsGeometryDragEventHelper;
  strictAngleController: StrictAngleController;
  shapeCreator: ShapeFactory;
  onHoverInstanceChanged: HoverChangedEventHandler;
  setCursorHint: SetCursorHintCallback;
  isDrawingEnabled: () => boolean;
}

export class DrawingsGeometryEntityHelper implements
  InstanceGeometryManager,
  InstancesManager,
  InstancesSelectionManager {
  private readonly config: DrawingsGeometryEntityHelperConfig;

  private instances: Map<string, ModifiableInstanceType>
    = new Map<string, ModifiableInstanceType>();

  private calibrationLine: DrawingsGeometryEntityPolyline;
  private visualSearchPolygon: DrawingsGeometryEntityPolygon;
  private drawingsGeometryModify: DrawingsGeometryModify;
  private changesProcessor: DrawingsInstancesChangesProcessor;
  private editSegmentHelper: DrawingsEditSegmentHelper;
  private continue: DrawingsGeometryContinue;

  /* layers */
  private layer: paper.Layer;
  private countLayer: paper.Layer;
  private pointsLayer: paper.Layer;
  private linesLayer: paper.Layer;
  private textLayer: paper.Layer;


  private _changesProcessors: Record<keyof DrawingsGeometryStyle, AsyncIterationExecuter> = {
    ['color']: new AsyncIterationExecuter(),
    ['strokeWidth']: new AsyncIterationExecuter(),
    ['strokeStyle']: new AsyncIterationExecuter(),
    ['shape']: new AsyncIterationExecuter(),
  };

  private _instancesRotationHelper: InstancesRotationHelper;
  private _selectionBoundingRectHelper: BoundingRectHelper;

  private _instancesFactory: GeometryInstancesFactory;

  constructor(config: DrawingsGeometryEntityHelperConfig) {
    this.config = config;
    this.layer = new paper.Layer();
    this.linesLayer = new paper.Layer();
    this.pointsLayer = new paper.Layer();
    this.textLayer = new paper.Layer();
    this.countLayer = new paper.Layer();

    this.changesProcessor = new DrawingsInstancesChangesProcessor(
      {
        getPointInfo: this.config.getPointInfo,
        getDrawingInstance: config.getDrawingInstance,
        getPointCoordinates: config.getPointCoordinates,
        getColorOfInstance: this.config.getColorOfInstance,
        getInstanceMeasures: this.config.getInstanceMeasures,
        cachedPoints: this.config.cachedPoints,
        getCurrentDrawing: this.config.getCurrentDrawing,
        entityRenderHelper: this,
        colorCacheHelper: this.config.colorCacheHelper,
        textRenderParamsObserver: this.config.textRenderParamsObserver,
        changePointsPositionAndMeasures: this.config.changePointsPositionAndMeasures,
        onBatchUpdateGeometries: this.config.onBatchUpdateGeometries,
      });

    this.continue = new DrawingsGeometryContinue({
      snappingHelper: this.config.snappingHelper,
      viewHelper: this.config.viewHelper,
      getCurrentDrawing: this.config.getCurrentDrawing,
      getDrawingInstance: this.config.getDrawingInstance,
      sendMeasuresUpdate: this.config.sendMeasuresUpdate,
      updateGeometry: this.config.onUpdateInstances,
      getInstanceMeasures: this.config.getInstanceMeasures,
      changesProcessor: this.changesProcessor,
      cachedPoints: this.config.cachedPoints,
      renderParametersContextObserver: this.config.renderParamsContextObserver,
      setCursorHint: this.config.setCursorHint,
    });
    this.editSegmentHelper = new DrawingsEditSegmentHelper({
      instancesGeometryManager: this,
      instancesSelectionManager: this,
      changesProcessor: this.changesProcessor,
      dragHelper: this.config.dragHelper,
      cachedPoints: this.config.cachedPoints,
      getPointInfo: this.config.getPointInfo,
      cursorHelper: this.config.cursorHelper,
      getInstance: this.config.getDrawingInstance,
      editPermissionsObserver: this.config.editPermissionsObserver,
      geometryConversionProcessor: this.config.geometryConversionProcessor,
      getDrawingInfo: this.config.getCurrentDrawing,
      onBatchUpdateGeometries: this.config.onBatchUpdateGeometries,
      isDrawingEnabled: this.config.isDrawingEnabled,
    });
    this.drawingsGeometryModify = new DrawingsGeometryModify({
      rendererHelper: this,
      cursorHelper: this.config.cursorHelper,
      dragHelper: this.config.dragHelper,
      cachedPoints: this.config.cachedPoints,
      changesProcessor: this.changesProcessor,
      getInstance: this.config.getDrawingInstance,
      viewHelper: this.config.viewHelper,
      cancelModify: this.config.cancelModify,
      getPointInfo: this.config.getPointInfo,
      getCurrentPageInfo: this.config.getCurrentDrawing,
    });

    this._instancesRotationHelper = new InstancesRotationHelper({
      dragProcessingHelper: this.config.dragHelper,
      pointsPositionEditor: this.changesProcessor,
      pointsManager: this.config.cachedPoints,
      getSelectedIds: () => [...this.selectedInstancesIdsIterator()],
      getSelectionRect: () => this._selectionBoundingRectHelper.selectionRect,
      getCurrentPageInfo: this.config.getCurrentDrawing,
      instanceManager: this,
      strictController: this.config.strictAngleController,
      getInstance: this.config.getDrawingInstance,
      getPointCoordinates: this.config.getPointCoordinates,
    });

    this._selectionBoundingRectHelper = new BoundingRectHelper({
      renderParamsContextObserver: this.config.renderParamsContextObserver,
      textRenderParamsObserver: this.config.textRenderParamsObserver,
      editPermissionsObserver: this.config.editPermissionsObserver,
      onDragEntity: this.config.onDragEntity,
      cursorHelper: this.config.cursorHelper,
      pointsLayer: this.pointsLayer,
      getRotationProcessor: () => this._instancesRotationHelper.rotationProcessor,
      dragEventsHelper: this.config.dragEventsHelper,
    });


    const strokedConfig = {
      layer: this.layer,
      textLayer: this.textLayer,
      pointLayer: this.pointsLayer,
      lineLayer: this.linesLayer,
      addPoint: this.changesProcessor.addPoint,
      startDragPoint: this.drawingsGeometryModify.startDragPoint,
      onSelectSegmentMeasure: config.onSelectSegmentMeasure,
      editPermissionsObserver: this.config.editPermissionsObserver,
      onStartDrag: config.onDragEntity,
      segmentDragHelper: this.editSegmentHelper,
      colorHelper: this.config.colorCacheHelper,
    };

    const countConfig = {
      colorHelper: this.config.colorCacheHelper,
      layer: this.countLayer,
      tryRemovePoint: this.changesProcessor.onRemoveCountPoint,
      shapeFactory: this.config.shapeCreator,
      onStartDrag: this.drawingsGeometryModify.startDragPoint,
    };


    this._instancesFactory = new GeometryInstancesFactory({
      factories: [
        {
          typeguard: DrawingsGeometryUtils.isPolygon,
          factory: new PolygonFactory(strokedConfig),
        },
        {
          typeguard: DrawingsGeometryUtils.isPolyline,
          factory: new PolylineFactory(strokedConfig),
        },
        {
          typeguard: DrawingsGeometryUtils.isCount,
          factory: new CountFactory(countConfig),
        },
        {
          typeguard: DrawingsGeometryUtils.isRectangle,
          factory: new RectangleFactory(strokedConfig),
        },
      ],
      commonGeometryConfig: {
        onSelect: config.onEntityClick,
        getPointInfo: config.getPoint,
        cursorHelper: config.cursorHelper,
        renderParamsContextObserver: config.renderParamsContextObserver,
        textRenderParamsObserver: config.textRenderParamsObserver,
        onDoubleClick: config.onDoubleMainGeometryClick,
        dragEventsHelper: config.dragEventsHelper,
        onMouseEnter: this.onInstanceMouseEnter,
        onMouseLeave: this.onInstanceMouseLeave,
      },
    });
  }

  public isInstanceSelected(instanceId: string): boolean {
    return this.instances.get(instanceId).selected;
  }

  public getInstancesIterator(): Iterable<[string, ModifiableInstanceType]> {
    return this.instances.entries();
  }

  public get isVisualSearchRendered(): boolean {
    return !!this.visualSearchPolygon;
  }

  public get isCalibrationRendered(): boolean {
    return !!this.calibrationLine;
  }

  public get renderedInstancesIds(): IterableIterator<string> {
    return this.instances.keys();
  }

  public get isModifyEnabled(): boolean {
    return this.drawingsGeometryModify.modifyEnabled;
  }

  public get isContinueEnabled(): boolean {
    return this.continue.enabled;
  }

  public get modifyInstanceId(): string {
    return this.drawingsGeometryModify.instanceId;
  }

  public get selectedPoints(): string[] {
    return this.drawingsGeometryModify.selectedPoints;
  }

  public get selectionPosition(): paper.Point {
    return this._selectionBoundingRectHelper.rectPosition;
  }

  public get entityForContinueId(): string {
    return this.continue.entityForProcessing ? this.continue.entityForProcessing.id : undefined;
  }

  public set entityForContinueId(value: string) {
    if (value) {
      this.renderInstanceById(value);
      this.continue.entityForProcessing = this.getInstance(value) as DrawingsGeometryEntityCount;
    } else if (this.continue.enabled) {
      this.continue.entityForProcessing = undefined;
    }
  }

  public set changePointsSelection(value: (ids: string[]) => void) {
    this.drawingsGeometryModify.changePointsSelection = value;
  }

  public changeEntitiesGeometryParams<T extends keyof DrawingsGeometryParams>(
    ids: string[],
    field: T,
    value: DrawingsGeometryParams[T],
  ): void {
    for (const id of ids) {
      const instance = this.instances.get(id);
      if (instance) {
        instance.updateGeometryParam(field, value);
      }
    }
  }

  public async changeEntitiesStyles<T extends keyof DrawingsGeometryStyle>(
    ids: string[],
    field: T,
    value: DrawingsGeometryStyle[T],
  ): Promise<void> {
    const processor =  this._changesProcessors[field];
    for await (const id of processor.startIteration(ids)) {
      const instance = this.instances.get(id);
      if (instance) {
        instance.updateStyle(field, value);
      }
    }
  }

  public updateSelectionRectPointVisibilityIfNeed(): void {
    this._selectionBoundingRectHelper.updatePointVisibility();
  }


  public startModify(value: string): void {
    this.drawingsGeometryModify.startModify(value);
  }

  public disableModify(): void {
    this.drawingsGeometryModify.stopModify();
  }

  public setSelectedPointsAlignment(alignment: AlignmentType): void {
    this.drawingsGeometryModify.setSelectedPointsAlignment(alignment);
  }

  public destroy(): void {
    this.layer.remove();
    this.linesLayer.remove();
    this.pointsLayer.remove();
    this.textLayer.remove();
    this.countLayer.remove();
    Object.values(this._changesProcessors).forEach((processor) => processor.cancel());
    this.removeSelectionBoundingRect();
    this.continue.destroy();
  }

  @autobind
  public flipElements(ids: string[], flipType: DrawingsEngineFlipType): void {
    this.changesProcessor.flipElements(ids, flipType);
  }

  @autobind
  public rotateElements(ids: string[], angle: number): void {
    this._instancesRotationHelper.rotateByIds(ids, angle);
  }

  @autobind
  public updatePointPositionInCalibration(pointId: string): DrawingsMeasureType {
    return this.instances.get(DrawingsCanvasConstants.calibrateLineId).updatePointsInEntity([pointId]);
  }

  @autobind
  public updatePointsPositionInInstance(pointIds: string[], instanceId: string): DrawingsInstanceMeasure | null {
    const measures = this.instances.get(instanceId).updatePointsInEntity(pointIds);
    if (!measures) {
      return null;
    }

    return {
      id: instanceId,
      measures,
      type: this.config.getDrawingInstance(instanceId).type,
      color: this.config.getColorOfInstance(instanceId),
    };
  }

  @autobind
  public removeEntityById(id: string): void {
    if (this.instances.has(id)) {
      if (this.continue.enabled && this.continue.entityForProcessing.id === id) {
        this.continue.entityForProcessing = null;
        this.config.changeDrawMode(DrawingsDrawMode.Disabled);
      }
      this.instances.get(id).destroy();
      this.instances.delete(id);
    }
  }

  public removeCalibration(): void {
    this.calibrationLine.destroy();
    this.calibrationLine = null;
  }

  public clear(): void {
    for (const polygon of this.instances.values()) {
      polygon.destroy();
    }
    this.instances.clear();
  }

  @autobind
  public renderInstanceById(instanceId: string, overrodeId?: string): void {
    const instance = this.config.getDrawingInstance(instanceId);
    this.renderInstance(instanceId, instance, overrodeId);
  }

  public renderInstance(instanceId: string, instance: DrawingsGeometryInstanceShort, overrodeId?: string): void {
    const id = overrodeId || instanceId;
    const color = this.getInstanceColor(instanceId, instance);
    const overrodeProps = overrodeId ? { id: overrodeId } : {};

    this.removeIncorrectElement(id, instance);

    if (this.instances.has(id)) {
      this.instances.get(id).updateGeometry(instance.geometry);
    } else {
      if (instanceId === DrawingsCanvasConstants.visualSearch || instanceId === DrawingsCanvasConstants.visualSearch) {
        (overrodeProps as any).startDragPoint = this.config.startDragUtilityPoint;
      }
      const rendered = this._instancesFactory.render(id, instance.geometry, instance.type, color, overrodeProps);
      if (rendered) {
        this.instances.set(id, rendered);
      }
    }
  }


  public drawCalibrationLine(
    geometry: DrawingsPolylineGeometry,
    color: DrawingsPaperColorInfo,
  ): void {
    if (this.isCalibrationRendered) {
      this.calibrationLine.updateGeometry(geometry);
    } else {
      const config = {
        canMoveSegment: false,
        startDragPoint: this.config.startDragUtilityPoint,
        instanceType: DrawingsInstanceType.CalibrationLine,
      };
      this.calibrationLine = this._instancesFactory.render(
        DrawingsCanvasConstants.calibrateLineId,
        geometry,
        DrawingsInstanceType.Polyline,
        color,
        config,
      ) as DrawingsGeometryEntityPolyline;
    }
  }

  public setEntitiesState(ids: string[], state: DrawingsGeometryEntityState): string[] {
    this.removeSelectionBoundingRect();
    const selectedIds = [];
    const currentDrawing = this.config.getCurrentDrawing();
    const currentDrawingId = currentDrawing ? currentDrawing.drawingId : undefined;
    for (const id of ids) {
      const instance = this.config.getDrawingInstance(id);
      if (this.instances.has(id)) {
        this.instances.get(id).state = state;
        selectedIds.push(id);
      } else if (instance && instance.drawingId === currentDrawingId) {
        selectedIds.push(id);
      }
    }
    if (state === DrawingsGeometryEntityState.Selected) {
      this.renderSelectionBoundingRectByIds(ids);
    }
    return selectedIds;
  }

  public recalculateSelectionState(): void {
    const idsIterator = this.selectedInstancesIdsIterator();
    this.renderSelectionBoundingRectByIds(idsIterator);
  }


  public renderSelectionBoundingRectByIds(ids: Iterable<string>): void {
    this.removeSelectionBoundingRect();
    const bounds = this.getEntityBounds(ids);
    if (bounds) {
      this.renderSelectionBoundingRect(bounds);
    }
  }

  public getEntityBounds(instancesIds: Iterable<string>): paper.Rectangle {
    const lefts = [];
    const rights = [];
    const tops = [];
    const bottoms = [];
    for (const id of instancesIds) {
      if (this.instances.has(id)) {
        const bounds = this.instances.get(id).bounds;
        tops.push(bounds.top);
        bottoms.push(bounds.bottom);
        rights.push(bounds.right);
        lefts.push(bounds.left);
      }
    }
    if (lefts.length) {
      const x = Math.min(...lefts);
      const y = Math.min(...tops);
      const x2 = Math.max(...rights);
      const y2 = Math.max(...bottoms);
      return new paper.Rectangle(
        new paper.Point(x, y),
        new paper.Size(x2 - x, y2 - y),
      );
    }
  }

  public setEntityState(id: string, state: DrawingsGeometryEntityState): void {
    this.removeSelectionBoundingRect();
    if (this.instances.has(id)) {
      this.instances.get(id).state = state;
      if (state === DrawingsGeometryEntityState.Selected) {
        this.renderSelectionBoundingRect(this.getEntityBounds([id]));
      }
    }
  }

  @autobind
  public* instancesInRectIdsIterator(
    rect: paper.Rectangle,
    selectionArea: DrawingsGeometrySelectionArea,
    ctrlKey?: boolean,
  ): IterableIterator<string> {
    if (this.drawingsGeometryModify.modifyEnabled) {
      const entity = this.instances.get(this.drawingsGeometryModify.instanceId);
      entity.changeSelectedPointsByRectangle(rect, ctrlKey);
      if (!ctrlKey) {
        yield this.drawingsGeometryModify.instanceId;
      }
    } else {
      for (const [id, polygon] of this.instances.entries()) {
        if (polygon.isExistsInRect(rect, selectionArea)) {
          yield id;
        }
      }
    }
  }

  public splitBySelectedModifyPoints(): void {
    this.drawingsGeometryModify.splitByPoints();
  }

  public removeModifySelectedPoints(): void {
    this.drawingsGeometryModify.removeSelectedPoints();
  }

  public updatePointInVisualSearch(pointId: string): boolean {
    return !!this.visualSearchPolygon.updatePointsInEntity([pointId]);
  }

  public updateSelectionBoundingRectPosition(diff: paper.Point): void {
    this._selectionBoundingRectHelper.updateRectPosition(diff);
  }

  public removeSelectionBoundingRect(): void {
    this._selectionBoundingRectHelper.destroy();
  }

  public canRemovePoints(pointIds: string[], instanceId?: string): boolean {
    if (pointIds[0].startsWith(DrawingsCanvasConstants.visualSearch)) {
      return this.instances.get(DrawingsCanvasConstants.visualSearch).canRemovePoints(pointIds);
    } else {
      if (this.instances.has(instanceId)) {
        if (!this.instances.get(instanceId).canRemovePoints(pointIds)) {
          return false;
        }
      } else {
        const { type, geometry } = this.config.getDrawingInstance(instanceId);
        if (DrawingsGeometryUtils.isPolyGeometry(type, geometry)) {
          if (!DrawingsGeometryUtils.canRemovePointFromNotVisibleData(
            geometry as DrawingsPolygonGeometry,
            pointIds, this.config.getPoint,
          )) {
            return false;
          }
        } else if (DrawingsGeometryUtils.isCount(type, geometry)) {
          if (geometry.points.length - pointIds.length < 1) {
            return false;
          }
        }
      }
    }
    return true;
  }

  public addPointsToInstances(
    instanceId: string,
    line: [string, string],
    pointId: string[],
  ): DrawingsAddRemovePointResults<DrawingsGeometryType, DrawingsMeasureType> {
    const instance = this.config.getDrawingInstance(instanceId);
    const renderedInstance = this.getInstance(instanceId);
    return renderedInstance
      ? renderedInstance.addPoints(line, pointId)
      : DrawingsGeometryUtils.addPointToHiddenInstance(instance.geometry, instance.type, pointId, line);
  }

  @autobind
  public getInstance(instanceId: string): ModifiableInstanceType {
    if (instanceId.startsWith(DrawingsCanvasConstants.visualSearch)) {
      return this.visualSearchPolygon;
    } else {
      return this.instances.get(instanceId);
    }
  }

  @autobind
  public getInstanceGeometry(instanceId: string): DrawingsGeometryType {
    if (this.instances.has(instanceId)) {
      return this.instances.get(instanceId).getGeometry();
    }
    return null;
  }

  @autobind
  public getInstancePath(instanceId: string): DrawingsAllowedPathType {
    if (this.instances.has(instanceId)) {
      return this.instances.get(instanceId).getPath();
    }
    return null;
  }

  public updateGeometry(instanceId: string, geometry: DrawingsGeometryType): DrawingsMeasureType {
    if (this.instances.has(instanceId)) {
      return this.instances.get(instanceId).updateGeometry(geometry);
    }
    return null;
  }

  public removePoint(
    pointIds: string[],
    instanceId: string,
  ): DrawingsAddRemovePointResults<DrawingsGeometryType, DrawingsMeasureType> {
    const instance = this.config.getDrawingInstance(instanceId);
    const renderedInstance = this.getInstance(instanceId);
    if (renderedInstance) {
      return renderedInstance.removePoints(pointIds);
    } else if (instance && DrawingsGeometryUtils.isCount(instance.type, instance.geometry)) {
      return DrawingsGeometryUtils.removePointsFromNotVisibleCount(instance.geometry, pointIds);
    }
    return null;
  }

  public isInstanceRendered(instanceId: string): boolean {
    return this.instances.has(instanceId);
  }

  @autobind
  public getVisibleInstancesIds(): string[] {
    return [...this.instances.keys()];
  }

  private renderSelectionBoundingRect(bounds: paper.Rectangle): void {
    this._selectionBoundingRectHelper.renderByBounds(bounds);
  }

  private *selectedInstancesIdsIterator(): Iterable<string> {
    for (const [id, value] of this.instances.entries()) {
      if (value.state === DrawingsGeometryEntityState.Selected) {
        yield id;
      }
    }
  }

  private removeIncorrectElement(id: string, instance: DrawingsGeometryInstanceShort): void {
    if (this.instances.has(id) && this.instances.get(id).instanceType !== instance.type) {
      this.removeEntityById(id);
    }
  }

  private getInstanceColor(instanceId: string, instance: DrawingsGeometryInstanceShort): DrawingsPaperColorInfo {
    const color = instance.geometry.color || this.config.getColorOfInstance(instanceId);
    return this.config.getPaperColor(color);
  }

  @autobind
  private onInstanceMouseEnter(id: string, e: PaperMouseEvent): void {
    this.config.onHoverInstanceChanged({ id, hovered: true, event: e });
  }

  @autobind
  private onInstanceMouseLeave(id: string, e: PaperMouseEvent): void {
    this.config.onHoverInstanceChanged({ id, hovered: false, event: e });
  }
}

