import autobind from 'autobind-decorator';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { EngineObjectConfig } from '../../common';
import {
  Completable,
  DrawModeProcessor,
  SetDrawModeCallback,
  ToolMode,
} from '../../interfaces';
import { BaseEventsController } from './base-events-controller';


interface DrawingsKeyListenerHelperConfig extends EngineObjectConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  drawProcessor: DrawModeProcessor;
  knifeHelper: Completable & ToolMode;
  changeDrawMode: SetDrawModeCallback;
}

export class DrawingsDrawKeyListenerHelper extends BaseEventsController<DrawingsKeyListenerHelperConfig> {
  public override activate(): void {
    if (this._isActive) {
      return;
    }
    const { api, drawProcessor } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.FinishDraw, this.onFinishDrawClick);
    api.addKeyDownEventListener(ModifyHotkey.RemoveLastDrawnPoint, this.removeLastDrawnPoint);
    api.addKeyDownEventListener(ModifyHotkey.Escape, this.removeLastDrawnPoint);
    api.addKeyDownEventListener(ModifyHotkey.StrictAngleMode, drawProcessor.enableStrictAngleMode);
    api.addKeyUpEventListener(ModifyHotkey.StrictAngleMode, drawProcessor.disableStringAngleMode);
    super.activate();
  }

  public override deactivate(): void {
    const { api, drawProcessor } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.FinishDraw, this.onFinishDrawClick);
    api.removeKeyDownEventListener(ModifyHotkey.RemoveLastDrawnPoint, this.removeLastDrawnPoint);
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this.removeLastDrawnPoint);
    api.removeKeyDownEventListener(ModifyHotkey.StrictAngleMode, drawProcessor.enableStrictAngleMode);
    api.removeKeyUpEventListener(ModifyHotkey.StrictAngleMode, drawProcessor.disableStringAngleMode);
    super.deactivate();
  }

  @autobind
  private onFinishDrawClick(): void {
    const { drawProcessor, knifeHelper } = this._config;
    if (knifeHelper.isActive) {
      if (knifeHelper.canComplete(false)) {
        knifeHelper.finish(false);
      }
    } else {
      drawProcessor.finishWithAutocompleteIfPossible();
    }
  }

  @autobind
  private removeLastDrawnPoint(): void {
    const { drawProcessor, changeDrawMode } = this._config;
    if (!drawProcessor.isDrawingMode) {
      return;
    }
    if (drawProcessor.hasPoints()) {
      drawProcessor.removeLastPoint();
    } else if (
      drawProcessor.drawMode !== DrawingsDrawMode.Calibrate
      && drawProcessor.drawMode !== DrawingsDrawMode.CalibrateScale
    ) {
      changeDrawMode(DrawingsDrawMode.Disabled);
    }
  }

}
