import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { SubscriptionType } from 'common/constants/subscription';
import { State } from 'common/interfaces/state';
import { SpinnerPage } from 'routes/spinner-page';
import { BillingEntityStatus } from '../../enums/billing-entity-status';
import { SubscriptionPlanListingModel } from '../../interfaces/subscription-plan-listing-model';
import { BusinessSubscribePage } from '../business-subscribe-page';
import { InvitingPeoplePage } from '../inviting-people-page';
import { SubscribePage } from '../subscribe-page';
import { useShowInvitePage } from './hooks';

const SubscribePageWithRouter = withRouter(SubscribePage);

export const NoneSubscriptionPage: React.FC = () => {
  const subscriptionType = useSelector<State, SubscriptionType>(s => s.account.selectedSubscriptionType);
  const [showInvitePage, setShowInvitePage] = useShowInvitePage();
  const handleSubmitInvitePeople = useCallback(() => {
    setShowInvitePage();
  }, []);
  const isBusinessCompany = useSelector<State, boolean>(s => s.account.ownedCompany?.isBusiness);
  const plansModel = useSelector<State, SubscriptionPlanListingModel | null>(s => s.account.subscriptionPlans);
  const isPlanLoaded = useMemo(() => {
    return !!plansModel;
  }, [plansModel]);
  const variant = useMemo(() => {
    if (isPlanLoaded) {
      const plan = plansModel.plans.find(x => x.isFree);
      return plan && plan.variants.find(v => v.isHidden === true && v.status === BillingEntityStatus.Active);
    }
  }, [isPlanLoaded]);

  if (subscriptionType !== SubscriptionType.Takeoff2d) {
    return <SubscribePageWithRouter />;
  }

  if (showInvitePage) {
    return <InvitingPeoplePage afterSubmit={handleSubmitInvitePeople} />;
  }

  if (!isPlanLoaded) {
    return <SpinnerPage />;
  }

  if (isBusinessCompany && !!variant) {
    return <BusinessSubscribePage />;
  } else {
    return <SubscribePageWithRouter />;
  }
};
