import React, { useMemo } from 'react';

import { InviteUsersToProjectPanel } from 'components/dialog';
import { ProjectAccessReason } from 'unit-projects/enums';
import { CompanyProjectHeader } from 'unit-projects/interfaces/company-project-header';
import { Person } from '../../../people/interfaces/person';

interface Props {
  isAdmin: boolean;
  projectToInviteUsers: CompanyProjectHeader | null;
  onInvitePeople: (users: Person[], isSharedWithCompany: boolean) => void;
  onInviteToProjectDialogClose: () => void;
  onChangeSharedWithCompany?: () => void;
}

export const InviteUsersDialog: React.FC<Props> = (props) => {
  const {
    projectToInviteUsers,
    isAdmin,
    onChangeSharedWithCompany,
    onInvitePeople,
    onInviteToProjectDialogClose,
  } = props;
  const invitedUsers = useMemo(() => {
    return projectToInviteUsers
      && projectToInviteUsers.users
      && projectToInviteUsers.users.map(user => user.email);
  }, [projectToInviteUsers]);
  return (
    <InviteUsersToProjectPanel
      showCurrentUser={isAdmin && projectToInviteUsers?.accessReason !== ProjectAccessReason.Owner}
      onFormSubmit={onInvitePeople}
      onCloseDialog={onInviteToProjectDialogClose}
      invitedUsers={invitedUsers}
      projectType={projectToInviteUsers && projectToInviteUsers.type}
      isSharedWithCompany={projectToInviteUsers?.isCompanyShared}
      onChangeSharedWithCompany={onChangeSharedWithCompany}
    />
  );
};
