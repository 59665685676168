import GUI from 'lil-gui';
import { getOrCreateRoot } from 'common/UIKit';

export class TestingGui {
  private static _gui: GUI;

  public static getTestingGui(): GUI {
    if (!this._gui) {
      this._gui = new GUI({ closeFolders: true });
      this._gui.domElement.style.position = 'absolute';
      getOrCreateRoot().appendChild(this._gui.domElement);
    }
    return this._gui;
  }

  public static destroy(): void {
    if (this._gui) {
      this._gui.destroy();
      this._gui = null;
    }
  }
}

