import React, { useCallback, useMemo } from 'react';

import { useDownloadImage } from 'common/components/auth-image/use-download-image';
import { Text, TinyText } from 'common/components/text';
import { ColorsName } from 'common/enums/kreo-colors';
import { arrayUtils } from 'common/utils/array-utils';
import { ProjectsApi } from 'unit-projects/api/common';
import { DemoProject } from 'unit-projects/interfaces/demo-project';
import { Styled } from './styled';


interface Props {
  project: DemoProject;
  onClick: (id: number) => void;
}


export const DemoProjectCard: React.FC<Props> = ({ project, onClick }) => {
  const { id, name, description, tags, hasLogo } = project;
  const src = hasLogo ? ProjectsApi.buildDemoProjectScreenshotUrl(id) : '';
  const image = useDownloadImage(src);
  const sortedTags = useMemo(() => {
    if (!tags || !tags.length) {
      return null;
    }
    return tags.sort((a, b) => arrayUtils.localCompareWithNumber(a.toLowerCase(), b.toLowerCase()));
  }, [tags]);

  const onProjectClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <Styled.Container onClick={onProjectClick}>
        <Styled.ProjectPreview image={image}/>
      <Styled.ContentWrapper>
        <Styled.DescriptionContainer>
          <Text fontSize={14}>{name}</Text>
          <TinyText color={ColorsName.turquoiseInversed}>{description}</TinyText>
        </Styled.DescriptionContainer>
        {!!sortedTags && !!sortedTags.length ?
          <Styled.TagsContainer>
            {sortedTags.map(tag => (
              <Styled.Tag key={tag}>
                <TinyText>{tag}</TinyText>
              </Styled.Tag>
            ))}
          </Styled.TagsContainer>
          : null}
      </Styled.ContentWrapper>
    </Styled.Container>
  );
};
