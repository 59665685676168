import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { ColorControl } from 'common/components/color-control';
import { DrawingsActions } from 'common/components/drawings';
import { DuplicatePagePayload } from 'common/components/drawings/actions/payloads/common';
import { PdfPagePreview } from 'common/components/drawings/components/pdf-page-preview';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import {
  DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG,
  DrawingsDeletePage,
} from 'common/components/drawings/dialogs/delete-page-confirmation-dialog';
import {
  DrawingsSubmenuContainer,
} from 'common/components/drawings/drawings-canvas-menus/drawings-flying-menu/submenu-containers';
import { DrawingsPage } from 'common/components/drawings/interfaces/drawings-file-info';
import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { InteractiveMenu } from 'common/components/interactive-menu';
import { LabelWithRename } from 'common/components/label-with-rename';
import { ColorList } from 'common/constants/color-list';
import { ConstantFunctions } from 'common/constants/functions';
import { State } from 'common/interfaces/state';
import { KreoDialogActions, KreoIconDots } from 'common/UIKit';
import { UndoRedoContextApiProps, withUndoRedoApiProps } from 'common/undo-redo';
import { StringUtils } from 'common/utils/string-utils';
import { UuidUtil } from 'common/utils/uuid-utils';
import { getActiveNavigationType } from 'routes/quantity-take-off-2d/route-fast-navigation/get-fast-navigation-helpers';
import { CommentCountMark } from 'unit-2d-comments/comment-count-mark';
import { pushItemToUrl } from 'unit-2d-database/helpers/current-tab-getters';
import { FastNavigationLinks } from '../../../../../../../units/2d/constants/fast-navigation-links';
import { DrawingsPdfBrowserPageMenu } from './drawings-pdf-browser-page-menu';
import { Styled } from './styled';


interface OwnProps {
  page: DrawingsPage;
  selected: boolean;
  pageNames: string[];
  canEdit: boolean;
  onChangeDrawingSelection: (id: string, select: boolean) => void;
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void;
  copyDrawingGeometriesToPage: (pdfId: string, drawingId: string, targetDrawingId: string) => void;
}

interface StateProps {
  projectId: number;
  color?: string;
  name: string;
  selectedPages: string[];
  isOptimized: boolean;
  commentCount: number;
  hasInstances: boolean;
}


interface DispatchProps {
  onAddTab: (entityIds: string[]) => void;
  onRemoveTabs: (removedTabs: string[]) => void;
  openDialog: (name: string, data: any) => void;
  duplicatePage: (duplicateSettings: DuplicatePagePayload) => void;
  closePdfManagerDialog: () => void;
  onSelectDrawing: (drawingId: string) => void;
}

interface Props extends
OwnProps,
StateProps,
DispatchProps,
RouteComponentProps<{}>,
AbilityAwareProps,
UndoRedoContextApiProps { }

interface ComponentState {
  isMenuOpened: boolean;
  isColorSelectorOpened: boolean;
  menuPositionX: number;
  menuPositionY: number;
}

class DrawingsPdfBrowserPageComponent extends React.PureComponent<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMenuOpened: false,
      isColorSelectorOpened: false,
      menuPositionX: 0,
      menuPositionY: 0,
    };
  }

  public render(): React.ReactNode {
    const { selected, color, name, page, isOptimized, commentCount, canEdit: measurementEditPermission } = this.props;
    const itemColor = color || ColorList[0];
    return (
      <Styled.Container
        className='drawings-pdf-browser-page'
        onClick={this.onClick}
        onContextMenu={this.menuClick}
        selected={selected}
        isColorSelectorOpened={this.state.isColorSelectorOpened}
      >
        <CommentCountMark
          count={commentCount}
          showTooltip={true}
        />
        <InteractiveMenu
          renderMenuContent={this.renderColorControl}
          onOpenStatusChange={this.onOpenColorSelectorChange}
          disabled={!measurementEditPermission}
        >
          <Styled.Color itemColor={itemColor} />
        </InteractiveMenu>
        <Styled.CheckIcon selected={selected}><Icons.ApplyS /></Styled.CheckIcon>
        <Styled.Optimized
          selected={isOptimized}
          count={!!commentCount}
        >
          <Icons.ArrowBottomCircle />
        </Styled.Optimized>
        <PdfPagePreview
          pdfId={page.pdfId}
          pageId={page.drawingId}
          created={page.screenshotCreated}
        />
        <Styled.FloatPanel>
          <Styled.NameContainer
            onClick={this.onNameContainerClick}
            measurementEditPermission={this.props.canEdit}
          >
            <LabelWithRename
              value={name}
              changeValue={this.onRenameWithUndoRedo}
              fontSize={12}
              isHoverBackground={true}
              isHoverEditIcon={true}
              disableRenaming={!this.props.canEdit}
            />
          </Styled.NameContainer>
          {this.props.canEdit &&
            <Styled.Menu onClick={this.menuClick}>
              <KreoIconDots color='currentColor' />
              {this.state.isMenuOpened &&
                <DrawingsPdfBrowserPageMenu
                  left={this.state.menuPositionX}
                  top={this.state.menuPositionY}
                  onClose={this.onMenuClose}
                  deletePage={this.openDeletePageConfirmDialog}
                  duplicatePage={this.duplicatePageWrapped}
                  openPage={this.onOpenPage}
                />
              }
            </Styled.Menu>
          }
        </Styled.FloatPanel>
      </Styled.Container>
    );
  }

  @autobind
  private renderColorControl(): React.ReactNode {
    return (
      <DrawingsSubmenuContainer>
        <ColorControl
          colorList={ColorList}
          selectedColor={this.props.color || ColorList[0]}
          onColor={this.onColorChangeWithUndoRedo}
          title={'colour'}
        />
      </DrawingsSubmenuContainer>
    );
  }

  @autobind
  private onNameContainerClick(e: React.MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
  }

  @autobind
  private openDeletePageConfirmDialog(): void {
    const { name, page } = this.props;
    const data: DrawingsDeletePage = { name, pageId: page.drawingId, pdfId: page.pdfId };
    this.props.openDialog(DRAWING_DELETE_PAGE_CONFIRMATION_DIALOG, data);
  }

  @autobind
  private onClick(): void {
    if (this.state.isColorSelectorOpened) {
      return null;
    }
    const isRemove = this.props.selected;
    if (this.props.onChangeDrawingSelection) {
      this.props.onChangeDrawingSelection(this.props.page.drawingId, !isRemove);
      return;
    }
  }

  @autobind
  private onOpenPage(): void {
    const { selected, page } = this.props;
    const activeNavigationType = getActiveNavigationType(this.props.location.search);
    if (selected) {
      this.props.onSelectDrawing(page.drawingId);
    } else {
      this.props.onChangeDrawingSelection(page.drawingId, true);
    }
    this.onMenuClose();

    if (activeNavigationType !== FastNavigationLinks.Project) {
      pushItemToUrl(FastNavigationLinks.Project, this.props.history.push);
    } else {
      this.props.closePdfManagerDialog();
    }
  }

  @autobind
  private onRenameWithUndoRedo(value: string): void {
    const { onParameterUpdate, page, name } = this.props;
    if (name !== value) {
      const { undo, redo } = DrawingsUndoRedoHelper.onRenameWithUndoRedo(
        page.drawingId,
        value,
        name,
        onParameterUpdate,
      );
      this.props.addUndoRedo(undo, redo);
      redo();
    }
  }

  @autobind
  private onColorChangeWithUndoRedo(color: string): void {
    this.onColorUpdate(this.props.page.drawingId, color);
  }

  @autobind
  private onColorUpdate(drawingId: string, color: string): void {
    const { undo, redo } = DrawingsUndoRedoHelper.updateColorPageUndoRedo(
      drawingId,
      color,
      this.props.color,
      this.props.onParameterUpdate,
    );
    this.props.addUndoRedo(undo, redo);
    redo();
  }

  @autobind
  private onMenuClose(): void {
    this.setState({ isMenuOpened: false });
  }

  @autobind
  private onOpenColorSelectorChange(value: boolean): void {
    this.setState({ isColorSelectorOpened: value });
  }

  @autobind
  private menuClick(e: React.MouseEvent): void {
    ConstantFunctions.stopEvent(e);
    const MENU_HEIGHT = 120;
    const MARGIN_BOTTOM = 20;
    const BOTTOM_MENU_DISPLAY_HEIGHT = MENU_HEIGHT + MARGIN_BOTTOM;
    const windowHeight = window.innerHeight;

    if ((windowHeight - e.pageY) < BOTTOM_MENU_DISPLAY_HEIGHT) {
      const pageY = windowHeight - BOTTOM_MENU_DISPLAY_HEIGHT;
      this.setState({ isMenuOpened: !this.state.isMenuOpened, menuPositionX: e.pageX, menuPositionY: pageY });
    } else {
      this.setState({ isMenuOpened: !this.state.isMenuOpened, menuPositionX: e.pageX, menuPositionY: e.pageY });
    }
  }

  @autobind
  private duplicatePageWrapped(): void {
    const { hasInstances } = this.props;
    if  (hasInstances) {
      this.props.openDialog(
        DrawingDialogs.DUPLICATE_DRAWING_DIALOG,
        this.duplicatePage,
      );
    } else {
      this.duplicatePage(false);
    }

    this.onMenuClose();
  }

  @autobind
  private duplicatePage(copyGeometries: boolean): void {
    const { name, projectId, page } = this.props;
    const newPageId = UuidUtil.generateUuid();
    this.props.duplicatePage({
      projectId,
      pdfId: page.pdfId,
      pageId: page.drawingId,
      onDuplicated: copyGeometries
        ? () => this.props.copyDrawingGeometriesToPage(page.pdfId, page.drawingId, newPageId)
        : undefined,
      data: {
        id: newPageId,
        name: StringUtils.iterateName(this.props.pageNames, `${name} copy`),
      },
    });
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { drawingId, pdfId } = ownProps.page;
  const hasInstances = state.drawings.aiAnnotation.fileData[pdfId]
    && !!state.drawings.aiAnnotation.fileData[pdfId][drawingId]?.instances.length;
  return {
    isOptimized: state.drawings.drawingsInfo[drawingId]?.isOptimized,
    projectId: state.projects.currentProject?.id,
    color: state.drawings.drawingsInfo[drawingId]?.color,
    name: state.drawings.drawingsInfo[drawingId]?.name,
    selectedPages: state.drawings.selectedPages,
    commentCount: state.twoDComments.drawingCommentsCount[ownProps.page.drawingId],
    hasInstances,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    onAddTab: (entityIds) => dispatch(DrawingsActions.addTabs({ entityIds, openLastPage: true })),
    onRemoveTabs: (payload) => dispatch(DrawingsActions.removeTabs(payload)),
    duplicatePage: (payload) =>
      dispatch(DrawingsActions.duplicatePage(payload)),
    openDialog: (name, data) => dispatch(KreoDialogActions.openDialog(name, data)),
    closePdfManagerDialog: () => dispatch(KreoDialogActions.closeDialog(DrawingDialogs.PDF_MANAGER_DIALOG)),
    onSelectDrawing: (drawingId) => dispatch(DrawingsActions.selectDrawing(drawingId)),
  };
};

export const DrawingsPdfBrowserPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withAbilityContext(withUndoRedoApiProps(DrawingsPdfBrowserPageComponent)),
  ),
);
