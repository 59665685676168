import { Text } from '@kreo/kreo-ui-components';
import React from 'react';

import { PaymentMethodInfo } from '../interfaces';
import { PaymentMethodsDropdown } from '../payment-methods-dropdown';
import { Styled } from './styled';

interface Props {
  selectedPaymentId: string;
  paymentMethods: PaymentMethodInfo[];
  onSelectPayment: (paymentId: string) => void;
}

const BodyComponent: React.FC<Props> = ({
  selectedPaymentId,
  paymentMethods,
  onSelectPayment,
}) => {
  return (
    <Styled.PaymentDropdownContainer>
      <Styled.PaymentDropdownDescription>
        <Text
          color='turquoiseFont'
          fontSize='14'
        >
          Choose your payment method for this subscription
        </Text>
      </Styled.PaymentDropdownDescription>
      <Styled.PaymentDropdown>
        <PaymentMethodsDropdown
          selectedPaymentId={selectedPaymentId}
          paymentMethods={paymentMethods}
          onSelectPaymentMethod={onSelectPayment}
        />
      </Styled.PaymentDropdown>
    </Styled.PaymentDropdownContainer>
  );
};

export const Body = React.memo(BodyComponent);
