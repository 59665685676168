import { MovableContextMenu } from '@kreo/kreo-ui-components';
import React, { useCallback, useMemo } from 'react';

import { GlobalKeyboardEventsFocusRestore } from 'common/components/global-keyboard-events-controller';
import { useSetDrawMode } from '../../drawings-controls/hooks';
import { DrawingsDrawMode, DrawingsInstanceType } from '../../enums';
import {
  CopyBaseInstance,
  GeometryParams,
  useCopyInstanceSettingsToNewGeometry,
  useStartWizzardWithTargetType,
} from '../../hooks';
import {
  DrawingsGeometryInstanceWithIdAndGroupId,
  DrawingsSelectAggregationGroup,
  PivotedInstance,
} from '../../interfaces';
import { DrawingsMenuUtils } from '../../utils/drawings-menu-utils';
import { isPivotedInstance } from '../../utils/instances-utils/pivoted-typeguards';
import { DrawModeMenuItem } from '../draw-mode-menu-item';


export interface InstanceMenuCommonProps {
  positionX: number;
  positionY: number;
  selectedItem: DrawingsGeometryInstanceWithIdAndGroupId | PivotedInstance;
}


interface Props extends InstanceMenuCommonProps {
  onClose: () => void;
  selectOnlyGroup: (id: string) => void;
}

const RESULT_BY_AGGREGATION_GROUP = {
  [DrawingsSelectAggregationGroup.Area]: DrawingsInstanceType.Polygon,
  [DrawingsSelectAggregationGroup.Line]: DrawingsInstanceType.Polyline,
  [DrawingsSelectAggregationGroup.Count]: DrawingsInstanceType.Count,
};

const POSIBLE_DRAW_MODES: Record<DrawingsSelectAggregationGroup, DrawingsDrawMode[]> = {
  [DrawingsSelectAggregationGroup.Area]: [
    DrawingsDrawMode.BucketFill,
    DrawingsDrawMode.Polygon,
    DrawingsDrawMode.Rectangle3Point,
    DrawingsDrawMode.Rectangle2Point,
    DrawingsDrawMode.Wand,
    DrawingsDrawMode.Dropper,
  ],
  [DrawingsSelectAggregationGroup.Line]: [
    DrawingsDrawMode.Polyline,
    DrawingsDrawMode.Wand,
    DrawingsDrawMode.Dropper,
    DrawingsDrawMode.OneClickLine,
  ],
  [DrawingsSelectAggregationGroup.Count]: [DrawingsDrawMode.Count, DrawingsDrawMode.Finder],
  [DrawingsSelectAggregationGroup.Unknown]: [],
};

const InstanceMenuComponent: React.FC<Props> = ({
  positionX,
  positionY,
  selectedItem,
  onClose,
  selectOnlyGroup,
}) => {
  const setDrawMode = useSetDrawMode();
  const [baseInstance, geometryParams, aggregationType] = useMemo(() => {
    let gParams: Partial<GeometryParams>;
    let aType: DrawingsSelectAggregationGroup;
    const instance: CopyBaseInstance = {
      groupId: selectedItem.groupId,
      name: selectedItem.name,
      id: selectedItem.id,
    };
    if (isPivotedInstance(selectedItem)) {
      gParams = selectedItem;
      aType = selectedItem.type;
      instance.id = selectedItem.groupedGeometries[0];
    } else {
      gParams = selectedItem.geometry;
      aType = DrawingsMenuUtils.getAggregationType(selectedItem);
    }
    return [instance, gParams, aType];
  }, [selectedItem]);

  const copy = useCopyInstanceSettingsToNewGeometry(
    baseInstance,
    geometryParams,
    aggregationType,
    RESULT_BY_AGGREGATION_GROUP[aggregationType],
  );

  const modes = POSIBLE_DRAW_MODES[aggregationType];

  const startWizzard = useStartWizzardWithTargetType();

  const changeDrawMode = useCallback((drawMode: DrawingsDrawMode) => {
    setDrawMode(
      drawMode,
      {
        afterSave: () => {
          selectOnlyGroup(selectedItem.groupId);
          copy();
          startWizzard(drawMode, aggregationType);
        },
      },
    );
  }, [aggregationType, copy, selectOnlyGroup, selectedItem.groupId, setDrawMode, startWizzard]);

  const onModeClick = useCallback((drawMode: DrawingsDrawMode) => {
    changeDrawMode(drawMode);
    onClose();
  }, [onClose, changeDrawMode]);

  return (
    <GlobalKeyboardEventsFocusRestore>
      <MovableContextMenu
        x={positionX}
        y={positionY}
        onClose={onClose}
      >
        {
          modes.map(drawMode => {
            return (
              <DrawModeMenuItem
                key={drawMode}
                drawMode={drawMode}
                onClick={onModeClick}
              />
            );
          })
        }
      </MovableContextMenu>
    </GlobalKeyboardEventsFocusRestore>
  );
};

export const InstanceMenu = React.memo(InstanceMenuComponent);
