import { RectangleButton, Icons } from '@kreo/kreo-ui-components';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import { AutoMeasureActions } from 'common/components/drawings/actions/creators';
import { AutoMeasureDrawingTypeDialog } from 'common/components/drawings/dialogs/auto-measure-drawing-type-dialog';
import { AutoMeasurePageSetting } from 'common/components/drawings/enums/auto-measure-page-setting';
import { AutoMeasureOption, DrawingsShortInfo } from 'common/components/drawings/interfaces';
import { LoadersList } from 'common/components/progress';
import { QuickSearchInput } from 'common/components/quick-search';
import { RenderIf } from 'common/components/render-if';
import { CheckboxGroupMenu } from 'common/components/selection-menus';
import { State } from 'common/interfaces/state';
import { ProgressUtils } from 'common/utils/progress-utils';
import { Menu } from 'unit-2d-info-panel/constants/menus';
import { useOpenMenu } from 'unit-2d-info-panel/hooks/use-open-menu';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { AnalyticsProps, MetricNames, withAnalyticsContext } from 'utils/posthog';
import { AutoMeasureCheckboxOption } from './auto-measure-checkbox-option';
import { AutoMeasureDescription } from './auto-measure-description';
import { Styled } from './styled';
import { useCheckDrawingType } from './use-check-drawing-type';


const renderOption = (
  data: AutoMeasureOption,
  selected: boolean,
): JSX.Element => {
  return (
    <AutoMeasureCheckboxOption
      data={data}
      selected={selected}
    />
  );
};

interface StateProps {
  availableOptions: AutoMeasureOption[];
  selectedOptions: number[];
  currentDrawing: DrawingsShortInfo;
}

interface DispatchProps {
  setSelectedOptions: (value: number[]) => void;
  startCalculation: (setting: AutoMeasurePageSetting) => void;
}

interface Props extends StateProps, DispatchProps, AnalyticsProps {
}

const sendAutoMeasureEvent = (
  selectedOptions: number[],
  pageSetting: AutoMeasurePageSetting,
  availableOptions: AutoMeasureOption[],
  sendEvent: (name: string, params: { pageSetting: string, selectedOptions: string }) => void,
): void => {
  const [pageSettingName] = Object.entries(AutoMeasurePageSetting).find(([, value]) => value === pageSetting);
  const selectedOptionsNames = selectedOptions.map(i => availableOptions[i].name).join(',');
  sendEvent(MetricNames.measurements.launchAutoMeasure,
    {
      pageSetting: pageSettingName,
      selectedOptions: selectedOptionsNames,
    });
};

const AutoMeasureComponent: React.FC<Props> = (
  {
    availableOptions,
    setSelectedOptions,
    selectedOptions,
    currentDrawing,
    startCalculation,
    sendEvent,
  },
) => {
  const selectedPagesCount = useSelector<State, number>(s => s.drawings.selectedPages.length);
  const [searchQuery, setSearchValue] = useState<string>();
  const closeAutoMeasureMenu = useOpenMenu(Menu.Default);

  useEffect(() => {
    if (currentDrawing && currentDrawing.recognitionStatus === ViewModelStatus.Calculating || !selectedPagesCount) {
      closeAutoMeasureMenu();
    }
  }, [closeAutoMeasureMenu, currentDrawing, selectedPagesCount]);

  const [ checkDrawingType, drawingTypeInProgress ] = useCheckDrawingType();
  const startCalculationCallback = useCallback(() => {
    checkDrawingType(() => {
      sendAutoMeasureEvent(selectedOptions, AutoMeasurePageSetting.Current, availableOptions, sendEvent);
      startCalculation(AutoMeasurePageSetting.Current);
      closeAutoMeasureMenu();
    });
  }, [selectedOptions, availableOptions, sendEvent, startCalculation, closeAutoMeasureMenu, checkDrawingType]);

  return (
    <Styled.Container>
      <QuickSearchInput
        placeholder='Type text...'
        isQuickSearchVisible={true}
        setQuickSearchValue={setSearchValue}
        value={searchQuery}
        tooltipText='Clear'
      />
      <Styled.Categories>
        <CheckboxGroupMenu
          withCommon={true}
          value={selectedOptions}
          options={availableOptions}
          onSelectionChanged={setSelectedOptions}
          filterQuery={searchQuery}
          customOptionRender={renderOption}
        />
      </Styled.Categories>
      <RenderIf condition={!!selectedOptions.length}>
        <Styled.Launch>
          <RectangleButton
            size='m'
            mood={drawingTypeInProgress ? 'disabled' : 'positive'}
            onClick={startCalculationCallback}
            Icon={Icons.AutoMeasureSmall}
            text='Launch'
          />
        </Styled.Launch>
      </RenderIf>
      <AutoMeasureDescription />
      <AutoMeasureDrawingTypeDialog/>
      <Styled.Progress>
        <LoadersList
          progressKey={ProgressUtils.EXTRACT_DRAWING_TYPE_KEY}
        />
      </Styled.Progress>
    </Styled.Container>
  );
};


function mapStateToProps(state: State): StateProps {
  return {
    availableOptions: state.drawings.autoMeasureSettings.availableOptions,
    selectedOptions: state.drawings.autoMeasureSettings.selectedOptions,
    currentDrawing: state.drawings.currentDrawingInfo,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setSelectedOptions: (value) => dispatch(AutoMeasureActions.setSelectedOptions(value)),
    startCalculation: (setting) => dispatch(AutoMeasureActions.startCalculation(setting)),
  };
}


export const AutoMeasure = connect(mapStateToProps, mapDispatchToProps)(
  withAnalyticsContext(AutoMeasureComponent));
