import { Icons, Text } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { pushSearch } from '../../../actions/common';
import { RenderIf } from '../render-if';
import { Styled } from './styled';

interface Props {
  isCollapsed: boolean;
}

const DATA_CONTROL_NAME = 'OPEN_DEMO_PROJECT_DIALOG';

export const DemoProjectButton: React.FC<Props> = ({ isCollapsed }) => {
  const dispatch = useDispatch();
  const isShow = useAbility(Operation.Manage, Subject.DemoProject);

  const handleClick = useCallback(() => {
    dispatch(pushSearch({ demoProject: 'true' }));
  }, [dispatch]);

  return (
    <RenderIf condition={isShow}>
      <Styled.Container
        onClick={handleClick}
        isCollapsed={isCollapsed}
        data-control-name={DATA_CONTROL_NAME}
      >
        <Icons.DemoProjectNavigation />
        <Text
          fontSize={14}
        >
          Demo Projects
        </Text>
      </Styled.Container>
    </RenderIf>
  );
};
