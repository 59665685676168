import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingMarkShapes, DrawingStrokeStyles } from '../../constants/drawing-styles';
import { DrawingsSolidColorSVGUrls, DrawingsSVGIcons } from '../../constants/drawings-svg-icons';
import { ContextObserver, ContextObserverWithPrevious } from '../../drawings-contexts';
import {
  AIDrawModes,
  DragDrawModes,
  DrawingsDrawMode,
  DrawingsModesWithCloseAtStart,
  DrawingsModesWithSnapping,
} from '../../enums/drawings-draw-mode';
import { DrawingsInstanceType } from '../../enums/drawings-instance-type';
import { WizzardStatus } from '../../enums/dropper-state';
import { DrawingsColorCacheHelper } from '../../helpers/drawings-color-cache-helper';
import { MagicSearchState, ShortPointDescription, WizzardToolsState } from '../../interfaces';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingsPaperColorInfo } from '../../interfaces/drawings-canvas-context-props';
import { DrawingsGeometryType } from '../../interfaces/drawings-geometry';
import { DrawingsCanvasUtils } from '../../utils/drawings-canvas-utils';
import { DrawingsCommonUtils } from '../../utils/drawings-common-utils';
import { PaperPointUtils } from '../../utils/paper-utils';
import {
  DrawingsGeometryCopyWithPointPreview,
  DrawingsGeometryTempCalibrateEntity,
  DrawingsGeometryTempCommentRect,
  DrawingsGeometryTempCountEntity,
  DrawingsGeometryTempDragRectangleEntity,
  DrawingsGeometryTempPolygonEntity,
  DrawingsGeometryTempRectangleEntity,
} from '../drawings-geometry-entities/temporary';
import { BucketFill } from '../drawings-geometry-entities/temporary/bucket-fill';
import {
  DrawingsGeometryTempEntity,
  FinishedDrawingElement,
  FinishedDrawingRuler,
  TryAddPointConfig,
} from '../drawings-geometry-entities/temporary/drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityPolyline,
} from '../drawings-geometry-entities/temporary/drawings-geometry-temp-entity-polyline';
import {
  DrawingsGeometryTempEntityWithStrokeConfig,
} from '../drawings-geometry-entities/temporary/drawings-geometry-temp-entity-with-stroke';
import { DrawingsGeometryTempRuler } from '../drawings-geometry-entities/temporary/drawings-geometry-temp-ruler';
import { OneClickLineConnector } from '../drawings-geometry-entities/temporary/one-click-line-connector';
import { WizzardPolyline } from '../drawings-geometry-entities/temporary/wizzard-polyline';
import {
  AiSuggestSettings,
  BatchedUpdateCallback,
  DrawingsGeometryAutocompleteEntityTypes,
  DrawModeProcessor,
  GetCurrentDrawingCallback,
  NewDrawingSettings,
  OpenDrawFinishMenuCallback,
  SetCursorHintCallback,
  ShapeFactory,
} from '../interfaces';
import { FinishDrawApi } from '../interfaces/finish-draw-api';
import { DrawingsUserAnnotationsHelper } from './annotations/drawings-user-annotation-helper';
import { DrawingsGeometryAutocomplete } from './autocomplete';
import { DrawingsDragCallbackParams, DrawingsDragProcessingHelper } from './drag-instances';
import { DrawingsGeometryCopyPasteBuffer } from './drawings-copy-paste-buffer';
import { DrawingsKnifeHelper } from './drawings-knife-helper';
import { DrawingsOrthogonalModeStatusController } from './drawings-orthogonal-mode-status-controller';
import { DrawingsViewHelper } from './drawings-view-helper';
import { PaperMouseEventUtils } from './paper-mouse-event-utils';
import { DrawingsGeometrySnappingHelper, DrawingsSnappingParameters } from './snapping';
import { DrawingsCursorTypeHelper } from './visual-helpers';
import { Dropper } from './wizzard';
import { Finder } from './wizzard/finder';

export type DrawingsFinishDrawGeometryCallback = (geometry: DrawingsGeometryType, type: DrawingsInstanceType) => void;

export interface DrawingsDrawModeParams {
  color: DrawingsPaperColorInfo;
  strokeStyle: DrawingStrokeStyles;
  strokeWidth: number;
  shape: DrawingMarkShapes;
}

export interface DrawingsGeometryRendererDrawHelperConfig {
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  magicSearchDataObserver: ContextObserver<MagicSearchState>;
  measurementsAutocompleteContext: ContextObserver<AiSuggestSettings>;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
  orthogonalModeController: DrawingsOrthogonalModeStatusController;
  snappingHelper: DrawingsGeometrySnappingHelper;
  layer: paper.Layer;
  viewHelper: DrawingsViewHelper;
  dragHelper: DrawingsDragProcessingHelper;
  knifeHelper: DrawingsKnifeHelper;
  userAnnotationsHelper: DrawingsUserAnnotationsHelper;
  colorsCacheHelper: DrawingsColorCacheHelper;
  getInstancesForPaste: () => DrawingsGeometryCopyPasteBuffer;
  pasteWithPoint: (point: paper.Point) => void;
  openFinishPopup: OpenDrawFinishMenuCallback;
  sendFinishApi: (api: FinishDrawApi) => void;
  onCreateElement: (result: FinishedDrawingElement[], config: { drawingId?: string, isDrawn?: boolean }) => void;
  openContextMenu: (e: PaperMouseEvent) => void;
  getCurrentPageInfo: () => Core.Document.PageInfo;
  getDrawingInfo: GetCurrentDrawingCallback;
  getColorOfLabel: (label: string) => string;
  shapeCreator: ShapeFactory;
  setCursorMessage: SetCursorHintCallback;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  onChangeDropperState: (state: WizzardStatus, itemsCount?: number) => void;
  setSelectionArea: (area: ShortPointDescription[]) => void;
  addFinderSelectedGeometryToRemove: (geometryId: number) => void;
  clearWizzardResult: () => void;
  onRunDropper: (point: ShortPointDescription) => void;
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
}

const MODES_WITH_ERROR_CHECK = [
  DrawingsDrawMode.Polygon,
  DrawingsDrawMode.Rectangle3Point,
];

const IGNORE_SNAPPING_MODES = [
  DrawingsDrawMode.BucketFill,
  DrawingsDrawMode.Comment,
];

export class DrawingsGeometryRendererDrawHelper implements DrawModeProcessor {
  private readonly _finishApi: FinishDrawApi = {
    finish: this.getContextMenuAction(this.finish),
    clear: this.getContextMenuAction(this.clear),
    finishWithAi: this.getContextMenuAction(this.finishWithAutocompleteIfPossible),
  };

  private _config: DrawingsGeometryRendererDrawHelperConfig;

  /* paper.js data */
  private _currentPosition: paper.Point;

  /* state data */
  private _tempImageId: DrawingsSVGIcons;
  private _drawingMode: DrawingsDrawMode;
  private _mouseMoveDisabled: boolean;
  private _hasError: boolean;
  private _drawingId: string;

  private _tempEntity: DrawingsGeometryTempEntity;
  private _pastePreview: DrawingsGeometryCopyWithPointPreview;
  private _autocomplete: DrawingsGeometryAutocomplete;

  private _dropper: Dropper;
  private _finder: Finder;

  private _isCompleteEnabled: boolean;
  private _isClearEnabled: boolean;

  /* other */
  private _executor: DeferredExecutor = new DeferredExecutor(3000);
  private _secondClickExecutor: DeferredExecutor = new DeferredExecutor(300);

  constructor(config: DrawingsGeometryRendererDrawHelperConfig) {
    this._config = config;
    this._config.newDrawingStylesObserver.subscribe(this.onUpdateDrawingStyles);
  }

  public destroy(): void {
    this.clearDrawn();
    this.disableDrawMode();
    this._secondClickExecutor.reset();
    this._config.newDrawingStylesObserver.unsubscribe(this.onUpdateDrawingStyles);
    if (this._dropper) {
      this._dropper.destroy();
      this._dropper = null;
    }
    if (this._finder) {
      this._finder.destroy();
      this._finder = null;
    }
  }

  public get finder(): Finder {
    return this._finder;
  }

  public get dropper(): Dropper {
    return this._dropper;
  }

  public get isDrawingMode(): boolean {
    return this._drawingMode !== undefined && DrawingsCommonUtils.isDrawEnabled(this._drawingMode);
  }

  public get isDrawing(): boolean {
    return this._tempEntity && this._tempEntity.hasPoint;
  }

  public get drawMode(): DrawingsDrawMode {
    return this._drawingMode;
  }


  public set mouseMoveDisabled(value: boolean) {
    this._mouseMoveDisabled = value;
  }

  public set tempImageId(value: DrawingsSVGIcons) {
    this._tempImageId = value;
  }

  @autobind
  public enableStrictAngleMode(): void {
    this._config.orthogonalModeController.hotkeyEnabled = true;
  }

  @autobind
  public disableStringAngleMode(): void {
    this._config.orthogonalModeController.hotkeyEnabled = false;
  }

  @autobind
  public finishWithAutocompleteIfPossible(): boolean {
    if (this._autocomplete && this._autocomplete.canUseAutocompleteGeometry()) {
      this._config.sendFinishApi(null);
      const result = this._autocomplete.convert(this._config.newDrawingStylesObserver.getContext());
      this.clearDrawn();
      this._config.onCreateElement([result], { drawingId: this._drawingId, isDrawn: true });
      return true;
    } else {
      return this.finishDrawGeometry(false);
    }
  }

  @autobind
  public finishDrawGeometry(addLastPoint: boolean, fromDisable?: boolean): boolean {
    if (!this._tempEntity || this._hasError || !this._tempEntity.canComplete(addLastPoint)) {
      return;
    }
    let result: FinishedDrawingElement[];

    switch (this._drawingMode) {
      case DrawingsDrawMode.OneClickLine:
      case DrawingsDrawMode.Polyline: {
        result = this._tempEntity.convert(addLastPoint, DrawingsInstanceType.Polyline) as FinishedDrawingElement[];
        break;
      }
      case DrawingsDrawMode.Wand:
      case DrawingsDrawMode.BucketFill:
      case DrawingsDrawMode.Polygon: {
        if (this._tempEntity.canSave) {
          result = this._tempEntity.convert(addLastPoint, DrawingsInstanceType.Polygon) as FinishedDrawingElement[];
        }
        if (!result) {
          this.changeColorsToError();
        }
        break;
      }
      case DrawingsDrawMode.FinderSelectionArea: {
        if (!(this._tempEntity as DrawingsGeometryTempPolygonEntity).isValidArea) {
          break;
        }
        result = this._tempEntity.convert(
          addLastPoint,
          DrawingsInstanceType.WizzardSelectionArea,
        ) as FinishedDrawingElement[];
        break;
      }
      case DrawingsDrawMode.MagicSearch: {
        result = this._tempEntity.convert(false) as FinishedDrawingElement[];
        break;
      }

      case DrawingsDrawMode.MagicSearchArea:
      case DrawingsDrawMode.WizzardSearchArea: {
        if (!(this._tempEntity as DrawingsGeometryTempPolygonEntity).isValidArea) {
          break;
        }
        result = this._tempEntity.convert(
          addLastPoint,
          DrawingsInstanceType.WizzardWorkingArea,
        ) as FinishedDrawingElement[];
        break;
      }
      case DrawingsDrawMode.PdfFilterArea:
        if (!(this._tempEntity as DrawingsGeometryTempPolygonEntity).isValidArea) {
          break;
        }
        result = this._tempEntity.convert(
          addLastPoint,
          DrawingsInstanceType.PdfFilterArea,
        ) as FinishedDrawingElement[];
        break;
      case DrawingsDrawMode.Calibrate: {
        result = this._tempEntity
          .convert(addLastPoint, DrawingsInstanceType.CalibrationLine) as FinishedDrawingElement[];
        break;
      }
      case DrawingsDrawMode.Count: {
        if (!this._tempEntity.hasPoint) {
          return;
        }
        result = this._tempEntity.convert(addLastPoint, DrawingsInstanceType.Count) as FinishedDrawingElement[];
        this._tempEntity.destroy();
        this._tempEntity = null;
        break;
      }
      case DrawingsDrawMode.Rectangle2Point:
      case DrawingsDrawMode.Rectangle3Point: {
        if (!(this._tempEntity as DrawingsGeometryTempPolygonEntity).isValidArea) {
          break;
        }
        result = this._tempEntity.convert(addLastPoint, DrawingsInstanceType.Rectangle) as FinishedDrawingElement[];
        if (!result) {
          this.changeColorsToError();
        }
        break;
      }
      case DrawingsDrawMode.Ruler:
        const { points, color } = this._tempEntity.convert() as FinishedDrawingRuler;
        this._config.userAnnotationsHelper.addRuler(points, color);
        this.clearDrawn();
        break;
      case DrawingsDrawMode.Comment:
        result = this._tempEntity.convert() as FinishedDrawingElement[];
        this._tempEntity.destroy();
        this._tempEntity = null;
        break;
      default:
    }
    if (result || !MODES_WITH_ERROR_CHECK.includes(this._drawingMode)) {
      if (!fromDisable) {
        this.clearDrawn();
      }

      this._config.sendFinishApi(null);
      this._config.onCreateElement(result, { drawingId: this._drawingId, isDrawn: true });
      return true;
    } else {
      return false;
    }
  }

  public clearDrawn(): void {
    this._config.snappingHelper.snapEntity = null;
    this._config.snappingHelper.cancelSnapping();
    document.onmouseup = undefined;
    const drawMode = this._drawingMode;
    if (this._tempEntity) {
      this._tempEntity.destroy();
      this._tempEntity = null;
    }
    if (this._pastePreview) {
      this._pastePreview.destroy();
    }
    this.destroyAutocomplete();
    this.enableDrawMode(drawMode);
  }

  public enableDrawMode(
    mode: DrawingsDrawMode,
    drawingId?: string,
  ): void {
    this.disableDrawMode();
    if (mode === DrawingsDrawMode.Disabled) {
      return;
    }

    this._config.snappingHelper.onSendPoint = this.updateMousePosition;

    this.createTempEntity(mode);


    if (drawingId) {
      this._drawingId = drawingId;
    }
    if (this._tempEntity) {
      this._config.snappingHelper.snapEntity = this._tempEntity;
    }
    this._config.viewHelper.restoreDefaultEvent('onRightMouseClick');
    this.applyModeToDropper(mode);
    this.applyModeToFinder(mode);
    if (DragDrawModes.includes(mode)) {
      this._config.viewHelper.onMouseDown = this.dragRectangleStart;
      this._config.viewHelper.onMouseMove = this.onMouseMove;
      this._config.viewHelper.onLeftMouseClick = null;
    } else if (mode !== DrawingsDrawMode.Dropper && mode !== DrawingsDrawMode.Finder) {
      this._config.viewHelper.onMouseDown = null;
      this._config.viewHelper.onMouseMove = this.onMouseMove;
      this._config.viewHelper.onMouseUp = this.leftClickHandler;
      if (mode === DrawingsDrawMode.BucketFill) {
        this._config.viewHelper.onMouseLeave = this.mouseLeave;
      }
      if (mode !== DrawingsDrawMode.Image) {
        this._config.viewHelper.onDoubleClick = this.doubleClick;
      }
    }
    this._drawingMode = mode;
  }

  public disableDrawMode(): void {
    this._config.snappingHelper.cancelSnapping();
    this._config.snappingHelper.snapEntity = null;
    this._config.knifeHelper.deactivate();
    this._config.viewHelper.restoreDefaultEvents([
      'onMouseDown',
      'onMouseMove',
      'onMouseUp',
      'onLeftMouseClick',
      'onDoubleClick',
      'onRightMouseClick',
      'onMouseLeave',
    ]);

    if (this._tempEntity) {
      this._tempEntity.destroy();
      this._tempEntity = null;
    }
    if (this._pastePreview) {
      this._pastePreview.destroy();
      this._pastePreview = null;
    }
    this._drawingMode = DrawingsDrawMode.Disabled;
    this._drawingId = null;
    this.destroyAutocomplete();
    if (this._dropper) {
      this._dropper.destroy();
      this._dropper = null;
    }
    if (this._finder) {
      this._finder.destroy();
      this._finder = null;
    }
  }

  @autobind
  public updateMousePosition(
    point: paper.Point,
    snappingInfo?: DrawingsSnappingParameters,
  ): paper.Point {
    this._currentPosition = point;
    if (snappingInfo) {
      const { threshold, distance } = snappingInfo;

      if (DrawingsModesWithSnapping.includes(this._drawingMode)) {
        let min = Infinity;
        let currentPosition = snappingInfo.snappingPoint;
        if (this._tempEntity) {
          for (let i = 0; i < this._tempEntity.pointsCount - 2; i++) {
            const segmentPoint = this._tempEntity.getPoint(i);
            const length = point.subtract(segmentPoint).length;
            if (length < threshold && length < distance && length < min) {
              min = length;
              currentPosition = segmentPoint;
            }
          }
        }
        this._currentPosition = currentPosition || point;
        this.updateVisualDataByPoint(this._currentPosition);
        return this._currentPosition;
      } else if (snappingInfo.snappingPoint && snappingInfo.snappingPoint.subtract(point).length < threshold) {
        this._currentPosition = snappingInfo.snappingPoint;
        this.updatePreviewPosition(this._currentPosition);
        return this._currentPosition;
      }
    }
    this.updateVisualDataByPoint(point);
    return null;
  }

  public removeLastPoint(): void {
    let lastPoint;
    if (this._config.knifeHelper.isActive) {
      this._config.knifeHelper.removeLastPoint();
    } else {
      if (DragDrawModes.includes(this._drawingMode)) {
        this._tempEntity.destroy();
        this._config.dragHelper.stopDrag();
      } else {
        lastPoint = this._tempEntity.removeLastPoint();
      }
    }
    if (!this._tempEntity?.hasPoint && !this._config.knifeHelper.hasPoints) {
      this._config.viewHelper.restoreDefaultEvent('onRightMouseClick');
    }

    if (lastPoint) {
      this.updateMousePosition(this._currentPosition);
    }
    this._mouseMoveDisabled = false;
    this._config.openFinishPopup(null, null, null);
    this.changeContextMenu();
  }

  public hasPoints(): boolean {
    return this._tempEntity?.hasPoint || this._config.knifeHelper?.hasPoints;
  }

  private destroyAutocomplete(): void {
    if (this._autocomplete) {
      this._autocomplete.destroy();
      this._autocomplete = null;
    }
  }

  private updateVisualDataByPoint(point: paper.Point): void {
    if (DragDrawModes.includes(this._drawingMode)) {
      if (this._tempEntity) {
        this._tempEntity.updateTempPointPosition(point);
      }
    } else if (this._drawingMode === DrawingsDrawMode.PasteWithPoint) {
      this.updatePreviewPosition(point);
    } else if (this._config.knifeHelper.isActive) {
      this._config.knifeHelper.updateTempPointPosition(point, this._config.orthogonalModeController.enabled);
    } else {
      this.updateTempLine(point);
    }
  }

  private updatePreviewPosition(position: paper.Point): void {
    if (this._pastePreview) {
      this._pastePreview.position = position;
    }
  }

  private updateTempLine(point: paper.Point): void {
    if (this._tempEntity) {
      this._tempEntity.updateTempPointPosition(point, this._config.orthogonalModeController.enabled);
      if (this._tempEntity.isValid) {
        this.changeColorToNormal();
      } else {
        this.changeColorsToError();
      }
    } else if (this._config.knifeHelper.isActive) {
      this._config.knifeHelper.updateTempPointPosition(point, this._config.orthogonalModeController.enabled);
    }
  }

  @autobind
  private changeColorToNormal(): void {
    this._hasError = false;
    if (this._tempEntity && this._drawingMode !== DrawingsDrawMode.Calibrate) {
      const { color } = this._config.newDrawingStylesObserver.getContext();
      this._tempEntity.updateColor(this._config.colorsCacheHelper.getPaperColor(color));
    }
  }

  private changeColorsToError(): void {
    this._tempEntity.updateColor(DrawingsCanvasColors.warningColorInfo);
    this._hasError = true;
    this._executor.execute(this.changeColorToNormal);
  }

  @autobind
  private onMouseMove(e: PaperMouseEvent): void {
    if (this._mouseMoveDisabled) {
      return;
    }
    if (!this.trySnapToPolygon(e)) {
      this.snapIfCan(e.point);
    }
  }

  private trySnapToPolygon(e: PaperMouseEvent): boolean {
    if (DrawingsModesWithCloseAtStart.includes(this._drawingMode)) {
      if ((this._tempEntity as DrawingsGeometryTempPolygonEntity).tryToSnappPoint(e.point)) {
        this.updateMousePosition(this._tempEntity.getPoint(0));
        return true;
      }
    }
    return false;
  }

  private isPointEqualLastStable(point: paper.Point): boolean {
    const lastStable = this._tempEntity?.lastStablePoint;
    return !!lastStable && PaperPointUtils.arePointsEqual(point, lastStable);
  }

  private tryAddPoint(point: paper.Point, config?: TryAddPointConfig): void {
    const isPointAdded = this._tempEntity.tryAddPoint(point, config);
    if (!isPointAdded) {
      this.changeColorsToError();
    }
  }

  @autobind
  private doubleClick(): void {
    this._secondClickExecutor.reset();
    if (this._hasError) {
      return;
    }

    if (this._drawingMode === DrawingsDrawMode.Knife) {
      if (this._config.knifeHelper.canComplete(false)) {
        this._config.knifeHelper.finish(false);
      }
    } else if (this._tempEntity.canComplete(false)) {
      this.finish();
    }
  }

  @autobind
  private leftClickHandler(e: PaperMouseEvent): void {
    if (this._drawingMode === DrawingsDrawMode.Image) {
      this.leftButtonClick(e);
      return;
    }
    if (this._secondClickExecutor.isWaitingForExecution()) {
      this._secondClickExecutor.reset();
      return;
    }
    if (
      this._drawingMode === DrawingsDrawMode.BucketFill
      || this._drawingMode === DrawingsDrawMode.Wand
      || this._drawingMode === DrawingsDrawMode.OneClickLine
    ) {
      this._secondClickExecutor.execute(this.leftButtonClick, e);
    } else {
      this._secondClickExecutor.execute(ConstantFunctions.doNothing);
      this.leftButtonClick(e);
    }
  }

  @autobind
  private mouseLeave(): void {
    this._tempEntity.updateTempPointPosition(null);
  }

  @autobind
  private leftButtonClick(e: PaperMouseEvent): void {
    if (!PaperMouseEventUtils.isLeftMouseButton(e)) {
      return;
    }
    if (
      this._hasError
      || DragDrawModes.includes(this._drawingMode)
      || this._drawingMode === DrawingsDrawMode.Disabled
    ) {
      return;
    }
    this.updateCurrentByEventPoint(e.point, PaperMouseEventUtils.isTouch(e.event));
    if (this._drawingMode === DrawingsDrawMode.Count) {
      if (!this.isPointEqualLastStable(this._currentPosition)) {
        this.tryAddPoint(this._currentPosition);
      }
    } else if (this._drawingMode === DrawingsDrawMode.Image) {
      const color: string = DrawingsSolidColorSVGUrls.includes(this._tempImageId)
        ? DrawingsCanvasUtils.getColorFromList()
        : undefined;
      this._config.userAnnotationsHelper.addAnnotationImage(this._currentPosition, this._tempImageId, color);
      return;
    } else if (this._drawingMode === DrawingsDrawMode.PasteWithPoint) {
      this._pastePreview.position = this._currentPosition;
      if (this._pastePreview.canPaste) {
        this._config.pasteWithPoint(this._currentPosition);
      }
      return;
    } else if (this._drawingMode === DrawingsDrawMode.Knife) {
      this._config.viewHelper.onRightMouseClick = this.rightButtonClick;
      this._config.knifeHelper.addLinePoint(e.point);
      return;
    }
    if (this._drawingMode === DrawingsDrawMode.Ruler && this._tempEntity.pointsCount === 3) {
      this.finishDrawGeometry(true);
    } else if (this._drawingMode === DrawingsDrawMode.Calibrate && this._tempEntity.pointsCount === 2) {
      this.finishDrawGeometry(true);
    } else if (
      this._tempEntity.pointsCount > 2
      && (DrawingsModesWithCloseAtStart.includes(this._drawingMode))
      && this._currentPosition.equals(this._tempEntity.getPoint(0))
    ) {
      this.finishDrawGeometry(false);
    } else if (
      this._tempEntity.pointsCount === 3
      && this._drawingMode === DrawingsDrawMode.Rectangle3Point
    ) {
      this.finishDrawGeometry(true);
    } else if (
      this._drawingMode === DrawingsDrawMode.Wand
      || this._drawingMode === DrawingsDrawMode.BucketFill
      || this._drawingMode === DrawingsDrawMode.OneClickLine
    ) {
      this.tryAddPoint(this._currentPosition, { pathOnly: false, mousePoint: e.point, event: e });
    } else {
      if (this._drawingMode !== DrawingsDrawMode.Count && !this.isPointEqualLastStable(this._currentPosition)) {
        if (!this._tempEntity.hasPoint) {
          this.tryAddPoint(this._currentPosition, { pathOnly: true });
        }
        this._tempEntity.updateTempPointPosition(
          this._currentPosition,
          this._config.orthogonalModeController.enabled,
        );
        this.tryAddPoint(this._tempEntity.lastPoint);
      }
      this.changeContextMenu();
    }
  }

  @autobind
  private changeContextMenuStatus(value: boolean, canComplete: boolean): void {
    this._isCompleteEnabled = canComplete;
    this._isClearEnabled = value;
    if (value) {
      this._config.sendFinishApi({
        finish: this._isCompleteEnabled ? () => this.finish() : null,
        clear: this._isClearEnabled ? () => this.clear() : null,
        finishWithAi: this._autocomplete?.canUseAutocompleteGeometry()
          ? () => this.finishWithAutocompleteIfPossible()
          : null,
      });
      this._config.viewHelper.onRightMouseClick = this.rightButtonClick;
      if (canComplete) {
        this._config.viewHelper.onDoubleClick = this.doubleClick;
      }
    } else {
      this._config.viewHelper.restoreDefaultEvent('onRightMouseClick');
      this._config.sendFinishApi(null);
      this._config.viewHelper.restoreDefaultEvent('onDoubleClick');
    }
  }

  private changeContextMenu(): void {
    this.changeContextMenuStatus(this._tempEntity?.hasPoint, this._tempEntity?.canComplete(false));
  }

  @autobind
  private rightButtonClick(e: PaperMouseEvent): void {
    if (this._config.knifeHelper.isActive || this._tempEntity.canComplete(false)) {
      this._mouseMoveDisabled = true;
      this._config.openFinishPopup(e, this._finishApi, {
        canFinish: true,
        canFinishWithAI: this._autocomplete?.canUseAutocompleteGeometry(),
      });
    } else if (this._autocomplete?.canUseAutocompleteGeometry()) {
      this._config.openFinishPopup(e, this._finishApi, {
        canFinish: false,
        canFinishWithAI: true,
      });
    } else if (!this.isDrawing) {
      this._config.openContextMenu(e);
    }
  }

  @autobind
  private clear(): boolean {
    this.mouseMoveDisabled = false;
    this.clearDrawn();
    this._config.openFinishPopup(null, null, null);
    this.changeContextMenuStatus(false, false);
    return true;
  }

  @autobind
  private finish(): boolean {
    this.mouseMoveDisabled = false;
    if (this._drawingMode === DrawingsDrawMode.Knife) {
      if (this._config.knifeHelper.canComplete(false)) {
        this._config.knifeHelper.finish(false);
      }
      return true;
    } else {
      return this.finishDrawGeometry(false);
    }
  }

  @autobind
  private dragRectangleStart(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      if (this._tempEntity) {
        this._tempEntity.destroy();
        this._tempEntity = null;
      }
      this.updateCurrentByEventPoint(e.point, PaperMouseEventUtils.isTouch(e.event));
      const Type = this._drawingMode === DrawingsDrawMode.Comment ?
        DrawingsGeometryTempCommentRect
        : DrawingsGeometryTempDragRectangleEntity;
      this._tempEntity = new Type(
        this._currentPosition,
        {
          layer: this._config.layer,
          renderParametersContextObserver: this._config.renderParametersContextObserver,
          textRenderParametersObserver: this._config.viewSettingsObserver,
          getDrawingInfo: this._config.getDrawingInfo,
          shapeCreator: this._config.shapeCreator,
          withMeasures: this._drawingMode === DrawingsDrawMode.Rectangle2Point,
          newDrawingStylesObserver: this._config.newDrawingStylesObserver,
          ...this.getStyles(this._config.newDrawingStylesObserver.getContext(), this.drawMode),
        },
      );
      this._config.viewHelper.restoreDefaultEvent('onMouseMove');
      this._config.dragHelper.setCallback(this.dragMouseMove);
    }
  }

  private getStyles(styles: NewDrawingSettings, mode: DrawingsDrawMode): DrawingsDrawModeParams {
    let { strokeStyle, strokeWidth } = styles;
    if (mode === DrawingsDrawMode.Calibrate) {
      strokeStyle = DrawingStrokeStyles.Normal;
      strokeWidth = DrawingsCanvasConstants.infoLinesStroke;
    }

    const color = (
      mode === DrawingsDrawMode.MagicSearchArea
      || mode === DrawingsDrawMode.WizzardSearchArea
      || mode === DrawingsDrawMode.PdfFilterArea
    )
      ? DrawingsCanvasColors.autocompleteColorInfo
      : this._config.colorsCacheHelper.getPaperColor(styles.color);

    return {
      ...styles,
      strokeStyle,
      strokeWidth,
      color,
    };
  }

  private updateCurrentByEventPoint(point: paper.Point, isTouch: boolean): void {
    const { zoom } = this._config.renderParametersContextObserver.getContext();
    const shouldChangeCurrentPosition = !this._currentPosition
      || point.subtract(this._currentPosition).length > DrawingsCanvasConstants.snappingThreshold / zoom;
    if (shouldChangeCurrentPosition) {
      const shouldForcePoint = (isTouch && DragDrawModes.includes(this._drawingMode))
        || AIDrawModes.includes(this._drawingMode);
      const shouldUpdateByOrtho = this._config.orthogonalModeController.enabled && this._tempEntity?.hasPoint;
      if (!shouldForcePoint && shouldUpdateByOrtho) {
        this._tempEntity.updateTempPointPosition(point);
        this._currentPosition = this._tempEntity.lastPoint;
      } else {
        this._currentPosition = point;
      }
    }
  }

  @autobind
  private dragMouseMove({ point, finish, mouseUpEvent }: DrawingsDragCallbackParams): boolean {
    if (point) {
      if (this._config.snappingHelper.canSnap) {
        this.snapIfCan(point);
      } else {
        this.updateMousePosition(point);
      }
    }

    if (finish) {
      this.finishDrawGeometry(false);
      if (mouseUpEvent) {
        ConstantFunctions.stopEvent(mouseUpEvent);
      }
    }
    return true;
  }

  private snapIfCan(point: paper.Point): void {
    if (!IGNORE_SNAPPING_MODES.includes(this._drawingMode) && this._config.snappingHelper.canSnap) {
      const { strokeWidth } = this._config.newDrawingStylesObserver.getContext();
      const snappingSize = strokeWidth > 8
        ? DrawingsCanvasConstants.defaultSnappingSize + 2
        : DrawingsCanvasConstants.defaultSnappingSize;
      this._config.snappingHelper.requestSnapping(point, snappingSize);
    } else {
      this.updateMousePosition(point);
    }
  }

  private initAutoComplete(instanceType: DrawingsGeometryAutocompleteEntityTypes): void {
    if (this._autocomplete) {
      this._autocomplete.destroy();
    }
    this._autocomplete = new DrawingsGeometryAutocomplete({
      layer: this._config.layer,
      getCurrentDrawingInfo: this._config.getDrawingInfo,
      renderParametersContextObserver: this._config.renderParametersContextObserver,
      textRenderParametersObserver: this._config.viewSettingsObserver,
      measurementsAutocompleteContext: this._config.measurementsAutocompleteContext,
      instanceType,
      onLoaded: this.onAutocompleteLoaded,
    });
  }

  @autobind
  private onAutocompleteLoaded(loaded: boolean): void {
    if (loaded) {
      this._config.sendFinishApi({
        finish: this._isCompleteEnabled ? () => this.finish() : null,
        clear: this._tempEntity?.hasPoint ? () => this.clear() : null,
        finishWithAi: () => this.finishWithAutocompleteIfPossible(),
      });
    } else {
      if (!this._isCompleteEnabled) {
        this._config.sendFinishApi(null);
      } else {
        this._config.sendFinishApi({
          finish: () => this.finish(),
          clear: () => this.clear(),
          finishWithAi: null,
        });
      }
    }
  }

  private getContextMenuAction(action: () => boolean): () => boolean {
    return () => {
      const result = action();
      this._mouseMoveDisabled = false;
      this._config.openFinishPopup(null, null, null);
      return result;
    };
  }


  @autobind
  private onUpdateDrawingStyles({ color, shape, strokeStyle, strokeWidth }: NewDrawingSettings): void {
    if (this._tempEntity) {
      const paperColor = this._config.colorsCacheHelper.getPaperColor(color || DrawingsCanvasUtils.getColorFromList());
      this._tempEntity.updateColor(paperColor);
      this._tempEntity.strokeStyle = strokeStyle;
      this._tempEntity.strokeWidth = strokeWidth;
      this._tempEntity.shape = shape;
    }
  }

  private applyModeToFinder(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.Finder || mode === DrawingsDrawMode.FinderSelectionArea) {
      if (this._finder) {
        return;
      }
      this._finder = new Finder({
        snappingHelper: this._config.snappingHelper,
        renderParametersContextObserver: this._config.renderParametersContextObserver,
        viewHelper: this._config.viewHelper,
        layer: this._config.layer,
        setDropperState: this._config.onChangeDropperState,
        colorsCacheHelper: this._config.colorsCacheHelper,
        setCursorMessage: this._config.setCursorMessage,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        getDrawingInfo: this._config.getDrawingInfo,
        wizzardSettingsObserver: this._config.wizzardSettingsObserver,
        onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
        setArea: this._config.setSelectionArea,
        addGeometryToRemove: this._config.addFinderSelectedGeometryToRemove,
        clearResult: this._config.clearWizzardResult,
        cursorTypeHelper: this._config.cursorTypeHelper,
        viewSettingsObserver: this._config.viewSettingsObserver,
      });
    } else if (this._finder && mode !== DrawingsDrawMode.WizzardSearchArea) {
      this._finder.destroy();
      this._finder = null;
    }
  }

  private applyModeToDropper(mode: DrawingsDrawMode): void {
    if (mode === DrawingsDrawMode.Dropper) {
      const dropper = new Dropper({
        snappingHelper: this._config.snappingHelper,
        renderParametersContextObserver: this._config.renderParametersContextObserver,
        viewHelper: this._config.viewHelper,
        layer: this._config.layer,
        setDropperState: this._config.onChangeDropperState,
        colorsCacheHelper: this._config.colorsCacheHelper,
        setCursorMessage: this._config.setCursorMessage,
        newDrawingStylesObserver: this._config.newDrawingStylesObserver,
        getDrawingInfo: this._config.getDrawingInfo,
        wizzardSettingsObserver: this._config.wizzardSettingsObserver,
        onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
        onRunDropper: this._config.onRunDropper,
        viewSettingsObserver: this._config.viewSettingsObserver,
      });
      this._dropper = dropper;
    } else if (this._dropper && mode !== DrawingsDrawMode.WizzardSearchArea) {
      this._dropper.destroy();
      this._dropper = null;
    }
  }

  private createTempEntity(mode: DrawingsDrawMode): void {
    const entityConfig: DrawingsGeometryTempEntityWithStrokeConfig = {
      layer: this._config.layer,
      renderParametersContextObserver: this._config.renderParametersContextObserver,
      textRenderParametersObserver: this._config.viewSettingsObserver,
      getDrawingInfo: this._config.getDrawingInfo,
      shapeCreator: this._config.shapeCreator,
      newDrawingStylesObserver: this._config.newDrawingStylesObserver,
      ...this.getStyles(this._config.newDrawingStylesObserver.getContext(), mode),
    };
    switch (mode) {
      case DrawingsDrawMode.Polygon:
        this.initAutoComplete(DrawingsInstanceType.Polygon);
        this._tempEntity = new DrawingsGeometryTempPolygonEntity({
          ...entityConfig,
          autocomplete: this._autocomplete,
        });
        break;
      case DrawingsDrawMode.Rectangle3Point:
        this._tempEntity = new DrawingsGeometryTempRectangleEntity(entityConfig);
        break;
      case DrawingsDrawMode.Wand:
        this._tempEntity = new WizzardPolyline({
          ...entityConfig,
          autocomplete: this._autocomplete,
          onEnableContextMenu: this.changeContextMenuStatus,
          setCursorMessage: this._config.setCursorMessage,
          cursorTypeHelper: this._config.cursorTypeHelper,
          wizzardSettingsObserver: this._config.wizzardSettingsObserver,
          onFinish: () => this.finishDrawGeometry(true),
        });
        break;
      case DrawingsDrawMode.OneClickLine:
        this._tempEntity = new OneClickLineConnector({
          ...entityConfig,
          autocomplete: this._autocomplete,
          onEnableContextMenu: this.changeContextMenuStatus,
          setCursorMessage: this._config.setCursorMessage,
          cursorTypeHelper: this._config.cursorTypeHelper,
          onFinish: () => this.finishDrawGeometry(true),
          wizzardSettingsObserver: this._config.wizzardSettingsObserver,
          colorsCacheHelper: this._config.colorsCacheHelper,
          newDrawingStylesObserver: this._config.newDrawingStylesObserver,
          onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
          oneClickAreaHoverContextObserver: this._config.oneClickAreaHoverContextObserver,
        });
        break;
      case DrawingsDrawMode.BucketFill:
        this._tempEntity = new BucketFill({
          ...entityConfig,
          autocomplete: this._autocomplete,
          onEnableContextMenu: this.changeContextMenuStatus,
          setCursorMessage: this._config.setCursorMessage,
          cursorTypeHelper: this._config.cursorTypeHelper,
          onFinish: () => this.finishDrawGeometry(true),
          wizzardSettingsObserver: this._config.wizzardSettingsObserver,
          colorsCacheHelper: this._config.colorsCacheHelper,
          newDrawingStylesObserver: this._config.newDrawingStylesObserver,
          onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
          oneClickAreaHoverContextObserver: this._config.oneClickAreaHoverContextObserver,
        });
        break;
      case DrawingsDrawMode.Polyline:
        this.initAutoComplete(DrawingsInstanceType.Polyline);
        this._tempEntity = new DrawingsGeometryTempEntityPolyline({
          ...entityConfig,
          autocomplete: this._autocomplete,
        });
        break;
      case DrawingsDrawMode.Calibrate:
        this._tempEntity = new DrawingsGeometryTempCalibrateEntity({
          ...entityConfig,
          color: { stroke: DrawingsCanvasColors.utility, fill: null, thickness: null },
        });
        break;
      case DrawingsDrawMode.Count:
        this._tempEntity = new DrawingsGeometryTempCountEntity(entityConfig);
        break;
      case DrawingsDrawMode.Ruler:
        const strokeColor = new paper.Color(DrawingsCanvasUtils.getColorFromList());
        this._tempEntity = new DrawingsGeometryTempRuler({
          ...entityConfig,
          color: { stroke: strokeColor, fill: strokeColor, thickness: strokeColor },
        });
        break;
      case DrawingsDrawMode.PasteWithPoint:
        this._pastePreview = new DrawingsGeometryCopyWithPointPreview({
          instances: this._config.getInstancesForPaste(),
          colorCache: this._config.colorsCacheHelper,
          textRenderParametersObserver: this._config.viewSettingsObserver,
          renderParametersContextObserver: this._config.renderParametersContextObserver,
          getCurrentPageInfo: this._config.getCurrentPageInfo,
          getColorOfLabel: this._config.getColorOfLabel,
          shapeCreator: this._config.shapeCreator,
        });
        break;
      case DrawingsDrawMode.Knife:
        this._config.knifeHelper.activate();
        break;
      default:
    }
  }
}
