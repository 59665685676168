import { Icons, Text, LinkComponent } from '@kreo/kreo-ui-components';
import * as React from 'react';
import { Styled } from './styled';

interface Props {
  openManagePaymentsDialog: () => void;
}

const HeaderComponent: React.FC<Props> = ({ openManagePaymentsDialog }) => {
  return (
    <Styled.Header>
      <Styled.Icon>
        <Icons.PaymentMethod />
      </Styled.Icon>
      <Text fontSize={14}>
        Payment method
      </Text>
      <Styled.OpenDialogButton>
        <LinkComponent
          mood={'secondary'}
          fontSize={14}
          text={'Manage payment methods'}
          Icon={Icons.Arrow45deg}
          rightIcon={true}
          onClick={openManagePaymentsDialog}
        />
      </Styled.OpenDialogButton>
    </Styled.Header>
  );
};


export const Header = React.memo(HeaderComponent);
