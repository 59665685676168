import { TinyText } from '@kreo/kreo-ui-components';
import React from 'react';

import { Styled } from './styled';

interface Props {
  count: number;
}

const CountComponent: React.FC<Props> = ({ count }) => {

  return (
    <Styled.Count>
      <TinyText fontWeight={700}>
        {count}
      </TinyText>
    </Styled.Count>
  );
};

export const Count = React.memo(CountComponent);
