import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { DrawingsCanvasColors, DrawingsCanvasConstants } from 'common/components/drawings/constants';
import { DrawingsRenderParams, DrawingsSimplifiedBoundingRect } from 'common/components/drawings/interfaces';
import { VisibleEntity, VisibleEntityConfig } from '../../../common';

export interface BoundingRectConfig extends VisibleEntityConfig<paper.Rectangle> {
  layer?: paper.Layer | paper.Group;
}

export class BoundingRect<T extends BoundingRectConfig = BoundingRectConfig>
  extends VisibleEntity<paper.Rectangle, T> {
  protected _bounds: DrawingsSimplifiedBoundingRect;

  private _rect: paper.Path.Rectangle;

  public get position(): paper.Point {
    return this._rect.position;
  }

  public set position(value: paper.Point) {
    this._rect.position = value;
    this._bounds = this._rect.bounds;
  }

  public get bounds(): paper.Rectangle {
    return this._rect.bounds;
  }

  public destroy(): void {
    this._rect.remove();
    this._config.renderParamsContextObserver.unsubscribe(this.updateRenderParams);
  }

  public rotate(angle: number, ..._args: any[]): void {
    this._rect.rotate(angle, this._rect.position);
  }

  protected override render(geometry: paper.Rectangle): void {
    this._bounds = geometry;
    this._rect = new paper.Path.Rectangle(
      new paper.Point(
        this._bounds.left,
        this._bounds.top,
      ),
      new paper.Point(
        this._bounds.right,
        this._bounds.bottom,
      ));
    const zoom = this._config.renderParamsContextObserver.getContext().zoom;
    this._rect.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
    const dashArray = DrawingsCanvasConstants.dashArray / zoom;
    this._rect.dashArray = [dashArray, dashArray];
    this._rect.strokeColor = DrawingsCanvasColors.utility;
    this._config.renderParamsContextObserver.subscribe(this.updateRenderParams);
    if (this._config.layer) {
      this._rect.addTo(this._config.layer);
    }
  }

  @autobind
  private updateRenderParams({ zoom }: DrawingsRenderParams): void {
    const dashArray = DrawingsCanvasConstants.dashArray / zoom;
    this._rect.dashArray = [dashArray, dashArray];
    this._rect.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / zoom;
  }
}
