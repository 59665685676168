import { ExcelFormulaHelper } from 'common/components/excel-table';
import { ExcelColumnHelper } from 'common/utils/excel-column-helper';
import { isMergeHorizontalKey } from '../../excel-table-cell-formatter/common';
import { changeMergeColumnValue } from '../change-merge-column-value';
import { MoveCellRefMap, MoveCellTempData, MoveDataPayload, UpdatedCellsMap } from '../interfaces';
import { isColumnIdSpecialWord } from '../is-column-id-special-word';
import { setEmptyCell } from '../set-empty-cell/set-empty-cell';
import { shiftColumns } from '../shift-columns/shift-columns';
import { getColumnIndex, getShiftedColumnId } from '../utils';

export const insertColumns = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const { rows } = sheetsData[sheetId];
  const updatedCellsMap: UpdatedCellsMap = {};
  const moveCellRefMap: MoveCellRefMap = {};
  const endIndex = startIndex + offset;

  const shiftCellsInRow = shiftColumns(-offset, updatedCellsMap, moveCellRefMap);

  rows.forEach(rowData => {
    const mergeColumnId: Record<string, number> = {};
    const rowId = rowData.id.toString();
    for (let i = startIndex; i < endIndex; i++) {
      setEmptyCell(updatedCellsMap, rowData, ExcelColumnHelper.indexToExcelColName(i));
    }

    const columnKeys = new Set<string>();
    Object.keys(rowData).forEach(key => {
      if (isMergeHorizontalKey(key)) {
        mergeColumnId[key] = Number(rowData[key]);
      }
      const columnIndexToSet = getColumnIndex(key);
      if (isColumnIdSpecialWord(key) || columnIndexToSet < startIndex) {
        return;
      }
      if (columnIndexToSet > endIndex - 1) {
        columnKeys.add(key);
      }
      columnKeys.add(getShiftedColumnId(key, offset));
    });

    shiftCellsInRow(columnKeys, rowData);
    if (Object.keys(mergeColumnId)) {
      const updates = changeMergeColumnValue(mergeColumnId, offset, startIndex-1);
      for (const update of updates) {
        const cellId = ExcelFormulaHelper.getCellLink(null, update.columnId, rowId);
        updatedCellsMap[cellId] = {
          rowId,
          columnId: update.columnId,
          value: update.value,
          prevValue: update.prevValue,
        };
      }
    }
  });

  return { updatedCellsMap, moveCellRefMap };
};
