import { arrayUtils } from 'common/utils/array-utils';
import { ShortPointDescription } from '../../interfaces';

interface PointsResult {
  points: ShortPointDescription[];
  pointsInfo: Array<{ positive: boolean, edge: boolean }>;
}


function fillContours(
  { points, pointsInfo }: PointsResult,
  contours: ShortPointDescription[][],
  positive: boolean,
): void {
  for (const contour of contours) {
    arrayUtils.extendArray(points, contour);
    points.push(contour[0]);
    pointsInfo.push({ positive, edge: false });
    arrayUtils.extendArray(pointsInfo, new Array(contour.length).fill({ positive, edge: true }));
  }
}

function prepareContoursAndPoints(
  positiveContours: ShortPointDescription[][],
  negativeContours: ShortPointDescription[][],
): PointsResult {
  const result: PointsResult = {
    points: [],
    pointsInfo: [],
  };
  fillContours(result, positiveContours, true);
  fillContours(result, negativeContours, false);
  return result;
}

export const MagicSearchPayloadUtils = {
  prepareContoursAndPoints,
};
