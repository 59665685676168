import { Icons, Input } from '@kreo/kreo-ui-components';
import React, { useCallback, useState } from 'react';

import { CustomDialog } from '../custom-dialog';
import { LinkComponent } from '../link-component';
import { Styled } from './styled';


interface Props {
  companyName: string;
  companyId: number;
  companyNameChange?: (name: string) => void;
  deleteCompany?: (companyId: number) => void;
}

export const CompanyTab: React.FC<Props> = React.memo(({
  companyName,
  companyId,
  companyNameChange,
  deleteCompany,
}) => {
  const [showConfirmPopUp, setShowConfirmPopUp] = useState(false);
  const [tempCompanyName, setTempCompanyName] = useState(companyName);

  const deleteCompanyHandler = useCallback(() => deleteCompany(companyId), [companyId, deleteCompany]);

  const onBlurHandler = useCallback(() => {
    if (tempCompanyName.trim()) {
      companyNameChange(tempCompanyName);
    } else (
      setTempCompanyName(companyName)
    );
  }, [tempCompanyName, companyName, companyNameChange]);

  const onClickShowConfirmPopUp = useCallback(() => {
    setShowConfirmPopUp(true);
  }, []);

  const onSubmit = useCallback(() => {
    deleteCompanyHandler();
    setShowConfirmPopUp(false);
  }, [deleteCompanyHandler]);

  const onReject = useCallback(() => {
    setShowConfirmPopUp(false);
  }, []);

  return (
    <Styled.Company>
      <Input
        width={'100%'}
        type="text"
        text={'Title'}
        input={{
          value: tempCompanyName,
          onChange: e => setTempCompanyName(e.target.value),
          onBlur: onBlurHandler,
          disabled: !companyNameChange,
        }}
      />
      {deleteCompany &&
        <Styled.DeleteCompanyButton>
          <LinkComponent
            text={'Delete Company'}
            icon={Icons.Delete}
            onClick={onClickShowConfirmPopUp}
            mood={'negative'}
          />
        </Styled.DeleteCompanyButton>
      }
      {showConfirmPopUp &&
        <CustomDialog
          mainText={'Delete your company'}
          secondaryText={'The action cannot be undone'}
          onSubmit={onSubmit}
          onReject={onReject}
        />
      }
    </Styled.Company>
  );
},
);
