import { TinyText } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AuthImage } from 'common/components/auth-image';
import { DrawingsActions } from 'common/components/drawings';
import { DrawingsApi } from 'common/components/drawings/api/drawings-api';
import { DrawingsShortInfo } from 'common/components/drawings/interfaces/drawings-short-drawing-info';
import { LabelWithRename } from 'common/components/label-with-rename';
import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { Styled } from './styled';

interface OwnProps {
  item: DrawingsShortInfo;
  onClickTab: (id: string) => void;
  canEditMeasurement: boolean;
}

interface ComponentState {
  screenShotLink: string;
}

interface StateProps {
  pdfName: string;
  projectId: number;
}

interface DispatchProps {
  onParameterUpdate: (drawingId: string, parameter: string, value: string | number) => void;
}

interface Props extends OwnProps, StateProps, DispatchProps {}

class MenuFileManagerPageTabComponent extends React.PureComponent<Props, ComponentState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      screenShotLink: props.item.screenshotCreated &&
        DrawingsApi.getScreenshotLink(props.item.pdfId, props.item.drawingId),
    };
  }

  public static getDerivedStateFromProps(props: Props, prevState: ComponentState): Partial<ComponentState> {
    if (!prevState.screenShotLink && props.item.screenshotCreated) {
      return {
        screenShotLink: DrawingsApi.getScreenshotLink(props.item.pdfId, props.item.drawingId),
      };
    }
    return null;
  }

  public render(): JSX.Element {
    const { pdfName, canEditMeasurement, item } = this.props;
    const { color, pageNumber, name } = item;
    return (
      <Styled.Container
        onClick={this.onClickTab}
      >
        <Styled.PreviewImage>
          {this.state.screenShotLink && (
            <AuthImage
              src={this.state.screenShotLink}
              alt={'screenshot preview'}
              showSpinner={true}
            />
          )}
          <Styled.PageBubble color={color}>
            <TinyText>{pageNumber + 1}</TinyText>
          </Styled.PageBubble>
        </Styled.PreviewImage>
        <Styled.TextContainer onClick={ConstantFunctions.stopEvent}>
          <LabelWithRename
            value={name}
            changeValue={this.onChange}
            fontSize={14}
            disableRenaming={!canEditMeasurement}
            backgroundColor={'background'}
            isHoverBackground={true}
            isHoverEditIcon={true}
          />
          <TinyText color={ColorsName.turquoiseFont} withEllipsis={true}>
            {pdfName}
          </TinyText>
        </Styled.TextContainer>
      </Styled.Container>
    );
  }

  @autobind
  private onClickTab(): void {
    this.props.onClickTab(this.props.item.drawingId);
  }

  @autobind
  private onChange(value: string): void {
    if (value && value !== this.props.item.name) {
      this.props.onParameterUpdate(this.props.item.drawingId, 'name', value);
    }
  }
}

function mapStateToProps(state: State, { item }: OwnProps): StateProps {
  return {
    projectId: state.projects.currentProject.id,
    pdfName: state.drawings.files.entities[item.pdfId].properties.name,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    onParameterUpdate: (drawingId, parameter, value) =>
      dispatch(DrawingsActions.pageMetaParameterUpdate(drawingId, parameter, value)),
  };
}

export const MenuFileManagerPageTab = connect(mapStateToProps, mapDispatchToProps)(MenuFileManagerPageTabComponent);
