import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import {
  DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG,
  DrawingsLeaveProjectConfirmationDialog,
} from 'common/components/drawings/dialogs';
import { RenderIf } from 'common/components/render-if';
import { ProjectType } from 'common/constants/project-type';
import { ProjectCreateStatus } from 'common/enums/project-create-status';
import { RequestStatus } from 'common/enums/request-status';
import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { AppUrls } from 'routes/app-urls';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { ProjectsActions } from 'unit-projects/actions/creators/common';
import { ProjectsFolderActions } from 'unit-projects/actions/creators/projects-folder';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { CompanyProjectHeader, CompanyProjectHeaderUser } from 'unit-projects/interfaces/company-project-header';
import { Project } from 'unit-projects/interfaces/project';
import { ProjectsFolder } from 'unit-projects/interfaces/projects-folder';
import {
  PROJECT_DUPLICATE_DIALOG,
} from '../../../../units/2d-projects-page/2d-project-duplicate-dialog';
import {
  MOVE_TO_FOLDER_DIALOG_NAME,
} from '../../../../units/2d-projects-page/2d-projects-content/move-to-folder-dialog';
import {
  PROJECT_NAME_EDIT_DIALOG_NAME,
  EditProjectNameDialog,
} from '../../../../units/2d-projects-page/2d-projects-name-edit-dialog';
import { AccountApi } from '../../../../units/account/api';
import { ProfileDialog } from '../../../../units/account/components/profile-dialog';
import { PeopleActions } from '../../../../units/people/actions/actions';
import { Person } from '../../../../units/people/interfaces/person';
import { DuplicateProjectDialog } from './duplicate-project-dialog';
import { FolderDialogs } from './folder-dialogs';
import { MenuProjectButton } from './menu-project-button';
import { MenuProjectDropDown } from './menu-project-drop-down/menu-project-drop-down';
import { ProjectRemoveDialog } from './project-remove-dialog';
import { Styled } from './styled';


export interface UserListInstance {
  name: string;
  email: string;
  avatar: string;
}

interface DispatchProps {
  fetchFolders: () => void;
  disconnectRealtime: () => void;
  showDialog: (name: string, data?: any) => void;
  removeProject: (projectId: number) => void;
  failureProjectIdSend: (projectId: number) => void;
  saveProjectAsTemplate: (projectId: number) => void;
  goToProjects: () => void;
  startCreateComment: () => void;
  leaveProject: (projectId: number) => void;
  setCurrentFolder: (folder: ProjectsFolder) => void;
  setShowInviteUsersPanel: (isOpen: boolean) => void;
}

interface StateProps {
  currentUserId: string;
  companyUsers: Person[];
  projectUsers: CompanyProjectHeaderUser[];
  projectHeaders: Record<number, CompanyProjectHeader>;
  currentProject: Project;
  currentFolder: ProjectsFolder;
  companyId: number;
  allFetched: boolean;
  projectHeadersRequestStatus: RequestStatus;
  projectType: ProjectType;
  searchQuery: string | null;
  projectInvitedUsers: Person[];
  folders: ProjectsFolder[];
  foldersLoadingStatus: RequestStatus;
}

interface ComponentState {
  isProfileDropDownOpened: boolean;
  projectToBeRemoved: CompanyProjectHeader | Project | null;
  currentProjectId: number;
  usersList: UserListInstance[];
  projectCreateStatus: ProjectCreateStatus;
  parentFolderId: string;
}

export const REMOVE_PROJECT_DIALOG_NAME = 'project-remove-dialog';


interface Props extends DispatchProps, StateProps, AbilityAwareProps {
  showMenu: boolean;
}

class MenuProjectFastNavigationComponent extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isProfileDropDownOpened: false,
      currentProjectId: null,
      projectToBeRemoved: null,
      projectCreateStatus: null,
      usersList: this.getUserList(),
      parentFolderId: null,
    };
  }

  public render(): React.ReactNode {
    const { currentProject, showMenu, projectType, projectHeaders } = this.props;
    const projectTypeName = 'project';
    const showFastMenu = showMenu && projectType === ProjectType.Project2d;

    return (
      <RenderIf condition={showFastMenu}>
        <Styled.Container>
          <FolderDialogs
            saveParentFolderId={this.saveParentFolderId}
            projectHeader={projectHeaders[currentProject?.id]}
          />
          <RenderIf condition={!!currentProject}>
            <EditProjectNameDialog />
          </RenderIf>
          <ProjectRemoveDialog
            projectToBeRemoved={this.state.projectToBeRemoved}
            type={projectTypeName}
            removeProject={this.onProjectRemoveConfirmed}
          />
          <DrawingsLeaveProjectConfirmationDialog onSubmit={this.onProjectLeaveConfirmed}/>
          <DuplicateProjectDialog
            currentProjectId={this.state.currentProjectId}
          />
          <MenuProjectButton
            toggleDropDown={this.toggleDropDown}
            isOpen={this.state.isProfileDropDownOpened}
            projectName={currentProject?.name}
          />
          <RenderIf condition={this.state.isProfileDropDownOpened}>
            <Styled.MenuProjectDropDownWrapper>
              <MenuProjectDropDown
                startCreateComment={this.props.startCreateComment}
                usersList={this.state.usersList}
                onClose={this.toggleDropDown}
                moveProject={this.openMoveToFolderDialog}
                onInviteToProject={this.onInviteToProjectClick}
                onDuplicateProject={this.onOpenDuplicateProjectDialog}
                onProjectRename={this.onProjectRename}
                deleteProject={this.onRemoveProject}
                onLeaveProject={this.onLeaveProject}
                onSaveAsTemplate={this.saveProjectAsTemplate}
                parentFolderId={this.state.parentFolderId}
                projectHeader={projectHeaders[currentProject?.id]}
              />
            </Styled.MenuProjectDropDownWrapper>
          </RenderIf>
          <ProfileDialog />
        </Styled.Container>
      </RenderIf>
    );
  }

  public componentDidMount(): void {
    this.fetchProjectFolders();
  }

  public componentDidUpdate(prevProps: Props): void {
    const { projectToBeRemoved, projectCreateStatus } = this.state;
    if (
      projectToBeRemoved
      && !projectToBeRemoved.projectFailureNotified
      && projectCreateStatus === ProjectCreateStatus.Failed
    ) {
      this.props.failureProjectIdSend(projectToBeRemoved.id);
    }

    if (
      this.props.companyUsers !== prevProps.companyUsers
      || this.props.projectUsers !== prevProps.projectUsers
      || this.props.projectInvitedUsers !== prevProps.projectInvitedUsers
      || this.props.projectHeaders !== prevProps.projectHeaders
    ) {
      this.setState({ usersList: this.getUserList() });
    }

    const areFoldersLoaded = this.props.foldersLoadingStatus === RequestStatus.Loaded;
    const prevAreFoldersLoaded = prevProps.foldersLoadingStatus === RequestStatus.Loaded;

    if (areFoldersLoaded && this.props.currentProject && (!prevAreFoldersLoaded || !prevProps.currentProject)) {
      if (!this.props.currentProject.folderId) {
        this.props.setCurrentFolder(null);
      } else if (this.props.folders) {
        const folder = this.props.folders.find(item => item.id === this.props.currentProject.folderId);
        this.props.setCurrentFolder(folder);
      }
    }
  }

  private fetchProjectFolders(): void {
    if (this.props.ability.can(Operation.Update, Subject.Project)) {
      this.props.fetchFolders();
    }
  }

  @autobind
  private openMoveToFolderDialog(): void {
    this.props.showDialog(
      MOVE_TO_FOLDER_DIALOG_NAME,
      {
        currentFolder: this.props.currentFolder,
        selectedFolder: null,
        projectId: this.props.currentProject.id,
      },
    );
  }

  @autobind
  private saveParentFolderId(id: number): void {
    this.setState({ parentFolderId: String(id) });
  }

  @autobind
  private onInviteToProjectClick(): void {
    this.props.setShowInviteUsersPanel(true);
    this.toggleDropDown();
  }

  @autobind
  private getUserList(): UserListInstance[] {
    const { projectInvitedUsers, currentProject, currentUserId, companyUsers, projectHeaders } = this.props;
    const users = projectInvitedUsers.length ? projectInvitedUsers : currentProject?.people;
    const projectUsers = new Set<string>(users?.map(user => user.id));
    projectUsers.delete(currentUserId);
    const filteredGuests = companyUsers.filter(user => user.roleGroup === RoleGroup.Guest
      && this.props.projectUsers?.some(u => u.email === user.email));
    const filteredUsers = currentProject && projectHeaders && projectHeaders[currentProject.id]?.isCompanyShared
      ? filteredGuests
      : currentProject && projectHeaders[currentProject.id] && !projectHeaders[currentProject.id]?.isCompanyShared
        ? (projectHeaders[currentProject.id].users as Person[])
        : companyUsers;

    return arrayUtils.filterMap(
      filteredUsers,
      user => projectUsers.has(user.id),
      user => {
        return {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          avatar: AccountApi.getAvatarLink(user),
        };
      },
    );
  }


  @autobind
  private onOpenDuplicateProjectDialog(): void {
    const { currentProject, showDialog } = this.props;
    this.setState(
      { currentProjectId: currentProject.id },
      () => showDialog(PROJECT_DUPLICATE_DIALOG),
    );
  }

  @autobind
  private onProjectRename(): void {
    const { currentProject, showDialog } = this.props;
    this.setState(
      { currentProjectId: currentProject.id },
      () => showDialog(PROJECT_NAME_EDIT_DIALOG_NAME, currentProject),
    );
  }

  @autobind
  private onProjectRemoveConfirmed(projectId: number): void {
    this.props.disconnectRealtime();
    this.props.removeProject(projectId);
    this.setState({ projectToBeRemoved: null });
  }

  @autobind
  private onRemoveProject(): void {
    const { currentProject, showDialog } = this.props;
    const projectStatus = currentProject.viewModelsStatuses;
    const isFailedStatus = projectStatus && projectStatus.status === ViewModelStatus.Failed;
    const isLoadingStatus = !isFailedStatus && projectStatus && projectStatus.status !== ViewModelStatus.Ready;
    const createStatus = isLoadingStatus
      ? ProjectCreateStatus.Loading
      : ProjectCreateStatus.Created;
    this.setState(
      {
        projectToBeRemoved: currentProject,
        projectCreateStatus: createStatus,
      },
      () => showDialog(REMOVE_PROJECT_DIALOG_NAME),
    );
  }

  @autobind
  private onLeaveProject(): void {
    this.props.showDialog(DRAWING_LEAVE_PROJECT_CONFIRMATION_DIALOG);
  }

  @autobind
  private onProjectLeaveConfirmed(): void {
    const { currentProject, leaveProject, goToProjects } = this.props;
    goToProjects();
    leaveProject(currentProject.id);
  }

  @autobind
  private saveProjectAsTemplate(): void {
    this.props.saveProjectAsTemplate(this.props.currentProject.id);
    this.toggleDropDown();
  }

  @autobind
  private toggleDropDown(): void {
    this.setState({ isProfileDropDownOpened: !this.state.isProfileDropDownOpened });
  }
}


const mapStateTopProps = (state: State): StateProps => {
  const selectedCompany = state.account.selectedCompany;
  const currentProject = state.projects.currentProject;
  const projectType = currentProject?.type;

  const companyId = selectedCompany ? selectedCompany.id : null;
  const headersStore = state.projects.projectHeadersByCompanyId[companyId];
  const projectHeadersStore = currentProject && headersStore && headersStore[projectType];
  const { data: projectHeaders, searchQuery, allFetched } = projectHeadersStore
    ? projectHeadersStore
    : { data: {}, searchQuery: '', allFetched: false };

  const projectHeader = currentProject && headersStore && headersStore[projectType]?.data[currentProject.id];

  const projectHeadersRequestStatus = state.projects.requests.projectHeadersByCompanyId[companyId]
    || RequestStatus.NotRequested;

  return {
    projectHeaders,
    currentProject,
    currentFolder: state.projects.currentFolder,
    companyId,
    allFetched,
    projectHeadersRequestStatus,
    projectType,
    searchQuery,

    companyUsers: state.people.companiesUsers,
    projectUsers: projectHeader?.users,
    currentUserId: state.account.id,
    projectInvitedUsers: state.projects.projectInvitedUsers,
    folders: state.projects.folders,
    foldersLoadingStatus: state.projects.foldersLoadingStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    fetchFolders: () => dispatch(ProjectsFolderActions.getFolders()),
    showDialog: (dialogName, data) => dispatch(KreoDialogActions.openDialog(dialogName, data)),
    removeProject: projectId => dispatch(ProjectsActions.removeProject(projectId)),
    failureProjectIdSend: projectId => dispatch(ProjectsActions.failureProjectIdSend(projectId)),
    saveProjectAsTemplate: (projectId) => dispatch(ProjectsActions.createTemplateFromProject(projectId)),
    goToProjects: () => dispatch(push(AppUrls.qto2d.listing.path)),
    startCreateComment: () => dispatch(TwoDCommentsActions.startAddComment({ type: CommentaryTargetType.Project })),
    leaveProject: projectId => dispatch(ProjectsActions.leaveProject(projectId)),
    disconnectRealtime: () => dispatch(TwoDCommentsActions.disconnectRealtime()),
    setCurrentFolder: (folder) => dispatch(ProjectsFolderActions.setCurrentFolder(folder)),
    setShowInviteUsersPanel: (isOpen) => dispatch(PeopleActions.setShowInviteUsersPanel(isOpen)),
  };
};


const connector = connect(mapStateTopProps, mapDispatchToProps);

export const MenuProjectFastNavigation = connector(withAbilityContext(MenuProjectFastNavigationComponent));
