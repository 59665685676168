import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { FinderSaveType } from 'common/components/drawings/interfaces';
import { InputSelectNoIndex } from 'common/components/inputs';
import { RenderIf } from 'common/components/render-if';
import { Spinner } from 'common/components/spinner';
import { PropertyWithInfo } from '../../property-with-info';
import { SimilaritySliderWithCount } from '../../similarity-slider';
import { ToolMenuButton } from '../../tool-menu-button';
import { ApplyButton } from '../apply-button';
import { useResetResult } from '../finder-menu/hooks';
import { useApplyWithState } from '../hooks';
import { WizzardMainContainer } from '../wizzard-main-container';
import { Styled } from './styled';

interface Props {
  apply: () => Promise<void>;
  count: number;
  withSimilaritySlider?: boolean;
  minSimilarity?: number;
  maxSimilarity?: number;
  similarity?: number;
  onSimilarityChange?: (value: number) => void;
  visibleCount?: number;
  exportType?: FinderSaveType;
  onExportTypeChange?: (type: FinderSaveType) => void;
}

const OPTIONS = [
  FinderSaveType.Count,
  FinderSaveType.Area,
  FinderSaveType.CenterLine,
  FinderSaveType.MaxLengthSegments,
  FinderSaveType.MinLengthSegments,
];

const ResultsMenuComponent: React.FC<Props> = ({
  apply,
  count,
  withSimilaritySlider,
  minSimilarity,
  maxSimilarity,
  similarity,
  onSimilarityChange,
  visibleCount,
  exportType,
  onExportTypeChange,
}) => {

  const [ applyCallback, isProcessing ] = useApplyWithState(apply);
  const reset = useResetResult();

  return (
    <WizzardMainContainer>
      <Spinner show={isProcessing} withBackground={true}/>
      <Styled.Header>
        <SimilaritySliderWithCount
          count={count}
          hideSlider={!withSimilaritySlider}
          minSimilarity={minSimilarity}
          maxSimilarity={maxSimilarity}
          similarity={similarity}
          onSimilarityChange={onSimilarityChange}
        />
      </Styled.Header>
      <ToolMenuButton
        text='Reset'
        icon={Icons.UndoClassic}
        onClick={reset}
      />
      <RenderIf condition={!!onExportTypeChange}>
        <PropertyWithInfo
          name='Save as'
          tooltipText='Select the type of the result you want to save'
        >
          <InputSelectNoIndex
            elements={OPTIONS}
            onClick={onExportTypeChange}
            createNewElement={false}
            activeElement={exportType}
            isShowArrow={true}
          />
        </PropertyWithInfo>
      </RenderIf>
      <ApplyButton
        count={visibleCount ?? count}
        apply={applyCallback}
      />
    </WizzardMainContainer>
  );
};

export const ResultsMenu = React.memo(ResultsMenuComponent);
