import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  DrawingsAllowedPathType,
  DrawingsPolygonGeometry,
  DrawingsRenderParams,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGeometryConverters } from 'common/components/drawings/utils/drawings-geometry-converters';
import { DrawingsPaperUtils } from 'common/components/drawings/utils/drawings-paper-utils';
import { DestroyableObject, EngineObjectConfig } from '../../../common';
import { FinishedDrawingElement } from '../../../drawings-geometry-entities/temporary';
import { NewDrawingSettings } from '../../../interfaces';
import { ThreeDParamsHelper } from '../../threed-params-helper';
import { AIToolsConstants } from './constants';

interface Config extends EngineObjectConfig {
  layer: paper.Group | paper.Layer;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  renderParamsContextObserver: ContextObserver<DrawingsRenderParams>;
  colorCache: DrawingsColorCacheHelper;
  closed: boolean;
}

export interface SearchWindow{
  dpi: number;
  points: paper.Point[];
}

export interface AddPolygonPayload {
  contours: ShortPointDescription[][][];
}

export class BucketAIPreview extends DestroyableObject<Config> {
  protected _lastGeometry: DrawingsAllowedPathType[];
  protected _currentSearchWindow: Array<{ dpi: number, points: paper.Point[] }> = [];

  protected _windowSize: number;
  protected _group: paper.Group;
  private _lastDpi: number;

  constructor(config: Config) {
    super(config);
    this._group = new paper.Group();
    this._group.addTo(this._config.layer);
    this._config.newDrawingStylesObserver.subscribe(this.onUpdateDrawingStyles);
    this._config.renderParamsContextObserver.subscribe(this.onUpdateZoom);
  }

  public destroy(): void {
    this._config.newDrawingStylesObserver.unsubscribe(this.onUpdateDrawingStyles);
    this._config.renderParamsContextObserver.unsubscribe(this.onUpdateZoom);
    this._group.remove();
  }

  public get currentGeomentry(): DrawingsAllowedPathType[] {
    return this._lastGeometry;
  }

  public validate(): boolean {
    let isValid = true;
    if (!this._config.closed && this._lastGeometry) {
      for (const geometry of this._lastGeometry) {
        if (DrawingsPaperUtils.isPolygonSelfIntersected(geometry)) {
          isValid = false;
          geometry.strokeColor = DrawingsCanvasColors.warningStrokeColor;
          if (this._config.closed) {
            geometry.fillColor = DrawingsCanvasColors.warningFillColor;
          }
        }
      }
    }
    return isValid;
  }

  public getGeometry(): FinishedDrawingElement[] {
    const { color, strokeStyle, strokeWidth } = this._config.newDrawingStylesObserver.getContext();
    const context = this._config.newDrawingStylesObserver.getContext();
    const result = this._lastGeometry.map((g) => {
      const { contour, points, children } = DrawingsGeometryConverters.simpleConvertToPolygon(g);
      const geometry: DrawingsPolygonGeometry = {
        points: contour,
        children,
        color,
        strokeStyle,
        strokeWidth,
      };
      ThreeDParamsHelper.addToPolygon(geometry, context);
      return {
        type: this._config.closed ? DrawingsInstanceType.Polygon : DrawingsInstanceType.Polyline,
        points,
        geometry,
      };
    });

    return result;
  }

  public hasGeometies(): boolean {
    return !!this._lastGeometry.length;
  }

  public getDpiBasedOnWindow(point: paper.Point): number | undefined {
    const hitResult = this._currentSearchWindow.findIndex(({ points: [topLeft, bottomRight] }) => {
      return (topLeft.x <= point.x && point.x <= bottomRight.x && topLeft.y <= point.y && point.y <= bottomRight.y);
    });
    switch (hitResult) {
      case -1:
        this._lastDpi = undefined;
        break;
      case 0:
        this._lastDpi = this._lastDpi || this._currentSearchWindow[0].dpi;
        break;
      case 1:
        this._lastDpi = this._currentSearchWindow[1].dpi;
        break;
      default:
    }
    return this._lastDpi;
  }

  public isPointOutsideLastPolygon(point: paper.Point): boolean {
    return this._lastGeometry.every(g => !g.contains(point));
  }

  public isPointOnLine(point: paper.Point): boolean {
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    const threshold = AIToolsConstants.POINT_COMPARISON_THRESHOLD * zoom;
    return this._lastGeometry.some(g => {
      const nearest = g.getNearestPoint(point);
      return nearest.getDistance(point) < threshold;
    });
  }

  public setGeometry({ contours }: AddPolygonPayload): void {
    this.restoreColor();
    this.replaceLastPolygon(contours);
  }

  protected refreshGroupStyles(): void {
    const { strokeStyle, strokeWidth, color } = this._config.newDrawingStylesObserver.getContext();
    const { zoom } = this._config.renderParamsContextObserver.getContext();
    const { stroke, fill } = this._config.colorCache.getPaperColor(color);
    this._group.strokeColor = stroke;
    if (this._config.closed) {
      this._group.fillColor = fill;
    }
    this._group.strokeWidth = strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke({ strokeStyle, strokeWidth }, zoom);
  }

  @autobind
  private onUpdateZoom({ zoom }: { zoom: number }): void {
    const newStyle = this._config.newDrawingStylesObserver.getContext();
    this._group.strokeWidth = newStyle.strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
  }

  @autobind
  private onUpdateDrawingStyles(newStyle: NewDrawingSettings): void {
    const color = this._config.colorCache.getPaperColor(newStyle.color);
    const zoom = this._config.renderParamsContextObserver.getContext().zoom;
    this._group.strokeWidth = newStyle.strokeWidth / zoom;
    this._group.dashArray = DrawingsCanvasUtils.scaleStroke(newStyle, zoom);
    this._group.strokeColor = color.stroke;
    if (this._config.closed) {
      this._group.fillColor = color.fill;
    }
  }

  private replaceLastPolygon(contours: ShortPointDescription[][][]): void {
    if (this._lastGeometry) {
      this._lastGeometry.forEach(g => g.remove());
    }
    this._lastGeometry = contours.map(contour => {
      let currentContour: DrawingsAllowedPathType;
      if (contour.length === 1) {
        currentContour = new paper.Path(contour[0].map(p => new paper.Point(p)));
      } else {
        const external = new paper.Path(contour[0].map(p => new paper.Point(p)));
        if (!external.clockwise) {
          external.reverse();
        }
        const allContours = [external];
        for (let i = 1; i < contour.length; i++) {
          const hole = new paper.Path(contour[i].map(p => new paper.Point(p)));
          if (hole.clockwise) {
            hole.reverse();
          }
          allContours.push(hole);
        }
        currentContour = new paper.CompoundPath({ children: allContours }) as DrawingsAllowedPathType;
      }
      if (this._config.closed) {
        currentContour.closePath();
      }
      currentContour.addTo(this._group);
      DrawingsPaperUtils.updatePathStyles(this._group);
      return currentContour;
    });
    this.refreshGroupStyles();
  }

  private restoreColor(): void {
    const { color } = this._config.newDrawingStylesObserver.getContext();
    const { stroke, fill } = this._config.colorCache.getPaperColor(color);
    this._group.strokeColor = stroke;
    if (this._config.closed) {
      this._group.fillColor = fill;
    }
    DrawingsPaperUtils.updatePathStyles(this._group);
  }
}
