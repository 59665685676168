import autobind from 'autobind-decorator';
import * as paper from 'paper';
import { ConstantFunctions } from 'common/constants/functions';
import { DeferredExecutor } from 'common/utils/deferred-executer';

import { DrawingsCanvasColors, DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { PaperMouseEventUtils } from '../drawings-helpers/paper-mouse-event-utils';
import {
  DrawingsGeometryEntity,
  DrawingsGeometryEntityPointBase,
  DrawingsGeometryEntityPointBaseConfig,
  DrawingsMouseEventHandler,
} from './base';


export interface DrawingsGeometryEntityPointConfig<T> extends DrawingsGeometryEntityPointBaseConfig {
  layer: paper.Layer | paper.Group;
  color?: paper.Color;
  canDelete?: boolean;
  canModify?: boolean;
  forceCanMove?: boolean;
  onMouseMove?: DrawingsMouseEventHandler;
  radius?: number;
  noBorder?: boolean;
  strokeWidth?: number;
  metadata?: T;
}

export class DrawingsGeometryEntityPoint<
  T = any,
  C extends DrawingsGeometryEntityPointConfig<T> = DrawingsGeometryEntityPointConfig<T>
>
  extends DrawingsGeometryEntityPointBase<C>
  implements DrawingsGeometryEntity {
  protected _group: paper.Group;
  protected _point: paper.Path.Circle;
  protected _position: paper.Point;

  private _strokeWidth: number = DrawingsCanvasConstants.infoLinesStroke;
  private _dragExecutor: DeferredExecutor = new DeferredExecutor(DrawingsCanvasConstants.doubleClickDelay / 2);

  constructor(config: C) {
    config.color = config.color || DrawingsCanvasColors.pointColor;
    super(config);
    this.render();
    this._config.renderParamsContextObserver.subscribe(this.changeVisualData);

  }

  public get metadata(): T {
    return this._config.metadata;
  }

  public get styles(): paper.Style {
    return this._point.style;
  }

  public set styles(value: paper.Style) {
    this._point.style = value;
  }

  public get strokeWidth(): number {
    return this._strokeWidth;
  }

  public set strokeWidth(value: number) {
    this._strokeWidth = value;
    this._point.strokeWidth = value / this._config.renderParamsContextObserver.getPrevContext().zoom;
  }

  @autobind
  public destroy(): void {
    this._group.remove();
    this._config.renderParamsContextObserver.unsubscribe(this.changeVisualData);
  }

  @autobind
  public onMouseLeave(e: PaperMouseEvent): void {
    if (!this._selected) {
      this._point.strokeColor = DrawingsCanvasColors.white;
    }
    if (this._config.onMouseLeave) {
      this._config.onMouseLeave(this._config.id, e);
    } else if (this._config.cursorHelper) {
      this._config.cursorHelper.hovered = false;
    }
  }

  public setCanModify(value: boolean): void {
    this._config.canModify = value;
  }

  @autobind
  public changePosition(x: number, y: number): void {
    this._point.position = new paper.Point(x, y);
  }

  @autobind
  public onMouseMove(e: PaperMouseEvent): void {
    if (this._config.onMouseMove) {
      this._config.onMouseMove(this._config.id, e);
    }
  }

  protected render(): void {
    const pointInfo = this._config.geometry;
    this._position = Array.isArray(pointInfo) ? new paper.Point(pointInfo) : pointInfo;
    this._group = new paper.Group();
    this._group.addTo(this._config.layer);
    if (!this._config.radius) {
      this._config.radius = DrawingsCanvasConstants.pointRadius;
    }
    if (this._config.noBorder) {
      this._strokeWidth = 0;
    } else {
      this._strokeWidth = this._config.strokeWidth || DrawingsCanvasConstants.infoLinesStroke;
    }


    this._point = new paper.Path.Circle(this._position, this._config.radius);

    this._point.addTo(this._group);
    this._group.scale(1 / this._config.renderParamsContextObserver.getPrevContext().zoom);
    this._point.strokeWidth = this._strokeWidth;
    this._point.strokeColor = DrawingsCanvasColors.white;
    this._point.fillColor = this._config.color;

    this._group.onMouseLeave = this.onMouseLeave;
    this._group.onMouseEnter = this.onMouseEnter;
    this._group.onClick = this.onClick;

    if (this._config.forceCanMove) {
      this._group.onMouseUp = this.onMouseLeave;
      this._group.onMouseDown = this.onMouseDown;
    } else {
      this._group.onMouseDown  = this.onClick;
    }
  }

  @autobind
  protected onMouseDown(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      ConstantFunctions.stopEvent(e);
      if (this._config.canModify) {
        this._dragExecutor.execute(() => this._config.onStartDrag(this._config.id, e));
      }
    }
  }

  protected updateStylesBySelection(value: boolean): void {
    if (value) {
      this._point.strokeColor = this._config.color;
      this._group.onMouseUp = this.onMouseLeave;
      this._group.onMouseDown = this.onMouseDown;
    } else {
      this._point.strokeColor = DrawingsCanvasColors.white;
      if (!this._config.forceCanMove) {
        this._group.onMouseUp = undefined;
        this._group.onMouseDown = this.onClick;
      }
    }
  }

  @autobind
  protected onMouseEnter(e: PaperMouseEvent): void {
    this._point.strokeColor = this._config.color;
    if (this._config.onMouseEnter) {
      this._config.onMouseEnter(this._config.id, e);
    }
    if (this._config.cursorHelper) {
      if (this._selected) {
        this._config.cursorHelper.hoveredSelected = true;
      } else {
        this._config.cursorHelper.hovered = true;
      }
    }
  }

  @autobind
  protected override onClick(e: PaperMouseEvent): void {
    if (PaperMouseEventUtils.isLeftMouseButton(e)) {
      this._dragExecutor.reset();
      if (this._config.onSelect) {
        e.stopPropagation();
        this._config.onSelect(this._config.id, e);
      }
    }
  }

  @autobind
  protected changeVisualData({ zoom }: DrawingsRenderParams): void {
    this._group.scale(this._config.renderParamsContextObserver.getPrevContext().zoom / zoom);
    this._point.strokeWidth = this._strokeWidth / zoom;
  }
}
