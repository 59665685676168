import { EngineObject, EngineObjectConfig } from '../../common';
import { DrawingsEventsController } from '../../interfaces';

export class BaseEventsController<
  T extends EngineObjectConfig = EngineObjectConfig,
> extends EngineObject<T> implements DrawingsEventsController {
  protected _isActive: boolean;

  public isActive(): boolean {
    return this._isActive;
  }

  public activate(): void {
    this._isActive = true;
  }

  public deactivate(): void {
    this._isActive = false;
  }
}
