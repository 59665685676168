import { createGenerateClassName, createMuiTheme, jssPreset, MuiThemeProvider } from '@material-ui/core/styles';
import 'babel-polyfill';
import { ConnectedRouter } from 'connected-react-router';
import { create } from 'jss';
import React from 'react';
import { hot } from 'react-hot-loader';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// import './styles/fonts.scss';
import './styles/main.scss';

import { UpgradeProPlanDialog } from '2d/components/upgrade-plan/upgrade-plan-dialog';
import { AbilityContextProvider } from 'common/ability/ability-context';
import { CrashDialog } from 'common/components/crash-dialog';
import { TwoDItemContextMenu } from 'common/components/item-context-menu';
import { NoInternet } from 'common/components/no-internet';
import { SvgSpinner } from 'common/components/svg-spinner';
import { KreoColors } from 'common/enums/kreo-colors';
import { EnvironmentContextProvider } from 'common/environment/environment-context-provider';
import { I18nContextProvider } from 'common/i18n/i18n-context';
import { RealtimeAbilitiesHandler } from 'common/realtime/realtime-abilities-handler';
import { StoreSubscriptionHelper } from 'common/store-subscribtion-helper/store-subscription-helper';
import { ServiceWorkerConnector } from 'common/utils/service-worker';
import { ContactOwnerDialog } from 'components/dialog/contact-owner-dialog';
import { ThemeProvider } from './components/theme-provider';
import { AppRoutes } from './routes';
import { history, persistor, store } from './store';
import { Styled } from './styled';
import { KeycloakProvider } from './units/account/contexts/keycloak-provider/keycloak-provider';
import { AnalyticsController } from './units/analytics/analytics-controller';
import { SegmentContextProvider } from './units/analytics/components/segment-context';
import { NotificationController } from './units/notifications/notification-controller';
import { AddCompanyInfoPopup } from './units/people/components/company-info-wrapper/add-company-info-popup';
import { AnalyticsWraper, referralDistinctIdRegex } from './utils/posthog';

const theme = createMuiTheme({
  /* theme for v^1.x */
  palette: {
    primary: {
      main: `${KreoColors.gray7}`,
    },
    secondary: {
      main: `${KreoColors.gray9}`,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['Quicksand', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
  },
});

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point',
});

ServiceWorkerConnector.init();

StoreSubscriptionHelper.init();


class App extends React.Component {
  public constructor(props: any) {
    super(props);
    const search = window.location.search;
    const matches = search.match(referralDistinctIdRegex);
    if (matches?.length > 1) {
      const referralDistinctId = matches[1];
      localStorage.setItem('referralDistinctId', referralDistinctId);
    }
  }
  public render(): React.ReactNode {
    return (
      <Provider store={store}>
        <ThemeProvider>
          <EnvironmentContextProvider>
            <KeycloakProvider>
              <JssProvider jss={jss} generateClassName={generateClassName}>
                <PersistGate loading={<SvgSpinner size="large" />} persistor={persistor}>
                  <AbilityContextProvider>
                    <RealtimeAbilitiesHandler />
                    <I18nContextProvider>
                      <AnalyticsWraper>
                        <ConnectedRouter history={history}>
                          <MuiThemeProvider theme={theme}>
                            <React.Fragment>
                              <NoInternet />
                              <CrashDialog />
                              <UpgradeProPlanDialog />
                              <ContactOwnerDialog />
                              <AddCompanyInfoPopup />
                              <AppRoutes />
                              <NotificationController />
                              <SegmentContextProvider>
                                <AnalyticsController />
                              </SegmentContextProvider>
                              <TwoDItemContextMenu />
                              <Styled.ModalRoot id="modal-root" />
                            </React.Fragment>
                          </MuiThemeProvider>
                        </ConnectedRouter>
                      </AnalyticsWraper>
                    </I18nContextProvider>
                  </AbilityContextProvider>
                </PersistGate>
              </JssProvider>
            </KeycloakProvider>
          </EnvironmentContextProvider>
        </ThemeProvider>
      </Provider>
    );
  }
}

export default hot(module)(App);
