import { CursorHintType } from './cursor-hint-type';

export const CursorHintByType: Record<CursorHintType, string> = {
  [CursorHintType.Continue]: 'Select the point you want to continue from',
  [CursorHintType.WizzardPolylineInProgress]: 'Searching for segment',
  [CursorHintType.WizzardPolylineNotFound]: 'No segment found, please try click to another place',
  [CursorHintType.NoGeometryFoundByClick]: 'No geometry found, please try click to another place',
  [CursorHintType.NoGeometriesInArea]: 'No geometries in area, please type another area',
  [CursorHintType.ReachedLimitInArea]: 'Reached limit of selected geometries, please try another area',
  [CursorHintType.SelectionAreaCrashed]: 'Something went wrong, please try another area',

  [CursorHintType.BucketAINoPoints]: 'Set the first point to define the area.',
  [CursorHintType.BucketAINegativePoint]: 'Adding a point inside shrinks the area.',
  [CursorHintType.BucketAIExpandArea]: 'Adding a point outside expands it.',
  [CursorHintType.BucketFillInProgress]: 'Optimizing the area. Please wait...',

  [CursorHintType.BucketAIOutOffArea]: 'To extend current area, please click closer to it',
  [CursorHintType.BucketAIOnlyNegativePoints]: 'Set point to define the area.',
  [CursorHintType.BucketFillNotFound]: 'No area found, please try click to another place',

  [CursorHintType.OneClickLineNoPoints]: 'Set the first point to define the polyline.',
  [CursorHintType.OneClickLineNegativePoint]: 'Adding a point inside bends the polyline inward.',
  [CursorHintType.OneClickLineExpandLine]: 'Adding a point outside extends it outward.',
  [CursorHintType.OneClickLineInProgress]: 'Optimizing the polyline. Please wait...',

  [CursorHintType.OneClickLineNotFound]: 'No line found, please try click to another place',
  [CursorHintType.OneClickLineOutOffArea]: 'To current line, please click closer to it',
  [CursorHintType.OneClickLineOnlyNegativePoints]: 'Please set point to define the line',
};
