import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DrawingsDrawMode, ModesWithNewDrawingPanel } from 'common/components/drawings/enums';
import { useDrawModeApi, useNewInstancesSetting } from 'common/components/drawings/hooks';
import { WizzardToolsState } from 'common/components/drawings/interfaces';
import { DrawingsMeasureUtils } from 'common/components/drawings/utils/drawings-measure-utils';
import { State } from 'common/interfaces/state';
import { StringUtils } from 'common/utils/string-utils';

export function  useActualizeNewInstanceName(): void {
  const [ name, onChange ] = useNewInstancesSetting('name');
  const { drawMode } = useDrawModeApi();
  const wizzard = useSelector<State, WizzardToolsState>(s => {
    return s.drawings.wizzardTools;
  });

  const prevRecommendedName = useRef<string>();

  useEffect(() => {
    if (
      !ModesWithNewDrawingPanel.includes(drawMode)
        && drawMode !== DrawingsDrawMode.MagicSearch
        && drawMode !== DrawingsDrawMode.AutoMeasure2
    ) {
      if (name.toLowerCase() === prevRecommendedName.current) {
        onChange('');
      }
      prevRecommendedName.current = undefined;
      return;
    }
    const newName = DrawingsMeasureUtils.getInstanceTypeFromDrawMode({
      drawMode,
      wizzard,
    });
    if (!name || (newName !== prevRecommendedName.current && name.toLowerCase() === prevRecommendedName.current)) {
      onChange(StringUtils.firstUpperLetter(newName));
    }
    prevRecommendedName.current = newName;
  }, [drawMode, wizzard, name, onChange]);
}
