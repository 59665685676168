import { SubscriptionPlanListingModel } from '../interfaces/subscription-plan-listing-model';
import { SubscriptionPlanVariant } from '../interfaces/subscription-plan-variant';

export const getPlan = (planModel: SubscriptionPlanListingModel, id: string): SubscriptionPlanVariant | null => {
  for (const plan of planModel.plans) {
    for (const variant of plan.variants) {
      if (variant.id === id) {
        return variant;
      }
    }
  }
  return null;
};
