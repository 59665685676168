import * as Ag from 'ag-grid-community';
import { filterValueGetter } from './helpers/filter-value-getter';
import { valueFormatter } from './helpers/value-formatter';


export const mapColumnStateToColDef = (
  columns: Ag.ColumnState[],
  breakdownColumns: Record<string, Ag.ColDef>,
): Ag.ColDef[] => {
  const columnsList: Ag.ColDef[] = columns.map(column => {
    const field = column.colId.replace(/[\]\[]/g, '');
    const colDef: Ag.ColDef = {
      field,
      resizable: false,
      enableRowGroup: false,
      lockVisible: true,
      sortable: false,
      cellRenderer: 'defaultCellRenderer',
      cellClass: 'ag-right-aligned-cell',
      enableValue: true,
      suppressMovable: true,
      filterValueGetter,
      valueFormatter: valueFormatter(column),
      ...column,
    };
    return colDef;
  }).filter(c => c.colId !== 'ag-Grid-AutoColumn');

  columnsList.unshift(...Object.values(breakdownColumns));
  return columnsList;
};
