import * as React from 'react';

import { CloseButton } from 'common/components/questionnaire/close-button';
import { ContinueButton } from 'common/components/questionnaire/continue-button';
import { RenderIf } from 'common/components/render-if';
import { Mood } from 'common/enums/mood';
import { Styled } from './styled';


interface Props {
  showContinueButton: boolean;
  continueButtonTitle: string;
  closeButtonClick: () => void;
  onClickContinue?: () => void;
}


export const ControlButtons: React.FC<Props> = ({
  showContinueButton,
  continueButtonTitle,
  onClickContinue,
  closeButtonClick,
}) => {

  return (
    <Styled.Container>
      <CloseButton
        onClick={closeButtonClick}
      />
      <RenderIf condition={showContinueButton}>
        <ContinueButton
          onClick={onClickContinue}
          buttonTitle={continueButtonTitle}
          buttonMood={Mood.Negative}
        />
      </RenderIf>
    </Styled.Container>
  );
};
