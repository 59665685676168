import autobind from 'autobind-decorator';
import { DrawingsDrawMode } from 'common/components/drawings/enums';
import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { EngineObjectConfig } from '../../common';
import { ModifyController, SetDrawModeCallback } from '../../interfaces';
import { BaseEventsController } from './base-events-controller';

interface DrawingsModifyKeyListenerHelperConfig extends EngineObjectConfig {
  api: GlobalKeyboardEventsControllerContextProps;
  changeDrawMode: SetDrawModeCallback;
  modifyController: ModifyController;
}

export class DrawingsModifyKeyListenerHelper extends BaseEventsController<DrawingsModifyKeyListenerHelperConfig> {
  public activate(): void {
    super.activate();
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.addKeyDownEventListener(ModifyHotkey.RemoveModifyPoints, this.removePoints);
  }

  public deactivate(): void {
    super.deactivate();
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this.disableDrawMode);
    api.removeKeyDownEventListener(ModifyHotkey.RemoveModifyPoints, this.removePoints);
  }

  @autobind
  private disableDrawMode(): void {
    this._config.changeDrawMode(DrawingsDrawMode.Disabled);
  }

  @autobind
  private removePoints(): void {
    if (this._config.modifyController.isModifyEnabled) {
      this._config.modifyController.removeModifySelectedPoints();
    }
  }
}
