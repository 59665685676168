import { Icons, FlowNavigation, Text } from '@kreo/kreo-ui-components';
import * as Ag from 'ag-grid-community';
import autobind from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { DataBaseTable, getDataBaseTableNodeData } from 'common/components/data-base-table';
import { DataBaseTableRowData, RowData, UserInfo } from 'common/components/data-base-table/interfaces';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { arrayUtils } from 'common/utils/array-utils';
import { Constants } from 'unit-2d-database/constants';
import {
  getCurrentTabAndUpdateUrl,
  getQueryStringByTab,
  getTransaction,
  mapAssemblies,
  mapItems,
  mapProperties,
  mapUser,
  PrevDataMap,
  PropertiesTypeGuards,
} from 'unit-2d-database/helpers';
import { TwoDFastNavigationActions } from 'unit-2d-database/store-slice-fast-navigation';
import { PersistedStorageActions } from '../../../units/persisted-storage/actions/creators';
import {
  DatabaseExamples,
  DatabasesHeader,
  IconItems,
  IDatabaseHeader,
} from '../components';
import {
  DeleteBreakDownPropertyConfirmationDialog,
  DrawingsDeletePIAConfirmationDialog,
  REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG,
  REMOVE_PIA_CONFIRMATION_DIALOG,
} from '../components/side-panel/components/confirm-dialogs';
import { Assembly, Group, Item, MenuName, Property, PropertyTypeEnum, User } from '../interfaces';
import { TwoDDatabaseActions } from '../store-slice';
import { Styled } from './styled';

const emptyRowData = [];

interface StateProps {
  properties: Property[];
  items: Item[];
  assemblies: Assembly[];
  companyId: number;
  itemsGroups: Group[];
  assemblyGroups: Group[];
  users: User[];
  twoDDataBaseColumnsState: Record<string, Ag.ColumnState[]>;
  assembliesLocationUrl: string;
}

interface DispatchProps {
  setPropertyPanel: (type: PropertyTypeEnum, data: Property) => void;
  setItemPanel: (item: Item) => void;
  setAssemblyPanel: (assembly: Assembly) => void;
  openRemoveDialog: () => void;
  openRemoveBreakDownPropertyDialog: (ids: string[]) => void;
  dumpDatabase: () => void;
  deleteProperties: (ids: string[]) => void;
  duplicateProperty: (id: string) => void;
  updateItemGroupName: (groupId: string, value: string) => void;
  createItemsGroup: () => void;
  updateItemsParent: (targetId: string, sourceId: string, isSourceGroup: boolean) => void;
  deleteItem: (id: string, isGroup: boolean) => void;
  duplicateItem: (id: string) => void;
  openItemPanel: () => void;
  updateAssemblyGroupName: (groupId: string, value: string) => void;
  createAssemblyGroup: () => void;
  updateAssemblyParent: (targetId: string, sourceId: string, isSourceGroup: boolean) => void;
  deleteAssembly: (id: string, isGroup: boolean) => void;
  openAssemblyPanel: () => void;
  duplicateAssembly: (id: string) => void;
  setTwoDDataBaseColumnsState: (type: string, state: Ag.ColumnState[]) => void;
  setAssembliesCurrentTab: (search: string) => void;
}

export interface TabContentProps {
  databasesHeader: IDatabaseHeader;
  searchPlaceholder: string;
  searchText: string;
  onChangeGroupName?: (id: string, newValue: string) => void;
  onCreateGroup?: () => void;
  onDragEnd?: (targetId: string, sourceId: string, isGroup: boolean) => void;
  onDeleted?: (id: string, isGroup: boolean) => void;
  onDuplicate?: (id: string) => void;
}

interface Props extends StateProps, DispatchProps, AbilityAwareProps, RouteComponentProps<{}> {
  menuTop: number;
  saveGridApi?: (api: Ag.GridApi) => void;
}

interface OwnState {
  currentTab: MenuName;
  columns: Ag.ColDef[];
  gridApi: Ag.GridApi;
  assembliesLocationUrl: string;
}

class TwoDDatabaseComponent extends React.PureComponent<Props, OwnState> {
  private userInfo: UserInfo[] = [];
  private columnApi: Ag.ColumnApi;
  private rowDataMap: PrevDataMap = {};
  private rowParams: DataBaseTableRowData = {
    id: '',
    isGroup: false,
  };
  private tabPayload: Record<MenuName, TabContentProps> = {
    [MenuName.Properties]: {
      onChangeGroupName: null,
      onDeleted: (id, isGroup) => {
        if (isGroup) {
          const groupId = id.replace('g_', '');
          const ids = arrayUtils.filterMap(this.props.properties, x => x.groupName === groupId, x => x.id);
          this.deleteProperties(ids);
        } else {
          this.deleteProperties([id]);
        }
      },
      onDuplicate: (id) => this.props.duplicateProperty(id),
      databasesHeader: {
        helpCentreLink: 'https://help-takeoff.kreo.net/en/collections/3410550-assemblies-database',
        videoLink: '',
        baseName: MenuName.Properties,
        itemName: 'a property',
        onClickAddNew: null,
        icon: <Icons.PropertiesDataBase />,
      },
      searchPlaceholder: 'Start typing properties name',
      searchText: 'Search for properties',
    },
    [MenuName.Items]: {
      onChangeGroupName: this.props.updateItemGroupName,
      onCreateGroup: () => this.props.createItemsGroup(),
      onDragEnd: (targetId, sourceId, isGroup) => this.props.updateItemsParent(targetId, sourceId, isGroup),
      onDeleted: (id, isGroup) => this.props.deleteItem(id, isGroup),
      onDuplicate: (id) => this.props.duplicateItem(id),
      databasesHeader: {
        helpCentreLink: 'https://help-takeoff.kreo.net/en/collections/3410550-assemblies-database',
        videoLink: '',
        baseName: MenuName.Items,
        itemName: 'an item',
        onClickAddNew: this.props.openItemPanel,
        onClickAddGroup: this.props.createItemsGroup,
        icon: <IconItems />,
      },
      searchPlaceholder: 'Start typing items name',
      searchText: 'Search for items',
    },
    [MenuName.Assemblies]: {
      onChangeGroupName: this.props.updateAssemblyGroupName,
      onCreateGroup: () => this.props.createAssemblyGroup(),
      onDragEnd: (targetId, sourceId, isGroup) => this.props.updateAssemblyParent(targetId, sourceId, isGroup),
      onDeleted: (id, isGroup) => this.props.deleteAssembly(id, isGroup),
      onDuplicate: (id) => this.props.duplicateAssembly(id),
      databasesHeader: {
        helpCentreLink: 'https://help-takeoff.kreo.net/en/collections/3410550-assemblies-database',
        videoLink: '',
        baseName: MenuName.Assemblies,
        itemName: 'an assembly',
        onClickAddNew: this.props.openAssemblyPanel,
        onClickAddGroup: this.props.createAssemblyGroup,
        icon: <Icons.AssembliesDataBase />,
      },
      searchPlaceholder: 'Start typing assemblies name',
      searchText: 'Search for assemblies',
    },
  };

  public constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: props.location && getCurrentTabAndUpdateUrl(props.location.search, props.history.push),
      columns: Constants.PROPERTIES_COLUMNS,
      gridApi: null,
      assembliesLocationUrl: props.assembliesLocationUrl,
    };
  }

  public static getDerivedStateFromProps(props: Props, state: OwnState): Partial<OwnState> {
    return {
      currentTab: props.location && getCurrentTabAndUpdateUrl(
        props.location.search,
        props.history.push,
        state.assembliesLocationUrl,
      ),
    };
  }

  public componentDidMount(): void {
    const { columns } = this.getDataBaseTableInfo(this.state.currentTab);
    this.setState({ columns, assembliesLocationUrl: this.props.location.search });
  }

  public componentDidUpdate(prevProps: Props, prevState: OwnState): void {
    const isTabChanged = prevState.currentTab !== this.state.currentTab;
    if (this.props.users !== prevProps.users) {
      this.updateUserInfo();
    }
    if (this.state.gridApi && (isTabChanged || this.isDataUpdated(prevProps))) {
      const { rowData, columns } = this.getDataBaseTableInfo(this.state.currentTab);
      this.updateGridData(
        rowData.sort(
          (ra, rb) => {
            if (ra.h_isDragTarget && !rb.h_isDragTarget) {
              return -1;
            }
            if (rb.h_isDragTarget && !ra.h_isDragTarget) {
              return 1;
            }
            return arrayUtils.localCompareWithNumber(`${ra.groupName} ${ra.name}`, `${rb.groupName} ${rb.name}`);
          },
        ),
        columns,
      );
    }
  }

  public componentWillUnmount(): void {
    this.props.setAssembliesCurrentTab(this.props.location.search);
  }

  public render(): React.ReactNode {
    const {
      onChangeGroupName,
      onDragEnd,
      databasesHeader,
    } = this.tabPayload[this.state.currentTab];
    const menuItems = Object.values(MenuName).map(name => ({
      name,
      current: name === this.state.currentTab,
      onClick: () => this.setTab(name),
    }));
    const canDumpDatabase = this.props.ability.can(Operation.Admin, Subject.Application);

    return (
      <Styled.Container>
        <DatabasesHeader
          databasesHeader={databasesHeader}
          dumpDatabase={canDumpDatabase ? this.props.dumpDatabase : null}
          menuTop={this.props.menuTop}
        />
        <Styled.NavigationContainer>
          <Styled.Navigation>
            <FlowNavigation
              items={menuItems}
              TextComponent={Text}
              borderRadius={5}
              borderOverhang={0}
              height={5}
            />
          </Styled.Navigation>
          <DatabaseExamples />
        </Styled.NavigationContainer>
        <Styled.Main>
          <DataBaseTable
            enableRowDrag={true}
            rowData={emptyRowData}
            columns={this.state.columns}
            selectByCheckbox={false}
            suppressRowClickSelection={false}
            onRowSelected={this.onRowSelected}
            onChangeElementName={onChangeGroupName}
            autoGroupName={this.state.currentTab}
            onDragEnd={onDragEnd}
            onGridReady={this.onGridReady}
            extendContextMenuItem={this.extendContextMenuItems}
            autoGroupColumnMinWidth={400}
          />
        </Styled.Main>
        <DeleteBreakDownPropertyConfirmationDialog
          onSubmit={this.props.deleteProperties}
        />
        <DrawingsDeletePIAConfirmationDialog
          onSubmit={this.onDelete}
          showTextItem={this.state.currentTab === MenuName.Items}
        />
      </Styled.Container>
    );
  }

  private setTab(name: MenuName): void {
    const columnState = this.columnApi.getColumnState();
    this.props.setTwoDDataBaseColumnsState(this.state.currentTab, columnState);
    this.props.history.push({ search: getQueryStringByTab(name) });
  }

  @autobind
  private deleteProperties(ids: string[]): void {
    const idsSet = new Set(ids);
    const breakDownProperty = this.props.properties.filter(p =>
      PropertiesTypeGuards.isBreakdown(p) && idsSet.has(p.id));
    if (breakDownProperty.length) {
      this.props.openRemoveBreakDownPropertyDialog(ids);
    } else {
      this.props.deleteProperties(ids);
    }
  }

  @autobind
  private updateUserInfo(): void {
    this.userInfo = this.props.users.map(mapUser);
  }

  private getDataBaseTableInfo(name: MenuName): { rowData: RowData[], columns: Ag.ColDef[] } {
    const { properties, items, assemblies, itemsGroups, assemblyGroups, users } = this.props;

    switch (name) {
      case MenuName.Items: {
        return {
          rowData: mapItems(items, itemsGroups, this.userInfo),
          columns: this.appendColumnConfigsByState(Constants.ITEMS_COLUMNS, name),
        };
      }
      case MenuName.Assemblies: {
        const { rowData, columns } = mapAssemblies(assemblies, assemblyGroups, items, this.userInfo);

        return {
          rowData,
          columns: this.appendColumnConfigsByState([...columns, ...Constants.ASSEMBLIES_COLUMNS], name),
        };
      }
      case MenuName.Properties:
      default:
        return {
          rowData: mapProperties(properties, users),
          columns: this.appendColumnConfigsByState(Constants.PROPERTIES_COLUMNS, name),
        };
    }
  }

  private appendColumnConfigsByState(colDefs: Ag.ColDef[], name: MenuName): Ag.ColDef[] {
    const columnConfigMap: Record<string, Ag.ColDef> = {};
    colDefs.forEach(c => columnConfigMap[`${name}-${c.colId}`] = c);
    const stateColumns = this.props.twoDDataBaseColumnsState && this.props.twoDDataBaseColumnsState[name]
      ? this.props.twoDDataBaseColumnsState[name]
      : [];

    const result: Ag.ColDef[] = stateColumns.map(s => {
      const colDef: Ag.ColDef = {
        ...columnConfigMap[s.colId],
        ...s,
      };
      delete columnConfigMap[s.colId];
      return colDef;
    });
    Object.values(columnConfigMap).forEach(config => {
      result.push({
        ...config,
        colId: `${name}-${config.colId}`,
      });
    });
    return result.filter(c => !!c.headerName);
  }

  private isDataUpdated(prevProps: Props): boolean {
    return prevProps.users !== this.props.users
      || prevProps.properties !== this.props.properties
      || prevProps.items !== this.props.items
      || prevProps.assemblies !== this.props.assemblies
      || prevProps.itemsGroups !== this.props.itemsGroups
      || prevProps.assemblyGroups !== this.props.assemblyGroups
      || Object.keys(this.rowDataMap).length === 0
      || this.state.columns.length === 0;
  }

  private updateGridData(rowData: RowData[], columns: Ag.ColDef[]): void {
    const { transaction, rowDataMap } = getTransaction(this.rowDataMap, rowData);
    const addedRows = transaction.add;
    if (this.isNeedAddIndex(addedRows)) {
      const addIndex = rowData.indexOf(addedRows[0]);
      transaction.addIndex = addIndex;
    }
    this.state.gridApi.clearFocusedCell();
    this.state.gridApi.applyTransaction(transaction);
    this.rowDataMap = rowDataMap;
    this.updateColumns(columns);
  }

  private isNeedAddIndex(addedRows: RowData[]): boolean {
    const isPropertyTab = this.state.currentTab === MenuName.Properties;
    if (isPropertyTab) {
      return addedRows.length === 2;
    } else {
      return addedRows.length === 1;
    }
  }

  @autobind
  private updateColumns(columns: Ag.ColDef[]): void {
    if (this.state.columns.length !== columns.length) {
      this.setState({ columns });
      this.columnApi.autoSizeAllColumns();
    }
  }

  @autobind
  private onGridReady(api: Ag.GridApi, columnApi: Ag.ColumnApi): void {
    const { saveGridApi } = this.props;
    this.columnApi = columnApi;
    this.setState({ gridApi: api });
    if (saveGridApi) {
      saveGridApi(api);
    }
  }

  @autobind
  private onDelete(): void {
    const { id, isGroup } = this.rowParams;
    const { onDeleted } = this.tabPayload[this.state.currentTab];
    onDeleted(id, isGroup);
  }

  @autobind
  private extendContextMenuItems(
    items: Array<Ag.MenuItemDef | string>,
    params: Ag.GetContextMenuItemsParams,
  ): Array<Ag.MenuItemDef | string> {
    if (this.state.currentTab === MenuName.Properties) {
      return this.extendPropertiesTabMenu(items, params);
    } else {
      return this.extendItemsAssembliesTabMenu(items, params);
    }
  }

  private extendItemsAssembliesTabMenu(
    items: Array<Ag.MenuItemDef | string>,
    params: Ag.GetContextMenuItemsParams,
  ): Array<Ag.MenuItemDef | string> {
    const { currentTab } = this.state;
    const { onCreateGroup, onDuplicate } = this.tabPayload[currentTab];
    items.push(
      {
        name: 'Create Group',
        action: onCreateGroup,
      },
    );

    if (params.node?.data?.h_isDragSource) {
      items.push({
        name: 'Delete',
        action: () => this.openRemoveDialog(params),
      });
      if (!params.node.data?.h_isDragTarget) {
        items.push(
          {
            name: 'Duplicate',
            action: () => onDuplicate(params.node.data.id),
          },
        );
      }
    }
    return items;
  }

  private extendPropertiesTabMenu(
    items: Array<Ag.MenuItemDef | string>,
    params: Ag.GetContextMenuItemsParams,
  ): Array<Ag.MenuItemDef | string> {
    if (!params.node) {
      return items;
    }
    const { onDuplicate } = this.tabPayload[this.state.currentTab];

    items.push(
      {
        name: 'Delete',
        action: () => this.openRemoveDialog(params),
      },
    );

    if (!params.node.data.h_isDragTarget) {
      items.push(
        {
          name: 'Duplicate',
          action: () => onDuplicate(params.node.data.id),
        },
      );
    }
    return items;
  }

  @autobind
  private openRemoveDialog(params: Ag.GetContextMenuItemsParams): void {
    this.rowParams = getDataBaseTableNodeData(params);
    this.props.openRemoveDialog();
  }

  @autobind
  private onRowSelected(event: Ag.RowSelectedEvent): void {
    const node = event.api.getSelectedNodes()[0];
    if (!node) {
      return;
    }

    const id = node.data?.id;

    if (this.state.currentTab === MenuName.Properties) {
      const selectedProperty = this.props.properties.find(prop => prop.id === id);
      if (selectedProperty) {
        this.props.setPropertyPanel(selectedProperty.value.type, selectedProperty);
        event.api.deselectAll();
      }
    }

    if (this.state.currentTab === MenuName.Items) {
      const selectedItem = this.props.items.find(prop => prop.id === id);
      if (selectedItem) {
        this.props.setItemPanel(selectedItem);
        event.api.deselectAll();
      }
    }

    if (this.state.currentTab === MenuName.Assemblies) {
      const selectedAssembly = this.props.assemblies.find(prop => prop.id === id);
      if (selectedAssembly) {
        this.props.setAssemblyPanel(selectedAssembly);
        event.api.deselectAll();
      }
    }
  }
}

const mapStateToProps = (state: State): StateProps => {
  const { properties, items, assemblies, itemsGroups, assemblyGroups } = state.twoDDatabase;

  return {
    properties,
    items,
    assemblies,
    companyId: state.account.selectedCompany ? state.account.selectedCompany.id : null,
    users: state.people.companiesUsers as User[],
    assemblyGroups,
    itemsGroups,
    twoDDataBaseColumnsState: state.persistedStorage.twoDDataBaseColumnState,
    assembliesLocationUrl: state.twoDFastNavigation.assembliesLocationUrl,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setPropertyPanel: (type, data) => {
      dispatch(TwoDDatabaseActions.setPropertyPanel({ type, data }));
      dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Properties, withSideBlock: false }));
    },
    setItemPanel: (item) => {
      dispatch(TwoDDatabaseActions.setItemPanel(item));
      dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Items, withSideBlock: true }));
    },
    setAssemblyPanel: (assembly) => {
      dispatch(TwoDDatabaseActions.setAssemblyPanel(assembly));
      dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Assemblies, withSideBlock: true }));
    },
    openRemoveDialog: () => dispatch(KreoDialogActions.openDialog(REMOVE_PIA_CONFIRMATION_DIALOG)),
    openRemoveBreakDownPropertyDialog: (ids: string[]) => dispatch(
      KreoDialogActions.openDialog(
        REMOVE_BREAK_DOWN_PROPERTY_CONFIRMATION_DIALOG,
        { ids })),
    dumpDatabase: () => dispatch(TwoDDatabaseActions.dumpDatabase()),
    deleteProperties: (id) => dispatch(TwoDDatabaseActions.deletePropertiesRequest(id)),
    duplicateProperty: (id) => dispatch(TwoDDatabaseActions.duplicateProperty(id)),
    updateItemGroupName: (id, name) =>
      dispatch(TwoDDatabaseActions.updateItemGroupNameRequest({ id, name })),
    createItemsGroup: () => dispatch(TwoDDatabaseActions.createItemGroupRequest()),
    updateItemsParent: (targetId, sourceId, isSourceGroup) => {
      if (isSourceGroup) {
        dispatch(TwoDDatabaseActions.updateItemGroupParentRequest({ targetId, sourceId }));
      } else {
        dispatch(TwoDDatabaseActions.updateItemParentRequest({ targetId, sourceId }));
      }
    },
    deleteItem: (id, isGroup) => {
      if (isGroup) {
        dispatch(TwoDDatabaseActions.deleteItemGroupRequest([id]));
      } else {
        dispatch(TwoDDatabaseActions.deleteItemRequest([id]));
        dispatch(TwoDDatabaseActions.deleteItemOverride(id));
      }
    },
    duplicateItem: (id) => dispatch(TwoDDatabaseActions.duplicateItem(id)),
    openItemPanel: () => dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Items, withSideBlock: true })),
    updateAssemblyGroupName: (id, name) =>
      dispatch(TwoDDatabaseActions.updateAssemblyGroupNameRequest({ id, name })),
    createAssemblyGroup: () => dispatch(TwoDDatabaseActions.createAssemblyGroupRequest()),
    updateAssemblyParent: (targetId, sourceId, isSourceGroup) => {
      if (isSourceGroup) {
        dispatch(TwoDDatabaseActions.updateAssemblyGroupParentRequest({ targetId, sourceId }));
      } else {
        dispatch(TwoDDatabaseActions.updateAssemblyParentRequest({ targetId, sourceId }));
      }
    },
    deleteAssembly: (id, isGroup) => {
      if (isGroup) {
        dispatch(TwoDDatabaseActions.deleteAssemblyGroupRequest([id]));
      } else {
        dispatch(TwoDDatabaseActions.deleteAssemblyRequest([id]));
      }
    },
    openAssemblyPanel: () =>
      dispatch(TwoDDatabaseActions.openSidePanel({ type: MenuName.Assemblies, withSideBlock: true })),
    duplicateAssembly: (id) => dispatch(TwoDDatabaseActions.duplicateAssembly(id)),
    setTwoDDataBaseColumnsState: (type, state) =>
      dispatch(PersistedStorageActions.setTwoDDataBaseColumnState(type, state)),
    setAssembliesCurrentTab: search => dispatch(TwoDFastNavigationActions.setAssembliesLocationUrl(search)),
  };
};

export const TwoDDatabase = withRouter(connect(mapStateToProps, mapDispatchToProps)(
  withAbilityContext(TwoDDatabaseComponent),
));
