import { Action } from 'redux';
import { ActionWith } from 'common/interfaces/action-with';
import { WizzardErrorTypes } from '../../enums';
import { WizzardStatus } from '../../enums/dropper-state';
import {
  WizzardToolsState,
  ShortPointDescription,
  FinderSelectedVisiblity,
  WizzardToolsFullState,
} from '../../interfaces';
import { PdfRect } from '../../interfaces/api-payloads';
import {
  PdfFinderGeometryResponse,
  PdfGeometry,
  PdfStatistic,
} from '../../interfaces/api-responses/pdf-geometry-response';
import { SetPdfStatisticPayload } from '../payloads/wizzard-tools';
import { WizzardToolsActionTypes } from '../types/wizzard-tools';

function setSettings<T extends keyof WizzardToolsFullState>(
  key: T,
  value: WizzardToolsFullState[T],
): ActionWith<{ key: T, value: WizzardToolsFullState[T] }> {
  return {
    type: WizzardToolsActionTypes.SET_SETTINGS,
    payload: { key, value },
  };
}

function setWizzardState(
  status: WizzardStatus,
  itemsCount?: number,
): ActionWith<{ status: WizzardStatus, foundedItemsCount?: number }> {
  return {
    type: WizzardToolsActionTypes.SET_WIZZARD_STATE,
    payload: { status, foundedItemsCount: itemsCount },
  };
}

function dropDropperState(): Action {
  return {
    type: WizzardToolsActionTypes.DROP_WIZZARD_STATE,
  };
}

function dropFinderSelectionArea(): Action {
  return {
    type: WizzardToolsActionTypes.DROP_FINDER_SELECTION_AREA,
  };
}

function setSelectionArea(area: ShortPointDescription[]): ActionWith<ShortPointDescription[]> {
  return {
    type: WizzardToolsActionTypes.SET_SELECTION_AREA,
    payload: area,
  };
}

function addGeometriesToRemove(geometryId: number[]): ActionWith<number[]> {
  return {
    type: WizzardToolsActionTypes.ADD_GEOMETRY_TO_REMOVE,
    payload: geometryId,
  };
}

function applyEraser(): Action {
  return {
    type: WizzardToolsActionTypes.APPLY_REMOVE_GEOMETRIES,
  };
}

function dropEraser(): Action {
  return {
    type: WizzardToolsActionTypes.DROP_REMOVE_GEOMETRIES,
  };
}

function setSelectedGeometries(payload: PdfGeometry[]): ActionWith<PdfGeometry[]> {
  return {
    type: WizzardToolsActionTypes.SET_SELECTED_GEOMETRY,
    payload,
  };
}

function runFinderSearch(): Action {
  return {
    type: WizzardToolsActionTypes.RUN_FINDER,
  };
}

function setResult(
  result: PdfGeometry[] | PdfFinderGeometryResponse[] | PdfRect[],
): ActionWith<PdfGeometry[] | PdfFinderGeometryResponse[] | PdfRect[]> {
  return {
    type: WizzardToolsActionTypes.SET_RESULT,
    payload: result,
  };
}

function setError(error: WizzardErrorTypes): ActionWith<WizzardErrorTypes> {
  return {
    type: WizzardToolsActionTypes.SET_ERROR,
    payload: error,
  };
}

function setFinderSelectionVisibility(
  key: keyof FinderSelectedVisiblity,
  isVisible: boolean,
): ActionWith<{ key: keyof FinderSelectedVisiblity, isVisible: boolean }> {
  return {
    type: WizzardToolsActionTypes.SET_FINDER_SELECTION_VISIBILITY,
    payload: { key, isVisible },
  };
}

function getPdfStatistic(drawingId: string): ActionWith<string> {
  return {
    type: WizzardToolsActionTypes.GET_PDF_STATISTIC,
    payload: drawingId,
  };
}

function setPdfStatistic(drawingId: string, statistic: PdfStatistic): ActionWith<SetPdfStatisticPayload> {
  return {
    type: WizzardToolsActionTypes.SET_PDF_STATISTIC,
    payload: { drawingId, statistic },
  };
}

function runDropper(point: ShortPointDescription): ActionWith<ShortPointDescription> {
  return {
    type: WizzardToolsActionTypes.RUN_DROPPER,
    payload: point,
  };
}

function changeResultHideStatus(id: number, status: boolean): ActionWith<{ id: number, status: boolean }> {
  return {
    type: WizzardToolsActionTypes.CHANGE_RESULT_HIDE_STATUS,
    payload: { id, status },
  };
}

function batchUpdateSettings(
  settings: Partial<WizzardToolsState>,
): ActionWith<Partial<WizzardToolsState>> {
  return {
    type: WizzardToolsActionTypes.BATCH_UPDATE_SETTINGS,
    payload: settings,
  };
}

export const WizzardToolsActions = {
  setSettings,
  setWizzardState,
  dropDropperState,
  dropFinderSelectionArea,
  setSelectionArea,
  addGeometriesToRemove,
  setSelectedGeometries,
  runFinderSearch,
  setResult,
  applyEraser,
  dropEraser,
  setError,
  setFinderSelectionVisibility,
  getPdfStatistic,
  setPdfStatistic,
  runDropper,
  changeResultHideStatus,
  batchUpdateSettings,
};

