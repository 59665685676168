import { Icons } from '@kreo/kreo-ui-components';
import React from 'react';

import { Subject } from 'common/ability/subject';
import { METRIC_IDS } from 'common/constants/id-maps';
import { HotkeysUtils } from 'common/hotkeys';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsSelectAggregationGroup } from '../../interfaces';
import { DrawingToolHotkey } from '../../utils/hotkey-utils';
import { NameBadge } from '../instruments-menu/name-badge';
import { InstrumentGroupInfo, InstrumentInfo } from '../interfaces';

interface OptionsSettings {
  editPermission: boolean;
  viewPermission: boolean;
  annotationsTryCreatePermission: boolean;
  isAiSuggestEnabled: boolean;
  aiSuggestPermission: boolean;
  canUseWizzard: boolean;
  canUseFinder: boolean;
  canUseOneClickLine: boolean;
  canShowComments: boolean;
}

export const OPTIONS_BASE_DESCRIPTIONS: Record<number, { name: string, icon: React.ComponentType }> = {
  [DrawingsDrawMode.Polyline]: {
    name: 'Polyline',
    icon: Icons.Line,
  },
  [DrawingsDrawMode.Polygon]: {
    name: 'Polygon',
    icon: Icons.Polygon_2d,
  },
  [DrawingsDrawMode.Rectangle2Point]: {
    name: 'Rectangle 2 Point',
    icon: Icons.Rectangle_2,
  },
  [DrawingsDrawMode.Rectangle3Point]: {
    name: 'Rectangle 3 Point',
    icon: Icons.Rectangle_3,
  },
  [DrawingsDrawMode.Count]: {
    name: 'Count',
    icon: Icons.Point,
  },
  [DrawingsDrawMode.Wand]: {
    name: 'Wand',
    icon: Icons.Wand,
  },
  [DrawingsDrawMode.BucketFill]: {
    name: 'One-click Area',
    icon: Icons.Bucket,
  },
  [DrawingsDrawMode.OneClickLine]: {
    name: 'One-click Line',
    icon: Icons.OneClickLine,
  },
  [DrawingsDrawMode.Dropper]: {
    name: 'Dropper',
    icon: Icons.Dropper,
  },
  [DrawingsDrawMode.Finder]: {
    name: 'Auto Count',
    icon: Icons.VisualSearch,
  },
  [DrawingsDrawMode.Legend]: {
    name: 'Drawing Legend',
    icon: Icons.Legend,
  },
  [DrawingsDrawMode.Sticker]: {
    name: 'Sticker',
    icon: Icons.StickyNote,
  },
  [DrawingsDrawMode.Ruler]: {
    name: 'Dimension Line',
    icon: Icons.Ruler2D,
  },
  [DrawingsDrawMode.Image]: {
    icon: Icons.Library,
    name: 'Symbols',
  },
  [DrawingsDrawMode.Comment]: {
    name: 'Comment',
    icon: Icons.Comments2D,
  },
};

export function getOptions(
  {
    editPermission,
    viewPermission,
    annotationsTryCreatePermission,
    isAiSuggestEnabled,
    aiSuggestPermission,
    canUseFinder,
    canUseWizzard,
    canUseOneClickLine,
    canShowComments,
  }: OptionsSettings,
): Array<InstrumentInfo | InstrumentGroupInfo> {
  return [
    {
      name: 'Select',
      uniqueId: METRIC_IDS.selectTool,
      id: DrawingsDrawMode.Disabled,
      icon: Icons.SelectArrow,
      hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.SelectTool),
      hotKeys: DrawingToolHotkey.SelectTool,
      disabled: !viewPermission,
      // eslint-disable-next-line max-len
      description: 'Click on an element to select it. Select multiple elements by pressing the "Ctrl" button and clicking on each of the relevant elements. To marquee select elements, click on the starting point and drag around the items you need to select with the help of a rectangular selection.',
    },
    {
      groupId: 'polygon',
      resultType: DrawingsSelectAggregationGroup.Area,
      subInstruments: [
        {
          uniqueId: METRIC_IDS.polygonTool,
          id: DrawingsDrawMode.Polygon,
          hotKeys: DrawingToolHotkey.PolygonTool,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.PolygonTool),
          disabled: !editPermission,
          enableBadge: isAiSuggestEnabled,
          badgeText: 'AI Suggest enabled',
          // eslint-disable-next-line max-len
          description: 'To draw a polygon, click on the relevant starting point, then select its other points by clicking on them. To finish the polygon, click on the initial point or press the "Enter" button or use the right-click and choose the "Finish" option.',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Polygon],
        },
        {
          uniqueId: METRIC_IDS.rectangle2pointsTool,
          id: DrawingsDrawMode.Rectangle2Point,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.Rectangle2PointTool),
          hotKeys: DrawingToolHotkey.Rectangle2PointTool,
          disabled: !editPermission,
          // eslint-disable-next-line max-len
          description: 'To draw a rectangle using 2 points, click on the starting point to select it and drag to the relevant end point.',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Rectangle2Point],
        },
        {
          uniqueId: METRIC_IDS.rectangle3pointsTool,
          id: DrawingsDrawMode.Rectangle3Point,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.Rectangle3PointTool),
          hotKeys: DrawingToolHotkey.Rectangle3PointTool,
          disabled: !editPermission,
          // eslint-disable-next-line max-len
          description: 'To draw a rectangle using 3 points, click on the starting point to select it, then click on the second point or specify the distance value between two points, select the last point after it.',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Rectangle3Point],
        },
        {
          uniqueId: METRIC_IDS.wandTool,
          id: DrawingsDrawMode.Wand,
          disabled: !editPermission,
          enableBadge: isAiSuggestEnabled && aiSuggestPermission,
          // eslint-disable-next-line max-len
          description: 'Click on any element in the drawing and instantly get a line. Keep clicking to get a polyline or area. Use the "Connect" and "Enclose" options to control the type of measurement you\'d like to get',
          addSeparationBefore: true,
          hidden: !canUseWizzard,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Wand],
        },
        {
          uniqueId: METRIC_IDS.dropperTool,
          id: DrawingsDrawMode.Dropper,
          disabled: !editPermission,
          enableBadge: isAiSuggestEnabled && aiSuggestPermission,
          // eslint-disable-next-line max-len
          description: 'Finds lines or areas of a similar type. Click on the required area/line to get one. After getting the result, click on the "Create" button to create a measurement',
          hidden: !canUseWizzard,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Dropper],
        },
      ],
    },
    {
      groupId: 'polyline',
      resultType: DrawingsSelectAggregationGroup.Line,
      subInstruments: [
        {
          uniqueId: METRIC_IDS.polylineTool,
          id: DrawingsDrawMode.Polyline,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.PolylineTool),
          hotKeys: DrawingToolHotkey.PolylineTool,
          disabled: !editPermission,
          badgeText: 'AI Suggest enabled',
          enableBadge: isAiSuggestEnabled && aiSuggestPermission,
          // eslint-disable-next-line max-len
          description: 'To draw a polyline, click on the relevant starting point, then select the necessary end point by clicking on it. To end the polyline, press the "Enter" button or use the right-click and choose the "Finish" option.',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Polyline],
        },
        {
          uniqueId: METRIC_IDS.wandTool,
          id: DrawingsDrawMode.Wand,
          disabled: !editPermission,
          enableBadge: isAiSuggestEnabled && aiSuggestPermission,
          // eslint-disable-next-line max-len
          description: 'Click on any element in the drawing and instantly get a line. Keep clicking to get a polyline or area. Use the "Connect" and "Enclose" options to control the type of measurement you\'d like to get',
          addSeparationBefore: true,
          hidden: !canUseWizzard,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Wand],
        },
        {
          uniqueId: METRIC_IDS.dropperTool,
          id: DrawingsDrawMode.Dropper,
          disabled: !editPermission,
          enableBadge: isAiSuggestEnabled && aiSuggestPermission,
          // eslint-disable-next-line max-len
          description: 'Finds lines or areas of a similar type. Click on the required area/line to get one. After getting the result, click on the "Create" button to create a measurement',
          hidden: !canUseWizzard,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Dropper],
        },
      ],
    },
    {
      uniqueId: METRIC_IDS.countTool,
      id: DrawingsDrawMode.Count,
      hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.CountTool),
      hotKeys: DrawingToolHotkey.CountTool,
      disabled: !editPermission,
      // eslint-disable-next-line max-len
      description:
        'Click on the relevant elements in the drawing to count them. To finish the Count, press the "Enter" button.',
      ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Count],
    },
    {
      uniqueId: METRIC_IDS.bucketTool,
      id: DrawingsDrawMode.BucketFill,
      disabled: !editPermission,
      addSeparationBefore: true,
      enableBadge: isAiSuggestEnabled && aiSuggestPermission,
      description: 'Click on an empty area or hatching to get an area measurement of the nearest closed loop',
      hidden: !canUseWizzard,
      ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.BucketFill],
      descriptionContent: {
        component: NameBadge,
        props: {
          name: 'One-click Area',
          icon: Icons.AITool,
        },
      },
    },
    {
      uniqueId: METRIC_IDS.aiLine,
      id: DrawingsDrawMode.OneClickLine,
      disabled: !editPermission,
      addSeparationBefore: false,
      enableBadge: isAiSuggestEnabled && aiSuggestPermission,
      description: 'Click on a line or in the centre of a wall to get a linear measurement',
      hidden: !canUseOneClickLine,
      ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.OneClickLine],
      descriptionContent: {
        component: NameBadge,
        props: {
          name: 'One-click Line',
          icon: Icons.AITool,
        },
      },
      dataControlName: 'OneClickLine',
    },
    {
      uniqueId: METRIC_IDS.finderTool,
      id: DrawingsDrawMode.Finder,
      hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.AutoCountTool),
      hotKeys: DrawingToolHotkey.AutoCountTool,
      disabled: !editPermission,
      enableBadge: isAiSuggestEnabled && aiSuggestPermission,
      // eslint-disable-next-line max-len
      description: 'Select an area in the drawing by holding down the left mouse button. Use the "Search" option to search for the selected geometry. After getting the result, click on the "Create" button to create a measurement',
      hidden: !canUseFinder || !canUseWizzard,
      ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Finder],
      descriptionContent: {
        component: NameBadge,
        props: {
          name: 'Auto Count',
          icon: Icons.AITool,
        },
      },
    },
    {
      groupId: 'annotations',
      resultType: null,
      addSeparationBefore: true,
      subInstruments: [
        {
          uniqueId: METRIC_IDS.legendAnnotation,
          id: DrawingsDrawMode.Legend,
          disabled: !annotationsTryCreatePermission,
          // eslint-disable-next-line max-len
          description: 'Visualize the measurement data available on the drawing page and save a key to your drawings when exporting them to PDF utilising this tool.',
          addSeparationBefore: true,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Legend],
        },
        {
          uniqueId: METRIC_IDS.stickerAnnotationAnnotation,
          id: DrawingsDrawMode.Sticker,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.StickersTool),
          hotKeys: DrawingToolHotkey.StickersTool,
          disabled: !annotationsTryCreatePermission,
          // eslint-disable-next-line max-len
          description: 'Click on the required place on the drawing to leave your notes or comments there',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Sticker],
        },
        {
          uniqueId: METRIC_IDS.dimensionLineAnnotation,
          id: DrawingsDrawMode.Ruler,
          hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.RulerTool),
          hotKeys: DrawingToolHotkey.RulerTool,
          disabled: !annotationsTryCreatePermission,
          // eslint-disable-next-line max-len
          description: 'To draw a dimension line, click on the relevant starting point. Then select the necessary end point by clicking on it to end the dimension line',
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Ruler],
        },
        {
          uniqueId: METRIC_IDS.symbolsLibraryAnnotation,
          id: DrawingsDrawMode.Image,
          disabled: !annotationsTryCreatePermission,
          ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Image],
        },
      ],
    },
    {
      uniqueId: METRIC_IDS.commentTool,
      id: DrawingsDrawMode.Comment,
      hotKey: HotkeysUtils.stringifyHotkeysList(DrawingToolHotkey.CommentTool),
      hotKeys: DrawingToolHotkey.CommentTool,
      enableBadge: !canShowComments,
      description: 'Click on the required place on the drawing to leave your notes or comments there',
      ...OPTIONS_BASE_DESCRIPTIONS[DrawingsDrawMode.Comment],
    },
  ];
}

export const OptionsSubjectsMap: Record<string, Subject[]> = {
  [DrawingsDrawMode.Finder]: [Subject.Takeoff2dWizzardAccess, Subject.Takeoff2dWizzardFinderAccess],
  [DrawingsDrawMode.Wand]: [Subject.Takeoff2dWizzardAccess],
  [DrawingsDrawMode.BucketFill]: [Subject.Takeoff2dWizzardAccess],
  [DrawingsDrawMode.Dropper]: [Subject.Takeoff2dWizzardAccess],
  [DrawingsDrawMode.Legend]: [Subject.Takeoff2dAnnotations],
  [DrawingsDrawMode.Sticker]: [Subject.Takeoff2dAnnotations],
  [DrawingsDrawMode.Ruler]: [Subject.Takeoff2dAnnotations],
  [DrawingsDrawMode.Image]: [Subject.Takeoff2dAnnotations],
  [DrawingsDrawMode.Comment]: [Subject.Comment2d],
};


export const VisibleSubjectsMap: Record<string, Subject[]> = {
  [DrawingsDrawMode.Finder]: [Subject.Takeoff2dWizzardShow, Subject.Takeoff2dWizzardFinderShow],
  [DrawingsDrawMode.Wand]: [Subject.Takeoff2dWizzardShow],
  [DrawingsDrawMode.BucketFill]: [Subject.Takeoff2dWizzardShow],
  [DrawingsDrawMode.Dropper]: [Subject.Takeoff2dWizzardShow],
  [DrawingsDrawMode.OneClickLine]: [Subject.Takeoff2dOneClickLineShow],
};
