import { createSlice } from '@reduxjs/toolkit';
import { ConstantFunctions } from 'common/constants/functions';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { MeasurePanelType } from 'unit-2d-database/components/side-panel/components/measure-panel';

import {
  Assembly,
  Group,
  Item,
  TwoDDataBaseState,
  Property,
  PropertyTypeEnum,
  MenuName,
  DatabaseFetchResponsePayload,
  CreatePropertyPayload,
  CreateItemPayload,
  UpdateGroupNamePayload,
  UpdateParentPayload,
  UpdateAssemblyPayload,
  UpdateItemRequest,
  VisibilityFilterType,
  ResetAssemblyOverridePayload,
  ItemOverride,
  SidePanelExtraProps,
} from '../interfaces';
import {
  deleteAssemblyFromItemOverride,
  deleteItemOverride,
  filterAssemblyByRemovedAssemblyGroups,
  filterItemsByRemovedItemGroups,
  getFoldersToRemove,
  removeItemsFromAssemblies,
} from './delete-helpers';
import { setLoadedStatus, setLoadingStatus } from './loaded-status-helpers';

export const TWO_D_DATA_BASE_INITIAL_STATE: TwoDDataBaseState = {
  properties: [],
  items: [],
  assemblies: [],
  itemsGroups: [],
  assemblyGroups: [],
  propertyPanel: {
    type: null,
    data: null,
  },
  itemPanel: {
    data: null,
  },
  assemblyPanel: {
    data: null,
  },
  sidePanel: {
    isOpen: false,
    entityType: null,
    withSelectBlock: false,
    openCloseDialog: false,
    collapsedGroups: new Set(),
    visibilityMode: VisibilityFilterType.ShowAll,
    extraProps: {},
    showSpinner: false,
  },
  requestStatus: RequestStatus.NotRequested,
  isFirstLoaded: false,
  baseItemOverride: {},
};

const TwoDDatabase = createSlice({
  name: '@twoDDataBase',
  initialState: TWO_D_DATA_BASE_INITIAL_STATE,
  reducers: {
    fetchDatabaseRequest: setLoadingStatus,
    setDatabase: (s, action: ActionWith<DatabaseFetchResponsePayload>) => {
      const { assemblies, assemblyFolders, itemFolders, items, properties } = action.payload;
      s.assemblies = assemblies;
      s.assemblyGroups = assemblyFolders;
      s.items = items;
      s.itemsGroups = itemFolders;
      s.properties = properties;
      setLoadedStatus(s);
      s.isFirstLoaded = true;
    },
    createItemGroupRequest: setLoadingStatus,
    createItemGroup: (s, action: ActionWith<Group>) => {
      s.itemsGroups.push(action.payload);
      setLoadedStatus(s);
    },
    createAssemblyGroupRequest: setLoadingStatus,
    createAssemblyGroup: (s, action: ActionWith<Group>) => {
      s.assemblyGroups.push(action.payload);
      setLoadedStatus(s);
    },
    updatePropertyGroupName: (s, action: ActionWith<UpdateGroupNamePayload>) => {
      const { id, name } = action.payload;
      const group = s.properties.find(g => g.id === id);
      if (group) {
        group.name = name;
      }
      setLoadedStatus(s);
    },
    updateItemGroupNameRequest: (s, _action: ActionWith<UpdateGroupNamePayload>) => setLoadingStatus(s),
    updateItemGroup: (s, { payload: group }: ActionWith<Group>) => {
      const groupIndex = s.itemsGroups.findIndex(g => g.id === group.id);
      if (groupIndex > -1) {
        s.itemsGroups[groupIndex] = group;
      }
      setLoadedStatus(s);
    },
    updateAssemblyGroupNameRequest: (s, _action: ActionWith<UpdateGroupNamePayload>) => {
      setLoadingStatus(s);
    },
    updateAssemblyGroup: (s, { payload: group }: ActionWith<Group>) => {
      const groupIndex = s.assemblyGroups.findIndex(g => g.id === group.id);
      if (groupIndex > -1) {
        s.assemblyGroups[groupIndex] = group;
      }
      setLoadedStatus(s);
    },
    updateItemGroupParentRequest: (s, _action: ActionWith<UpdateParentPayload>) => setLoadingStatus(s),
    updateItemParentRequest: (s, _action: ActionWith<UpdateParentPayload>) => setLoadingStatus(s),
    updateAssemblyParentRequest: (
      s,
      _action: ActionWith<UpdateParentPayload>,
    ) => {
      setLoadingStatus(s);
    },
    updateAssemblyGroupParentRequest: (
      s,
      _action: ActionWith<UpdateParentPayload>,
    ) => {
      setLoadingStatus(s);
    },
    deleteAssemblyRequest: (s, _action: ActionWith<string[]>) => setLoadingStatus(s),
    deleteAssembly: (s, { payload }: ActionWith<string[]>) => {
      const ids = new Set(payload);
      s.assemblies = s.assemblies.filter(a => !ids.has(a.id));
      deleteAssemblyFromItemOverride(s.baseItemOverride, ids);
      setLoadedStatus(s);
    },
    deleteAssemblyGroupRequest: (s, _action: ActionWith<string[]>) => setLoadingStatus(s),
    deleteAssemblyGroup: (s, { payload: id }: ActionWith<string[]>) => {
      const { groupsToDelete, filteredGroups } = getFoldersToRemove(s.assemblyGroups, id);
      const { assemblies, removedAssembliesIds } = filterAssemblyByRemovedAssemblyGroups(s.assemblies, groupsToDelete);
      s.assemblies = assemblies;
      s.assemblyGroups =  filteredGroups;
      deleteAssemblyFromItemOverride(s.baseItemOverride, removedAssembliesIds);
      setLoadedStatus(s);
    },
    deleteItemRequest: (s, _action: ActionWith<string[]>) => setLoadingStatus(s),
    deleteItem: (s, action: ActionWith<string[]>) => {
      const ids = new Set(action.payload);
      s.items = s.items.filter(i => !ids.has(i.id));
      removeItemsFromAssemblies(ids, s.assemblies);
      setLoadedStatus(s);
    },
    deleteItemGroupRequest: (s, _action: ActionWith<string[]>) => setLoadingStatus(s),
    deleteItemGroup: (s, { payload: id }: ActionWith<string[]>) => {
      const { groupsToDelete, filteredGroups } = getFoldersToRemove(s.itemsGroups, id);
      const { items, removedItemsIds } = filterItemsByRemovedItemGroups(s.items, groupsToDelete);
      s.items = items;
      s.itemsGroups = filteredGroups;
      removeItemsFromAssemblies(removedItemsIds, s.assemblies);
      removedItemsIds.forEach((i) => deleteItemOverride(s.baseItemOverride, i));
      setLoadedStatus(s);
    },
    deletePropertiesRequest: (s, _action: ActionWith<string[]>) => setLoadingStatus(s),
    deleteProperties: (
      s,
      action: ActionWith<string[]>,
    ) => {
      const propertiesToDelete = new Set(action.payload);
      s.properties = s.properties.filter(p => !propertiesToDelete.has(p.id));
      setLoadedStatus(s);
    },
    createPropertyRequest: (s, _action: ActionWith<CreatePropertyPayload>) => setLoadingStatus(s),
    createProperty: (s, { payload: property }: ActionWith<Property>) => {
      s.properties.push(property);
      setLoadedStatus(s);
    },
    createItemRequest: (s, _action: ActionWith<CreateItemPayload>) => setLoadingStatus(s),
    createItem: (s, { payload: item }: ActionWith<Item>) => {
      s.items.push(item);
      setLoadedStatus(s);
    },
    createAssemblyRequest: (s, _action: ActionWith<{ items: Item[], name: string }>) => setLoadingStatus(s),
    createAssembly: (
      s,
      action: ActionWith<Assembly>,
    ) => {
      s.assemblies.push(action.payload);
      action.payload.items.forEach(i => {
        const override: ItemOverride = {
          assembly: action.payload,
          item: i,
        };
        if (i.properties.length) {
          if (s.baseItemOverride[i.baseItemId]) {
            s.baseItemOverride[i.baseItemId].push(override);
          } else {
            s.baseItemOverride[i.baseItemId] = [override];
          }
        }
      });
      setLoadedStatus(s);
    },
    updatePropertyRequest: (s, _action: ActionWith<Property>) => setLoadingStatus(s),
    updateProperty: (s, { payload: property }: ActionWith<Property>) => {
      const index = s.properties.findIndex(p => p.id === property.id);
      if (index > -1) {
        s.properties[index] = property;
      }
      setLoadedStatus(s);
    },
    duplicateProperty: (s, _action: ActionWith<string>) => setLoadingStatus(s),
    duplicateItem: (s, _action: ActionWith<string>) => setLoadingStatus(s),
    duplicateAssembly: (s, _action: ActionWith<string>) => setLoadingStatus(s),
    setAssemblyPanel: (s, action: ActionWith<Assembly>) => {
      s.assemblyPanel.data = action.payload;
    },
    updateProperties: (s, action: ActionWith<Property>) => {
      const property = action.payload;
      const propertyToUpdate = s.properties.find(p => p.id === property.id);
      if (propertyToUpdate) {
        for (const [key, value] of Object.entries(property)) {
          propertyToUpdate[key] = value;
        }
      }
    },
    updateItemRequest: (
      s,
      _action: ActionWith<UpdateItemRequest>,
    ) => setLoadingStatus(s),
    updateItem: (s, { payload: item }: ActionWith<Item>) => {
      const itemIndex = s.items.findIndex(i => item.id === i.id);
      if (itemIndex > -1) {
        s.items[itemIndex] = item;
      }
      setLoadedStatus(s);
    },
    updateAssemblyRequest: (s, _action: ActionWith<UpdateAssemblyPayload>) => setLoadingStatus(s),
    updateAssemblies: (s, { payload: assemblies }: ActionWith<Assembly[]>) => {
      for (const assembly of assemblies) {
        const index = s.assemblies.findIndex(i => i.id === assembly.id);
        if (index > -1) {
          s.assemblies[index] = assembly;
        }
        assembly.items.forEach(i => {
          if (i.properties.length) {
            const override: ItemOverride = {
              assembly,
              item: i,
            };
            if (s.baseItemOverride[i.baseItemId]) {
              s.baseItemOverride[i.baseItemId].push(override);
            } else {
              s.baseItemOverride[i.baseItemId] = [override];
            }
          }
        });
      }
      setLoadedStatus(s);
    },
    closeAssemblyPanel: (s) => {
      s.assemblyPanel.data = null;
    },
    closeSidePanel: (s) => {
      s.sidePanel.isOpen = false;
      s.sidePanel.entityType = null;
      s.sidePanel.withSelectBlock = false;
      s.sidePanel.openCloseDialog = false;
      s.sidePanel.visibilityMode = VisibilityFilterType.ShowAll;
      s.sidePanel.collapsedGroups = new Set();
      s.sidePanel.showSpinner = false;
    },
    showSpinner: (s) => {
      s.sidePanel.showSpinner = true;
    },
    closePropertyPanel: (s) => {
      s.propertyPanel.data = null;
      s.propertyPanel.type = null;
    },
    closeItemPanel: (s) => {
      s.itemPanel.data = null;
    },
    openSidePanel: (
      s,
      action: ActionWith<{
        type: MenuName | MeasurePanelType,
        withSideBlock: boolean,
        extraProps?: SidePanelExtraProps,
     }>,
    ) => {
      const { type, withSideBlock, extraProps } = action.payload;
      s.sidePanel.isOpen = true;
      s.sidePanel.entityType = type;
      s.sidePanel.openCloseDialog = false;
      s.sidePanel.withSelectBlock = withSideBlock;
      s.sidePanel.extraProps = extraProps;
    },
    setPropertyPanel: (s, action: ActionWith<{ type: PropertyTypeEnum, data: Property | null }>) => {
      const { data, type } = action.payload;
      s.propertyPanel.data = data;
      s.propertyPanel.type = type;
    },
    setItemPanel: (s, action: ActionWith<Item>) => {
      s.itemPanel.data = action.payload;
    },
    setOpenCloseDialog: (s) => {
      s.sidePanel.openCloseDialog = true;
    },
    setCollapsedGroups: (s, action: ActionWith<Set<string>>) => {
      s.sidePanel.collapsedGroups = action.payload;
    },
    setVisibilityMode: (s, action: ActionWith<VisibilityFilterType>) => {
      s.sidePanel.visibilityMode = action.payload;
    },
    dumpDatabase: setLoadingStatus,
    setLoadedStatus,
    sendUpdateItem: (s, _a: ActionWith<UpdateItemRequest>) => {
      return s;
    },
    resetAssemblyOverride: (s, action: ActionWith<ResetAssemblyOverridePayload[]>) => {
      const payloads = action.payload;
      payloads.forEach(({ assemblyId, itemId, propertyIds }) => {
        const assembly = s.assemblies.find(a => a.id === assemblyId);
        if (!assembly) {
          return;
        }
        const item = assembly.items.find(i => i.id === itemId);
        if (!item) {
          return;
        }
        item.properties = item.properties.filter(p => !propertyIds.includes(p.id));
      });

      return s;
    },
    sendResetAssemblyOverride: (_, _a: ActionWith<ResetAssemblyOverridePayload[]>) => {
      ConstantFunctions.doNothing();
    },
    updateItemOverride: (s) => {
      const map: Record<string, ItemOverride[]> = {};
      s.assemblies.forEach(a => {
        a.items.forEach(i => {
          if (i.properties.length) {
            const override: ItemOverride = {
              assembly: a,
              item: i,
            };
            if (map[i.baseItemId]) {
              map[i.baseItemId].push(override);
            } else {
              map[i.baseItemId] = [override];
            }
          }
        });
      });
      s.baseItemOverride = map;
      return s;
    },
    updateItemOverrideByBaseItem: (s, { payload: baseItemId }: ActionWith<string>) => {
      s.baseItemOverride[baseItemId] = [];
      s.assemblies.forEach(a => {
        a.items.forEach(i => {
          const override: ItemOverride = {
            assembly: a,
            item: i,
          };
          if (i.baseItemId === baseItemId && i.properties.length) {
            s.baseItemOverride[baseItemId].push(override);
          }
        });
      });
    },
    deleteItemOverride: (s, { payload: baseItemId }: ActionWith<string>) => {
      deleteItemOverride(s.baseItemOverride, baseItemId);
    },
  },
});

export const TwoDDatabaseActions = TwoDDatabase.actions;
export const twoDDatabaseReducer = TwoDDatabase.reducer;
