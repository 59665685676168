import React from 'react';

import {
  UndoRedoContextApiProps,
  useUndoRedoFullApi,
} from 'common/undo-redo';
import { AnalyticsProps, useAnalytics } from 'utils/posthog';
import {
  DrawingsElementAndGroupOperationsContextProps,
  DrawingsRendererApiContextProps,
  useElementAndGroupsOperationContext,
  useRendererApiContext,
} from '../drawings-contexts';
import { useSetDrawMode } from '../drawings-controls/hooks';
import { DrawingsDrawMode } from '../enums';
import { useNewInstancesSettingsSetter } from '../hooks';
import { SaveDrawModeOptions } from '../interfaces';

export interface LegendContextWrapperProps extends
  UndoRedoContextApiProps,
  DrawingsRendererApiContextProps,
  DrawingsElementAndGroupOperationsContextProps,
  AnalyticsProps {
  setNewInstanceColor: (color: string) => void;
  setDrawMode: (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void;
}

export function connectToLegendContexts<P>(
  Component: React.ComponentType<P & LegendContextWrapperProps>,
): React.ComponentType<Pick<P, Exclude<keyof P, keyof LegendContextWrapperProps>>> {
  return (props: P) => {
    const setDrawMode = useSetDrawMode();
    const ability = useAnalytics();
    const undoRedo = useUndoRedoFullApi();
    const rendererApi = useRendererApiContext();
    const elementAndGroupOperations = useElementAndGroupsOperationContext();
    const setNewInstanceColor = useNewInstancesSettingsSetter('color');
    return (
      <Component
        {...props}
        {...ability}
        {...undoRedo}
        {...rendererApi}
        {...elementAndGroupOperations}
        setDrawMode={setDrawMode}
        setNewInstanceColor={setNewInstanceColor}
      />
    );
  };
}
