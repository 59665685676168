import { isMergeHorizontalKey } from '2d/units/excel-table-cell-formatter/common';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { changeMergeColumnValue } from '../change-merge-column-value';
import { getShiftColumnAfterDelete } from '../get-shift-column-after-delete';
import { MoveCellRefMap, MoveCellTempData, MoveDataPayload, UpdatedCellsMap } from '../interfaces';
import { shiftColumns } from '../shift-columns/shift-columns';

export const deleteColumns = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const { rows } = sheetsData[sheetId];
  const updatedCellsMap: UpdatedCellsMap = {};
  const moveCellRefMap: MoveCellRefMap = {};

  const shiftCellsInRow = shiftColumns(offset, updatedCellsMap, moveCellRefMap);

  rows.forEach((row) => {
    const columnKeys = new Set<string>();
    const mergeColumnId: Record<string, number> = {};
    const rowId = row.id.toString();
    Object.keys(row).forEach((key, _index, rowKeys) => {
      if (isMergeHorizontalKey(key)) {
        mergeColumnId[key] = Number(row[key]);
      }
      getShiftColumnAfterDelete(key, startIndex, offset, columnKeys, rowKeys);
    });
    shiftCellsInRow(columnKeys, row);
    if (Object.keys(mergeColumnId)) {
      const updates = changeMergeColumnValue(mergeColumnId, -offset, startIndex);
      for (const update of updates) {
        const cellId = ExcelFormulaHelper.getCellLink(null, update.columnId, rowId);
        updatedCellsMap[cellId] = {
          rowId,
          columnId: update.columnId,
          value: update.value,
          prevValue: update.prevValue,
        };
      }
    }
  });

  return { updatedCellsMap, moveCellRefMap };
};
