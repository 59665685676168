import autobind from 'autobind-decorator';
import { DrawingsCanvasColors } from 'common/components/drawings/constants';
import { ContextObserver, ContextObserverWithPrevious } from 'common/components/drawings/drawings-contexts';
import { WizzardStatus } from 'common/components/drawings/enums/dropper-state';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import {
  DrawingsRenderParams,
  FinderSelectedVisiblity,
  ShortPointDescription,
  WizzardToolsState,
} from 'common/components/drawings/interfaces';
import {
  PdfGeometry,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { MeasuresViewSettings } from 'common/components/drawings/interfaces/drawing-text-render-parameters';
import { ConstantFunctions } from 'common/constants/functions';
import { DestroyableObject, EngineObjectConfig } from '../../common';
import { DrawingsGeometryUtilityPolygon } from '../../drawings-geometry-entities';
import {
  BatchedUpdateCallback,
  GetCurrentDrawingCallback,
  NewDrawingSettings,
  SetCursorHintCallback,
} from '../../interfaces';
import { DrawingsViewHelper } from '../drawings-view-helper';
import { DrawingsGeometrySnappingHelper } from '../snapping';
import { DrawingsCursorTypeHelper } from '../visual-helpers';
import { FinderPreview } from './finder-preview';
import { FinderSelectedGeometries } from './finder-selected-geometries';

interface Config extends EngineObjectConfig {
  snappingHelper: DrawingsGeometrySnappingHelper;
  layer: paper.Layer;
  viewHelper: DrawingsViewHelper;
  renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  colorsCacheHelper: DrawingsColorCacheHelper;
  setCursorMessage: SetCursorHintCallback;
  getDrawingInfo: GetCurrentDrawingCallback;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  clearResult: () => void;
  setDropperState: (state: WizzardStatus, itemsCount?: number) => void;
  setArea: (area: ShortPointDescription[]) => void;
  addGeometryToRemove: (geometryId: number) => void;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  viewSettingsObserver: ContextObserver<MeasuresViewSettings>;
}

export class Finder extends DestroyableObject<Config> {
  private _selectionArea: DrawingsGeometryUtilityPolygon;
  private _searchArea: DrawingsGeometryUtilityPolygon;
  private _selectedGeometries: FinderSelectedGeometries;
  private _preview: FinderPreview;

  constructor(config: Config) {
    super(config);
    this._config.wizzardSettingsObserver.subscribe(this.onWizzardSettingsChanged);
    this._config.viewHelper.onLeftMouseClick = ConstantFunctions.doNothing;
    this._config.viewHelper.onMouseDown = ConstantFunctions.doNothing;
  }

  public set enableEraser(enable: boolean) {
    this.enableEraser = enable;
  }

  public destroy(): void {
    this._config.wizzardSettingsObserver.unsubscribe(this.onWizzardSettingsChanged);
    if (this._selectionArea) {
      this._selectionArea.destroy();
      this._selectionArea = null;
    }
    if (this._selectedGeometries) {
      this._selectedGeometries.destroy();
      this._selectedGeometries = null;
    }
    if (this._searchArea) {
      this._searchArea.destroy();
      this._searchArea = null;
    }
    if (this._preview) {
      this._preview.destroy();
      this._preview = null;
    }
  }

  public async apply(): Promise<void> {
    const drawingId = this._config.getDrawingInfo().drawingId;
    const saveType = this._config.wizzardSettingsObserver.getContext().finderSaveType;
    const { instances, newPoints } = this._preview.getInstances(drawingId, saveType);
    this._config.onBatchUpdateGeometries({
      addedInstances: instances,
      newPoints,
      pageId: drawingId,
      moveToSelectedGroup: true,
    });
    this._config.clearResult();
  }

  private onSelectedGeometriesUpdated(geometries: PdfGeometry[], geometriesToRemove: number[]): void {
    if (!geometries) {
      this._selectedGeometries?.destroy();
      this._selectedGeometries = null;
    } else if (this._selectedGeometries?.geometry !== geometries) {
      if (this._selectedGeometries) {
        this._selectedGeometries.destroy();
      }
      this._selectedGeometries = new FinderSelectedGeometries({
        layer: this._config.layer,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        geometry: geometries,
        viewHelper: this._config.viewHelper,
        addGeometryToRemove: this._config.addGeometryToRemove,
        geometriesToRemove,
        visibilityState: this._config.wizzardSettingsObserver.getContext().finderSelectedVisibility,
      });
    }
  }

  private onSelectionAreaUpdated(area: ShortPointDescription[]): void {
    if (!area) {
      this._selectionArea?.destroy();
      this._selectionArea = null;
    } else if (this._selectionArea?.geometry !== area) {
      if (this._selectionArea) {
        this._selectionArea.destroy();
      }
      this._selectionArea = new DrawingsGeometryUtilityPolygon({
        layer: this._config.layer,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
        geometry: area,
        color: DrawingsCanvasColors.autocompleteColorInfo,
      });
    }
  }

  @autobind
  private onWizzardSettingsChanged({
    finderSelectionArea,
    wizzardState,
    finderSelectedGeometries,
    result,
    selectedToRemove,
    searchArea,
    finderSelectedVisibility,
    finderSimilarity: similarity,
    finderResultToHide,
  }: WizzardToolsState): void {
    this.onSelectionAreaUpdated(finderSelectionArea);
    this.onSelectedGeometriesUpdated(finderSelectedGeometries, selectedToRemove);
    this.onUpdateSearchArea(searchArea);
    this.onSelectedVisibilityUpdated(finderSelectedVisibility);
    if (this._preview?.geometry !== result) {
      this._preview?.destroy();
      this.renderPreview(result, similarity, finderResultToHide);
    } else if (this._preview) {
      this._preview.updateFilters(similarity, finderResultToHide);
    }
    if (this._selectedGeometries) {
      const erasing = wizzardState.status === WizzardStatus.Eraser;
      this._selectedGeometries.erasing = erasing;
      this._config.cursorTypeHelper.eraser = erasing;
    }
  }

  private onSelectedVisibilityUpdated(visibilityState: FinderSelectedVisiblity): void {
    if (this._selectedGeometries) {
      this._selectedGeometries.visibilityState = visibilityState;
    }
  }

  @autobind
  private onUpdateSearchArea(searchArea: ShortPointDescription[]): void {
    if (!searchArea) {
      this._searchArea?.destroy();
      this._searchArea = null;
      return;
    } else if (this._searchArea?.geometry !== searchArea) {
      this._searchArea?.destroy();
      this._searchArea = new DrawingsGeometryUtilityPolygon({
        layer: this._config.layer,
        geometry: searchArea,
        renderParamsContextObserver: this._config.renderParametersContextObserver,
      });
    }
  }

  private renderPreview(
    geometries: PdfGeometry[],
    similarity: number,
    geometriesToHide: Record<number, boolean>,
  ): void {
    if (!geometries) {
      return;
    }
    this._preview = new FinderPreview({
      geometry: geometries,
      colorCache: this._config.colorsCacheHelper,
      newDrawingsStylesObserver: this._config.newDrawingStylesObserver,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      layer: this._config.layer,
      isCount: this._config.wizzardSettingsObserver.getContext().isCount,
      similarity,
      geometriesToHide,
      viewSettingsObserver: this._config.viewSettingsObserver,
    });
  }
}
