import { Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { AddProjectUserForm } from 'common/components/add-project-user-form';
import { ProjectType } from 'common/constants/project-type';
import { RoleGroup } from 'common/enums/role-group';
import { State } from 'common/interfaces/state';
import { arrayUtils } from 'common/utils/array-utils';
import { SidePanelContainer } from 'unit-2d-database/components/side-panel/components';
import { pushSearch } from '../../../actions/common';
import { Company } from '../../../units/account/interfaces/company';
import { PeopleActions } from '../../../units/people/actions/actions';
import { Person } from '../../../units/people/interfaces/person';
import { Styled } from './styled';


interface StateProps {
  people: Person[];
  companyId: number;
  currentUserEmail: string;
  company: Company;
  sidePanelIsOpen: boolean;
  isAddGuest: boolean;
}

interface DispatchProps {
  setShowInviteUsersPanel: (isOpen: boolean) => void;
  openAddUserDialog: () => void;
  openAddGuestDialog: () => void;
  setIsAddGuest: (value: boolean) => void;
}

interface Props extends StateProps, DispatchProps {
  onFormSubmit: (forms: Person[], isSharedWithCompany: boolean) => void;
  onCloseDialog?: () => void;
  invitedUsers?: string[];
  projectType: string;
  showCurrentUser?: boolean;
  isSharedWithCompany: boolean;
  onChangeSharedWithCompany?: () => void;
}

interface OwnState {
  isSharedWithCompany: boolean;
}


class InviteUsersToProjectPanelComponent extends React.PureComponent<Props, OwnState> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      isSharedWithCompany: props.isSharedWithCompany,
    };
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.isSharedWithCompany !== this.props.isSharedWithCompany) {
      this.setState({ isSharedWithCompany: this.props.isSharedWithCompany });
    }
  }

  public render(): JSX.Element {
    if (!this.props.people || !this.props.people.length) {
      return null;
    }
    const filterPeople = this.getFilter();
    const userEmails = arrayUtils.filterMap(this.props.people, filterPeople, person => person.email);
    const guestEmails = arrayUtils.filterMap(
      this.props.people,
      person => person.roleGroup === RoleGroup.Guest,
      person => person.email,
    );
    const company = this.props.company;

    return (
      <Styled.InviteUsersContainer>
        <SidePanelContainer
          isOpen={this.props.sidePanelIsOpen}
          onClose={this.onCloseDialog}
          isClickOutsideActive={true}
          withBlur={false}
        >
          <Styled.AddProjectUserPopupWrapper>
            <AddProjectUserForm
              onFormSubmit={this.onFormSubmit}
              title={'Share'}
              buttonTitle={'Share'}
              ButtonIcon={Icons.AddUser}
              userEmails={this.state.isSharedWithCompany ? guestEmails : userEmails}
              addedEmails={this.props.invitedUsers}
              currentUserEmail={this.props.currentUserEmail}
              onClose={this.onCloseDialog}
              isShareWithCompany={this.state.isSharedWithCompany}
              company={company as any}
              onChangeShareWithCompany={this.changeIsSharedWithCompany}
              openAddPeopleDialog={this.openAddPeopleDialog}
            />
          </Styled.AddProjectUserPopupWrapper>
        </SidePanelContainer>
      </Styled.InviteUsersContainer>
    );
  }

  @autobind
  private changeIsSharedWithCompany(): void {
    this.setState(s => ({ isSharedWithCompany: !s.isSharedWithCompany }));
    if (this.props.onChangeSharedWithCompany) {
      this.props.onChangeSharedWithCompany();
    }
  }

  private getFilter(): (person: Person) => boolean {
    if (this.props.projectType === ProjectType.Project2dTemplate) {
      if (this.props.showCurrentUser) {
        return person => person.roleGroup !== RoleGroup.Guest;
      } else {
        return person => person.roleGroup !== RoleGroup.Guest && person.email !== this.props.currentUserEmail;
      }
    } else if (this.props.showCurrentUser) {
      return () => true;
    } else {
      return person => person.email !== this.props.currentUserEmail;
    }
  }

  @autobind
  private onFormSubmit(emails: string[]): void {
    const emailsMap = new Set(emails);
    this.props.onFormSubmit(this.props.people.filter(x => emailsMap.has(x.email)), this.state.isSharedWithCompany);

    if (this.props.onCloseDialog) {
      this.props.onCloseDialog();
    }
    this.props.setShowInviteUsersPanel(false);
  }

  @autobind
  private onCloseDialog(): void {
    this.props.setShowInviteUsersPanel(false);
    if (this.props.onCloseDialog) {
      this.props.onCloseDialog();
    }
  }

  @autobind
  private openAddPeopleDialog(): void {
    this.props.setIsAddGuest(this.state.isSharedWithCompany);
    if (!this.state.isSharedWithCompany) {
      this.props.openAddUserDialog();
    } else {
      this.props.openAddGuestDialog();
    }
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    people: state.people.companiesUsers || [],
    companyId: state.account.selectedCompany.id,
    currentUserEmail: state.account.email,
    company: state.account.selectedCompany,
    sidePanelIsOpen: state.people.inviteUsersSidePanelIsOpen,
    isAddGuest: state.people.isAddGuest,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    setShowInviteUsersPanel: (isOpen) => dispatch(PeopleActions.setShowInviteUsersPanel(isOpen)),
    openAddUserDialog: () => dispatch(pushSearch({ addCompanyUser: 'true' })),
    openAddGuestDialog: () => dispatch(pushSearch({ addCompanyGuest: 'true' })),
    setIsAddGuest: (value) => dispatch(PeopleActions.setIsAddGuest(value)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const InviteUsersToProjectPanel = connector(InviteUsersToProjectPanelComponent);
