import produce from 'immer';
import { DrawingsDrawMode } from 'common/components/drawings/enums/drawings-draw-mode';

import { DrawingsInstanceType } from '../enums/drawings-instance-type';
import {
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
  DrawingsSelectAggregationGroup,
  FinderSaveType,
  WizzardToolsState,
} from '../interfaces';
import { DrawingsInstanceMeasure } from '../interfaces/drawings-instance-measure';
import {
  DrawingsGroupMeasure,
  DrawingsMeasureCount,
  DrawingsMeasureLength,
  DrawingsMeasurePolygon,
  DrawingsMeasurePolyline,
  DrawingsMeasureRectangle,
  DrawingsMeasureType,
} from '../interfaces/drawings-measures';
import { DrawingsCanvasUtils } from './drawings-canvas-utils';

function isRectangleMeasure(
  type: DrawingsInstanceType,
  _measure: DrawingsMeasureType,
): _measure is DrawingsMeasureRectangle {
  return type === DrawingsInstanceType.Rectangle;
}

function isCountMeasure(
  type: DrawingsInstanceType,
  _measure: DrawingsMeasureType,
): _measure is DrawingsMeasureCount {
  return type === DrawingsInstanceType.Count;
}

function isPolygonMeasure(
  type: DrawingsInstanceType,
  _measure: DrawingsMeasureType,
): _measure is DrawingsMeasurePolygon {
  return type === DrawingsInstanceType.Polygon;
}

function isLengthMeasure(
  type: DrawingsInstanceType,
  _measure: DrawingsMeasureType,
): _measure is DrawingsMeasurePolyline {
  return type === DrawingsInstanceType.Polyline;
}

function isSegmentLength(
  type: DrawingsInstanceType,
  _measure: DrawingsMeasureType,
): _measure is DrawingsMeasureLength {
  return type === DrawingsInstanceType.Segment;
}

const SAVE_TYPE_TO_INSTANCE: Record<FinderSaveType, DrawingsInstanceType> = {
  [FinderSaveType.Area]: DrawingsInstanceType.Rectangle,
  [FinderSaveType.CenterLine]: DrawingsInstanceType.Polyline,
  [FinderSaveType.Count]: DrawingsInstanceType.Count,
  [FinderSaveType.MaxLengthSegments]: DrawingsInstanceType.Polyline,
  [FinderSaveType.MinLengthSegments]: DrawingsInstanceType.Polyline,
};

function getInstanceTypeFromDrawMode(
  {
    drawMode,
    wizzard: { enclose, connect, dropperInstanceType, finderSaveType },
  }: {
    drawMode: DrawingsDrawMode,
    wizzard: WizzardToolsState,
  },
): string {
  switch (drawMode) {
    case DrawingsDrawMode.Finder:
      return SAVE_TYPE_TO_INSTANCE[finderSaveType];
    case DrawingsDrawMode.BucketFill:
    case DrawingsDrawMode.MagicSearch:
    case DrawingsDrawMode.AutoMeasure2:
    case DrawingsDrawMode.Polygon: {
      return DrawingsInstanceType.Polygon;
    }
    case DrawingsDrawMode.Wand:
      return enclose && connect ? DrawingsInstanceType.Polygon : DrawingsInstanceType.Polyline;
    case DrawingsDrawMode.OneClickLine:
    case DrawingsDrawMode.Polyline: {
      return DrawingsInstanceType.Polyline;
    }
    case DrawingsDrawMode.Dropper: {
      return dropperInstanceType === DrawingsSelectAggregationGroup.Area
        ? DrawingsInstanceType.Polygon
        : DrawingsInstanceType.Polyline;
    }
    case DrawingsDrawMode.Calibrate: {
      return DrawingsInstanceType.CalibrationLine;
    }
    case DrawingsDrawMode.Count: {
      return DrawingsInstanceType.Count;
    }
    case DrawingsDrawMode.Rectangle3Point:
    case DrawingsDrawMode.Rectangle2Point: {
      return DrawingsInstanceType.Rectangle;
    }
    default: return;
  }
}


function getPolygonIgnoreMeasures(polygon: DrawingsPolygonGeometry): Array<keyof DrawingsMeasurePolygon> {
  return polygon.height === undefined || polygon.height === null ? ['verticalArea', 'volume'] : [];
}

function getPolylineIngoreMeasures(polyline: DrawingsPolylineGeometry): Array<keyof DrawingsMeasurePolyline> {
  const ignoreMeasures: Array<keyof DrawingsMeasurePolyline> = [];

  if (polyline.thickness === undefined || polyline.thickness === null) {
    ignoreMeasures.push('area');
    ignoreMeasures.push('volume');
  }

  if (polyline.height === undefined || polyline.height === null) {
    ignoreMeasures.push('verticalArea');
    ignoreMeasures.push('volume');
  }
  return ignoreMeasures;
}

function updateMeasuresByConstantParams(
  measureInfo: DrawingsInstanceMeasure,
): void {
  const measures = measureInfo.measures;
  if (isPolygonMeasure(measureInfo.type, measures) || isRectangleMeasure(measureInfo.type, measures)) {
    if (measures.height !== undefined && measures.height !== null) {
      measures.volume = measures.area * measures.height;
      measures.verticalArea = measures.perimeter * measures.height;
    } else {
      measures.volume = 0;
      measures.verticalArea = 0;
    }
  } else if (isLengthMeasure(measureInfo.type, measures)) {
    if (measures.thickness !== undefined && measures.thickness !== null) {
      measures.area = measures.length * measures.thickness;
      if (measures.height !== undefined && measures.height !== null) {
        measures.volume = measures.area * measures.height;
      }
    } else {
      measures.volume = 0;
      measures.area = 0;
    }
    if (measures.height !== undefined && measures.height !== null) {
      measures.verticalArea = measures.length * measures.height;
    } else {
      measures.verticalArea = 0;
    }
  }
}

function updateMeasureWithScale(
  measureInfo: DrawingsInstanceMeasure,
  metersPerPx: number,
  scale: number,
): DrawingsInstanceMeasure {
  if (isPolygonMeasure(measureInfo.type, measureInfo.measures)) {
    const measures = {
      ...measureInfo.measures,
      area: DrawingsCanvasUtils.squarePxToMetres(measureInfo.measures.pxArea, scale, metersPerPx),
      perimeter: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxPerimeter, scale, metersPerPx),
    };
    if (measures.height) {
      measures.volume = measures.area * measures.height;
      measures.verticalArea = measures.perimeter * measures.height;
    }
    return {
      ...measureInfo,
      measures,
    };
  } else if (isRectangleMeasure(measureInfo.type, measureInfo.measures)) {
    const measures = {
      ...measureInfo.measures,
      area: DrawingsCanvasUtils.squarePxToMetres(measureInfo.measures.pxArea, scale, metersPerPx),
      perimeter: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxPerimeter, scale, metersPerPx),
      rectangleMax: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxRectangleMin, scale, metersPerPx),
      rectangleMin: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxRectangleMax, scale, metersPerPx),
    };

    if (measures.height) {
      measures.volume = measures.area * measures.height;
      measures.verticalArea = measures.perimeter * measures.height;
    }
    return {
      ...measureInfo,
      measures,
    };
  } else if (isLengthMeasure(measureInfo.type, measureInfo.measures)) {
    const measures = {
      ...measureInfo.measures,
      length: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxLength, scale, metersPerPx),
    };
    if (measures.thickness) {
      measures.area = measures.length * measures.thickness;
      if (measures.height) {
        measures.volume = measures.area * measures.height;
      }
    }
    if (measures.height) {
      measures.verticalArea = measures.length * measures.height;
    }
    return {
      ...measureInfo,
      measures,
    };
  } else if (isSegmentLength(measureInfo.type, measureInfo.measures)) {
    return {
      ...measureInfo,
      measures: {
        ...measureInfo.measures,
        length: DrawingsCanvasUtils.pxToMetres(measureInfo.measures.pxLength, scale, metersPerPx),
      },
    };
  }
  return measureInfo;
}

function updateGroupMeasuresWithScale(
  measureInfo: DrawingsGroupMeasure,
  metersPerPx: number,
  scale: number,
): DrawingsGroupMeasure {
  return {
    ...measureInfo,
    area: measureInfo.area ? DrawingsCanvasUtils.squarePxToMetres(measureInfo.pxArea, scale, metersPerPx) : 0,
    length: measureInfo.length ? DrawingsCanvasUtils.pxToMetres(measureInfo.pxLength, scale, metersPerPx) : 0,
    perimeter: measureInfo.perimeter ? DrawingsCanvasUtils.pxToMetres(measureInfo.pxPerimeter, scale, metersPerPx) : 0,
  };
}

function addToGroupMeasures(
  groupMeasures: DrawingsGroupMeasure,
  measureInfo: DrawingsInstanceMeasure,
): DrawingsGroupMeasure {
  const measures = groupMeasures;
  if (isPolygonMeasure(measureInfo.type, measureInfo.measures)) {
    measures.area += measureInfo.measures.area;
    measures.perimeter += measureInfo.measures.perimeter;
    if (measureInfo.measures.height) {
      measures.verticalArea += measureInfo.measures.verticalArea;
    }
  } else if (isRectangleMeasure(measureInfo.type, measureInfo.measures)) {
    measures.area += measureInfo.measures.area;
    measures.perimeter += measureInfo.measures.perimeter;
    if (measureInfo.measures.height) {
      measures.verticalArea += measureInfo.measures.verticalArea;
    }
  } else if (isLengthMeasure(measureInfo.type, measureInfo.measures)) {
    measures.length += measureInfo.measures.length;
    if (measureInfo.measures.thickness) {
      measures.area += measureInfo.measures.area;
      if (measureInfo.measures.height) {
        measures.volume += measureInfo.measures.volume;
      }
    }
    if (measureInfo.measures.height) {
      measures.verticalArea += measureInfo.measures.verticalArea;
    }
  }
  return measures;
}

function negativizeMeasure(measure: DrawingsInstanceMeasure): DrawingsInstanceMeasure {
  return produce(measure, (m) => {
    m.measures = produce(m.measures, (measures) => {
      for (const key in measures) {
        if (measures[key] !== undefined && measures[key] !== null) {
          measures[key] = -measures[key];
        }
      }
      return measures;
    });
  });
}


export const DrawingsMeasureUtils = {
  isPolygonMeasure,
  isLengthMeasure,
  isRectangleMeasure,
  isCountMeasure,
  getInstanceTypeFromDrawMode,
  updateMeasureWithScale,
  updateGroupMeasuresWithScale,
  updateMeasuresByConstantParams,
  getPolygonIgnoreMeasures,
  getPolylineIngoreMeasures,
  addToGroupMeasures,
  negativizeMeasure,
};
