import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { SubscriptionEditorState } from 'common/interfaces/account/subscription-editor-form-state';
import { State } from 'common/interfaces/state';
import { EstimateSubscriptionForm } from '../../../interfaces/estimate-subscription-form';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { SubscriptionListingUtils } from '../../../utils/subscription-listing-utils';

export const useSubscriptionFormSelector = (): EstimateSubscriptionForm => {
  const plansModel = useSelector<State, SubscriptionPlanListingModel>(s => s.account.subscriptionPlans);
  const {
    teamSize,
    selectedAddonGroups,
    selectedPlanGroup,
    billingPeriod,
    vatNumber,
    billingCountry,
    coupons,
  } = useSelector<State, SubscriptionEditorState>(s => s.account.subscriptionEditorState);
  const planVariant = SubscriptionListingUtils.getSelectedPlanVariant(plansModel, billingPeriod, selectedPlanGroup);
  const filteredAddonsGroups = planVariant
    ? SubscriptionListingUtils.filterAddonsGroups(
      selectedAddonGroups,
      plansModel,
      planVariant,
    )
    : [];
  const selectedAddons = SubscriptionListingUtils.getSelectedAddons(
    plansModel,
    billingPeriod,
    filteredAddonsGroups,
  );
  const addons = selectedAddons.map(x => x.id);
  const planId = planVariant?.id;

  const quantity = planVariant?.isCollaborationBlocked
    ? 1
    : teamSize;

  return useMemo(() => ({
    addons,
    quantity,
    planId,
    vatNumber,
    billingCountry,
    coupons,
    reactivate: true,
  }), [addons, quantity, planId, vatNumber, billingCountry, coupons]);
};
