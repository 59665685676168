import React, { useMemo } from 'react';

import { Text } from 'common/components/text';
import { InvitePeopleForm } from '../invite-people-form';
import { Styled } from './styled';

interface RoleData {
  id: number;
  name: string;
}

interface Props {
  onFormSubmit: (formsData: Array<{ email: string, role: RoleData }>) => void;
  userLimit?: number;
  userRoles: RoleData[];
  title: string;
}

export const AddCompanyUserPopup = React.memo<Props>((props) => {
  const { onFormSubmit, userLimit, userRoles, title } = props;
  const roles = useMemo(() => userRoles.map(x => x.name), [userRoles]);

  return (
      <Styled.Container>
        <Text>{title}</Text>
        <InvitePeopleForm
          onSubmit={onFormSubmit}
          roles={roles}
          userLimit={userLimit}
          userRoles={userRoles}
          hiddenSkipButton={true}
          inviteUsersButtonText={'Share'}
        />
      </Styled.Container>
  );
},
);
