import autobind from 'autobind-decorator';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  CANT_SELECT_PLAN_DIALOG,
  CantSelectSinglePlanDialog,
  USER_MORE_THAN_SEATS_DIALOG,
  UserMoreThanSeatsDialog,
} from 'common/components/change-plan-dialogs';
import { SubscriptionType } from 'common/constants/subscription';
import { RequestStatus } from 'common/enums/request-status';
import { CurrentCountry } from 'common/environment/current-country-service';
import { SubscriptionEditorState } from 'common/interfaces/account/subscription-editor-form-state';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DeferredExecutor } from 'common/utils/deferred-executer';
import { SubscriptionActivityStatus } from '../../../../../units/subscription/enums/subscription-activity-status';
import { EstimateSubscriptionError } from '../../../../../units/subscription/interfaces';
import { SubscriptionPlanVariant } from '../../../../../units/subscription/interfaces/subscription-plan-variant';
import { AccountActions } from '../../../../account/actions/creators';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../account/selectors';
import { Person } from '../../../../people/interfaces/person';
import { SubscriptionActions } from '../../../actions/creators';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { BillingPeriodUnit } from '../../../enums/billing-period-unit';
import { getPlan } from '../../../hooks';
import { CompanyBillingInfoModel } from '../../../interfaces/company-billing-info-model';
import { EstimateSubscriptionForm } from '../../../interfaces/estimate-subscription-form';
import { EstimateSubscriptionModel } from '../../../interfaces/estimation-subscription-model';
import { PaymentSourcesModel } from '../../../interfaces/payment-sources-model';
import { SubscriptionDetailsModel } from '../../../interfaces/subscription-details';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';
import { UpdateSubscriptionForm } from '../../../interfaces/update-subscription-form';
import { BillingEstimationUtils } from '../../../utils/billing-estimation-utils';
import { SubscriptionListingUtils } from '../../../utils/subscription-listing-utils';
import { SubscriptionDetails } from '../../subscription-details';
import {
  SelectSubscriptionPlanDialog,
  SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS,
} from '../select-subscription-plan-dialog';


const UPDATE_ESTIMATION_DELAY = 1000;
const UPDATE_PAYMENT_METHOD_DELAY = 2000;
const MIN_TEAM_SIZE_FOR_TEAM = 2;
const MIN_TEAM_SIZE_FOR_ANOTHER = 1;
const TEAM = 'Team';

interface DispatchProps {
  getSubscriptionPlans: (subscriptionType: SubscriptionType) => void;
  setSubscriptionPaymentMethod: (subscriptionId: string, paymentMethodId: string) => void;
  getOwnCompanyBillingInfo: (subscriptionType: SubscriptionType) => void;
  updateSubscription: (subscriptionId: string, form: UpdateSubscriptionForm, afterApply?: (id: string) => void) => void;
  getSubscriptionEstimation: (subscriptionId: string, form?: EstimateSubscriptionForm) => void;
  pauseSubscription: (subscriptionId: string) => void;
  dropOwnCompanyBillingInfoState: () => void;
  openDialog: (dialogName: string) => void;
  startUpdateSubscription: () => void;
  prolongateTrial: (subscriptionId: string) => void;
  setSubscriptionEditFormState: (payload: Partial<SubscriptionEditorState>) => void;
  resetEstimation: () => void;
}

interface StateProps {
  billingEstimationError: EstimateSubscriptionError;
  subscriptionType: SubscriptionType;
  subscription: CompanySubscriptionModel;
  ownCompanyBillingInfo: CompanyBillingInfoModel;
  subscriptionPlansRequestStatus: RequestStatus;
  ownCompanyBillingInfoRequestStatus: RequestStatus;
  plansModel: SubscriptionPlanListingModel | null;
  paymentSource: PaymentSourcesModel;
  billingEstimation: EstimateSubscriptionModel;
  paidEmployeesCount: number;
  guestUsersCount: number;
  currentCountry: CurrentCountry;
  isHandleSubscriptionUpdate: boolean;
  subscriptionEditFormState: SubscriptionEditorState;
  companyUsers: Person[];
}

interface OwnProps {
  submitButtonText?: string;
  skipUpdate?: boolean;
  hideCancelSubscribtion?: boolean;
  hidePauseSubscribtion?: boolean;
  reactivate?: boolean;
  headerText?: string;
  isSkipUpdateSubscription?: boolean;
  isArhived?: boolean;
  afterApply?: (id: string, hasChange?: boolean) => void;
  onChangeSubscription?: () => void;
}

interface Props extends DispatchProps, StateProps, OwnProps {
  onSubscriptionUpdate?: () => void;
  onSubscriptionCancel?: () => void;
}

class SubscriptionEditorComponent extends React.Component<Props> {
  private getEstimationDeferredExecutor: DeferredExecutor = new DeferredExecutor(UPDATE_ESTIMATION_DELAY);
  private setPaymentMethodDeferredExecutor: DeferredExecutor = new DeferredExecutor(UPDATE_PAYMENT_METHOD_DELAY);
  private prevFormState: SubscriptionEditorState = null;
  private isFirstBillingRequested: boolean = false;

  public componentDidMount(): void {
    const {
      plansModel,
      billingEstimation,
      ownCompanyBillingInfo,
      paymentSource,
      dropOwnCompanyBillingInfoState,
      resetEstimation,
    } = this.props;
    if (billingEstimation !== null) {
      resetEstimation();
    }
    dropOwnCompanyBillingInfoState();
    this.fetchSubscriptionInfoIfNeeded();
    if (plansModel && ownCompanyBillingInfo && this.isBillingForCurrentSubscription()) {
      this.setPlanInitialState();
    }

    if (paymentSource) {
      this.setPaymentInitialState();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const { plansModel, subscriptionEditFormState, billingEstimation } = this.props;
    const {
      billingPeriod,
      selectedPlanGroup,
      teamSize,
      coupons,
    } = subscriptionEditFormState;


    this.fetchSubscriptionInfoIfNeeded();
    this.setInitialStateIfAllDataIsReady(prevProps);

    const filteredPlanGroups = SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod);
    const planVariant = filteredPlanGroups.find(x => x.name === selectedPlanGroup);

    if (prevProps.subscriptionEditFormState.selectedPlanGroup
      && prevProps.subscriptionEditFormState.selectedPlanGroup !== selectedPlanGroup
      && planVariant?.status !== BillingEntityStatus.Archived
    ) {
      const currentSubscription = {
        planVariant,
        teamSize,
        selectedAddonIds: [],
        billingPeriod,
        coupons,
      };
      this.onSubscriptionChange(currentSubscription);
    }

    if (billingEstimation === null && !this.isFirstBillingRequested) {
      this.getSubscriptionEstimation();
    }

    if (subscriptionEditFormState !== prevProps.subscriptionEditFormState) {
      this.prevFormState = prevProps.subscriptionEditFormState;
      this.getEstimationIfNeeded();
    }
  }

  public render(): JSX.Element {
    const {
      plansModel,
      ownCompanyBillingInfo,
      submitButtonText,
      hideCancelSubscribtion,
      hidePauseSubscribtion,
      headerText,
      isSkipUpdateSubscription,
      subscriptionEditFormState,
      isArhived,
      isHandleSubscriptionUpdate,
    } = this.props;
    const {
      billingPeriod,
      selectedAddonGroups,
      selectedPlanGroup,
      teamSize,
      coupons,
      paymentCardId,
      billingCountry,
      vatNumber,
    } = subscriptionEditFormState;

    if (!ownCompanyBillingInfo || !plansModel || !this.isBillingForCurrentSubscription()) {
      return null;
    }

    const filteredPlanGroups = SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod);
    const filteredAddons = SubscriptionListingUtils.getFilteredAddons(plansModel, billingPeriod);
    const selectedAddons = SubscriptionListingUtils.getSelectedAddons(plansModel, billingPeriod, selectedAddonGroups);
    const planVariant = filteredPlanGroups.find(x => x.name === selectedPlanGroup);
    const isSelectedPlanTeam = selectedPlanGroup === TEAM;
    const currentTeamSize = isSelectedPlanTeam
      && teamSize < MIN_TEAM_SIZE_FOR_TEAM
      ? MIN_TEAM_SIZE_FOR_TEAM : teamSize;
    const isBillingStatusTrial = this.props.subscription.billingStatus === SubscriptionActivityStatus.Trial;

    if (!planVariant) {
      return null;
    }

    if (ownCompanyBillingInfo.isMocked) {
      return (
        <SubscriptionDetails
          addons={filteredAddons}
          applyButtonCaption={'Update Plan'}
          estimationError={this.props.billingEstimationError}
          currentSubscription={{
            planVariant,
            teamSize,
            selectedAddonIds: filteredAddons.map(x => x.id),
            billingPeriod,
            coupons,
          }}
          header={'Mock plan'}
          currentTeamSize={currentTeamSize}
          minTeamSize={isSelectedPlanTeam ? MIN_TEAM_SIZE_FOR_TEAM : MIN_TEAM_SIZE_FOR_ANOTHER}
          onSubscriptionChange={this.onSubscriptionChange}
          onChangePaymentCardId={this.onChangePaymentCardId}
          onCountryIdChanged={this.onCountryIdChanged}
          onVatNumberIdChanged={this.onVatNumberIdChanged}
          paymentCardId={paymentCardId}
          countryId={billingCountry}
          vatNumber={vatNumber}
          isHandleInProgress={false}
        />
      );
    }

    const canUpdateSubscription = this.canUpdateSubscription();
    const paymentDetails = canUpdateSubscription
      ? this.getPaymentDetails()
      : null;

    const handlePauseSubscription = hidePauseSubscribtion
      ? undefined
      : this.pauseSubscriptionPlanDialog;
    const handleChangePlan = isSkipUpdateSubscription
      ? undefined
      : this.openSelectSubscriptionPlanDialog;
    const header = headerText
      ? headerText
      : isBillingStatusTrial
        ? 'Your trial subscription'
        : 'Billing details';

    return (
      <>
        <SubscriptionDetails
          addons={filteredAddons}
          estimationError={this.props.billingEstimationError}
          applyButtonCaption={submitButtonText || 'Update Plan'}
          currentSubscription={{
            planVariant,
            teamSize,
            selectedAddonIds: selectedAddons.map(x => x.id),
            billingPeriod,
            coupons,
          }}
          header={header}
          currentTeamSize={currentTeamSize}
          minTeamSize={isSelectedPlanTeam ? MIN_TEAM_SIZE_FOR_TEAM : MIN_TEAM_SIZE_FOR_ANOTHER}
          onApplyClick={canUpdateSubscription ? this.onApplyClick : null}
          showCancelSubscriptionButton={!hideCancelSubscribtion}
          onPauseSubscriptionClick={handlePauseSubscription}
          onChangePlanClick={handleChangePlan}
          onSubscriptionChange={this.onSubscriptionChange}
          onChangePaymentCardId={this.onChangePaymentCardId}
          onCountryIdChanged={this.onCountryIdChanged}
          onVatNumberIdChanged={this.onVatNumberIdChanged}
          handleExtendTrial={this.prolongateTrial}
          paymentCardId={paymentCardId}
          countryId={billingCountry}
          vatNumber={vatNumber}
          paymentDetails={paymentDetails}
          hideChangeSubscription={isSkipUpdateSubscription}
          isHandleInProgress={isHandleSubscriptionUpdate}
        />
        <SelectSubscriptionPlanDialog
          selectedAddons={selectedAddons}
          onBillingPeriodChanged={this.onBillingPeriodChanged}
          onSubscriptionPlanGroupClick={this.setSubscriptionPlanGroup}
        />
        <CantSelectSinglePlanDialog />
        <UserMoreThanSeatsDialog isArhived={isArhived} />
      </>
    );
  }

  public componentWillUnmount(): void {
    this.getEstimationDeferredExecutor.reset();
  }

  @autobind
  private onCountryIdChanged(billingCountry: string): void {
    const { setSubscriptionEditFormState } = this.props;
    setSubscriptionEditFormState({ billingCountry });
  }

  @autobind
  private onVatNumberIdChanged(vatNumber: string): void {
    const { setSubscriptionEditFormState } = this.props;
    setSubscriptionEditFormState({ vatNumber });
  }

  @autobind
  private onChangePaymentCardId(paymentCardId: string): void {
    const {
      ownCompanyBillingInfo,
      paymentSource,
      setSubscriptionEditFormState,
      setSubscriptionPaymentMethod,
    } = this.props;
    if (!paymentSource.paymentSources.some(x => x.id === paymentCardId)) {
      return;
    }
    setSubscriptionEditFormState({ paymentCardId });
    this.setPaymentMethodDeferredExecutor.execute(() => {
      setSubscriptionPaymentMethod(ownCompanyBillingInfo.subscriptionId, paymentCardId);
    });
    this.getEstimationIfNeeded();
  }

  private fetchSubscriptionInfoIfNeeded(): void {
    const {
      subscriptionPlansRequestStatus,
      ownCompanyBillingInfoRequestStatus,
      ownCompanyBillingInfo,
      subscriptionType,
      getSubscriptionPlans,
      getOwnCompanyBillingInfo,
    } = this.props;
    if (subscriptionPlansRequestStatus === RequestStatus.NotRequested) {
      getSubscriptionPlans(subscriptionType);
    }
    if (ownCompanyBillingInfoRequestStatus === RequestStatus.NotRequested
      || (ownCompanyBillingInfo && !this.isBillingForCurrentSubscription())
    ) {
      getOwnCompanyBillingInfo(subscriptionType);
    }
  }

  private getPaymentDetails(): string[] {
    const { subscriptionEditFormState: { billingCountry }, billingEstimation } = this.props;
    const extraCurrency = BillingEstimationUtils.getExtraCurrency(billingCountry);
    return BillingEstimationUtils.getPaymentDetails(billingEstimation, extraCurrency);
  }

  private isBillingForCurrentSubscription(): boolean {
    const { subscriptionType, ownCompanyBillingInfo } = this.props;
    return subscriptionType === ownCompanyBillingInfo.subscriptionType;
  }

  private getPlanVariantByPlanId(id: string): SubscriptionPlanVariant {
    const { plansModel } = this.props;
    for (const group of plansModel.plans) {
      for (const variant of group.variants) {
        if (variant.id === id) {
          return variant;
        }
      }
    }
  }
  private getPlanGroupNameByPlanId(id: string): string {
    const { plansModel } = this.props;
    const group = plansModel && plansModel.plans.find(x => x.variants.find(v => v.id === id));

    return group && group.name;
  }

  private getAddonGroupsByAddonIds(addonIds: string[]): string[] {
    const { plansModel } = this.props;
    return plansModel && plansModel.addons.filter(x => addonIds.includes(x.id)).map(x => x.groupName);
  }

  private isSubscriptionChanged(): boolean {
    const {
      ownCompanyBillingInfo: currentPlan,
      paymentSource: currentPayment,
      subscriptionEditFormState: formState,
    } = this.props;
    const prevFormState = this.prevFormState;

    if (!currentPlan || !currentPayment || currentPlan.isMocked || !formState.billingCountry) {
      return false;
    }

    return formState.billingPeriod !== currentPlan.billingPeriodUnit
      || formState.billingCountry !== currentPayment?.billingCountry
      || formState.vatNumber !== currentPayment?.vatNumber
      || formState.teamSize !== currentPlan.licensesCount
      || formState.paidPlanGroup !== formState.selectedPlanGroup
      || this.isListChanged(formState.coupons, prevFormState?.coupons)
      || this.isListChanged(formState.paidAddonGroups, formState?.selectedAddonGroups);
  }

  private canUpdateSubscription(): boolean {
    const {
      skipUpdate,
      subscriptionEditFormState: {
        paymentCardId,
        billingCountry,
        teamSize,
      },
    } = this.props;
    return skipUpdate
      || this.isSubscriptionChanged()
      && !!paymentCardId
      && this.isTeamSizeValid(teamSize)
      && !!billingCountry;
  }

  private isNeedUpdateEstimate(): boolean {
    const {
      subscriptionEditFormState: formState,
    } = this.props;
    const prevFormState = this.prevFormState;

    if (!formState.billingCountry) {
      return false;
    }

    return formState.billingPeriod !== prevFormState?.billingPeriod
      || formState.billingCountry !== prevFormState?.billingCountry
      || formState.vatNumber !== prevFormState?.vatNumber
      || formState.teamSize !== prevFormState?.teamSize
      || formState.paidPlanGroup !== prevFormState?.paidPlanGroup
      || formState.selectedPlanGroup !== prevFormState?.selectedPlanGroup
      || this.isListChanged(formState.coupons, prevFormState?.coupons)
      || this.isListChanged(formState.paidAddonGroups, formState?.selectedAddonGroups);
  }

  private isListChanged(paidAddons: string[], selectedAddons: string[]): boolean {
    const paid = new Set(paidAddons);
    const selected = new Set(selectedAddons);

    if (paid.size !== selected.size) {
      return true;
    }
    for (const group of paid) {
      if (!selected.has(group)) {
        return true;
      }
    }

    return false;
  }

  @autobind
  private onSubscriptionChange(model: SubscriptionDetailsModel): void {
    const {
      subscriptionEditFormState: {
        billingPeriod,
        selectedAddonGroups,
        teamSize,
        coupons,
      },
      setSubscriptionEditFormState,
    } = this.props;
    if (billingPeriod !== model.billingPeriod) {
      this.onBillingPeriodChanged(model.billingPeriod);
    }

    const selectedGroupNames = this.props.plansModel.addons
      .filter(x => model.selectedAddonIds.includes(x.id))
      .map(x => x.groupName);
    if (!isEqual(selectedAddonGroups, selectedGroupNames)) {
      this.onSelectedAddonGroupsChanged(selectedGroupNames);
    }

    if (teamSize !== model.teamSize) {
      this.onTeamSize(model.teamSize);
    }

    if (coupons !== model.coupons) {
      setSubscriptionEditFormState({ coupons: model.coupons });
    }
  }

  @autobind
  private onTeamSize(teamSize: number): void {
    const { setSubscriptionEditFormState, openDialog } = this.props;
    if (!this.isTeamSizeValid(teamSize)) {
      openDialog(USER_MORE_THAN_SEATS_DIALOG);
    }
    setSubscriptionEditFormState({ teamSize });
  }

  @autobind
  private isTeamSizeValid(teamSize: number): boolean {
    const { companyUsers } = this.props;
    const payableUserCount = companyUsers.filter(u => u.isPayable).length;

    return teamSize >= payableUserCount;
  }

  @autobind
  private onBillingPeriodChanged(billingPeriod: BillingPeriodUnit): void {
    const { setSubscriptionEditFormState, isSkipUpdateSubscription } = this.props;
    if (isSkipUpdateSubscription) {
      return;
    }
    setSubscriptionEditFormState({ billingPeriod });
  }

  @autobind
  private onSelectedAddonGroupsChanged(selectedAddonGroups: string[]): void {
    this.props.setSubscriptionEditFormState({ selectedAddonGroups });
  }

  @autobind
  private setSubscriptionPlanGroup(selectedPlanGroup: string): void {
    const {
      plansModel,
      paidEmployeesCount,
      guestUsersCount,
      subscriptionEditFormState: {
        billingPeriod,
      },
      onChangeSubscription,
      setSubscriptionEditFormState,
    } = this.props;
    const filteredPlanGroups = SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod);
    const planVariant = filteredPlanGroups.find(x => x.name === selectedPlanGroup);

    if (planVariant.isCollaborationBlocked && (paidEmployeesCount > 1 || guestUsersCount)) {
      this.props.openDialog(CANT_SELECT_PLAN_DIALOG);
      return;
    }

    if (onChangeSubscription) {
      onChangeSubscription();
    }
    setSubscriptionEditFormState({ selectedPlanGroup });
  }

  @autobind
  private pauseSubscriptionPlanDialog(): void {
    const subscriptionId = this.props.ownCompanyBillingInfo.subscriptionId;
    this.props.pauseSubscription(subscriptionId);
    if (this.props.onSubscriptionCancel) {
      this.props.onSubscriptionCancel();
    }
  }

  @autobind
  private prolongateTrial(): void {
    const subscriptionId = this.props.ownCompanyBillingInfo.subscriptionId;
    this.props.prolongateTrial(subscriptionId);
  }

  @autobind
  private openSelectSubscriptionPlanDialog(): void {
    this.props.openDialog(SELECT_SUBSCRIPTION_PLAN_DIALOG_CONSTANTS.DIALOG_NAME);
  }

  private getEstimationIfNeeded(): void {
    const { ownCompanyBillingInfo, subscription } = this.props;
    if (
      this.isNeedUpdateEstimate()
      && ownCompanyBillingInfo
      && !ownCompanyBillingInfo.isMocked
      && !AccountSelectors.isPausedSubscription(subscription)
    ) {
      this.getSubscriptionEstimation();
    }
  }

  private getSubscriptionEstimation(): void {
    const {
      ownCompanyBillingInfo,
      getSubscriptionEstimation,
      resetEstimation,
    } = this.props;
    const form = this.getForm();
    if (this.isFromDataValid(form) && this.shouldSkipEstimating(form.planId)) {
      this.getEstimationDeferredExecutor.execute(() => {
        this.isFirstBillingRequested = true;
        resetEstimation();
        getSubscriptionEstimation(ownCompanyBillingInfo.subscriptionId, form);
      });
    }
  }

  private shouldSkipEstimating(planId: string): boolean {
    const { plansModel, skipUpdate } = this.props;
    const plan = getPlan(plansModel, planId);
    const isSelectedPlanArchived = plan?.status === BillingEntityStatus.Archived;
    if (isSelectedPlanArchived) {
      return !skipUpdate;
    } else {
      return true;
    }
  }

  private isFromDataValid(form: UpdateSubscriptionForm | EstimateSubscriptionForm): boolean {
    const {
      ownCompanyBillingInfo,
    } = this.props;

    return !!form.billingCountry && !!form.planId && !!ownCompanyBillingInfo?.subscriptionId;
  }

  private getAlternativePlanGroupNameIfCurrentIsNotAvailable(): string {
    const { plansModel, ownCompanyBillingInfo, paidEmployeesCount, guestUsersCount } = this.props;
    const paidPlanVariant = this.getPlanVariantByPlanId(ownCompanyBillingInfo.planId);
    if (!paidPlanVariant) {
      return;
    }
    if (paidPlanVariant.isCollaborationBlocked && (paidEmployeesCount > 1 || guestUsersCount)) {
      return SubscriptionListingUtils.getFilteredPlanGroups(plansModel, paidPlanVariant.billingPeriodUnit)
        .find(x => !x.isCollaborationBlocked && x.isSelfService)
        .name;
    }
  }

  private setInitialStateIfAllDataIsReady(prevProps: Props): void {
    const {
      plansModel,
      ownCompanyBillingInfo,
    } = this.props;
    if (plansModel
      && ownCompanyBillingInfo
      && (plansModel !== prevProps.plansModel
        || ownCompanyBillingInfo !== prevProps.ownCompanyBillingInfo
      )) {
      this.setPlanInitialState();
    }

    if (this.isPaymentSourceChange(prevProps)) {
      this.changePaymentMethod();
    }
  }

  private isPaymentSourceChange(prevProps: Props): boolean {
    const {
      paymentSource,
    } = this.props;
    return paymentSource && paymentSource !== prevProps.paymentSource;
  }

  private changePaymentMethod(): void {
    const {
      paymentSource,
      currentCountry,
      subscriptionEditFormState: {
        paymentCardId,
      },
    } = this.props;
    this.onChangePaymentCardId(paymentCardId || paymentSource.primaryCardId);
    this.onCountryIdChanged(paymentSource.billingCountry || currentCountry?.country);
    this.onVatNumberIdChanged(paymentSource.vatNumber);
  }

  private setPlanInitialState(): void {
    const {
      ownCompanyBillingInfo,
      paidEmployeesCount,
      subscriptionEditFormState,
      setSubscriptionEditFormState,
      plansModel,
    } = this.props;

    const paidPlanGroup = this.getPlanGroupNameByPlanId(ownCompanyBillingInfo.planId);
    const alternativePlanGroupName = this.getAlternativePlanGroupNameIfCurrentIsNotAvailable();
    const paidAddonGroups = this.getAddonGroupsByAddonIds(ownCompanyBillingInfo.addons);
    const selectedAddonGroups = subscriptionEditFormState.selectedAddonGroups === null
      ? paidAddonGroups
      : subscriptionEditFormState.selectedAddonGroups;
    let selectedPlanGroup = subscriptionEditFormState.selectedPlanGroup;
    let billingPeriod = subscriptionEditFormState.billingPeriod || ownCompanyBillingInfo.billingPeriodUnit;
    const filteredPlanGroups = SubscriptionListingUtils.getFilteredPlanGroups(plansModel, billingPeriod);

    if (!filteredPlanGroups.some(x => x.name === selectedPlanGroup)) {
      selectedPlanGroup = alternativePlanGroupName || paidPlanGroup;
      billingPeriod = ownCompanyBillingInfo.billingPeriodUnit;
    }

    setSubscriptionEditFormState({
      teamSize: ownCompanyBillingInfo.licensesCount < paidEmployeesCount
        ? paidEmployeesCount
        : ownCompanyBillingInfo.licensesCount,
      selectedPlanGroup,
      paidPlanGroup,
      billingPeriod: subscriptionEditFormState.billingPeriod || ownCompanyBillingInfo.billingPeriodUnit,
      paymentCardId: ownCompanyBillingInfo.paymentSourceId,
      selectedAddonGroups,
      paidAddonGroups,
    });
  }

  private setPaymentInitialState(): void {
    const {
      paymentSource,
      subscriptionEditFormState: {
        paymentCardId,
      },
      setSubscriptionEditFormState,
    } = this.props;
    setSubscriptionEditFormState({
      paymentCardId: paymentCardId || paymentSource.primaryCardId,
      billingCountry: paymentSource.billingCountry,
      vatNumber: paymentSource.vatNumber,
    });
  }

  private getForm(): UpdateSubscriptionForm | EstimateSubscriptionForm {
    const {
      plansModel,
      reactivate,
      subscriptionEditFormState: {
        teamSize,
        selectedAddonGroups,
        selectedPlanGroup,
        billingPeriod,
        vatNumber,
        billingCountry,
        coupons,
      },
    } = this.props;
    const planVariant = SubscriptionListingUtils.getSelectedPlanVariant(plansModel, billingPeriod, selectedPlanGroup);
    const filteredAddonsGroups = planVariant
      ? SubscriptionListingUtils.filterAddonsGroups(
        selectedAddonGroups,
        plansModel,
        planVariant,
      )
      : [];
    const selectedAddons = SubscriptionListingUtils.getSelectedAddons(plansModel, billingPeriod, filteredAddonsGroups);
    const addons = selectedAddons.map(x => x.id);
    const planId = planVariant?.id;

    const quantity = planVariant?.isCollaborationBlocked ? 1
      : planVariant?.name === TEAM && teamSize < MIN_TEAM_SIZE_FOR_TEAM
        ? MIN_TEAM_SIZE_FOR_TEAM
        : teamSize;
    return { addons, quantity, planId, vatNumber, billingCountry, coupons, reactivate: !!reactivate };
  }

  @autobind
  private onApplyClick(): void {
    const {
      ownCompanyBillingInfo,
      isSkipUpdateSubscription,
      startUpdateSubscription,
      skipUpdate,
      afterApply,
      updateSubscription,
    } = this.props;
    startUpdateSubscription();

    if (isSkipUpdateSubscription) {
      afterApply(ownCompanyBillingInfo.subscriptionId);
      return;
    }

    if (this.isSubscriptionChanged()) {
      if (skipUpdate) {
        afterApply(ownCompanyBillingInfo.subscriptionId, true);
        return;
      }
      const form = this.getForm();
      updateSubscription(ownCompanyBillingInfo.subscriptionId, form);
    } else if (skipUpdate) {
      afterApply(ownCompanyBillingInfo.subscriptionId);
    }
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    subscriptionType: state.account.selectedSubscriptionType,
    subscriptionPlansRequestStatus: state.account.requests.subscriptionPlans,
    ownCompanyBillingInfoRequestStatus: state.account.requests.ownCompanyBillingInfo,
    plansModel: state.account.subscriptionPlans,
    ownCompanyBillingInfo: state.account.ownCompanyBillingInfo,
    subscription: AccountSelectors.currentSubscription(state),
    paymentSource: state.account.payment,
    billingEstimation: state.account.billingEstimation,
    paidEmployeesCount: AccountSelectors.getPaidEmployeesCount(state),
    guestUsersCount: AccountSelectors.getGuestUsersCount(state),
    currentCountry: state.account.currentCountry,
    billingEstimationError: state.account.billingEstimationError,
    isHandleSubscriptionUpdate: state.account.isHandleSubscriptionUpdate,
    subscriptionEditFormState: state.account.subscriptionEditorState,
    companyUsers: state.people.companiesUsers,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getSubscriptionPlans: type => dispatch(SubscriptionActions.getSubscriptionPlansRequest(type)),
    setSubscriptionPaymentMethod: (subscriptionId, paymentMethodId) =>
      dispatch(SubscriptionActions.setSubscriptionPaymentMethod(subscriptionId, paymentMethodId)),
    getOwnCompanyBillingInfo: type => dispatch(SubscriptionActions.fetchOwnCompanyBillingInfo(type)),
    dropOwnCompanyBillingInfoState: () => dispatch(SubscriptionActions.dropOwnCompanyBillingInfoState()),
    openDialog: (dialogName) => dispatch(KreoDialogActions.openDialog(dialogName)),
    updateSubscription: (subscriptionId, form, afterApply) =>
      dispatch(SubscriptionActions.updateSubscription(subscriptionId, form, afterApply)),
    getSubscriptionEstimation: (subscriptionId, form) =>
      dispatch(SubscriptionActions.getUpdateSubscriptionEstimation(subscriptionId, form)),
    pauseSubscription: (id) => dispatch(SubscriptionActions.pauseSubscription(id)),
    startUpdateSubscription: () => dispatch(SubscriptionActions.startUpdateSubscription()),
    prolongateTrial: (subscriptionId) => dispatch(SubscriptionActions.prolongateTrial(subscriptionId)),
    setSubscriptionEditFormState: (payload) => dispatch(AccountActions.setSubscriptionEditFormState(payload)),
    resetEstimation: () => dispatch(SubscriptionActions.setSubscriptionEstimation(null)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export const SubscriptionEditor = connector(SubscriptionEditorComponent);
