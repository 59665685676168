import { Icons } from '@kreo/kreo-ui-components';
import { DrawingsContextMenuBaseItem } from '../../drawings-context-menu';


export enum TransformMenuActions {
  FlipVertically = 'Flip Vertically',
  FlipHorizontally = 'Flip Horizontally',
  RotateRight = 'Rotate right 90°',
  RotateLeft = 'Rotate left 90°',
}

export const TransformMenuItems: DrawingsContextMenuBaseItem[] = [
  {
    action: TransformMenuActions.FlipVertically,
    filters: [],
    icon: Icons.FlipVertically,
  },
  {
    action: TransformMenuActions.FlipHorizontally,
    filters: [],
    icon: Icons.FlipHorizontally,
  },
  {
    action: TransformMenuActions.RotateRight,
    filters: [],
    icon: Icons.RotateSmallRight,
  },
  {
    action: TransformMenuActions.RotateLeft,
    filters: [],
    icon: Icons.RotateSmallLeft,
  },
];
