import { ModalWrapper } from '@kreo/kreo-ui-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { AddCompanyUserPopup } from 'common/components/add-company-user-popup';
import { RenderIf } from 'common/components/render-if';
import { RoleGroup } from 'common/enums/role-group';
import { Role } from 'common/interfaces/account/role';
import { State } from 'common/interfaces/state';
import { pushSearch } from '../../../../actions/common';
import { AccountApi } from '../../../../units/account/api';
import { AccountSelectors } from '../../../../units/account/selectors';
import { PeopleActions } from '../../actions/actions';
import { Person } from '../../interfaces/person';
import { PersonVisibleData } from './company-info-wrapper';
import { RoleData } from './interfaces';
import { useInvitePeopleToCompany } from './use-invite-people-to-company';


const AddCompanyInfoPopupComponent: React.FC = () => {
  const dispatch = useDispatch();
  const canShareProjects = useAbility(Operation.Manage, Subject.ShareProjects);
  const isEmployees = useAbility(Operation.Create, Subject.Employees);
  const isGuests = useAbility(Operation.Create, Subject.Guests);
  const isSubcontractors = useAbility(Operation.Create, Subject.Subcontractors);
  const companyId = useSelector((state: State) => state.account.selectedCompany?.id);
  const isAddGuest = useSelector((state: State) => state.people.isAddGuest);
  const employees = useSelector((state: State) => state.people.employees);
  const userRoles = useSelector((state: State) => state.people.userRoles);
  const guestRoles = useSelector((state: State) => state.people.guestRoles);
  const subscriptionRoles = useSelector((state: State) => state.account.subscriptionRoles);
  const people = useSelector((state: State) => state.people.companiesUsers || []);
  const employeesMaxCount = useSelector((state: State) => {
    const subscription = AccountSelectors.currentSubscription(state);
    return subscription && subscription.employeesMaxCount;
  });
  const showAddUsersDialog = useSelector<State, boolean>(s => Boolean(s.router.location.query.addCompanyUser));
  const showAddGuestDialog = useSelector<State, boolean>(s => Boolean(s.router.location.query.addCompanyGuest));

  const show = useMemo(() => showAddUsersDialog || showAddGuestDialog, [showAddGuestDialog, showAddUsersDialog]);
  const addedUserRoles = useMemo(() => isAddGuest ? guestRoles : userRoles, [guestRoles, isAddGuest, userRoles]);
  const emptySeatCount = useMemo(() => {
    return employeesMaxCount - employees.filter(x => x.isPayable).length;
  }, [employees, employeesMaxCount]);
  const userLimit = useMemo(() => !isAddGuest ? emptySeatCount : null, [emptySeatCount, isAddGuest]);
  const canInvite = useMemo(() => {
    return isEmployees || isGuests || isSubcontractors;
  }, [isEmployees, isGuests, isSubcontractors]);
  const canAddPeople = useMemo(() => {
    return canShareProjects && userLimit && canInvite;
  }, [canInvite, canShareProjects, userLimit]);

  const onInvitePeople = useInvitePeopleToCompany(companyId);

  const closeAddPeopleDialog = useCallback(() => {
    dispatch(PeopleActions.setIsAddGuest(false));
    dispatch(pushSearch({ addCompanyUser: undefined, addCompanyGuest: undefined }));
  }, [dispatch]);

  const parsePersonData = useCallback((person: Person, roleName: string): PersonVisibleData => {
    return {
      name: `${person.firstName} ${person.lastName}`,
      accountRole: roleName,
      email: person.email,
      avatar: AccountApi.getAvatarLink(person),
      isPayable: person.isPayable,
      isAccepted: person.isCompletedRegistration,
    };
  }, []);

  useEffect(() => {
    const employeesData: PersonVisibleData[] = [];
    const userRolesData: RoleData[] = [];
    const guestRolesData: RoleData[] = [];
    const roles: Record<number, Role> = {};
    let owner: PersonVisibleData;

    if (subscriptionRoles) {
      for (const role of subscriptionRoles.roles) {
        roles[role.id] = role;
        if (role.group === RoleGroup.Employee) {
          userRolesData.push({ id: role.id, name: role.name });
          dispatch(PeopleActions.setUserRoles(userRolesData));
        } else {
          guestRolesData.push({ id: role.id, name: role.name });
          dispatch(PeopleActions.setGuestRoles(guestRolesData));
        }
      }
    }

    if (subscriptionRoles && people) {
      for (const person of people) {
        const role = roles[person.roleId];
        if (!role) {
          continue;
        }
        const parsedPerson = parsePersonData(person, role.name);
        if (person.isOwner) {
          owner = parsedPerson;
        } else if (role.group === RoleGroup.Employee) {
          employeesData.push(parsedPerson);
        }
      }

      employeesData.sort((a, b) => a.email > b.email ? 1 : -1);
      if (owner) {
        employeesData.unshift(owner);
      }
      dispatch(PeopleActions.setEmployees(employeesData));
    }
  }, [dispatch, parsePersonData, people, subscriptionRoles]);

  useEffect(() => {
    dispatch(PeopleActions.setIsAddGuest(showAddGuestDialog));
  }, [dispatch, showAddGuestDialog]);

  useEffect(() => {
    if (employees?.length) {
      if (showAddUsersDialog && !emptySeatCount) {
        closeAddPeopleDialog();
      }
      if (showAddGuestDialog && canAddPeople === false) {
        closeAddPeopleDialog();
      }
    }
  }, [employees, emptySeatCount]);

  if (!employees?.length) {
    return null;
  }

  return (
    <RenderIf condition={show}>
      <ModalWrapper onExit={closeAddPeopleDialog} zIndex={1003}>
        <AddCompanyUserPopup
          userRoles={addedUserRoles}
          title='Add people to company'
          onFormSubmit={onInvitePeople}
          userLimit={userLimit}
        />
      </ModalWrapper>
    </RenderIf>
  );
};

export const AddCompanyInfoPopup = React.memo(AddCompanyInfoPopupComponent);
