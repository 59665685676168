import { IconButton, H5, Icons, Text } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { METRIC_IDS } from 'common/constants/id-maps';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AccountActions } from '../../../../units/account/actions/creators';
import { ValidationField } from '../../../../units/account/actions/payloads';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../units/account/selectors';
import { PaymentMethodActions } from '../../actions/creators';
import { BillingPeriodUnit } from '../../enums/billing-period-unit';
import { BillingPricingModel } from '../../enums/billing-pricing-model';
import { SubscriptionActivityStatus } from '../../enums/subscription-activity-status';
import { EstimateSubscriptionError } from '../../interfaces';
import { BillingCollection } from '../../interfaces/billing-collection';
import { EstimateSubscriptionModel } from '../../interfaces/estimation-subscription-model';
import { SubscriptionAddon } from '../../interfaces/subscription-addon';
import { SubscriptionDetailsModel } from '../../interfaces/subscription-details';
import { CurrencySelector } from '../currency-selector';
import { PaymentDetailsDialog } from '../payment-details-dialog';
import { AddonListBlock } from '../subscription-details-new-user/addon-list-block';
import { BillingAddressBlock } from '../subscription-details-new-user/billing-address-block';
import {
  ChangeBillingPeriodRadioMenu,
} from '../subscription-details-new-user/change-billing-radio-menu/change-billing-radio-menu';
import { PaymentDetailsBlock } from '../subscription-details-new-user/payment-details-block';
import { PaymentManagerBlock } from '../subscription-details-new-user/payment-manager-block';
import { TeamSizeBlock } from '../subscription-details-new-user/team-size-block';
import { ApplyButton } from './apply-button';
import { BillingHistoryButton } from './billing-history-button';
import { Coupons } from './coupons';
import { Styled } from './styled';
import { SubscriptionControllButtons } from './subscription-controll-buttons';
import { SubscriptionPrice } from './subscription-price';


interface StateProps {
  billingCollection: BillingCollection;
  subscription: CompanySubscriptionModel;
  billingEstimation: EstimateSubscriptionModel;
}

interface DispatchProps {
  getBillingHistory: (limit: number, offset: string) => void;
  resetVatNumberValidation: () => void;
  openDialog: (name: string) => void;
}

interface Props extends StateProps, DispatchProps {
  onSubscriptionChange: (subscription: SubscriptionDetailsModel) => void;
  onApplyClick?: () => void;
  onChangePlanClick?: () => void;
  onPauseSubscriptionClick?: () => void;
  onChangePaymentCardId: (id: string) => void;
  onCountryIdChanged: (id: string) => void;
  onVatNumberIdChanged: (vatNumber: string) => void;
  handleExtendTrial?: () => void;
  closeDialog?: () => void;
  header: string;
  estimationError: EstimateSubscriptionError;
  paymentDetails?: string[];
  applyButtonCaption: string;
  currentSubscription: SubscriptionDetailsModel;
  currentTeamSize: number;
  minTeamSize: number;
  addons: SubscriptionAddon[];
  paymentCardId: string | null;
  countryId: string | null;
  vatNumber: string | null;
  isHandleInProgress: boolean;
  hideChangeSubscription?: boolean;
  showCancelSubscriptionButton?: boolean;
}

export class SubscriptionDetailsComponent extends React.Component<Props> {
  public render(): JSX.Element {
    const {
      header,
      currentSubscription,
      addons,
      billingCollection,
      hideChangeSubscription,
      countryId,
      vatNumber,
      paymentCardId,
      currentTeamSize,
      minTeamSize,
      subscription,
      billingEstimation,
      showCancelSubscriptionButton,
      closeDialog,
    } = this.props;
    const planVariant = currentSubscription.planVariant;
    const isTeamPlan = planVariant && planVariant.pricingModel === BillingPricingModel.PerUnit;
    const availableAddons = addons.filter(addon => planVariant.availableAddons.includes(addon.id));
    const hasTrialPeriod = !!planVariant.trialPeriod;
    const isBillingStatusTrial = subscription?.billingStatus === SubscriptionActivityStatus.Trial;
    const isPausedSubscription = AccountSelectors.isPausedSubscription(subscription);

    return (
      <>
        <Styled.Container>
          <Styled.ReviewCard>
            <Styled.HeaderWrapper>
              <H5>{header}</H5>
              <RenderIf condition={!!billingCollection?.items.length}>
                <BillingHistoryButton />
              </RenderIf>
            </Styled.HeaderWrapper>
            <RenderIf condition={!isPausedSubscription}>
              <Styled.BillingAddressBlock>
                <BillingAddressBlock
                  countryId={countryId}
                  vatNumber={vatNumber}
                  headerText={'Country'}
                  onCountryIdChanged={this.props.onCountryIdChanged}
                  onVatNumberIdChanged={this.onVatNumberChange}
                />
              </Styled.BillingAddressBlock>
            </RenderIf>
            <Styled.PaymentManagerBlock>
              <Styled.BorderBlock />
              <PaymentManagerBlock
                paymentCardId={paymentCardId}
                onChangePaymentCardId={this.props.onChangePaymentCardId}
              />
              <Styled.BorderBlock />
            </Styled.PaymentManagerBlock>
            <RenderIf condition={isTeamPlan && !hideChangeSubscription}>
              <Styled.TeamSizeBlock>
                <TeamSizeBlock
                  currentSubscription={currentSubscription}
                  currentTeamSize={currentTeamSize}
                  minTeamSize={minTeamSize}
                  headerText={'Team size'}
                  onSubscriptionChange={this.props.onSubscriptionChange}
                />
                <Styled.BorderBlock />
              </Styled.TeamSizeBlock>
            </RenderIf>
            <RenderIf condition={!hideChangeSubscription && availableAddons?.length > 1}>
              <Styled.AddonListBlock>
                <AddonListBlock
                  headerText={'Add-ons'}
                  addons={addons}
                  currentSubscription={currentSubscription}
                  hideChangeSubscription={hideChangeSubscription}
                  onSubscriptionChange={this.props.onSubscriptionChange}
                />
              </Styled.AddonListBlock>
            </RenderIf>
            <RenderIf condition={hasTrialPeriod && !hideChangeSubscription && isBillingStatusTrial}>
              <Styled.AttentionText>
                <Icons.Error />
                <Text fontSize={14} color='mainFont'>Your card will not be charged until the trial is over</Text>
              </Styled.AttentionText>
            </RenderIf>
          </Styled.ReviewCard>
          <Styled.PlanCard>
            <SubscriptionPrice
              currentSubscription={currentSubscription}
              addons={availableAddons}
              isViewMode={true}
            />
            <CurrencySelector />
            <RenderIf condition={!hideChangeSubscription}>
              <Styled.RadioMenu>
                <ChangeBillingPeriodRadioMenu
                  billingPeriod={currentSubscription.planVariant.billingPeriodUnit}
                  onSelectionChanged={this.changePeriod}
                />
              </Styled.RadioMenu>
            </RenderIf>
            <ApplyButton
              applyButtonCaption={this.props.applyButtonCaption}
              onApplyClick={this.props.onApplyClick}
              inProgress={this.props.isHandleInProgress}
            />
            {
              !hideChangeSubscription && <Coupons
                error={this.props.estimationError}
                coupons={this.props.currentSubscription.coupons}
                addCoupons={this.addCoupons}
                placeHolder={'Enter the promo code'}
              />
            }
            <RenderIf condition={(!!subscription || !!billingEstimation)}>
              <PaymentDetailsBlock
                subscription={subscription}
                billingEstimation={billingEstimation}
                openDialog={this.props.openDialog}
              />
            </RenderIf>
            <SubscriptionControllButtons
              showCancelSubscriptionButton={showCancelSubscriptionButton}
              onChangePlanClick={this.props.onChangePlanClick}
              onPauseSubscriptionClick={this.props.onPauseSubscriptionClick}
              handleExtendTrial={this.props.handleExtendTrial}
            />
          </Styled.PlanCard>
          <RenderIf condition={!!closeDialog}>
            <Styled.CloseButtonContainer id={METRIC_IDS.closeBillingDialog}>
              <IconButton
                Icon={Icons.CloseSmall}
                onClick={closeDialog}
                width={30}
                height={30}
                iconHeight={10}
              />
            </Styled.CloseButtonContainer>
          </RenderIf>
        </Styled.Container>
        <RenderIf condition={!!subscription || !hideChangeSubscription && !!billingEstimation}>
          <PaymentDetailsDialog />
        </RenderIf>
      </>
    );
  }

  public componentDidMount(): void {
    this.props.getBillingHistory(5, null);
  }

  @autobind
  private onVatNumberChange(vatNumber: string): void {
    this.props.onVatNumberIdChanged(vatNumber);
    this.props.resetVatNumberValidation();
  }

  @autobind
  private addCoupons(coupons: string[]): void {
    this.props.onSubscriptionChange({
      ...this.props.currentSubscription,
      coupons,
    });
  }

  @autobind
  private changePeriod(billingPeriod: BillingPeriodUnit): void {
    this.props.onSubscriptionChange({
      ...this.props.currentSubscription,
      billingPeriod,
    });
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    billingCollection: state.account.billingCollection,
    subscription: AccountSelectors.currentSubscription(state),
    billingEstimation: state.account.billingEstimation,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getBillingHistory: (limit, offset) => dispatch(PaymentMethodActions.getBillingHistory({ limit, offset })),
    resetVatNumberValidation: () => dispatch(AccountActions.setSubscribeError(ValidationField.VatNumber, '')),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
}

export const SubscriptionDetails = connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetailsComponent);
