import React, { useEffect } from 'react';
import { useGlobalKeyboardEvents } from './use-global-keyboard-events';

export const GlobalKeyboardEventsFocusRestore: React.FC = ({ children }) => {
  const events = useGlobalKeyboardEvents();
  useEffect(() => {
    return () => {
      if (events) {
        events.focus();
      }
    };
  }, [ events ]);
  return <>{children}</>;
};


