import { ModalWrapper } from '@kreo/kreo-ui-components';
import { push } from 'connected-react-router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { DialogWrapper } from 'common/UIKit/dialogs/dialog-wrapper';
import { pushSearch } from '../../../../actions/common';
import { SUBSCRIPTION_EDIT_FORM_STATE } from '../../../../reducers/account';
import { AppUrls } from '../../../../routes/app-urls';
import { AccountActions } from '../../../account/actions/creators';
import { SubscriptionEditor } from './subscription-editor';

export const SUBSCRIPTION__DIALOG = 'SUBSCRIPTION__DIALOG';


export const SubscriptionDialog: React.FC = () => {
  const dispatch = useDispatch();
  const isDialogOpen = useSelector<State, boolean>((state) => SUBSCRIPTION__DIALOG in state.dialog);
  const isBillingDetails = useSelector<State, boolean>(s => Boolean(s.router.location.query.billingDetails));

  const close = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(SUBSCRIPTION__DIALOG));
    dispatch(AccountActions.setSubscriptionEditFormState(SUBSCRIPTION_EDIT_FORM_STATE));
    dispatch(pushSearch({ billingDetails: undefined }));
  }, [dispatch]);

  const onSubscriptionCancel = useCallback(() => {
    close();
    dispatch(push(AppUrls.products.url()));
  }, [close, dispatch]);

  useEffect(() => {
    if (isDialogOpen) {
      dispatch(pushSearch({  billingDetails: 'true' }));
    }
  }, [dispatch, isDialogOpen]);

  useEffect(() => {
    if (isBillingDetails) {
      dispatch(KreoDialogActions.openDialog(SUBSCRIPTION__DIALOG));
    }
  }, [dispatch, isBillingDetails]);

  return (
    <DialogWrapper name={SUBSCRIPTION__DIALOG}>
      <ModalWrapper onExit={close}>
        <SubscriptionEditor
          onSubscriptionUpdate={close}
          onSubscriptionCancel={onSubscriptionCancel}
        />
      </ModalWrapper>
    </DialogWrapper>
  );
};
