import React from 'react';
import { PaymentMethodInfo } from '../interfaces';
import { Body } from './body';
import { Header } from './header';
import { Styled } from './styled';

interface Props {
  selectedPaymentId: string;
  paymentMethods: PaymentMethodInfo[];
  onSelectPayment: (paymentId: string) => void;
  openManagePaymentsDialog: () => void;
}

export const Component: React.FC<Props> = (
  { selectedPaymentId, paymentMethods, openManagePaymentsDialog, onSelectPayment },
) => {
  return (
    <Styled.Container>
      <Header openManagePaymentsDialog={openManagePaymentsDialog}/>
      <Body
        selectedPaymentId={selectedPaymentId}
        paymentMethods={paymentMethods}
        onSelectPayment={onSelectPayment}
      />
    </Styled.Container>
  );
};

export const Manager = React.memo(Component);
