import { FooterValue, NumberColumnDef, StringColumnDef } from '../../../constants';
import { GROUP_HIERARCHY_PREFIX, getColumn as getGroupHierarchyColumn } from '../../extends-by-group';
import { Column, ColumnType, PropertyColumn } from '../interfaces';
import { DefaultColumnColdIdConstants } from './constants';

const groupOrder = {
  defaultColumn: 1,
  propertyColumn: 2,
  groupHierarchy: 3,
  breakDownColumn: 4,
};

const defaultColumnDef: Record<string, Column> = {
  itemName: {
    ...StringColumnDef,
    field: DefaultColumnColdIdConstants.ITEM_NAME,
    colId: DefaultColumnColdIdConstants.ITEM_NAME,
    headerName: 'Item name',
    checkboxSelection: (params) => !params.columnApi.getRowGroupColumns().length,
    headerCheckboxSelection: (params) => !params.columnApi.getRowGroupColumns().length,
    valueGetter: (params) => {
      const value = params.data?.itemName;
      if (params.columnApi.getRowGroupColumns().length || value) {
        return value;
      } else {
        return FooterValue;
      }
    },
  },
  assemblyName: {
    field: DefaultColumnColdIdConstants.ASSEMBLY_NAME,
    colId: DefaultColumnColdIdConstants.ASSEMBLY_NAME,
    headerName: 'Assembly name',
    ...StringColumnDef,
  },
  fileName: {
    field: DefaultColumnColdIdConstants.FILE_NAME,
    colId: DefaultColumnColdIdConstants.FILE_NAME,
    headerName: `File name`,
    ...StringColumnDef,
  },
  pageName: {
    field: DefaultColumnColdIdConstants.PAGE_NAME,
    colId: DefaultColumnColdIdConstants.PAGE_NAME,
    headerName: `Page name`,
    ...StringColumnDef,
  },
  parentGroup: {
    field: DefaultColumnColdIdConstants.PARENT_GROUP,
    colId: DefaultColumnColdIdConstants.PARENT_GROUP,
    headerName: 'Parent group',
    ...StringColumnDef,
  },
  measureName: {
    field: DefaultColumnColdIdConstants.MEASURE_NAME,
    colId: DefaultColumnColdIdConstants.MEASURE_NAME,
    headerName: 'Measurement name',
    ...StringColumnDef,
  },
};

export const defaultColumSet: Set<string> = new Set([
  DefaultColumnColdIdConstants.ITEM_NAME,
  DefaultColumnColdIdConstants.ASSEMBLY_NAME,
  DefaultColumnColdIdConstants.FILE_NAME,
  DefaultColumnColdIdConstants.PAGE_NAME,
  DefaultColumnColdIdConstants.PARENT_GROUP,
  DefaultColumnColdIdConstants.MEASURE_NAME,
]);

export const defaultColumnOrder = {
  [DefaultColumnColdIdConstants.ITEM_NAME]: 1,
  [DefaultColumnColdIdConstants.ASSEMBLY_NAME]: 2,
  [DefaultColumnColdIdConstants.FILE_NAME]: 3,
  [DefaultColumnColdIdConstants.PAGE_NAME]: 4,
  [DefaultColumnColdIdConstants.PARENT_GROUP]: 5,
  [DefaultColumnColdIdConstants.MEASURE_NAME]: 6,
};

const innerField: Set<string> = new Set([
  'id',
  'measureId',
  'fileId',
  'pageId',
  'parentGroupId',
]);

const getDefaultColDef = (field: string): Column => {
  return defaultColumnDef[field];
};

const getCommonColDef = (field: string): Column => {
  return ({
    field,
    colId: field,
    headerName: field,
    ...StringColumnDef,
  });
};

const getPropertyColumn = (propertyColumn: PropertyColumn): Column => {
  const { colDef: def, colType: type } = propertyColumn;

  if (type === ColumnType.String) {
    return def;
  }
  return {
    ...def,
    ...NumberColumnDef,
    enableValue: true,
  };
};

const getGroupColumn = (field: string): Column => {
  const index = Number(field.replace(GROUP_HIERARCHY_PREFIX, ''));
  return getGroupHierarchyColumn(index);
};

export const getColDef = (
  field: string,
  propertyColumns: Record<string, PropertyColumn>,
  breakDownPropertyColumns: Record<string, Column>,
): Column => {
  if (innerField.has(field)) {
    return null;
  }
  if (defaultColumSet.has(field)) {
    const colDef = getDefaultColDef(field);
    colDef.orderGroup = groupOrder.defaultColumn;
    return colDef;
  }
  if (propertyColumns[field]) {
    const colDef = getPropertyColumn(propertyColumns[field]);
    colDef.orderGroup = groupOrder.propertyColumn;
    return colDef;
  }
  if (field.startsWith(GROUP_HIERARCHY_PREFIX)) {
    const colDef = getGroupColumn(field);
    colDef.orderGroup = groupOrder.groupHierarchy;
    return colDef;
  }
  if (breakDownPropertyColumns[field]) {
    const colDef = breakDownPropertyColumns[field];
    colDef.orderGroup = groupOrder.breakDownColumn;
    return colDef;
  }
  return getCommonColDef(field);
};
