import { useCallback } from 'react';

import { useActionDispatch } from 'common/hooks';
import { CommentaryTargetType } from 'unit-2d-comments/enums';
import {
  CommentaryDrawingWithRectTarget,
  CommentaryDrawingTarget,
  CommentaryDrawingWithPointTarget,
} from 'unit-2d-comments/interfaces';
import { TwoDCommentsActions } from 'unit-2d-comments/store-slice';
import { StartCreateCommentPayload } from '../../drawings-geometry/interfaces';
import { useCurrentDrawingInfo } from '../../hooks';
import { DrawingContextMenuPosition } from '../../interfaces';

export function useAddComment(): (position: DrawingContextMenuPosition) => void {
  const startAddComment = useActionDispatch(TwoDCommentsActions.startAddComment);
  const currentDrawing = useCurrentDrawingInfo();
  return useCallback((position: DrawingContextMenuPosition) => {
    const point = position?.drawingPoint;
    if (point) {
      startAddComment({
        type: CommentaryTargetType.DrawingWithPoint,
        pageId: currentDrawing?.drawingId,
        x: point.x,
        y: point.y,
      } as CommentaryDrawingWithPointTarget);
    } else {
      startAddComment({
        type: CommentaryTargetType.Drawing,
        pageId: currentDrawing?.drawingId,
      } as CommentaryDrawingTarget);
    }
  }, [currentDrawing?.drawingId, startAddComment]);
}


export function useAddCommentFromDrawing(): (payload: StartCreateCommentPayload) => void {
  const startAddComment = useActionDispatch(TwoDCommentsActions.startAddComment);
  const currentDrawing = useCurrentDrawingInfo();
  return useCallback((payload: StartCreateCommentPayload) => {
    if (payload.endPoint) {
      startAddComment({
        type: CommentaryTargetType.DrawingWithRect,
        pageId: currentDrawing?.drawingId,
        x1: payload.startPoint[0],
        y1: payload.startPoint[1],
        x2: payload.endPoint[0],
        y2: payload.endPoint[1],
      } as CommentaryDrawingWithRectTarget);
    } else {
      startAddComment({
        type: CommentaryTargetType.DrawingWithPoint,
        pageId: currentDrawing?.drawingId,
        x: payload.startPoint[0],
        y: payload.startPoint[1],
      } as CommentaryDrawingWithPointTarget);
    }
  }, [currentDrawing?.drawingId, startAddComment]);
}

