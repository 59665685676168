import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import * as React from 'react';

import { Text } from 'common/components/text';
import { Styled } from '../../styled';


interface Props {
  addUserButtonClick: () => void;
}

export const EmptySeat = React.memo<Props>(({ addUserButtonClick }) => {
  return (
    <Styled.User>
      <Styled.MoreUserAvatar>
        <Icons.Username />
      </Styled.MoreUserAvatar>
      <Styled.UserTextInfo>
        <Text>Free seat</Text>
      </Styled.UserTextInfo>
      <Styled.AddUserButton>
        <RectangleButton
          text={'Add user'}
          Icon={Icons.PlusSmall}
          mood="secondary"
          height={30}
          iconHeight={10}
          fontSize={12}
          onClick={addUserButtonClick} />
      </Styled.AddUserButton>
    </Styled.User>
  );
});
