import { useCallback } from 'react';
import { NoScaleDescriptionType } from '../dialogs';
import { useShowCalibrationDialogIfNeed } from './calibration/use-show-dialog-if-need';

export function useTurnOnOneClickLineEffect(): (skip: () => void) => void {
  const showCalibrationIfNeeded = useShowCalibrationDialogIfNeed();

  return useCallback(
    (skip: () => void) => {
      showCalibrationIfNeeded(skip, NoScaleDescriptionType.OneClickLine);
    },
    [showCalibrationIfNeeded],
  );
}
