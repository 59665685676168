import { ClickOutsideWrapper, Constants, Icons, MenuItem, Text, ThemeSwitcher } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { AbilityAwareProps, withAbilityContext } from 'common/ability/with-ability-context';
import { RESTRICT_AVATAR_DIALOG_NAME } from 'common/components/image-uploader';
import { THEMES_DIALOG_NAME } from 'common/components/page-header-avatar';
import { ColorsName } from 'common/enums/kreo-colors';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ThemeType } from '../../../../components/theme-provider/interfaces';
import { AppUrls } from '../../../../routes/app-urls';
import { ProfileDialogName } from '../../../../units/account/components/profile-dialog';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { KeyCloakService } from '../../../../units/account/keycloak';
import { AccountSelectors } from '../../../../units/account/selectors';
import { PersistedStorageActions } from '../../../../units/persisted-storage/actions/creators';
import { SUBSCRIPTION__DIALOG } from '../../../../units/subscription/components/subscription-dialog';
import { UPGRADE_SUBSCRIPTION_DIAGLOG } from '../../../../units/subscription/components/upgrade-subscription-dialog';
import { SubscriptionStatus } from '../../../../units/subscription/enums/subscription-status';
import { AvatarEditor } from './avatar-editor';
import { Styled } from './styled';

interface ReduxActions {
  openProfileDialog: () => void;
  goTo: (path: string) => void;
  setTheme: (theme: ThemeType) => void;
  openThemesDialog: () => void;
  openSubscriptionDialog: () => void;
  openUpgradeDialog: () => void;
}

interface StateProps {
  nightVersion: boolean;
  subscription: CompanySubscriptionModel;
  isCropAvatarOpened: boolean;
}

interface Props extends ReduxActions, StateProps, AbilityAwareProps {
  userName: string;
  fullName: string;
  hasAvatar: boolean;
  userId: string;
  logOut: () => void;
  onClose: () => void;
  isAdminPage?: boolean;
}

class PageHeaderAvatarMenuComponent extends React.Component<Props> {

  public render(): JSX.Element {
    const isBillingAvailable = this.props.ability.can(Operation.Manage, Subject.Billing)
      && this.props.subscription && this.props.subscription.status === SubscriptionStatus.Valid;

    return (
      <ClickOutsideWrapper onExit={this.onClose}>
        <Styled.Container id='avatar-menu'>
          <AvatarEditor
            hasAvatar={this.props.hasAvatar}
            userId={this.props.userId}
          />
          <Styled.Header>
            <Text textTransform='Capitalize'>{this.props.fullName}</Text>
            <Text
              color={ColorsName.additionalFont}
              fontSize={12}
            >
              {this.props.userName}
            </Text>
          </Styled.Header>
          <Styled.Main>
            <MenuItem
              Icon={Icons.Username}
              text='Account'
              onClick={this.onAccountClick}
              fontSize={14}
              withBorder={true}
            />
            {
              isBillingAvailable && (
                <MenuItem
                  Icon={Icons.Subscribe}
                  text='Billing'
                  onClick={this.onBillingClick}
                  fontSize={14}
                  withBorder={true}
                  controlName={'billing-details'}
                />
              )
            }
            {this.props.ability.can(Operation.Admin, Subject.Application)
              ? (
                <MenuItem
                  Icon={Icons.Gear}
                  text={this.props.isAdminPage ? 'Back to app' : 'Administration'}
                  onClick={this.props.isAdminPage ? this.goBackToApp : this.goAdministration}
                  fontSize={14}
                  withBorder={true}
                />
              )
              : null
            }
            <MenuItem
              Icon={Icons.BrushSmall}
              text='Themes'
              onClick={this.onThemesClick}
              fontSize={14}
              withBorder={true}
            />
            <MenuItem
              Icon={Icons.Exit}
              text='Exit'
              onClick={this.props.logOut}
              fontSize={14}
              withBorder={true}
            />
            <Styled.ThemeSwitcher onClick={this.toggleTheme}>
              <Text color='gray' fontSize={14}>Night Mode</Text>
              <ThemeSwitcher
                name='Night mode'
                checked={this.props.nightVersion}
                nameColor='turquoiseFont'
                size='m'
              />
            </Styled.ThemeSwitcher>
          </Styled.Main>
        </Styled.Container>
      </ClickOutsideWrapper>
    );
  }

  @autobind
  private onClose(): void {
    if (this.props.isCropAvatarOpened) {
      return;
    }
    this.props.onClose();
  }

  @autobind
  private onAccountClick(): void {
    KeyCloakService.toAccount();
  }

  @autobind
  private onBillingClick(): void {
    const { subscription, openSubscriptionDialog, onClose, openUpgradeDialog } = this.props;
    const isFree = AccountSelectors.isFreeSubscription(subscription);
    if (isFree) {
      openUpgradeDialog();
    } else {
      openSubscriptionDialog();
    }
    onClose();
  }

  @autobind
  private onThemesClick(): void {
    this.props.openThemesDialog();
    this.props.onClose();
  }

  @autobind
  private toggleTheme(): void {
    this.props.setTheme(this.props.nightVersion ? 'light' : 'dark');
  }

  @autobind
  private goAdministration(): void {
    this.props.goTo(AppUrls.admin.index.url());
  }

  @autobind
  private goBackToApp(): void {
    this.props.goTo(AppUrls.products.url());
  }
}

function mapStateToProps(state: State): StateProps {
  return {
    nightVersion: state.persistedStorage.theme === Constants.Theme.DARK_THEME.name,
    subscription: AccountSelectors.currentSubscription(state),
    isCropAvatarOpened: RESTRICT_AVATAR_DIALOG_NAME in state.dialog,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): ReduxActions => {
  return {
    openProfileDialog: () => dispatch(KreoDialogActions.openDialog(ProfileDialogName)),
    setTheme: (theme) => dispatch(PersistedStorageActions.setTheme(theme)),
    goTo: path => dispatch(push(path)),
    openThemesDialog: () => dispatch(KreoDialogActions.openDialog(THEMES_DIALOG_NAME)),
    openSubscriptionDialog: () => dispatch(KreoDialogActions.openDialog(SUBSCRIPTION__DIALOG)),
    openUpgradeDialog: () => dispatch(KreoDialogActions.openDialog(UPGRADE_SUBSCRIPTION_DIAGLOG)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export const PageHeaderAvatarMenu = connector(withAbilityContext(PageHeaderAvatarMenuComponent));
