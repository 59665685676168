import produce from 'immer';
import uuid from 'uuid';
import { RequestStatus } from 'common/enums/request-status';
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';

import {
  FormulaBarPayload,
  MoveToPagePayload,
  SetDrawingInstanceInCellRangePayload,
  ToggleViewToolPanelsPayload,
  UpdatePageInfoPayload,
  UpdateViewConfigPayload,
} from '../actions/payloads';
import { TwoDActionTypes } from '../actions/types';
import { Arrangement } from '../constants';
import { TWO_D_INITIAL_STATE } from '../initial-state';
import {
  AddNewSheetStatuses,
  AssignedPia,
  CellToUpdatePayload,
  PiaCalculated,
  ReportPage,
  ReportPageType,
  TablePreset,
  TwoDReduxState,
  UpdateFocusedCell,
} from '../interfaces';

export const twoDReducerMethods: ReducerMethods<TwoDReduxState> = {
  [TwoDActionTypes.FOCUS_CELL]: (state, payload: UpdateFocusedCell) => {
    return new MonoliteHelper(state)
      .set(_ => _.focusedCell.cell, payload.data)
      .set(_ => _.selectedSheetId, payload.sheetId)
      .set(_ => _.isShowTwoDReport, true)
      .get();
  },
  [TwoDActionTypes.SET_READ]: (state, payload: boolean) => {
    return new MonoliteHelper(state).set(_ => _.focusedCell.isCanRead, payload).get();
  },
  [TwoDActionTypes.SET_WRITE]: (state, payload: boolean) => {
    return new MonoliteHelper(state).set(_ => _.focusedCell.isCanWrite, payload).get();
  },
  [TwoDActionTypes.UPDATE_DRAWINGS_TO_CELL]: (state, payload: Record<string, string[]>) => {
    const helper = new MonoliteHelper(state);

    for (const [key, value] of Object.entries(payload)) {
      helper.set(
        _ => _.drawingInstanceToCell[key],
        value,
      );
    }

    return helper.get();
  },
  [TwoDActionTypes.UPDATE_DYNAMIC_GROUPS_TO_CELL]: (state, payload: Record<string, string[]>) => {
    const helper = new MonoliteHelper(state);

    for (const [key, value] of Object.entries(payload)) {
      helper.set(
        _ => _.dynamicGroupsToCell[key],
        value,
      );
    }

    return helper.get();
  },
  [TwoDActionTypes.UPDATE_CELL_TO_CELL]: (state, payload: Record<string, string[]>) => {
    const helper = new MonoliteHelper(state);
    const cellToCell = { ...state.cellToCell, ...payload };
    helper.set(_ => _.cellToCell, cellToCell);
    return helper.get();
  },
  [TwoDActionTypes.CLEAR_CELL_TO_UPDATE]: (state) => {
    return new MonoliteHelper(state).set(_ => _.cellToUpdate, []).get();
  },
  [TwoDActionTypes.SET_CELL_TO_UPDATE]: (state, payload: CellToUpdatePayload[]) => {
    return new MonoliteHelper(state).set(_ => _.cellToUpdate, payload).get();
  },
  [TwoDActionTypes.SET_PAGES]: (state, payload: ReportPage[]) => {
    const reportPageSessionIdMap = {};

    payload.forEach((page) => {
      if (page.type === ReportPageType.SpreadSheet) {
        reportPageSessionIdMap[page.id] = state.reportPageSessionIdMap[page.id]
          ? state.reportPageSessionIdMap[page.id]
          : uuid.v4();
      }
    });
    return new MonoliteHelper(state)
      .set(_ => _.reportPages, payload)
      .set(_ => _.reportPageSessionIdMap, reportPageSessionIdMap)
      .get();
  },
  [TwoDActionTypes.SET_REPORT_PAGE_ETAG]: (state, payload: [string, number]) => {
    const [pageId, etag] = payload;
    return new MonoliteHelper(state)
      .set(_ => _.reportPageEtagMap[pageId], etag)
      .get();
  },
  [TwoDActionTypes.SET_REPORT_SESSION_ID]: (state, payload: [string, string]) => {
    const [ pageId, sessionId ] = payload;
    return new MonoliteHelper(state)
      .set(_ => _.reportPageSessionIdMap[pageId], sessionId)
      .get();
  },
  [TwoDActionTypes.UPDATE_PAGE_INFO]: (state, payload: UpdatePageInfoPayload) => {
    return new MonoliteHelper(state).setFind<ReportPage>(
      _ => _.reportPages,
      p => p.id === payload.data.id,
      () => payload.data,
    ).get();
  },
  [TwoDActionTypes.MOVE_TO_PAGE]: (state, payload: MoveToPagePayload) => {
    const { pageId, offset } = payload;
    const pageIndex = state.reportPages.findIndex(item => item.id === pageId);
    const newReportPages = state.reportPages.slice();
    const removedPage = newReportPages.splice(pageIndex, 1);
    newReportPages.splice(pageIndex + offset, 0, removedPage[0]);
    return new MonoliteHelper(state).set(_ => _.reportPages, newReportPages).get();
  },
  [TwoDActionTypes.SET_NEW_PAGE]: (state, payload: ReportPage) => {
    const sessionId = uuid.v4();
    return new MonoliteHelper(state)
      .setAppend(_ => _.reportPages, payload)
      .set(_ => _.reportPageSessionIdMap[payload.id], sessionId)
      .get();
  },
  [TwoDActionTypes.SET_SELECTED_SHEET_ID]: (state, payload: string) => {
    return new MonoliteHelper(state).set(_ => _.selectedSheetId, payload).get();
  },
  [TwoDActionTypes.SET_SELECTED_VIEW]: (state, payload: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.selectedSheetId, payload)
      .set(_ => _.isShowTwoDReport, false)
      .get();
  },
  [TwoDActionTypes.SET_IS_GET_PAGE_DATA]: (state, payload: boolean) => {
    return new MonoliteHelper(state)
      .set(_ => _.isGetPageData, payload)
      .get();
  },
  [TwoDActionTypes.DELETE_PAGE]: (state, payload: string) => {
    const helper = new MonoliteHelper(state);

    helper.setFilter(_ => _.reportPages, r => r.id !== payload);
    helper.setRecordValue(
      _ => _.drawingInstanceToCell,
      (_, cells) => cells.filter(cell => !cell.includes(payload)),
    );
    helper.setRecordValue(
      _ => _.cellToCell,
      (_, cells) => cells.filter(cell => !cell.includes(payload)),
    );

    return helper.get();
  },
  [TwoDActionTypes.SET_SHOW_SHEETS_IDS]: (state, payload: string[]) => {
    const showSheetIds = payload.length ? payload : [state.reportPages[0].id];
    return new MonoliteHelper(state).set(_ => _.showSheetIds, showSheetIds).get();
  },
  [TwoDActionTypes.SHOW_TRACE_LINK]: (state, payload: string) => {
    return new MonoliteHelper(state).set(_ => _.traceLink, payload).get();
  },
  [TwoDActionTypes.HIDE_TRACE_LINK]: (state) => {
    return new MonoliteHelper(state).set(_ => _.traceLink, '').get();
  },
  [TwoDActionTypes.SET_CELL_WITH_BORDER]: (state, cellId: string) => {
    return new MonoliteHelper(state).set(_ => _.cellWithBorder, cellId).get();
  },
  [TwoDActionTypes.SET_FORMULA_BAR]: (state, payload: FormulaBarPayload) => {
    return new MonoliteHelper(state).set(_ => _.formulaBar, _ => ({
      value: payload.value === undefined ? _.value : payload.value,
      isFocused: payload.isFocused === undefined ? _.isFocused : payload.isFocused,
    })).get();
  },
  [TwoDActionTypes.DROP_STATE]: (state) => {
    return new MonoliteHelper(state).set(_ => _, TWO_D_INITIAL_STATE).get();
  },
  [TwoDActionTypes.SET_DRAWING_INSTANCE_IN_CELL_RANGE]: (state, payload: SetDrawingInstanceInCellRangePayload) => {
    return new MonoliteHelper(state).set(_ => _.drawingInstanceInCellRange, payload.ranges).get();
  },
  [TwoDActionTypes.SET_EDIT_FULL_CELL_ID]: (state, cellId: string) => {
    return new MonoliteHelper(state).set(_ => _.editFullCellId, _ => cellId).get();
  },
  [TwoDActionTypes.SET_TWOD_TABLE_VIEW_TYPE]: (state, payload: Arrangement) => {
    return new MonoliteHelper(state).set(_ => _.tableViewType, payload).get();
  },
  [TwoDActionTypes.SET_FILTERED_NODE_IDS]: (state, payload: Record<string, boolean>) => {
    return new MonoliteHelper(state)
      .set(_ => _.filteredNodeIds, payload)
      .get();
  },
  [TwoDActionTypes.SET_QUICK_SEARCH_VALUE]: (state, payload: string) => {
    return new MonoliteHelper(state)
      .set(_ => _.quickSearchValue, payload)
      .get();
  },
  [TwoDActionTypes.SET_ADD_NEW_SHEET_STATUS]: (state, payload: AddNewSheetStatuses) => {
    return new MonoliteHelper(state)
      .set(_ => _.addNewSheetStatus, payload)
      .get();
  },
  [TwoDActionTypes.SET_TABLE_PRESETS]: (state, payload: TablePreset[]) => {
    return new MonoliteHelper(state)
      .set(_ => _.presets, payload)
      .setSort(
        _ => _.presets,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .get();
  },
  [TwoDActionTypes.CREATE_TABLE_PRESET]: (state, payload: TablePreset) => {
    return new MonoliteHelper(state)
      .setAppend(_ => _.presets, payload)
      .setSort(
        _ => _.presets,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .get();
  },
  [TwoDActionTypes.UPDATE_TABLE_PRESET]: (state, payload: TablePreset) => {
    return new MonoliteHelper(state)
      .setFind(
        _ => _.presets,
        _ => _.id === payload.id,
        () => payload,
      )
      .setSort(
        _ => _.presets,
        (item1, item2) => item1.name > item2.name ? 1 : -1,
      )
      .get();
  },
  [TwoDActionTypes.DELETE_TABLE_PRESET]: (state, payload: string) => {
    return new MonoliteHelper(state)
      .setFilter(_ => _.presets, _ => _.id !== payload)
      .get();
  },
  [TwoDActionTypes.SET_SELECTED_ASSEMBLIES_IDS]: (state, ids: string[]) => {
    const selectedIds = {};
    ids.forEach(id => selectedIds[id] = true);
    return new MonoliteHelper(state)
      .set(_ => _.piaMeasurePanel.selectedAssembliesIds, selectedIds)
      .get();
  },
  [TwoDActionTypes.SET_SELECTED_ITEMS_IDS]: (state, ids: string[]) => {
    const selectedIds = {};
    ids.forEach(id => selectedIds[id] = true);
    return new MonoliteHelper(state)
      .set(_ => _.piaMeasurePanel.selectedItemsIds, selectedIds)
      .get();
  },
  [TwoDActionTypes.SET_OPEN_MEASURE_SELECT_TABLE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.piaMeasurePanel.isOpenSelectTable, true)
      .get();
  },
  [TwoDActionTypes.SET_CLOSE_MEASURE_SELECT_TABLE]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.piaMeasurePanel.isOpenSelectTable, false)
      .get();
  },
  [TwoDActionTypes.SET_CALCULATED_PIA]: (state, payload: Record<string, PiaCalculated>) => {
    return new MonoliteHelper(state).set(_ => _.calculatedPia, _ => ({ ..._, ...payload })).get();
  },
  [TwoDActionTypes.SET_INSTANCES_ASSIGN_PIA]: (state, payload: Record<string, AssignedPia>) => {
    return new MonoliteHelper(state).set(_ => _.assignPia, _ => ({ ..._, ...payload })).get();
  },
  [TwoDActionTypes.SET_IS_SKIP_CALC_PIA]: (state, payload: boolean) => {
    return new MonoliteHelper(state).set(_ => _.isSkipCalcPia, payload).get();
  },
  [TwoDActionTypes.FETCH_ASSIGN_PIA]: (state): TwoDReduxState => {
    return new MonoliteHelper(state)
      .set(_ => _.fetchAssignPiaStatus, RequestStatus.Loading)
      .get();
  },
  [TwoDActionTypes.SET_ASSIGN_PIA]: (state, payload: Record<string, AssignedPia>) => {
    return produce(state, s => {
      s.assignPia = payload;
      s.fetchAssignPiaStatus = RequestStatus.Loaded;
    });
  },
  [TwoDActionTypes.SET_IS_ASSIGN_PIA_LOADING]: (state, payload: boolean) => {
    return new MonoliteHelper(state)
      .set(_ => _.isAssignPiaLoading, payload)
      .get();
  },
  [TwoDActionTypes.SET_MEASURE_VIEW_CONFIGURATION]: (state, { tableId, configuration }: UpdateViewConfigPayload) => {
    return new MonoliteHelper(state)
      .set(_ => _.viewsConfigs[tableId], configuration)
      .get();
  },
  [TwoDActionTypes.REMOVE_ASSIGN_PIA]: (state, instancesIds) => {
    const assignPiaHelper = { ...state.assignPia };
    for (const instanceId of instancesIds) {
      delete assignPiaHelper[instanceId];
    }
    return new MonoliteHelper(state)
      .set(_ => _.assignPia, assignPiaHelper)
      .get();
  },
  [TwoDActionTypes.TOGGLE_VIEW_TOOL_PANELS]: (state, { tableId, toolPanelName }: ToggleViewToolPanelsPayload) => {
    return new MonoliteHelper(state).set(_ => _.viewToolPanels[tableId], toolPanelName).get();
  },
  [TwoDActionTypes.SET_ASSIGN_PIA_READY]: (state) => {
    return new MonoliteHelper(state).set(_ => _.isAssignPiaReady, true).get();
  },
  [TwoDActionTypes.SET_ASSIGN_PIA_LOAD]: (state) => {
    return new MonoliteHelper(state).set(_ => _.isAssignPiaReady, false).get();
  },
  [TwoDActionTypes.SET_EXPORT_REPORT_READY]: (state, iterationId: string) => {
    if (state.isExportReportReady === iterationId) {
      return new MonoliteHelper(state).set(_ => _.isExportReportReady, '').get();
    } else {
      return state;
    }
  },
  [TwoDActionTypes.SET_EXPORT_REPORT_LOAD]: (state, iterationId: string) => {
    return new MonoliteHelper(state).set(_ => _.isExportReportReady, iterationId).get();
  },
  [TwoDActionTypes.SET_VIEW_MEASURE_ID]: (state, payload: Record<string, boolean>) => {
    return new MonoliteHelper(state).set(_ => _.usedViewMeasureId, payload).get();
  },
  [TwoDActionTypes.SET_SELECTED_MEASURE_ID_VIEW]: (state, payload: string[]) => {
    const map = {};
    payload.forEach(id => {
      map[id] = true;
    });
    return new MonoliteHelper(state).set(_ => _.selectedMeasureIdView, map).get();
  },
  [TwoDActionTypes.AFTER_COPY_MEASURES]: (state, payload: string[]) => {
    const assignMap = {};
    for (const id of payload) {
      assignMap[id] = state.assignPia[id];
    }

    return new MonoliteHelper(state)
      .set(s => s.assignPiaBuffer, assignMap)
      .get();
  },
  [TwoDActionTypes.TOGGLE_SHOW_CODE]: (state, payload: string) => {
    return new MonoliteHelper(state).set(
      _ => _.viewsConfigs[payload].createCodeColumn,
      createCodeColumn => !createCodeColumn,
    ).get();
  },
};
