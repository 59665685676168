import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { selectWrapper } from 'common/utils/saga-wrappers';
import { analyticEvent, MetricNames } from 'utils/posthog';
import { PdfFilterActions } from '../actions/creators/pdf-filter';
import { PdfFilterBasePayload, PdfFilterRectPayload, PdfFilterSwitchPayload } from '../actions/payloads/pdf-filter';
import { PdfFilterActionTypes } from '../actions/types/pdf-filter';
import { DrawingsFilterApi } from '../api/drawings-filter-api';
import { DrawingsState } from '../interfaces';
import { PdfPageFilterResponse } from '../interfaces/api-responses/pdf-filter';
import { WizzardUtils } from '../utils';

function* switchPdfFilter({ payload }: ActionWith<PdfFilterSwitchPayload>): SagaIterator {
  try {
    const { fileId, pageId, value } = payload;
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Loading));
    const applyFilterResult = yield call(DrawingsFilterApi.switchFilter, fileId, pageId, value);
    yield put(PdfFilterActions.saveFilterResult(pageId, applyFilterResult));
    yield put(PdfFilterActions.setFilterApplyStatus(pageId, RequestStatus.Loaded));
  } catch (e) {
    console.error('switchPdfFilter error', e);
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Failed));
  }
}

function* hidePdfBackground({ payload }: ActionWith<PdfFilterBasePayload>): SagaIterator {
  try {
    const { fileId, pageId } = payload;
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Loading));
    const applyFilterResult = yield call(DrawingsFilterApi.hideBacground, fileId, pageId);
    yield put(PdfFilterActions.saveFilterResult(pageId, applyFilterResult));
    yield put(PdfFilterActions.setFilterApplyStatus(pageId, RequestStatus.Loaded));
    yield call(
      sendAnalitics,
      { pageId, fileId, source: 'hide background', hiddenCount: applyFilterResult.state?.uselessElementStylesCount },
    );
  } catch (e) {
    console.error('hidePdfBackground error', e);
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Failed));
  }
}

interface SendAnaliticsPayload {
  pageId: string;
  fileId: string;
  source: string;
  hiddenCount: number;
}

function* sendAnalitics({ pageId, fileId, source, hiddenCount }: SendAnaliticsPayload): SagaIterator {
  const state: DrawingsState = yield selectWrapper(s => s.drawings);
  const drawing = state.drawingsInfo[pageId];
  analyticEvent(MetricNames.pdfFilter.applyFilter, {
    pageName: drawing.name,
    pageNumber: drawing.pageNumber,
    fileName: state.files.entities[fileId].properties.name,
    source,
    hiddenCount,
  });
}

function * createEmptyPdfFilter({ payload }: ActionWith<PdfFilterBasePayload>): SagaIterator {
  try {
    const { fileId, pageId } = payload;
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Loading));
    const applyFilterResult = yield call(DrawingsFilterApi.createEmptyFilter, fileId, pageId);
    yield put(PdfFilterActions.saveFilterResult(pageId, applyFilterResult));
    yield put(PdfFilterActions.setFilterApplyStatus(pageId, RequestStatus.Loaded));
    if (payload.afterCreate) {
      payload.afterCreate();
    }
  } catch (e) {
    console.error('createEmptyPdfFilter error', e);
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Failed));
  }
}

function * addToPdfFilter({ payload }: ActionWith<PdfFilterRectPayload>): SagaIterator {
  try {
    const { fileId, pageId, rect } = payload;
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Loading));
    const convertedRect = WizzardUtils.getPdfRect(rect);
    convertedRect.fullyInside = false;
    const applyFilterResult: PdfPageFilterResponse = yield call(
      DrawingsFilterApi.addToFilter,
      fileId,
      pageId,
      convertedRect,
    );
    yield put(PdfFilterActions.saveFilterResult(pageId, applyFilterResult));
    yield put(PdfFilterActions.setFilterApplyStatus(pageId, RequestStatus.Loaded));
    yield call(
      sendAnalitics,
      { pageId, fileId, source: 'add to filter', hiddenCount: applyFilterResult.state?.uselessElementStylesCount },
    );
  } catch (e) {
    console.error('addToPdfFilter error', e);
    yield put(PdfFilterActions.setFilterApplyStatus(payload.pageId, RequestStatus.Failed));
  }
}

export function* pdfFilteresSaga(): SagaIterator {
  yield takeLatest(PdfFilterActionTypes.SWITCH, switchPdfFilter);
  yield takeLatest(PdfFilterActionTypes.HIDE_BACKGROUND, hidePdfBackground);
  yield takeLatest(PdfFilterActionTypes.CREATE_EMPTY, createEmptyPdfFilter);
  yield takeLatest(PdfFilterActionTypes.ADD_RECT, addToPdfFilter);
}
