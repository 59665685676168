export function getValidInputValueNumber(value: string): number | ''  {
  if (value === '') {
    return '';
  }

  const newValue = Number(value);
  const integerValue = Math.trunc(newValue);

  if (integerValue.toString().length <= 10 && (value.split('.')[1] || '').length <= 3) {
    return newValue;
  } else {
    return null;
  }
}

export const isValidInputValue = (value: string): boolean => {
  const regex = /^-?\d{0,10}(\.\d{0,3})?$/;

  const negativeCount = value.split('-').length - 1;
  const negativePosition = value.indexOf('-');

  // Minus sign at the beginning of the line and only one
  return (
    regex.test(value) &&
    (negativeCount <= 1) &&
    (negativeCount === 0 || negativePosition === 0)
  );
};

export function decimalCounter(value: string): number  {
  return value.toString().includes('.') ? value.toString().split('.').pop().length : 0;
}
