import styled from 'styled-components';

import { PageHeaderStyled } from 'common/components/page-header';
import { KreoColors } from 'common/enums/kreo-colors';
import { MenuSelectProductStyled } from '../../../../units/2d-navigation/menu-select-product';

const HEADER_HEIGHT = 60;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.color.background};

  *::-webkit-scrollbar-thumb {
    background: ${p => p.theme.color.disabled};
  }

  *::-webkit-scrollbar-thumb:hover {
    background: ${p => p.theme.color.gray};
  }
`;

const Header = styled.div`
  height: ${HEADER_HEIGHT}px;

  ${MenuSelectProductStyled.Container} {
    left: 10px;
    top: 80px;
  }

  ${PageHeaderStyled.Container} {
    height: ${HEADER_HEIGHT}px;
    > a {
      padding: 10px 20px;
    }
  }

  ${PageHeaderStyled.UserMenu} {
    height: ${HEADER_HEIGHT}px;
  }
`;

const Main = styled.div`
  box-sizing: border-box;
  overflow: overlay;
  height: calc(100% - ${HEADER_HEIGHT}px);
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  box-sizing: border-box;
  width: 720px;
  margin: auto;
  padding: 50px;
  border-radius: 40px;
  background-color: ${props => props.theme.color.backgroundRush};

  > p {
    margin: 0 80px;
    text-align: center;
  }

  button {
    margin: 0 auto;
    margin-top: 60px;
  }
`;

const ReviewCard = styled.div`
  width: 570px;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 40px;
  margin: auto;
  background-color: ${props => props.theme.color.backgroundRush};
`;

const ErrorMessage = styled.p`
  padding-left: 115px;
  p {
    color: ${KreoColors.red};
  }
`;

const DialogContainer = styled.div`
  background-color: red;
  button {
    width: 170px;
  }
`;

const DetailBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsButton = styled.div`
`;

export const Styled = {
  Container,
  Header,
  Main,
  Message,
  ReviewCard,
  ErrorMessage,
  DialogContainer,
  DetailBlock,
  DetailsButton,
};
