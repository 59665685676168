import { Assembly, Group, Item, ItemOverride } from 'unit-2d-database/interfaces';

export const getFoldersToRemove = (
  folders: Group[],
  groupIds: string[],
): { groupsToDelete: Set<string>, filteredGroups: Group[] } => {
  const groupsToDelete = new Set<string>(groupIds);
  let filteredGroups = folders.slice();
  let hasNewIds: boolean = true;
  while (hasNewIds) {
    hasNewIds = false;
    const groups = [];
    for (const group of filteredGroups) {
      if (groupsToDelete.has(group.id) || groupsToDelete.has(group.parentFolderId)) {
        groupsToDelete.add(group.id);
        hasNewIds = true;
      } else {
        groups.push(group);
      }
    }
    filteredGroups = groups;
  }
  return { groupsToDelete, filteredGroups };
};

export const filterItemsByRemovedItemGroups = (
  items: Item[],
  groupsToDelete: Set<string>,
): { items: Item[], removedItemsIds: Set<string> } => {
  const removedItemsIds = new Set<string>();
  const filteredItems = [];
  for (const item of items) {
    if (groupsToDelete.has(item.folderId)) {
      removedItemsIds.add(item.id);
    } else {
      filteredItems.push(item);
    }
  }
  return {
    items: filteredItems,
    removedItemsIds,
  };
};

export const filterAssemblyByRemovedAssemblyGroups = (
  assemblies: Assembly[],
  groupsToDelete: Set<string>,
): {
  assemblies: Assembly[],
  removedAssembliesIds: Set<string>,
} => {
  const removedAssembliesIds = new Set<string>();
  const filteredAssemblies: Assembly[] = [];
  assemblies.forEach(a => {
    if (groupsToDelete.has(a.folderId)) {
      removedAssembliesIds.add(a.id);
    } else {
      filteredAssemblies.push(a);
    }
  });
  return {
    assemblies: filteredAssemblies,
    removedAssembliesIds,
  };
};

export const removeItemsFromAssemblies = (
  itemIds: Set<string>,
  assemblies: Assembly[],
): void => {
  for (const assembly of assemblies) {
    assembly.items = assembly.items.filter(x => !itemIds.has(x.baseItemId));
  }
};

export const deleteItemOverride = (
  override: Record<string, ItemOverride[]>,
  itemId: string,
): void => {
  delete override[itemId];
};

export const deleteAssemblyFromItemOverride = (
  override: Record<string, ItemOverride[]>,
  assemblyIds: Set<string>,
): void => {
  Object.keys(override).forEach(itemId => {
    override[itemId] = override[itemId].filter(o => !assemblyIds.has(o.assembly.id));
  });
};
