import { Text } from '@kreo/kreo-ui-components';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RenderIf } from 'common/components/render-if';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { SubscriptionActions } from '../../actions/creators';
import { useTotalInvoice } from '../../hooks';
import { useSubscriptionFormSelector } from './hooks';
import { Styled } from './styled';

export const RENEW_CONFIRM_DIALOG = 'RENEW_CONFIRM_DIALOG';

interface Props {
  afterApply: () => void;
}

export const RenewConfirmDialog: React.FC<Props> = memo(({
  afterApply,
}) => {
  const dispatch = useDispatch();
  const dialogData = useSelector<State, {
    hasChange: boolean,
    subscriptionId: string,
  }>(s => s.dialog[RENEW_CONFIRM_DIALOG]);
  const {
    subscriptionId,
    hasChange,
  } = dialogData
    ? dialogData
    : {
      subscriptionId: '',
      hasChange: false,
    };
  const handleExit = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(RENEW_CONFIRM_DIALOG));
    dispatch(SubscriptionActions.endUpdateSubscription());
  }, [dispatch]);
  const form = useSubscriptionFormSelector();
  const handleRenew = useCallback(() => {
    if (hasChange) {
      dispatch(SubscriptionActions.updateSubscription(subscriptionId, form, afterApply));
    } else {
      dispatch(SubscriptionActions.renewSubscription(subscriptionId));
    }
    afterApply();
  }, [subscriptionId, dispatch, afterApply, form, hasChange]);
  const total = useTotalInvoice();
  useEffect(() => {
    if (total === null && subscriptionId && form.planId && form.billingCountry) {
      dispatch(SubscriptionActions.getUpdateSubscriptionEstimation(
        subscriptionId,
        form,
      ));
    }
  }, [total, form, subscriptionId]);

  return (
    <ConfirmationDialog
      name={RENEW_CONFIRM_DIALOG}
      title='Are you sure you want to renew the subscription?'
      text=''
      cancelButtonText={'No'}
      onCancel={handleExit}
      onConfirm={handleRenew}
      confirmButtonText={'Confirm renewal'}
      zIndex={1003}
      cancelButtonWidth={290}
      submitButtonMood='positive'
      ExtraStyled={Styled.DialogContainer}
    >
      <Styled.DetailBlock>
        <RenderIf condition={total !== null}>
          <Text fontSize={14}>
            <Text fontSize={14} color="additionalFont">
              <pre>{'Immediate invoice: '}£{total}</pre>
            </Text>
          </Text>
        </RenderIf>
        <RenderIf condition={total === null}>
          <Text fontSize={14} color="additionalFont">
            <pre>Calculating invoice...</pre>
          </Text>
        </RenderIf>
      </Styled.DetailBlock>
    </ConfirmationDialog>
  );
});
