import { EventChannel, SagaIterator } from 'redux-saga';
import { call, delay, fork, put, take, takeLatest } from 'redux-saga/effects';
import { RequestStatus } from 'common/enums/request-status';
import { ActionWith } from 'common/interfaces/action-with';
import { arrayUtils } from 'common/utils/array-utils';
import { createSagaEventsChannel, selectWrapper } from 'common/utils/saga-wrappers';
import { MagicSearchActions } from '../actions/creators/magic-search';
import { MagicSearchSaveResultPayload, SetPreviewsPayload } from '../actions/payloads/magic-search';
import { MagicSearchActionTypes } from '../actions/types/magic-search';
import { MagicSearchApi } from '../api/magic-search-api';
import {
  MagicSearchStream,
  MagicSearchStreamRunConfig,
} from '../api/magic-search-requests-controller/magic-search-stream';
import { StreamProgress, StreamedRequestController } from '../api/streams';
import { DrawingsScaleConstant } from '../constants/drawings-scale-constants';
import { WizzardStatus } from '../enums/dropper-state';
import { MagicSearchUtils } from '../helpers/geometry/magic-search';
import { ContourType, DrawingsState, MagicSearchState, ShortPointDescription } from '../interfaces';
import { MagicSearchPayload } from '../interfaces/api-payloads';
import { AISnappingResponse } from '../interfaces/api-responses/ai-snapping';
import { MagicSearchSnappedGeometry } from '../interfaces/api-responses/magic-search-responses';
import { MagicSearchPayloadUtils } from '../utils/magic-search-utils';

interface RunConfig {
  points: ShortPointDescription[];
  pointsInfo: Array<{ positive: boolean }>;
  text: string;
  controller: RequestsController;
}

interface RequestsController {
  run(config: MagicSearchStreamRunConfig): Promise<void>;
  cancel: () => void;
}

interface WatchProps {
  channel: EventChannel<any>;
}

function* watch({ channel }: WatchProps): SagaIterator {
  while (true) {
    const response: StreamProgress<MagicSearchSaveResultPayload> = yield take(channel);
    if (response.value) {
      response.value.status = response.status;
      yield put(MagicSearchActions.saveResult(response.value));
    } else {
      yield put(MagicSearchActions.saveResult({ contours: [], status: WizzardStatus.Error, geometries: [] }));
    }
  }
}

function *run(
  {
    points,
    pointsInfo,
    text,
    controller,
  }: RunConfig,
): SagaIterator {
  try {
    yield put(MagicSearchActions.setStatus(WizzardStatus.Loading));

    const drawingsState: DrawingsState = yield selectWrapper((x) => x.drawings);
    const drawingInfo = drawingsState.currentDrawingInfo;
    const currentProjectId = yield selectWrapper(x => x.projects.currentProject.id);

    const request: MagicSearchPayload = {
      projectId: currentProjectId,
      fileId: drawingInfo.pdfId,
      pageIdx: drawingInfo.pageNumber,
      dpi: drawingsState.magicSearch.dpi,
      scale: drawingInfo.originalCalibrationLineLength / drawingInfo.drawingCalibrationLineLength || 1,
      paperSize: drawingInfo.paperSize || DrawingsScaleConstant.DEFAULT_FORMAT,
      type: 'polygon',
      userScreen: [0, 0, drawingInfo.width, drawingInfo.height],
      points,
      pointsInfo,
      text,
    };

    if (drawingsState.magicSearch.searchArea) {
      request.cropBox = drawingsState.magicSearch.searchArea.reduce((acc, point) => {
        acc[0] = Math.min(acc[0], point[0]);
        acc[1] = Math.min(acc[1], point[1]);
        acc[2] = Math.max(acc[2], point[0]);
        acc[3] = Math.max(acc[3], point[1]);
        return acc;
      }, [Infinity, Infinity, -Infinity, -Infinity]);
    }

    let listener: (input: any) => void;
    const channel = createSagaEventsChannel((emitter) => (listener = emitter));
    yield fork<(props: WatchProps) => SagaIterator>(watch, { channel });
    const { contoursToSearch, negativeContours } = drawingsState.magicSearch;
    yield call(
      controller.run,
      {
        request,
        fileId: drawingInfo.pdfId,
        drawingId: drawingInfo.drawingId,
        onProgress: listener,
        sourceContours: contoursToSearch.concat(negativeContours),
        snappingConfidence: drawingsState.magicSearch.similarity,
      },
    );
  } catch (e) {
    yield put(MagicSearchActions.saveResult({
      contours: [],
      status: WizzardStatus.Error,
    }));
    console.error('magic search saga: run', e);
  }
}

function* runSearch(controller: RequestsController): SagaIterator {
  try {
    yield put(MagicSearchActions.setStatus(WizzardStatus.Loading));

    const drawingsState: DrawingsState = yield selectWrapper((x) => x.drawings);
    const contours: ShortPointDescription[][] = drawingsState.magicSearch.contoursToSearch;
    const negativeCountours: ShortPointDescription[][] = drawingsState.magicSearch.negativeContours;
    const points = MagicSearchPayloadUtils.prepareContoursAndPoints(contours, negativeCountours);
    yield call(run, { ...points, text: '', controller });
  } catch (e) {
    yield put(MagicSearchActions.saveResult({
      contours: [],
      status: WizzardStatus.Error,
    }));
    console.error('magic search saga: run search', e);
  }
}

function *mergeAndShowPreviews(): SagaIterator {
  try {
    const magicSearch: MagicSearchState = yield selectWrapper(x => x.drawings.magicSearch);

    const {
      contours,
      contoursToDelete,
      similarity,
      replacedContours,
      fixContour,
    } = magicSearch;

    let contoursToReplace = replacedContours;

    if (fixContour && fixContour.contourType === ContourType.Source) {
      contoursToReplace = contoursToReplace.concat(fixContour.sources);
    }

    const result: SetPreviewsPayload = yield call(
      MagicSearchUtils.getMergedContours,
      contours,
      contoursToDelete,
      contoursToReplace,
      similarity,
    );
    result.snapped = false;
    yield put(MagicSearchActions.setPreviews(result));
  } catch (e) {
    console.error('magic search saga: merge and show previews:', e);
  }
}

function* saveResultEffect({ payload }: ActionWith<MagicSearchSaveResultPayload>): SagaIterator {
  try {
    if (payload.status === WizzardStatus.Preview && payload.geometries.length) {
      const previewsInfo = payload.geometries.reduce<SetPreviewsPayload>((prev, current, index) => {
        prev.contours.push({
          points: current.value.points,
          holes: current.value.holes || [],
        });
        prev.resultToSources[index] = current.initialIds;
        return prev;
      }, {
        contours: [],
        resultToSources: {},
        previewsToDelete: [],
      });
      yield put(MagicSearchActions.setPreviews(previewsInfo));
    } else {
      yield call(mergeAndShowPreviews);
      if (payload.status === WizzardStatus.Preview) {
        yield put(MagicSearchActions.resnap());
      }
    }
  } catch (e) {
    console.error('magic search saga: save result:', e);
  }
}

function *resnapPreviews(): SagaIterator {
  try {
    yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loading));

    const drawingsState: DrawingsState = yield selectWrapper((x) => x.drawings);
    const drawingInfo = drawingsState.currentDrawingInfo;
    const magicSearch: MagicSearchState = drawingsState.magicSearch;

    const currentContours = magicSearch.previews;
    const lastSnapped = magicSearch.lastSnapped;
    const previewToSource = magicSearch.resultToSources;

    const idComponent = lastSnapped.reduce<Record<number, MagicSearchSnappedGeometry>>((prev, current) => {
      current.initialIds.forEach((id) => {
        prev[id] = current;
      });
      return prev;
    }, {});

    const contoursToUseInSnapping = [];
    const previewsToSave = new Array<MagicSearchSnappedGeometry>();
    currentContours.forEach((_contour, index) => {
      const sourceId = previewToSource[index][0];
      if (
        !idComponent[sourceId]
        || !arrayUtils.areSetsEqual(idComponent[sourceId].initialIds, previewToSource[index])
      ) {
        arrayUtils.extendArray(contoursToUseInSnapping, previewToSource[index]);
        if (idComponent[sourceId]) {
          arrayUtils.extendArray(contoursToUseInSnapping, idComponent[sourceId].initialIds);
        }
      } else {
        previewsToSave.push(idComponent[sourceId]);
      }
    }, []);

    const contoursToDeleteSet = new Set(magicSearch.contoursToDelete);
    const processedPolygons = previewsToSave.reduce<SetPreviewsPayload>((prev, current, index) => {
      prev.contours.push({
        points: current.value.points,
        holes: current.value.holes || [],
      });
      prev.resultToSources[index] = current.initialIds;
      if (contoursToDeleteSet.has(current.initialIds[0])) {
        prev.previewsToDelete.push(index);
      }
      return prev;
    }, {
      contours: [],
      resultToSources: {},
      previewsToDelete: [],
    });
    if (!contoursToUseInSnapping.length) {
      yield put(MagicSearchActions.setPreviews(processedPolygons));
      yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loaded));
      return;
    }

    const contoursToUseInSpanningSet = new Set(contoursToUseInSnapping);
    const groupedContours = magicSearch.contours.reduce((prev, current) => {
      if (!contoursToUseInSpanningSet.has(current.id) || current.conf < magicSearch.similarity) {
        return prev;
      }
      if (contoursToDeleteSet.has(current.id)) {
        prev.negativeContours.push(current);
      } else {
        prev.positiveContours.push(current);
      }
      return prev;
    }, { positiveContours: [], negativeContours: [] });
    if (!groupedContours.positiveContours.length && !groupedContours.negativeContours.length) {
      yield put(MagicSearchActions.setPreviews(processedPolygons));
      yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loaded));
      return;
    }
    const snapped: AISnappingResponse = yield call(
      MagicSearchApi.snapping,
      drawingInfo.pdfId,
      drawingInfo.drawingId,
      groupedContours,
    );

    if (snapped.positiveGeometries) {
      snapped.positiveGeometries.forEach((geometry) => {
        previewsToSave.push(geometry);
        const currentIndex = processedPolygons.contours.push({
          points: geometry.value.points,
          holes: geometry.value.holes || [],
        }) - 1;
        processedPolygons.resultToSources[currentIndex] = geometry.initialIds;
      });
    }

    if (snapped.negativeGeometries) {
      snapped.negativeGeometries.forEach((geometry) => {
        previewsToSave.push(geometry);
        const currentIndex = processedPolygons.contours.push({
          points: geometry.value.points,
          holes: geometry.value.holes || [],
        }) - 1;
        processedPolygons.resultToSources[currentIndex] = geometry.initialIds;
        processedPolygons.previewsToDelete.push(currentIndex);
      });
    }

    yield put(MagicSearchActions.setPreviews(processedPolygons));
    yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loaded));
    yield put(MagicSearchActions.setSnappedGeometry(previewsToSave));
  } catch (e) {
    console.error('magic search saga: recalculate previews:', e);
    yield call(mergeAndShowPreviews);
    yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loaded));
  }
}

function *setSimilarity(): SagaIterator {
  try {
    yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loading));
    yield call(mergeAndShowPreviews);
    yield delay(2000);
    yield put(MagicSearchActions.resnap());
  } catch (e) {
    console.error('magic search saga: set similarity:', e);
  }
}

function* runTextSearch(
  controller: RequestsController,
  { payload: query }: ActionWith<string>,
): SagaIterator {
  try {
    yield call(run, { points: [], pointsInfo: [], text: query, controller });
  } catch (e) {
    yield put(MagicSearchActions.saveResult({
      contours: [],
      status: WizzardStatus.Error,
    }));
    console.error('magic search saga: run text search:', e);
  }
}

function *cancelSearch(controller: RequestsController): SagaIterator {
  try {
    controller.cancel();
  } catch (e) {
    console.error('magic search saga: cancel search:', e);
  }
}

function* runResnap(): SagaIterator {
  yield put(MagicSearchActions.resnap());
}

function* applyContourFix(): SagaIterator {
  try {
    yield put(MagicSearchActions.setSnappingStatus(RequestStatus.Loading));
    yield call(mergeAndShowPreviews);
    yield put(MagicSearchActions.resnap());
  } catch (e) {
    console.error('magic search saga: apply contour fix:', e);
  }
}

function* forceRestart(controller: RequestsController): SagaIterator {
  try {
    yield call(runSearch, controller);
  } catch (e) {
    console.error('magic search saga: force restart:', e);
  }
}

export function* magicSearchSaga(): SagaIterator {
  const magicSearchController = new StreamedRequestController(MagicSearchStream);
  yield takeLatest(MagicSearchActionTypes.RUN_SEARCH, runSearch, magicSearchController);
  yield takeLatest(MagicSearchActionTypes.SET_SIMILARITY, setSimilarity);
  yield takeLatest(MagicSearchActionTypes.TOGGLE_DELETE_PREVIEW, runResnap);
  yield takeLatest(MagicSearchActionTypes.SAVE_RESULT, saveResultEffect);
  yield takeLatest(MagicSearchActionTypes.RESET_DELETE_PREVIEWS, runResnap);
  yield takeLatest(MagicSearchActionTypes.RUN_TEXT_SEARCH, runTextSearch, magicSearchController);
  yield takeLatest(MagicSearchActionTypes.CANCEL_SEARCH, cancelSearch, magicSearchController);
  yield takeLatest(MagicSearchActionTypes.RESNAP, resnapPreviews);
  yield takeLatest(MagicSearchActionTypes.APPLY_FIX_CONTOUR, applyContourFix);
  yield takeLatest(MagicSearchActionTypes.REMOVE_NEGATIVE_CONTOUR, forceRestart, magicSearchController);
  yield takeLatest(MagicSearchActionTypes.SET_CONTOUR_AS_NEGATIVE, forceRestart, magicSearchController);
  yield takeLatest(MagicSearchActionTypes.RESET_NEGATIVE_CONTOURS, forceRestart, magicSearchController);
}
