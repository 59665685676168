import { PagesFilterValue } from 'common/components/drawings/interfaces/drawing-filters';
import { SortDataInitialState } from 'common/components/drawings/interfaces/drawing-measurement-sort';
import { SubscriptionType } from 'common/constants/subscription';
import { ProjectsView } from '../../../units/2d-projects-page/2d-projects-content/constants';
import { Currency } from '../../../units/subscription/constants';
import { PersistedStorageState } from '../interfaces/state';

export const THREE_D_SETTINGS_INITIAL_STATE = {
  isCameraOrthographic: false,
  fillOpacity: 0.5,
  showDrawing: true,
  drawingOpacity: 1,
  drawingOffset: 0,
};

export const PERSISTED_STORAGE_INITIAL_STATE: PersistedStorageState = {
  projectIdToRevisionId: {},
  isCollapseMenu: false,
  selectedCompanyId: null,
  isChatCloudDismissed: null,
  isAutoFocusEngine: null,
  disableShowDialogList: null,
  disableShowTourList: {},
  viewedReleaseNotesVersion: null,
  drawingBlackAndWhiteToggle: false,
  drawingNightModeToggle: false,
  drawingSnappingToggle: true,
  drawingOrthogonalMode: false,
  drawingOpacity: 100,
  isPagesTabsMinified: null,
  theme: 'dark',
  darkTone: 'night',
  lightTone: 'day',
  isQuickSearchVisible: false,
  isMeasurementsSearchVisible: true,
  isAutoMoveToCell: false,
  drawingIsKeepPolygon: false,
  drawingIsKeepGroup: false,
  drawingOffsetIsStroke: false,
  drawingSnappingModes: [Core.PDFNet.GeometryCollection.SnappingMode.e_PathEndpoint],
  drawingKeepMeasuresNames: false,
  drawingAutofocusEnabled: false,
  drawingKeepStructureEnabled: true,
  drawingMinimapOpened: true,
  drawingNewInstanceStrokeWidth: 6,
  drawingAutocomplete: false,
  drawingExportFontSize: 4,
  drawingExportLineThickness: 1,
  drawingUseGroupNameForNewGeometry: true,
  drawingNewPolygonHeight: null,
  drawingNewPolylineHeight: null,
  drawingNewPolylineThickness: null,
  drawingMeasurementsPivot: true,
  drawingThreeDSettings: THREE_D_SETTINGS_INITIAL_STATE,
  drawingShowThickness: true,
  drawingMeasurementCategoryInstrument: {},
  drawingsPinFlyingMenu: true,
  drawingsWizzardAllowFlipping: true,
  isOpenDrawingsAnnotationLegendPanel: true,
  isDemoProjectCollapsed: false,
  isFoldersSectionCollapsed: false,
  selectedFilterPages: PagesFilterValue.Current,
  selectedMeasurementSort: SortDataInitialState,
  innerClipboard: {
    twoDTable: {
      rows: [],
      expectedClipboard: '',
    },
  },
  twoDDataBaseColumnState: {},
  splitterPaneSize: {},
  hideUnselectedPageGroups: false,
  entityPanel: {
    position: { top: null, left: null, width: null, height: null },
    show: true,
  },
  showTableUnits: true,
  showTableTotal: false,
  drawingsShowLabel: true,
  selectedCurrency: Currency.GBP.shortcat,
  projectAccessFilter: {},
  lastUsedProductType: SubscriptionType.Takeoff2d,
  currentPathname: '',
  hideSmallScreenDialog: false,
  isTryAiPanelShown: false,
  projectsViewType: ProjectsView.Grid,
  projectTwoDFullScreenMode: [],
};
