import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { Text } from 'common/components/text';
import { PeopleActions } from '../../../../units/people/actions/actions';
import { Styled } from './styled';


interface Props {
  text: string;
  canShareProject: boolean;
}

const InvitePeopleButtonComponent: React.FC<Props> = ({ text, canShareProject }) => {
  const dispatch = useDispatch();

  const openDialogClick = useCallback(() => {
    if (canShareProject) {
      dispatch(PeopleActions.setShowInviteUsersPanel(true));
    }
  }, [canShareProject, dispatch]);

  return (
    <Styled.InvitePeople onClick={openDialogClick}>
      <Icons.AddUser />
      <Text>{text}</Text>
    </Styled.InvitePeople>
  );
};

export const InvitePeopleButton = React.memo(InvitePeopleButtonComponent);
