import { MovableContextMenu } from '@kreo/kreo-ui-components';
import React from 'react';

import { DrawingsColorControl } from 'common/components/drawings/drawings-controls';
import { GlobalKeyboardEventsFocusRestore } from 'common/components/global-keyboard-events-controller';
import { ColorList } from 'common/constants/color-list';
import { DrawingsDrawMode } from '../../enums';
import { DrawingsGeometryGroupWithNesting } from '../../interfaces';
import { CreateNewMenuContent } from './create-new-menu-content/create-new-menu-content';

export enum GroupsMenuType {
  Color,
  CreateNew,
}

export interface GroupsMenuCommonProps {
  isOpen: boolean;
  positionX: number;
  positionY: number;
  selectedGroup: DrawingsGeometryGroupWithNesting;
  menuType: GroupsMenuType;
}

interface Props extends GroupsMenuCommonProps {
  onClose: () => void;
  setColor: (color: string) => void;
  drawGeometry: (drawMode: DrawingsDrawMode) => void;
  createGroup: () => void;
}

const GroupsMenuComponent: React.FC<Props> = ({
  positionX,
  positionY,
  selectedGroup,
  menuType,
  createGroup,
  onClose,
  drawGeometry,
  setColor,
}) => {
  if (menuType === GroupsMenuType.Color) {
    return (
      <GlobalKeyboardEventsFocusRestore>
        <MovableContextMenu
          x={positionX}
          y={positionY}
          onClose={onClose}
        >
          <DrawingsColorControl
            colorList={ColorList}
            selectedColor={selectedGroup.color}
            onColor={setColor}
          />
        </MovableContextMenu>
      </GlobalKeyboardEventsFocusRestore>
    );
  } else if (menuType === GroupsMenuType.CreateNew) {
    return (
      <GlobalKeyboardEventsFocusRestore>
        <MovableContextMenu
          x={positionX}
          y={positionY}
          onClose={onClose}
        >
          <CreateNewMenuContent
            drawGeometry={drawGeometry}
            createNewGroup={createGroup}
            onClose={onClose}
          />
        </MovableContextMenu>
      </GlobalKeyboardEventsFocusRestore>
    );
  }
};


export const GroupsMenu = React.memo(GroupsMenuComponent);
