import styled from 'styled-components';

import { InputSelectStyled } from '../inputs';
import { ShareWithCompanyStyled } from '../share-with-company';
import { Text } from '../text';


const Container = styled.div`
  position: relative;
  padding: 40px 40px 0;
  display: flex;
  flex-direction: column;
  background: ${p => p.theme.color.backgroundRush};
  width: 100%;
  height: 100%;
  &, * {
    box-sizing: border-box;
  }
  button {
    margin-left: auto;
  }
  > ${Text} {
    display: flex;
    justify-content: space-between;
  }

  ${ShareWithCompanyStyled.Container} {
    margin-bottom: 15px;
  }
`;

const IconShare = styled.div`
  display: inline-flex;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    fill: ${p => p.theme.color.gray};
  }
`;

const BtnWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 5px 35px 20px 0;
  margin-right: -40px;
  overflow-y: auto;
`;

const Form = styled.div`
  position: relative;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const DropdownContainer = styled.form`
  width: 100%;

  ${InputSelectStyled.DropDownMenuContainer} {
    width: 100%;
  }
`;

export const Styled = {
  Container,
  DropdownContainer,
  Form,
  FormWrapper,
  IconShare,
  BtnWrapper,
  LinkWrapper,
};
