import ReactDOMServer from 'react-dom/server';

import { UserInfo } from 'common/components/data-base-table/interfaces';
import { getUserAvatarElement } from 'unit-2d-database/components/cell-renderer-user-avatar';
import { User } from 'unit-2d-database/interfaces';
import { AccountApi } from '../../../../units/account/api';

export const mapUser = (user?: User): UserInfo => {
  if (!user) {
    return {
      userId: '',
      firstName: '',
      lastName: '',
      avatarLink: '',
      content: '',
    };
  }

  const fullName = `${user.firstName} ${user.lastName}`;
  const link = AccountApi.getAvatarLink({ id: user.id, hasAvatar: user.hasAvatar });
  return {
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    avatarLink: link,
    content: ReactDOMServer.renderToString(getUserAvatarElement(fullName, link)),
  };
};
