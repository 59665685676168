import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'common/interfaces/state';
import { CompanySubscriptionModel } from '../../../../account/interfaces/company-subscription-model';
import { BillingEntityStatus } from '../../../enums/billing-entity-status';
import { getPlan } from '../../../hooks';
import { SubscriptionPlanListingModel } from '../../../interfaces/subscription-plan-listing-model';

export const useIsArchivedMemo = (
  subscription: CompanySubscriptionModel,
): boolean => {
  const planModel = useSelector<State, SubscriptionPlanListingModel>(s => s.account.subscriptionPlans);
  return useMemo(() => {
    const plan = getPlan(planModel, subscription.planId);
    return plan?.status === BillingEntityStatus.Archived;
  }, [subscription, planModel]);
};
