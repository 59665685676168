import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'common/interfaces/state';
import { useOpenCloseDialog } from 'common/UIKit';
import { DrawingDialogs } from '../../constants/drawing-dialogs';
import { DrawingsContextMenuActions } from '../../drawings-canvas-menus';
import { ActionEventHandlers } from '../../drawings-canvas-menus/drawings-context-menu/interfaces';
import {
  useAddComment,
  useFolderToCell,
  useModify,
  useMoveToCell,
  useStartMagicSearch,
} from '../../drawings-canvas-menus/hooks';
import { useElementAndGroupsOperationContext } from '../../drawings-contexts';
import { DrawingContextMenuPosition } from '../../interfaces';

export interface UseActionEventHandersPayload {
  copy: () => void;
  paste: (coordinates: paper.Point) => void;
  pasteWithPoint: () => void;
  pasteInSamePlace: () => void;
  cut: () => void;
  onRemoveInstancesHandler: () => void;
  onShowAll: () => void;
  groupDrawings: () => void;
  focus: () => void;
  toggleFullScreen: () => void;
  selectAll: () => void;
  instancesIds: string[];
}

export function useActionEventHandlers(
  {
    copy,
    paste,
    pasteWithPoint,
    pasteInSamePlace,
    cut,
    onRemoveInstancesHandler,
    onShowAll,
    groupDrawings,
    focus,
    toggleFullScreen,
    selectAll,
    instancesIds,
  }: UseActionEventHandersPayload,
): Record<string, ActionEventHandlers> {
  const {
    onTraceLink,
    hideSelectedInstances,
    showSelectedInstances,
    duplicateSelectedInstances,
    onFolderToCell,
    onMoveToCell,
    isolateSelectedInstances,
    removeSelectedElementsFromGroups,
  } = useElementAndGroupsOperationContext();
  const startMagicSearch = useStartMagicSearch(instancesIds);
  const modify = useModify(instancesIds);
  const onFolderToCellCallback = useFolderToCell(onFolderToCell);
  const moveToCellCallback = useMoveToCell(onMoveToCell, instancesIds);
  const contextMenuPosition = useSelector<State, DrawingContextMenuPosition>(
    s => s.drawings.contextMenuPosition,
  );
  const [ openMoveToGroup ] = useOpenCloseDialog(DrawingDialogs.MOVE_TO_DIALOG);

  const addComment = useAddComment();
  return useMemo(
    () => ({
      [DrawingsContextMenuActions.Copy]: { onClick: () => copy() },
      [DrawingsContextMenuActions.Paste]: { onClick: () =>  paste(contextMenuPosition?.drawingPoint) },
      [DrawingsContextMenuActions.PasteWithPoint]: { onClick: () => pasteWithPoint() },
      [DrawingsContextMenuActions.PasteInSamePlace]: { onClick: () => pasteInSamePlace() },
      [DrawingsContextMenuActions.Cut]: { onClick: () => cut() },
      [DrawingsContextMenuActions.Hide]: { onClick: () => hideSelectedInstances() },
      [DrawingsContextMenuActions.Show]: { onClick: () => showSelectedInstances() },
      [DrawingsContextMenuActions.Delete]: { onClick: () => onRemoveInstancesHandler() },
      [DrawingsContextMenuActions.ShowAll]: { onClick: () => onShowAll() },
      [DrawingsContextMenuActions.Modify]: { onClick: modify },
      [DrawingsContextMenuActions.Group]: { onClick: () => groupDrawings() },
      [DrawingsContextMenuActions.Ungroup]: { onClick: () => removeSelectedElementsFromGroups() },
      [DrawingsContextMenuActions.MoveToCell]: { onClick: null, onSelect: ({ type }) => moveToCellCallback(type) },
      [DrawingsContextMenuActions.Focus]: { onClick: () => focus() },
      [DrawingsContextMenuActions.Isolate]: { onClick: () => isolateSelectedInstances() },
      [DrawingsContextMenuActions.TraceLink]: { onClick: () => onTraceLink(instancesIds[0]) },
      [DrawingsContextMenuActions.FullScreen]: { onClick: () => toggleFullScreen() },
      [DrawingsContextMenuActions.ExitFullscreen]: { onClick: () => toggleFullScreen() },
      [DrawingsContextMenuActions.SelectAll]: { onClick: () => selectAll() },
      [DrawingsContextMenuActions.Duplicate]: { onClick: () => duplicateSelectedInstances() },
      [DrawingsContextMenuActions.DynamicMoveToCell]: { onClick: null },
      [DrawingsContextMenuActions.GroupToCell]: {
        onClick: null,
        onSelect: ({ type }) => onFolderToCellCallback(type),
      },
      [DrawingsContextMenuActions.AddComment]: { onClick: () => addComment(contextMenuPosition) },
      [DrawingsContextMenuActions.MoveToGroup]: { onClick: () => openMoveToGroup() },
      [DrawingsContextMenuActions.MagicSearch]: { onClick: startMagicSearch },
    }),
    [
      modify,
      startMagicSearch,
      copy,
      paste,
      contextMenuPosition,
      pasteWithPoint,
      pasteInSamePlace,
      cut,
      hideSelectedInstances,
      showSelectedInstances,
      onRemoveInstancesHandler,
      onShowAll,
      groupDrawings,
      removeSelectedElementsFromGroups,
      moveToCellCallback,
      focus,
      isolateSelectedInstances,
      onTraceLink,
      instancesIds,
      toggleFullScreen,
      selectAll,
      duplicateSelectedInstances,
      onFolderToCellCallback,
      addComment,
      openMoveToGroup,
    ],
  );
}
