/* eslint-disable indent */
import { ReducerMethods } from 'common/interfaces/reducer-methods';
import { MonoliteHelper } from 'common/monolite';
import { arrayUtils } from 'common/utils/array-utils';
import { objectUtils } from 'common/utils/object-utils';
import {
  DrawingsAnnotationUpdatePoint,
  DrawingsGeometryAddFileData,
  DrawingsGeometryAddInstances,
  DrawingsGeometryRenameInstance,
  DrawingsGeometryUpdateParametr,
  DrawingsGeometryUpdateUser,
  DrawingsUpdateAnnotationGeometry,
  ProcessFileDataResult,
} from '../actions/payloads/annotation';
import { DrawingsAnnotationActionTypes } from '../actions/types/annotation';
import { GeometryGroupsResponse } from '../interfaces';
import { DrawingsPointInfo } from '../interfaces/drawing-ai-annotation';
import { DrawingsGeometryInstance, UpdateGroupInDrawingsPayload } from '../interfaces/drawings-geometry-instance';
import { DrawingsState } from '../interfaces/drawings-state';
import { DrawingAnnotationReducerUtils } from '../utils/drawing-annotation-reducer-utils';
import { DrawingAnnotationUtils } from '../utils/drawing-annotation-utils';
import { DrawingsPointUtils } from '../utils/drawing-point-utils';
import { DrawingsGeometryUtils } from '../utils/drawings-geometry-utils';
import { setGroups } from './drawings-annotation-legend-methods';

export const DrawingsAnnotationReducerMethods: ReducerMethods<DrawingsState> = {
  [DrawingsAnnotationActionTypes.SAVE_AI_ANNOTATION]: (state, payload: DrawingsGeometryAddFileData) => {
    if (!state.drawingsInfo[payload.pageId]) {
      return state;
    }
    const { pdfId } = state.drawingsInfo[payload.pageId];

    const {
      geometryInstances,
      points,
      pointsInfo,
      fileData,
      users,
      usedColors,
      hiddenInstances,
      pagePoints,
    } = payload.response;
    const creators = state.aiAnnotation.geometryCreators;
    const geometryHelper = new MonoliteHelper(state.aiAnnotation)
      .set(_ => _.geometry, _ => ({ ..._, ...geometryInstances }))
      .set(_ => _.points, _ => ({ ..._, ...points }))
      .set(_ => _.pointsInfo, _ => ({ ..._, ...pointsInfo }))
      .set(_ => _.fileData[pdfId], _ => ({ ..._, ...fileData[pdfId] }))
      .set(_ => _.geometryCreators, arrayUtils.uniq(creators.concat(users)));

    return new MonoliteHelper(state)
      .set(_ => _.usedColors, arrayUtils.uniq(usedColors))
      .setConcat(_ => _.hiddenInstances, hiddenInstances)
      .set(
        _ => _.drawingPaperPoints,
        _ => ({ ..._, ...pagePoints }),
      )
      .set(_ => _.aiAnnotation, geometryHelper.get())
      .get();
  },
  [DrawingsAnnotationActionTypes.LOAD_FULL_GEOMETRY]: (state) => {
    return new MonoliteHelper(state)
      .set(_ => _.groupsLoaded, false)
      .get();
  },
  [DrawingsAnnotationActionTypes.SAVE_ALL_GEOMETRIES]: (
    state,
    { response, groups }: { response: ProcessFileDataResult, groups: GeometryGroupsResponse }) => {
    const creators = state.aiAnnotation.geometryCreators;
    const geometryHelper = new MonoliteHelper(state.aiAnnotation)
      .set(_ => _.geometry, response.geometryInstances)
      .set(_ => _.points, response.points)
      .set(_ => _.pointsInfo, response.pointsInfo)
      .set(_ => _.fileData, response.fileData)
      .set(_ => _.geometryCreators, arrayUtils.uniq(creators.concat(response.users)));
    return setGroups(new MonoliteHelper(state), groups)
      .set(_ => _.usedColors, arrayUtils.uniq(response.usedColors))
      .setConcat(_ => _.hiddenInstances, response.hiddenInstances)
      .set(_ => _.groupsLoaded, true)
      .set(
        _ => _.drawingPaperPoints,
        response.pagePoints,
      )
      .set(_ => _.aiAnnotation, geometryHelper.get())
      .get();

  },
  [DrawingsAnnotationActionTypes.UPDATE_POINT]: (state, payload: DrawingsAnnotationUpdatePoint) => {
    return new MonoliteHelper(state)
      .set(_ => _.aiAnnotation.points, _ => ({ ..._, ...payload.pointsUpdated }))
      .get();
  },
  [DrawingsAnnotationActionTypes.UPDATE_GEOMETRY]: (state, payload: DrawingsUpdateAnnotationGeometry) => {
    const helper = new MonoliteHelper(state);
    const geometryHelper = { ...state.aiAnnotation };
    const pointsHelper = new MonoliteHelper(state.drawingPaperPoints);
    const elementMeasurementHelper = { ...state.elementMeasurement };
    const { id, geometry, type } = payload.instance;
    if (
      state.aiAnnotation.geometry[id].geometry.color !== geometry.color
      && !state.usedColors.includes(geometry.color)
    ) {
      helper.setAppend(_ => _.usedColors, geometry.color);
    }
    geometryHelper.geometry = { ...geometryHelper.geometry, [id]: { ...geometryHelper.geometry[id], geometry } };
    if (state.elementMeasurement[id]) {
      elementMeasurementHelper[id] = { ...elementMeasurementHelper[id], color: geometry.color };
    }

    if (type) {
      geometryHelper.geometry[id].type = type;
    }
    const pointsUpdates = {};
    if (payload.updatePoints) {
      objectUtils.extend(pointsUpdates, payload.updatePoints);
    }
    if (payload.newPoints) {
      objectUtils.extend(pointsUpdates, payload.newPoints);
      pointsHelper.set(
        _ => _,
        _ =>  DrawingsPointUtils.addPointsToCommon(
          payload.newPoints,
          state.aiAnnotation.geometry[payload.instance.id].drawingId,
          _,
        ),
      );

      const newPointsInfo: Record<string, DrawingsPointInfo> = arrayUtils.toDictionary(
          Object.keys(payload.newPoints),
          x => x,
          _ => DrawingAnnotationUtils.createPointInfo(payload.instance.id),
        );
      geometryHelper.pointsInfo = { ...geometryHelper.pointsInfo, ...newPointsInfo };
    }

    if (Object.keys(pointsUpdates).length) {
      geometryHelper.points = { ...geometryHelper.points, ...pointsUpdates };
    }

    if (payload.lines) {
      if (payload.lines.removedLines.length) {
        for (const lineId of payload.lines.removedLines) {
          for (const pointId of DrawingAnnotationUtils.getPointsIdsFromLineKey(lineId)) {
            geometryHelper.pointsInfo[pointId] = {
              ...geometryHelper.pointsInfo[pointId],
              lines: geometryHelper.pointsInfo[pointId].lines.filter(x => x !== lineId),
            };
          }
          if (lineId in state.elementMeasurement) {
            delete elementMeasurementHelper[lineId];
          }
        }
      }
      for (const [key, points] of Object.entries(payload.lines.addLines)) {
        for (const point of points) {
          geometryHelper.pointsInfo[point] = {
            ...geometryHelper.pointsInfo[point],
            lines: geometryHelper.pointsInfo[point].lines ? geometryHelper.pointsInfo[point].lines.concat(key) : [key],
          };
        }
      }
    }
    if (payload.removePoints) {
      const pointsToRemove: Record<string, string[]> = {};
      geometryHelper.pointsInfo = { ...geometryHelper.pointsInfo };
      geometryHelper.points = { ...geometryHelper.points };
      for (const pointId of payload.removePoints) {
        const instance = state.aiAnnotation.geometry[state.aiAnnotation.pointsInfo[pointId].instanceId];
        pointsToRemove[instance.drawingId] = pointsToRemove[instance.drawingId] || [];
        pointsToRemove[instance.drawingId].push(pointId);
        delete geometryHelper.points[pointId];
        delete geometryHelper.pointsInfo[pointId];
      }
      for (const [drawingId, points] of Object.entries(pointsToRemove)) {
        pointsHelper.set(_ => _[drawingId], _ => DrawingsPointUtils.removeDrawingPoints(_, points));
      }
    }
    return helper
      .set(_ => _.aiAnnotation, _ => geometryHelper)
      .set(_ => _.drawingPaperPoints, pointsHelper.get())
      .set(_ => _.elementMeasurement, _ => elementMeasurementHelper)
      .get();
  },
  [DrawingsAnnotationActionTypes.REMOVE_INSTANCES]: (state, instancesIds: string[]) => {
    const helper = new MonoliteHelper(state);
    const annotationHelper = new MonoliteHelper(state.aiAnnotation);

    const paperPoints = { ...state.drawingPaperPoints };
    const pointsInfo = { ...state.aiAnnotation.pointsInfo };
    const points = { ...state.aiAnnotation.points };
    const geometry = { ...state.aiAnnotation.geometry };
    const elementMeasurement = { ...state.elementMeasurement };

    const removedInstancesByDrawings: Record<string, Record<string, string[]>> = {};
    const drawingPointsToRemove: Record<string, string[]> = {};

    for (const id of instancesIds) {
      const instance = state.aiAnnotation.geometry[id];
      if (!instance) {
        continue;
      }
      const instancesPoints = DrawingAnnotationUtils.geometryPointsIterator(instance.geometry, instance.type);
      delete elementMeasurement[id];
      drawingPointsToRemove[instance.drawingId] = drawingPointsToRemove[instance.drawingId] || [];
      for (const pointId of instancesPoints) {
        if (!(pointId in state.aiAnnotation.points)) {
          continue;
        }
        const annotationState = annotationHelper.get();
        if (annotationState.pointsInfo[pointId].instanceId === id) {
          if (annotationState.pointsInfo[pointId].lines) {
            for (const line of annotationState.pointsInfo[pointId].lines) {
              delete elementMeasurement[line];
            }
          }
          drawingPointsToRemove[instance.drawingId].push(pointId);
          delete points[pointId];
          delete pointsInfo[pointId];
        }
      }
      const { pdfId, drawingId } = state.drawingsInfo[instance.drawingId];
      removedInstancesByDrawings[pdfId] = removedInstancesByDrawings[pdfId] || {};
      removedInstancesByDrawings[pdfId][drawingId] = removedInstancesByDrawings[pdfId][drawingId] || [];
      removedInstancesByDrawings[pdfId][drawingId].push(id);
      delete geometry[id];
    }

    for (const [pdfId, drawingInstances] of Object.entries(removedInstancesByDrawings)) {
      for (const [drawingId, drawingInstancesIds] of Object.entries(drawingInstances)) {
        const instancesToDeleteSet = new Set(drawingInstancesIds);
        annotationHelper
          .setFilter(x => x.fileData[pdfId][drawingId].instances, _ => !instancesToDeleteSet.has(_));
        paperPoints[drawingId] = DrawingsPointUtils
          .removeDrawingPoints(paperPoints[drawingId], drawingPointsToRemove[drawingId]);
      }
    }

    annotationHelper
      .set(_ => _.points, points)
      .set(_ => _.pointsInfo, pointsInfo)
      .set(_ => _.geometry, geometry);

    const instancesSet = new Set(instancesIds);
    helper
      .setFilter(_ => _.selectedInstances, _ => !instancesSet.has(_))
      .setFilter(_ => _.filteredItems, item => !instancesSet.has(item.id))
      .setFilter(_ => _.notSavedInstances, _ => !instancesSet.has(_))
      .set(_ => _.aiAnnotation, annotationHelper.get())
      .set(_ => _.drawingPaperPoints, paperPoints)
      .set(_ => _.elementMeasurement, elementMeasurement);

    return helper.get();
  },
  [DrawingsAnnotationActionTypes.ADD_INSTANCE]: (state, payload: DrawingsGeometryAddInstances) => {
    const helper = { ...state.aiAnnotation };
    helper.points = { ...helper.points, ...payload.newPoints };

    const colors = new Set(state.usedColors);

    const newDrawingInstances: Record<string, string[]> = {};
    const currentPointsInfo: Record<string, DrawingsPointInfo> = {};
    const newGeometries: Record<string, DrawingsGeometryInstance> = {};

    const actualDrawingPoints: Record<string, string[]> = {};

    for (const instance of payload.instances) {
      const { geometry, type, id, drawingId } = instance;
      newDrawingInstances[drawingId] = newDrawingInstances[drawingId] || [];
      newDrawingInstances[drawingId].push(id);

      const points = {};
      if (DrawingsGeometryUtils.isClosedContour(type, geometry)) {
        DrawingAnnotationUtils.processContourPoints(
          geometry.points,
          points,
          payload,
          id,
          state.aiAnnotation.pointsInfo,
          currentPointsInfo,
          state.aiAnnotation.points,
        );
        if (DrawingsGeometryUtils.isPolygon(type, geometry) && geometry.children) {
          for (const child of geometry.children) {
            DrawingAnnotationUtils.processContourPoints(
              child,
              points,
              payload,
              id,
              state.aiAnnotation.pointsInfo,
              currentPointsInfo,
              state.aiAnnotation.points,
            );
          }
        }
      } else if (DrawingsGeometryUtils.isCount(type, geometry)) {
        for (const point of geometry.points) {
          currentPointsInfo[point] = DrawingAnnotationUtils.createPointInfo(id);
          points[point] = payload.newPoints[point] || state.aiAnnotation.points[point];
        }
      } else if (DrawingsGeometryUtils.isPolyline(type, geometry)) {
        for (let i = 0; i < geometry.points.length; i++) {
          const current = geometry.points[i];
          if (i !== geometry.points.length - 1) {
            const next = geometry.points[i + 1];
            DrawingAnnotationUtils.addLineLinks(currentPointsInfo, state.aiAnnotation.pointsInfo, id, next, current);
          } else {
            currentPointsInfo[current] = currentPointsInfo[current] || DrawingAnnotationUtils.getOrCreatePointInfo(
              state.aiAnnotation.pointsInfo,
              current,
              id,
            );
          }
          points[current] = payload.newPoints[current] || state.aiAnnotation.points[current];
        }
      }
      actualDrawingPoints[drawingId] = actualDrawingPoints[drawingId]
        || state.drawingPaperPoints[drawingId]?.slice()
        || [];
      arrayUtils.extendArray(actualDrawingPoints[drawingId], Object.keys(points));
      if (geometry.color) {
        colors.add(geometry.color);
      }
      newGeometries[id] = instance;
    }
    helper.geometry = { ...helper.geometry, ...newGeometries };

    const fileDataHelper = { ...state.aiAnnotation.fileData };
    for (const [key, instances] of Object.entries(newDrawingInstances)) {
      const drawing = state.drawingsInfo[key];
      fileDataHelper[drawing.pdfId] = fileDataHelper[drawing.pdfId]
        ? { ...fileDataHelper[drawing.pdfId] }
        : {};
      fileDataHelper[drawing.pdfId][drawing.drawingId] = fileDataHelper[drawing.pdfId][drawing.drawingId]
        ? { ...fileDataHelper[drawing.pdfId][drawing.drawingId] }
        : DrawingAnnotationUtils.createEmptyFileData();
        const page = fileDataHelper[drawing.pdfId][drawing.drawingId];
        page.instances = page.instances.concat(instances);
    }

    helper.pointsInfo = { ...state.aiAnnotation.pointsInfo, ...currentPointsInfo };
    helper.fileData = { ...fileDataHelper };
    const newState = { ...state };
    newState.aiAnnotation = helper;
    newState.drawingPaperPoints = { ...state.drawingPaperPoints, ...actualDrawingPoints };
    newState.usedColors = Array.from(colors);

    if (payload?.pia) {
      const instances = payload.instances.map(instance => instance.id);
      newState.notSavedInstances = [...newState.notSavedInstances, ...instances];
    }

    return newState;
  },
  [DrawingsAnnotationActionTypes.REMOVE_NOT_SAVED_INSTANCES]: (state, payload: string[]) => {
    const helper = new MonoliteHelper(state);
    const notSavedInstances = state.notSavedInstances.filter(id => !payload.includes(id));
    return helper.set(_ => _.notSavedInstances, notSavedInstances).get();
  },
  [DrawingsAnnotationActionTypes.RENAME_INSTANCES]: (state, payload: DrawingsGeometryRenameInstance[]) => {
    const helper = new MonoliteHelper(state);
    const geometries = { ...state.aiAnnotation.geometry };
    payload.forEach(({ instanceId, name }) => {
      geometries[instanceId] = { ...geometries[instanceId], name };
    });
    return helper.set(_ => _.aiAnnotation.geometry, geometries).get();
  },
  [DrawingsAnnotationActionTypes.CHANGE_INSTANCES_GEOMETRY_PARAM]: (
    state,
    payload: DrawingsGeometryUpdateParametr,
  ) => {
    const helper = new MonoliteHelper(state);
    const { parameter, value } = payload;
    const isColor = parameter === 'color';
    if (isColor && !state.usedColors.includes(value as string)) {
      helper.setAppend(_ => _.usedColors, value);
    }
    const geometries = { ...state.aiAnnotation.geometry };
    const measures = { ...state.elementMeasurement };
    for (const instanceId of payload.instancesIds) {
      const geometry = geometries[instanceId].geometry;
      geometries[instanceId] = { ...geometries[instanceId], geometry: { ...geometry, [parameter]: value } };
      if (isColor) {
        if (instanceId in state.elementMeasurement) {
          measures[instanceId] = { ...measures[instanceId], color: value as string };
        }
        const instance = state.aiAnnotation.geometry[instanceId];
        if (DrawingsGeometryUtils.isPolyGeometry(instance.type, instance.geometry)) {
          for (const lineId of DrawingAnnotationUtils.iterateLines(instance.geometry, instance.type)) {
            if (lineId in state.elementMeasurement) {
              measures[lineId] = { ...measures[lineId], color: value as string };
            }
          }
        }
      }
    }
    return helper
      .set(_ => _.elementMeasurement, measures)
      .set(_ => _.aiAnnotation.geometry, geometries)
      .get();
  },
  [DrawingsAnnotationActionTypes.UPDATE_USER_IN_INSTANCES]: (state, payload: DrawingsGeometryUpdateUser) => {
    const { changesInfo: { userId, appliedAt }, createdIds, updatedIds } = payload;
    const helper = new MonoliteHelper(state);
    const geometries = { ...state.aiAnnotation.geometry };
    for  (const instanceId of createdIds) {
      if (state.aiAnnotation.geometry[instanceId]) {
        geometries[instanceId] = { ...geometries[instanceId], creator: userId, createdAt: appliedAt };
      }
    }
    for  (const instanceId of updatedIds) {
      if (state.aiAnnotation.geometry[instanceId]) {
        geometries[instanceId] = { ...geometries[instanceId], editor: userId, editedAt: appliedAt };
      }
    }
    if (!state.aiAnnotation.geometryCreators.includes(userId) && createdIds.length) {
      helper.setAppend(_ => _.aiAnnotation.geometryCreators, userId);
    }
    const createdIdsSet = new Set(createdIds);
    return helper.set(_ => _.aiAnnotation.geometry, geometries)
      .setFilter(_ => _.notSavedInstances, _ => !createdIdsSet.has(_))
      .get();
  },
  [DrawingsAnnotationActionTypes.UPDATE_GROUP_IN_INSTANCES]: (state, payload: UpdateGroupInDrawingsPayload[]) => {
    const helper = new MonoliteHelper(state);
    return DrawingAnnotationReducerUtils.moveMeasurements(helper, payload).get();
  },
  [DrawingsAnnotationActionTypes.REMOVE_PAGE_DATA]: (state, drawingIds: string[]) => {
    const helper = new MonoliteHelper(state);
    const groupsToRemoveInstances: Record<string, string[]> = {};
    const drawingPoints = { ...state.drawingPaperPoints };
    const pointsInfo = { ...state.aiAnnotation.pointsInfo };
    const points = { ...state.aiAnnotation.points };
    const geometries = { ...state.aiAnnotation.geometry };
    const elementMeasurement = { ...state.elementMeasurement };

    for (const drawingId of drawingIds) {
      const drawing = state.drawingsInfo[drawingId];
      const fileData = state.aiAnnotation.fileData[drawing.pdfId];
      const drawingData = fileData
        ? fileData[drawing.drawingId]
        : null;
      if (!drawingData) {
        continue;
      }
      delete drawingPoints[drawing.drawingId];

      for (const instanceId of drawingData.instances) {
        const instance = state.aiAnnotation.geometry[instanceId];
        if (instance?.groupId) {
          groupsToRemoveInstances[instance.groupId] = groupsToRemoveInstances[instance.groupId] || [];
          groupsToRemoveInstances[instance.groupId].push(instanceId);
        }
        delete geometries[instanceId];
        if (instanceId in state.elementMeasurement) {
          delete elementMeasurement[instanceId];
        }
        const { type, geometry } = state.aiAnnotation.geometry[instanceId];
        if (DrawingsGeometryUtils.isCount(type, geometry)) {
          for (const point of geometry.points) {
            delete points[point];
            delete pointsInfo[point];
          }
        } else if (DrawingsGeometryUtils.isPolyGeometry(type, geometry)) {
          for (const [start, end] of DrawingAnnotationUtils.iteratePoints(geometry.points, type)) {
            delete points[start];
            delete pointsInfo[start];
            if (end) {
              const lineKey = DrawingAnnotationUtils.getLineKey(start, end);
              delete elementMeasurement[lineKey];
            }
          }
        }
      }
    }


    for (const [groupId, instancesIds] of Object.entries(groupsToRemoveInstances)) {
      const instancesIdsSet = new Set(instancesIds);
      const groupIndex = state.drawingGeometryGroups.findIndex(x => x.id === groupId);
      helper.setFilter(_ => _.drawingGeometryGroups[groupIndex].measurements, _ => !instancesIdsSet.has(_))
        .setFilter(_ => _.notSavedInstances, _ => !instancesIdsSet.has(_));
    }
    const setToDelete = new Set(drawingIds);

    return helper
      .setFilter(_ => _.filteredItems, item => !setToDelete.has(item.id))
      .set(_ => _.aiAnnotation.geometry, geometries)
      .set(_ => _.drawingPaperPoints, drawingPoints)
      .set(_ => _.aiAnnotation.points, points)
      .set(_ => _.aiAnnotation.pointsInfo, pointsInfo)
      .set(_ => _.elementMeasurement, elementMeasurement)
      .get();

  },
};


