import { EventChannel, SagaIterator } from 'redux-saga';
import { call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { GptAIChat, GPTRequestBody, createDefaultRequestBody } from 'common/ai-chat/gpt/gpt-ai-chat';
import { AIChat } from 'common/ai-chat/interfaces';
import { ActionWith } from 'common/interfaces/action-with';
import { arrayUtils } from 'common/utils/array-utils';
import { createSagaEventsChannel, selectWrapper } from 'common/utils/saga-wrappers';
import { TwoDCopilotActions } from 'unit-2d-copilot/store-slice';
import { MessageUtils } from 'unit-2d-copilot/utils.ts/message-utils';
import { AIRequestStatus, Message, SendMessagePayload } from '../interfaces';

interface WatchProps {
  channel: EventChannel<any>;
}

function* watch({ channel }: WatchProps): SagaIterator {
  while (true) {
    const data = yield take(channel);
    yield put(TwoDCopilotActions.setTempAnswerText(data));
  }
}


function* send(history: Message[], gptChat: AIChat<GPTRequestBody>): SagaIterator {
  try {
    const historyToSend = arrayUtils.filterMap(
      history,
      x => !x.dontSend,
      x => MessageUtils.toChatMessage(x),
    );
    let listener: (input: any) => void;
    const channel = createSagaEventsChannel((emitter) => (listener = emitter));
    yield fork<(props: WatchProps) => SagaIterator>(watch, { channel });
    const requestBody = createDefaultRequestBody(historyToSend);
    const answer = yield call(gptChat.send, requestBody, listener);
    yield put(TwoDCopilotActions.setTempAnswerText(''));
    yield put(TwoDCopilotActions.addToHistory({ content: answer, role: 'system' } as Message));
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Default));
  } catch (e) {
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Error));
    console.error(e);
  }
}

function* sendMessage(gptChat: AIChat<GPTRequestBody>, { payload }: ActionWith<SendMessagePayload>): SagaIterator {
  try {
    const { getPdfText, query } = payload;
    const history: Message[] = yield selectWrapper((x) => x.twoDCopilot.history);
    if (history.length === 1) {
      yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.TextExtracting));
    } else {
      yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Loading));
    }
    const updatedHistory = yield call(MessageUtils.addPromptToHistory, { history, getPdfText, userQuery: query });
    if (history.length === 1) {
      yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Loading));
    }
    yield put(TwoDCopilotActions.setUpdatedHistory(updatedHistory));
    yield call(send, updatedHistory, gptChat);
  } catch (e) {
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Error));
    console.error(e);
  }
}

function* tryAgain(gptChat: AIChat<GPTRequestBody>): SagaIterator {
  try {
    const history: Message[] = yield selectWrapper((x) => x.twoDCopilot.history);
    yield call(send, history, gptChat);
  } catch (e) {
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Error));
    console.error(e);
  }
}

function* stop(gptChat: AIChat<GPTRequestBody>): SagaIterator {
  try {
    gptChat.cancel();
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Stoped));
  } catch (error) {
    console.error(error);
  }
}

function* reset(gptChat: AIChat<GPTRequestBody>): SagaIterator {
  try {
    gptChat.cancel();
    yield put(TwoDCopilotActions.setRequestStatus(AIRequestStatus.Default));
    yield put(TwoDCopilotActions.setTempAnswerText(''));
  } catch (error) {
    console.error(error);
  }
}

export function* twoDCopilotSaga(): SagaIterator {
  const gptChat = new GptAIChat();
  yield takeEvery(TwoDCopilotActions.sendMessage.type, sendMessage, gptChat);
  yield takeEvery(TwoDCopilotActions.stop.type, stop, gptChat);
  yield takeEvery(TwoDCopilotActions.tryAgain.type, tryAgain, gptChat);
  yield takeEvery(TwoDCopilotActions.reset.type, reset, gptChat);
}
