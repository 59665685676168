import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import {
  ContextObserver,
  ContextObserverWithPrevious,
} from '../../drawings-contexts';
import { DrawingsRenderParams } from '../../interfaces/drawing-render-parameters';
import { MeasuresViewSettings } from '../../interfaces/drawing-text-render-parameters';
import { DrawingsAllowedPathType } from '../../interfaces/drawings-geometry';
import { DrawingsPaperUtils } from '../../utils/drawings-paper-utils';
import { PaperPointUtils } from '../../utils/paper-utils';
import { DrawingsGeometryEntity } from './base';
import { DrawingsGeometryEntityConfig } from './drawings-geometry-entity-config';
import { DrawingsGeometryEntityText } from './drawings-geometry-entity-text';

interface DrawingsGeometryEntityAngleArcConfig extends DrawingsGeometryEntityConfig {
  points: [paper.Point, paper.Point, paper.Point];
  color: paper.Color;
  layer: paper.Layer | paper.Group;
  mustExistInPath?: boolean;
  clampPath?: DrawingsAllowedPathType;
  specificViewForRightAngle?: boolean;
  radius?: number;
  textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
}

export class DrawingsGeometryEntityAngleArc implements DrawingsGeometryEntity {
  private layer: paper.Layer | paper.Group;
  private arc: paper.Path;
  private points: [paper.Point, paper.Point, paper.Point];
  private color: paper.Color;
  private text: DrawingsGeometryEntityText;
  private endLine: paper.Path.Line;
  private startLine: paper.Path.Line;
  private mustExistInPath: boolean;
  private strictPath: DrawingsAllowedPathType;
  private specificViewForRightAngle: boolean;
  private radius: number = DrawingsCanvasConstants.angleCircleRadius;
  private _id: string;

  private textRenderParamsObserver: ContextObserver<MeasuresViewSettings>;
  private renderParametersContextObserver: ContextObserverWithPrevious<DrawingsRenderParams>;

  constructor(
    {
      points,
      color,
      layer,
      mustExistInPath,
      clampPath,
      specificViewForRightAngle,
      radius,
      textRenderParamsObserver,
      renderParamsContextObserver,
      id,
    }: DrawingsGeometryEntityAngleArcConfig,
  ) {
    if (radius) {
      this.radius = radius;
    }
    this._id = id;
    this.layer = layer;
    this.points = points;
    this.color = color;
    this.mustExistInPath = mustExistInPath;
    this.strictPath = clampPath;
    this.specificViewForRightAngle = specificViewForRightAngle;
    this.renderParametersContextObserver = renderParamsContextObserver;
    this.textRenderParamsObserver = textRenderParamsObserver;
    this.renderArc(this.renderParametersContextObserver.getContext());
    this.renderParametersContextObserver.subscribe(this.changeVisualData);
  }

  public get id(): string {
    return this._id;
  }

  public set textRotation(value: number) {
    this.text.rotation = -value;
  }

  public changeColor(color: paper.Color): void {
    this.color = color;
    if (this.arc) {
      this.arc.strokeColor = color;
      if (this.startLine) {
        this.arc.strokeColor = color;
      }
      if (this.endLine) {
        this.arc.strokeColor = color;
      }
    }
    if (this.text) {
      this.text.changeColor(color);
    }
  }

  public updatePoints(
    points: [paper.Point, paper.Point, paper.Point],
    strictPath?: paper.Path,
    strictMode?: boolean,
  ): void {
    this.strictPath = strictPath;
    this.mustExistInPath = strictMode;
    this.removeArc();
    this.points = points;
    this.renderArc(this.renderParametersContextObserver.getContext());
  }

  public destroy(): void {
    this.renderParametersContextObserver.unsubscribe(this.changeVisualData);
    this.removeArc();
    if (this.text) {
      this.text.destroy();
      this.text = null;
    }
    if (this.endLine) {
      this.endLine.remove();
      this.endLine = null;
    }
    if (this.startLine) {
      this.startLine.remove();
      this.startLine = null;
    }
  }

  public changeSpecificViewForRightAngle(value: boolean): void {
    this.specificViewForRightAngle = value;
  }

  @autobind
  private changeVisualData(context: DrawingsRenderParams): void {
    this.removeArc();
    this.renderArc(context);
  }

  private renderArc(context: DrawingsRenderParams): void {
    let p3 = this.points[2];
    const [p1, p2] = this.points;
    const startVector = p1.subtract(p2);
    const startAngle = startVector.angle;
    const textRadius = (DrawingsCanvasConstants.angleTextRadiusDiff + this.radius)  / context.zoom;
    if (!p3) {
      if (Math.abs(startAngle) > 90) {
        p3 = new paper.Point(p2.x - textRadius, p2.y);
      } else {
        p3 = new paper.Point(p2.x + textRadius, p2.y);
      }

      if (this.endLine) {
        this.endLine.firstSegment.point = p3;
        this.endLine.lastSegment.point = p2;
      } else {
        this.endLine = new paper.Path.Line(p3, p2);
        this.endLine.strokeColor = this.color;
      }
    } else if (this.endLine) {
      this.endLine.remove();
      this.endLine = null;
    }

    const endVector = p3.subtract(p2);
    const endAngle = endVector.angle;

    const zero = DrawingsCanvasConstants.zeroPoint;
    let angle = startAngle - endAngle;
    let from = new paper.Point(this.radius / context.zoom, 0);
    let textPosition: paper.Point = new paper.Point(textRadius, 0);

    if (startVector.length < textRadius) {
      const newPosition =  textPosition.rotate(startAngle, zero).add(p2);
      if (this.startLine) {
        this.startLine.firstSegment.point = newPosition;
        this.startLine.lastSegment.point = p2;
      } else {
        this.startLine = new paper.Path.Line(newPosition, p2);
        this.startLine.addTo(this.layer);
        this.startLine.strokeColor = this.color;
      }
    } else if (this.startLine) {
      this.startLine.remove();
      this.startLine = null;
    }

    if (endVector.length < textRadius) {
      const newPosition =  textPosition.rotate(endAngle, zero).add(p2);
      if (this.endLine) {
        this.endLine.firstSegment.point = newPosition;
        this.endLine.lastSegment.point = p2;
      } else {
        this.endLine = new paper.Path.Line(newPosition, p2);
        this.endLine.addTo(this.layer);
        this.endLine.strokeColor = this.color;
      }
    } else if (this.points[2] && this.endLine) {
      this.endLine.remove();
      this.endLine = null;
    }

    let checkDirectionPoint = new paper.Point(1 / context.zoom, 0);
    if (Math.abs(angle) > 180) {
      angle =  360 + endAngle - startAngle;
      from = from.rotate(startAngle, zero);
      textPosition = textPosition.rotate(startAngle, zero);
      checkDirectionPoint = checkDirectionPoint.rotate(startAngle + angle / 2, zero);
    } else {
      from = from.rotate(endAngle, zero);
      textPosition = textPosition.rotate(endAngle, zero);
      checkDirectionPoint = checkDirectionPoint.rotate(endAngle + angle / 2, zero);
    }
    let through = from.rotate(angle / 2, zero);
    let to = from.rotate(angle, zero);
    if (this.strictPath && this.mustExistInPath) {
      const segments = this.strictPath.children && this.strictPath.children.length
        ? (this.strictPath.firstChild as paper.Path).segments
        : this.strictPath.segments;
      const pointsIterator = DrawingsPaperUtils.getPolygonSegmentsPointIterator(segments);
      if (!DrawingsPaperUtils.isPointInside(checkDirectionPoint.add(p2), pointsIterator)) {
        angle = 360 - angle;
        through = through.rotate(180, zero);
        const temp = from;
        from = to;
        to = temp;
        textPosition = textPosition.rotate(180, zero);
      }
    }

    textPosition = p2.add(textPosition.rotate(angle / 2, zero));
    let angleValue = Math.round(Math.abs(angle));
    if (angleValue > 360) {
      angleValue = 360 -  angleValue % 360;
    }
    if (this.specificViewForRightAngle && (angleValue === 90 || angleValue === 270)) {
      const fromCalculated = p2.add(from);
      const toCalculated = p2.add(to);
      this.arc = new paper.Path(
        [
          fromCalculated,
          DrawingsPaperUtils.findLastPointOfRectangle(fromCalculated, p2, toCalculated),
          toCalculated,
        ],
      );
      if (this.text) {
        this.text.destroy();
        this.text = null;
      }
    } else {
      if (!this.tryRenderPaperArc(p2.add(from), p2.add(through), p2.add(to))) {
        this.removeText();
        return;
      }
      if (this.text) {
        this.text.updateText(`${angleValue}°`);
        this.text.changePosition([textPosition.x, textPosition.y]);
      } else {
        this.text = new DrawingsGeometryEntityText(
          {
            geometry: textPosition,
            text: `${angleValue}°`,
            layer: this.layer,
            color: this.color,
            renderParamsContextObserver: this.renderParametersContextObserver,
            angle: -this.textRenderParamsObserver.getContext().rotation,
            eventApplier: null,
          },
        );
      }
    }
    this.arc.strokeColor = this.color;
    this.arc.addTo(this.layer);
    this.updateStyleParams(context);
  }

  private removeText(): void {
    if (this.text) {
      this.text.destroy();
      this.text = null;
    }
  }

  private updateStyleParams(context: DrawingsRenderParams): void {
    const dashArray = [DrawingsCanvasConstants.dashArray / context.zoom];
    const strokeWidth = DrawingsCanvasConstants.infoLinesStroke / 2 / context.zoom;
    if (this.endLine) {
      this.endLine.dashArray = dashArray;
      this.endLine.strokeWidth = strokeWidth;
    }
    if (this.startLine) {
      this.startLine.dashArray = dashArray;
      this.startLine.strokeWidth = strokeWidth;
    }
    this.arc.strokeWidth = DrawingsCanvasConstants.infoLinesStroke / context.zoom;
  }

  private tryRenderPaperArc(from: paper.Point, through: paper.Point, to: paper.Point): boolean {
    if (
      from.subtract(through).angle === to.subtract(through).angle
      || PaperPointUtils.arePointsEqual(from, through) && PaperPointUtils.arePointsEqual(through, to)) {
      return false;
    }
    try {
      this.arc = new paper.Path.Arc(from, through, to);
    } catch (e) {
      console.error('invalid points', from, through, to);
      return false;
    }
    return true;
  }

  private removeArc(): void {
    if (this.arc) {
      this.arc.remove();
    }
  }
}
