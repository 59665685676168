import { MenuOptionInfo } from 'common/components/selection-menus';
import { PageOptions, MeasurementOptions, AnnotationOptions } from './drawings-export-options';

export const PAGE_OPTIONS: Array<MenuOptionInfo<PageOptions>> = [{
  name: 'Current page',
  value: PageOptions.Current,
}, {
  name: 'Current file',
  value: PageOptions.CurrentFile,
}, {
  name: 'Pages with measurements',
  value: PageOptions.WithMeasurements,
}, {
  name: 'Opened pages',
  value: PageOptions.OpenedPages,
}, {
  name: 'All pages',
  value: PageOptions.All,
}];

export const  MEASUREMENT_OPTIONS: Array<MenuOptionInfo<MeasurementOptions>> = [{
  name: 'All measurements',
  value: MeasurementOptions.All,
}, {
  name: 'Only visible',
  value: MeasurementOptions.Visible,
}];

export const ANNOTATION_OPTIONS: Array<MenuOptionInfo<AnnotationOptions>> = [
  {
    name: 'Stickers',
    value: AnnotationOptions.Stickers,
  },
  {
    name: 'Dimension Lines',
    value: AnnotationOptions.Rulers,
  },
];

export const FONT_SIZE_OPTIONS = {
  name: 'Font Size',
  elements: [4, 5, 6, 7, 8],
  tooltipText: `Increase this parameter 
    if you need the dimension values on measurements and annotations to be larger`,
};

export const LINE_THICKNESS_OPTIONS = {
  name: 'Scale Factor',
  elements: [1, 2, 3, 4, 5],
  tooltipText: 'Factor increases line size for linear and area measurements in the exported file',
};
