import React, { useCallback, useMemo } from 'react';

import { MenuItem } from 'common/components/menu-item';
import { ConstantFunctions } from 'common/constants/functions';
import { ColorsName } from 'common/enums/kreo-colors';
import { AiBadge } from 'unit-2d-info-panel/content/ai-badge';
import { DrawingsDrawMode } from '../../enums';

interface Props {
  name: string;
  icon: React.ComponentType;
  color?: string;
  id?: string | number;
  onClick: (id: string | number) => void;
  fontSize?: number;
  disabled?: boolean;
}

const MenuItemWrapperComponent: React.FC<Props> = ({
  name,
  icon,
  id,
  onClick,
  color = ColorsName.mainFont,
  disabled,
}) => {
  const onSelectCallback = useCallback((e) => {
    ConstantFunctions.stopEvent(e);
    onClick(id);
  }, [onClick, id]);

  const showBadge = useMemo(() => id === DrawingsDrawMode.BucketFill || id === DrawingsDrawMode.OneClickLine, [id]);

  return (
    <MenuItem
      text={name}
      textColor={color}
      fontSize={14}
      onClick={onSelectCallback}
      withBorder={true}
      size='m'
      Icon={icon}
      disabled={disabled}
      badge={showBadge && <AiBadge />}
    />
  );
};

export const MenuItemWrapper = React.memo(MenuItemWrapperComponent);
