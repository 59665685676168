import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TwoDActions } from '2d/actions/creators';
import { State } from 'common/interfaces/state';


export const useSetAssignPiaLoading = (): void => {
  const dispatch = useDispatch();
  const notSavedInstances = useSelector<State, string[]>(s => s.drawings.notSavedInstances);
  const selectedInstances = useSelector<State, string[]>(s => s.drawings.selectedInstances);

  useEffect(() => {
    const isLoading = notSavedInstances.some(instance =>
      selectedInstances.includes(instance),
    );

    if (isLoading) {
      dispatch(TwoDActions.setIsAssignPiaLoading(true));
    } else {
      dispatch(TwoDActions.setIsAssignPiaLoading(false));
    }
  }, [notSavedInstances, selectedInstances, dispatch]);
};
