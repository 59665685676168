import { RoleData } from 'common/components/company-info/interfaces';
import { RequestStatus } from 'common/enums/request-status';
import { RoleGroup } from 'common/enums/role-group';
import {
  PeopleState,
  UserMap,
} from '../../common/interfaces/people';
import { ReducerMethods } from '../../common/interfaces/reducer-methods';
import { MonoliteHelper } from '../../common/monolite';
import { CompanySubscriptionUsersChangedModel } from '../account/interfaces/company-subscription-users-changed-model';
import {
  CompanyUserEmailPayload,
  CompanyUserInvitationTokenPayload,
  CompanyUsersPayload,
  UpdateUserRolePayload,
} from './actions/payloads';
import { PeopleActionTypes } from './actions/types';
import { PersonVisibleData } from './components/company-info-wrapper';

export const INITIAL_STATE: PeopleState = {
  filter: '',
  invite_info: null,
  inviteUsersSidePanelIsOpen: false,
  isAddGuest: false,
  employees: [],
  userRoles: [],
  guestRoles: [],
  companiesUsers: [],
  usersmap: {},
  displayedRoleGroup: null,
  requests: {
    companiesUsers: RequestStatus.NotRequested,
  },
};

export const peopleReducerMethods: ReducerMethods<PeopleState> = {
  [PeopleActionTypes.COMPANY_PEOPLE_GET_REQUEST]: (s) => {
    return new MonoliteHelper(s)
      .set((_) => _.companiesUsers, [])
      .set((_) => _.requests.companiesUsers, RequestStatus.Loading)
      .get();
  },
  [PeopleActionTypes.COMPANY_PEOPLE_GET_SUCCEEDED]: (state, { people }: CompanyUsersPayload) => {
    return new MonoliteHelper(state)
      .set((_) => _.companiesUsers, people)
      .set((_) => _.requests.companiesUsers, RequestStatus.Loaded)
      .get();
  },
  [PeopleActionTypes.SET_FILTER]: (state, payload: string) => {
    return new MonoliteHelper(state).set((_) => _.filter, payload).get();
  },
  [PeopleActionTypes.SAVE_USER_MAP]: (state, payload: UserMap) => {
    return new MonoliteHelper(state).set((_) => _.usersmap, payload).get();
  },
  [PeopleActionTypes.ADD_TO_USER_MAP]: (state, payload: UserMap) => {
    return new MonoliteHelper(state)
      .set((_) => _.usersmap, { ...state.usersmap, ...payload })
      .get();
  },
  [PeopleActionTypes.REJECT_INVITATION_SUCCEEDED]: (state, { userEmail }: CompanyUserEmailPayload) => {
    if (!state.companiesUsers || !Array.isArray(state.companiesUsers)) {
      return;
    }

    return new MonoliteHelper(state)
      // user emails are stored in firstName field for invited users for some reason
      .setFilter(_ => _.companiesUsers, user => user.firstName !== userEmail)
      .get();
  },
  [PeopleActionTypes.CHANGE_DISPLAYED_ROLE_GROUP]: (state, displayedRoleGroup: RoleGroup | null) => {
    return new MonoliteHelper(state)
      .set((_) => _.displayedRoleGroup, displayedRoleGroup)
      .get();
  },
  [PeopleActionTypes.RESEND_INVITATION_SUCCEEDED]: (
    state,
    { invitationToken }: CompanyUserInvitationTokenPayload,
  ) => {
    if (!state.companiesUsers || !Array.isArray(state.companiesUsers)) {
      return;
    }

    const personIndex = state.companiesUsers.findIndex((p) => p.invitationToken === invitationToken);
    if (personIndex === -1) {
      return state;
    }

    return new MonoliteHelper(state)
      .set((_) => _.companiesUsers[personIndex].invitationResent, true)
      .get();
  },
  [PeopleActionTypes.UPDATE_USER_ROLE_SUCCEEDED]: (state, payload: UpdateUserRolePayload) => {
    const { userId, roleId } = payload;
    if (!state.companiesUsers || !Array.isArray(state.companiesUsers)) {
      return;
    }

    const personIndex = state.companiesUsers.findIndex((p) => p.id === userId);
    if (personIndex === -1) {
      return state;
    }

    return new MonoliteHelper(state)
      .set((_) => _.companiesUsers[personIndex].roleId, roleId)
      .get();
  },
  [PeopleActionTypes.DROP_INVITE_INFO]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.invite_info, null)
      .get();
  },
  [PeopleActionTypes.SET_SHOW_INVITE_USERS_SIDE_PANEL]: (s, payload: boolean) => {
    return new MonoliteHelper(s).set(_ => _.inviteUsersSidePanelIsOpen, payload).get();
  },
  [PeopleActionTypes.SET_IS_ADD_GUEST]: (s, payload: boolean) => {
    return new MonoliteHelper(s).set(_ => _.isAddGuest, payload).get();
  },
  [PeopleActionTypes.SET_EMPLOYEES]: (s, payload: PersonVisibleData[]) => {
    return new MonoliteHelper(s).set(_ => _.employees, payload).get();
  },
  [PeopleActionTypes.SET_USER_ROLES]: (s, payload: RoleData[]) => {
    return new MonoliteHelper(s).set(_ => _.userRoles, payload).get();
  },
  [PeopleActionTypes.SET_GUEST_ROLES]: (s, payload: RoleData[]) => {
    return new MonoliteHelper(s).set(_ => _.guestRoles, payload).get();
  },
  [PeopleActionTypes.UPDATE_SUBSCRIPTION_USERS]: (
    s,
    { users }: CompanySubscriptionUsersChangedModel,
  ) => {
    return new MonoliteHelper(s)
      .set(_ => _.companiesUsers, users)
      .get();
  },
  [PeopleActionTypes.DROP_PEOPLE_STATE]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _, INITIAL_STATE)
      .get();
  },
  [PeopleActionTypes.INVITE_TO_COMPANY]: (state) => {
    return new MonoliteHelper(state)
      .set((_) => _.requests.companiesUsers, RequestStatus.Loading)
      .get();
  },
};
