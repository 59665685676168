import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Mood } from 'common/enums/mood';
import { KreoDialogActions } from 'common/UIKit';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';


export const DAMAGED_AVATAR_COMPANY_DIALOG = 'DAMAGED_AVATAR_COMPANY_DIALOG';
const DESCRIPTION = `The file is either damaged or in an unsupported format.
  Please try uploading a different image or proceed without a logo.`;

export const DamagedAvatarCompanyDialog: React.FC  = () => {
  const dispatch = useDispatch();

  const handleCloseDialog = useCallback(() => {
    dispatch(KreoDialogActions.closeDialog(DAMAGED_AVATAR_COMPANY_DIALOG));
  }, [dispatch]);

  return (
    <ConfirmationDialog
      name={DAMAGED_AVATAR_COMPANY_DIALOG}
      title={`Image Error`}
      text={DESCRIPTION}
      cancelButtonText={'Ok'}
      cancelButtonMood={Mood.Positive}
      onCancel={handleCloseDialog}
    />
  );
};
