import autobind from 'autobind-decorator';
import * as React from 'react';

import { AuthImage } from 'common/components/auth-image';
import { DrawingsApi } from '../../../api/drawings-api';
import { Styled } from './styled';

interface Props {
  created: boolean;
  pdfId: string;
  pageId: string;
  zIndexSpinner?: number;
}

interface State {
  link: string;
}

export class PdfPagePreview extends React.PureComponent<Props, State>  {
  constructor(props: Props) {
    super(props);
    this.state = {
      link: null,
    };
  }


  public render(): React.ReactNode {
    const { created, zIndexSpinner = 0 } = this.props;
    return (
      <Styled.Container zIndexSpinner={zIndexSpinner}>
        {
          created ? (
            <AuthImage
              src={this.state.link}
              onError={this.onError}
              alt={'screenshot preview'}
              showSpinner={true}
            />
          ) : null
        }
      </Styled.Container>
    );
  }

  public componentDidMount(): void {
    if (this.props.created) {
      this.createScreenshotLink();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.created !== prevProps.created && this.props.created) {
      this.createScreenshotLink();
    }
  }

  @autobind
  private onError(): void {
    this.setState({ link: null });
  }

  private createScreenshotLink(): void {
    const { pdfId, pageId } = this.props;
    this.setState({ link: DrawingsApi.getScreenshotLink(pdfId, pageId) });
  }
}
