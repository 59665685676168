import autobind from 'autobind-decorator';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { FlattenSimpleInterpolation } from 'styled-components';
import { getOrCreateRoot } from 'common/UIKit';
import { mathUtils } from 'common/utils/math-utils';
import { GlobalKeyboardEventsFocusRestore } from '../global-keyboard-events-controller';
import { Styled } from './container-styled';

interface Props {
  parentRect: DOMRect;
  bindVertical?: 'top' | 'bottom' | 'center';
  bindHorizontal?: 'left' | 'right' | 'center';
  orientation?: 'vertical' | 'horizontal';
  withBlanket?: boolean;
  closeOnContainerClick?: boolean;
  marginTop?: number;
  onCloseClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  style?: FlattenSimpleInterpolation;
  fillDistance?: boolean;
}


const MARGIN = 20;
const WINDOW_PADDING = 40;

export class InteractiveMenuContainer extends React.PureComponent<Props> {
  private dropDownRef: HTMLDivElement;
  private fillerRef: HTMLDivElement;


  public render(): React.ReactNode {
    return ReactDOM.createPortal(
      <GlobalKeyboardEventsFocusRestore>
        {this.props.fillDistance ? <Styled.FillerContainer ref={x => this.fillerRef = x}/> : null}
        <Styled.Container
          ref={this.saveRef}
          onClick={this.onClick}
          styled={this.props.style}

        >
          {this.props.children}
        </Styled.Container>
        {
          this.props.withBlanket && <Styled.Blank onClick={this.props.onCloseClick}/>
        }
      </GlobalKeyboardEventsFocusRestore>,
      getOrCreateRoot(),
    );
  }

  public componentDidMount(): void {
    const { width, height } = this.dropDownRef.getBoundingClientRect();
    const { parentRect, orientation, bindVertical, bindHorizontal, marginTop } = this.props;
    let bottom = window.innerHeight - parentRect.bottom;
    const topSpace = parentRect.top - WINDOW_PADDING + MARGIN;
    const bottomSpace = bottom - WINDOW_PADDING - MARGIN;
    let top = WINDOW_PADDING;
    const offsetTop = marginTop !== undefined ? marginTop : MARGIN;
    let maxHeight = window.innerHeight - WINDOW_PADDING * 2;

    const isVerticalOrientated = orientation === 'vertical';
    let fillerKey = '';
    let fillerPosition = 0;

    if (isVerticalOrientated) {
      if (topSpace > bottomSpace) {
        maxHeight = topSpace;
        fillerKey = 'bottom';
        fillerPosition = window.innerHeight - parentRect.top;
        this.dropDownRef.style.bottom = `${window.innerHeight - parentRect.top + MARGIN}px`;
      } else {
        fillerKey = 'top';
        maxHeight = bottomSpace;
        fillerPosition = parentRect.bottom;
        this.dropDownRef.style.top = `${parentRect.bottom + offsetTop}px`;
      }
    } else if (bindVertical === 'top') {
      top = mathUtils.clamp(
        parentRect.top,
        WINDOW_PADDING,
        window.innerHeight - Math.min(maxHeight, height) - WINDOW_PADDING,
      );
      fillerKey = 'top';
      fillerPosition = top;
      this.dropDownRef.style.top = `${top}px`;

    } else if (bindVertical === 'bottom') {
      bottom = mathUtils.clamp(
        parentRect.bottom,
        WINDOW_PADDING + Math.min(maxHeight, height),
        window.innerHeight - WINDOW_PADDING,
      );
      fillerKey = 'bottom';
      fillerPosition = bottom;
      this.dropDownRef.style.bottom = `${bottom}px`;
      maxHeight = parentRect.bottom - WINDOW_PADDING;
    } else {
      if (height < maxHeight) {
        top = parentRect.top + parentRect.height / 2;
        top = mathUtils.clamp(
          top,
          WINDOW_PADDING,
          window.innerHeight - WINDOW_PADDING - height);
      }
      fillerKey = 'top';
      fillerPosition = top;
      this.dropDownRef.style.top = `${top}px`;
    }

    let left = parentRect.right + MARGIN;
    let fillerHeight = 0;
    let fillerWidth = 0;
    let fillerLeft = parentRect.right;
    let hideFiller = false;
    if (isVerticalOrientated) {
      fillerHeight = MARGIN;
      fillerWidth = width;
      switch (bindHorizontal) {
        case 'right':
          left = parentRect.right - width;
          break;
        case 'left':
          left = parentRect.left;
          break;
        case 'center':
        default:
          const center = parentRect.left + parentRect.width / 2;
          left = center - width / 2;
      }
      left = mathUtils.clamp(
        left,
        WINDOW_PADDING,
        window.innerWidth - WINDOW_PADDING - width,
      );
      fillerLeft = left;
    } else {
      fillerHeight = height;
      fillerWidth = MARGIN;
      if (window.innerWidth - left - WINDOW_PADDING < width) {
        left = window.innerWidth - width - MARGIN - WINDOW_PADDING;
        fillerLeft = left + width;
        if (left >= parentRect.width) {
          left = parentRect.left - MARGIN - width;
          fillerLeft = left + width;
          if (left < WINDOW_PADDING) {
            left = WINDOW_PADDING + width;
            hideFiller = true;
          }
        }
      }
    }

    if (parentRect.width > this.dropDownRef.offsetWidth) {
      this.dropDownRef.style.left = `${parentRect.left}px`;
      hideFiller = true;
    } else {
      this.dropDownRef.style.left = `${left}px`;
    }
    this.dropDownRef.style.maxHeight = `${maxHeight}px`;
    if (this.fillerRef) {
      if (hideFiller) {
        this.fillerRef.style.display = 'none';
      } else {
        this.fillerRef.style.display = 'block';
      }
      this.fillerRef.style.height = `${fillerHeight}px`;
      this.fillerRef.style.width = `${fillerWidth}px`;
      this.fillerRef.style[fillerKey] = `${fillerPosition}px`;
      this.fillerRef.style.left = `${fillerLeft}px`;
    }
  }

  @autobind
  private saveRef(ref: HTMLDivElement): void {
    this.dropDownRef = ref;
  }

  @autobind
  private onClick(e: React.MouseEvent<HTMLDivElement>): void {
    if (this.props.closeOnContainerClick) {
      this.props.onCloseClick(e);
    }
  }
}
