const createCurrentDrawingKey = (drawingId: string): string => `drawing-progress-${drawingId}`;
const createReportDataKey = (): string => `report-data-progress`;
const createCurrentProjectExportKey = (projectId: string | number): string => `project-export-progress-${projectId}`;

export const ProgressUtils = {
  createCurrentDrawingKey,
  createCurrentProjectExportKey,
  DRAWING_PROGRESS_KEY: 'drawing-progress',
  createReportDataKey,
  REPORT_DATA_PROGRESS_KEY: 'report-data-progress',
  EXTRACT_DRAWING_TYPE_KEY: 'extract-drawing-type',
};

