import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SubscriptionEditorState } from 'common/interfaces/account/subscription-editor-form-state';
import { State } from 'common/interfaces/state';
import { AccountActions } from '../../../../account/actions/creators';
import { BillingPeriodUnit } from '../../../../subscription/enums/billing-period-unit';

export const useBillingPeriod = (): [BillingPeriodUnit, (billingPeriod: BillingPeriodUnit) => void] => {
  const subscriptionEditForm = useSelector<State, SubscriptionEditorState>(s => s.account.subscriptionEditorState);
  const dispatch = useDispatch();
  const billingPeriodUnit = React.useMemo(() => {
    if (!subscriptionEditForm) {
      return BillingPeriodUnit.Year;
    }
    return subscriptionEditForm.billingPeriod === null
      ? BillingPeriodUnit.Year
      : subscriptionEditForm.billingPeriod;
  },
  [subscriptionEditForm]);
  const handleChange = React.useCallback((billingPeriod: BillingPeriodUnit) => {
    dispatch(AccountActions.setSubscriptionEditFormState({ billingPeriod }));
  }, [dispatch]);

  return [billingPeriodUnit, handleChange];
};
