import { ModifyHotkey } from 'common/components/drawings/utils/hotkey-utils';
import { GlobalKeyboardEventsControllerContextProps } from 'common/components/global-keyboard-events-controller';
import { EngineObjectConfig } from '../../common';
import { BaseEventsController } from './base-events-controller';

interface DrawingsContinueKeyListenerHelperConfig extends EngineObjectConfig{
  api: GlobalKeyboardEventsControllerContextProps;
  cancel: () => void;
  apply: () => void;
}

export class BooleanOperationsKeyListeners extends  BaseEventsController<DrawingsContinueKeyListenerHelperConfig> {
  public activate(): void {
    super.activate();
    const { api } = this._config;
    api.addKeyDownEventListener(ModifyHotkey.Escape, this._config.cancel);
    api.addKeyDownEventListener(ModifyHotkey.FinishDraw, this._config.apply);
  }

  public deactivate(): void {
    super.activate();
    const { api } = this._config;
    api.removeKeyDownEventListener(ModifyHotkey.Escape, this._config.cancel);
    api.removeKeyDownEventListener(ModifyHotkey.FinishDraw, this._config.apply);
  }
}
