export enum PaymentMethodType {
  GooglePay = 'GooglePay',
  Card = 'Card',
  ApplePay = 'ApplePay',
  Paypal = 'Paypal',
}

interface Card {
  last4: string;
  brand: string;
}

export interface PaymentMethodInfo {
  id: string;
  type: string;
  card?: Card;
  description?: string;
}
