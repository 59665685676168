import autobind from 'autobind-decorator';
import { DrawingsCanvasConstants } from '../../constants/drawing-canvas-constants';
import { DrawingsInstanceType } from '../../enums';
import { DrawingsPointInfo, ShortPointDescription } from '../../interfaces/drawing-ai-annotation';
import { Vector2Utils } from '../../utils/math-utils/vector2-utils';
import { DrawingsGeometryHighOrderEntity } from '../drawings-geometry-entities';
import {
  DrawingsGeometryEntityModifiable,
} from '../drawings-geometry-entities/base/drawings-geometry-entity-modifiable';
import {
  GetDrawingInstanceCallback,
} from '../interfaces';
import { DrawingsDragCallbackParams, DrawingsDragProcessingHelper } from './drag-instances';
import { DrawingsGeometryEntityHelper } from './drawings-geometry-entity-helper';
import { DrawingsInstancesChangesProcessor } from './drawings-instances-changes-processor';
import { DrawingsRenderedPointsCache } from './drawings-rendered-points-cache';
import { DrawingsViewHelper } from './drawings-view-helper';
import { DrawingsCursorTypeHelper } from './visual-helpers';

interface DrawingsGeometryModifyConfig {
  rendererHelper: DrawingsGeometryEntityHelper;
  viewHelper: DrawingsViewHelper;
  changesProcessor: DrawingsInstancesChangesProcessor;
  dragHelper: DrawingsDragProcessingHelper;
  cachedPoints: DrawingsRenderedPointsCache;
  cancelModify: () => void;
  getPointInfo: (pointId: string) => DrawingsPointInfo;
  getInstance: GetDrawingInstanceCallback;
  cursorHelper: DrawingsCursorTypeHelper;
  getCurrentPageInfo: () => Core.Document.PageInfo;
}

export enum AlignmentType {
  TOP = 270,
  RIGHT = 180,
  BOTTOM = 90,
  LEFT = 0,
}

export type ModifiableInstanceType = DrawingsGeometryHighOrderEntity & DrawingsGeometryEntityModifiable;

export class DrawingsGeometryModify {
  private _config: DrawingsGeometryModifyConfig;
  private _modifyEnabled: boolean;

  private _instanceForModifyId: string;
  private _changePointsSelectionCallback: (ids: string[]) => void;

  constructor(config: DrawingsGeometryModifyConfig) {
    this._config = config;
  }

  public get modifyEnabled(): boolean {
    return this._modifyEnabled;
  }

  public get instanceId(): string {
    return this._instanceForModifyId;
  }

  public get selectedPoints(): string[] {
    if (this._instanceForModifyId) {
      const instance = this._config.rendererHelper.getInstance(this.instanceId);
      if (instance) {
        return instance.selectedPointsIds;
      }
    }
    return null;
  }

  public set changePointsSelection(value: (ids: string[]) => void) {
    this._changePointsSelectionCallback = value;
    if (this._instanceForModifyId) {
      const instance = this._config.rendererHelper.getInstance(this.instanceId);
      if (instance) {
        instance.onChangePointsSelection = this._changePointsSelectionCallback;
      }
    }
  }

  @autobind
  public startModify(instanceId: string): void {
    this._instanceForModifyId = instanceId;
    this._config.viewHelper.onLeftMouseClick = this.layoutClick;
    this._modifyEnabled = true;
    if (this._changePointsSelectionCallback) {
      const instance = this._config.rendererHelper.getInstance(this.instanceId);
      if (instance) {
        instance.onChangePointsSelection = this._changePointsSelectionCallback;
      }
    }
  }

  @autobind
  public stopModify(): void {
    if (this.instanceId) {
      const instance = this._config.rendererHelper.getInstance(this.instanceId);
      if (instance) {
        instance.updatePointsSelection([], false, true);
      }
    }
    this._config.dragHelper.stopDrag();
    this._config.viewHelper.restoreDefaultEvent('onLeftMouseClick');
    this._instanceForModifyId = null;
    this._modifyEnabled = false;
  }

  public setSelectedPointsAlignment(alignment: AlignmentType): void {
    const instance = this._config.rendererHelper.getInstance(this.instanceId);
    const { xs, ys } = instance.selectedPointsIds.reduce(
      (p, c) => {
        const point = this._config.cachedPoints.getPoint(c);
        p.xs.push(point.x);
        p.ys.push(point.y);
        return p;
      },
      { xs: [], ys: [] },
    );
    if (alignment === AlignmentType.TOP || alignment === AlignmentType.BOTTOM) {
      const y = alignment === AlignmentType.TOP ? Math.min(...ys) : Math.max(...ys);
      for (const pointId of instance.selectedPointsIds) {
        const point = this._config.cachedPoints.getPoint(pointId);
        point.y = y;
        this._config.cachedPoints.setPoint(pointId, point);
      }
    } else if (alignment === AlignmentType.LEFT || alignment === AlignmentType.RIGHT) {
      const x = alignment === AlignmentType.LEFT ? Math.min(...xs) : Math.max(...xs);
      for (const pointId of instance.selectedPointsIds) {
        const point = this._config.cachedPoints.getPoint(pointId);
        point.x = x;
        this._config.cachedPoints.setPoint(pointId, point);
      }
    }
    const elementMeasures =
      this._config.rendererHelper.updatePointsPositionInInstance(instance.selectedPointsIds, this.instanceId);
    if (!elementMeasures) {
      for (const pointId of instance.selectedPointsIds) {
        this._config.cachedPoints.setPointFromState(pointId);
      }
      this._config.rendererHelper.updatePointsPositionInInstance(instance.selectedPointsIds, this.instanceId);
    } else {
      this._config.changesProcessor.finishEditPoints(instance.selectedPointsIds, elementMeasures);
    }
  }

  public splitByPoints(): void {
    const instance = this._config.rendererHelper.getInstance(this._instanceForModifyId);
    const { id, selectedPointsIds, instanceType } = instance;


    if (instanceType === DrawingsInstanceType.Count) {
      if (!this._config.rendererHelper.canRemovePoints(selectedPointsIds, this._instanceForModifyId)) {
        return;
      }
      this._config.changesProcessor.splitCount(id, selectedPointsIds);
    } else if (instanceType === DrawingsInstanceType.Polyline) {
      this._config.changesProcessor.splitPolyline(id, selectedPointsIds);
    } else {
      return;
    }
    instance.updatePointsSelection([], false);
  }


  @autobind
  public startDragPoint(id: string): void {
    if (!this._modifyEnabled) {
      return;
    }
    this._config.cursorHelper.grabbing = true;
    const defaultValidPoint = this._config.cachedPoints.getPoint(id);
    this._config.dragHelper.setCallback(this.editPointPosition, { defaultValidPoint, entityId: id });
  }

  @autobind
  public removeSelectedPoints(): void {
    const instance = this._config.rendererHelper.getInstance(this.instanceId);
    if (instance.selectedPointsIds.length) {
      this._config.changesProcessor.removePointsProcessor(instance.selectedPointsIds, this.instanceId);
      instance.updatePointsSelection([], false);
    }
  }

  @autobind
  private layoutClick(): void {
    const instance = this._config.rendererHelper.getInstance(this.instanceId);
    if (instance.selectedPointsIds && instance.selectedPointsIds.length) {
      instance.updatePointsSelection([], false);
    } else if (!this.instanceId.startsWith(DrawingsCanvasConstants.visualSearch)) {
      this._config.viewHelper.restoreDefaultEvent('onLeftMouseClick');
      this._config.cancelModify();
    }
  }

  @autobind
  private editPointPosition(params: DrawingsDragCallbackParams): boolean {
    const entity = this._config.rendererHelper.getInstance(this._instanceForModifyId);
    const pointsIds = entity.selectedPointsIds;
    return this.updatePointsPosition(params, pointsIds, this._instanceForModifyId);
  }

  private updatePointsPosition(
    { point, finish, entityId }: DrawingsDragCallbackParams,
    pointsIds: string[],
    instanceId: string,
  ): boolean {
    const currentPointPosition = this._config.cachedPoints.getPoint(entityId);
    const diff = point.subtract(currentPointPosition);
    let isValidPosition = true;
    const { width, height } = this._config.getCurrentPageInfo();
    const windowRect = [[0, 0], [width, height]] as ShortPointDescription[];

    for (const pointId of pointsIds) {
      this._config.cachedPoints.addToPoint(pointId, diff);
      const shortPoint = this._config.cachedPoints.getConvertedPoint(pointId);

      if (!Vector2Utils.isPointInRect(shortPoint, windowRect)) {
        isValidPosition = false;
      }
    }
    if (!isValidPosition) {
      return false;
    }

    const elementMeasures =
      this._config.rendererHelper.updatePointsPositionInInstance(pointsIds, instanceId);
    if (!elementMeasures) {
      return false;
    }
    if (finish) {
      this._config.changesProcessor.finishEditPoints(pointsIds, elementMeasures);
      this._config.cursorHelper.grabbing = false;
    }
    return true;
  }
}
