export const METRIC_IDS = {
  selectTool: 'select_tool',
  rectangle2pointsTool: 'rectangle_2_points_tool',
  rectangle3pointsTool: 'rectangle_3_points_tool',
  polylineTool: 'polyline_tool',
  bucketTool: 'bucket_tool',
  polygonTool: 'polygon_tool',
  autoCountTool: 'auto_count_tool',
  countTool: 'count_tool',
  wandTool: 'wand_tool',
  finderTool: 'finder_tool',
  dropperTool: 'dropper_tool',
  commentTool: 'comment_tool',
  stickerAnnotationAnnotation: 'sticker_annotation_annotation',
  dimensionLineAnnotation: 'dimension_line_annotation',
  symbolsLibraryAnnotation: 'symbols_library_annotation',
  launchingAutoMeasureForSinglePage: 'launching_auto_measure_for_a_single_page',
  launchingAutoMeasureForAllPages: 'launching_auto_measure_for_all_pages',
  uploadNewFilesInProject: 'upload_new_files_in_project',
  autoCountToolFindText: 'auto_count_tool_find_text',
  autoCountToolFindGeometry: 'auto_count_tool_find_geometry',
  autoCountCreateCounts: 'auto_count_create_counts',
  autoCountSpecifyZone: 'auto_count_specify_zone',
  exportPdf: 'export_pdf',
  legendAnnotation: 'legend_annotation',
  aiSuggest: 'aisuggest',
  createProject: 'create_project',
  addNewUsers: 'add_new_users',
  addFolderForProjects: 'add_folder_for_projects',
  addNewTemplate: 'add_new_template',
  addNewProject: 'add_new_project',
  closeBillingDialog: 'close_billing_dialog',
  closeMobileBillingDialog: 'close_mobile_billing_dialog',
  aiLine: 'one-click-line',
};
