import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createDefaultRequestBody, GptAIChat, GptModels } from 'common/ai-chat/gpt/gpt-ai-chat';
import { DrawingDialogs } from 'common/components/drawings/constants/drawing-dialogs';
import { useDrawingsLayoutApi } from 'common/components/drawings/drawings-layout-api-context';
import { useCurrentDrawingInfo } from 'common/components/drawings/hooks';
import { DrawingsFile } from 'common/components/drawings/interfaces/drawings-file-info';
import { ProgressActions, ProgressBarType } from 'common/components/progress';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { useOpenCloseDialog } from 'common/UIKit';
import { ProgressUtils } from 'common/utils/progress-utils';
import { MessageUtils } from 'unit-2d-copilot/utils.ts';
import { ViewModelStatus } from 'unit-projects/enums/view-model-status';
import { MetricNames, useAnalytics } from 'utils/posthog';

// eslint-disable-next-line max-len
const PROMPT = `What type of drawing is this? Choose from the list: Floor Plans; Elevation Drawings; Section Drawings; Site Plans; Engineering Blueprints; Electrical Schematics; Piping and Instrumentation Diagrams (P&ID); Structural Blueprints; HVAC Plans; Plumbing Plans; Civil Engineering Blueprints; Bridge and Tunnel Plans; Drainage and Utilities Plans; Manufacturing and Product Design Blueprints; Fabrication Drawings; Exploded Views; Landscape Architecture Blueprints; Grading Plans; Naval Architecture Blueprints; Aerospace Engineering Blueprints; Spacecraft Blueprints; Don't refer to yourself. Don't explain what you're doing. Specify only the option from the list.`;


enum DrawingType {
  FloorPlans = 'Floor Plans',
  ElevationDrawings = 'Elevation Drawings',
  SectionDrawings = 'Section Drawings',
  SitePlans = 'Site Plans',
  EngineeringBlueprints = 'Engineering Blueprints',
  ElectricalSchematics = 'Electrical Schematics',
  PipingAndInstrumentationDiagrams = 'Piping and Instrumentation Diagrams (P&ID)',
  StructuralBlueprints = 'Structural Blueprints',
  HVACPlans = 'HVAC Plans',
  PlumbingPlans = 'Plumbing Plans',
  CivilEngineeringBlueprints = 'Civil Engineering Blueprints',
  BridgeAndTunnelPlans = 'Bridge and Tunnel Plans',
  DrainageAndUtilitiesPlans = 'Drainage and Utilities Plans',
  ManufacturingAndProductDesignBlueprints = 'Manufacturing and Product Design Blueprints',
  FabricationDrawings = 'Fabrication Drawings',
  ExplodedViews = 'Exploded Views',
  LandscapeArchitectureBlueprints = 'Landscape Architecture Blueprints',
  GradingPlans = 'Grading Plans',
  NavalArchitectureBlueprints = 'Naval Architecture Blueprints',
  AerospaceEngineeringBlueprints = 'Aerospace Engineering Blueprints',
  SpacecraftBlueprints = 'Spacecraft Blueprints',
  Unknown = 'Unknown',
}

const ALL_TYPES = Object.values(DrawingType);

const buildIncorrectTypeText = (type: string): string => {
  // eslint-disable-next-line max-len
  return `Based on your drawing. Our AI has determined that it is an ${type}. Auto Measure only works on residential floor plans.`;
};
const NOT_SUPPORTED_TITLE = 'Unsupported Drawing Detected';
const NO_TEXT = `Couldn't recognize the type of drawing. You may have mistakes in results`;
const NO_TEXT_TITLE = 'Unable to Identify Your Drawing';

const SETTINGS = {
  model: GptModels.gpt35Turbo,
};

export function useCheckDrawingType(): [(launch: () => void) => void, boolean] {
  const drawingsApi = useDrawingsLayoutApi();
  const [inProgress, setInProgress] = useState(false);

  const aiChat = useMemo(() => new GptAIChat(), []);
  const [ open, close ] = useOpenCloseDialog(DrawingDialogs.AUTO_MEASURE_TYPE_DIALOG);
  const isCancelled = useRef(false);

  const addProgress = useActionDispatch(ProgressActions.addOrUpdateProgress);
  const removeProgress = useActionDispatch(ProgressActions.removeProgress);
  useEffect(() => {
    return () => {
      removeProgress(ProgressUtils.EXTRACT_DRAWING_TYPE_KEY);
      close();
      isCancelled.current = true;
      aiChat.cancel();
    };
  }, [close, removeProgress, aiChat]);
  const { sendEvent } = useAnalytics();
  const currentDrawing = useCurrentDrawingInfo();
  const pdfs = useSelector<State, Record<string, DrawingsFile>>(
    s => s.drawings.files.entities as Record<string, DrawingsFile>,
  );

  const sendAnalytics = useCallback((drawingType: string) => {
    const currentDrawingInfo = currentDrawing ? {
      drawingType,
      pageName: currentDrawing.name,
      pageNumber: currentDrawing.pageNumber + 1,
      fileName: pdfs[currentDrawing.pdfId].properties.name,
    } : { drawingType };
    sendEvent(
      MetricNames.measurements.amClassification,
      currentDrawingInfo,
    );
  }, [currentDrawing, pdfs, sendEvent]);

  const check = useCallback((launch: () => void) => {
    if (currentDrawing?.recognitionStatus === ViewModelStatus.Ready) {
      launch();
      return;
    }
    new Promise<string>((resolve, reject) => {
      setInProgress(true);
      addProgress({
        progressKey: ProgressUtils.EXTRACT_DRAWING_TYPE_KEY,
        progressBar: {
          progressTitle: 'Defining Drawing Type',
          type: ProgressBarType.Indeterminate,
        },
      });
      drawingsApi.getCurrentPageText().then((text) => {
        if (!isCancelled.current) {
          if (!text) {
            open({ description: NO_TEXT, title: NO_TEXT_TITLE, onSkip: launch });
            setInProgress(false);
            sendAnalytics(DrawingType.Unknown);
            removeProgress(ProgressUtils.EXTRACT_DRAWING_TYPE_KEY);
          } else {
            const body = createDefaultRequestBody([{
              role: 'user',
              content: MessageUtils.buildFirstPrompt(PROMPT, text),
            }], SETTINGS.model);
            aiChat.send(body).then(resolve).catch(reject);
          }
        } else {
          removeProgress(ProgressUtils.EXTRACT_DRAWING_TYPE_KEY);
          setInProgress(false);
        }
      }).catch(reject);
    }).then(drawingType => {
      removeProgress(ProgressUtils.EXTRACT_DRAWING_TYPE_KEY);
      setInProgress(false);
      if (!ALL_TYPES.includes(drawingType as DrawingType)) {
        drawingType = DrawingType.Unknown;
      }
      sendAnalytics(drawingType);
      if (DrawingType.FloorPlans !== drawingType) {
        open({ description: buildIncorrectTypeText(drawingType), title: NOT_SUPPORTED_TITLE, onSkip: launch });
      } else {
        launch();
      }
    }).catch((error) => {
      removeProgress(ProgressUtils.EXTRACT_DRAWING_TYPE_KEY);
      setInProgress(false);
      console.error('Error checking drawing type', error);
      launch();
    });

  }, [drawingsApi, aiChat, open, currentDrawing, sendAnalytics, addProgress, removeProgress]);
  return [check, inProgress];
}
