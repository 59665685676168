const ITEM_NAME = 'itemName';
const ASSEMBLY_NAME = 'assemblyName';
const FILE_NAME = 'fileName';
const PAGE_NAME = 'pageName';
const PARENT_GROUP = 'parentGroup';
const MEASURE_NAME = 'measureName';
const NAME = 'name';
const AREA = 'area';
const PERIMETER = 'perimeter';
const HEIGHT = 'height';
const THICKNESS = 'thickness';
const VOLUME = 'volume';
const VERTICAL_AREA = 'verticalArea';
const LENGTH = 'length';
const COUNT = 'count';
const POINTS_COUNT = 'pointsCount';
const SEGMENTS_COUNT = 'segmentsCount';
const CREATED_BY = 'createdBy';
const CREATED_DATE = 'createdDate';
const LAST_EDITED_BY = 'lastEditedBy';
const LAST_EDITED_DATE = 'lastEditedDate';


export const DefaultColumnColdIdConstants = {
  ITEM_NAME,
  ASSEMBLY_NAME,
  FILE_NAME,
  PAGE_NAME,
  PARENT_GROUP,
  MEASURE_NAME,
  NAME,
  AREA,
  PERIMETER,
  HEIGHT,
  THICKNESS,
  VOLUME,
  VERTICAL_AREA,
  LENGTH,
  COUNT,
  POINTS_COUNT,
  SEGMENTS_COUNT,
  CREATED_BY,
  CREATED_DATE,
  LAST_EDITED_BY,
  LAST_EDITED_DATE,
};

export const defaultVisibleItemColumnSet: Set<string> = new Set([
  DefaultColumnColdIdConstants.ITEM_NAME,
  DefaultColumnColdIdConstants.ASSEMBLY_NAME,
  DefaultColumnColdIdConstants.FILE_NAME,
  DefaultColumnColdIdConstants.PAGE_NAME,
  DefaultColumnColdIdConstants.PARENT_GROUP,
  DefaultColumnColdIdConstants.MEASURE_NAME,
]);
