export enum NoScaleDescriptionType {
  Default,
  BucketFIll,
  OneClickLine,
}

export const Descriptions = {
  [NoScaleDescriptionType.Default]: 'Scale is not set for this page yet. Would you like to set it?',
  [NoScaleDescriptionType.BucketFIll]: 'One-click Area works only on pages with a set scale. Would you like to set it?',
  // eslint-disable-next-line max-len
  [NoScaleDescriptionType.OneClickLine]: 'One-click Line works only on pages with a set scale. Would you like to set it?',
};
