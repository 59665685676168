import { IconButton, H5, ProgressBar, Icons } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RenderIf } from 'common/components/render-if';
import { METRIC_IDS } from 'common/constants/id-maps';
import { RequestStatus } from 'common/enums/request-status';
import { CurrentCountry } from 'common/environment/current-country-service';
import { State } from 'common/interfaces/state';
import { KreoDialogActions } from 'common/UIKit';
import { AccountActions } from '../../../../units/account/actions/creators';
import { ValidationField } from '../../../../units/account/actions/payloads';
import { CompanySubscriptionModel } from '../../../../units/account/interfaces/company-subscription-model';
import { AccountSelectors } from '../../../../units/account/selectors';
import { PaymentMethodActions } from '../../actions/creators';
import { BillingPeriodUnit } from '../../enums/billing-period-unit';
import { BillingPricingModel } from '../../enums/billing-pricing-model';
import { EstimateSubscriptionError } from '../../interfaces';
import { BillingCountryModel } from '../../interfaces/billing-country-model';
import { BillingPaymentModel } from '../../interfaces/billing-payment-model';
import { EstimateSubscriptionModel } from '../../interfaces/estimation-subscription-model';
import { SubscriptionAddon } from '../../interfaces/subscription-addon';
import { SubscriptionDetailsModel } from '../../interfaces/subscription-details';
import { DecrementInvitedEmailsDialog } from '../decrement-invited-emails-dialog';
import { PaymentDetailsDialog } from '../payment-details-dialog';
import { ApplyButton } from '../subscription-details/apply-button';
import { Coupons } from '../subscription-details/coupons';
import { SubscriptionControllButtons } from '../subscription-details/subscription-controll-buttons';
import { SubscriptionPrice } from '../subscription-details/subscription-price';
import { AddonListBlock } from './addon-list-block';
import { BillingAddressBlock } from './billing-address-block';
import { ChangeBillingRadioMenu } from './change-billing-radio-menu';
import { PaymentDetailsBlock } from './payment-details-block';
import { PaymentManagerBlock } from './payment-manager-block';
import { PaymentMethodNotAddedDialog } from './payment-method-not-added-dialog';
import { Styled } from './styled';
import { TeamSizeBlock } from './team-size-block';


interface StateProps {
  availableCountries: BillingCountryModel[];
  paymentMethodRequestStatus: RequestStatus;
  currentCountry: CurrentCountry;
  paymentMethods: BillingPaymentModel[];
  primaryCardId: string;
  backupCardId: string;
  subscription: CompanySubscriptionModel;
  billingEstimation: EstimateSubscriptionModel;
}

interface DispatchProps {
  getBillingHistory: (limit: number, offset: string) => void;
  resetVatNumberValidation: () => void;
  loadPaymentData: () => void;
  openDialog: (name: string) => void;
}

interface Props extends StateProps, DispatchProps {
  onSubscriptionChange: (subscription: SubscriptionDetailsModel) => void;
  onApplyClick?: () => void;
  onChangePlanClick?: () => void;
  onPauseSubscriptionClick?: () => void;
  onChangePaymentCardId: (id: string) => void;
  onCountryIdChanged: (id: string) => void;
  onVatNumberIdChanged: (vatNumber: string) => void;
  onClickCloseButton: () => void;
  header: string;
  estimationError: EstimateSubscriptionError;
  applyButtonCaption: string;
  currentSubscription: SubscriptionDetailsModel;
  currentTeamSize: number;
  minTeamSize: number;
  addons: SubscriptionAddon[];
  paymentCardId: string | null;
  countryId: string | null;
  vatNumber: string | null;
  isHandleInProgress: boolean;
  hideChangeSubscription?: boolean;
  paymentDetails?: string[];
}

class SubscriptionDetailsNewUserComponent extends React.Component<Props> {
  private containerRef: HTMLDivElement = null;

  public render(): JSX.Element {
    const {
      currentSubscription,
      addons,
      countryId,
      hideChangeSubscription,
      vatNumber,
      paymentCardId,
      currentTeamSize,
      minTeamSize,
      estimationError,
      subscription,
      billingEstimation,
      header,
      onClickCloseButton,
    } = this.props;
    const planVariant = currentSubscription.planVariant;
    const isTeamPlan = planVariant && planVariant.pricingModel === BillingPricingModel.PerUnit;
    const availableAddons = addons.filter((addon) =>
      planVariant.availableAddons.includes(addon.id),
    );
    const progressBarCompleted = [countryId, paymentCardId].filter((id) => id);
    const couponPlaceHolder = 'Enter the promo code';

    return (
      <>
        <Styled.Container ref={this.saveContainerRef}>
          <Styled.ReviewCard>
            <Styled.HeaderWrapper>
              <H5>{header}</H5>
            </Styled.HeaderWrapper>
            <Styled.ContentWrapper>
              <Styled.Line isCutLine={isTeamPlan && !hideChangeSubscription}/>
              <Styled.Block>
                <BillingAddressBlock
                  countryId={countryId}
                  vatNumber={vatNumber}
                  headerText={'Select country'}
                  onCountryIdChanged={this.props.onCountryIdChanged}
                  onVatNumberIdChanged={this.onVatNumberChange}
                />
              </Styled.Block>
              <Styled.Block>
                <PaymentManagerBlock
                  paymentCardId={paymentCardId}
                  onChangePaymentCardId={this.props.onChangePaymentCardId}
                />
              </Styled.Block>
              <RenderIf condition={isTeamPlan && !hideChangeSubscription}>
                <Styled.Block>
                  <TeamSizeBlock
                    currentSubscription={currentSubscription}
                    currentTeamSize={currentTeamSize}
                    minTeamSize={minTeamSize}
                    headerText={'Choose your team size'}
                    onSubscriptionChange={this.props.onSubscriptionChange}
                    openDialog={this.props.openDialog}
                  />
                </Styled.Block>
              </RenderIf>
              <RenderIf condition={!hideChangeSubscription && availableAddons?.length > 1}>
                <Styled.Block>
                  <AddonListBlock
                    headerText={'Select add-ons'}
                    addons={addons}
                    currentSubscription={currentSubscription}
                    hideChangeSubscription={hideChangeSubscription}
                    onSubscriptionChange={this.props.onSubscriptionChange}
                    canAddAddons={true}
                  />
                </Styled.Block>
              </RenderIf>
            </Styled.ContentWrapper>
          </Styled.ReviewCard>
          <Styled.PlanCard isActiveButton={!!countryId}>
            <Styled.MobileHeader>
              <Styled.MobileHeaderWrapper>
                <H5>{header}</H5>
              </Styled.MobileHeaderWrapper>
              <Styled.MobileCloseButton id={METRIC_IDS.closeMobileBillingDialog}>
                <IconButton
                  Icon={Icons.CloseSmall}
                  onClick={onClickCloseButton}
                  width={30}
                  height={30}
                  iconHeight={10}
                />
              </Styled.MobileCloseButton>
            </Styled.MobileHeader>
            <SubscriptionPrice
              currentSubscription={currentSubscription}
              addons={availableAddons}
              isViewMode={true}
            />
            <RenderIf condition={!hideChangeSubscription}>
              <ChangeBillingRadioMenu
                onSelectionChanged={this.changePeriod}
                billingPeriod={this.props.currentSubscription.billingPeriod}
              />
            </RenderIf>
            <ApplyButton
              applyButtonCaption={this.props.applyButtonCaption}
              onApplyClick={this.props.onApplyClick}
              openPaymentDialog={this.props.openDialog}
              inProgress={this.props.isHandleInProgress}
              countryId={countryId}
            />
            <Styled.ProgressBarContainer>
              <ProgressBar size={2} completed={progressBarCompleted.length} />
            </Styled.ProgressBarContainer>
            {
              !hideChangeSubscription && <Coupons
                error={estimationError}
                coupons={this.props.currentSubscription.coupons}
                addCoupons={this.addCoupons}
                placeHolder={couponPlaceHolder}
              />
            }
            <RenderIf condition={!!subscription || !!billingEstimation && !hideChangeSubscription}>
              <PaymentDetailsBlock
                subscription={subscription}
                billingEstimation={billingEstimation}
                openDialog={this.props.openDialog}
              />
            </RenderIf>
            <SubscriptionControllButtons
              onChangePlanClick={this.props.onChangePlanClick}
              onPauseSubscriptionClick={this.props.onPauseSubscriptionClick}
            />
          </Styled.PlanCard>
          <Styled.CloseButtonContainer id={METRIC_IDS.closeBillingDialog}>
            <IconButton
              Icon={Icons.CloseSmall}
              onClick={onClickCloseButton}
              width={30}
              height={30}
              iconHeight={10}
            />
          </Styled.CloseButtonContainer>
        </Styled.Container>
        <PaymentMethodNotAddedDialog />
        <RenderIf condition={!!subscription || !hideChangeSubscription && !!billingEstimation}>
          <PaymentDetailsDialog />
        </RenderIf>
        <DecrementInvitedEmailsDialog />
      </>
    );
  }

  public componentDidMount(): void {
    this.props.getBillingHistory(5, null);
    this.props.loadPaymentData();
    this.containerRef.scrollBy(0, -window.innerHeight);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    this.setCountryIfNeeded(prevProps);
    this.updatePaymentMethodToValid();
  }

  private setCountryIfNeeded(prevProps: Readonly<Props>): void {
    const { currentCountry, countryId, paymentMethodRequestStatus } = this.props;
    if (
      paymentMethodRequestStatus === RequestStatus.Loaded
      && paymentMethodRequestStatus !== prevProps.paymentMethodRequestStatus
      && !countryId
      && currentCountry
    ) {
      this.setCurrentCountryAsSelected();
    }
  }

  private setCurrentCountryAsSelected(): void {
    const { currentCountry, availableCountries } = this.props;
    const country = availableCountries.find(x => x.id === currentCountry.country);
    if (country) {
      this.props.onCountryIdChanged(country.id);
    }
  }

  private updatePaymentMethodToValid(): void {
    const { paymentMethods, primaryCardId, backupCardId, paymentCardId, onChangePaymentCardId } = this.props;
    if (!paymentMethods?.length || paymentMethods.some(x => x.id === paymentCardId)) {
      return;
    }

    if (primaryCardId && paymentMethods.some(x => x.id === primaryCardId)) {
      onChangePaymentCardId(primaryCardId);
      return;
    }

    if (backupCardId && paymentMethods.some(x => x.id === backupCardId)) {
      onChangePaymentCardId(backupCardId);
      return;
    }

    onChangePaymentCardId(paymentMethods[0].id);
  }

  @autobind
  private saveContainerRef(ref: HTMLDivElement): void {
    this.containerRef = ref;
  }

  @autobind
  private onVatNumberChange(vatNumber: string): void {
    this.props.onVatNumberIdChanged(vatNumber);
    this.props.resetVatNumberValidation();
  }

  @autobind
  private addCoupons(coupons: string[]): void {
    this.props.onSubscriptionChange({
      ...this.props.currentSubscription,
      coupons,
    });
  }

  @autobind
  private changePeriod(billingPeriod: BillingPeriodUnit): void {
    this.props.onSubscriptionChange({
      ...this.props.currentSubscription,
      billingPeriod,
    });
  }
}

function mapStateToProps(state: State): StateProps {
  const payment = state.account.payment;

  return {
    availableCountries: state.account.billingCountries,
    paymentMethodRequestStatus: state.account.paymentStatus,
    currentCountry: state.account.currentCountry,
    paymentMethods: payment && payment.paymentSources,
    primaryCardId: payment && payment.primaryCardId,
    backupCardId: payment && payment.backupCardId,
    subscription: AccountSelectors.currentSubscription(state),
    billingEstimation: state.account.billingEstimation,
  };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchProps {
  return {
    getBillingHistory: (limit, offset) =>
      dispatch(PaymentMethodActions.getBillingHistory({ limit, offset })),
    resetVatNumberValidation: () => dispatch(AccountActions.setSubscribeError(ValidationField.VatNumber, '')),
    loadPaymentData: () => dispatch(PaymentMethodActions.getPaymentData()),
    openDialog: (name) => dispatch(KreoDialogActions.openDialog(name)),
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export const SubscriptionDetailsNewUser = connector(SubscriptionDetailsNewUserComponent);
