import { FilterData } from 'common/components/drawings/interfaces/drawing-filters';
import { RequestStatus } from 'common/enums/request-status';
import { DrawingsGroupMeasure } from '..';
import { DrawingMarkShapes, DrawingStrokeStyles } from '../constants/drawing-styles';
import { FinishDrawApi } from '../drawings-geometry';
import { DrawingsDrawMode } from '../enums';
import { AutoMeasurePageSetting } from '../enums/auto-measure-page-setting';
import { CursorHintType } from '../layout-components/drawings-cursor-hint';
import {
  AnnotationOptions,
  MeasurementOptions,
  PageOptions,
} from '../layout-components/top-control-group/drawings-export/drawings-export-options';
import { AnnotationLegendItem } from './annotation-legend-tree';
import { PdfStatistic } from './api-responses/pdf-geometry-response';
import { AutoMeasureOption } from './auto-measure-option';
import { DrawingContextMenuPosition } from './context-menu-position';
import { DrawingSnapping } from './drawing';
import { DrawingsFilesData, DrawingsPointInfo, ShortPointDescription } from './drawing-ai-annotation';
import { DrawingsFiles } from './drawings-file-info';
import { DrawingsGeometryGroup } from './drawings-geometry-group';
import { DrawingsGeometryInstance } from './drawings-geometry-instance';
import { DrawingsGroupTemplate } from './drawings-group-template';
import { DrawingsInstanceMeasure } from './drawings-instance-measure';
import { DrawingsShortInfo } from './drawings-short-drawing-info';
import { DrawingsSnappingGeometry } from './drawings-snapping-geometry';
import {
  DrawingUserAnnotationImage,
  DrawingUserAnnotationRuler,
  DrawingUserAnnotationSticker,
} from './drawings-user-annotation';
import { HoverState } from './hover-state';
import { MagicSearchState } from './magic-search-state';
import { PdfTextRects } from './pdf-text-rectangle';
import { TextSearchResultsInfo, TextSearchSettings } from './text-search';
import { WizzardToolsFullState } from './wizzard-tool-state';


export enum VisualSearchType {
  Geometry,
  Text,
}

export interface DrawingsUserAnnotationsState {
  readonly stickers: Record<string, DrawingUserAnnotationSticker>;
  readonly images: Record<string, DrawingUserAnnotationImage>;
  readonly rulers: Record<string, DrawingUserAnnotationRuler>;
  readonly legend: ShortPointDescription;
  readonly groupsMeasuresCache: Record<string, DrawingsGroupMeasure>;
  readonly annotationLoadingStatus: RequestStatus;
  readonly selectedAnnotations: string[];
  readonly currentDrawingId: string;

  readonly tempSticker: { position: ShortPointDescription, hasText: boolean };
}

export interface DrawingsFSHighlightState {
  readonly filesWithSelectedElement: Record<string, boolean>;
  readonly pagesWithSelectedElement: Record<string, boolean>;
}

export interface DrawingsGeometryState {
  readonly fileData: DrawingsFilesData;
  readonly additionalColors: Record<string, string>;
  readonly geometry: Record<string, DrawingsGeometryInstance>;
  readonly points: Record<string, ShortPointDescription>;
  readonly pointsInfo: Record<string, DrawingsPointInfo>;
  readonly geometryCreators: string[];
}

export interface DrawingsTextSearchState {
  readonly settings: Readonly<TextSearchSettings>;
  readonly isLoading: boolean;
  readonly isError: boolean;
  readonly results: Readonly<TextSearchResultsInfo>;
  readonly textRectangles: PdfTextRects;
  readonly isTextLoading: boolean;
  readonly isActive: boolean;
}

export interface AutoMeasureSettingsState {
  readonly availableOptions: AutoMeasureOption[];
  readonly selectedOptions: number[];
  readonly pageSetting: AutoMeasurePageSetting;
}

export interface ExportSettings {
  readonly exportPages: PageOptions;
  readonly exportMeasurements: MeasurementOptions;
  readonly exportAnnotations: AnnotationOptions[];
  readonly drawingStylesEnabled: boolean;
  readonly segmentLength: boolean;
  readonly segmentLengthOnOtherLayer: boolean;
  readonly loadingExport: boolean;
}


export interface DrawingsCalibrationState {
  lineLength: string;
  lineLengthPx: number;
  metersPerPx: number;
  paperSize: string;
  drawingLength: number;
  originalLength: number;
}

export interface NewInstanceSettings {
  color: string;
  name: string;
  strokeStyle: DrawingStrokeStyles;
  shape: DrawingMarkShapes;
  offset: number;
  shouldSearchSimilar: boolean;
  baseInstanceId?: string;
}

export interface DrawingsState {
  readonly drawingInstances: Record<string, string[]>;
  readonly snappingGeometry: DrawingsSnappingGeometry;
  readonly currentDrawingInfo: DrawingsShortInfo;
  readonly selectedInstances: string[];
  readonly notSavedInstances: string[];
  readonly hiddenInstances: string[];
  readonly filesCount: number;
  readonly drawingsInfo: Record<string, DrawingsShortInfo>;
  readonly drawingSnappingGeometry: Record<string, DrawingsSnappingGeometry>;
  readonly files: DrawingsFiles;
  readonly tagDrawings: Record<string, string[]>;
  readonly isInstancesBoolOperationsEnabled: boolean;
  readonly loadStatus: RequestStatus;
  readonly fsHighlightStatus: DrawingsFSHighlightState;
  readonly drawingPaperPoints: Record<string, string[]>;
  readonly aiAnnotation: DrawingsGeometryState;
  readonly elementMeasurement: Record<string, DrawingsInstanceMeasure>;
  readonly drawingGeometryGroups: DrawingsGeometryGroup[];
  readonly pinnedGroupIds: string[];
  readonly drawingGeometryOpenGroups: Record<string, boolean>;
  readonly selectGeometryGroup: string[];
  readonly selectedFile: string;
  readonly selectedPages: string[];
  readonly filteredElementIds: string[];
  readonly filterData: FilterData;
  readonly drawingGroupTemplates: DrawingsGroupTemplate[];
  readonly userAnnotations: DrawingsUserAnnotationsState;
  readonly usedColors: string[];
  readonly groupsLoaded: boolean;
  readonly textSearch: DrawingsTextSearchState;
  readonly autoMeasureSettings: AutoMeasureSettingsState;
  readonly exportSettings: ExportSettings;

  readonly contextMenuPosition: DrawingContextMenuPosition;

  readonly hoverState: HoverState;

  readonly currentDrawingSnapping: DrawingSnapping;
  readonly showOnlyGroups: boolean;

  readonly showOptimizeMessage: boolean;

  readonly filteredItems: AnnotationLegendItem[];

  readonly drawMode: DrawingsDrawMode;
  readonly drawingRenderedStatus: boolean;
  readonly cursorHintType: CursorHintType;
  readonly drawingStatistic: Record<string, PdfStatistic>;

  readonly temporaryCalibration: DrawingsCalibrationState;
  readonly newInstanceSettings: NewInstanceSettings;
  readonly is3d: boolean;

  readonly wizzardTools: WizzardToolsFullState;
  readonly magicSearch: MagicSearchState;
  readonly finishApi: FinishDrawApi;
  readonly pageFilterApplyStatus: Record<string, RequestStatus>;
  readonly instanceToOperateWith: string[];
}
