import { useCallback } from 'react';
import { DrawingsDrawMode } from '../../enums';
import { useDrawModeApi, useTurnOnBucketFillEffect, useTurnOnOneClickLineEffect } from '../../hooks';
import { SaveDrawModeOptions } from '../../interfaces';

export function useSetDrawMode(): (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void {
  const { setDrawMode } = useDrawModeApi();
  const turnOnBucketFill = useTurnOnBucketFillEffect();
  const turnOnOneClickLine = useTurnOnOneClickLineEffect();

  return useCallback((drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    if (drawMode === DrawingsDrawMode.BucketFill) {
      turnOnBucketFill(() => setDrawMode(drawMode, options));
    } else if (drawMode === DrawingsDrawMode.OneClickLine) {
      turnOnOneClickLine(() => setDrawMode(drawMode, options));
    } {
      setDrawMode(drawMode, options);
    }
  }, [setDrawMode, turnOnBucketFill]);
}

export function useToggleDrawMode(): (drawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => void {
  const { drawMode } = useDrawModeApi();
  const setDrawMode = useSetDrawMode();

  return useCallback((newDrawMode: DrawingsDrawMode, options?: SaveDrawModeOptions) => {
    if (newDrawMode === drawMode) {
      setDrawMode(DrawingsDrawMode.Disabled);
    } else {
      setDrawMode(newDrawMode, options);
    }
  }, [setDrawMode, drawMode]);
}
