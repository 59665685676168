import { WizzardErrorTypes } from '../enums';
import { WizzardStatus } from '../enums/dropper-state';
import { PdfGeometry, PdfGeometryResults } from './api-responses/pdf-geometry-response';
import { ShortPointDescription } from './drawing-ai-annotation';
import { DrawingsSelectAggregationGroup } from './drawings-geometry';

export interface FinderSelectedVisiblity {
  area: boolean;
  line: boolean;
}

export enum AiRequestQuality {
  Auto = 'Auto',
  Big = 'Big Areas',
  Small = 'Small Areas',
}

export enum LineQuality {
  Light = 150,
  MediumLight = 300,
  MediumHigh = 450,
}

export const LineQualityName = {
  [LineQuality.Light]: 'Long Lines',
  [LineQuality.MediumLight]: 'Auto',
  [LineQuality.MediumHigh]: 'Short Lines',
};

export const getLineQualityByName = (name: string): number => {
  switch (name) {
    case LineQualityName[LineQuality.Light]:
      return LineQuality.Light;
    case LineQualityName[LineQuality.MediumLight]:
      return LineQuality.MediumLight;
    case LineQualityName[LineQuality.MediumHigh]:
      return LineQuality.MediumHigh;
    default:
      return LineQuality.MediumLight;
  }
};

export enum FinderSaveType {
  Count = 'Count',
  Area = 'Rectangle',
  CenterLine = 'Center line',
  MaxLengthSegments = 'Max dimension lines',
  MinLengthSegments = 'Min dimenstion lines',
}

export interface WizzardToolsState {
  connect: boolean;
  enclose: boolean;
  wizzardState: { status: WizzardStatus, foundedItemsCount?: number };
  dropperInstanceType: DrawingsSelectAggregationGroup;
  dropperSameGeometry: boolean;
  searchArea: ShortPointDescription[];
  allowRotation: boolean;
  finderSelectionArea: ShortPointDescription[];
  finderSelectedGeometries: PdfGeometry[];
  finderSelectedVisibility: { area: boolean, line: boolean };
  selectedToRemove: number[];
  findText: boolean;
  result: PdfGeometryResults;
  isCount: boolean;
  error: WizzardErrorTypes;
  dropperSimilarity: number;
  finderSimilarity: number;
  boostWithAi: boolean;
  finderResultToHide: Record<number, boolean>;
  quality: AiRequestQuality;
  lineQuality: LineQuality;
  finderSaveType: FinderSaveType;
}


export interface WizzardToolsFullState extends WizzardToolsState {
  allowFlipping: boolean;
}
