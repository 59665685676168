import * as paper from 'paper';

import { DrawingsGeometryEntityMeasureLength } from '../utility/measure-length/drawings-geometry-entity-measure-length';
import { DrawingsGeometryTempEntityWithStrokeConfig } from './drawings-geometry-temp-entity-with-stroke';
import { DrawingsGeometryTempPolygonEntity } from './drawings-geometry-temp-polygon-entity';

export interface DragRectConfig extends DrawingsGeometryTempEntityWithStrokeConfig {
  withMeasures?: boolean;
}

export class DrawingsGeometryTempDragRectangleEntity extends DrawingsGeometryTempPolygonEntity<DragRectConfig> {
  protected _path: paper.Path;

  constructor(
    point: paper.Point,
    config: DragRectConfig,
  ) {
    super(config);
    this.render(point);
  }

  public destroy(): void {
    this._path.removeSegments();
    super.destroy();
  }

  public updateTempPointPosition(point: paper.Point): paper.Point {
    if (!this._path.segments.length) {
      return;
    }
    this._path.segments[1].point = new paper.Point(point.x, this._path.segments[0].point.y);
    this._path.segments[2].point = point;
    this._path.segments[3].point = new paper.Point(this._path.segments[0].point.x, point.y);
    const firstLinePoint = [this._path.segments[0].point, this._path.segments[1].point] as [paper.Point, paper.Point];
    const secondPoint = [this._path.segments[0].point, this._path.segments[3].point] as [paper.Point, paper.Point];
    if (this._measures) {
      this._measures[0].changeLine(firstLinePoint);
      this._measures[1].changeLine(secondPoint);
    } else if (this._config.withMeasures) {
      this._measures = [
        new DrawingsGeometryEntityMeasureLength({
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          points: firstLinePoint,
          layer: this._config.layer,
          textLayer: this._config.layer,
          color: this._path.strokeColor,
          id: 'rect-length',
          mustBeOutOfPath: true,
          clampPath: this._path,
          textRenderParamsObserver: this._config.textRenderParametersObserver,
        }),
        new DrawingsGeometryEntityMeasureLength({
          renderParamsContextObserver: this._config.renderParametersContextObserver,
          points: secondPoint,
          layer: this._config.layer,
          textLayer: this._config.layer,
          color: this._path.strokeColor,
          id: 'rect-length',
          mustBeOutOfPath: true,
          clampPath: this._path,
          textRenderParamsObserver: this._config.textRenderParametersObserver,
        }),
      ];
    }
    return point;
  }

  protected render(point: paper.Point): void {
    this._path.addSegments(new paper.Path.Rectangle(point, point).segments);
    this._path.add(this._path.firstSegment);
  }
}
