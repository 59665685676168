import * as React from 'react';

import { RoleData, UserData, UserMenuInfo } from '../../interfaces';
import { EmptySeat } from './empty-seat';
import { User } from './user';


interface Props {
  userMenuInfo: UserMenuInfo;
  user: UserData | null;
  disableEditUserList: string[];
  userRoles: RoleData[];
  canInvite: boolean;
  resendInvitation: (email: string) => void;
  closeMenu: () => void;
  changeUserRole?: (email: string, role: RoleData) => void;
  deleteUser?: (email: string) => void;
  menuButtonClick?: (target: HTMLElement, email: string) => void;
  addUserButtonClick: () => void;
}

export const UsersTabListItem: React.FC<Props> = ({
  userMenuInfo,
  user,
  disableEditUserList,
  canInvite,
  changeUserRole,
  deleteUser,
  menuButtonClick,
  closeMenu,
  userRoles,
  resendInvitation,
  addUserButtonClick,
}) => {
  if (user) {
    return (<User
      resendInvitation={resendInvitation}
      key={user.email}
      menuButtonClick={menuButtonClick}
      userMenuInfo={userMenuInfo}
      user={user}
      userRoles={userRoles}
      canInvite={canInvite}
      closeMenu={closeMenu}
      deleteUser={deleteUser}
      changeUserRole={changeUserRole}
      disableEditUserList={disableEditUserList}
    />);
  } else {
    return (<EmptySeat addUserButtonClick={addUserButtonClick} />);
  }
};
