export enum FlyingMenuActionKeys {
  Modify,
  MoveTo,
  Knife,
  Offset,
  Merge,
  Subtract,
  Enclose,
  OpenPolygon,
  SplitPolyline,
  JoinCount,
  JoinLine,
  Group,
  TraceLink,
  MagicSearch,
  TransformMenu,
}
