import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';


import { DrawingsUndoRedoHelper } from 'common/components/drawings/utils/drawings-undo-redo-helper';
import { ConstantFunctions } from 'common/constants/functions';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { useUndoRedoFullApi } from 'common/undo-redo';
import { DNDSourceProps, withDragSourceWrapper } from 'common/utils/with-drag/with-drag-source-wrapper';
import { DrawingsInstanceMeasure } from '../../';
import { DrawingsAnnotationActions } from '../../actions/creators/annotation';
import { PivotedInstance } from '../../interfaces';
import { DrawingsDragAndDropData, DrawingsDragAndDropType } from '../../utils/drawings-annotation-drag-utils';
import { LegendItemAPI } from '../interfaces';
import { DrawingsAnnoationBaseItem } from './drawings-annotation-base-item';


interface OwnProps {
  isSelected: boolean;
  isImperial: boolean;
  isHidden: boolean;
  instance: PivotedInstance;
  nestingCount: number;
  measure: DrawingsInstanceMeasure;
  onClick: (event: React.MouseEvent<HTMLDivElement>, internalIds: string[], baseId: string) => void;
  onContextMenu: (event: React.MouseEvent<HTMLDivElement>, internalIds: string[]) => boolean;
  onHiddenChange: (ids: string[], isHidden: boolean) => void;
  onDoubleClick: (ids: string[]) => void;
  canEditMeasurement: boolean;
  getItemsToMove(): DrawingsDragAndDropData;
  hasAssignedPia: boolean;
  hasInheritedPia: boolean;
  minWidth: number;
  sendUpdateApi: (api: LegendItemAPI, id: string) => void;
  onSelectParentGroup: (groupId: string) => void;
  onOpenInstanceMenu: (e: React.MouseEvent, instance: PivotedInstance) => void;
}

interface Props extends OwnProps, DNDSourceProps {
}

const DrawingsAnnotationLegendItemComponent: React.FC<Props> = ({
  instance,
  isSelected,
  isHidden,
  nestingCount,
  canEditMeasurement,
  isImperial,
  hasAssignedPia,
  hasInheritedPia,
  minWidth,
  sendUpdateApi,
  onClick,
  onContextMenu,
  onDoubleClick,
  onHiddenChange,
  measure,
  onOpenInstanceMenu,
}: Props) => {
  const name = useSelector<State, string>(
    state => state.drawings.aiAnnotation.geometry[instance.groupedGeometries[0]]?.name
    || instance.name,
  );

  const onVisibleToggle = useCallback(() => {
    onHiddenChange(instance.groupedGeometries, !isHidden);
  }, [onHiddenChange, isHidden, instance.groupedGeometries]);

  const onClickCallback = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    onClick(event, instance.groupedGeometries, instance.id);
  }, [onClick, instance.groupedGeometries, instance.id]);

  const onContextMenuCallback = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    return onContextMenu(event, instance.groupedGeometries);
  }, [onContextMenu, instance.groupedGeometries]);

  const { addUndoRedo } = useUndoRedoFullApi();

  const onNameChange = useActionDispatch(DrawingsAnnotationActions.renameInstance);

  const onNameChangeCallback = useCallback((value: string) => {
    if (instance.name !== value) {
      const { undo, redo } = DrawingsUndoRedoHelper.renameMeasurementUndoRedo(
        instance.groupedGeometries.map(id => ({ instanceId: id, name: value })),
        instance.groupedGeometries.map(id => ({ instanceId: id, name: instance.name })),
        onNameChange,
      );
      addUndoRedo(undo, redo);
      redo();
    }
  }, [instance.groupedGeometries, instance.name, onNameChange, addUndoRedo]);

  const onDoubleClickCallback = useCallback(() => {
    onDoubleClick(instance.groupedGeometries);
  }, [onDoubleClick, instance.groupedGeometries]);

  const openInstanceMenuCallback = useCallback((e: React.MouseEvent) => {
    ConstantFunctions.stopEvent(e);
    onOpenInstanceMenu(e, instance);
  }, [instance, onOpenInstanceMenu]);

  return (
    <DrawingsAnnoationBaseItem
      onOpenInstanceMenu={openInstanceMenuCallback}
      name={name}
      id={instance.id}
      color={instance.color}
      isSelected={isSelected}
      isHidden={isHidden}
      nestingCount={nestingCount}
      canEditMeasurement={canEditMeasurement}
      isImperial={isImperial}
      hasAssignedPia={hasAssignedPia}
      hasInheritedPia={hasInheritedPia}
      minWidth={minWidth}
      sendUpdateApi={sendUpdateApi}
      onClick={onClickCallback}
      onContextMenu={onContextMenuCallback}
      onNameChange={onNameChangeCallback}
      onDoubleClick={onDoubleClickCallback}
      onVisibleToggle={onVisibleToggle}
      ignoreAvatar={true}
      measure={measure}
      count={instance.groupedGeometries.length}
    />
  );
};

const specDrag = {
  beginDrag: (props: Props) => {
    const itemsToMove = props.isSelected
      ? props.getItemsToMove()
      : {
        groups: [],
        measurements: props.instance.groupedGeometries,
      };
    return itemsToMove;
  },
  canDrag: (props: Props) => props.canEditMeasurement,
};


export const DrawingsAnnotationLegendPivotedItem = withDragSourceWrapper(
  DrawingsAnnotationLegendItemComponent,
  specDrag,
  DrawingsDragAndDropType.DrawingGroup,
);
