import { Constants } from '@kreo/kreo-ui-components';
import * as paper from 'paper';

import { KreoColors } from 'common/enums/kreo-colors';
import { DrawingsColorUtils } from '../utils/drawings-color-utils';

const lineStroke = 3;
const selectionMultiplier = 1.5;


export const DrawingsCanvasConstants = {
  mInPx: 0.00026458333,
  lineStroke,
  snappingStroke: 3,
  infoLinesStroke: 2,
  defaultSnappingSize: 10,
  dashArray: 4,
  dashArrayMultiplier: 2,
  selectedOpacity: 0.5,
  polygonAreaThreshold: 5,
  angleCircleRadius: 30,
  angleTextRadiusDiff: 30,
  snappingThreshold: 15,
  countRadius: 10,
  fontSize: 12,
  selectionMultiplier,
  snappingAngleStep: 15,
  pointRadius: 6,
  doubleClickDelay: 300,
  mouseOutDelay: 250,
  estimationBordersLength: 50,
  textLineMargin: 20,
  visualSearch: 'visualSearch',
  calibrateLineId: 'calibrateLineId',
  snappingOperationName: 'snapping',
  zeroPoint: new paper.Point(0, 0),
  backgroundOpacity: 0.4,
  thicknessOpacity: 0.6,
  selectionDelay: 400,
  zoomInCoefficient: 1.1,
  zoomOutCoefficient: 10 / 11,
  menuButtonSize: 40,
  minimumCalibrationLength: 0.1,
  zoomInFocusPerfectMarginsSum: 160,
  menuMarginVertical: 80,
  menuMarginHorizontal: 40,
  previewStrokeWidth: 1,
  previewDashArray: [4, 2],
  countTraceStrokeWidth: 1,
  countMarkPadding: 10,
};

export interface DrawingsTextEntitySettingsInterface {
  padding: {
    vertical: number,
    horizontal: number,
  };
  size: paper.Size;
  round: paper.Size;
  strokeWidth: number;
}

export const DrawingsTextEntitySettings: Record<string, DrawingsTextEntitySettingsInterface> = {
  textGroup: {
    padding: {
      vertical: 3,
      horizontal: 12,
    },
    strokeWidth: DrawingsCanvasConstants.infoLinesStroke,
    size: new paper.Size(0, 0),
    round: new paper.Size(11, 11),
  },
  textWithIcon: {
    padding: {
      vertical: 3,
      horizontal: 3,
    },
    strokeWidth: DrawingsCanvasConstants.infoLinesStroke,
    size: new paper.Size(0, 0),
    round: new paper.Size(5, 5),
  },
};


export const DrawingsCanvasColors = {
  pointColor: new paper.Color('#8000FF'),
  primaryFigure: new paper.Color('#FF57C7'),
  secondaryFigure: new paper.Color('#FFC500'),
  secondaryFigureTransparent: new paper.Color('#FFC50040'),
  nightDeepBackground: new paper.Color(KreoColors.nightDeepBackground),
  white: new paper.Color('#ffffff'),
  darkBlue: new paper.Color(Constants.Colors.GENERAL_COLORS.darkBlue),
  utility: new paper.Color('#8000FF'),
  utilityHex: '#8000FF',
  utilityTransparent: DrawingsColorUtils.convertColorWithAlpha('#8000FF', 0.2),
  utilityHighlight: DrawingsColorUtils.convertColorWithAlpha('#8000FF', 0.75),
  utilityFill: new paper.Color('#8000FF1A'),
  warningStrokeColor: new paper.Color(KreoColors.red),
  warningFillColor: new paper.Color(`${KreoColors.red}99`),
  autocompleteColorInfo: {
    stroke: new paper.Color(`${Constants.Colors.GENERAL_COLORS.green}`),
    fill: new paper.Color(`${Constants.Colors.GENERAL_COLORS.green}40`),
    thickness: new paper.Color(`${Constants.Colors.GENERAL_COLORS.green}60`),
    fillHover: new paper.Color(`${Constants.Colors.GENERAL_COLORS.green}80`),
  },
  warningColorInfo: {
    stroke: new paper.Color(KreoColors.red),
    fill: new paper.Color(`${KreoColors.red}40`),
    thickness: new paper.Color(`${KreoColors.red}60`),
  },
  secondaryColorInfo: {
    stroke: new paper.Color(KreoColors.orange),
    fill: new paper.Color(`${KreoColors.orange}40`),
    thickness: new paper.Color(`${KreoColors.orange}60`),
  },
};


export const ShadowOffsetNormals = {
  [0]: [0, 1],
  [90]: [1, 0],
  [180]: [0, -1],
  [270]: [-1, 0],
};
