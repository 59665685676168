import autobind from 'autobind-decorator';
import { ContextObserver } from 'common/components/drawings/drawings-contexts';
import { DrawingsColorCacheHelper } from 'common/components/drawings/helpers/drawings-color-cache-helper';
import { WizzardToolsState } from 'common/components/drawings/interfaces';
import {
  PdfGeometryResponse,
  PdfGeometryStatus,
} from 'common/components/drawings/interfaces/api-responses/pdf-geometry-response';
import { DrawingsCursorTypeHelper } from '../../drawings-helpers';
import { GeometryType } from '../../drawings-helpers/wizzard/ai/geometry-types';
import { OneClickLineTool } from '../../drawings-helpers/wizzard/ai/one-click-line-tool';
import { BatchedUpdateCallback, NewDrawingSettings, SetCursorHintCallback } from '../../interfaces';
import { FinishedDrawingElement, TryAddPointConfig } from './drawings-geometry-temp-entity';
import {
  DrawingsGeometryTempEntityWithStroke,
  DrawingsGeometryTempEntityWithStrokeConfig,
} from './drawings-geometry-temp-entity-with-stroke';

interface Config extends DrawingsGeometryTempEntityWithStrokeConfig {
  setCursorMessage: SetCursorHintCallback;
  wizzardSettingsObserver: ContextObserver<WizzardToolsState>;
  onEnableContextMenu: (canClear: boolean, canFinish) => void;
  onFinish: () => void;
  cursorTypeHelper: DrawingsCursorTypeHelper;
  colorsCacheHelper: DrawingsColorCacheHelper;
  newDrawingStylesObserver: ContextObserver<NewDrawingSettings>;
  onBatchUpdateGeometries: BatchedUpdateCallback;
  oneClickAreaHoverContextObserver: ContextObserver<boolean>;
}

export class OneClickLineConnector extends DrawingsGeometryTempEntityWithStroke<Config> {
  private _ai: OneClickLineTool;
  private _isProcessing: boolean = false;
  private _destroyed: boolean = false;

  constructor(config: Config) {
    super(config);
    this._ai = new OneClickLineTool({
      layer: this._config.layer,
      wizzardSettingsObserver: this._config.wizzardSettingsObserver,
      renderParamsContextObserver: this._config.renderParametersContextObserver,
      colorCache: this._config.colorsCacheHelper,
      newDrawingStylesObserver: this._config.newDrawingStylesObserver,
      onBatchUpdateGeometries: this._config.onBatchUpdateGeometries,
      setCursorMessage: (m) => {
        this._config.setCursorMessage(m);
      },
      getCurrentDrawingInfo: this._config.getDrawingInfo,
      oneClickAreaHoverContextObserver: this._config.oneClickAreaHoverContextObserver,
      type: GeometryType.Polyline,
      textRenderParams: this._config.textRenderParametersObserver,
      onPointClick: this.onPointClick,
    });
  }


  public override updateTempPointPosition(point: paper.Point): paper.Point {
    this._ai.onHover(point);
    return point;
  }

  public override get hasPoint(): boolean {
    return this._ai.hasGeometies();
  }

  public override removeLastPoint(): paper.Point | null {
    const removeResult = this._ai.removeLastPoint();
    this._config.cursorTypeHelper.loading = false;
    this._isProcessing = false;
    this._config.onEnableContextMenu(false, false);
    if (removeResult[1]) {
      this.processAIClick(removeResult[1]);
    }
    if (removeResult[0]) {
      return removeResult[0];
    }
  }

  public override tryAddPoint(point: paper.Point, { event }: TryAddPointConfig): boolean {
    if (this._isProcessing) {
      return true;
    }

    this._config.onEnableContextMenu(false, false);
    this.processAIClick(this._ai.addPoint(point, event.event.ctrlKey));
    return true;
  }

  public override canComplete(): boolean {
    return this._ai?.hasGeometies() && this._ai?.isValid();
  }

  public override convert(): FinishedDrawingElement[] {
    const geometry = this._ai.getGeometry();
    return geometry;
  }


  public override destroy(): void {
    super.destroy();
    this._config.setCursorMessage(null);
    this._destroyed = true;
    this._config.cursorTypeHelper.loading = false;
    this._ai.destroy();
  }

  private processAIClick(promise: Promise<PdfGeometryResponse>): void {
    promise.then((result) => {
      this._isProcessing = false;
      this._config.cursorTypeHelper.loading = false;
      if (this._destroyed || !result) {
        this._config.setCursorMessage(null);
        return;
      }
      if (result.status === PdfGeometryStatus.IGNORE) {
        this._config.onEnableContextMenu(this._ai.hasGeometies(), this._ai.hasPreview());
        return;
      }
      if (result.status !== PdfGeometryStatus.Succcess) {
        if (result.hasPoints) {
          this._config.onEnableContextMenu(true, false);
        }
      } else {
        if (result.geometries[0].points.length > 1) {
          this._config.onEnableContextMenu(true, true);
        } else {
          this._config.onEnableContextMenu(true, false);
        }
      }
    });
  }

  @autobind
  private onPointClick(pointId: string): void {
    this._isProcessing = false;
    this._config.onEnableContextMenu(false, false);
    this.processAIClick(this._ai.removePoint(pointId));
  }
}
