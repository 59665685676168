import * as paper from 'paper';

import { ColorList } from 'common/constants/color-list';

import { DrawingsCanvasConstants } from '../constants/drawing-canvas-constants';
import { DrawingsDashedParameters, DrawingStrokeStyles } from '../constants/drawing-styles';
import { DrawingsShortInfo } from '../interfaces';
import { ShortPointDescription } from '../interfaces/drawing-ai-annotation';
import {
  DrawingsAllowedPathType,
  DrawingsCanvasRenderParams,
  DrawingsPolygonGeometry,
  DrawingsPolylineGeometry,
  DrawingsSimplifiedBoundingRect,
  DrawingsSizeParameters,
} from '../interfaces/drawings-geometry';
import {
  DrawingsMeasurePolygon,
  DrawingsMeasurePolyline,
  DrawingsMeasureRectangle,
} from '../interfaces/drawings-measures';
import { StrokeParams } from '../interfaces/stroke-params';
import { DrawingsPaperUtils } from './drawings-paper-utils';
import { PaperIntersectionUtils } from './paper-utils';

function calculateLineLength([x1, y1]: ShortPointDescription, [x2, y2]: ShortPointDescription): number {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

function metersToPx(
  meters: number,
  scale: number,
  currentPxInM: number = DrawingsCanvasConstants.mInPx,
): number {
  return meters / currentPxInM / scale;
}

function pxToMetres(
  px: number,
  scale: number,
  currentPxInM: number = DrawingsCanvasConstants.mInPx,
): number {
  return px * currentPxInM * scale;
}

function squarePxToMetres(
  px: number,
  scale: number,
  currentPxInM: number = DrawingsCanvasConstants.mInPx,
): number {
  return px * Math.pow(scale * currentPxInM, 2);
}

function cubiquePxToMetres(
  px: number,
  scale: number,
  currentPxInM: number = DrawingsCanvasConstants.mInPx,
): number {
  return px * Math.pow(scale * currentPxInM, 3);
}

function rotatePoint(
  [bx, by]: ShortPointDescription,
  angle: number,
  [ax, ay]: ShortPointDescription,
): ShortPointDescription {
  const rad = (Math.PI / 180) * angle;
  const cos = Math.cos(rad);
  const sin = Math.sin(rad);
  const run = bx - ax;
  const rise = by - ay;
  return [
    (cos * run) + (sin * rise) + ax,
    (cos * rise) - (sin * run) + ay,
  ];
}

function getColorFromList(): string {
  return ColorList[Math.round(Math.random() * (ColorList.length - 1))];
}


function isCalibrationPoint(id: string): boolean {
  return id.startsWith(DrawingsCanvasConstants.calibrateLineId);
}

function needRenderMeasureOnOtherSide(angle: number): boolean {
  return (angle < - 180) || (angle > 0 && angle < 90);
}

function getPolylineMeasures(
  path: paper.Path,
  scale: number,
  metersPerPixel: number,
  {
    height,
    thickness,
  }: DrawingsPolylineGeometry,
): DrawingsMeasurePolyline {
  const measure: DrawingsMeasurePolyline = {
    pxLength: path.length,
    length: pxToMetres(path.length, scale, metersPerPixel),
    pointsCount: path.segments.length,
    segmentsCount: path.segments.length - 1,
    thickness: thickness || 0,
    height: height || 0,
    verticalArea: 0,
    area: 0,
    volume: 0,
  };

  if (thickness !== undefined && thickness !== null) {
    measure.area = measure.length * thickness;
    if (height !== undefined && height !== null) {
      measure.volume = measure.area * height;
    }
  }
  if (height !== undefined && height !== null) {
    measure.verticalArea = measure.length * height;
  }
  return measure;
}

function countPolygonSegments(
  path: DrawingsAllowedPathType,
): number {
  if (path.children) {
    return (path.children as paper.Path[]).reduce((sum, child) => sum + countPolygonSegments(child), 0);
  } else {
    return path.segments.length - (path.lastSegment.point.equals(path.firstSegment.point) ? 1 : 0);
  }
}

function getPolygonMeasures(
  path: DrawingsAllowedPathType,
  scale: number,
  metersPerPixel: number,
  {
    height,
  }: DrawingsPolygonGeometry,
): DrawingsMeasurePolygon {
  const area = Math.abs(path.area);
  const perimeter = path.length;
  const segmentsCount = countPolygonSegments(path);
  const measure: DrawingsMeasurePolygon = {
    pxArea: area,
    pxPerimeter: perimeter,
    area: squarePxToMetres(area, scale, metersPerPixel),
    perimeter: pxToMetres(perimeter, scale, metersPerPixel),
    segmentsCount,
    pointsCount: segmentsCount,
    height: height || 0,
    verticalArea: 0,
    volume: 0,
  };

  if (height !== undefined && height !== null) {
    measure.height = height;
    measure.verticalArea = measure.perimeter * height;
    measure.volume = measure.area * height;
  }

  return measure as DrawingsMeasurePolygon;
}

function getRectangleMeasures(
  path: paper.Path,
  scale: number,
  metersPerPixel: number,
  geometry: DrawingsPolygonGeometry,
): DrawingsMeasureRectangle {
  const polygonMeasures = getPolygonMeasures(path, scale, metersPerPixel, geometry);
  let rectangleMin = path.segments[0].point.subtract(path.segments[1].point).length;
  let rectangleMax = path.segments[1].point.subtract(path.segments[2].point).length;
  if (rectangleMax < rectangleMin) {
    [rectangleMax, rectangleMin] = [rectangleMin, rectangleMax];
  }
  return {
    ...polygonMeasures,
    rectangleMin: pxToMetres(rectangleMin, scale, metersPerPixel),
    rectangleMax: pxToMetres(rectangleMax, scale, metersPerPixel),
    pxRectangleMin: rectangleMax,
    pxRectangleMax: rectangleMin,
  };
}

function subtractPolygonMeasures(
  measure1: DrawingsMeasurePolygon,
  measure2: DrawingsMeasurePolygon,
): DrawingsMeasurePolygon {
  const result: DrawingsMeasurePolygon = {
    pxArea: measure1.pxArea - measure2.pxArea,
    pxPerimeter: measure1.pxPerimeter + measure2.pxPerimeter,
    area: measure1.area - measure2.area,
    perimeter: measure1.perimeter + measure2.perimeter,
    segmentsCount: measure1.segmentsCount + measure2.segmentsCount,
    pointsCount: measure1.pointsCount + measure2.pointsCount,
    height: measure1.height,
    verticalArea: 0,
    volume: 0,
  };

  if (measure1.height) {
    result.verticalArea = result.perimeter * measure1.height;
    result.volume = result.area * measure1.height;
  }
  return result;
}

function changeWidthHeightByRotation(width: number, height: number, rotation: number): [number, number] {
  if (rotation / 90 % 2) {
    return [height, width];
  } else {
    return [width, height];
  }
}

function getPDFTronRotation(rotation: number): Core.PageRotation {
  switch (rotation) {
    case 0:
      return Core.PageRotation.E_0;
    case 90:
      return Core.PageRotation.E_90;
    case 180:
      return Core.PageRotation.E_180;
    case 270:
      return Core.PageRotation.E_270;
    default:
      return Core.PageRotation.E_0;
  }
}

function isVerticallyRotated(rotation: number): boolean {
  return rotation === 90 || rotation === 270;
}

function fillResultHorizontalRotatedRenderParams(
  result: DrawingsCanvasRenderParams,
  scrollLayoutSize: DrawingsSizeParameters,
  zoom: number,
  scrollLeft: number,
  canvasX: number,
  scrollTop: number,
  canvasY: number,
  canvasSize: DrawingsSizeParameters,
  pageSize: DrawingsSizeParameters,
  rotation: number,
): void {
  result.width = scrollLayoutSize.width / zoom;
  result.height = scrollLayoutSize.height / zoom;
  result.x = Math.max(scrollLeft - canvasX, 0) / zoom;
  result.y = Math.max(scrollTop - canvasY, 0) / zoom;
  result.drawingLayoutWidth = scrollLayoutSize.width;
  result.drawingLayoutHeight = scrollLayoutSize.height;
  if (canvasSize.width < scrollLayoutSize.width) {
    result.left = canvasX;
    result.x = 0;
    result.width = pageSize.width;
    result.drawingLayoutWidth = canvasSize.width;
  } else if (result.x + result.width > pageSize.width) {
    result.width = pageSize.width - result.x;
    result.drawingLayoutWidth = result.width * zoom;
  }

  if (canvasSize.height < scrollLayoutSize.height) {
    result.top = canvasY;
    result.y = 0;
    result.height = pageSize.height;
    result.drawingLayoutHeight = canvasSize.height;
  } else if (result.y + result.height > pageSize.height) {
    result.height = pageSize.height - result.y;
    result.drawingLayoutHeight = result.height * zoom;
  }
  if (rotation === 180) {
    result.y = pageSize.height - result.y - result.height;
    result.x = pageSize.width - result.x - result.width;
  }
}

function fillResultViaVerticalRotatedRenderParams(
  result: DrawingsCanvasRenderParams,
  scrollTop: number,
  canvasY: number,
  zoom: number,
  scrollLeft: number,
  canvasX: number,
  scrollLayoutSize: DrawingsSizeParameters,
  canvasSize: DrawingsSizeParameters,
  pageSize: DrawingsSizeParameters,
  rotation: number,
): void {
  result.x = Math.max(scrollTop - canvasY, 0) / zoom;
  result.y = Math.max(scrollLeft - canvasX, 0) / zoom;
  result.width = scrollLayoutSize.height / zoom;
  result.height = scrollLayoutSize.width / zoom;
  result.drawingLayoutWidth = scrollLayoutSize.height;
  result.drawingLayoutHeight = scrollLayoutSize.width;
  if (canvasSize.height < scrollLayoutSize.height) {
    result.top = canvasY;
    result.x = 0;
    result.width = pageSize.height;
    result.drawingLayoutWidth = canvasSize.height;
  } else if (result.x + result.width > pageSize.height) {
    result.width = pageSize.height - result.x;
    result.drawingLayoutWidth = result.width * zoom;
  }
  if (canvasSize.width < scrollLayoutSize.width) {
    result.left = canvasX;
    result.y = 0;
    result.height = pageSize.width;
    result.drawingLayoutHeight = canvasSize.width;
  } else if (result.y + result.height > pageSize.width) {
    result.height = pageSize.width - result.y;
    result.drawingLayoutHeight = result.height * zoom;
  }
  if (rotation === 90) {
    result.y = pageSize.width - result.y - result.height;
  } else {
    result.x = pageSize.height - result.x - result.width;
  }
}

function getCanvasRenderParams(
  mainLayoutSize: DrawingsSizeParameters,
  scrollLayoutSize: DrawingsSizeParameters,
  pageSize: DrawingsSizeParameters,
  scrollLeft: number,
  scrollTop: number,
  zoom: number,
  rotation: number,
): DrawingsCanvasRenderParams {
  const canvasSize = { width: pageSize.width * zoom, height: pageSize.height * zoom };
  const canvasX = (mainLayoutSize.width - canvasSize.width) / 2;
  const canvasY = (mainLayoutSize.height - canvasSize.height) / 2;
  const result = {
    x: undefined,
    y: undefined,
    width: undefined,
    height: undefined,
    left: Math.max(canvasX, scrollLeft),
    top: Math.max(canvasY, scrollTop),
    drawingLayoutWidth: scrollLayoutSize.width,
    drawingLayoutHeight: scrollLayoutSize.height,
  };

  if (isVerticallyRotated(rotation)) {
    fillResultViaVerticalRotatedRenderParams(
      result,
      scrollTop,
      canvasY,
      zoom,
      scrollLeft,
      canvasX,
      scrollLayoutSize,
      canvasSize,
      pageSize,
      rotation,
    );
  } else {
    fillResultHorizontalRotatedRenderParams(
      result,
      scrollLayoutSize,
      zoom,
      scrollLeft,
      canvasX,
      scrollTop,
      canvasY,
      canvasSize,
      pageSize,
      rotation,
    );
  }
  return result;
}


function calculateTriangleHighLength(v1: paper.Point, v2: paper.Point, v3: paper.Point): number {
  const a = v2.subtract(v1).length;
  const b = v3.subtract(v2).length;
  const c = v1.subtract(v3).length;
  const p = (a + b + c) / 2;
  return 2 * Math.sqrt(Math.abs(p * (p - a) * (p - b) * (p - c))) / a;
}

function getPointOfLineOffset(start: paper.Point, end: paper.Point, offset: paper.Point): number {
  const angle = end.subtract(start).angle;
  return offset.rotate(-angle, start).y - start.y;
}

function getRightSideLine(
  rectangle: paper.Rectangle,
  rotation: number,
): [paper.Point, paper.Point] {
  switch (rotation) {
    case 0:
      return [rectangle.topRight, rectangle.bottomRight];
    case 90:
      return [rectangle.topLeft, rectangle.topRight];
    case 180:
      return [rectangle.bottomLeft, rectangle.topLeft];
    case 270:
      return [rectangle.bottomRight, rectangle.bottomLeft];
    default:
      return null;
  }
}

function getTopLeftPointByRotation(
  { left, top, right, bottom }: DrawingsSimplifiedBoundingRect,
  rotation: number): ShortPointDescription {
  switch (rotation) {
    case 0:
      return [left, top];
    case 90:
      return [left, bottom];
    case 180:
      return [right, bottom];
    case 270:
      return [right, top];
    default:
      return null;
  }
}

function getBottomRightPointByRotation(
  { left, top, right, bottom }: DrawingsSimplifiedBoundingRect,
  rotation: number): ShortPointDescription {
  switch (rotation) {
    case 0:
      return [right, bottom];
    case 90:
      return [right, top];
    case 180:
      return [left, top];
    case 270:
      return [left, bottom];
    default:
      return null;
  }
}

function scaleDashArray(dashArray: number[], strokeWidth: number, zoom: number): number[] {
  return dashArray.map(x => {
    const newValue = x * strokeWidth / zoom;
    if (x === 1) {
      return newValue < 1 ? x / zoom : x;
    }
    return newValue;
  });
}

function scaleStroke(
  { strokeStyle, strokeWidth }: StrokeParams,
  zoom: number,
): number[] {
  return scaleDashArray(DrawingsDashedParameters[strokeStyle || DrawingStrokeStyles.Normal], strokeWidth, zoom);
}

function getPointOnLayer([x, y]: ShortPointDescription, zoom: number, rotation: number): ShortPointDescription {
  if (rotation === 0) {
    return [(x - paper.view.bounds.x) * zoom, (y - paper.view.bounds.y) * zoom];
  } else if (rotation === 90) {
    return [
      (paper.view.bounds.height + paper.view.bounds.y - y) * zoom,
      (x - paper.view.bounds.x) * zoom,
    ];
  } else if (rotation === 180) {
    return [
      (paper.view.bounds.width + paper.view.bounds.x - x) * zoom,
      (paper.view.bounds.height + paper.view.bounds.y - y) * zoom,
    ];
  } else if (rotation === 270) {
    return [
      (y - paper.view.bounds.y) * zoom,
      (paper.view.bounds.width + paper.view.bounds.x - x) * zoom,
    ];
  }
}


function getPointOnPage(
  point: ShortPointDescription,
  { width, height }: DrawingsSizeParameters,
  rotation: number,
): ShortPointDescription {
  rotation = rotation < 0 ? 360 + rotation : rotation;
  if (rotation === 0) {
    return point;
  } else if (rotation === 90) {
    return [height - point[1], point[0]];
  } else if (rotation === 180) {
    return [width - point[0], height - point[1]];
  } else if (rotation === 270) {
    return [point[1], width - point[0]];
  }
}


function getLineToCanvasIntersection(
  point1: paper.Point,
  point2: paper.Point,
  { width, height }: DrawingsShortInfo,
): paper.Point {
  if (point1.x < 0 && point1.y > 0) {
    return PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(0, 0),
      new paper.Point(0, height),
    );
  } else if (point1.x > 0 && point1.y < 0) {
    return PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(0, 0),
      new paper.Point(width, 0),
    );
  } else if (point1.x < 0 && point1.y < 0) {
    const xIntersection = PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(0, 0),
      new paper.Point(0, height),
    );
    const yIntersection = PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(0, 0),
      new paper.Point(width, 0),
    );
    if (point1.subtract(xIntersection).length < point1.subtract(yIntersection).length) {
      return xIntersection;
    } else {
      return yIntersection;
    }
  } else if (point1.x > width && point1.y < height) {
    return PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(width, 0),
      new paper.Point(width, height),
    );
  } else if (point1.x < width && point1.y > height) {
    return PaperIntersectionUtils.getLinesIntersection(
      point1,
      point2,
      new paper.Point(0, height),
      new paper.Point(width, height),
    );
  } else if (point1.x > width && point1.y > height) {
    const xIntersection =
      PaperIntersectionUtils.getLinesIntersection(
        point1,
        point2, new paper.Point(width, 0), new paper.Point(width, height));
    const yIntersection =
      PaperIntersectionUtils.getLinesIntersection(
        point1,
        point2,
        new paper.Point(0, height),
        new paper.Point(width, height),
      );
    if (point1.subtract(xIntersection).length < point1.subtract(yIntersection).length) {
      return xIntersection;
    } else {
      return yIntersection;
    }
  } else {
    return null;
  }
}

function bindToStrictAngle(
  points: [paper.Point, paper.Point, paper.Point],
  stepAngle: number): paper.Point {
  const angle = DrawingsPaperUtils.getAngleSnappingTurn(
    DrawingsPaperUtils.getPaperAngle(...points),
    stepAngle,
  );
  return points[0].rotate(angle, points[1]);
}

export const DrawingsCanvasUtils = {
  getLineToCanvasIntersection,
  getColorFromList,
  calculateLineLength,
  pxToMetres,
  squarePxToMetres,
  cubiquePxToMetres,
  rotatePoint,
  metersToPx,

  isCalibrationPoint,
  needRenderMeasureOnOtherSide,

  getPolylineMeasures,
  getRectangleMeasures,
  getPolygonMeasures,
  subtractPolygonMeasures,
  getCanvasRenderParams,
  changeWidthHeightByRotation,

  getPDFTronRotation,
  isVerticallyRotated,

  calculateTriangleHighLength,
  getPointOfLineOffset,
  getTopLeftPointByRotation,
  getBottomRightPointByRotation,
  getRightSideLine,

  scaleStroke,
  scaleDashArray,
  getPointOnLayer,
  getPointOnPage,

  bindToStrictAngle,
};
