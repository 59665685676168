const prefix = '@drawings-magic-search';

export const MagicSearchActionTypes = {
  RUN_SEARCH: `${prefix}/RUN_SEARCH`,
  RUN_TEXT_SEARCH: `${prefix}/RUN_TEXT_SEARCH`,
  SET_CONTOURS_TO_SEARCH: `${prefix}/SET_CONTOURS_TO_SEARCH`,
  SAVE_RESULT: `${prefix}/SAVE_RESULT`,
  SAVE_PREVIEW_RESULT: `${prefix}/SAVE_PREVIEW_RESULT`,
  SAVE_SNAPPED_GEOMETRIES: `${prefix}/SAVE_SNAPPED_GEOMETRIES`,
  SET_DPI: `${prefix}/SET_DPI`,
  SET_SIMILARITY: `${prefix}/SET_SIMILARITY`,
  SET_STATUS: `${prefix}/SET_STATUS`,
  SET_SEARCH_ZONE: `${prefix}/SET_SEARCH_ZONE`,
  SET_QUERY: `${prefix}/SET_QUERY`,
  SET_SPECIFY_SEARCH_ZONE: `${prefix}/SET_SPECIFY_SEARCH_ZONE`,
  TOGGLE_DELETE_PREVIEW: `${prefix}/TOGGLE_DELETE_PREVIEW`,
  CANCEL_SEARCH: `${prefix}/CANCEL_SEARCH`,
  RESET_DELETE_PREVIEWS: `${prefix}/RESET_DELETE_PREVIEWS`,
  SET_SNAPPING_STATUS: `${prefix}/SET_SNAPPING_STATUS`,
  SET_FIX_API: `${prefix}/SET_FIX_API`,
  APPLY_FIX_CONTOUR: `${prefix}/APPLY_FIX_CONTOUR`,
  DELETE_FIX_CONTOUR: `${prefix}/DELETE_FIX_CONTOUR`,
  SET_CONTOUR_AS_NEGATIVE: `${prefix}/SET_CONTOUR_AS_NEGATIVE`,
  REMOVE_NEGATIVE_CONTOUR: `${prefix}/REMOVE_NEGATIVE_CONTOUR`,
  RESET_NEGATIVE_CONTOURS: `${prefix}/RESET_NEGATIVE_CONTOURS`,
  SET_FIX_STATUS: `${prefix}/SET_FIX_STATUS`,
  RESNAP: `${prefix}/RESNAP`,
  FORCE_RESTART: `${prefix}/FORCE_RESTART`,
};
