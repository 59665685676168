import autobind from 'autobind-decorator';
import * as paper from 'paper';

import { ConstantFunctions } from 'common/constants/functions';
import { HotkeyMultiOsHelper } from 'common/hotkeys/hotkey-multi-os-helper';
import { DrawingContextObserver } from '../../../drawings-contexts';
import { EventWrapper, StrictAngleController } from '../../interfaces';
import { PaperMouseEventUtils } from '../paper-mouse-event-utils';
import {
  DrawingsGeometrySnappingHelper,
  DrawingsSnappingParameters,
} from '../snapping/drawings-geometry-snapping-helper';
import { DragCallbackConfig, DrawingsDragCallback } from './interfaces';


export interface DrawingsDragCallbackParams {
  point: paper.Point;
  finish: boolean;
  ignoreChange?: boolean;
  ctrlKey?: boolean;
  entityId?: string;
  mouseUpEvent?: PaperMouseEvent;
}


export class DrawingsDragProcessingHelper implements EventWrapper<DrawingsDragCallback> {
  private _lastValidPoint: paper.Point;
  private _callback: DrawingsDragCallback;
  private _withSnapping: boolean;
  private _sharedSpaceBarHeldContext: DrawingContextObserver<boolean>;
  private _snapping: DrawingsGeometrySnappingHelper;
  private _strictAngleController: StrictAngleController;
  private _entityId: string;

  constructor(
    sharedSpaceBarHeldContext: DrawingContextObserver<boolean>,
    snappingHelper: DrawingsGeometrySnappingHelper,
    strictAngleController: StrictAngleController,
  ) {
    this._sharedSpaceBarHeldContext = sharedSpaceBarHeldContext;
    this._snapping = snappingHelper;
    this._strictAngleController = strictAngleController;
  }

  @autobind
  public setCallback(callback: DrawingsDragCallback, config?: DragCallbackConfig): void {
    if (this._sharedSpaceBarHeldContext.getContext()) {
      return;
    }
    if (this._callback) {
      this.stopDrag();
    }
    this._callback = callback;
    if (config) {
      const { withSnapping, defaultValidPoint, entityId } = config;
      this._withSnapping = withSnapping;
      this._lastValidPoint = defaultValidPoint;
      this._entityId = entityId;
    } else {
      this._withSnapping = undefined;
      this._lastValidPoint = undefined;
      this._entityId = undefined;
    }

    if (this._withSnapping) {
      this._snapping.onSendPoint = this.updateMousePosition;
    }
  }

  @autobind
  public onMouseMove(event: PaperMouseEvent): void {
    if (this._callback && !this._sharedSpaceBarHeldContext.getContext()) {
      ConstantFunctions.stopEvent(event);
      const point = event.point;
      if (!PaperMouseEventUtils.hasPressed(event) || !PaperMouseEventUtils.isLeftMouseButton(event)) {
        this.stopDrag();
      } else {
        if (this._snapping.canSnap && this._withSnapping) {
          this._snapping.requestSnapping(point);
        } else {
          this.applyPointChange(point);
        }
      }
    }
  }

  @autobind
  public onMouseUp(e: PaperMouseEvent): boolean {
    if (this._callback && !PaperMouseEventUtils.isWheel(e)) {
      ConstantFunctions.stopEvent(e);
      ConstantFunctions.stopEvent(e.event);
      this.stopDrag(e.point, HotkeyMultiOsHelper.isCtrlOrCommandKeyDown(e.event), e);
      return true;
    }
    return false;
  }

  @autobind
  public stopDrag(point?: paper.Point, ctrlKey?: boolean, event?: PaperMouseEvent): void {
    if (this._callback) {
      this._strictAngleController.deactivate();
      this._snapping.removeSnapping();
      const callback = this._callback;
      this._callback = null;
      callback({
        point: this._lastValidPoint || point,
        finish: true,
        ignoreChange: false,
        ctrlKey,
        entityId: this._entityId,
        mouseUpEvent: event,
      });
      this._entityId = null;
      this._lastValidPoint = null;
    }
  }

  @autobind
  private updateMousePosition(
    point: paper.Point,
    snappingInfo?: DrawingsSnappingParameters,
  ): paper.Point {
    if (snappingInfo) {
      const { threshold, snappingPoint } = snappingInfo;
      if (snappingPoint && snappingPoint.subtract(point).length < threshold) {
        this.applyPointChange(snappingPoint);
        return snappingPoint;
      }
    }
    this.applyPointChange(point);
    return null;
  }

  @autobind
  private applyPointChange(point: paper.Point): void {
    if (this._callback && this._callback({ point, finish: false, entityId: this._entityId })) {
      this._lastValidPoint = point;
    }
  }
}
