import { Icons, RectangleButton } from '@kreo/kreo-ui-components';
import autobind from 'autobind-decorator';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { RoleCode } from 'common/enums/role-code';
import { State } from 'common/interfaces/state';
import { AccountSelectors } from '../../../../units/account/selectors';
import { PeopleActions } from '../../../../units/people/actions/actions';
import { Styled } from './styled';


interface DispatchProps {
  setShowInviteUsersPanel: (isOpen: boolean) => void;
}

interface StateProps {
  isRoleGuest: boolean;
}

interface Props extends DispatchProps, StateProps {

}

class ButtonShareFastNavigationComponent extends React.Component<Props> {
  public render(): React.ReactNode {
    const { isRoleGuest } = this.props;

    return (
      <Styled.ShareButtonContainer>
        <RectangleButton
          height={20}
          width={66}
          text={'Share'}
          Icon={Icons.ShareButton}
          mood={isRoleGuest ? 'disabled' : 'secondary'}
          onClick={this.openInviteUsersPanel}
        />
      </Styled.ShareButtonContainer>
    );
  }

  @autobind
  private openInviteUsersPanel(): void {
    this.props.setShowInviteUsersPanel(true);
  }
}


function mapStateToProps(state: State): StateProps {
  return {
    isRoleGuest: AccountSelectors.currentSubscription(state).userRoleCode === RoleCode.Guest,
  };
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => {
  return {
    setShowInviteUsersPanel: (isOpen) => dispatch(PeopleActions.setShowInviteUsersPanel(isOpen)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export const ButtonShareFastNavigation = connector(ButtonShareFastNavigationComponent);
