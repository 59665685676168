import { isMergeVerticalKey } from '2d/units/excel-table-cell-formatter/common';
import { ExcelFormulaHelper } from 'common/components/excel-table';
import { ExcelTableRowIdentification } from 'common/components/excel-table/excel-table-row-identificator';
import { MoveCellTempData, MoveDataPayload } from '../interfaces';
import { setEmptyRows } from '../set-empty-lines/set-empty-lines';
import { shiftRows } from '../shift-rows';

export const insertRows = ({ startIndex, sheetsData, sheetId, offset }: MoveDataPayload): MoveCellTempData => {
  const sheetData = sheetsData[sheetId];
  const rows = sheetData.rows;
  const insertIndex = startIndex + offset;

  const updatedRows = shiftRows(sheetData, insertIndex, -offset);
  for (let i = 0; i < insertIndex; i++) {
    const row = rows[i];
    Object.keys(row).forEach((columnId) => {
      if (isMergeVerticalKey(columnId)) {
        const mergeSize: number = row[columnId] as number;
        if (insertIndex <= i + mergeSize) {
          const rowId = ExcelTableRowIdentification.getRowIdFromIndex(i);
          const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);

          updatedRows.updatedCellsMap[cellId] = {
            rowId,
            columnId,
            value: mergeSize + offset,
            prevValue: mergeSize,
          };
        }
      }
    });
  }

  setEmptyRows(startIndex, offset, rows, updatedRows);
  return updatedRows;
};
