import styled from 'styled-components';

import { CompanyInfoStyled } from 'common/components/company-info';
import { SpinnerStyled } from 'common/components/spinner';
import { BackdropStyled } from 'unit-2d-database/components/side-panel/components';


const CompanyInfoContainer = styled.div`
  ${BackdropStyled.Backdrop} {
    z-index: 1001;
  }

  ${BackdropStyled.Container} {
    z-index: 1002;
    width: 640px;
  }
`;

const CompanyInfoWrapper = styled.div<{ canManage: boolean }>`
  width: fit-content;
  height: 100%;
  position: relative;

  ${SpinnerStyled.SpinnerContainer} {
    border-radius: 40px;
  }

  ${CompanyInfoStyled.UserMenu} {
    display: ${p => p.canManage ? 'block' : 'none'};
  }

  .virtual-list-content {
    height: 100%;
    margin-left: 0;
    margin-right: 5px;
  }
  .scroll-box__track-horizontal {
    display: none;
  }
  .scroll-box__track-vertical {
    display: none;
  }
  .scroll-box__content {
    margin-right: 0 !important;
    overflow: auto !important;
    padding-right: 40px;

    & ::-webkit-scrollbar-thumb {
      background: ${p => p.theme.color.disabled};
    }

    & ::-webkit-scrollbar-thumb:hover {
      background: ${p => p.theme.color.gray};
    }

    & ::-webkit-scrollbar-track {
      background: ${p => p.theme.color.background};
    }

    & ::-webkit-scrollbar {
      width: 6px;
    }
  }
`;

export const Styled = {
  CompanyInfoContainer,
  CompanyInfoWrapper,
};
