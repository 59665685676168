import { UpdateCellData } from 'common/components/excel-table/interfaces';
import { ExcelFormulaHelper } from 'common/components/excel-table/utils/excel-formula-helper';
import { RowDataStore } from '../../cell-data-controllers/report-cell-data-store';
import {
  getConfigId,
  getMergeHorizontalId,
  getMergeVerticalId,
  getStylePropertyId,
} from '../../excel-table-cell-formatter/common';
import { isColumnIdSpecialWord } from '../is-column-id-special-word';
import { getCellValue } from '../utils';

export const setEmptyCell = (
  updatedCellsMap: Record<string, UpdateCellData>,
  rowData: RowDataStore,
  columnId: string,
): void  => {
  if (isColumnIdSpecialWord(columnId)) {
    return;
  }
  const rowId = rowData.id.toString();
  const cellId = ExcelFormulaHelper.getCellLink(null, columnId, rowId);

  updatedCellsMap[cellId] = {
    rowId,
    columnId,
    value: '',
    prevValue: getCellValue(rowData, columnId),
  };

  const configColumnId = getConfigId(columnId);
  if (configColumnId in rowData) {
    const configCellId = ExcelFormulaHelper.getCellLink(null, configColumnId, rowId);
    updatedCellsMap[configCellId] = {
      rowId,
      columnId: configColumnId,
      value: null,
      prevValue: getCellValue(rowData, configColumnId),
    };
  }
  const mhColumnId = getMergeHorizontalId(columnId);
  if (mhColumnId in rowData) {
    const mhCellId = ExcelFormulaHelper.getCellLink(null, mhColumnId, rowId);
    updatedCellsMap[mhCellId] = {
      rowId,
      columnId: mhColumnId,
      value: 1,
      prevValue: getCellValue(rowData, mhColumnId),
    };
  }
  const mvColumnId = getMergeVerticalId(columnId);
  if (mvColumnId in rowData) {
    const mvCellId = ExcelFormulaHelper.getCellLink(null, mvColumnId, rowId);
    updatedCellsMap[mvCellId] = {
      rowId,
      columnId: mvColumnId,
      value: 1,
      prevValue: getCellValue(rowData, mvColumnId),
    };
  }

  const styleColumnId = getStylePropertyId(columnId);
  if (styleColumnId in rowData) {
    const styleCellId = ExcelFormulaHelper.getCellLink(null, styleColumnId, rowId);
    updatedCellsMap[styleCellId] = {
      rowId,
      columnId: styleColumnId,
      value: {},
      prevValue: getCellValue(rowData, styleColumnId),
    };
  }
};
