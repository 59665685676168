import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DrawingsInstanceType } from 'common/components/drawings/enums';
import { useAddInstancesWithUndo, useNewInstancesSettings } from 'common/components/drawings/hooks';
import {
  DrawingsCountGeometry,
  DrawingsGeometryInstanceWithId,
  ShortPointDescription,
} from 'common/components/drawings/interfaces';
import { DrawingsTextSearchState } from 'common/components/drawings/interfaces/drawings-state';
import { DrawingsCanvasUtils } from 'common/components/drawings/utils/drawings-canvas-utils';
import { DrawingsGroupUtils } from 'common/components/drawings/utils/drawings-group-utils';
import { State } from 'common/interfaces/state';
import { UuidUtil } from 'common/utils/uuid-utils';

export function useCreateCounts(): () => void {
  const {
    results,
    settings: { query },
  }= useSelector<State, DrawingsTextSearchState>(state => state.drawings.textSearch);
  const addInstancesWithUndo = useAddInstancesWithUndo();
  const { color: newDrawingColor, shape: newDrawingShape } = useNewInstancesSettings();
  return useCallback(
    () => {
      const color = newDrawingColor || DrawingsCanvasUtils.getColorFromList();
      const group = DrawingsGroupUtils.createNewGroup(query);
      const instances = new Array<DrawingsGeometryInstanceWithId<DrawingsCountGeometry>>();
      const points: Record<string, ShortPointDescription> = {};
      for (const [ drawingId, searchResults ] of Object.entries(results.results)) {
        const pointIds = [];
        for (const { rectangleBounds } of searchResults) {
          const pointsSum = rectangleBounds.reduce(
            ([sumX, sumY], [x, y]) => ([sumX + x, sumY + y]),
            [0, 0],
          );
          const pointValue = [pointsSum[0] / rectangleBounds.length, pointsSum[1] / rectangleBounds.length];
          const pointId = UuidUtil.generateUuid();
          pointIds.push(pointId);
          points[pointId] = pointValue as ShortPointDescription;
        }

        const instance: DrawingsGeometryInstanceWithId<DrawingsCountGeometry> = {
          id: UuidUtil.generateUuid(),
          drawingId,
          name: query,
          isAuto: false,
          type: DrawingsInstanceType.Count,
          groupId: group.id,
          geometry: {
            color,
            shape: newDrawingShape,
            points: pointIds,
          },
        };
        instances.push(instance);
      }
      addInstancesWithUndo({
        instances,
        points,
        groups: [group],
      });
    },
    [newDrawingColor, newDrawingShape, results, query, addInstancesWithUndo],
  );
}
