import { IconButton, Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LinkComponent } from 'common/components/link-component';
import { RenderIf } from 'common/components/render-if';
import { ElementTooltip } from 'common/components/tooltip';
import { Mood } from 'common/enums/mood';
import { RequestStatus } from 'common/enums/request-status';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { MagicSearchActions } from '../../actions/creators/magic-search';
import { DrawingsCanvasConstants, MagicSearchConstants } from '../../constants';
import { ContourType, FixContour, ShortPointDescription } from '../../interfaces';
import { DrawingsFlyingMenuContainer } from '../drawings-flying-menu';

interface Props {
  sendRef: (ref: HTMLDivElement) => void;
  fixContourAPI: FixContour;
}

const Loading: React.FC<{ sendRef: (ref: HTMLDivElement) => void }> = ({ sendRef }) => (
  <DrawingsFlyingMenuContainer ref={sendRef}>
    <LinkComponent
      icon={Icons.Loader}
    />
  </DrawingsFlyingMenuContainer>
);

const useDelete = ({ api: { setAsDeleted }, contourIndex, contourType }: FixContour): () => void => {
  const deleteContoursToFix = useActionDispatch(MagicSearchActions.deleteFixContour);

  return useCallback(() => {
    if (contourType === ContourType.Fixed) {
      deleteContoursToFix(contourIndex);
    } else {
      setAsDeleted();
    }
  }, [setAsDeleted, contourIndex, contourType, deleteContoursToFix]);
};


const Loaded: React.FC<Props> = ({ sendRef, fixContourAPI }) => {
  const { api: { tryFix, apply } } = fixContourAPI;
  const deleteCallback = useDelete(fixContourAPI);

  return (
    <DrawingsFlyingMenuContainer ref={sendRef}>
      <IconButton
        onClick={apply}
        Icon={Icons.Apply}
        defaultColor='green'
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
      />
      <LinkComponent
        icon={Icons.Reset}
        text='Again'
        onClick={tryFix}
        fontColor='mainFont'
      />
      <IconButton
        defaultColor='red'
        onClick={deleteCallback}
        Icon={Icons.Delete}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
      />
    </DrawingsFlyingMenuContainer>
  );
};

const Failed: React.FC<Props> = ({ sendRef, fixContourAPI }) => {
  const { api: { tryFix } } = fixContourAPI;
  const deleteCallback = useDelete(fixContourAPI);
  return (
    <DrawingsFlyingMenuContainer ref={sendRef}>
      <LinkComponent
        icon={Icons.Reset}
        text='Again'
        onClick={tryFix}
        mood='negative'
        fontColor='mainFont'
      />
      <IconButton
        defaultColor='red'
        onClick={deleteCallback}
        Icon={Icons.Delete}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
      />
    </DrawingsFlyingMenuContainer>
  );
};


const Main: React.FC<Props> = ({ sendRef, fixContourAPI }) => {
  const { api: { tryFix } } = fixContourAPI;
  const deleteCallback = useDelete(fixContourAPI);
  const addToNegative = useActionDispatch(MagicSearchActions.setCurrentContourAsNegative);
  const contoursToSearch = useSelector<State, ShortPointDescription[][]>(
    state => state.drawings.magicSearch.contoursToSearch,
  );
  const negativeContours = useSelector<State, ShortPointDescription[][]>(
    state => state.drawings.magicSearch.negativeContours,
  );
  const canAddNegative = contoursToSearch.length + negativeContours.length < MagicSearchConstants.MAX_POLYGONS_COUNT;

  return (
    <DrawingsFlyingMenuContainer ref={sendRef}>
      <LinkComponent
        icon={Icons.AITool}
        text='Fix with AI'
        onClick={tryFix}
        mood={Mood.Secondary}
        fontColor='mainFont'
      />
      <RenderIf condition={!!contoursToSearch.length}>
        <ElementTooltip
          position={'top'}
          text={canAddNegative
            ? `Don't search areas like this`
            : 'You can mark use only 4 polygons (requested + negative) as input'
          }
        >
          <IconButton
            mood={canAddNegative ? Mood.Negative : Mood.Disabled}
            defaultColor={canAddNegative ? 'red' : undefined}
            onClick={addToNegative}
            Icon={Icons.DislikeAI}
            height={DrawingsCanvasConstants.menuButtonSize}
            width={DrawingsCanvasConstants.menuButtonSize}
          />
        </ElementTooltip>
      </RenderIf>
      <IconButton
        defaultColor='red'
        onClick={deleteCallback}
        Icon={Icons.Delete}
        height={DrawingsCanvasConstants.menuButtonSize}
        width={DrawingsCanvasConstants.menuButtonSize}
      />
    </DrawingsFlyingMenuContainer>
  );
};

const MenuComponent: Record<string, React.ComponentType<Props>> = {
  [RequestStatus.Loading]: Loading,
  [RequestStatus.Loaded]: Loaded,
  [RequestStatus.Failed]: Failed,
};

const AiSearchFixMenuComponent: React.FC<Props> = ({
  sendRef,
  fixContourAPI,
}) => {
  const Component = MenuComponent[fixContourAPI.state] || Main;

  return <Component sendRef={sendRef} fixContourAPI={fixContourAPI} />;
};

export const AiSearchFixMenu = React.memo(AiSearchFixMenuComponent);
