import Axios,
{
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';
import * as t from 'io-ts';

import { ApiConstants } from 'common/constants/api';
import { DatadogLogger } from 'common/environment/datadog-logger';
import { CodecUtils } from 'common/utils/codec-utils';
import { reloadWrapper } from 'common/utils/reload-wrapper';
import { store } from '../../store';
import { AccountActions } from '../../units/account/actions/creators';
import { KeyCloakService } from '../../units/account/keycloak';

const dispatch = store.dispatch;

const UNAUTHORIZED_ERROR_CODE = 401;
const AXIOS_INSTANCE_CONFIG_API: AxiosRequestConfig = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    credentials: 'include',
    'Content-Type': 'application/json',
  },
  baseURL: '/api',
};
const AXIOS_INSTANCE_CONFIG_ROOT: AxiosRequestConfig = {
  headers: {
    credentials: 'include',
  },
  baseURL: '/',
};

interface RequestOnHold {
  promiseResolver: (response: AxiosResponse) => void;
  config: AxiosRequestConfig;
}


export interface ProgressData {
  lengthComputable: boolean;
  loaded: number;
  total: number;
}

function logResponse(response: AxiosResponse<any>): void {
  DatadogLogger.log(`${response.config.method} ${response.config.url} ${response.status}`);
}

function logResponseError(error: any): void {
  if (Axios.isCancel(error)) {
    return;
  }
  const status = error && error.response && error.response.status;
  DatadogLogger.error(`${error.config.method} ${error.config.url} ${status}`);
}

function logRequest(requestConfig: AxiosRequestConfig): AxiosRequestConfig {
  DatadogLogger.log(`${requestConfig.method} ${requestConfig.url}`);
  return requestConfig;
}

function getConfigWithAuth(local: boolean): AxiosRequestConfig {
  let config = local ? AXIOS_INSTANCE_CONFIG_ROOT : AXIOS_INSTANCE_CONFIG_API;
  config = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${KeyCloakService.getToken()}`,
    },
  };
  return config;
}

function createAxiosInstance(onErrorInterceptor: (error: any) => Promise<any>, local?: boolean): AxiosInstance {
  const instance = Axios.create(getConfigWithAuth(local));
  instance.interceptors.response.use(
    response => {
      logResponse(response);
      return response;
    },
    (error) => {
      logResponseError(error);
      return onErrorInterceptor(error);
    },
  );
  instance.interceptors.request.use(logRequest);
  return instance;
}

export class CommonApi {
  private static requestsOnHold: RequestOnHold[] = new Array<RequestOnHold>();
  private static isRefreshingToken: boolean = false;
  private static axiosInstanceWithLogout: AxiosInstance =
    createAxiosInstance(CommonApi.logoutOnUnauthorizedInterceptor);
  private static axiosInstanceWithRefresh: AxiosInstance = createAxiosInstance(CommonApi.refreshOnErrorInterceptor);
  private static axiosInstanceWithRefreshRoot: AxiosInstance =
    createAxiosInstance(CommonApi.refreshOnErrorInterceptor, true);

  /**
   * @deprecated Use getV instead
   */
  public static get<TResponse = any>(
    url: string,
    error: string = null,
  ): Promise<TResponse> {
    return this.makeRequest('get', url, null, error);
  }

  public static getV<TCodec extends t.Any>(
    url: string,
    codec: TCodec,
    error: string = null,
  ): Promise<t.TypeOf<TCodec>> {
    return this.makeRequest('get', url, null, error)
      .then(r => CommonApi.validateResponse(r, codec, 'get', url));
  }

  public static getBinary<TResponse = any>(
    url: string,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
    onProgress?: (progressEvent: any) => void,
  ): Promise<TResponse> {
    const params: AxiosRequestConfig = {
      url,
      method: 'get',
      responseType: 'arraybuffer',
      cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
      headers: this.getAuthHeaders(),
      onDownloadProgress: onProgress,
    };
    return new Promise<TResponse>((resolve, reject) => {
      CommonApi.axiosInstanceWithRefresh
        .request(params)
        .then(response => resolve(response.data))
        .catch(responseError =>
          !Axios.isCancel(responseError) && reject(new Error(error ? `${error}` : responseError)),
        );
    });
  }

  public static refreshSession(): Promise<void> {
    return KeyCloakService.updateToken().catch(CommonApi.logoutOnUnauthorizedInterceptor);
  }

  public static post<TResponse = any, TPayload = any>(
    url: string,
    data?: TPayload,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<TResponse> {
    return this.makeRequest('post', url, data, error, cancelTokenSource);
  }

  public static postV<TResponseValidator extends t.Any, TPayload = any>(
    url: string,
    data?: TPayload,
    responseValidator?: TResponseValidator,
    error?: string,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<t.TypeOf<TResponseValidator>> {
    return this.makeRequest('post', url, data, error, cancelTokenSource)
      .then(r => CommonApi.validateResponse(r, responseValidator, 'post', url));
  }

  public static put<TResponse = any, TPayload = any>(
    url: string,
    data: TPayload,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<TResponse> {
    return this.makeRequest('put', url, data, error, cancelTokenSource);
  }

  public static putV<TResponseValidator extends t.Any, TPayload = any>(
    url: string,
    data?: TPayload,
    responseValidator?: TResponseValidator,
    error?: string,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<t.TypeOf<TResponseValidator>> {
    return this.makeRequest('put', url, data, error, cancelTokenSource)
      .then(r => CommonApi.validateResponse(r, responseValidator, 'post', url));
  }

  public static delete<TResponse = any, TPayload = any>(
    url: string,
    data: TPayload = null,
    error: string = null,
  ): Promise<TResponse> {
    return this.makeRequest('delete', url, data, error);
  }

  public static deleteV<TResponseValidator extends t.Any, TPayload = any>(
    url: string,
    data: TPayload = null,
    responseValidator?: TResponseValidator,
    error: string = null,
  ): Promise<t.TypeOf<TResponseValidator>> {
    return this.makeRequest('delete', url, data, error)
      .then(r => CommonApi.validateResponse(r, responseValidator, 'delete', url));
  }


  public static patch<TResponse = any, TPayload = any>(
    url: string,
    data: TPayload,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<TResponse> {
    return this.makeRequest('patch', url, data, error, cancelTokenSource);
  }

  public static patchV<TResponseValidator extends t.Any, TPayload = any>(
    url: string,
    data: TPayload,
    responseValidator?: TResponseValidator,
    error?: string,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<t.TypeOf<TResponseValidator>> {
    return this.makeRequest('patch', url, data, error, cancelTokenSource)
      .then(r => CommonApi.validateResponse(r, responseValidator, 'patch', url));
  }

  public static postWithProgress<T = any, Data = any>(
    url: string,
    data: Data,
    onProgress: (progressEvent: any) => void,
    error?: string,
    cancelTokenSource?: CancelTokenSource,
    onCancelled?: () => void,
  ): Promise<T> {
    return new Promise((resolve, reject) => CommonApi
      .axiosInstanceWithRefresh
      .post<T>(
        url,
        data,
        {
          headers: this.getAuthHeaders(),
          onDownloadProgress: onProgress,
          cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        },
      )
      .then(response => resolve(response.data))
      .catch(responseError => {
        if (Axios.isCancel(responseError)) {
          if (onCancelled) {
            onCancelled();
          }
          return;
        }
        if (responseError.response?.status === 503) {
          reloadWrapper('503 error');
        }
        reject(CommonApi.getResponseError(responseError, error));
      }),
    );
  }

  public static uploadFile<T = any>(
    url: string,
    file: File,
    onProgress: (loaded: number) => void,
    onSuccess: (data: T) => void,
    onError: (err: any) => void,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);
    return CommonApi.axiosInstanceWithRefresh
      .post<T>(url, formData, {
        headers: this.getAuthHeaders(),
        onUploadProgress: e => {
          if (e.lengthComputable) {
            const loaded = e.loaded / e.total;
            if (onProgress) {
              onProgress(loaded);
            }
          }
        },
        cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
      })
      .then(response => {
        if (response.status === 200) {
          if (onSuccess) {
            onSuccess(response.data);
          }
        }
      })
      .catch(err => {
        onError(err);
      });
  }

  public static downloadFile(
    url: string,
    error: string = null,
  ): Promise<void> {
    return this.makeFileRequest('get', url, null, error);
  }

  public static downloadFileFromAnotherSite(
    url: string,
  ): void {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
  }

  public static downloadFileWithData<TPayload = any>(
    url: string,
    data: TPayload,
    error: string = null,
    onProgress?: (progressEvent: ProgressData) => void,
  ): Promise<void> {
    return this.makeFileRequest('post', url, data, error, onProgress);
  }

  public static getBlob<T = any>(
    url: string,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      CommonApi.axiosInstanceWithRefreshRoot
        .request({
          url,
          method: 'get',
          responseType: 'blob',
          headers: CommonApi.getAuthHeaders(),
          cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        })
        .then(response => resolve(response.data))
        .catch(_error => {
          if (!Axios.isCancel(_error)) {
            return reject(new Error(error ? `${error}` : _error));
          }
        });
    });
  }

  public static getLink(url: string): string {
    return `/api${url}`;
  }

  private static makeRequest<T = any, K = any>(
    method: 'get' | 'post' | 'put' | 'delete' | 'patch',
    url: string,
    data: K,
    error: string = null,
    cancelTokenSource?: CancelTokenSource,
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      CommonApi.axiosInstanceWithRefresh
        .request<T>({
          url,
          data,
          method,
          headers: CommonApi.getAuthHeaders(),
          cancelToken: cancelTokenSource ? cancelTokenSource.token : undefined,
        })
        .then(response => resolve(response.data))
        .catch(responseError => {
          if (Axios.isCancel(responseError)) {
            return;
          }
          if (responseError.response?.status === 503) {
            reloadWrapper('503 error');
          }
          reject(CommonApi.getResponseError(responseError, error));
        });
    });
  }

  private static getAuthHeaders(): { Authorization: string } {
    return {
      Authorization: `Bearer ${KeyCloakService.getToken()}`,
    };
  }

  private static getResponseError(responseError: any, error: string | null): any {
    const responseCode = responseError.response && responseError.response.status;
    if (error || responseCode === 500) {
      return new Error(error ? `${error}` : JSON.stringify(responseError));
    } else {
      const responseData = responseError.response && responseError.response.data;
      return responseData
        ? { code: responseCode, ...responseData }
        : responseError;
    }
  }

  private static logoutOnUnauthorizedInterceptor(error: any): Promise<any> {
    const status = error && error.response && error.response.status;
    if (status === UNAUTHORIZED_ERROR_CODE) {
      dispatch(AccountActions.logOut());
    }
    CommonApi.requestsOnHold = [];
    CommonApi.isRefreshingToken = false;
    return Promise.reject(error);
  }

  // any because, any in typepings
  private static refreshOnErrorInterceptor(error: any): Promise<any> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      if (!error.message || error.message !== ApiConstants.RequestCancelMessage) {
        const status = error && error.response && error.response.status;
        const originalRequest = error && error.config;

        if (status === UNAUTHORIZED_ERROR_CODE) {
          if (originalRequest.__retry) {
            dispatch(AccountActions.logOut());
          } else {
            originalRequest.__retry = true;
            originalRequest.baseURL = '/';
            CommonApi.putRequestOnHold(resolve, originalRequest);
            if (!CommonApi.isRefreshingToken) {
              CommonApi.isRefreshingToken = true;
              return CommonApi.refreshSession().then(() => {
                for (const { promiseResolver, config } of CommonApi.requestsOnHold) {
                  config.headers = { ...config.headers, ...CommonApi.getAuthHeaders() };
                  CommonApi.axiosInstanceWithLogout.request(config).then(promiseResolver);
                }
                CommonApi.requestsOnHold = [];
                CommonApi.isRefreshingToken = false;
              }).catch(reject);
            }
          }
        } else {
          return reject(error);
        }
      }
    });
  }

  private static putRequestOnHold(
    promiseResolver: (response: AxiosResponse) => void,
    config: AxiosRequestConfig,
  ): void {
    this.requestsOnHold.push({ promiseResolver, config });
  }

  private static validateResponse<TResponseValidator extends t.Any>(
    response: t.TypeOf<TResponseValidator> | null,
    responseValidator: TResponseValidator | null,
    method: string,
    url: string,
  ): t.TypeOf<TResponseValidator> {
    if (response && responseValidator) {
      try {
        return CodecUtils.decode(response, responseValidator);
      } catch (error) {
        const message = `Invalid API response (method: ${method}, url: ${url}).\n ${error}`;
        console.error(message);
        throw Error(message);
      }
    }

    return response;
  }

  private static async makeFileRequest<K = any>(
    method: 'get' | 'post',
    url: string,
    data: K,
    error: string = null,
    onProgress?: (progressEvent: ProgressData) => void,
  ): Promise<void> {
    try {
      const response = await CommonApi.axiosInstanceWithRefresh
        .request({
          url,
          method,
          responseType: 'blob',
          headers: CommonApi.getAuthHeaders(),
          data,
          onDownloadProgress: onProgress,
        });

      if (response.status === 200) {
        let fileName = '';
        const matches = response.headers['content-disposition'].match(/filename=((['"]).*?\2|[^;\n]*)/);
        if (matches && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        } else {
          throw new Error('Cannot parse Content-Disposition header.');
        }
        const fileUrl = URL.createObjectURL(new Blob([response.data], { type: response.data.type }));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(fileUrl);
      }
    } catch (_error) {
      throw new Error(error ? `${error}` : _error);
    }
  }
}
