import { Icons, IconButton } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';


import { EmailInput, InputSelect } from 'common/components/inputs';
import { RenderIf } from 'common/components/render-if';
import { Text } from '../../text';
import { Styled } from './styled';


interface Props {
  index: number;
  value: string;
  activeElementIndex: number;
  roles: string[];
  onEmailChange: (index: number, value: string) => void;
  deleteField?: (index: number) => void;
  onRoleChange?: (index: number, roleIndex: number) => void;
}

export const InviteField: React.FC<Props> = ({
  deleteField,
  onRoleChange,
  onEmailChange,
  index,
  value,
  activeElementIndex,
  roles,
}) => {
  const onHandleDeleteField = useCallback(() => {
    deleteField(index);
  }, [deleteField, index]);

  const handleEmailChange = useCallback((newValue) => {
    onEmailChange(index, newValue);
  }, [index, onEmailChange]);

  const onRoleChangeHandler = useCallback((roleIndex) => {
    onRoleChange(index, roleIndex);
  }, [index, onRoleChange]);

  return (
    <Styled.Form>
      <RenderIf condition={!!handleEmailChange}>
        <Styled.EmailInput>
          <Text fontSize={14}>Email address</Text>
          <EmailInput
            value={value}
            placeholder={'Enter email'}
            onBlur={handleEmailChange}
          />
        </Styled.EmailInput>
      </RenderIf>
      <RenderIf condition={!!onRoleChange}>
        <Styled.DropdownContainer>
          <Styled.RoleContainer>
            <Text fontSize={14}>Role</Text>
          </Styled.RoleContainer>
          <InputSelect
            elements={roles}
            activeElementIndex={activeElementIndex}
            onClick={onRoleChangeHandler}
            isShowArrow={true}
            createNewElement={false}
          />
        </Styled.DropdownContainer>
      </RenderIf>
      <RenderIf condition={!!deleteField}>
        <Styled.DeleteButtonContainer>
          <IconButton
            Icon={Icons.Delete}
            height={20}
            iconHeight={20}
            onClick={onHandleDeleteField}
          />
        </Styled.DeleteButtonContainer>
      </RenderIf>
    </Styled.Form>
  );
};
