import { Icons } from '@kreo/kreo-ui-components';
import React, { useCallback } from 'react';
import { Operation } from 'common/ability/operation';
import { Subject } from 'common/ability/subject';
import { useAbility } from 'common/ability/use-ability';
import { Submenu } from '../submenu';
import { TransformMenuContent } from './transform-menu-content';


interface Props {
  selectedInstancesIds: string[];
}


const TransformMenuComponent: React.FC<Props> = ({
  selectedInstancesIds,
}) => {
  const canEditMeasurements = useAbility(Operation.Update, Subject.Takeoff2DMeasurement);

  const renderContextMenu = useCallback((close: () => void) => {
    return (
      <TransformMenuContent
        selectedInstancesIds={selectedInstancesIds}
        onClose={close}
      />
    );
  }, [ selectedInstancesIds ]);

  if (!canEditMeasurements) {
    return null;
  }

  return (
    <Submenu
      menuRenderer={renderContextMenu}
      iconType={Icons.Transform}
      disabled={false}
      tooltip='Transform'
    />
  );
};

export const TransformMenu = React.memo(TransformMenuComponent);
