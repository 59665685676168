import { Icons } from '@kreo/kreo-ui-components';
import {
  DrawingsContextMenuActions,
  DrawingsContextMenuFilter,
  DrawingsContextMenuItem,
} from '../../drawings-context-menu';

export const FlyingMenuContextMenuItems: DrawingsContextMenuItem[] = [
  {
    action: DrawingsContextMenuActions.Copy,
    filters: [DrawingsContextMenuFilter.CanEditMeasurement],
    hotKey: 'Ctrl + C',
    icon: Icons.CopyDuplicate,
  },
  {
    action: DrawingsContextMenuActions.Duplicate,
    filters: [
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    hotKey: 'Ctrl + D',
    icon: Icons.Duplicate2D,
  },
  {
    action: DrawingsContextMenuActions.Cut,
    filters: [DrawingsContextMenuFilter.CanEditMeasurement],
    hotKey: 'Ctrl + X',
    icon: Icons.Cut,
  },
  {
    action: DrawingsContextMenuActions.Paste,
    filters: [
      DrawingsContextMenuFilter.CanPaste,
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    hotKey: 'Ctrl + V',
    icon: Icons.Paste,
  },
  {
    action: DrawingsContextMenuActions.PasteWithPoint,
    filters: [
      DrawingsContextMenuFilter.CanPaste,
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    icon: Icons.PasteWithPoint,
  },
  {
    action: DrawingsContextMenuActions.PasteInSamePlace,
    filters: [
      DrawingsContextMenuFilter.CanPasteInSamePlace,
      DrawingsContextMenuFilter.CanEditMeasurement,
    ],
    icon: Icons.Paste,
  },
  {
    action: DrawingsContextMenuActions.Hide,
    filters: [DrawingsContextMenuFilter.CanInstancesHide],
    hotKey: 'H',
    icon: Icons.Hide2D,
  },
  {
    action: DrawingsContextMenuActions.Show,
    filters: [DrawingsContextMenuFilter.CanInstancesShow],
    hotKey: 'H',
    icon: Icons.Show2D,
  },
  {
    action: DrawingsContextMenuActions.Isolate,
    filters: [],
    hotKey: 'I',
    icon: Icons.Isolate2D,
  },
  {
    action: DrawingsContextMenuActions.Delete,
    filters: [DrawingsContextMenuFilter.CanEditMeasurement],
    icon: Icons.Delete,
    hotKey: 'Del',
  },
  {
    action: DrawingsContextMenuActions.ShowAll,
    filters: [DrawingsContextMenuFilter.HasHiddenInstances],
    hotKey: 'Ctrl + H',
    icon: Icons.Show2D,
  },

  {
    action: DrawingsContextMenuActions.SelectAll,
    filters: [DrawingsContextMenuFilter.HasVisibleInstances],
    hotKey: 'Ctrl + A',
    icon: Icons.SelectAll,
  },
  {
    action: DrawingsContextMenuActions.FullScreen,
    filters: [DrawingsContextMenuFilter.NotFullScreen],
    icon: Icons.FullScreen,
  },
  {
    action: DrawingsContextMenuActions.ExitFullscreen,
    filters: [DrawingsContextMenuFilter.FullScreen],
    icon: Icons.ExitFullscreen,
  },
  {
    action: DrawingsContextMenuActions.Focus,
    filters: [],
    icon: Icons.Focus,
    hotKey: 'F',
  },
];
