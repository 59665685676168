import React from 'react';

import { Mood } from 'common/enums/mood';
import { useDialogState, useOpenCloseDialog } from 'common/UIKit/dialogs/hooks';
import { ConfirmationDialog } from 'components/dialog/confirmation-dialog';
import { DrawingDialogs } from '../../constants/drawing-dialogs';


export interface AutoMeasureDrawingTypeDialogInfo {
  onSkip: () => void;
  description: string;
  title: string;
}

const AutoMeasureDrawingTypeDialogComponent: React.FC = () => {
  const dialogState = useDialogState<AutoMeasureDrawingTypeDialogInfo>(DrawingDialogs.AUTO_MEASURE_TYPE_DIALOG);
  const [, close ] = useOpenCloseDialog(DrawingDialogs.AUTO_MEASURE_TYPE_DIALOG);
  if (!dialogState) {
    return null;
  }
  const { description, title, onSkip } = dialogState;
  return (
    <ConfirmationDialog
      name={DrawingDialogs.AUTO_MEASURE_TYPE_DIALOG}
      title={title}
      text={description}
      confirmButtonText={`Run Anyway`}
      submitButtonMood={Mood.Negative}
      onConfirm={onSkip}
      onCancel={close}
      cancelButtonText={`Cancel`}
      zIndex={1003}
    />
  );
};


export const AutoMeasureDrawingTypeDialog = React.memo(AutoMeasureDrawingTypeDialogComponent);
