import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { WizzardToolsFullState } from 'common/components/drawings/interfaces';
import { useActionDispatch } from 'common/hooks';
import { State } from 'common/interfaces/state';
import { WizzardToolsActions } from '../../actions/creators';

export function useWizzardSetting<K extends keyof WizzardToolsFullState>(key: K):
[WizzardToolsFullState[K], (value: WizzardToolsFullState[K]) => void] {
  const value = useSelector<State, WizzardToolsFullState[K]>((x) => x.drawings.wizzardTools[key]);

  const onChange = useActionDispatch(WizzardToolsActions.setSettings);

  const onChangeCallback = useCallback(
    (newSelectedType: WizzardToolsFullState[K]) => {
      onChange(key, newSelectedType);
    },
    [onChange, key],
  );
  return [value, onChangeCallback];
}
